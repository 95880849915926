<template>
  <div style="height: 100%">
    <iframe :src="url" frameborder="0" width="100%" height="100%" />
  </div>
</template>

<script>
export default {
  name: 'Office',
  props: {
    value: {
      type: String,
      default: '',
    },
    height: {
      type: [String, Number],
      default: 700,
    },
  },
  computed: {
    url() {
      return `https://view.officeapps.live.com/op/view.aspx?src=${this.value}`
    },
  },
}
</script>
