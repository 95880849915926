const BLOG_LIST = {
  TITLE: 'Blog',
  BUTTON_ADD: 'Tạo bài viết',
  LABEL_NAME: 'Tên Blog',
  PLACEHOLDER_NAME: 'Tìm kiếm theo tên bài viết',

  //EMTY DATA
  TITLE_EMTY_DATA: 'Chưa tồn tại bài viết nào',
  SUBTITLE_EMTY_DATA:
    'Vui lòng tạo mới bài viết để hiển thị trên danh sách này',
  TH_NAME: 'Tên bài viết',
  TH_POSTING_TIME: 'Thời gian đăng',
  TH_VIEW: 'Lượt xem',
  TH_ACTION: 'Thao tác',
  TOOLTIP_EDIT_BLOG: 'Chỉnh sửa bài viết',
  BUTTON_CREATE_COPY: 'Tạo bản sao',

  POPUP_CONFIRM_CREATE_COPY_TITLE: 'Tạo bản sao bài viết',
  POPUP_CONFIRM_CREATE_COPY_MESSAGE:
    'Bạn có chắc muốn tạo bản sao cho bài viết này',
  MSG_CREATING: 'Đang tạo bài viết...'
}
const BLOG_DETAIL = {
  TITLE_NEW_BLOG: 'Blog mới',
  LINK_RETURN_TO_BLOG_LIST: 'Danh sách bài viết',

  //BLOG_INFO
  TITLE_INFO: 'Thông tin bài viết',
  LABEL_BLOG_TITLE: 'Tiêu đề',
  PLACEHOLDER_IMPORT_BLOG_TITLE: 'Nhập tiêu đề bài viết',
  LABEL_AUTHOR_NAME: 'Tác giả',
  PLACEHOLDER_IMPORT_AUTHOR: 'Nhập tên tác giả',

  TITLE_DISCUSSION: 'Thảo luận',
  SUBTITLE_DISCUSSION: 'Cho phép học viên bình luận trong trang học.',
  CHECKBOX_TURN_ON_COMMENT_FACEBOOK:
    'Bật bình luận Facebook nằm dưới chân trang',
  LABEL_CONFIGURATION_APPID: 'Cấu hình AppId',
  PLACEHOLDER_CONFIGURATION_APPID: 'Ví dụ: 1110049499506',

  //BLOG_DESCRIPTION
  TITLE_DESCRIPTION: 'Nội dung bài viết',
  //BLOG_IMAGE
  TITLE_IMAGE: 'Ảnh đại diện bài viết',
  SUBTITLE_IMAGE: 'Ảnh sẽ nằm trên danh sách bài viết.',
  //BLOG_PUBLISH
  TITLE_PUBLISH: 'Xuất bản bài viết',
  SUBTITLE_PUBLISH:
    'Bài viết đang được công khai. Bài viết sẽ được hiển thị trên trang web và có thể được tìm thấy trên các thanh công cụ tìm kiếm',
  SUBTITLE_HIDDEN_ARTICLE:
    'Bài viết đang bị ẩn. Người dùng sẽ không thể truy cập vào bài viết.',
  BUTTON_HIDE: 'Ẩn khỏi trang',
  BUTTON_PUBLIC: 'Công khai',
  //BLOG_DELETION
  TITLE_DELETION: 'Xóa bài viết',
  SUBTITLE_DELETION: 'Bài viết bị xóa sẽ không thể khôi phục',
  BUTTON_DELETE: 'Xoá bài viết',
  POPUP_CONFIRM_DELETE_BLOG_TITLE: 'Xoá bài viết khỏi hệ thống',
  POPUP_CONFIRM_DELETE_BLOG_MESSAGE: 'Dữ liệu sau khi xóa không thể khôi phục',
  MSG_DELETING_ARTICLE: 'Đang xoá bài viết',
  MSG_DELETE_SUCCESS: 'Xoá bài viết thành công',

  //MESSAGE
  MSG_CHECK_BLOG_TITLE_AGAIN: 'Vui lòng nhập tiêu đề bài viết!',
  MSG_CREATING: 'Đang tạo bài viết mới...',
  MSG_CREATE_SUCCESS: 'Tạo thành công',
  MSG_FAIL: 'Lỗi tạo bài viết!',
  MSG_UPDATING: 'Đang cập nhật bài viết...',
  MSG_UPDATE_SUCCESS: 'Cập nhật bài viết thành công',
  MSG_UPDATE_FAIL: 'Lỗi cập nhật bài viết!',
  MSG_LOADDING: 'Đang tải chi tiết bài viết...'
}
export default {
  BLOG_LIST,
  BLOG_DETAIL,

  BUTTON_REMOVE: 'Xoá',
  BUTTON_ADD: 'Thêm',
  BUTTON_SAVE: 'Lưu',
  BUTTON_CANCEL: 'Huỷ bỏ',
  BUTTON_CONFIRM: 'Xác nhận',
  BUTTON_ADD_IMAGE: 'Thêm ảnh',
  LINK_RETURN_CONTENT_BLOCK_LIST: 'Danh sách khối nội dung'
}
