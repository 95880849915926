<template>
	<div class="full-width d-flex justify-space-between align-center">
		<BaseTitleTable isBack :backText="$vuetify.lang.t('$vuetify.SITE_BUILDER.ACTION_BAR.GO_BACK_TEXT')"
			@click="openPopupConfirm" />
		<BaseButton :text="$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_SAVE')" @click="$emit('onSave')"
			:loading="loading" />
	</div>
</template>
<script>
export default {
	data() {
		return {
			loading: false
		}
	},
	methods: {
		openPopupConfirm() {
			this.$confirm.show({
				title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.ACTION_BAR.CONFIRM_GO_BACK_TITLE'),
				text: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.ACTION_BAR.CONFIRM_GO_BACK_DESCRIPTION'),
				okText: this.$vuetify.lang.t("$vuetify.SITE_BUILDER.BUTTON_CONFIRM"),
				cancelText: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_CANCEL'),
				done: async () => {
					this.$router.push('/')
				}
			})
		}
	},
}
</script>
