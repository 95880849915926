import Repository from '@/api/core'
import queryString from 'query-string'
const baseURL = process.env.VUE_APP_API_OFFLINE_STACK + '/attendance'

const count = (params) => {
  return Repository(baseURL + '-count').fetch(params)
}

const fetch = (params) => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const create = (params) => {
  return Repository(baseURL).create(params)
}

const createMany = (params) => {
  return Repository(baseURL + '-many').create(params)
}

const removeMany = (params) => {
  const query = queryString.stringify(params)
  return Repository(baseURL + `?${query}`).deleteMany()
}

const updateMany = (params) => {
  return Repository(baseURL).updateMany(params)
}

const reportStudentAttendance = (params) => {
  return Repository(baseURL + '-student').fetch(params)
}

export default {
  count,
  fetch,
  fetchOne,
  create,
  createMany,
  removeMany,
  updateMany,
  reportStudentAttendance,
}
