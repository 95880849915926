<template>
  <v-dialog v-model="dialog" width="650" :persistent="isConfirm">
    <v-card class="base-upload-dragger-list">
      <v-card-title class="headline pa-2">
        <div class="ml-2">
          {{
            $vuetify.lang.t(
              '$vuetify.COURSE_CONTENT.LIBRARY_LIST.VIDEO_PANEL.CONFIRM_UPLOAD_MULTIPLE_FILES'
            )
          }}
        </div>
        <v-spacer />
        <v-btn icon @click="closePopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">
        <div class="upload-wrapper">
          <div
            v-for="(item, index) in dataItems"
            :key="index"
            class="upload-file-wrapper pa-2 full-width"
          >
            <div class="upload-file-wrapper__info">
              <div class="full-width">
                <div
                  class="upload-file-wrapper__info-name text-body-2"
                  :class="{
                    'upload-file-wrapper__info-oversize':
                      item.originSize > singleUploadFileSize * 1024 * 1024, //2.5*1024*1024*1024 = 2,5gb
                  }"
                >
                  <span class="upload-file-wrapper__info-name--title">
                    {{ item.name }}
                  </span>
                  <div
                    v-if="item.originSize > singleUploadFileSize * 1024 * 1024"
                    class="pl-1"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <v-icon color="error" v-on="on" v-bind="attrs">
                          mdi-close-circle-outline
                        </v-icon>
                      </template>
                      <span class="black--text text-justify">
                        {{ `File không được vượt quá ${singleUploadFileSize} MB` }}
                      </span>
                    </v-tooltip>
                    <span
                      class="upload-file-wrapper__info-oversize--re-upload"
                      @click="reUploadImage(index)"
                    >
                      {{ 'Chọn lại File' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="upload-file-wrapper__action">
              <div v-if="!isConfirm">
                <div class="upload-file-wrapper__action-stat">
                  <div class="text-body-2">
                    {{ item.size }}
                  </div>
                  <v-btn @click="() => onRemoveFile(index)" icon>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
              <div v-else>
                <div
                  class="upload-file-wrapper__action-loading"
                  v-if="item.percent > 0 && item.percent !== 100"
                >
                  <div
                    class="upload-file-wrapper__action-loading--percent text-body-2"
                  >
                    {{ item.percent }}%
                  </div>
                  <BaseLoading />
                </div>
                <div v-if="item.percent === 100">
                  <v-icon color="primary">mdi-check-circle-outline</v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-4">
        <BaseButton
          :text="$vuetify.lang.t('$vuetify.BUTTON_CANCEL')"
          @click="closePopup"
          outlined
          :loading="isConfirm"
        />
        <v-spacer />
        <BaseButton
          :text="$vuetify.lang.t('$vuetify.BUTTON_CONFIRM')"
          @click="onConfirm"
          :loading="dataItems.length !== 0 && isConfirm"
          :disabled="dataItems.length === 0"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters({
      getFileEdited: 'cinema/getFileEdited',
      getEditedIndex: 'cinema/getEditedIndex',
    }),
  },
  props: {
    type: {
      type: String,
      default: 'video',
    },
    singleUploadFileSize: {
      type: Number,
      default: 2560,
    },
  },
  data() {
    return {
      dialog: false,
      files: [],
      dataItems: [],
      isConfirm: false,
      dataEdited: [],
    }
  },
  methods: {
    ...mapActions({
      createDocument: 'document/createDocument',
      createHLSVideo: 'cinema/createHLSVideo',
      setEditedIndex: 'cinema/setEditedIndex',
      removeEditedIndex: 'cinema/removeEditedIndex',
    }),
    openPopup(files = []) {
      this.dialog = true
      this.handleDataItems(files)
      this.files = [...files]
    },
    closePopup() {
      this.dialog = false
      this.refresh()
    },
    refresh() {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    handleDataItems(files = []) {
      this.dataItems = files.map((file) => {
        return {
          name: file.name,
          size: this.$utils.parseFileSize(file.size),
          originSize: file.size,
          percent: 0,
        }
      })
    },
    progressHandler(index, percent) {
      this.dataItems[index].percent = Math.min(100, Math.floor(percent))
    },
    async onConfirm() {
      this.isConfirm = true
      try {
        const promises = this.files.map((file, index) => {
          if (file.size <= this.singleUploadFileSize * 1024 * 1024) {
            if (this.type === 'video') {
              return this.createHLSVideo({
                file,
                onProgress: ({percent}) => {
                  this.progressHandler(index, percent)
                },
              })
            } else {
              return this.createDocument({
                type: this.type,
                file,
                onProgress: ({percent}) => {
                  this.progressHandler(index, percent)
                },
              })
            }
          }
        })
        const result = await Promise.all(promises)
        if (result) {
          this.$message.success(
            'Đã tải file lên thành công',
            3000
          )
          this.$emit('onUploadSuccess', result)
          this.closePopup()
        }
      } catch (e) {
        this.isConfirm = false
        this.$message.error(e.message)
      }
    },
    onRemoveFile(index = -1) {
      if (index > -1) {
        this.dataItems.splice(index, 1)
        this.files.splice(index, 1)
      }
    },
    reUploadImage(index) {
      this.setEditedIndex(index)
      document.querySelector('#dropzone').click()
    },
  },
  watch: {
    getFileEdited: {
      handler(val) {
        if (Array.isArray(val) && val.length) {
          this.dataEdited = val.map((file) => {
            return {
              name: file.name,
              size: this.$utils.parseFileSize(file.size),
              originSize: file.size,
              percent: 0,
            }
          })
          this.$utils.delay(200)
          this.dataItems.splice(this.getEditedIndex, 1, ...this.dataEdited)
          this.$utils.delay(200)
          this.removeEditedIndex()
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
<style lang="scss" scoped>
$color_grey: #e6e4eb;
$color_dark_black: #3e3e3c;
$space_xxs: 4px;

.base-upload-dragger-list {
  .upload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .upload-file-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $color_grey;
      border-bottom: none;
      &:last-of-type {
        border-bottom: 1px solid $color_grey;
      }
      .upload-file-wrapper__info {
        width: 1px;
        flex: 1;
        .upload-file-wrapper__info-name {
          display: inline-flex;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $color_dark_black;
          .upload-file-wrapper__info-name--title {
            padding-top: 2px;
          }
        }
        .upload-file-wrapper__info-oversize {
          color: red;
          .upload-file-wrapper__info-oversize--re-upload {
            color: rgb(74, 74, 244);
            cursor: pointer;
          }
        }
      }
      .upload-file-wrapper__action {
        display: flex;
        align-items: center;
        .upload-file-wrapper__action-stat {
          display: flex;
          align-items: center;
        }
        .upload-file-wrapper__action-loading {
          display: flex;
          align-items: center;
          .upload-file-wrapper__action-loading--percent {
            margin-right: $space_xxs;
          }
        }
      }
    }
  }
}
</style>
