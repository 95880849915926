<template>
  <div v-show="isVisibility">
    <div id="pdf-renderer" :key="rendererTime">
      <div class="canvas-element">
        <div class="exam-title">{{ title }}</div>
        <div class="exam-duration">
          {{ examCode
          }}{{
            $vuetify.lang.t("$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_TIME")
          }}: {{ duration }}
          {{ $vuetify.lang.t("$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.TEXT_MINUTE") }}
        </div>
      </div>
      <div
        class="question-container"
        v-for="(question, index) in flattenQuestions"
        :key="index"
      >
        <div class="question-overview">
          <div class="question-overview__parent" v-if="question.parentHtml">
            <div
              class="question-overview__description canvas-element"
              v-for="(element, i) in question.parentHtml"
              :key="'parent' + i"
              :class="{ 'is-last': i === question.parentHtml.length - 1 }"
            >
              <div v-if="i === 0" class="question-overview__parent-title">
                {{
                  $vuetify.lang.t(
                    "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_THREADS"
                  )
                }}:
              </div>
              <div v-html="element"></div>
            </div>
          </div>
          <div
            class="question-overview__description canvas-element"
            v-for="(element, i) in question.description"
            :key="'description' + i"
          >
            <div v-if="i === 0" class="question-overview__title">
              {{
                $vuetify.lang.t(
                  "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_QUESTION"
                )
              }}
              {{ index + 1 }}: {{ generateType(question.type) }}
            </div>
            <div v-html="element"></div>
          </div>
        </div>
        <div
          v-if="question.type !== 'group-single-choice'"
          class="question-container__answer canvas-element"
          :class="{ 'is-last': question.isLastQuestion }"
        >
          <div
            style="font-weight: bold"
            v-if="
              question.type === 'fill-blank' || question.type === 'group-fill-blank-child'
            "
          >
            {{
              $vuetify.lang.t(
                "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_ANSWER_THE_QUESTION"
              )
            }}:....................................
          </div>
          <div v-else>
            <div v-for="(answer, answerIndex) in question.answers" :key="answerIndex">
              <div style="display: inline-flex">{{ answer.label }}.</div>
              <div
                v-html="answer.value || ''"
                style="display: inline-flex; margin-left: 4px"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="pdf-renderer-temp"></div>
    <div id="description"></div>
  </div>
</template>
<script>
const qlWrapper = `<div data-v-b07b6bbe="" ql-container ql-snow" style="min-height: inherit; max-height: inherit; overflow-y: auto; position: relative; border: 0px;"><div class="ql-editor" data-gramm="false" style="padding: 0px;">{{element}}</div><div class="ql-clipboard" contenteditable="true" tabindex="-1"></div><div class="ql-tooltip ql-hidden"><a class="ql-preview" rel="noopener noreferrer" target="_blank" href="about:blank"></a><input type="text" data-formula="e=mc^2" data-link="https://quilljs.com" data-video="Embed URL"><a class="ql-action"></a><a class="ql-remove"></a></div><div class="ql-custom"><textarea style="width: 100%; margin: 0px; background: rgb(29, 29, 29); box-sizing: border-box; color: rgb(204, 204, 204); font-size: 15px; outline: none; padding: 20px; line-height: 24px; position: absolute; top: 0px; bottom: 0px; border: none; display: none;"></textarea></div></div>`;
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      questions: "pdfRender/getQuestions",
      examCode: "pdfRender/getExamCode",
      title: "pdfRender/getTitle",
      duration: "pdfRender/getDuration",
      rendererTime: "pdfRender/getRenderedTime",
      isVisibility: "pdfRender/getVisibility",
    }),
  },
  data() {
    return {
      flattenQuestions: [],
      qlWrapper,
    };
  },
  methods: {
    paragraphDivision(description) {
      if (description.indexOf("ql-editor") !== -1) {
        let descriptionTemp = document.createElement("div");
        let child = [];
        descriptionTemp.innerHTML = description;
        const qlEditor = descriptionTemp.firstChild
          ? descriptionTemp.firstChild.firstChild
          : null;
        if (!qlEditor) return [];
        const elementTemp = document.getElementById("description");
        let newElement = document.createElement("div");
        elementTemp.appendChild(newElement);
        qlEditor.children.forEach((element, index) => {
          newElement.appendChild(element.cloneNode(true));
          const newElementHeight = newElement.getBoundingClientRect().height;
          if (newElementHeight > 300 || index === (qlEditor.children || []).length - 1) {
            let elementWrapped = this.qlWrapper.replace(
              "{{element}}",
              newElement.outerHTML
            );
            child.push(elementWrapped);
            newElement.innerHTML = "";
          }
        });
        return child;
      } else return [description];
    },
    generateType(type) {
      switch (type) {
        case "single-choice":
        case "single-choice-pdf":
        case "group-single-choice-child":
          return this.$vuetify.lang.t(
            "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.CHECKBOX_PICK_ONCE_CORRECT_ANSWER"
          );
        case "multiple-choice":
        case "multiple-choice-pdf":
        case "group-multiple-choice-child":
          return this.$vuetify.lang.t(
            "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.CHECKBOX_PICK_MULTIPLE_ANSWERS"
          );
        case "fill-blank":
        case "group-fill-blank-child":
          return this.$vuetify.lang.t(
            "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.CHECKBOX_FILL_BLANK"
          );
        default:
          return "";
      }
    },
    handlerQuestions(sourceList) {
      let questions = [];
      sourceList.forEach((question) => {
        if (question.type === "group-single-choice") {
          if (!(question.questions || []).length) return;
          const child = question.questions
            .filter((v) => v)
            .map((q, index) => {
              let childData = {
                answers: q.answers,
                description: this.paragraphDivision(q.html || ""),
                type: q.type,
              };
              if (index === 0)
                childData.parentHtml = this.paragraphDivision(question.description || "");
              if (index === question.questions.length - 1)
                childData.isLastQuestion = true;
              return childData;
            });
          questions = questions.concat(child);
        } else
          questions.push({
            ...question,
            description: this.paragraphDivision(question.description || ""),
          });
      });
      return questions;
    },
  },
  watch: {
    questions: {
      async handler(questions) {
        this.flattenQuestions = this.handlerQuestions(questions);
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
#pdf-renderer,
#pdf-renderer-temp {
  line-height: 1.2;
  z-index: 99999;
  min-width: 699px;
  max-width: 700px;
  overflow-wrap: break-word;
  .exam-title {
    padding: 8px 0;
    font-size: 16px;
  }
  .exam-duration {
    padding-top: 4px;
    padding-bottom: 16px;
    font-size: 14px;
  }
  .question-overview__parent-title {
    padding-top: 4px;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
  }
  .question-overview__title {
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 8px;
  }
  .question-overview__description {
    font-size: 14px;
    &.is-last {
      padding-bottom: 8px;
    }
  }
  .question-container__answer {
    padding: 12px 0;
    > div {
      font-size: 13px;
    }
    &.is-last {
      padding-bottom: 24px;
    }
  }
  & img {
    width: 250px !important;
    height: 100%;
  }
  ::v-deep {
    .ql-clipboard,
    .ql-tooltip,
    .ql-custom {
      display: none;
    }
  }
  .canvas-element {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
