import axios from 'axios'
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'
import helpers from '@/helpers'
import get from 'lodash-es/get'
import {message} from '@/plugins/message.js'

const uploaderURL = 'https://ee.smoke-ant.com/api/v1/upload'

const createFormData = (file, unitId, mode = 'default') => {
  const data = new FormData()
  data.append('file', file)
  data.append('vendorId', vendor.state.vendor.vendorId)
  data.append('unitId', unitId)
  data.append('mode', mode)
  return data
}

const handleAxiosError = (error) => {
  if (error.response?.status === 500) {
    const messageErr = get(error, 'response.data.message', null)
    if (typeof messageErr === 'string' && messageErr.length) {
      message.error(messageErr)
    }
  }
  return Promise.reject(error)
}

const createProgressHandler = (onProgress) => (progressEvent) => {
  const percentage = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
  if (typeof onProgress === 'function') {
    onProgress({
      loaded: parseFloat(progressEvent.loaded),
      total: parseFloat(progressEvent.total),
      percent: parseFloat(percentage),
    })
  }
}

const uploadDocxToServer = ({file, unitId, onProgress, mode = 'default'}) => {
  const data = createFormData(file, unitId, mode)

  axios.interceptors.response.use((res) => res, handleAxiosError)

  return axios.post(uploaderURL, data, {
    headers: {
      Authorization: auth.state.auth.accessToken,
    },
    onUploadProgress: createProgressHandler(onProgress),
  })
}

const createQuestionConfig = (data) => ({
  time: 1,
  ...(data.Explain && {
    explain: data.Explain,
    isShowExplain: true,
  }),
  key: data.Key || null,
})

const createQuestionCorrect = (data) => {
  if (data.CorrectObjects && Object.keys(data.CorrectObjects).length) {
    return data.CorrectObjects
  }
  if (data.Correct && Array.isArray(data.Correct)) {
    if (data.Type.includes('fill-blank')) {
      return data.Correct.join('//')
    }
    return data.Correct.length > 1 ? [...data.Correct] : data.Correct[0]
  }
  return ''
}

const createQuestionAnswers = (data) => {
  const answers = {}
  const listDataAnswers = data.Answers || []
  listDataAnswers.forEach((dataAnswer, index) => {
    answers[dataAnswer.Id] = {
      type: 'html',
      value: dataAnswer.Content,
      label: helpers.DEFAULT_LABELS[index],
      key: dataAnswer.Id,
    }
  })
  return answers
}

const handleQuestion = (data = {}) => ({
  id: data.Id,
  html: data.Content || '',
  answers: createQuestionAnswers(data),
  correct: createQuestionCorrect(data),
  type: data.Type,
  config: createQuestionConfig(data),
  factor: 1,
  knowledge: data.KnowledgeTags,
  tags: (data.KnowledgeTags || []).join('-'),
  parentId: data.ParentId || null,
})

const handleGroupQuestion = (data) => {
  const childQuestions = data.Children.map((child) => handleQuestion(child))
  return {
    id: data.Id,
    type: data.Type,
    html: data.Content,
    questions: childQuestions.map((x) => x.id),
    factor: childQuestions.length,
    children: childQuestions,
    config: {time: 1},
    knowledge: data.KnowledgeTags,
    tags: (data.KnowledgeTags || []).join('-'),
  }
}

const mapQuestionData = (listData = []) =>
  listData.map((data) => (data.Type.includes('group') ? handleGroupQuestion(data) : handleQuestion(data)))

export default {
  uploadDocxToServer,
  mapQuestionData,
}
