const state = {
  questions: [],
  examCode: '',
  title: '',
  duration: '',
  rendererTime: 0,
  isVisibility: false,
  isVisibilityExam: false,
  isVisibilityExamReview: false,
  submission: null,
  isExamReviewRendered: false,
}
const actions = {
  pdfSetQuestions({ commit }, questions) {
    commit('setQuestions', questions)
  },
  pdfSetExamCode({ commit }, examCode) {
    commit('setExamCode', examCode)
  },
  pdfSetTitle({ commit }, title) {
    commit('setTitle', title)
  },
  pdfSetDuration({ commit }, duration) {
    commit('setDuration', duration)
  },
  pdfSetRenderedTime({ commit }, rendererTime) {
    commit('setRenderedTime', rendererTime)
  },
  pdfSetVisibility({ commit }, isVisibility) {
    commit('setVisibility', isVisibility)
  },
  pdfSetSubmission({ commit }, submission) {
    commit('setSubmission', submission)
  },
  pdfSetVisibilityExam({ commit }, isVisibility) {
    commit('setVisibilityExam', isVisibility)
  },
  pdfSetVisibilityExamReview({ commit }, isVisibility) {
    commit('setVisibilityExamReview', isVisibility)
  },
  pdfSetIsExamReviewRendered({commit}, isRendered = false) {
    commit('setIsExamReviewRendered', isRendered)
  },
}
const mutations = {
  setQuestions(state, questions) {
    state.questions = questions
  },
  setExamCode(state, examCode) {
    state.examCode = examCode
  },
  setTitle(state, title) {
    state.title = title
  },
  setDuration(state, duration) {
    state.duration = duration
  },
  setRenderedTime(state, rendererTime) {
    state.rendererTime = rendererTime
  },
  setVisibility(state, isVisibility) {
    state.isVisibility = isVisibility
  },
  setSubmission(state, submission) {
    state.submission = submission
  },
  setVisibilityExam(state, isVisibility) {
    state.isVisibilityExam = isVisibility
  },
  setVisibilityExamReview(state, isVisibility) {
    state.isVisibilityExamReview = isVisibility
  },
  setIsExamReviewRendered(state, isRendered) {
    state.isExamReviewRendered = isRendered
  },
}
const getters = {
  getQuestions: state => state.questions,
  getExamCode: state => state.examCode,
  getTitle: state => state.title,
  getDuration: state => state.duration,
  getRenderedTime: state => state.rendererTime,
  getVisibility: state => state.isVisibility,
  getSubmission: state => state.submission,
  getVisibilityExam: state => state.isVisibilityExam,
  getVisibilityExamReview: state => state.isVisibilityExamReview,
  getIsExamReviewRendered: state => state.isExamReviewRendered,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}