import {confirm} from '@/plugins/confirm'
import {message} from '@/plugins/message'
import utils from '@/helpers/utils.js'

const state = {
  exam: {},
  progress: {},
  loadingProgress: false,
}
const actions = {
  async executeExamFormatrix({commit, dispatch}, {settings, exam}) {
    if (exam) {
      commit('setExam', exam)
      const questionGenrate = await dispatch('questionGenerate/count', {exam: exam.id}, {root: true})
      if (questionGenrate) {
        let res = true
        confirm.show({
          title: 'Tạo mã đề mới',
          text: 'Hệ thống sẽ xóa các mã đề cũ và tạo lại các mã đề mới theo ma trận đề bạn mới cập nhật. Bạn có chắc chắn muốn tiếp tục ?',
          okText: 'Xác nhận',
          cancelText: 'Hủy bỏ',
          done: async () => {
            res = await dispatch('updateMatrix', {settings, exam, questionGenrate})
          },
        })
        await utils.delay(5000)
        return res
      }
      return await dispatch('updateMatrix', {settings, exam})
    }
  },
  async updateMatrix({commit, dispatch}, {settings, exam, questionGenrate}) {
    const validateNumberOfExam = await dispatch('validateNumberOfExam', {settings})
    if (!validateNumberOfExam) return
    await dispatch('deleteManyExamForMatrix', exam)
    await dispatch('createManyExamForMatrix', {settings, exam})
    if (questionGenrate) await dispatch('confirmDeleteSubmission', {settings, exam})
    commit('reset')
    return true
  },
  async createManyExamForMatrix({dispatch, commit}, {settings, exam}) {
    for (let i = 0; i < settings.numberOfExam; i++) {
      try {
        commit('setLoading', true)
        commit('setProgress', {
          type: 'create',
          number: i,
          total: settings.numberOfExam,
          entity: '',
        })
        await dispatch('exam/createExamMatrix', exam.id, {root: true})
      } catch (error) {
        message.error(error.message || 'Internal server error!')
      }
      console.log(i)
      await utils.delay(300)
    }
    commit('setLoading', false)
    message.success('Tạo đề thi thành công')
  },
  async deleteManyExamForMatrix({dispatch}, exam) {
    await dispatch('questionGenerate/deleteMany', {exam: exam.id}, {root: true})
  },
  async confirmDeleteSubmission({dispatch, commit}, {exam}) {
    const listSubmission = await dispatch('submission/fetchSubmissions', {exam: exam.id}, {root: true})
    if (listSubmission.length) {
      confirm.show({
        title: 'Hủy kết quả cũ của học viên',
        text: 'Đã có học viên làm đề cũ và có kết quả. Bạn có muốn hệ thống xóa kết quả của các học viên đó hay không ?',
        okText: 'Xác nhận',
        cancelText: 'Hủy bỏ',
        done: async () => {
          for await (const [index, submission] of listSubmission.entries()) {
            try {
              commit('setLoading', true)
              commit('setProgress', {
                type: 'delete',
                number: index,
                total: listSubmission.length,
                entity: '',
              })
              await dispatch('submission/removeSubmission', submission.id, {root: true})
            } catch (error) {
              message.error(error.message || 'Internal server error!')
            }
            await utils.delay(300)
          }
          message.success('Xoá kết quả thành công !')
          commit('setLoading', false)
        },
      })
    }
  },
  validateNumberOfExam(_, {settings}) {
    if (isNaN(settings.numberOfExam)) {
      message.error('Sai định dạng số lượng mã đề !')
      return false
    }
    if (settings.numberOfExam > 1000) {
      message.error('Số đề tối đa có thể tạo là 1000')
      return false
    }
    return true
  },
}
const mutations = {
  setExam: (state, val) => {
    return (state.exam = val)
  },
  setLoading: (state, val) => {
    return (state.loadingProgress = val)
  },
  setProgress: (state, val) => {
    return (state.progress = val)
  },
  reset: (state) => {
    state.exam = {}
    state.progress = {}
  },
}
const getters = {
  getLoadingProgress: (state) => {
    return state.loadingProgress
  },
  getProgress: (state) => {
    return state.progress
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
