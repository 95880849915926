<template>
  <div>
    <v-dialog v-if="isAdmin" v-model="dialog" width="800px" scrollable>
      <v-card>
        <v-card-title class="headline lighten-2 email-dialog__header" primary-titledialog
          >{{ $vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_SEND_EMAIL') }}
          <v-spacer></v-spacer>
          <v-btn icon @click="resetPopup">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-5">
          <v-form v-model="form" ref="form">
            <v-checkbox
              v-if="sentStudent"
              class="mt-0"
              :label="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_PICK_ALL_STUDENT')"
              v-model="isSelectAllStudent"
            />
            <BaseAutocomplete
              v-if="!isSelectAllStudent && sentStudent"
              :rules="[$rules.minLength(1)]"
              :label="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_PICK_STUDENT')"
              :placeholder="$vuetify.lang.t('$vuetify.USER_MANAGE.STUDENT_LIST.TITLE')"
              :items="students"
              item-text="user.name"
              item-value="user.id"
              :returnObject="false"
              multiple
              small-chips
              deletable-chips
              v-model="studentsChoice"
            />
            <BaseInput
              class="mt-2"
              :label="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_EMAIL_SUBJECTS')"
              :rules="[$rules.required]"
              :placeholder="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.PLACEHOLDER_EMAIL_SUBJECTS')"
              v-model="title"
            />
            <BaseEditor ref="edit-html" :rules="[$rules.required]" @text-change="(html) => (editorHtml = html)" />
            <input
              @change="handleFileSelect"
              id="imp-html"
              ref="input-html-file"
              type="file"
              accept=".html"
              style="display: none"
            />
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4 px-6">
          <!-- <BaseButton
            outlined
            @click="closePopup"
            :text="
              $vuetify.lang.t(
                '$vuetify.SYSTEM_SETTING.EMAIL_CONTENT_SETTING_FORM.BUTTON_INSTRUCT'
              )
            "
          /> -->
          <BaseButton
            outlined
            @click="importHtml"
            :text="$vuetify.lang.t('$vuetify.SYSTEM_SETTING.EMAIL_CONTENT_SETTING_FORM.BUTTON_IMPORT_TEMPLATE')"
          />
          <v-spacer></v-spacer>
          <BaseButton
            :text="
              !sentStudent
                ? `${$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.SEND_EMAIL_TO')}`
                : isSelectAllStudent
                ? $vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TH_SEND_EMAIL_ALL')
                : `${$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TH_SEND_EMAIL_TO')} ${
                    studentsChoice.length
                  } ${$vuetify.lang.t('$vuetify.USER_MANAGE.STUDENT_LIST.TITLE')}`
            "
            @click="onSubmit"
          />
        </v-card-actions>
      </v-card>
      <SentNotificationPopup type="email" ref="send-email" />
      <ErrorNotificationPopup type="email" ref="error-email" />
      <BaseProgressBar ref="progress-bar" entity="email" />
    </v-dialog>
  </div>
</template>
<script>
import SentNotificationPopup from '@/modules/Group/components/notification/SentNotificationPopup.vue'
import ErrorNotificationPopup from '@/modules/Group/components/notification/ErrorNotificationPopup.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  props: {
    type: String,
    sentStudent: {
      type: Boolean,
      default: true,
    },
    studentIds: {
      type: Array,
    },
  },

  components: {
    SentNotificationPopup,
    ErrorNotificationPopup,
  },
  data() {
    return {
      dialog: false,
      title: '',
      studentsChoice: [],
      editorHtml: '',
      form: false,
      isSelectAllStudent: true,
      height: 800,
    }
  },

  methods: {
    ...mapActions({
      sendMailToGroup: 'mail/sendMailToGroup',
      sendMail: 'mail/sendMail',
    }),
    openPopup(value) {
      if (value) {
        this.studentsChoice = value.map((item) => {
          return item.id
        })
      }
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
    },
    resetPopup() {
      this.dialog = false
      this.title = ''
      this.studentsChoice = []
      this.editorHtml = ''
      this.isSelectAllStudent = true
      this.$refs.form.resetValidation()

      this.$refs['edit-html'].resetData()
    },
    renderBaseEditor(value) {
      this.$refs['edit-html'].insertContent(value)
    },
    importHtml() {
      this.$refs['input-html-file'].click()
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        let data = e.target.result
        if (data) this.renderBaseEditor(data)
        this.isDataChange = true
      }
      reader.readAsText(file)
      document.getElementById('imp-html').value = ''
    },
    async onSubmit() {
      if (!this.$refs['form'].validate() || this.editorHtml.trim().length <= 0) return
      try {
        this.closePopup()
        this.$message.loading('Đang gửi email ...', 1000 * 60)
        if (this.isSelectAllStudent && this.sentStudent) {
          await this.sendMailToGroup({
            groupId: this.group.id,
            html: this.editorHtml,
            subject: this.title,
          })
        } else if (!this.sentStudent) {
          let studentIds = this.studentIds.map((item) => item.userId)
          await this.sendMail({
            userIds: studentIds,
            html: this.editorHtml,
            subject: this.title,
          })
        } else {
          await this.sendMail({
            userIds: this.studentsChoice,
            relationId: this.group.id,
            relationType: 'group',
            html: this.editorHtml,
            subject: this.title,
          })
        }
        this.$message.success(
          this.$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.MESSAGE_SEND_EMAIL_SUCCESS')
        )
      } catch (error) {
        if (error.response.status != 504) {
          this.$message.error(error)
        }
      } finally {
        this.resetPopup()
      }
    },
  },
  computed: {
    ...mapGetters({
      group: 'group/getGroup',
      students: 'userGroup/getUserGroups',
    }),
    isAdmin() {
      return this.$utils.isAdmin()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 8px 0px;
  }
  .v-dialog--scrollable > .v-card > .v-card__text,
  .v-dialog--scrollable > form > .v-card > .v-card__text {
    overflow-y: hidden;
  }
}
.email-dialog__content {
  height: 100%;
}
</style>
