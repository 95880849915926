<template>
	<div v-if="selectedImage">
		<h2>
			{{$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.TITLE')}}
		</h2>
		<v-row>
			<v-col class="d-flex justify-center" cols="12" md="5">
				<img
					v-if="!isLoading"
					class="thumbnail"
					:src="$utils.convertURL($utils.combineLinkWithBucket(selectedImage.link))"
				/>
				<v-progress-circular
					v-else
					class="mt-8"
					indeterminate
					color="gray"
				></v-progress-circular>
			</v-col>
			<v-col cols="12" md="7">
				<div class="font-weight-bold ellipsis-one-line">
					{{ (selectedImage && selectedImage.title) || 'Untitled' }}
				</div>
				<div class="ellipsis-one-line">{{$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.LABEL_SIZE')}}: {{ size }}</div>
				<div class="ellipsis-one-line">{{$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.LABEL_CREATE_AT')}}: {{ createdAt }}</div>
				<div @click="editImage()" class="primary--text cursor-pointer">
					{{$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.BUTTON_EDIT_IMAGE_INFO')}}
				</div>
				<div class="green--text ">{{$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.LABEL_FORMAT')}}: {{ mimeType }}</div>
			</v-col>
		</v-row>
		<v-divider></v-divider>
		<BaseInput
			class="mr-2 mt-5"
			name="url"
			label="URL"
			:placeholder="$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.PLACEHOLDER_INPUT_LINK')"
			disabled
			:value="url"
		/>
		<BaseInput
			class="mr-2"
			name="title"
			:label="$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.LABEL_HEADER')"
			:placeholder="$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.PLACEHOLDER_INPUT_LINK')"
			v-model="title"
		/>
	</div>
</template>
<script>
import dayjs from 'dayjs'
import {mapActions} from 'vuex'
import get from 'lodash-es/get'
export default {
	props: {
		selectedImage: Object
	},
	computed: {
		size() {
			return this.$utils.memorySizeOf(this.selectedImage.size)
		},
		mimeType() {
			return get(this.selectedImage, 'origin.mimeType')
		},
		createdAt() {
			return dayjs(this.selectedImage.createdAt).format('DD/MM/YYYY')
		},
		url() {
			return this.$utils.convertURL(this.$utils.combineLinkWithBucket(this.selectedImage.link))
		}
	},
	data() {
		return {
			title: '',
			isLoading: false
		}
	},
	methods: {
		...mapActions({
			updateDocument: 'document/updateDocument'
		}),
		async editImage() {
			try {
				await this.updateDocument({
					id: this.selectedImage.id,
					title: this.title
				})
				this.$message.success(this.$vuetify.lang.t('$vuetify.COURSE.MSG_UPDATE_SUCCESS'))
			} catch (error) {
				this.$message.error(error)
			}
		}
	},
	watch: {
		selectedImage: {
			handler(newValue) {
				if (newValue) {
					this.title = newValue.title
					this.isLoading = true
					setTimeout(() => {
						this.isLoading = false
					}, 500)
				}
			},
			immediate: true
		}
	}
}
</script>
<style lang="scss" scoped>
.thumbnail {
	width: 100%;
	border-radius: 4px;
}
</style>
