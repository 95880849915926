import Repository from '@/api/core'
const reportUrl  = process.env.VUE_APP_API_REPORT + '/log'

const fetch = (params) => {
  return Repository(reportUrl).fetch(params)
}
const count = (params) => {
  return Repository(reportUrl + '/count').fetch(params)
}

export default {
  fetch,
  count,
}