<template>
	<div>
		<BaseTitleTable
			isBack
			:backText="$vuetify.lang.t('$vuetify.SITE_BUILDER.DETAIL.GO_BACK_TEXT')"
			@click="backToListSection"
			textClass="subtitle-blur action-hover"
		/>
		<div class="text-h6 font-weight-medium mb-3">
			{{$vuetify.lang.t('$vuetify.SITE_BUILDER.DETAIL.BTN_ADD_COMPONENT')}}
		</div>
		<v-expansion-panels flat accordion hover>
			<v-expansion-panel v-for="(section, i) in listSelections" :key="i">
				<v-expansion-panel-header>{{ $vuetify.lang.t(section.name) }} </v-expansion-panel-header>
				<v-expansion-panel-content>
					<div
						class="mt-8 d-flex align-start cursor-pointer"
						v-for="(child, j) in section.children"
						@click="selectedItem(child)"
						:key="j"
					>
						<v-icon class="pr-4">mdi-image-area</v-icon>
						<div class="full-width mr-3">
							<div>{{ $vuetify.lang.t(child.label) }}</div>
							<div class="caption">{{ $vuetify.lang.t(child.description) }}</div>
						</div>
						<v-btn
							v-if="selected.name === child.name"
							depressed
							color="primary"
							@click="onAddSection(child)" 
							dark
							small
							>
							{{$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_ADD')}}
						</v-btn>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>
<script>
import helpers from '@/helpers'
import uuidv1 from 'uuid/v1'
export default {
	props: {
		listSelectionsForBuilder: {
			type: Array,
			default: () => []
		},
		isMobile: Boolean
	},
	data() {
		return {
			listSelections:
				this.listSelectionsForBuilder.length > 0
					? this.listSelectionsForBuilder
					: helpers.LIST_SELECTION_SITEBUILDER,
			selected: {}
		}
	},
	methods: {
		backToListSection() {
			this.$emit('backToListSection')
		},
		async onAddSection(item) {
			let newId = uuidv1()
			const defaultData = await import(
				`@/modules/${
					this.isMobile ? 'ScreenBuilder' : 'SiteBuilder'
				}/defaultData/${item.name}`
			)
			let payload = {
				...defaultData.default,
				name: `${item.name}:${newId}`,
				id: `${item.name}:${newId}`
			}
			this.$emit('onAddSection', payload)
		},
		selectedItem(selected) {
			this.selected = selected
		}
	}
}
</script>
