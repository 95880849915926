import Vue from 'vue'

export const filtersRegistry = () => {
  Vue.filter('getVideoStatusText', (status) => {
    switch (status) {
      case 'done':
        return 'Sẵn sàng để sử dụng'
      case 'queued':
      case 'queued-manual':
        return 'Đang được chuyển đổi'
      default:
        'Video lỗi trong quá trình tải lên'
    }
  })
}
