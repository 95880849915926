const getDataForBgImg = ({ imageObj, imageWidth, imageHeight }) => {
  return {
    id: 'bgImg',
    image: imageObj,
    // x: imageWidth / 2,
    // y: imageHeight / 2,
    width: imageWidth,
    height: imageHeight,
    src: imageObj.src,
    // offsetX: imageWidth / 2,
    // offsetY: imageHeight / 2,
    x: 0,
    y: 0,
  }
}

const getDataForCenterAnchor = ({ imageWidth, imageHeight }) => {
  return {
    id: 'anchor',
    points: [
      imageWidth / 2,
      imageHeight / 2,
      imageWidth / 2,
      imageHeight / 2
    ],
    opacity: 0
  }
}

const getDataForTextMarker = ({ imageWidth, imageHeight }) => {
  return {
    id: 'markerText',
    x: imageWidth / 2,
    y: imageHeight / 2,
    opacity: 0
  }
}

const getDataForText = ({id, text, x = 0, y = 0, rotation = 0, fontSize = 20, width = 200}) => {
  return {
    id,
    text,
    x,
    y,
    fontSize,
    fill: 'red',
    rotation,
    scaleX: 1,
    scaleY: 1,
    status: 'created',
    width,
  }
}

const getTargetId = (target) => {
  try {
    return target.id()
  } catch {
    return ''
  }
}

const getDataForGroup = ({ groupId = '', box = {}, relation = {}, x = 0, y = 0, rotation = 0 }) => {
  return {
    id: groupId,
    box,
    relation,
    draggable: true,
    opacity: 1,
    x,
    y,
    rotation,
    scaleX: 1,
    scaleY: 1,
    status: 'created',
  }
}

const getDataForRemovedNode = (nodeId = '', status = '') => {
  let result = {
    id: nodeId,
    draggable: false,
    opacity: 0,
  }
  if (typeof status === 'string' && status.length) {
    result.status = status
  }
  return result
}

const getDataForLine = ({ id = '', pos = {} }) => {
  const {x, y} = pos
  return {
    id,
    points: [x, y, x, y],
    stroke: 'red',
    status: 'created'
  }
}

export default {
  getDataForBgImg,
  getDataForCenterAnchor,
  getDataForTextMarker,
  getDataForText,
  getTargetId,
  getDataForGroup,
  getDataForRemovedNode,
  getDataForLine,
}