import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Template = RepositoryFactory.get('evaluationTemplate')
const namespaced = true
import vendor from '@/store/modules/vendor'
import get from 'lodash-es/get'
import dayjs from 'dayjs'

const state = {
  templates: [],
  template: {},
  selectedTemplate: {},
  indicators: [],
  updateAt: '', //ghi theo bản cập nhập trong cấu hình chung, lấy ngày ra cập nhập trong bản template cập nhập cuối cùng
  classId: '',
}

const actions = {
  // Tạo form đánh giá thường
  async create(_, template) {
    const res = await Template.create({...template, vendorId: vendor.state.vendor.vendorId})
    return res.data
  },

  //Tạo form đánh giá tự ngẫu
  async createCustomTemplate(_, template) {
    const res = await Template.createCustomTemplate({...template, vendorId: vendor.state.vendor.vendorId})
    return res.data
  },

  async fetchAllTemplates({commit}, params = {}) {
    const res = await Template.fetch({...params})
    commit('setTemplates', res.data)
    return res.data
  },

  async fetchTemplate({commit}, id) {
    const res = await Template.fetchOne(id)
    commit('setTemplate', res.data)
    return res.data
  },

  async updateTemplate({commit}, {id, ...template}) {
    const res = await Template.update(id, {...template, vendorId: vendor.state.vendor.vendorId})
    commit('updateTemplate', res.data)
    return res.data
  },

  async removeTemplate({commit}, id) {
    await Template.remove(id)
    commit('removeTemplate', id)
  },
  setSelectedTemplate({commit}, template) {
    commit('setSelectedTemplate', template)
  },
  setIndicators({commit}, indicator) {
    commit('setIndicators', indicator)
  },
  refreshIndicator({commit}) {
    commit('refreshIndicator')
  },
  removeItemUpdate({commit}, indicatorId) {
    commit('removeItemUpdate', indicatorId)
  },
  updateIndicatorsData({commit}, data) {
    commit('updateIndicatorsData', data)
  },
  setUpdateAt({commit}, date) {
    commit('setUpdateAt', date)
  },
  setClassId({commit}, id) {
    commit('setClassId', id)
  },
  refreshListToUpdate({commit}) {
    commit('refreshListToUpdate')
  },
  updateTitleIndicators({commit}, data) {
    commit('updateTitleIndicators', data)
  },
  updateRequiredIndicators({commit}, data) {
    commit('updateRequiredIndicators', data)
  },
  setIndicatorsPosition({commit}, list) {
    commit('setIndicatorsPosition', list)
  },
}

const mutations = {
  setTemplates(state, templates) {
    state.templates = templates
  },
  setTemplate(state, template) {
    state.template = template
    state.indicators = get(template, 'indicators', [])
  },
  updateTemplate(state, template) {
    const index = state.templates.findIndex((v) => v.id === template.id)
    if (index > -1) state.templates.splice(index, 1, template)
  },
  removeTemplate(state, id) {
    const index = state.templates.findIndex((v) => v.id === id)
    if (index > -1) state.templates.splice(index, 1)
  },
  setSelectedTemplate(state, template) {
    state.selectedTemplate = template
  },
  setIndicators(state, indicator) {
    state.indicators.push(indicator)
  },
  refreshListToUpdate(state) {
    state.indicators = []
  },
  removeItemUpdate(state, id) {
    const index = state.indicators.findIndex((v) => v.id === id)
    if (index > -1) state.indicators.splice(index, 1)
  },
  updateIndicatorsData(state, data) {
    const index = state.indicators.findIndex((v) => v.id === data.id)
    if (index > -1) {
      state.indicators[index].data = {
        questions: data.data,
        answers: null,
      }
    }
  },
  setUpdateAt(state, date) {
    const formatDate = dayjs(date).format('DD/MM/YYYY')
    const formatTime = dayjs(date).format('HH:mm')
    state.updateAt = `${formatDate}, lúc ${formatTime}`
  },
  setClassId(state, id) {
    state.classId = id
  },
  updateTitleIndicators(state, data) {
    const index = state.indicators.findIndex((v) => v.id === data.id)
    if (index > -1) state.indicators[index].title = data.title
  },
  updateRequiredIndicators(state, data) {
    const index = state.indicators.findIndex((v) => v.id === data.id)
    if (index > -1) {
      state.indicators[index].isRequired = data.isRequired
    }
  },
  setIndicatorsPosition(state, list) {
    if (Array.isArray(list) && list.length) state.indicators = list
  },
}

const getters = {
  getTemplates: (state) => state.templates,
  getTemplate: (state) => state.template,
  getSelectedTemplate: (state) => state.selectedTemplate,
  getIndicators: (state) => state.indicators,
  getUpdateAt: (state) => state.updateAt,
  getClassId: (state) => state.classId,
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
