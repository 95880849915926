<template>
  <v-dialog :value="isOpen" persistent max-width="800px">
    <v-card>
      <v-card-text class="pa-4">
        <BaseEditor
          v-if="isOpen"
          ref="editor"
          @text-change="onTextChange"
          :init-data="tempHtml"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-2">
        <v-spacer></v-spacer>
        <BaseButton
          isButtonText
          :text="$vuetify.lang.t('$vuetify.COURSE_CONTENT.BUTTON_CANCEL_TEXT')"
          @click="$emit('close')"
        />
        <BaseButton
          className="mr-2"
          :text="$vuetify.lang.t('$vuetify.COURSE_CONTENT.BUTTON_OK')"
          @click="$emit('accept', tempHtml)"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    content: String,
    isOpen: Boolean,
  },
  data() {
    return {
      tempHtml: "",
    };
  },
  methods: {
    onTextChange(html) {
      this.tempHtml = html;
    },
  },
  watch: {
    content: {
      handler(val) {
        this.tempHtml = val;
      }, immediate: true
    },
  },
};
</script>
