import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_EXAM + '/question'

const fetch = params => {
	return Repository(`${baseURL}/admin`).fetch(params)
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const create = params => {
	return Repository(baseURL).create(params)
}

const remove = id => {
	return Repository(baseURL).delete(id)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const count = params => {
	return Repository(`${baseURL}/count`).fetch(params)
}

export default {
	create,
	remove,
	update,
	fetch,
	fetchOne,
	count
}
