<template>
  <v-dialog v-model="dialog" width="400px" scrollable>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <div class="text">
          {{ textFirst }}
        </div>
        <div class="text">
          {{ textSecond }}
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="button-area">
          <v-btn @click="() => closeDialog()" outlined color="primary" depressed
            ><span class="text-none">
              {{ 'Đóng' }}
            </span></v-btn
          >
        </div>
      </v-card-actions>
      <v-divider />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    textFirst: {
      type: String,
      default: '',
    },
    textSecond: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
.button-area {
  width: 100%;
  height: auto;
  text-align: end;
  padding-bottom: 8px;
}

.text {
  font-size: 14px;
  color: #797979;
}
</style>
