import { RepositoryFactory } from '@/api/factory/repositoryFactory'
import _ from 'lodash-es'
const Mail = RepositoryFactory.get('mail')
const namespaced = true

const state = {
  account: {},
  template: {},
  vnpay: {},
  momo: {},
  alepay: {},
  sendedMail: [],
  mails: [],
  totalItems: 0,
  pageText: '',
  itemsPerPage: 10,
}

const actions = {
  async setupAccountMail({ commit }, params = {}) {
    const res = await Mail.setupAccountMail(params)
    if (res.data.gmail) commit('setAccount', res.data.gmail)
    return res.data
  },
  async getAccountMail({ commit }) {
    const res = await Mail.getAccountMail()
    if (res.data && res.data.gmail) commit('setAccount', res.data.gmail)
    if (res.data && res.data.vnpay) commit('setAccountVnpay', res.data.vnpay)
    if (res.data && res.data.momo) commit('setAccountMomo', res.data.momo)
    if (res.data && res.data.alepay) commit('setAccountAlePay', res.data.alepay)
    return res.data
  },
  async createOrUpdateTemplate({ commit }, params = {}) {
    const res = await Mail.createOrUpdateTemplate(params)
    commit('setTemplate', [res.data])
    return res.data
  },
  async getTemplates({ commit }, params = {}) {
    const res = await Mail.getTemplates(params)
    commit('setTemplate', res.data)
    return res.data
  },
  async sendMail({ commit, dispatch }, params = {}) {
    const res = await Mail.sendMail(params)
    await dispatch('getMailByPagination', { relationId: params.relationId })
    commit('saveMail', res.data)
  },
  async sendMailToGroup({ commit, state, dispatch }, params = {}) {
    const res = await Mail.sendMailToGroup(params)
    await dispatch('getMailByPagination', { relationId: params.groupId })
    commit('saveMail', [res.data, ...state.sendedMail])
  },
  async getMail({ commit }, params = {}) {
    const res = await Mail.getMail(params)
    commit('saveMail', res.data)
  },
  async sendMailVerify(_, params = {}) {
    return await Mail.sendMailVerify(params)
  },
  async checkMailVerifiled(_, params = {}) {
    var res = await Mail.checkMailVerifiled(params)
    return res.data
  },
  async confirmMailVerifiled({ commit }, params = {}) {
    var res = await Mail.confirmMailVerifiled(params)
    if (res.data.gmail) commit('setAccount', res.data.gmail)
    return res.data
  },
  async getTemplateEmail(_, params) {
    var res = await Mail.getTemplateEmail(params)
    return res.data
  },
  async updateDefaultTemplateEmail(_, params = {}) {
    var res = await Mail.updateDefaultTemplateEmail(params)
    return res.data
  },
  async rollbackTemplateDefaultEmail(_, params = {}) {
    var res = await Mail.rollbackTemplateDefaultEmail(params)
    return res.data
  },
  async getTemplateDefault(_, params = {}) {
    var res = await Mail.getTemplateDefault(params)
    return res.data
  },
  async fetchMails({ commit }, params) {
    let countParams = { ...params, _limit: null, _skip: null }
    const [totalItems, mails] = await Promise.all([
      Mail.countMail(countParams),
      Mail.getMail(params),
    ])
    const promises = mails.data.map(async (mail) => {
      mail.html = mail.html.replace('{{name}}', mail.student.name)
      mail.html = mail.html.replace('{{email}}', mail.student.email)
      mail.html = mail.html.replace('{{phone}}', mail.student.phone)
      return mail
    })
    let emails = await Promise.all(promises)
    commit('setMails', emails)
    commit('setTotalItem', totalItems.data)
  },
  async findEmail({ dispatch }, { relationId, tags }) {
    await dispatch('getMailByPagination', { relationId, tags })
  },
  async getMailByPagination(
    { state, dispatch },
    { page, itemsPerPage, relationId, tags }
  ) {
    if (!page) page = 1
    if (!itemsPerPage) itemsPerPage = state.itemsPerPage
    let params = {
      _skip: (page - 1) * itemsPerPage,
      _limit: itemsPerPage == -1 ? null : itemsPerPage,
      relationId,
      tags: tags
        ? _.join(
          _.split(tags.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), ' '),
          '-'
        )
        : '',
    }
    await dispatch('fetchMails', params)
    await dispatch('setPageText', { page, itemsPerPage })
  },
  setPageText({ state, commit }, { page, itemsPerPage }) {
    const pageStart = (page - 1) * itemsPerPage + 1
    let pageStop = page * itemsPerPage
    pageStop =
      pageStop > state.totalItems || pageStop === -1
        ? state.totalItems
        : pageStop
    let pageText = `${pageStart}-${pageStop} trên ${state.totalItems}`
    commit('setPageText', pageText)
  },
}

const mutations = {
  setAccount(state, account) {
    return (state.account = account)
  },
  setAccountVnpay(state, account) {
    return (state.vnpay = account)
  },
  setAccountMomo(state, account) {
    return (state.momo = account)
  },
  setAccountAlePay(state, account) {
    return (state.alepay = account)
  },
  setTemplate(state, templates) {
    templates.forEach((template) => {
      state.template = {
        ...state.template,
        [template.name]: template.template,
      }
    })
  },
  saveMail(state, mail) {
    state.sendedMail = mail
  },
  setMails(state, value) {
    return (state.mails = value)
  },
  setPageText(state, value) {
    return (state.pageText = value)
  },
  setTotalItem(state, value) {
    return (state.totalItems = value)
  },
}

const getters = {
  getAccount: (state) => {
    return state.account
  },
  getAccountVnpay: (state) => {
    return state.vnpay
  },
  getAccountMomo: (state) => {
    return state.momo
  },
  getAccountAlePay: (state) => {
    return state.alepay
  },
  getTemplate: (state) => {
    return state.template
  },
  getMail: (state) => {
    return state.sendedMail
  },
  getMails: (state) => {
    return state.mails
  },
  getPageText: (state) => {
    return state.pageText
  },
  getTotalItem: (state) => {
    return state.totalItems
  },
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
