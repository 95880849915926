const ONLINE_COURSE_REPORT = {
	TITLE: 'オンラインコース',
	COURSE_UNIT: 'コース',
	LABEL_PUBLISHED_COURSE: '発行済み',
	LABEL_FREE_COURSE: '無料',
	LABEL_PAID_COURSE: '有料'
}
const TOP_COURSE_REPORT_CHART = {
	TITLE: "トップ人気のコース",
	LABEL_COURSE_OTHER: 'その他',
	COURSE_UNIT: 'コース'
}
const TOTAL_NUMBER_STUDENTS_REPORT = {
	TITLE: "ユーザー総数",
	STUDENT_UNIT: 'ユーザー',
	LABEL_STUDENT_SELF_REGISTER: '自己登録',
	LABEL_STUDENT_CREATED_BY_ADMIN: '作成されたユーザー',
	LABEL_INTERACTION: 'インタラクションあり'
}
const STUDENT_REPORT_CHART = {
	TITLE: '週の増加',
	LABEL_NEW_STUDENT: '新しいユーザー',
	LABEL_UNVERIFY: 'アクティベート待ち'
}
const TOTAL_INCOME_REPORT = {
	TITLE: '総売上',
	LABEL_TRANSFER: '振込',
	LABEL_VNPAYBANK: 'オンライン',
	LABEL_DIRECPAY: '直接',
	LABEL_COD: 'COD',
	CURRENCY_UNIT: 'ベトナムドン'
}
const INCOME_REPORT_CHART = {
	TITLE: '今週の増加分',
	LABEL_BILLS: '注文',
	LABEL_INCOME: '売上'
}
const TOTAL_DATA_SIZE_REPORT = {
	TITLE: '容量'
}
const DOCCUMENT_UPLOAD_FILE_REPORT = {
	TITLE: 'アップロード資料',
	FILE_UNIT: 'ファイル'
}
const VIDEO_VIEWS_REPORT = {
	TITLE: "閲覧回数",
	VIEWS_UNIT: '回',
	LABEL_VIEWS: '直近の月間動画再生数'
}
const VIDEO_BAND_WIDTH_USED_REPORT = {
	TITLE: "帯域幅",
	LABEL_BAND_WIDTH: '今月使用された動画帯域幅',
	LABEL_BAND_WIDTH_NOTE: '(当月の初日から計算します。)'
}
const NEW_STUDENT_REPORT = {
	TITLE: '新しいユーザー',
	LINK_TO_LIST_STUDENT: 'もっと見る',
	TH_STUDENT: 'ユーザー',
	TH_EMAIL: 'メールアドレス',
	TH_PHONE_NUMBER: '電話番号',
	TH_STUDENT_CREATED_BY: '作成者',
	TH_CREATE_TIME: '作成時間',
	TEXT_ADMIN: '管理者',
	TEXT_STUDENT_SELF_CREATED: '自己登録',
}
export default {
	ONLINE_COURSE_REPORT,
	TOP_COURSE_REPORT_CHART,
	TOTAL_NUMBER_STUDENTS_REPORT,
	STUDENT_REPORT_CHART,
	TOTAL_INCOME_REPORT,
	INCOME_REPORT_CHART,
	TOTAL_DATA_SIZE_REPORT,
	DOCCUMENT_UPLOAD_FILE_REPORT,
	VIDEO_VIEWS_REPORT,
	VIDEO_BAND_WIDTH_USED_REPORT,
	NEW_STUDENT_REPORT,
	//MESSAGE
	MSG_GET_DATA_LOADING: 'データを取得しています。',
	LABEL_UNDEFINED:"未定",
}