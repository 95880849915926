import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const learningPath = RepositoryFactory.get('learningPath')

const state = {
  path: {},
  paths: [],
  count: 0,
};

const actions = {
  async countPaths({commit}, params = {}) {
		const res = await learningPath.count(params)
		commit('setCount', res.data || 0)
	},
  async fetchPaths({ commit }, params) {
    const res = await learningPath.fetch(params)
    commit('setPaths', res.data || [])
    return res.data || []
  },
  async fetchPath({ commit }, id) {
    const res = await learningPath.fetchOne(id)
    commit('setPath', res.data || {})
    return res.data || {}
  },
  async createPath({ commit }, params) {
    const res = await learningPath.create(params)
    commit('addPath', res.data || {})
    return res.data || {}
  },
  async updatePath({commit}, { id, ...params }) {
    const res = await learningPath.update(id, params)
    commit('updatePath', res.data || {})
    return res.data
  },
  async removePath({ commit }, { id }) {
    const res = await learningPath.remove(id)
    commit('removePath', id)
    return res.data
  },
}

const mutations = {
  setPaths(state, list) {
    return (state.paths = list.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    ));
  },
  setPath(state, path) {
    state.path = path
  },
  updatePath(state, path) {
    state.path = path
    return (state.paths = {
      ...state.paths,
      [path.id]: path,
    });
  },
  setCount(state, count) {
    state.count = count
  },
  addPath(state, path) {
    state.count = state.count + 1;
    return (state.paths = {
      [path.id]: path,
      ...state.paths,
    });
  },
  removePath(state, id) {
    delete state.paths[id];
    state.paths = { ...state.paths };
  },
};

const getters = {
  getPaths: (state) => {
    let paths = Object.values(state.paths);
    return paths.sort((a,b)=> Date.parse(b.createdAt) - Date.parse(a.createdAt))
  },
  getPath: (state) => state.path,
  getCount: (state) => state.count
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
