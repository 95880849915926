//MANAGE ACTIVATION CODE
const ACTIVATION_CODE_CAMPAIGN_LIST = {
	TITLE: 'Activation Code',
	BUTTON_ADD: 'Add Activation Code',
	LABEL_FILTER_BY_NAME: 'Program name',
	PLACEHOLDER_FILTER_BY_NAME: 'Search by program name',
	BUTTON_IMPORT_EXPORT_DATA_BY_EXCEL: 'Excel Import/Export',
	BUTTON_IMPORT_DATA_BY_EXCEL_FILE: 'Import Excel file',
	BUTTON_DOWNLOAD_EXCEL_TEMPLATE: 'Download Excel file',
	BUTTON_EXPORT_DATA_TO_EXCEL_FILE: 'Export Excel file',
	// TABLE
	TH_NAME: 'Program Name',
	TH_COURSE_NAME: 'Course name',
	TH_EXPIRED_AT: 'Expiry date',
	TH_CODE_QUANTITY: 'Code used',
	TH_CODE_QUANTITY_DIVIDE_TOTAL: 'Codes Used / Total Codes Available',
	TH_AVAILABLE_DATE: 'Start date',
	TH_ACTION: 'Action',
	TOOLTIP_EDIT_ACTIVE_CODE: 'Edit Activation Code',
	// EXPORTING DATA
	EXPORTING_DATA_TH_CODE: 'Activation Code',
	// IMPORTING DATA
	MSG_IMPORT_FAIL_READING_FILE_FAIL: 'There was an error reading the file, please try again!',
	MSG_IMPORT_FAIL_EMPTY_FILE: 'Please upload file with content',
	MSG_IMPORT_FAIL_INCORRECT_ROWS: 'Wrong lines in file: {0}',
	// EMPTY DATA
	TITLE_EMPTY_DATA: 'There is no program for activation code',
	SUBTITLE_EMPTY_DATA: 'Please create a new one to display on this list'
}
const FORM_CREATE_NEW_ACTIVATION_CODE_CAMPAIGN = {
	TITLE: 'Create New',
	LINK_RETURN_TO_CAMPAGIN_LIST: 'Program List',
	BUTTON_ADD: 'Add Program',
	MSG_CREATING_CODE: 'Creating a program...',
	MSG_CREATE_CODE_SUCCESS: 'Create program successfully',
	// BASIC_INFO
	TITLE_CAMPAIGN_INFOS: 'Program Information',
	LABE_CAMPAIGN_NAME: 'Program Name',
	PLACEHOLDER_CAMPAGIN_NAME: 'Enter program name',
	LABEL_COURSE_TYPE: 'Course Type',
	TEXT_COURSE_TYPE_ONLINE: 'Online',
	LABEL_COURSE: 'Course to be applied',
	PLACEHOLDER_COURSE: 'Select a course',
	MSG_CHECK_INFO_AGAIN: 'Please check the information again',
	// ADVANCED_INFO
	TITLE_CODE_INFOS: 'Activation Code Information',
	CHECKBOX_REUSABLE_CODE: 'Multiple Use Code',
	LABEL_CODE_QUANTITY: 'Number of codes to generate',
	PLACEHOLDER_CODE_QUANTITY: 'Enter the number of codes you want to generate',
	LABEL_AVAILABLE_DATE: 'Start time (0h 00)',
	PLACEHOLDER_AVAILABLE_DATE: 'Select start time',
	LABEL_EXPIRATION_DATE: 'End time (0h 00)',
	PLACEHOLDER_EXPIRATION_DATE: 'Select end time',
	MSG_CHECK_TIME_INFO_AGAIN: 'Please check the time again!'
}
const CAMPAIGN_ACTIVATION_CODE_DETAIL = {
	LINK_RETURN_TO_CAMPAGIN_LIST: 'Program List',
	// TAB
	TAB_CAMPAIGN_INFO: 'Program Information',
	TAB_CODE_LIST: 'Code List',
	// BASIC_INFO
	TITLE_CAMPAIGN_INFOS: 'Program Information',
	LABEL_COURSE: 'Lock enabled',
	LABEL_COURSE_TYPE: 'Course Type',
	TEXT_COURSE_TYPE_ONLINE: 'online',
	LABEL_REUSABLE_CODE: 'Reusable Code',
	TEXT_STATUS_ACTIVE: 'Activate',
	TEXT_STATUS_INACTIVE: 'Inactive',
	LABEL_AVAILABLE_AT: 'Effective from',
	LABEL_EXPIRED_AT: 'Expiry date',
	LABEL_CODE_QUANTITY: 'Total Code',
	LABEL_USED_CODE_QUANTITY: 'Code used',
	// TRANSACTIONS
	TITLE_TRANSACTIONS: 'Transaction history',
	TH_CODE: 'Code',
	TH_USED_AT: 'Activation Date',
	TH_STUDENT_NAME: 'Student Name',
	TH_STUDENT_EMAIL: 'Email',
	TH_STUDENT_PHONE: 'Phone number',
	// AVAILABLE CODES
	TITLE_AVAILABLE_CODES: 'Code not activated',
	TH_AVAILABLE_CODE: 'Code',
	TH_ACTION: 'Action',
	// MESSAGE
	MSG_COPY_SUCCESS: 'Copy Successful!',
	MSG_COPY_FAIL: 'Copy failed. Please try again!'
}
//MANAGE COUPON
const COUPON_CAMPAIGN_LIST = {
	TITLE: 'Coupon',
	BUTTON_ADD: 'Add Coupon',
	LABEL_NAME: 'Program name',
	PLACEHOLDER_NAME: 'Search by program name',
	// TABLE
	TH_NAME: 'Program Name',
	TH_COURSE_TYPE: 'Course Type',
	TH_DISCOUNT_VALUE: 'Promotion',
	TH_AVAILABLE_AT: 'Start',
	TH_EXPIRED_AT: 'End',
	TH_USED_CODE: 'Code used',
	TH_ACTION: 'Action',
	TOOLTIP_EDIT_COUPON: 'Edit Coupon',
	// EMPTY DATA
	TITLE_EMPTY_DATA: 'There is no discount code program yet',
	SUBTITLE_EMPTY_DATA: 'Please create a new one to display on this list'
}
const FORM_CREATE_NEW_COUPON_CAMPAIGN = {
	TITLE: 'Create New',
	LINK_RETURN_TO_COUPON_LIST: 'Program List',
	BUTTON_SAVE: 'Save Program',
	// MASSAGE
	MSG_CREATING_CODE: 'Creating a program...',
	MSG_CREATE_SUCCESS: 'Program creation successful',
	// BASIC INFO
	TITLE_BASIC_INFOS: 'Basic Info',
	LABEL_CAMPAIGN_NAME: 'Program Name',
	PLACEHOLDER_CAMPAIGN_NAME: 'Enter program name',
	LABEL_COURSE_TYPE: 'Course Type',
	PLACEHOLDER_COURSE_TYPE: 'Select Course Type',
	TEXT_COURSE_TYPE_ONLINE: 'Online',
	TEXT_COURSE_TYPE_OFFLINE: 'Offline',
	LABEL_COURSE: 'Course to be applied',
	PLACEHOLDER_COURSE: 'Select a course',
	MSG_CHECK_BASIC_INFO_AGAIN: 'Check the basics again!',
	// DISCOUNT INFO
	TITLE_DISCOUNT_INFOS: 'Promotion info',
	LABEL_COURSE_PROCE: 'Original Price',
	LABEL_DISCOUNT_VALUE: 'Promotional value',
	PLACEHOLDER_DISCCOUNT_VALUE: 'Enter promotional value',
	LABEL_CODE_QUANTITY: 'Total Code',
	PLACEHOLDER_CODE_QUANTITY: 'Enter the total number of codes you want to generate',
	LABEL_AVAILABLE_DATE: 'Start time',
	PLACEHOLDER_AVAILABLE_DATE: 'Select start time',
	LABEL_EXPIRATION_DATE: 'End time',
	PLACEHOLDER_EXPIRATION_DATE: 'Select end time',
	MSG_CHECK_DISCOUNT_INFO_AGAIN: 'Please check the promotion information again!'
}
const COUPON_CAMPAIGN_DETAIL = {
	LINK_RETURN_TO_CAMPAGIN_LIST: 'Program List',
	// TAB
	TAB_CAMPPAIGN_INFO: 'Program Information',
	TAB_CODE_LIST: 'Code List',
	// BASIC INFO
	TITLE_CAMPAIGN_INFOS: 'Program Information',
	LABEL_CAMPAIGN_NAME: 'Program Name',
	LABEL_COURSE_TYPE: 'Course Type',
	PLACEHOLDER_COURSE_TYPE: 'Select Course Type',
	TEXT_COURSE_TYPE_ONLINE: 'Online',
	TEXT_COURSE_TYPE_OFFLINE: 'Offline',
	LABEL_COURSE: 'Course to be applied',
	PLACEHOLDER_COURSE: 'Select a course',
	TEXT_COURSE_NOT_SET_YET: 'No course selected',
	MSG_DATA_LOADING: 'Loading data...',
	MSG_CHECK_BASIC_INFO_AGAIN: 'Check the basics again!',
	MSG_PROMOTION_INFO_AGAIN: 'Please check the promotion information again!',
	// DISCOUNT INFO
	TITLE_DISCOUNT_INFOS: 'Promotional content',
	LABEL_COURSE_PRICE: 'Original Price',
	LABEL_DISCOUNT_VALUE: 'Promotional value',
	TEXT_CURRENCY_UNIT: 'đ',
	LABEL_CODE_QUANTITY: 'Total Code',
	LABEL_USED_CODE_QUANTITY: 'Code used',
	LABEL_AVAILABLE_AT: 'Start time',
	LABEL_EXPIRATION_AT: 'End time',
	LABEL_TIME: 'Time',
	MSG_CHECK_INFO_AGAIN: 'Please check the promotion information again!',
	// USED CODES
	LABEL_USED_CODE: 'Code used',
	LABEL_CODE: 'Discount Code',
	PLACEHOLDER_CODE: 'Search by code',
	BUTTON_VIEW_MORE: 'View More',
	TH_CODE: 'Code',
	TH_ACTION: 'Action',
	TH_USED_AT: 'Used Date',
	TH_STUDENT_ACCOUNT: 'User Account',
	TH_BILL_TOTAL_VALUE: 'Order value (đ)',
	TH_DISCOUNT_VALUE: 'Promotion',
	TH_CODE_STATUS: 'Status',
	TH_VIEW_BILL: 'Order',
	TEXT_CODE_STATUS_USED: 'Used',
	TEXT_CODE_STATUS_NOT_USED_YET: 'Unused',
	MSG_COPY_CODE_SUCCESS: 'Copy Successful!',
	MSG_COPY_CODE_FAIL: 'Copy failed. Please try again',
	// BUTTON
	BUTTON_CANCEL: 'Cancel',
	// MASSAGE
	MSG_UPDATING: 'Updating information...',
	MSG_UPDATE_SUCCESS: 'Update successful',
	// DELETE
	TITLE_DELETE_ACTION: 'Delete program',
	SUBTITLE_DELETE_ACTION:
		'After deleting the program, all unused codes are automatically disabled.',
	BUTTON_DELETE: 'Delete program',
	POPUP_DELETION_CONFIRM_TITLE: 'Delete program',
	POPUP_DELETION_COMFIRM_MESSAGE:
		'Data after deletion cannot be recovered. Are you sure you want to delete this sale?',
	MSG_DELETE_SUCCESS: 'Delete successful!'
}
//MANAGE COMBO
const COMBO_CAMPAIGN_LIST = {
	TITLE: 'Combo',
	BUTTON_ADD: 'Add Combo',
	LABEL_FILTER_BY_NAME: 'Program name',
	PLACEHOLDER_FILTER_BY_NAME: 'Search by program name',
	// TABLE
	TH_NAME: 'Program Name',
	TH_DISCOUNT_VALUE: 'Value',
	TH_AVAILABLE_AT: 'Start',
	TH_EXPIRED_AT: 'Expires',
	TH_ACTION: 'Action',
	// DELETE ACTION
	POPUP_CONFIRM_DELETION_TITLE: 'Delete Combo',
	POPUP_CONFIRM_DELETION_MESSAGE:
		'Data after deletion cannot be recovered. Are you sure you want to delete this combo?',
	// MESSAGE
	MSG_DELETING: 'Deleting...',
	MSG_DELETE_SUCCESS: 'Delete combo successful'
}
const COMBO_CAMPAIGN_DETAIL = {
	LINK_RETURN_TO_COMBO_LIST: 'Program List',
	// BASIC INFO
	TITLE_CAMPAIGN_INFOS: 'Basic Info',
	LABEL_NAME: 'Combo name',
	LABEL_AVAILABLE_TIME: 'Start time',
	LABEL_EXPIRED_TIME: 'End time',
	// BASIC INFO EDIT FORM CODE
	PLACEHOLDER_IMPORT_NAME: 'Enter combo name',
	PLACEHOLDER_AVAILABLE_TIME: 'Select start time',
	PLACEHOLDER_EXPIRED_TIME: 'Select end time',
	MSG_UPDATE_SUCCESS: 'Combo update successful',
	// CONDITION APPLY COMBO
	TITLE_CONDITION: 'Conditions apply',
	SUBTITLE_CONDITION:
		"When the student's cart is eligible, this discount Combo will be applied. Applicable conditions can only be set while creating a combo, not modified after it has been saved.",
	LABEL_MIN_NUMBER_OF_COURSES: 'Minimum course',
	LABEL_PRICE_APPLY: 'Applicable amount',
	LABEL_COURSE_APPLY: 'Applied course',
	BUTTON_ADD_CONDITION: 'Add condition',
	//VALUE COMBO
	TITLE_VALUE: 'Combo value',
	SUBTITLE_VALUE: 'You cannot change combo value after initialization.',
	LABEL_CURRENCY_UNIT: 'VND',
	//DELETE COMBO
	TITLE_DELETION: 'Delete Combo',
	SUBTITLE_DELETION:
		'Students can still access the course purchased through the combo package. Combo information on Order Management and Sales will still be saved.',
	BUTTON_DELETE: 'Delete combo',
	POPUP_CONFIRM_DELETE_TITLE: 'Delete Combo',
	POPUP_CONFIRM_DELETE_MESSAGE:
		'Data after deletion cannot be recovered. Are you sure you want to delete this combo?',
	MSG_DELETING: 'Deleting...',
	MSG_DELETE_SUCCESS: 'Delete combo successful',
	MSG_UPDATING: 'Updating...',

	COMBO_PUBLISHER: {
		TITLE: "Publishing",
		SUBTITLE_ACTIVATION: 'Combo will only be applied when activated.',
		SUBTITLE_HIDE: 'This combo will be hidden from the web',
		BUTTON_ACTIVE: 'Activate',
		BUTTON_BUTTON_ACTIVE: 'Pause',
	}
}
const FORM_CREATE_NEW_COMBO_CAMPAIGN = {
	TITLE: 'Create New',
	LINK_RETURN_TO_COMBO_LIST: 'Program List',
	//BASIC INFO
	TITLE_BASIC_INFO: 'Basic Info',
	LABEL_NAME: 'Combo name',
	PLACEHOLDER_INPUT_NAME: 'Enter combo name',
	LABEL_AVAILABLE_DATE: 'Start time',
	PLACEHOLDER_SELECT_AVAILABLE_DATE: 'Select start time',
	LABEL_EXPIRATION_DATE: 'End time',
	PLACEHOLDER_SELECT_EXPIRATION_DATE: 'Select end time',
	MSG_UPDATE_SUCCESS: 'Combo update successful',
	// CONDITION
	TITLE_CONDITION: 'Conditions apply',
	SUBTITLE_CONDITION:
		"When the student's cart is eligible, this discount Combo will be applied. Applicable conditions can only be set while creating a combo, not modified after it has been saved.",
	BUTTON_ADD_CONDITION: 'Add condition',

	//VALUE
	TITLE_DISCOUNT_INFOS: 'Program Value',
	SUBTITLE_COMBO_VALUE:
		'You cannot change program values ​​after initialization.',
	OPTION_DISCOUNT_BY_FIXED_AMOUNT: 'Currency discount',
	HINT_DISCOUNT_BY_FIXED_AMOUNT:
		'The e-Payment Gateway cannot make transactions under 10.000VNĐ. Please set a higher value',
	PLACEHOLDER_DISCOUNT_VALUE: 'Amount to be reduced',
	PLACEHOLDER_INPUT_DISCOUNT_AMOUNT_VALUE: 'Amount to be reduced',
	OPTION_DISCOUNT_BY_PERCENT: 'Percentage discount',
	PLACEHOLDER_INPUT_DISCOUNT_PERCENT_VALUE: 'Decrease by %',
	// MESSAGE
	MSG_CREATING: 'Combining...',
	MSG_CREATE_SUCCESS: 'Combination successful',

}
const POPUP_ADD_CONDITION = {
	TITLE: 'Add condition',
	TEXT_NOTE:
		"When a student's cart is eligible, this discount combo will be applied.",
	//
	OPTION_COURSE_QUANTITY: 'Number of courses required to qualify',
	PLACEHOLDER_INPUT_COURSE_QUANTITY: 'Enter the number of courses in the order',
	//
	OPTION_BILL_VALUE: 'Minimum amount to qualify',
	LABEL_CURRENCY_UNIT: 'VND',
	HINT_INPUT_BILL_VALUE: 'Please enter an amount greater than 10.000VNĐ',
	//
	OPTION_SELECTED_COURSES:
		'Apply combo when buying the following keys at a time',
	LABEL_COURSE: 'Course',
	PLACEHOLDER_SELECT_COURSE: 'Select Course',
	POPUP_CONFIRM_REMOVE_COURSE: 'Remove this course from condition?',
	MSG_REMOVING_COURSE: 'Course removal',
	MSG_REMOVING_SUCCESS: 'Successful Course Removal',
	//
	BUTTON_SAVE_CONDITION: 'Save condition',
	// MESSAGE
	MSG_CHECK_INFO_AGAIN: 'Please check the information again!',
	MSG_COURSES_IS_REQUIRED: 'Please select a course!',
	MSG_ADDING_COURSE: 'Adding courses...',
	MSG_ADD_COURSE_SUCCESS: 'Course added successfully!',
	MSG_ADD_COURSE_FAIL:
		'Adding Course Failed, Cannot Add Existing Course!',
	MSG_CONDITION_IS_REQUIRED: 'Please select a condition that applies!'
}
//MANAGE BILL
const BILL_LIST = {
	TITLE: 'Manage Orders',
	LABEL_FILTER_BY_EMAIL_STUDENT: 'Student email',
	PLACEHOLDER_INPUT_EMAIL_STUDENT: 'Search by student email',
	LABEL_AVAILABLE_AND_EXPIRED_DATE: 'Start Date - End Date',
	PLACEHOLDER_INPUT_SEARCH_TIME: 'Search by interval',
	LABEL_STATUS_BILL: 'Single Status',
	TEXT_STATUS_ALL: 'All',
	TEXT_STATUS_WAITTING: 'Waiting',
	TEXT_STATUS_FAIL: 'Failure',
	TEXT_STATUS_COMPLATED: 'Done',
	TEXT_STATUS_REFUSED: 'Rejected',
	TEXT_STATUS_PAID: 'Paid',
	// TABLE
	TH_STUDENT: 'Students',
	TH_CONTENT: 'Content',
	TH_TIME: 'Time',
	TH_PAYMENT_METHOD: 'Payment',
	TH_VALUE: 'Unit Price',
	TH_STATUS: 'Status',
	TH_ACTION: 'Action',
	TEXT_STUDENT_CONTACT_INFO: 'Contact Information',
	TEXT_COURSE_UNIT: 'Course',
	TEXT_EBOOK: 'Document',
	TEXT_OTHER_PRODUCT: 'Product',
	//BILL TABLE DETAIL
	TH_BILL_VALUE_BEFORE_DISCOUNT: 'Pre-promotion price (đ)',
	TH_BILL_VALUE_AFTER_DISCOUNT: 'Price after promotion (đ)',
	TEXT_RECEIVER_ADDRESS: 'Address',
	TEXT_RECEIVER_NAME: 'Recipient Name',
	TEXT_RECEIVER_PHONE: 'Phone number',
	TEXT_EMAIL: "Email",
	TEXT_PRICE_BEFORE_DISCOUNT: 'Temporary',
	TEXT_COMBO_IS_APPLIED: 'Apply Combo',
	TEXT_COUPON_IS_APPLIED: 'Apply Coupon',
	TEXT_FINAL_PRICE: 'Total amount',
	//
	BUTTON_REFUND_ALL: 'Refund all',
	BUTTON_REJECT_ALL: 'Deny All',
	BUTTON_APPROVE_ALL: 'Approved All',
	BUTTON_REFUND: 'Refund',
	BUTTON_REJECT: 'Deny',
	//
	POPUP_CONFIRM_REFUND_BILL_TITLE: 'Order Return?',
	POPUP_CONFIRM_REFUND_BILL_MESSAGE:
		'Refundable orders will not be able to edit the status. Students who own products on the application will also lose access. Orders will not be recorded in sales in the last period.',
	//
	POPUP_CONFIRM_APPROVE_BILL_TITLE: 'Confirm invoice paid?',
	POPUP_CONFIRM_APPROVE_BILL_MESSAGE:
		'After confirming the invoice, the student will have the right to study the course',

	POPUP_CONFIRM_REJECT_BILL_TITLE: 'Order rejected',
	POPUP_CONFIRM_REJECT_BILL_MESSAGE:
		'Rejected orders will not be reviewed and will not be recorded in sales.',
	//
	POPUP_CONFIRM_REFUND_MANY_BILLS_TITLE: 'Refund all orders?',
	POPUP_CONFIRM_REFUND_MANY_BILLS_MESSAGE:
		'Refundable orders will not be able to edit the status. Students who own products on the application will also lose access. Orders will not be recorded in sales in the last period.',
	//
	POPUP_CONFIRM_APPROVE_MANY_BILLS_TITLE:
		'Confirm all paid invoices?',
	POPUP_CONFIRM_APPROVE_MANY_BILLS_MESSAGE:
		'After confirming the invoice, the student will have the right to study the course',
	//
	POPUP_CONFIRM_REJECT_MANY_BILLS_TITLE: 'Reject all orders',
	POPUP_CONFIRM_REJECT_MANY_BILLS_MESSAGE:
		'Rejected orders will not be reviewed and will not be recorded in sales.',
	//MESSAGE
	MSG_UPDATING: 'Updating data...',
	MSG_UPDATE_SUCCESS: 'Data update successful!'
}
// MANAGE TRANSACTION
const TRANSACTION_LIST = {
	TITLE: 'Sales',
	//STATISTIC
	TITLE_STATISTIC: 'Statistics',
	LABEL_TIME_BY_MONTH: 'Monthly',
	LABEL_NUMBER_OF_BILLS: 'Invoice in the month',
	LABEL_REVENUE: 'Real revenue received',
	LABEL_NUMBER_OF_SUCCESS_BILLS: 'Successful application',
	TITLE_TRANSACTION_LIST: 'Details',
	TH_STUDENT: 'Buyer',
	TH_VALUE: 'Price',
	TH_CREATED_DATE: 'Date Created',
	TH_STATUS: 'Status',
	TEXT_STATUS_SUCCES: 'Success',
	TEXT_STATUS_WAITING: 'Waiting',
	TEXT_STATUS_FAIL: 'Failure',
	TEXT_CURRENT_UNIT: 'd'
}
//MANAGE TEACHER TRANSACTION
const TEACHER_TRANSACTION_LIST = {
	TITLE: "Teacher Sales",
	TITLE_STATISTIC: 'Statistics',
	LABEL_BILL_QUANTITY: "Invoice of the month",
	LABEL_REVENUE: 'Revenue',
	LABEL_COURSE_QUANTITY_SOLD_IN_SIX_MONTH: "Number of courses sold in 6 months",
	LABEL_TOTAL_BILL: "Total Single",
	LABEL_REVENUE_IN_SIX_MONTH: "Sales in 6 months",
	LABEL_TOTAL_REVENUE: "Total Revenue",
	LABEL_CURRENCY_UNIT: "(VNĐ)",
	LABEL_STATISTICS_WITH_SUCCESSFUL_ORDERS: "Statistics applied to orders with a successful status",
	GRAPH_STATISTIC: {
		LABEL_REVENUE: "Revenue (thousand)",
		LABEL_REGISTER_QUANTITY: "Number of registrations",
	}
}
//MANAGE CONTACT
const CONTACT_LIST = {
	TITLE: 'Contact',
	TAB_CONTACT_WITHIN_BILL: 'Order',
	TAB_CONTACT_WITHIN_CONTACT_FORM: 'Contact',
	//
	LABEL_FILTER_BY_TIME: 'Time filter',
	PLACEHOLDER_INPUT_TIME: 'Filter interval',
	OPTION_TIME_SCOPE_WITHIN_THE_MONTH: 'Within 1 month',
	OPTION_TIME_SCOPE_UNLIMITED: 'All',
	// ORDER
	LABEL_FILTER_BY_PRODUCT_NAME: 'Product Name',
	PLACEHOLDER_INPUT_PRODUCT_NAME: 'Enter product name',
	TH_EMAIL: 'Email',
	TH_PHONE: 'Phone',
	MSG_FILTERING_BILL: "Filtering menu...",
	// CONTACT
	LABEL_SEARCH_BY_INFOS: 'Search for a contact',
	PLACEHOLDER_INPUT_INFOS: 'Enter information according to the table below',
	TH_DATE: 'Date',
	TH_STUDENT_NAME: 'Student Name',
	TH_PRODUCT_NAME: "Product name of interest",
	MSG_FILTERING: 'Filtering contacts',
	//TH_EMAIL: 'Mail',
	//TH_PHONE: 'Phone',
	//MSG_FILTERING: 'Filtering contacts'
}
//MANAGE AFFILIATE
const AFFILIATE_LIST = {
	TITLE: 'Manage Affiliate',
	BUTTON_ADD: 'Add KOL',
	LABEL_FILTER_BY_NAME_EMAIL: 'KOL Name',
	PLACEHOLDER_INPUT_NAME_EMAIL: 'Search by name and email KOL',
	TH_NAME: 'KOL Name',
	TH_EMAIL: 'Email',
	TH_CREATED_DATE: 'Date Created',
	TH_STATUS: 'Status',
	TH_ACTION: 'Action',
	TOOLTIP_EDIT_AFFILIATE: 'Edit Affiliate',
	TEXT_STATUS_CONFIRMED: 'Confirmed',
	TEXT_STATUS_UNCONFIRMED: 'Unconfirmed',
	POPUP_CONFIRM_DELETE_TITLE: 'Remove KOL from the system',
	POPUP_CONFIRM_DELETE_MESSAGE: 'Data after deletion cannot be recovered',
	//MESSAGE
	MSG_DELETE_SUCCESS: 'KOL deleted successfully!',
	// EMPTY DATA
	TITLE_EMPTY_DATA: 'No KOLs yet',
	SUBTITLE_EMPTY_DATA: 'Please create a new KOL to display on this list'
}
const FORM_CREATE_NEW_AFFILIATE = {
	TITLE: 'Create a new KOL',
	LABEL_FULL_NAME: 'First and last name',
	PLACEHOLDER_INPUT_FULL_NAME: 'Enter first and last name of KOL',
	LABEL_PHONE: 'Phone number',
	PLACEHOLDER_INPUT_PHONE: 'Enter KOL phone number',
	LABEL_EMAIL: 'Email',
	PLACEHOLDER_INPUT_EMAIL: "Enter KOL's email",
	LABEL_PASSWORD: 'Password',
	PLACEHOLDER_INPUT_PASSWORD: 'Enter Password',
	LABEL_CONFIRM_PASSWORD: 'Re-enter password',
	PLACEHOLDER_INPUT_CONFIRM_PASSWORD: 'Re-enter password',
	LABEL_DESCRIPTION: "Description of KOL",
	PLACEHOLDER_DESCRIPTION: "Enter a description",
	TITLE_AVATA: "Picture",
	MSG_CREATE_SUCCESS: 'KOL creation successful!',
	MSG_CHECK_INFO_AGAIN: 'Please review the info field!'
}
const AFFILIATE_DETAIL = {
	TITLE: "KOL details",
	LINK_RETURN_TO_AFILIATE_LIST: 'Affiliate List',
	TAB_CONFIGURATION: 'Configuration',
	TAB_REPORT: 'Report',
	// CONFIG_KOL
	TITLE_CONFIGURATION: 'Configuration',
	SUBTITLE_CONFIGURATION: 'Configuration settings for KOL',
	LABEL_FULL_NAME: 'First and last name',
	PLACEHOLDER_INPUT_FULL_NAME: 'Enter first and last name of KOL',
	LABEL_PHONE: 'Phone number',
	PLACEHOLDER_INPUT_PHONE: "Enter KOL's phone number",
	LABEL_EMAIL: 'Email',
	PLACEHOLDER_INPUT_EMAIL: 'Ex: hanoiltv@gmail.com',
	TEXT_LIST: "List",
	TEXT_INTRODUCED: "Referred",
	LABEL_PASSWORD: "Password",
	PLACEHOLDER_INPUT_PASSWORD: "Enter password",
	LABEL_INPUT_PASSWORD_AGAIN: "Re-enter password",
	PLACEHOLDER_INPUT_PASSWORD_AGAIN: "Re-enter password",
	LABEL_DESCRIPTION_KOL: "KOL Description",
	PLACEHOLDER_INPUT_DESCRIPTION: "Enter a description",
	TITLE_AVATA: "Picture",

	//TAB
	TAB_ONLINE_COURSE: 'Online Course',
	TAB_OFFLINE_COURSE: 'Offline Course',
	TAB_EBOOK: 'E-book',
	TAB_EVENT: 'Event',
	//
	TH_NAME: 'Program Name',
	TH_LINK: 'Link',
	TH_AVAILABLE_AT: 'Use words',
	TH_EXPIRED_AT: 'Expiry date',
	TH_COMMISSION: 'Commission',
	TH_ACTION: 'Action',

	//LIST_PRODUCT_AFFILIATE
	BUTTON_ADD: "Add a program",
	TEXT_CURRENT_UNIT: "d",

	MSG_COPY_SUCCESS: 'Copy successful',
	MSG_COPY_FAIL: 'Copy failed. Please try again',
	MSG_UPDATE_SUCCESS: 'Update successful!',
	MSG_CHECK_PASSWORD_AGAIN: 'Password does not match!',
	//MSG_CHECK_INFO_AGAIN: 'Please check the information!',
	POPUP_CONFIRM_DELETION_TITLE: 'Delete Program',
	POPUP_CONFIRM_DELETION_MESSAGE: 'Data after deletion cannot be recovered',
	MSG_DELETE_AFILIATE_SUCCES: 'Afiliate delete successful',
	//REPORT
	TITLE_REPORT: 'Report',
	SUBTITLE_REPORT: 'Statistics of program referral effectiveness from KOL',
	LABEL_TIME_BY_MONTH: 'Monthly',
	LABEL_NUMBER_OF_BILLS: 'Number of orders per month',
	LABEL_REVENUE: 'Real revenue received',
	LABEL_COMMISSION: 'Commission',
	//
	TH_BILL_STUDENT: 'Students',
	TH_BILL_CONTENT: 'Content',
	TH_BILL_VALUE: 'Unit Price(đ)',
	TH_BILL_STATUS: 'Status',
	TH_BILL_CREATED_AT: 'Time',
	TH_BILL_PAYMENT_METHOD: 'Form of payment',
	TEXT_NO_MAIL: 'No email'
}
//POPUP ADD PROGRAM FOR KOL
const POPUP_ADD_PROGRAM = {
	TITLE_CREATE: 'Create program',
	TEXT_NEW: "new",
	LABEL_COURSE: 'Select a course',
	PLACEHOLDER_SELECT_COURSE: 'Search Course',
	LABEL_EBOOK: 'Select books',
	PLACEHOLDER_SEARCH_EBOOK: 'Search for books',
	LABEL_EVENT: 'Select event',
	LABEL_DISCOUNT_TYPE: "Discount Type",
	LABEL_DISCOUNT_VALUE: "Discount Value",
	LABEL_COMMISSION_TYPE: 'Commission Style',
	OPTION_COMMISSION_PERCENT: 'Percent (%)',
	OPTION_COMMISSION_FIX_VALUE: 'Fixed (e)',
	LABEL_COMMISSION_VALUE: 'Commission Value',

	LABEL_AVAILABLE_DATE: 'Start Date',
	LABEL_AVAILABLE_HOUR: 'Start Time',
	LABEL_EXPIRED_DATE: 'End Date',
	LABEL_EXPIRED_HOUR: 'Time End',
	MSG_CREATING_AFFILIATE: 'Creating affiliate...',
	MSG_CREATE_AFFILIATE_SUCCESS: 'Affiliate creation successful!',
	MSG_CHECK_INFO_AGAIN: 'Please check the information again!',

	//BASE_TREE_SELECT_TOPIC
	TITLE_TREE_SELECT_TOPIC: "No topics selected.",
	//BaseCourseOfflinePicker
	LABEL_FILTER_COURSE: "Select a course",
	PLACEHOLDER_FILTER_COURSE: "Enter to search",
	//BaseEventPicker
	LABEL_SELECT_EVENT: "Select event",
	MSG_GETTING_EVENT: "Getting events",
	//BaseBookPicker
}
export default {
	ACTIVATION_CODE_CAMPAIGN_LIST,
	FORM_CREATE_NEW_ACTIVATION_CODE_CAMPAIGN,
	CAMPAIGN_ACTIVATION_CODE_DETAIL,
	COUPON_CAMPAIGN_LIST,
	FORM_CREATE_NEW_COUPON_CAMPAIGN,
	COUPON_CAMPAIGN_DETAIL,
	COMBO_CAMPAIGN_LIST,
	COMBO_CAMPAIGN_DETAIL,
	FORM_CREATE_NEW_COMBO_CAMPAIGN,
	BILL_LIST,
	TRANSACTION_LIST,
	TEACHER_TRANSACTION_LIST,
	POPUP_ADD_CONDITION,
	CONTACT_LIST,
	AFFILIATE_LIST,
	FORM_CREATE_NEW_AFFILIATE,
	AFFILIATE_DETAIL,
	POPUP_ADD_PROGRAM,
	BUTTON_CANCEL_TEXT: 'Cancel',
	BUTTON_CANCEL: 'Cancel',
	BUTTON_CONFIRM: 'Confirm',
	BUTTON_EDIT: 'Edit',
	BUTTON_SAVE: 'Save',
	BUTTON_ADD: 'Add',
	BUTTON_SEARCH: "Search",
	MSG_UPDATING_INFO: 'Update info...',
	MSG_UPDATE_SUCCESS: 'Update successful',
	MSG_SYSTEM_LOADING: 'The system is loading data, please try again later!',
	MSG_DATA_SYSTEM_LOADING: 'Loading data...',
	MSG_UPDATING_DATA: 'Updating data....',
	MSG_UPDATE_DATA_SUCCESS: 'Data update successful!',
	MSG_GETTING_DATA: 'Getting data...',
	MSG_CHECK_INFO_AGAIN: 'Please check the information again!',
	MSG_CHECH_KEYWORD_QUANTITY_AGAIN: 'Please choose 2 or more keys!',
	MSG_CHECK_PICK_COURSE_AGAIN: 'Please select a course!',
}
