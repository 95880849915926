import BaseConfirm from '@/global/BaseConfirm.vue'
const _initConfirmData = {
	state: false,
	title: "",
	text: "",
	okText: 'Đồng ý',
	persistent: false,
	confirmColor: "",
	cancelText: 'Huỷ',
	hideCancel: false,
	hideOk: false,
	doneButtonOutlined: false,
	cancelButtonOutlined: true,
	cancelColor: '#616161',
	done() { },
	cancel() { },
};

const confirm = {
	confirmData: { ..._initConfirmData },
	show(dialogData = {}) {
		Object.assign(this.confirmData, {
			state: true,
			confirmColor: "red",
			cancelColor: "primary",
			doneButtonOutlined: true,
	cancelButtonOutlined: false,
			hideCancel: false,
			...dialogData
		})
	},
	cancel() {
		this.confirmData.state = false
		this.confirmData.cancel()
		setTimeout(() => {
			Object.assign(this.confirmData, _initConfirmData)
		}, 500)
	}
}

export default {
	install(Vue, pluginName = '$confirm') {
		Vue.prototype[pluginName] = confirm
		Vue.component('plugin-confirm', BaseConfirm)
	}
}
export { confirm }
