import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Event = RepositoryFactory.get('event')

const state = {
  events: [],
  event: {},
};

const actions = {
  async fetchAllEvents({ commit }, params = {}) {
    const res = await Event.fetch({
      ...params,
    });
    commit("setEvents", res.data);
    return res.data;
  },
  async fetchEventById({ commit }, id) {
    const res = await Event.fetchOne(id);
    commit("setOneEvent", res.data);
    return res.data
  },
  async createEvent(_, data) {
    const res = await Event.create(data);
    return res.data
  },
  async deleteEvent({ commit }, id) {
    await Event.remove(id);
    return commit("removeEvent", id);
  },
  async updateEvent({ dispatch }, {id, ...data}) {
    const res = await Event.update(id, data);
    dispatch("fetchEventById", id);
    return res.data
  },
};

const mutations = {
  setEvents(state, events) {
    return state.events = events;
  },
  removeEvent(state, id) {
    state.events = state.events.filter(val => val.id !== id)
  },
  setOneEvent(state, event) {
    state.event = event
  }
};

const getters = {
  getAllEvents: (state) => {
    return state.events;
  },
  getOneEvent: (state) => {
    return state.event;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}