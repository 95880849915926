const state = {
  thumbnailResource: {},
  thumnailIsCoverted: '',
  hasThumbnailExisted: false,
  itemVideoUpload: {},
  isUploadDone: false,
  idVideoDeletethumbnail: '',
  tableUploadStatus: false,
}
const actions = {
  setThumbnailResource({commit}, link) {
    commit('setThumbnailResource', link)
  },
  setThumnailIsConverted({commit}, link) {
    commit('setThumnailIsConverted', link)
  },
  setThumbnailExistedStatus({commit}, status) {
    commit('setThumbnailExistedStatus', status)
  },
  setItemVideoUpload({commit}, item) {
    commit('setItemVideoUpload', item)
  },
  setUploadStatus({commit}, status) {
    commit('setUploadStatus', status)
  },
  setIdVideoDeletethumbnail({commit}, id) {
    commit('setIdVideoDeletethumbnail', id)
  },
  setTableUploadStatus({commit}, status) {
    commit('setTableUploadStatus', status)
  },
  clearItemVideoUpload({commit}) {
    commit('clearItemVideoUpload')
  },
}
const mutations = {
  setThumbnailResource(state, link) {
    state.thumbnailResource = link
  },
  setThumnailIsConverted(state, link) {
    if (link !== '') state.thumnailIsCoverted = link
  },
  setThumbnailExistedStatus(state, status) {
    state.hasThumbnailExisted = status
  },
  setItemVideoUpload(state, item) {
    state.itemVideoUpload = item
  },
  setUploadStatus(state, status) {
    state.isUploadDone = status
  },
  setIdVideoDeletethumbnail(state, id) {
    state.idVideoDeletethumbnail = id
  },
  setTableUploadStatus(state, status) {
    state.tableUploadStatus = status
  },
  clearItemVideoUpload(state) {
    state.itemVideoUpload = {}
  },
}
const getters = {
  getThumbnailResource: (state) => state.thumbnailResource,
  getThumnailIsConverted: (state) => state.setThumnailIsConverted,
  getThumbnailExistedStatus: (state) => state.hasThumbnailExisted,
  getItemVideoUpload: (state) => state.itemVideoUpload,
  getUploadStatus: (state) => state.isUploadDone,
  getIdVideoDeletethumbnail: (state) => state.idVideoDeletethumbnail,
  getTableUploadStatus: (state) => state.tableUploadStatus,
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
