import axios from 'axios'
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'

const Repository = (BASE_URL) => ({
  count: (params, option) =>
    axios.get(
      BASE_URL + '-count',
      {
        params: {...params, vendorId: vendor.state.vendor.vendorId},
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      option
    ),
  fetch: (params, option) =>
    axios.get(
      BASE_URL,
      {
        params: {...params, vendorId: vendor.state.vendor.vendorId},
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      option
    ),
  fetchOne: (id, option) =>
    axios.get(
      BASE_URL + '/' + id,
      {
        params: {vendorId: vendor.state.vendor.vendorId},
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      option
    ),
  create: (params, options) =>
    axios.post(
      BASE_URL,
      {
        ...params,
        vendorId: vendor.state.vendor.vendorId,
      },
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      options
    ),
  createMany: (params, options) =>
    axios.post(
      BASE_URL + '/create-many',
      {...params, vendorId: vendor.state.vendor.vendorId},
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      options
    ),
  update: (id, params, option) =>
    axios.put(
      BASE_URL + `${id ? `/${id}` : ''}?vendorId=${vendor.state.vendor.vendorId}`,
      params,
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      option
    ),
  updateMany: (params, option) => {
    axios.put(
      BASE_URL + `?vendorId=${vendor.state.vendor.vendorId}`,
      {...params, vendorId: vendor.state.vendor.vendorId},
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      option
    )
  },
  delete: (id, option) =>
    axios.delete(
      BASE_URL + `/${id}?vendorId=${vendor.state.vendor.vendorId}`,
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      option
    ),
  deleteMany: (option) =>
    axios.delete(
      BASE_URL + `&vendorId=${vendor.state.vendor.vendorId}`,
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      option
    ),
  download: (params) =>
    axios.get(
      BASE_URL + `?key=${params}`,
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      }
      // {responseType: 'blob'}
    ),
  // .then((response) => {

  //   const url = window.URL.createObjectURL(
  //     new Blob([response.data], {type: 'application/video'})
  //   )
  //   // const url = response.data
  //   const link = document.createElement('a')
  //   link.href = url
  //   link.setAttribute('download', 'file.mp4')
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  //   URL.revokeObjectURL(url)
  // }),
})

export default Repository
