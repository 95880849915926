//STUDENT

const STUDENT_LIST = {
  TITLE: 'ユーザー',
  BUTTON_IMPORT_EXPORT_EXCEL: 'Excelの取込・出力',
  BUTTON_ADD: 'ユーザー追加',
  BUTTON_IMPORT_EXCEL: 'Excel取込',
  BUTTON_DOWNLOAD_EXCEL_TEMPLATE: 'テンプレートダウンロード',
  BUTTON_EXPORT_FILE_EXCEL: 'Excel出力',

  TOOLTIP_EXCEL: '注：ユーザーは一回30人まで追加できます。', ///
  LABEL_FILTER_BY_NAME_AND_EMAIL: 'フィルター',
  PLACEHOLDER_FILTER_BY_NAME_AND_EMAIL: 'ユーザーの氏名とメールで検索',
  // FILTER_DETAIL
  TITLE_DETAILED_FILTER: '詳細フィルター',
  LABEL_VIEW_ALL_STUDENTS_THAT:
    '以下条件に一致する全てのユーザーを表示します。',
  PLACEHOLDER_SELECT_FILTER: 'フィルター選択',
  OPTION_FILTER_GROUP: 'グループ',
  OPTION_FILTER_INTEREST: '関心内容',
  LABEL_FILTER_VALUE_IS: '詳細：',
  LABEL_SELECT_STUDENT_GROUP: 'ユーザーグループの選択',
  PLACEHOLDER_SELECT_STUDENT_GROUP: '追加対象グループを選択してください。',
  LABEL_SELECT_INTEREST: '関心内容の選択',
  MSG_CHECK_INFO_AGAIN: 'すべての情報を入力してください。',
  // ACTIONS
  BUTTON_EDIT: '情報編集',
  BUTTON_DELETE: 'ユーザー削除',
  POPUP_CONFIRM_DELETE_TITLE: 'このユーザーを本当に削除しますか？',
  POPUP_CONFIRM_DELETE_MESSAGE:
    'ユーザーに関する情報がすべてシステムから削除されます。よろしいでしょうか？',
  MSG_DELETE_STUDENT_SUCCESS: '対象ユーザーが削除されました。',
  // EMPTY DATA
  TITLE_EMPTY_DATA: 'ユーザーグループが存在しません。',
  SUBTITLE_EMPTY_DATA: 'ユーザーグループを新規作成してください。',
  // TABLE
  TH_FULL_NAME: 'ユーザーの氏名',
  TH_EMAIL: 'メールアドレス',
  TH_PHONE: '電話番号',
  TH_CREATE_DATE: '作成日',
  TH_CREATE_FROM: '自己登録',
  TD_CREATE_FROM_VERIFIED: '確認済み',
  TD_CREATE_FROM_UNVERIFIED: '未確認',
  TH_ACTION: 'アクション',
  // BUTTON_DELETE: 'ユーザー削除',
  BUTTON_SEND_NOTIFICATION: '通知作成',
  BUTTON_ADD_TO_GROUP: 'グループに追加',

  // ADD_MANY_STUDENT_TO_GROUP
  TITLE_ADD_STUDENT_TO_GROUP: '新グループに追加',
  BUTTON_ADD_TO_EXISTED_GROUP: '既存グループに追加',
  BUTTON_CREATE_NEW_GROUP: 'グループ新規作成',
  LABEL_FILTER_NEW_GROUP: '新グループ',
  PLACEHOLDER_NEW_GROUP: '新グループの名称を入力してください。',
  PLACEHOLDER_SELECT_GROUP: '追加先グループを選択してください。',

  // BASE_SEND_NOTIFICATION_POPUP
  POPUP_SEND_NOTIFICATION_TITLE: '通知作成',
  POPUP_NOTIFICATION_HEADER_LABEL: '通知タイトル',
  POPUP_INPUT_NOTIFICATION_HEADER_PLACEHOLDER:
    '通知タイトルを入力してください。',
  POPUP_NOTIFICATION_CONTENT_LABEL: '通知内容',
  POPUP_INPUT_NOTIFICATION_CONTENT_PLACEHOLDER: '通知内容を入力してください。',
  POPUP_SEND_NOTIFICATION_BUTTON: '送信',
  POPUP_SEND_NOTIFICATION_SUCCESS_MESSAGE: 'ユーザーに通知を送信しました。',
  POPUP_CHECK_STUDENT_QUANTITY_ADD_GROUP_MESSAGE:
    '一回で追加できる人数を超えました。',

  //EXPPORT_TABLE_EXCEL
  TH_PASSWORD: 'パスワード',
  TH_BIRTHDAY: '生年月日（任意）',
  TH_PHONE_OPTIONAL: '電話番号（任意）',

  TEXT_LABEL_KEYWORD: 'キーワード',
}
const FORM_CREATE_NEW_STUDENT = {
  LINK_RETURN_TO_STUDENT_LIST: 'ユーザー一覧',
  TITLE: 'ユーザーの新規追加',

  // BASIC_INFO
  TITLE_INFO: '基本情報',
  LABEL_NAME: 'ユーザーの氏名',
  PLACEHOLDER_INPUT_NAME: 'ユーザー氏名を入力してください。',
  LABEL_EMAIL: 'メールアドレス',
  PLACEHOLDER_INPUT_EMAIL: 'メールアドレスを入力してください。',
  LABEL_PHONE_NUMBER: '電話番号',
  PLACEHOLDER_INPUT_PHONE_NUMBER: '電話番号を入力してください。',
  LABEL_BIRTH_DATE: '生年月日',
  PLACEHOLDER_INPUT_BIRTH_DATE: '生年月日を入力してください。',

  //NOTIFICATION_EMAIL
  TITLE_EMAIL_NOTIFICATION: '通知メールの送信',
  TOGGLE_SEND_NOTIFICATION:
    'アカウント発行通知をユーザのメールアドレスに送信します。',

  // EDIT_TEMPLATE_EMAIL_REGISTER

  // TITLE_EMAIL_RESGISTER: '登録メールのテンプレート',
  // TITLE_EDIT_EMAIL_REGISTER_TEMPLATE: '登録メール',
  TITLE_SAMPLE_SEND_EMAIL_TEMPLATE: '登録メール送信のテンプレート',
  SUBTITLE_SAMPLE_SEND_EMAIL_TEMPLATE: '登録メール',

  MSG_CHECK_EMAIL_REGISTER_AGAIN:
    'このメールアドレスが既に登録されています。他のメールアドレスで登録さい。',
  MSG_CREATE_SUCCESS: '学生を作成しました。',
  MSG_CREATING: 'ユーザー作成中',
  MSG_CHECK_VALID_FIELD: '入力情報を再確認してください。',
}
const STUDENT_DETAIL = {
  LINK_RETURN_TO_STUDENT_LIST: 'ユーザー一覧',
  //TAB
  TAB_INFOS: '共通情報',
  TAB_GROUP: 'ユーザーグループ',
  TAB_COURSES: 'ユーザーのコース',
  TAB_PROPERTY_EBOOK: 'ユーザーの資料',
  TAB_EDUCATION_REPORT: '学習報告',
  TAB_INTEREST: '関心内容',
  TAB_DELETE: 'ユーザー削除',

  // BASIC_INFO
  TITLE_INFO: '基本情報',
  LABEL_TOTAL_STUDENT: 'ユーザー数',
  LABEL_FOUNDING: '作成日',
  MSG_UPDATING__INFO: '更新中',
  MSG_UPDATE_INFO_SUCCESS: '更新しました。',
  MSG_RECHECK_INVALID_FIELD: '入力情報を再確認してください。',

  //GROUP OF STUDENT
  TITLE_GROUP: 'ユーザーグループ',
  LABEL_SELECT_GROUPT: 'ユーザーグループの選択',
  PLACEHOLDER_SELECT_GROUP: 'ユーザーグループを選択してください。',
  //TABLE GROUP OF STUDENT
  TH_GROUP: 'グループ',
  TH_UPDATED_AT: '更新日',
  TH_ACTION: 'アクション',

  MSG_ADDING_TO_GROUP: 'ユーザーをグループに追加しています。',
  MSG_ADD_STUDENT_TO_GROUP_SUCCESS: 'ユーザーをグループに追加しました。',
  MSG_CHECK_LIMITED_NUMBER_OF_STUDENTS: '一回で追加できる人数を超えました。',

  POPUP_CONFIRM_DELETE_OUT_GROUP_TITLE:
    'このユーザーを本当にグループから削除しますか？',
  POPUP_CONFIRM_DELETE_OUT_GROUP_MESSAGE: '削除されたデータは回復できません。',
  MSG_DELETING: 'ユーザーをグループから削除しています。',
  MSG_DELETE_STUDENT_OUT_GROUP_SUCCESS:
    'ユーザーがグループから削除されました。',

  //COURSE OF STUDENT
  TITLE_PROPERTY_COURSE: 'ユーザーのコース',
  LABEL_SELECT_COURSE: 'コース選択',
  PLACEHOLDER_FILTER_COURSE: 'コースを選択してください。',
  TH_COURSE_NAME: 'コース名',
  POPUP_DELETE_ROUTE_STUDY_OUT_SYSTEM_TITLE: '学習ルート削除',
  MSG_DELETING_COURSE: 'コースを削除しています。',
  MSG_DELETE_COURSE_SUCCESS: 'コースが削除されました。',
  MSG_ADDING_COURSE: 'ユーザーのコースを追加しています。',
  MSG_ADD_COURSE_SUCCESS: 'ユーザーのコースが追加されました。',

  // EBOOK
  TITLE_PROPERTY_EBOOK: 'ユーザーの電子書類',
  LABEL_SELECT_EBOOK: '電子書類選択',
  PLACEHOLDER_FILTER_EBOOK: '電子書類を検索してください。',
  TH_EBOOK_NAME: '電子書類名',
  TH_LAST_VIEW: '最終閲覧日時',
  POPUP_BUTTON_DELETE_EBOOK_TITLE: 'グループから資料削除',

  MSG_ADDING_EBOOK: 'ユーザーの資料を追加しています。',
  MSG_ADD_EBOOK_SUCCESS: 'ユーザーの資料が追加されました。',
  MSG_DELETING_EBOOK: 'グループから資料を削除しています。',
  MSG_DELETE_EBOOK_SUCCESS: '資料がグループから削除されました。',

  //REPORT
  TITLE_COURSE_DONE_QUANTITY: '完了コース',
  TITLE_COURSES_DONE_QUANTITY_UNIT: 'コース',
  TITLE_OVERVIEW_LAST: '最終アクセス',
  TITLE_AVAILABLE_STUDY: '学習開始',

  LABEL_TIME_UNDEFINED: '未定',
  BUTTON_VIEW: 'コースをもっと見る',
  TITLE_PROGRESS_LEARNING: '学習進捗',
  TITLE_REPORT_DETAIL: '詳細報告',
  //FILTER STUDY REPORT DETAIL
  LABEL_FILTER_COURSE: 'コース検索',
  LABEL_SELECT_LESSON_TYPE: 'レッスン種類の選択',
  OPTION_PDF_SLIDE: 'PDFスライド',
  OPTION_EXAM: 'テスト',
  OPTION_HOMEWORK: '宿題',
  OPTION_VIDEO: '動画',
  HINT_SELECT_COURSE_EXERCISE_COURSE:
    'コースとレッスン種類を選択してください。',
  BUTTON_FILTER: 'フィルター',

  //TABLE STUDY REPORT EXAM
  TH_EXERCISE: '問題',
  TH_EXERCISE_TYPE: '問題種類',
  TH_MARK: '点数',
  //TABLE STUDY REPORT HOMEWORK
  TH_HOMEWORK_NAME: '宿題名',
  TH_TIME: '日時',
  TH_STATE: '状態',
  //TABLE STUDY REPORT VIDEO OR PDF
  TH_LESSON_NAME: 'レッスン名',

  //INTEREST
  TITLE_INTEREST: 'ユーザーの関心内容',
  TAB_TOPIC: 'トピック',
  TAB_ONLINE_COURSE: 'オンラインコース',
  TAB_OFFLINE_COURSE: 'オフラインコース',
  TAB_EBOOK: '電子書籍',
  TAB_EVENT: 'イベント',
  TITLE_LIST: '関心内容一覧',
  TH_INTERES_NAME: '関心内容の名称',
  TH_UPDATE: '更新',

  BUTTON_UPDATE: '更新',
  POPUP_TITLE_DELETE_INTEREST: 'この関心内容を本当に削除しますか？',
  POPUP_CREATE_NEW_INTEREST_TITLE: '{0}関心内容の新規作成',
  MSG_CREATING_INTEREST: '関心内容を作成しています。',
  MSG_CREATE_SUCCESS: '関心内容が作成されました。',

  //DELETION
  TITLE_DETELE: 'ユーザー削除',
  SUBTITLE_DELETE: '削除されたユーザーは回復できません。',
  BUTTON_DELETE: 'ユーザー削除',
  POPUP_CONFIRM_DELETE_TITLE: 'このユーザーを本当に削除しますか？',
  MSG_DELETE_SUCCESS: 'ユーザーが削除されました。',
}
//GROUP OF STUDENT
const GROUP_STUDENT_LIST = {
  TITLE: 'ユーザーグループ',
  BUTTON_ADD: 'グループ新規作成',
  LABEL_NAME: 'グループ名',
  PLACEHOLDER_NAME: 'グループ名で検索してください。',

  //EMPTY_DATA
  TITLE_EMPTY_DATA: 'ユーザーグループが存在しません。',
  SUBTITLE_EMPTY_DATA: 'ユーザーグループを新規作成してください。',

  TH_NAME: 'グループ名',
  TH_STUDENT_NUMBER: 'ユーザー数',
  TH_TOTAL_COURSE: 'コース数',
  TH_UPDATE: '更新',
  TH_ACTION: 'アクション',
  TOOLTIP_EDIT_GROUP_STUDENT: '学生グループの編集',
  MSG_REMOVING_STUDENT: 'ユーザーをグループから削除しています。',
  MSG_REMOVE_STUDENT_SUCCESS: 'ユーザーがグループから削除されました。',
  LABEL_FILTER_GROUP_BY_NAME: 'ユーザーグループ名',
  PLACEHOLDER_FILTER_GROUP_BY_NAME: 'ユーザーグループ名を入力してください。',
}
const FORM_CREATE_NEW_GROUP_STUDENT = {
  TITLE: 'グループ新規作成',
  LABEL_NAME: 'ユーザーグループ名',
  PLACEHOLDER_NAME: 'ユーザーグループ名を入力してください。',
  MSG_CREATING: 'グループを作成しています。',
  MSG_CREATE_SUCCESS: 'ユーザーグループが作成されました。',
  MSG_CHECK_NAME_AGAIN: '研究会名が使用されています',
  MSG_CREATE_FAIL: '失敗研究会の作成',
  //MESSAGE
  MSG_ADDING_STUDENT_TO_GROUP: 'ユーザーを追加しています。',
  MSG_ADD_STUDENT_TO_GROUP_SUCCESS: 'ユーザーをグループに追加しました。',
  MSG_ADD_STUDENT_FAIL: '一回で追加できる人数を超えました。',
}
const GROUP_STUDENT_DETAIL = {
  LINK_RETURN_TO_GROUP_STUDENT_LIST: 'ユーザーグループ一覧',
  //TAB
  TAB_INFO: '情報',
  TAB_STUDENT: '学生のリスト',
  TAB_COURSE: 'コースのリスト',
  TAB_E_BOOK: '電子書籍リスト',
  TAB_REPORT: 'レポート',
  TAB_ADVANCED: '詳細',
  TAB_SEND_NOTICE: '通知',
  TAB_EMAIL: 'Eメール',
  TAB_DELETE: '削除',
  //BASIC_INFO
  TITLE_INFO: '基本情報',
  LABEL_NAME: 'グループ名',
  LABEL_STUDENT_QUANTITY: 'ユーザー数',
  LABEL_FOUNDING_DATE: '作成日',

  MSG_UPDATE_SUCCESS: '更新されました。',
  MSG_UPDATE_FAIL: '更新に失敗しました。',

  //STUDENT_ASSIGNED
  TITLE_STUDENT_LIST: 'ユーザー一覧',
  BUTTON_IMPORT_EXPORT_EXCEL: 'Excelの取込・出力',
  BUTTON_IMPORT_FILE_EXCEL: 'Excel取込',
  BUTTON_DOWNLOAD_FILE_EXCEL: ' Excelダウンロード',
  BUTTON_EXPORT_FILE_EXCEL: 'Excel出力',

  LABEL_PICK_STUDENT: 'ユーザー選択',
  LABEL_PICK_ALL_STUDENT:'グループ内のすべての生徒に送信を選択',
  PLACEHOLDER_INPUT_STUDENT: 'ユーザーの氏名を入力してください。',
  MSG_ADDING_STUDENT: 'ユーザーをグループに追加しています。',
  MSG_ADD_STUDENT_SUCCESS: 'ユーザーがグループに追加されました。',

  TH_TIME_SEND: '送信日',
  TH_STATUS: 'ステータス',
  TH_STUDENT: 'ユーザー名',
  TH_EMAIL: 'メールアドレス',
  TH_ACTION: 'アクション',
  TH_ACTION_ITEM: '詳細を表示',
  TOOLTIP_IMPORT_EXCEL_MAXIMUM_STUDENT_NUMBER: '注：一回',
  TEXT_STUDENT_EVERY_TIMES: '人まで追加できます。',
  // TABLE EXPORT EXCEL SUDENT
  TH_FULL_NAME: 'ユーザー氏名',
  // TH_EMAIL: 'メールアドレス',
  TH_PHONE_STUDENT: '電話番号',
  TH_PASSWORD: 'パスワード',
  POPUP_DELETE_STUDENT_TITLE: 'グループからユーザー削除',
  MSG_REMOVING_STUDENT: 'ユーザーをグループから削除しています。',
  MSG_REMOVING_STUDENT_SUCCESS: 'ユーザーがグループから削除されました。',

  //COURSE_ASSIGNED
  TITLE_COURSE_ASSIGNED: '付与したコース',
  LABEL_FILTER_COURSE_ASSIGNED: 'コース選択',
  PLACEHOLDER_FILTER_COUSRE: 'コースを検索してください。',
  ENTITY_COURSE_FOR_STUDENT: 'ユーザーのコース',
  TH_COURSE_NAME: 'コース名',
  POPUP_TITLE_DELETE_COURSE: 'グループからコース削除',

  MSG_UPDATING_COURSE_FOR_STUDENT:
    'ユーザーのコースを{0}から{1}まで更新しています',
  MSG_ADDING_COURSE: 'グループにコースを追加しています。',
  MSG_ADD_COURSE_SUCCESS: 'コースがグループに追加されました。',
  MSG_DELETING_COURSE: 'コースをグループから削除しています。',
  MSG_DELETE_COURSE_SUCCESS: 'コースがグループから削除されました。',

  //EBOOK_ASSIGNED
  TITLE_DOCUMENT_ASSIGNED: '付与した資料',
  LABEL_FILTER_BOOK: '資料選択',
  PLACEHOLDER_FILTER_EBOOK: '資料を検索してください。',
  TH_EBOOK_NAME: '資料名',
  POPUP_DELETE_EBOOK_TITLE: 'グループから資料削除',
  MSG_ADDING_EBOOK: '資料をグループに追加しています。',
  MSG_ADD_EBOOK_SUCCESS: '資料がグループに追加されました。',
  MSG_DELETING_EBOOK: '資料をグループから削除しています。',
  MSG_DELETING_EBOOK_SUCCESS: '資料がグループから削除されました。',

  //NOTIFICATION_EMAIL
  PLACEHOLDER_INPUT_STUDENT_NOTIFICATION: '学生または役職で検索',
  // HISTORY_SEND_EMAIL
  TITLE_HISTORY_SEND_EMAIL: 'メール送信履歴',
  BUTTON_SEND_EMAIL: 'メール作成',
  TITLE_HISTORY_SEND_NOTIFICATION: '通知送信履歴',
  MESSAGE_NO_DATA_YET: 'データがありません。',
  BUTTON_SEND_NOTIFICATION: '通知作成',
  TH_HEADER: 'タイトル',
  TH_EMAIL_TITLE: 'メールタイトル',
  TH_SEND_EMAIL_TO: '送信先',
  TH_SEND_EMAIL_ALL: 'グループ全体へ',
  TH_EMAIL_SENDING_TIME: '送信日時',
  TH_EMAIL_RECIPIENTS_EMAIL_QUANTITY: '既読/重心者の総数',
  //SEND_EMAIL
  TITLE_SEND_EMAIL_IN_GROUP: 'グループ送信のメール',
  TITLE_SEND_EMAIL: 'メール作成',
  LABEL_EMAIL_SUBJECTS: 'タイトル',
  PLACEHOLDER_EMAIL_SUBJECTS: 'メールタイトルを入力してください。',
  LABEL_EMAIL_TEMPLATE: 'メールテンプレート',
  BUTTON_EMAIL_HTML_TEMPLATE: 'HTMLテンプレート使用',
  BUTTON_IMPORT_FROM_EDITOR: 'エディターから取り込む',

  MESSAGE_SAVE_EMAIL_SUCCESS: 'メールが保存されました。',
  MESSAGE_WARNING_INFOS: '情報を入力してください。',
  MESSAGE_SEND_EMAIL_SUCCESS: 'メールが送信されました。',
  MESSAGE_CHECK_INVALID_FIELD: '情報を再確認してください。',
  MSG_SEND_NOTIFICATION_FOR_GROUP_SUCCESS: '通知がグループに送信されました。',
  //DELETE GROUP STUDENT
  TITLE_DELETE: 'ユーザーグループ削除',
  SUBTITLE_DELETE: '削除されたユーザーグループは回復できません。',
  BUTTON_DELETE: 'ユーザーグループ削除',
  POPUP_CONFIRM_DELETE_STUDENT_TITLE: 'ユーザーグループ削除',
  POPUP_CONFIRM_DELETE_STUDENT_MESSAGE:
    '削除されたユーザーグループは回復できません。',
  MSG_DELETING: 'ユーザーグループを削除しています。',
  MSG_DELETE_SUCCESS: 'ユーザーグループが削除されました',
  MSG_FAIL_AS_EXCEED_LIMITATION:
    'ユーザー数が上限（50人）を超えています。まずユーザーをグループから削除してください。',
}
//TEACHER
const TEACHER_LIST = {
  TITLE: '講師',
  BUTTON_ADD: '講師の追加',
  LABEL_FILTER_BY_NAME_EMAIL: '講師氏名',
  PLACEHOLDER_FILTER_BY_NAME_EMAIL:
    '講師氏名とメールアドレスで検索してください',

  TH_TEACHER: '講師',
  TH_EMAIL: 'メールアドレス',
  TH_ACTION: 'アクション',
  TOOLTIP_EDIT_TEACHER: 'インストラクターを編集',
  EMPTY_DATA_TITLE: 'まだインストラクターはいません',
  EMPTY_DATA_SUBTITLE:
    'このリストに表示する新しいインストラクターを作成してください',
}
const FORM_CREATE_NEW_TEACHER = {
  TITLE: '新しい講師の追加',
  LINK_RETURN_TO_TEACHER_LIST: '講師一覧',

  //BASIC_INFO
  TITLE_BASIC_INFO: '基本情報',
  LABEL_TEACHER_NAME: '講師氏名',
  PLACEHOLDER_TEACHER_NAME: '講師氏名を入力してください',
  LABEL_PHONE: '電話番号',
  PLACEHOLDER_PHONE: '電話番号を入力してください',
  LABEL_EMAIL: 'メールアドレス',
  PLACEHOLDER_EMAIL: 'メールアドレスを入力してください',
  LABEL_INPUT_DESCRIPTION: '簡単な説明',
  PLACEHOLDER_INPUT_DESCRIPTION: '講師の簡単な説明を入力してください',
  HINT_INPUT_DESCRIPTION: '推奨長さ: 200文字',

  //DESCRIPTION
  TITLE_DESCRIPTION: '詳細説明',

  MSG_CREATE_SUCCESS: '講師は作成されました。',
  MSG_CREATE_FAIL: '講師は作成できませんでした!',
  MSG_REGISTER_EMAIL_EXISTED:
    '既に登録されたメールアドレスです。他のメールアドレスで試してください。!',
}
const TEACHER_DETAIL = {
  LINK_RETURN_TO_TEACHER_LIST: '講師一覧',

  //TAB
  TAB_INFO: '共通情報',
  TAB_COURSE_ASSIGNED: '割り当てられたコース',
  TAB_STUDENT_ASSIGNED: '学生が割り当てられます',
  TAB_TEST: '割り当てられたテスト',
  TAB_KNOWLEDGE: '知識の枠組み',
  TAB_BOOK: '講師の本',
  TAB_DELETE: '講師削除',

  // COURSE OF TEACHER
  TITLE_ASSIGN_COURSE: '割り当てられたコース',
  LABEL_SELECT_COURSE: 'コース選択',
  PLACEHOLDER_FILTER_COURSE: 'コースを選択してください。',
  POPUP_DELETE_ROUTE_STUDY_OUT_SYSTEM_TITLE: 'システムからコースを削除する ',
  MSG_DELETING_COURSE: 'コースを削除しています...',
  MSG_DELETE_COURSE_SUCCESS: 'コースが削除されました。',
  MSG_ASSIGN_COURSE: '教師に割り当てられたコース...',
  MSG_ASSIGN_COURSE_SUCCESS: '成功した教師のためのコースを割り当てました',
  MSG_ASSIGN_COURSE_FAIL: '割り当てられたコースの失敗: {0}',

  // MARK_DIVITION
  TITLE_MARK_DIVITION_LIST: '採点を割り当てられるテスト一覧',
  LABEL_PICK_COURSE: 'コース選択',
  PLACEHOLDER_PICK_COURSE: 'コースを検索してください',
  LABEL_PICK_TEST: 'テスト選択',
  TEXT_NO_DATA: 'データなし',

  TH_COURSE_NAME: 'コース名',
  TH_TEST_REQUEST: 'オンデマンドテスト',
  TH_PUBLIC_DATE: '公開日',
  TH_ACTION: 'アクション',

  LABEL_FILTER_BY_UNIT: '要求によるテスト選択',
  BUTTON_ASSIGN: '割り当てる',

  MSG_ASSIGN_SUCCESS: '採点する講師が割り当てられました。',
  MSG_ASSIGN_FAIL: '講師が割り当てられません。. 後で試してください。',
  MSG_DELETE_ASSIGN_SUCCESS: '講師の割り当てが解除されました',

  //DELETION
  TITLE_DELETE: '講師削除',
  SUBTITLE_DELETE: '削除された講師は回復できません。',
  BUTTON_DELETE: '講師削除',

  POPUP_CONFIRM_DELETE_TITLE: 'この講師を削除します。よろしいでしょうか?',
  POPUP_CONFIRM_DELETE_MESSAGE:
    '講師に関する情報がすべてシステムから削除されます。よろしいでしょうか？',
  MSG_DELETE_SUCCESS: '講師は削除されました',
}
const TEACHER_SALE = {
  TITLE: '講師の売上',
  LABEL_STATISTICAL: '集計',
  OPTION_BILL_IN_MONTH: '月度の請求書',
  OPTION_INCOME: '売上',
  //TABLE HEADER
  TH_BUYER: '購入者',
  TH_PRICE: '価格',
  TH_CREATE_AT: '作成日',
  TH_COUPON: 'クーポン',
  TH_COMBO: 'コンボ',
  TH_CODE_TYPE: 'クーポン / コンボ / アクティベーションコード',
  TH_AFFILIATE: 'アフィリエイト',
  TH_BEFORE_DISCOUNT: '原価',
  TH_TOTAL_PRICE: '合計',

  TH_STATUS: '状態',
  TEXT_CURRENT_UNIT: 'ベトナムドン',
  TEXT_USER: '使用あり',
  TEXT_NOT_USE: '使用なし',
  TEXT_SUCCESS: '合格',
  TEXT_WAITING: '待機中',
  TEXT_FAIL: '不合格',
}
//COMMENT OF STUDENT
const COMMENT_LIST = {
  TITLE: '議論',
  LABEL_INPUT_COURSE_NAME: 'コース名',
  PLACEHOLDER_INPUT_COURSE_NAME: 'コース名を入力してください',
  LABEL_TIME: '開始日-終了日',
  PLACEHOLDER_FILTER_TIME: 'フィルタリングする時間を選択してください',
  LABEL_STATUS: '状態',
  PLACEHOLDER_SELECT_STATUS: '状態を選択してください',
  OPTION_FILTER_ALL_STATUS: '全状態',
  OPTION_FILTER_PUBLISH: '承認済み',
  OPTION_FILTER_UNPUBLISH: '未承認',
  OPTION_FILTER_WAIT: '承認待ち',
  BUTTON_APPROVE_MULTI: '全部承認',

  POPUB_TITLE_DELETE: 'コメント削除の確認',
  POPUB_SUBTITLE_DELETE: 'このコメントを削除します。よろしいでしょうか?',
  BUTTON_DELETE: '削除',

  TH_COURSE: 'コース',
  TH_CONTENT: '内容',
  TH_END_UPDATE: '最後の更新',
  TH_STATUS: '状態',
  TH_ACTION: 'アクション',
  TEXT_UNDEFINED: '未定',
  TEXT_APPROVED: '承認済み',

  //POPUP
  POPUP_CONFIRM_APPROVE_TITLE: 'コメント承認の確認',
  POPUP_CONFIRM_APPROVE_ONE_MESSAGE:
    'このコメントを承認します。よろしいでしょうか?',

  POPUP_CONFIRM_APPROVE_MULTI_TITLE: '全コメント承認の確認',
  POPUP_CONFIRM_APPROVE_MULTI_MESSAGE:
    '全てのコメントを承認します。よろしいでしょうか?',

  POPUP_CONFIRM_DELETE_TITLE: 'コメント削除の確認',
  POPUP_CONFIRM_DELETE_MESSAGE: 'このコメントを削除します。よろしいでしょうか?',

  POPUP_CONFIRM_DELETE_MULTI_TITLE: '全コメント削除の確認',
  POPUP_CONFIRM_DELETE_MULTI_MESSAGE:
    '全てのコメントを削除します。よろしいでしょうか?',

  //MESSAGE
  MSG_APPROVE_SUCCESS: '議論は承認されました',
  MSG_APPROVE_MULTI_SUCCESS: '全ての議論は承認されました',
  MSG_DELETE_SUCCESS: '議論は削除されました',
  MSG_DELETE_MULTI_SUCCESS: '全ての議論は削除されました',
  MESSAGE_REQUEST_PENDING: '受け取っています',
  MESSAGE_REQUEST_SUCCESS: '成功しました。',
}
//RATING OF STUDENT
const RATING_LIST = {
  TITLE: '評価',
  //TAB
  TAB_ONLINE_COURSE: 'オンラインコース',
  TAB_LEARNING_PATH: '勉強ルート',
  TAB_EBOK: '電子書籍',

  LABEL_NAME: '名称',
  PLACEHOLDE_INPUT_NAME: '名称を入力してください。',
  LABEL_COUSE: 'コース',
  LABEL_LEARNING_PATH: '学習ルート',
  LABEL_EBOOK: '電子書類',
  // ONLINE_COURSE
  LABEL_COURSE_NAME: 'コース名',
  PLACEHOLDER_COURSE_NAME: 'コース名を入力してください。',

  // LEARNING_PATH
  LABEL_LEARNING_PATH_NAME: 'ルート名',
  PLACEHOLDER_LEARNING_PATH_NAME: 'ルート名を入力してください。',

  // EBOOK
  LABEL_EBOOK_NAME: 'ドキュメント名',
  PLACEHOLDER_NAME: 'ドキュメント名を入力してください',

  LABEL_FILTER_INDICATION_TIME: '開始日 - 終了日',
  PLACEHOLDER_FILTER_INDICATION_TIME:
    'フィルタリングする時間を選択してください',
  LABEL_STATUS: '状態',
  PLACEHOLDER_SELECT_STATUS: '状態を選択してください',
  OPTION_FILTER_ALL_STATUS: '全状態',
  OPTION_FILTER_PUBLISH: '承認済み',
  OPTION_FILTER_UNPUBLISH: '未承認',
  OPTION_FILTER_WAIT: '承認待ち',

  POPUP_CONFIRM_APPROVE_ONE_TITLE: 'レビューを閲覧しますか？',
  POPUP_CONFIRM_APPROVE_ONE_DESCRIPTION:
    'このレビューを閲覧してもよろしいですか？',

  POPUP_CONFIRM_APPROVE_ALL_TITLE: 'すべてのレビューを閲覧しますか？',
  POPUP_CONFIRM_APPROVE_ALL_DESCRIPTION:
    'これらすべてのレビューを閲覧してもよろしいですか？',

  POPUP_CONFIRM_DELETE_TITLE: '評価削除',
  POPUP_CONFIRM_DELETE_MESSAGE: 'この評価を削除します。よろしいでしょうか?',

  POPUP_CONFIRM_DELETE_ALL_TITLE: 'すべてのレビューを削除しますか？',
  POPUP_CONFIRM_DELETE_ALL_MESSAGE:
    'これらの貴重な投稿を削除してもよろしいですか？',

  TH_CONTENT: '内容',
  TH_END_UPDATE: '最後の更新',
  TH_STATUS: '更新',
  TH_ACTION: 'アクション',
  TEXT_APPROVED: '承認済み',
  TEXT_PENDDING: '承認待ち',
  TEXT_UNDEFINED: '未定',
  MESSAGE_REQUEST_PENDING: '受け取っています',
  MESSAGE_REQUEST_SUCCESS: '成功しました!',
  MSG_APPROVE_SUCCESS: '成功したレビューを閲覧する!',
  MSG_APPROVE_MULTI_SUCCESS: '成功したすべてのレビューを閲覧する!',
  MSG_DELETE_SUCCESS: 'レビューを正常に削除する',
  MSG_DELETE_MULTI_SUCCESS: '成功したレビューをすべて削除する',
}
//CERTIFICATE FOR COURSE
const CERTIFICATE_LIST = {
  TITLE: '証明書',
  BUTTON_ADD: '証明書の追加',
  LABEL_FILTER_BY_NAME: '証明書の名称',
  PLACEHOLDER_FILTER_BY_NAME: '証明書の名称で検索してください',

  TH_NAME: '証明書の名称',
  TH_PATH_COURSE: '指定の勉強ルート・コース',
  TH_CREATE_DATE: '作成日',
  TH_GRANTED: '承諾済み',
  TH_ACTION: 'アクション',
  TOOLTIP_EDIT_CERTIFICATE: '証明書の編集',

  TITLE_EMPTY_DATA: '証明書が存在していません',
  SUBTITLE_EMPTY_DATA: 'この一覧に表示するには、投稿を新規作成してください',
}
const FORM_CREATE_NEW_CERTIFICATE = {
  LINK_RETURN_TO_LIST: '証明書一覧',
  TITLE: '新しい証明書',
  //TAB
  TAB_CONTENT: '内容',

  //BASIC_INFO
  TITLE_INFOS: '証明書情報',
  LABEL_NAME: '証明書の名称',
  PLACEHOLDER_NAME: '例：コース修了証明書',
  LABEL_TYPE: '証明書種類',
  OPTION_ONLINE: 'オンライン',
  OPTION_LEARNING_PATH: '勉強ルート',
  LABEL_FILTER_COURSE: 'コース選択',
  PLACEHOLDER_FILTER_COURSE: 'コースを検索してください',

  // ADVANCE_INFO
  LABEL_INPUT_HEADER: 'タイトル',
  PLACEHOLDER_INPUT_HEADER: '例：証明書',
  LABEL_INPUT_DESCRIPTION_STUDENT_NAME: 'ユーザー氏名',
  PLACEHOLDER_INPUT_DESCRIPTION_STUDENT_NAME: '例：ユーザー氏名',
  LABEL_INPUT_DESCRIPTION_COURNAME: 'コース名の記載',
  PLACEHOLDER_INPUT_COURNAME_TITLE: 'コースのタイトル',

  LABEL_INPUT_AVAILABEL_DATE: '発行日',
  PLACEHOLDER_INPUT_AVAILABLE_DATE: '例：発行日',
  LABEL_AVAILABLE_DATE_TITLE: '発行日のタイトル',
  PLACEHOLDER_AVAILABLE_DATE_TITLE: '発行日',
  LABEL_EXPIRED_DATE_TITLE: '有効期限',
  PLACEHOLDER_EXPIRED_DATE_TITLE: '有効期限',
  LABEL_EXPIRED_DATE: '有効期限',
  PLACEHOLDER_EXPIRED_DATE: '例：2020年12月12日',
  LABEL_CHANGE_PHOTO_DESIGN: 'デザイン写真変更',
  BUTTON_SELECT_PHOTO_FROM_LIBRARY: 'ライブラリから写真選択',
  BUTTON_UPLOAD_PHOTO: '写真アップロード',
  HINT_CHANGE_PHOTO_DESIGN: 'リコメンドサイズ：1024x726px',

  MSGH_CREATE_CERTIFICATE_SUCCESS: '証明書が発行されました。',
}
const CERTIFICATE_DETAIL = {
  LINK_RETURN_TO_CERTIFICATE_LIST: '証明書一覧',
  TAB_INFO: '共通情報',
  TAB_HISTORY_GRANT_CERTIFICATE: '証明書発行履歴',

  //BASIC_INFO
  LABEL_NAME: '証明書の名称',
  LABEL_PATH_COURSE: '指定の勉強ルート・コース',
  LABEL_CREATE_DATE: '作成日',
  //ENABLE
  TITLE_STOP_APPLICATION: '証明書の無効化',
  TITLE_APPLICATION: '証明書の有効化',
  BUTTON_STOP_APPLICATION: '無効化',
  BUTTON_APPLICATION: '有効化',
  SUBTITLE_APPLICATION_AND_STOP_APPLICATION:
    '設定したコースに対して、証明書を有効化・無効化する。',

  //DELETION
  TITLE_DELETE: '証明書削除',
  SUBTITLE_DELETE:
    '以前に証明書を取得したユーザーは結果と証明書が保持されます。',
  BUTTON_DELETE: '証明書削除',

  POPUP_CONFIRM_DELETE_TITLE: 'この証明書を本当に削除しますか？',
  POPUP_CONFIRM_DELETE_MESSAGE: '削除されたデータは回復できません。',

  //HISTORY_GRANT
  TITLE_STUDENT_LIST: '取得したユーザー一覧',
  LABEL_FILTER_BY_STUDENT_NAME: 'ユーザー氏名',
  PLACEHOLDER_FILTER_BY_STUDENT_NAME: 'ユーザー氏名で検索してください',

  TH_STUDENT_NAME: 'ユーザー氏名',
  TH_AVAILABLE_AT: '発行',
  TH_EXPIRED_AT: '有効期限',

  MSG_UPDATE_SUCCESS: '更新しました。',
  //VIEW_CERTIFICATE
  LABEL_STUDENT_NAME: 'ユーザー氏名',
  VIEW_CERTIFICATE: {
    TITLE: '証明書',
    SUBTITLE: '管理者',
    DESCRIPTION: 'コースを完了しました。',
    SUBDESCRIPTION: '発行日',
    TITLE_EXPIRED: '有効期限',
    HEADER: 'コースのタイトル',
  },
  STUDENT_HAS_CERTIFICATE_LIST: {
    TITLE: '取得したユーザー一覧',
    LABEL_FILTER_BY_NAME: 'ユーザー氏名',
    PLACEHOLDER_FILTER_BY_NAME: 'ユーザー氏名で検索してください',
    TH_NAME: 'ユーザー氏名',
    TH_ACHIEVE_DATE: '発行日',
    TH_EXPIRED: '有効期限',
  },
}
//FORM CHANGE PASSWORD USE FOR STUDENT AND TEACHER
const FORM_CHANGE_PASSWORD = {
  TITLE: 'パスワード',
  LABEL_INPUT: 'パスワード',
  PLACEHOLDER_INPUT: 'パスワードを入力してください',
  LABEL_INPUT_AGAIN: 'パスワードの再入力',
  PLACEHOLDER_INPUT_AGAIN: 'パスワードを再度入力してください',
  MSG_NOT_SAME: 'パスワードが一致しません',
  MSG_CHECK_AGAIN: 'パスワードを再度確認してください',
}
const FORM_PREVIEW_IMAGE = {
  TITLE: '表示画像',
  SUBTITLE: '推奨サイズ：300x300px',
}
export default {
  STUDENT_LIST,
  FORM_CREATE_NEW_STUDENT,
  STUDENT_DETAIL,
  GROUP_STUDENT_LIST,
  FORM_CREATE_NEW_GROUP_STUDENT,
  GROUP_STUDENT_DETAIL,
  TEACHER_LIST,
  FORM_CREATE_NEW_TEACHER,
  TEACHER_DETAIL,
  TEACHER_SALE,
  COMMENT_LIST,
  RATING_LIST,
  CERTIFICATE_LIST,
  FORM_CREATE_NEW_CERTIFICATE,
  CERTIFICATE_DETAIL,
  FORM_CHANGE_PASSWORD,
  FORM_PREVIEW_IMAGE,
  BUTTON_DELETE: '削除',
  BUTTON_SAVE: '保存',
  BUTTON_ADD: '追加',
  BUTTON_CANCEL_TEXT: 'キャンセル',
  BUTTON_CANCEL: 'キャンセル',
  BUTTON_CONFIRM: '確認',
  BUTTON_EDIT: '修正',
  BUTTON_UPDATE: '更新',
  BUTTON_RESEST: 'リセット',
  BUTTON_SEARCH: '検索',
  BUTTON_SEND: '送付',
  LABEL_LAST_TIME: '最後',
  LABEL_UNDEFINED: '未定',
  //SUBTITLE POPUP
  POPUP_CORFIRM_DELETE_DATA_MESSAGE: '削除されたデータは回復できません',
  //MESSAGE
  MSG_UPDATING: '更新中',
  MSG_UPDATE_SUCCESS: 'データは更新されました !',
  MSG_GETTING_DATA: 'データを取得しています',
  MSG_RECHECK_INVALID_FIELD: '情報を再確認してください。',
  MSG_CHECK_INFO_AGAIN: 'もう一度情報を確認してください',
  MSG_CHECK_HAS_EXISTED: 'このコースの認定は存在します',
  MSG_CREATE_CERTIFICATE_FAIL: '証明書の生成に失敗しました',
  ENTITY_STUDENT: 'ユーザー',
}
