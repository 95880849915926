export default {
    HINT_TIME_NOT_INVALID:"Thời gian chưa hợp lệ",
    HINT_INFO_OBLIGATORY:"Thông tin bắt buộc",
    HINT_NO_WHITESPACES: "Không có khoảng trắng",
    HINT_PERCENTAGE: "Giá trị phải nhỏ hơn 100 và lớn hơn 0",
    HINT_MAX_LENGTH: "Độ dài tối đa là {0}",
    HINT_MIN_LENGTH: "Độ dài tối thiểu là {0}",
    HINT_PHONE_NUMBER_FALSE:"Không đúng số điện thoại",
    HINT_EMAIL_FORMAT_FALSE:"Sai định dạng e-mail.",
    HINT_URL_FORMAT_FALSE: "Sai định dạng đường dẫn",
    HINT_NUMBER_FORMAT_FALSE: "Sai định dạng số",
    HINT_PRICE_FORMAT_FALSE: "Sai định dạng giá tiền",
    HINT_VALID_PRICE: "Cổng thanh toán yêu cầu giao dịch trên 10.000VNĐ !",
    HINT_NUMBER_GREATER_EQUAL: "Số lớn hơn hoặc bằng {0}",
    HINT_NUMBER_GREATER_THAN: "Số lớn hơn {0}",
    HINT_NUMBER_LESS_THAN: "Số nhỏ hơn {0}",
    HINT_NUMBER_LESS_EQUAL: "Số lớn hơn hoặc bằng {0}",
    HINT_NUMBER_CLAMP: "Số nằm giữa {0} và {1}",
    HINT_DATE_GREATER_THAN: "Ngày được chọn phải sau ngày {0}",
    HINT_DATE_LESS_THAN: "Ngày được chọn phải trước ngày {0}",
    HINT_TIME_LESS_THAN:"Thời gian bắt đầu phải trước thời gian kết thúc!",
    HINT_DOMAIN_FORMAT_FALSE:"Sai định dạng domain.",

    HINT_AT_LEAST_ONE: "Có ít nhất 1 ký tự",
    MSG_CHECK_FILE_FORMAT_AGAIN:'Vui lòng kiểm tra lại định dạng file',
}