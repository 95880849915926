//STUDENT
const STUDENT_LIST = {
  TITLE: 'Students',
  BUTTON_IMPORT_EXPORT_EXCEL: 'Excel Import/Export',
  BUTTON_ADD: 'Add student',
  BUTTON_IMPORT_EXCEL: 'Import Excel file',
  BUTTON_DOWNLOAD_EXCEL_TEMPLATE: 'Download sample file',
  BUTTON_EXPORT_FILE_EXCEL: 'Export Excel file',

  TOOLTIP_EXCEL:
    'Note: The system only allows adding up to 30 students at a time!', ///
  LABEL_FILTER_BY_NAME_AND_EMAIL: 'Filter',
  PLACEHOLDER_FILTER_BY_NAME_AND_EMAIL: "Search by student's name and email",
  // FILTER_DETAIL
  TITLE_DETAILED_FILTER: 'Detailed Filter',
  LABEL_VIEW_ALL_STUDENTS_THAT: 'See all students have',
  PLACEHOLDER_SELECT_FILTER: 'Select filter',
  OPTION_FILTER_GROUP: 'Group',
  OPTION_FILTER_INTEREST: 'Interested',
  LABEL_FILTER_VALUE_IS: 'is',
  LABEL_SELECT_STUDENT_GROUP: 'Select a group of students',
  PLACEHOLDER_SELECT_STUDENT_GROUP: 'Select group name to add',
  LABEL_SELECT_INTEREST: 'Select interest',
  MSG_CHECK_INFO_AGAIN: 'Please select enough information',
  // ACTIONS
  BUTTON_EDIT: 'Edit Info',
  BUTTON_DELETE: 'Delete student',
  POPUP_CONFIRM_DELETE_TITLE: 'Are you sure to delete this student?',
  POPUP_CONFIRM_DELETE_MESSAGE:
    'All data related to students will be completely deleted from the system. Do you still want to continue?',
  MSG_DELETE_STUDENT_SUCCESS: 'Delete student successfully!',
  // EMPTY DATA
  TITLE_EMPTY_DATA: 'No student group exists yet',
  SUBTITLE_EMPTY_DATA:
    'Please create a new group of students to show on this list',
  // TABLE
  TH_FULL_NAME: "Student's first and last name",
  TH_EMAIL: 'Email',
  TH_PHONE: 'Phone number',
  TH_CREATE_DATE: 'Date Created',
  TH_CREATE_FROM: 'Is self-created',
  TD_CREATE_FROM_VERIFIED: 'Verified',
  TD_CREATE_FROM_UNVERIFIED: 'Unverified',
  TH_ACTION: 'Action',
  // BUTTON_DELETE: 'Delete student',
  BUTTON_SEND_NOTIFICATION: 'Compose a message',
  BUTTON_ADD_TO_GROUP: 'Add to group',

  // ADD_MANY_STUDENT_TO_GROUP
  TITLE_ADD_STUDENT_TO_GROUP: 'Add students to group',
  BUTTON_ADD_TO_EXISTED_GROUP: 'Add to existing group',
  BUTTON_CREATE_NEW_GROUP: 'Create a new group',
  LABEL_FILTER_NEW_GROUP: 'New Group',
  PLACEHOLDER_NEW_GROUP: 'Enter a new group name',
  PLACEHOLDER_SELECT_GROUP: 'Select groups to add',

  //BASE_SEND_NOTIFICATION_POPUP
  POPUP_SEND_NOTIFICATION_TITLE: 'Compose message',
  POPUP_NOTIFICATION_HEADER_LABEL: 'Notification Header',
  POPUP_INPUT_NOTIFICATION_HEADER_PLACEHOLDER: 'Enter message title',
  POPUP_NOTIFICATION_CONTENT_LABEL: 'Notice content',
  POPUP_INPUT_NOTIFICATION_CONTENT_PLACEHOLDER: 'Enter message text',
  POPUP_SEND_NOTIFICATION_BUTTON: 'Send',
  POPUP_SEND_NOTIFICATION_SUCCESS_MESSAGE:
    'Send notification to successful students',
  POPUP_CHECK_STUDENT_QUANTITY_ADD_GROUP_MESSAGE:
    'The number of students added to the group has exceeded the limit',

  //EXPPORT_TABLE_EXCEL
  TH_PASSWORD: 'Password',
  TH_BIRTHDAY: 'Birthday (optional)',
  TH_PHONE_OPTIONAL: 'Phone number (optional)',

  TEXT_LABEL_KEYWORD: 'Keyword',
}
const FORM_CREATE_NEW_STUDENT = {
  LINK_RETURN_TO_STUDENT_LIST: 'List of students',
  TITLE: 'Add New Student',

  //BASIC_INFO
  TITLE_INFO: 'Basic Info',
  LABEL_NAME: 'Student Name',
  PLACEHOLDER_INPUT_NAME: "Enter student's name",
  LABEL_EMAIL: 'Email',
  PLACEHOLDER_INPUT_EMAIL: 'Enter email',
  LABEL_PHONE_NUMBER: 'Phone number',
  PLACEHOLDER_INPUT_PHONE_NUMBER: 'Enter phone number',
  LABEL_BIRTH_DATE: 'Date of Birth',
  PLACEHOLDER_INPUT_BIRTH_DATE: 'Enter date of birth',

  //NOTIFICATION_EMAIL
  TITLE_EMAIL_NOTIFICATION: 'Send email notification',
  TOGGLE_SEND_NOTIFICATION: 'Send new account notification to user email',

  //EDIT_TEMPLATE_EMAIL_REGISTER

  // TITLE_EMAIL_RESGISTER: 'Registration mailing form',
  // TITLE_EDIT_EMAIL_REGISTER_TEMPLATE: 'Registered mail',
  TITLE_SAMPLE_SEND_EMAIL_TEMPLATE: 'Registration mailing form',
  SUBTITLE_SAMPLE_SEND_EMAIL_TEMPLATE: 'Registered mail',

  MSG_CHECK_EMAIL_REGISTER_AGAIN:
    'Email already registered, please use another email!',
  MSG_CREATE_SUCCESS: 'Create Successful Students!',
  MSG_CREATING: 'Creating students...',
  MSG_CHECK_VALID_FIELD: 'Please check the info field again!',
}
const STUDENT_DETAIL = {
  LINK_RETURN_TO_STUDENT_LIST: 'List of students',
  //TAB
  TAB_INFOS: 'General Information',
  TAB_GROUP: 'Group of students',
  TAB_COURSES: 'Course ownership',
  TAB_PROPERTY_EBOOK: 'Property Document',
  TAB_EDUCATION_REPORT: 'Academic report',
  TAB_INTEREST: 'Interest',
  TAB_DELETE: 'Delete student',

  //BASIC_INFO
  TITLE_INFO: 'General Information',
  LABEL_TOTAL_STUDENT: 'Total students',
  LABEL_FOUNDING: 'Established Date',
  MSG_UPDATING__INFO: 'Updating...',
  MSG_UPDATE_INFO_SUCCESS: 'Update successful',
  MSG_RECHECK_INVALID_FIELD: 'Please check the information field again!',

  //GROUP OF STUDENT
  TITLE_GROUP: 'Group of students',
  LABEL_SELECT_GROUPT: 'Select a group of students',
  PLACEHOLDER_SELECT_GROUP: 'Select student group name',
  //TABLE GROUP OF STUDENT
  TH_GROUP: 'Group',
  TH_UPDATED_AT: 'Updated date',
  TH_ACTION: 'Action',

  MSG_ADDING_TO_GROUP: 'Adding students to group...',
  MSG_ADD_STUDENT_TO_GROUP_SUCCESS: 'Successfully added student to group!',
  MSG_CHECK_LIMITED_NUMBER_OF_STUDENTS:
    'The number of students added to the group has exceeded the limit',

  POPUP_CONFIRM_DELETE_OUT_GROUP_TITLE:
    'Confirm removal of student from this study group?',
  POPUP_CONFIRM_DELETE_OUT_GROUP_MESSAGE:
    'Data after deletion cannot be recovered',
  MSG_DELETING: 'Deleting students from group...',
  MSG_DELETE_STUDENT_OUT_GROUP_SUCCESS:
    'Successfully removed student from group!',

  //COURSE OF STUDENT
  TITLE_PROPERTY_COURSE: 'Owner Course',
  LABEL_SELECT_COURSE: 'Select a course',
  PLACEHOLDER_FILTER_COURSE: 'Course Search',
  TH_COURSE_NAME: 'Course name',
  POPUP_DELETE_ROUTE_STUDY_OUT_SYSTEM_TITLE:
    'Delete the learning path from the system',
  MSG_DELETING_COURSE: 'Deleting a course...',
  MSG_DELETE_COURSE_SUCCESS: 'Course deletion successful',
  MSG_ADDING_COURSE: 'Adding courses for students...',
  MSG_ADD_COURSE_SUCCESS: 'Add a course for successful students!',

  //EBOOK
  TITLE_PROPERTY_EBOOK: 'Proprietary document',
  LABEL_SELECT_EBOOK: 'Select books',
  PLACEHOLDER_FILTER_EBOOK: 'Search for books',
  TH_EBOOK_NAME: 'Book title',
  TH_LAST_VIEW: 'Last view at',
  POPUP_BUTTON_DELETE_EBOOK_TITLE: 'Delete document from group',

  MSG_ADDING_EBOOK: 'Adding material for students...',
  MSG_ADD_EBOOK_SUCCESS: 'Adding material for student success!',
  MSG_DELETING_EBOOK: 'Deleting documents from group...',
  MSG_DELETE_EBOOK_SUCCESS: 'Document deletion from group successful',

  //REPORT
  TITLE_COURSE_DONE_QUANTITY: 'Done',
  TITLE_COURSES_DONE_QUANTITY_UNIT: 'course',
  TITLE_OVERVIEW_LAST: 'Last Access',
  TITLE_AVAILABLE_STUDY: 'Start learning',

  LABEL_TIME_UNDEFINED: 'Unknown',
  BUTTON_VIEW: 'View more courses',
  TITLE_PROGRESS_LEARNING: 'Learning Progress',
  TITLE_REPORT_DETAIL: 'Detailed Report',
  //FILTER STUDY REPORT DETAIL
  LABEL_FILTER_COURSE: 'Course Search',
  LABEL_SELECT_LESSON_TYPE: 'Select post type',
  OPTION_PDF_SLIDE: 'PDF Slide',
  OPTION_EXAM: 'Test',
  OPTION_HOMEWORK: 'Homework',
  OPTION_VIDEO: 'Video',
  TEXT_EXAM_TYPE_MATRIX: 'Matrix test',
  TEXT_EXAM_TYPE_TEST: 'Self-generated test',
  TEXT_EXAM_TYPE_EXERCISE: 'Exam available',
  TEXT_DETAIL_UNIT_STATE_DONE: 'Done',
  TEXT_DETAIL_UNIT_STATE_NOT_DONE: 'Not done',
  HINT_SELECT_COURSE_EXERCISE_COURSE:
    'Please select the course and type of assignment to see results',
  BUTTON_FILTER: 'Filter',

  //TABLE STUDY REPORT EXAM
  TH_EXERCISE: 'Exercise',
  TH_EXERCISE_TYPE: 'Post Type',
  TH_MARK: 'Score',
  //TABLE STUDY REPORT HOMEWORK
  TH_HOMEWORK_NAME: 'Assignment Name',
  TH_TIME: 'Time',
  TH_STATE: 'Status',
  //TABLE STUDY REPORT VIDEO OR PDF
  TH_LESSON_NAME: 'Lecture Name',

  //INTEREST
  TITLE_INTEREST: "Students' interest",
  TAB_TOPIC: 'Theme',
  TAB_ONLINE_COURSE: 'Online Course',
  TAB_OFFLINE_COURSE: 'Offline Course',
  TAB_EBOOK: 'E-book',
  TAB_EVENT: 'Event',
  TITLE_LIST: 'List of Interests',
  TH_INTERES_NAME: 'Interest Name',
  TH_UPDATE: 'Update',

  BUTTON_UPDATE: 'Update',
  POPUP_TITLE_DELETE_INTEREST: 'Are you sure you want to delete this interest?',
  POPUP_CREATE_NEW_INTEREST_TITLE: 'Create new {0} interest',
  MSG_CREATING_INTEREST: 'Creating interest!',
  MSG_CREATE_SUCCESS: 'Successful Interest Generation!',

  //DELETION
  TITLE_DETELE: 'Delete student',
  SUBTITLE_DELETE: 'Deleted students cannot be recovered',
  BUTTON_DELETE: 'Delete student',
  POPUP_CONFIRM_DELETE_TITLE: 'Are you sure to delete this student?',
  MSG_DELETE_SUCCESS: 'Delete successful student!',
}
//GROUP OF STUDENTs
const GROUP_STUDENT_LIST = {
  TITLE: 'Student Groups',
  BUTTON_ADD: 'Create New Group',
  LABEL_NAME: 'Group Name',
  PLACEHOLDER_NAME: 'Search by group name',

  //EMPTY_DATA
  TITLE_EMPTY_DATA: 'No student group exists yet',
  SUBTITLE_EMPTY_DATA:
    'Please create a new group of students to show on this list',

  TH_NAME: 'Group Name',
  TH_STUDENT_NUMBER: 'Number of students',
  TH_TOTAL_COURSE: 'Total Course',
  TH_UPDATE: 'Update',
  TH_ACTION: 'Action',
  TOOLTIP_EDIT_GROUP_STUDENT: 'Edit Student Groups',
  MSG_REMOVING_STUDENT: 'Removing student from group...',
  MSG_REMOVE_STUDENT_SUCCESS: 'Successfully removed student from group!',
  LABEL_FILTER_GROUP_BY_NAME: 'Student group name',
  PLACEHOLDER_FILTER_GROUP_BY_NAME: 'Enter student group name',
}
const FORM_CREATE_NEW_GROUP_STUDENT = {
  TITLE: 'Create a new group',
  LABEL_NAME: 'Student group name',
  PLACEHOLDER_NAME: 'Enter student group name',
  MSG_CREATING: 'Creating a group',
  MSG_CREATE_SUCCESS: 'Successful group creation!',
  MSG_CHECK_NAME_AGAIN: 'Group name already taken',
  MSG_CREATE_FAIL: 'Create new group failed',
  //MESSAGE
  MSG_ADDING_STUDENT_TO_GROUP: 'Adding students to group...',
  MSG_ADD_STUDENT_TO_GROUP_SUCCESS: 'Successfully added student to group!',
  MSG_ADD_STUDENT_FAIL:
    'The number of students added to the group has exceeded the limit',
}
const GROUP_STUDENT_DETAIL = {
  LINK_RETURN_TO_GROUP_STUDENT_LIST: 'List of Student Groups',
  //TAB
  AB_INFO: 'Information',
  TAB_STUDENT: 'List of students',
  TAB_COURSE: 'List of courses',
  TAB_E_BOOK: 'E-book list',
  TAB_REPORT: 'Report',
  TAB_ADVANCED: 'Advanced',
  TAB_SEND_NOTICE: 'Notice',
  TAB_EMAIL: 'Email',
  TAB_DELETE: 'Delete',
  //BASIC_INFO
  TITLE_INFO: 'General Information',
  LABEL_NAME: 'Group Name',
  LABEL_STUDENT_QUANTITY: 'Total students',
  LABEL_FOUNDING_DATE: 'Established Date',

  MSG_UPDATE_SUCCESS: 'Update successful',
  MSG_UPDATE_FAIL: 'Update failed',

  //STUDENT_ASSIGNED
  TITLE_STUDENT_LIST: 'List of students',
  BUTTON_IMPORT_EXPORT_EXCEL: 'Excel Import/Export',
  BUTTON_IMPORT_FILE_EXCEL: 'Import Excel file',
  BUTTON_DOWNLOAD_FILE_EXCEL: 'Download Excel file',
  BUTTON_EXPORT_FILE_EXCEL: 'Export Excel file',

  LABEL_PICK_STUDENT: 'Select a student',
  LABEL_PICK_ALL_STUDENT:'Select send to all students in group',
  PLACEHOLDER_INPUT_STUDENT: 'Enter the name of the student to add',
  MSG_ADDING_STUDENT: 'Adding students to group...',
  MSG_ADD_STUDENT_SUCCESS: 'Successfully added student to group!',

  TH_TIME_SEND:'Sent at',
  TH_STATUS: 'Status',
  TH_STUDENT: 'Student Name',
  TH_EMAIL: 'Email',
  TH_ACTION: 'Action',
  TH_ACTION_ITEM: 'View Details',
  TOOLTIP_IMPORT_EXCEL_MAXIMUM_STUDENT_NUMBER:
    'Note: The system only allows adding up to',
  TEXT_STUDENT_EVERY_TIMES: 'student every time!',
  //TABLE EXPORT EXCEL SUDENT
  TH_FULL_NAME: "Student's name",
  // TH_EMAIL: 'Email',
  TH_PHONE_STUDENT: 'Phone',
  TH_PASSWORD: 'Password',
  POPUP_DELETE_STUDENT_TITLE: 'Remove student from group',
  MSG_REMOVING_STUDENT: 'Removing student from group...',
  MSG_REMOVING_STUDENT_SUCCESS: 'Successfully removed student from group',

  //COURSE_ASSIGNED
  TITLE_COURSE_ASSIGNED: 'Assigned course',
  LABEL_FILTER_COURSE_ASSIGNED: 'Select a course',
  PLACEHOLDER_FILTER_COUSRE: 'Search Course',
  ENTITY_COURSE_FOR_STUDENT: 'course for students',
  TH_COURSE_NAME: 'Course name',
  POPUP_TITLE_DELETE_COURSE: 'Delete course from group',

  MSG_UPDATING_COURSE_FOR_STUDENT:
    'Updating course for students from {0} to {1}',
  MSG_ADDING_COURSE: 'Adding courses to group...',
  MSG_ADD_COURSE_SUCCESS: 'Adding course to group successfully',
  MSG_DELETING_COURSE: 'Deleting a course from the group',
  MSG_DELETE_COURSE_SUCCESS: 'Course removal from group was successful',

  //EBOOK_ASSIGNED
  TITLE_DOCUMENT_ASSIGNED: 'Assigned document',
  LABEL_FILTER_BOOK: 'Select books',
  PLACEHOLDER_FILTER_EBOOK: 'Search for books',
  TH_EBOOK_NAME: 'Book title',
  POPUP_DELETE_EBOOK_TITLE: 'Delete document from group',
  MSG_ADDING_EBOOK: 'Adding documents to group...',
  MSG_ADD_EBOOK_SUCCESS: 'Added document to group successfully',
  MSG_DELETING_EBOOK: 'Deleting documents from group',
  MSG_DELETING_EBOOK_SUCCESS: 'Document deletion from group successful',

  //NOTIFICATIO_EMAIL
  PLACEHOLDER_INPUT_STUDENT_NOTIFICATION: 'Search by student or title',

  //HISTORY_SEND_EMAIL
  TITLE_HISTORY_SEND_EMAIL: 'Email sending history',
  BUTTON_SEND_EMAIL: 'Compose Email',
  TITLE_HISTORY_SEND_NOTIFICATION: 'Notification sending history',
  MESSAGE_NO_DATA_YET: 'No data available',
  BUTTON_SEND_NOTIFICATION: 'Compose a message',
  TH_HEADER: 'Title',
  TH_EMAIL_TITLE: 'Email Subject',
  TH_SEND_EMAIL_TO: 'Send To',
  TH_SEND_EMAIL_ALL: 'Send To All',
  TH_EMAIL_SENDING_TIME: 'Send time',
  TH_EMAIL_RECIPIENTS_EMAIL_QUANTITY: 'Viewed/Total Recipients',
  //SEND_EMAIL
  TITLE_SEND_EMAIL_IN_GROUP: 'Email sent in group',
  TITLE_SEND_EMAIL: 'Compose Email',
  LABEL_EMAIL_SUBJECTS: 'Title',
  PLACEHOLDER_EMAIL_SUBJECTS: 'Enter Email subject',
  LABEL_EMAIL_TEMPLATE: 'Email Template',
  BUTTON_EMAIL_HTML_TEMPLATE: 'Use HTML Template',
  BUTTON_IMPORT_FROM_EDITOR: 'Import from editor',

  MESSAGE_SAVE_EMAIL_SUCCESS: 'Email saved successfully!',
  MESSAGE_WARNING_INFOS: 'Information cannot be empty',
  MESSAGE_SEND_EMAIL_SUCCESS: 'Email sent successfully!',
  MESSAGE_CHECK_INVALID_FIELD: 'Please check the information field again!',
  MSG_SEND_NOTIFICATION_FOR_GROUP_SUCCESS: 'Successful group message delivery',
  //DELETE GROUP STUDENT
  TITLE_DELETE: 'Delete student group',
  SUBTITLE_DELETE: 'Deleted student group cannot be recovered',
  BUTTON_DELETE: 'Delete group of students',
  POPUP_CONFIRM_DELETE_STUDENT_TITLE:
    'Delete the student group from the system',
  POPUP_CONFIRM_DELETE_STUDENT_MESSAGE:
    'Deleted group of students cannot be recovered',
  MSG_DELETING: 'Deleting student group...',
  MSG_DELETE_SUCCESS: 'Successfully deleted student group',
  MSG_FAIL_AS_EXCEED_LIMITATION:
    'The number of students exceeds the limit (50 students), please remove students from the group before deleting the group',
}
//TEACHER
const TEACHER_LIST = {
  TITLE: 'Teacher',
  BUTTON_ADD: 'Add Teacher',
  LABEL_FILTER_BY_NAME_EMAIL: 'Teacher Name',
  PLACEHOLDER_FILTER_BY_NAME_EMAIL: 'Search by faculty name and email',

  TH_TEACHER: 'Teacher',
  TH_EMAIL: 'Email',
  TH_ACTION: 'Action',
  TOOLTIP_EDIT_TEACHER: 'Edit Teacher',

  EMPTY_DATA_TITLE: 'There is no teacher at the moment',
  EMPTY_DATA_SUBTITLE: 'Please create new teacher to be shown in this list',
}
const FORM_CREATE_NEW_TEACHER = {
  TITLE: 'Add New Instructor',
  LINK_RETURN_TO_TEACHER_LIST: 'List of instructors',

  //BASIC_INFO
  TITLE_BASIC_INFO: 'Basic Info',
  LABEL_TEACHER_NAME: 'Teacher Name',
  PLACEHOLDER_TEACHER_NAME: 'Enter instructor name',
  LABEL_PHONE: 'Phone number',
  PLACEHOLDER_PHONE: 'Enter phone number',
  LABEL_EMAIL: 'Email',
  PLACEHOLDER_EMAIL: 'Enter email',
  LABEL_INPUT_DESCRIPTION: 'Short description',
  PLACEHOLDER_INPUT_DESCRIPTION: 'Enter a brief description for the trainer',
  HINT_INPUT_DESCRIPTION: 'Recommended length: 200 characters',

  //DESCRIPTION
  TITLE_DESCRIPTION: 'Detailed description',

  MSG_CREATE_SUCCESS: 'Teacher Creation Successful!',
  MSG_CREATE_FAIL: 'Trainer creation failed!',
  MSG_REGISTER_EMAIL_EXISTED:
    'Teacher email is already in use, please enter another email!',
}
const TEACHER_DETAIL = {
  LINK_RETURN_TO_TEACHER_LIST: 'List of Lecturers',

  //TAB
  TAB_INFO: 'General Information',
  TAB_COURSE_ASSIGNED: 'Assigned course',
  TAB_STUDENT_ASSIGNED: 'Assigned student',
  TAB_TEST: 'Assigned test',
  TAB_KNOWLEDGE: 'Knowledge Framework',
  TAB_BOOK: "Teacher's Book",
  TAB_DELETE: 'Delete Instructor',

  // COURSE OF TEACHER
  TITLE_ASSIGN_COURSE: 'Assigned course',
  LABEL_SELECT_COURSE: 'Select a course',
  PLACEHOLDER_FILTER_COURSE: 'Course Search',
  POPUP_DELETE_ROUTE_STUDY_OUT_SYSTEM_TITLE:
    'Delete the course from the system ',
  MSG_DELETING_COURSE: 'Deleting a course...',
  MSG_DELETE_COURSE_SUCCESS: 'Course deletion successful',
  MSG_ASSIGN_COURSE: 'Assign courses for teacher...',
  MSG_ASSIGN_COURSE_SUCCESS: 'Assigned course for successful teacher ',
  MSG_ASSIGN_COURSE_FAIL: 'Assigned course fail: {0}',

  //MARK_DIVITION
  TITLE_MARK_DIVITION_LIST: 'Assigned test list',
  LABEL_PICK_COURSE: 'Select a course',
  PLACEHOLDER_PICK_COURSE: 'Course Search',
  LABEL_PICK_TEST: 'Select test',
  TEXT_NO_DATA: 'No data',

  TH_COURSE_NAME: 'Course name',
  TH_TEST_REQUEST: 'Test on demand',
  TH_PUBLIC_DATE: 'Assignment Date',
  TH_ACTION: 'Action',

  LABEL_FILTER_BY_UNIT: 'Select the required test',
  BUTTON_ASSIGN: 'Assignment',

  MSG_ASSIGN_SUCCESS:
    'The assignment of the lecturer to mark the test was successful!',
  MSG_ASSIGN_FAIL:
    'At present, it is not possible to assign lecturers. Please try again later.',
  MSG_DELETE_ASSIGN_SUCCESS: 'Unassigned teacher successfully',

  //DELETION
  TITLE_DELETE: 'Delete Instructor',
  SUBTITLE_DELETE: 'Deleted faculty cannot be recovered',
  BUTTON_DELETE: 'Delete Instructor',

  POPUP_CONFIRM_DELETE_TITLE: 'Are you sure to delete this trainer?',
  POPUP_CONFIRM_DELETE_MESSAGE:
    'All data related to lecturers will be completely deleted from the system. Do you still want to continue?',
  MSG_DELETE_SUCCESS: 'Delete the trainer successfully',
}
const TEACHER_SALE = {
  TITLE: 'Teacher Sales',
  LABEL_STATISTICAL: 'Statistics',
  OPTION_BILL_IN_MONTH: 'Invoice of the month',
  OPTION_INCOME: 'Revenue',
  //TABLE HEADER
  TH_BUYER: 'Buyer',
  TH_PRICE: 'Price',
  TH_CREATE_AT: 'Date Created',
  TH_COUPON: 'Coupon',
  TH_COMBO: 'Combo',
  TH_CODE_TYPE: 'Coupon / Combo / Activation Code',
  TH_AFFILIATE: 'Affiliate',
  TH_BEFORE_DISCOUNT: 'Original Price',
  TH_TOTAL_PRICE: 'To make money',
  TH_STATUS: 'Status',
  TEXT_CURRENT_UNIT: 'd',
  TEXT_USER: 'Used',
  TEXT_NOT_USE: 'Do not use',
  TEXT_SUCCESS: 'Success',
  TEXT_WAITING: 'Waiting',
  TEXT_FAIL: 'Failed',
}
//COMMENT OF STUDENT
const COMMENT_LIST = {
  TITLE: 'Comment',
  LABEL_INPUT_COURSE_NAME: 'Course name',
  PLACEHOLDER_INPUT_COURSE_NAME: 'Enter course name',
  LABEL_TIME: 'Start Date - End Date',
  PLACEHOLDER_FILTER_TIME: 'Select time to filter',
  LABEL_STATUS: 'Status',
  PLACEHOLDER_SELECT_STATUS: 'Select Status',
  OPTION_FILTER_ALL_STATUS: 'All Status',
  OPTION_FILTER_PUBLISH: 'Approved',
  OPTION_FILTER_UNPUBLISH: 'Not yet browsed',
  OPTION_FILTER_WAIT: 'Waiting for approval',
  BUTTON_APPROVE_MULTI: 'Approved All',

  POPUB_TITLE_DELETE: 'Confirm comment deletion',
  POPUB_SUBTITLE_DELETE: 'Are you sure to delete this comment?',
  BUTTON_DELETE: 'Delete',

  TH_COURSE: 'Course',
  TH_CONTENT: 'Content',
  TH_END_UPDATE: 'Last Updated',
  TH_STATUS: 'Status',
  TH_ACTION: 'Action',
  TEXT_UNDEFINED: 'Unknown',
  TEXT_APPROVED: 'Approved',

  //POPUP
  POPUP_CONFIRM_APPROVE_TITLE: 'Confirm comment browsing',
  POPUP_CONFIRM_APPROVE_ONE_MESSAGE: 'Are you sure to browse this comment?',

  POPUP_CONFIRM_APPROVE_MULTI_TITLE: 'Confirm all comments',
  POPUP_CONFIRM_APPROVE_MULTI_MESSAGE:
    'Are you sure to browse all these comments?',

  POPUP_CONFIRM_DELETE_TITLE: 'Confirm comment deletion',
  POPUP_CONFIRM_DELETE_MESSAGE: 'Are you sure to delete this comment?',

  POPUP_CONFIRM_DELETE_MULTI_TITLE: 'Confirm delete all comments',
  POPUP_CONFIRM_DELETE_MULTI_MESSAGE:
    'Are you sure to delete all these comments?',

  //MESSAGE
  MSG_APPROVE_SUCCESS: 'Approved discussion successful!',
  MSG_APPROVE_MULTI_SUCCESS: 'Ap all discussions successfully!',
  MSG_DELETE_SUCCESS: 'Delete discussion successful',
  MSG_DELETE_MULTI_SUCCESS: 'Delete all successful discussions',
  MESSAGE_REQUEST_PENDING: 'Receiving...',
  MESSAGE_REQUEST_SUCCESS: 'Success!',
}
//RATING OF STUDENT
const RATING_LIST = {
  TITLE: 'Rating',
  //TAB
  TAB_ONLINE_COURSE: 'Online Course',
  TAB_LEARNING_PATH: 'Learning path',
  TAB_EBOK: 'E-BOOK',

  LABEL_NAME: 'Name',
  PLACEHOLDE_INPUT_NAME: 'Enter a name',
  LABEL_COUSE: 'Course',
  LABEL_LEARNING_PATH: 'Learning Path',
  LABEL_EBOOK: 'Ebook',
  //ONLINE_COURSE
  LABEL_COURSE_NAME: 'Course name',
  PLACEHOLDER_COURSE_NAME: 'Enter course name',

  //LEARNING_PATH
  LABEL_LEARNING_PATH_NAME: 'Route name',
  PLACEHOLDER_LEARNING_PATH_NAME: 'Enter route name',

  //EBOOK
  LABEL_EBOOK_NAME: 'Document Name',
  PLACEHOLDER_NAME: 'Enter a document name',

  LABEL_FILTER_INDICATION_TIME: 'Start Date - End Date',
  PLACEHOLDER_FILTER_INDICATION_TIME: 'Select time to filter',
  LABEL_STATUS: 'Status',
  PLACEHOLDER_SELECT_STATUS: 'Select state',
  OPTION_FILTER_ALL_STATUS: 'All Status',
  OPTION_FILTER_PUBLISH: 'Approved',
  OPTION_FILTER_UNPUBLISH: 'Not yet browsed',
  OPTION_FILTER_WAIT: 'Waiting for approval',

  POPUP_CONFIRM_APPROVE_ONE_TITLE: 'Approve this rating?',
  POPUP_CONFIRM_APPROVE_ONE_DESCRIPTION:
    'Are you sure you want to approve this rating?',

  POPUP_CONFIRM_APPROVE_ALL_TITLE: 'Approve all these ratings?',
  POPUP_CONFIRM_APPROVE_ALL_DESCRIPTION:
    'Are you sure you want to approve all these ratings?',

  POPUP_CONFIRM_DELETE_TITLE: 'Delete this rating?',
  POPUP_CONFIRM_DELETE_MESSAGE: 'Are you sure you want to delete this rating?',

  POPUP_CONFIRM_DELETE_ALL_TITLE: 'Delete all these ratings?',
  POPUP_CONFIRM_DELETE_ALL_MESSAGE:
    'Are you sure you want to delete all these ratings?',

  TH_CONTENT: 'Content',
  TH_END_UPDATE: 'Last Updated',
  TH_STATUS: 'Update',
  TH_ACTION: 'Action',
  TEXT_APPROVED: 'Approved',
  TEXT_PENDDING: 'Waiting for approval',
  TEXT_UNDEFINED: 'Unknown',
  MESSAGE_REQUEST_PENDING: 'Receiving...',
  MESSAGE_REQUEST_SUCCESS: 'Success!',
  MSG_APPROVE_SUCCESS: 'Approve rating successfully!',
  MSG_APPROVE_MULTI_SUCCESS: 'Approve all selected ratings successfully!',
  MSG_DELETE_SUCCESS: 'Delete rating successfully',
  MSG_DELETE_MULTI_SUCCESS: 'Delete all selected ratings successfully',
}
//CERTIFICATE FOR COURSE
const CERTIFICATE_LIST = {
  TITLE: 'Certificate',
  BUTTON_ADD: 'Add Certificate',
  LABEL_FILTER_BY_NAME: 'Certificate Name',
  PLACEHOLDER_FILTER_BY_NAME: 'Search by certificate name',

  TH_NAME: 'Certificate Name',
  TH_PATH_COURSE: 'Specified Course/Route',
  TH_CREATE_DATE: 'Date Created',
  TH_GRANTED: 'Granted',
  TH_ACTION: 'Action',
  TOOLTIP_EDIT_CERTIFICATE: 'Edit Certificate',

  TITLE_EMPTY_DATA: 'No certificate exists yet',
  SUBTITLE_EMPTY_DATA: 'Please create a new post to display on this list',
}
const FORM_CREATE_NEW_CERTIFICATE = {
  LINK_RETURN_TO_LIST: 'Certificate List',
  TITLE: 'New Certificate',
  //TAB
  TAB_CONTENT: 'CONTENT',

  //BASIC_INFO
  TITLE_INFOS: 'Certificate Information',
  LABEL_NAME: 'Certificate Name',
  PLACEHOLDER_NAME: 'Example: Certificate of Completion',
  LABEL_TYPE: 'Certificate Type',
  OPTION_ONLINE: 'Online',
  OPTION_LEARNING_PATH: 'Learning Path',
  LABEL_FILTER_COURSE: 'Select a course',
  PLACEHOLDER_FILTER_COURSE: 'Course Search',

  //ADVANCE_INFO
  LABEL_INPUT_HEADER: 'Title',
  PLACEHOLDER_INPUT_HEADER: 'Example: Certificate',
  LABEL_INPUT_DESCRIPTION_STUDENT_NAME: 'Describe student name',
  PLACEHOLDER_INPUT_DESCRIPTION_STUDENT_NAME: "Example: Student's name",
  LABEL_INPUT_DESCRIPTION_COURNAME: 'Description of course name',
  PLACEHOLDER_INPUT_COURNAME_TITLE: 'Course title',

  LABEL_INPUT_AVAILABEL_DATE: 'Issuance date',
  PLACEHOLDER_INPUT_AVAILABLE_DATE: 'Example: Issued Date',
  LABEL_AVAILABLE_DATE_TITLE: 'Grant Date Title',
  PLACEHOLDER_AVAILABLE_DATE_TITLE: 'Issuance date',
  LABEL_EXPIRED_DATE_TITLE: 'Expiration Date Title',
  PLACEHOLDER_EXPIRED_DATE_TITLE: 'Expiration Date',
  LABEL_EXPIRED_DATE: 'Expiration Date',
  PLACEHOLDER_EXPIRED_DATE: 'Example: 12/12/2020',
  LABEL_CHANGE_PHOTO_DESIGN: 'Change design image',
  BUTTON_SELECT_PHOTO_FROM_LIBRARY: 'Select photo from gallery',
  BUTTON_UPLOAD_PHOTO: 'Upload photo',
  HINT_CHANGE_PHOTO_DESIGN: 'Recommended size: 1024x726px',

  MSGH_CREATE_CERTIFICATE_SUCCESS: 'Certificate generation successful',
}
const CERTIFICATE_DETAIL = {
  LINK_RETURN_TO_CERTIFICATE_LIST: 'Certificate List',
  TAB_INFO: 'General Information',
  TAB_HISTORY_GRANT_CERTIFICATE: 'Certificate Issue History',

  //BASIC_INFO
  LABEL_NAME: 'Certificate Name',
  LABEL_PATH_COURSE: 'Specified Course/Route',
  LABEL_CREATE_DATE: 'Date Created',
  //ENABLE
  TITLE_STOP_APPLICATION: 'Stop applying this certificate',
  TITLE_APPLICATION: 'Apply this certificate',
  BUTTON_STOP_APPLICATION: 'Stop applying',
  BUTTON_APPLICATION: 'Apply',
  SUBTITLE_APPLICATION_AND_STOP_APPLICATION:
    'Customize allows this certificate to be used on the installed course.',

  //DELETION
  TITLE_DELETE: 'Delete certificate',
  SUBTITLE_DELETE:
    'Academy that received this certificate prior to deletion will still have the results and certificate saved.',
  BUTTON_DELETE: 'Delete certificate',

  POPUP_CONFIRM_DELETE_TITLE:
    'Are you sure you want to delete this certificate?',
  POPUP_CONFIRM_DELETE_MESSAGE: 'Data after deletion cannot be recovered',

  //HISTORY_GRANT
  TITLE_STUDENT_LIST: 'List of students received',
  LABEL_FILTER_BY_STUDENT_NAME: 'Student Name',
  PLACEHOLDER_FILTER_BY_STUDENT_NAME: 'Search by student name',

  TH_STUDENT_NAME: 'Student Name',
  TH_AVAILABLE_AT: 'Issuance date',
  TH_EXPIRED_AT: 'Expiration Date',

  MSG_UPDATE_SUCCESS: 'Update successful',
  //VIEW_CERTIFICATE
  LABEL_STUDENT_NAME: 'Student Name',
  VIEW_CERTIFICATE: {
    TITLE: 'Certificate',
    SUBTITLE: 'Management specialist',
    DESCRIPTION: 'Completed course',
    SUBDESCRIPTION: 'Issuance date',
    TITLE_EXPIRED: 'Expires',
    HEADER: 'Course Title',
  },
  STUDENT_HAS_CERTIFICATE_LIST: {
    TITLE: 'List of students received',
    LABEL_FILTER_BY_NAME: 'Student Name',
    PLACEHOLDER_FILTER_BY_NAME: 'Search by student name',
    TH_NAME: 'Student Name',
    TH_ACHIEVE_DATE: 'Issuance Date',
    TH_EXPIRED: 'Expiration Date',
  },
}
//FORM CHANGE PASSWORD USE FOR STUDENT AND TEACHER
const FORM_CHANGE_PASSWORD = {
  TITLE: 'Password',
  LABEL_INPUT: 'Password',
  PLACEHOLDER_INPUT: 'Enter Password',
  LABEL_INPUT_AGAIN: 'Re-enter password',
  PLACEHOLDER_INPUT_AGAIN: 'Re-enter password',
  MSG_NOT_SAME: 'Password does not match',
  MSG_CHECK_AGAIN: 'Please check your password again!',
}
const FORM_PREVIEW_IMAGE = {
  TITLE: 'Picture shown',
  SUBTITLE: 'Recommended size: 300x300px',
}
export default {
  STUDENT_LIST,
  FORM_CREATE_NEW_STUDENT,
  STUDENT_DETAIL,
  GROUP_STUDENT_LIST,
  FORM_CREATE_NEW_GROUP_STUDENT,
  GROUP_STUDENT_DETAIL,
  TEACHER_LIST,
  FORM_CREATE_NEW_TEACHER,
  TEACHER_DETAIL,
  TEACHER_SALE,
  COMMENT_LIST,
  RATING_LIST,
  CERTIFICATE_LIST,
  FORM_CREATE_NEW_CERTIFICATE,
  CERTIFICATE_DETAIL,
  FORM_CHANGE_PASSWORD,
  FORM_PREVIEW_IMAGE,
  BUTTON_DELETE: 'Delete',
  BUTTON_SAVE: 'Save',
  BUTTON_ADD: 'Add',
  BUTTON_CANCEL_TEXT: 'Cancel',
  BUTTON_CANCEL: 'Cancel',
  BUTTON_CONFIRM: 'Confirm',
  BUTTON_EDIT: 'Edit',
  BUTTON_UPDATE: 'Update',
  BUTTON_RESEST: 'Reset',
  BUTTON_SEARCH: 'Search',
  BUTTON_SEND: 'Send',
  LABEL_LAST_TIME: 'Last time',
  LABEL_UNDEFINED: 'Unknown',
  //SUBTITLE POPUP
  POPUP_CORFIRM_DELETE_DATA_MESSAGE: 'Data after deletion cannot be recovered',
  //MESSAGE
  MSG_UPDATING: 'Updating...',
  MSG_UPDATE_SUCCESS: 'Update successful!',
  MSG_GETTING_DATA: 'Getting data...',
  MSG_RECHECK_INVALID_FIELD: 'Please check the information field again!',
  MSG_CHECK_INFO_AGAIN: 'Please check the information again!',
  MSG_CHECK_HAS_EXISTED: 'Certification for this course has existed',
  MSG_CREATE_CERTIFICATE_FAIL: 'Certification creation failed!',
  ENTITY_STUDENT: 'Student',
}
