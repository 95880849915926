import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Class = RepositoryFactory.get('classOffline')
const namespaced = true
import utils from '@/helpers/utils.js'

const state = {
  class: {},
  classes: [],
  selectedClass: {},
}

const actions = {
  async createClass({ commit }, data) {
    const res = await Class.create(data)
    commit('addClass', res.data)
    return res.data
  },
  async fetchClasses({ commit, rootState }, params = {}) {
    const fetchParams = { ...params }
    if (utils.isTeacher()) {
      if (!rootState.offlineCourse.isGlobalTeacher) {
        const teacherId = rootState.offlineCourse.teacherId
        if (!teacherId) return
        fetchParams['authors'] = teacherId
      }
    }
    const res = await Class.fetch(fetchParams)
    commit('setClasses', res.data || [])
    return res.data
  },
  async fetchClassById({ commit }, id) {
    const res = await Class.fetchOne(id)
    commit('setClass', res.data || {})
  },
  async updateClass({ commit }, { id, ...classInfo }) {
    const res = await Class.update(id, classInfo)
    commit('setClass', res.data)
    return res.data
  },
  async removeClass({ commit }, id) {
    await Class.remove(id)
    return commit('removeClass', id)
  },
  setClass({ commit }, clazz) {
    commit('setClass', clazz)
  },
}

const mutations = {
  addClass(state, newClass) {
    return state.classes.unshift(newClass)
  },
  setClasses(state, classes) {
    state.classes = classes
  },
  setClass(state, newClass) {
    state.classes = state.classes.map((item) => {
      if (item.id === newClass.id) {
        return newClass
      } else return item
    })
  },
  removeClass(state, id) {
    state.classes = state.classes.filter((classInfo) => classInfo.id !== id)
  },
  setSelectedClass(state, data) {
    state.selectedClass = data
  },
}

const getters = {
  getAllClasses: (state) => {
    return state.classes
  },
  getClass: (state) => state.class,
  getSelectedClass: (state) => state.selectedClass,
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
