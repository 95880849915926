import axios from 'axios'
import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_LEADERBOARD + '/leaderboard'

const create = params => {
	return Repository(baseURL).create(params)
}

const fetchWithRank = params => {
	return Repository(baseURL + '/rank').fetch(params)
}

const searchWithRank = params => {
	return Repository(baseURL + '/search').fetch(params)
}

const getAverageScore = params => {
	return Repository(baseURL + '/average-score').fetch(params)
}

const fetchWithScore = params => {
	return Repository(baseURL + '/score').fetch(params)
}

const count = params => {
	return Repository(baseURL + '/count').fetch(params)
}
const statistic = params => {
	return Repository(baseURL + '/statistic').fetch(params)
}

const fetchRankStudent = (id, params) => {
	return axios.get(baseURL + `/user/${id}`, {
		params: params
	})
}

export default {
	create,
	fetchWithRank,
	fetchWithScore,
	fetchRankStudent,
	count,
	statistic,
	searchWithRank,
	getAverageScore
}
