<template>
	<span class="outlined">
		<v-autocomplete
			class="body-2"
			v-bind="this.$attrs"
			:items="events"
			:search-input.sync="search"
			:value="eventId"
			:item-text="itemText"
			:label="$vuetify.lang.t('$vuetify.MANAGE_FINANCE.POPUP_ADD_PROGRAM.LABEL_SELECT_EVENT')"
			:item-value="itemValue"
			return-object
			dense
			outlined
			@change="(value) => $emit('change', value)"
		></v-autocomplete>
	</span>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
	props: {
		itemText: String,
		itemValue: String,
	},
	data() {
		return {
			search: '',
			items: [],
			eventId: '',
		}
	},
	async mounted() {
		this.$message.loading(this.$vuetify.lang.t('$vuetify.MANAGE_FINANCE.POPUP_ADD_PROGRAM.MSG_GETTING_EVENT'))
		await this.fetchAllEvents()
	},
	methods: {
		...mapActions({
			fetchAllEvents: 'event/fetchAllEvents',
		}),
	},
	computed: {
		...mapGetters({
			events: 'event/getAllEvents',
		}),
	},
}
</script>