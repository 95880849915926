const ONLINE_COURSE_REPORT = {
	TITLE: 'Khoá học Online',
	COURSE_UNIT: 'Khoá học',
	LABEL_PUBLISHED_COURSE: 'Đã xuất bản',
	LABEL_FREE_COURSE: 'Miễn phí',
	LABEL_PAID_COURSE: 'Có phí'
}
const TOP_COURSE_REPORT_CHART = {
	TITLE: 'Top khoá học nổi bật',
	LABEL_COURSE_OTHER: 'Khác',
	COURSE_UNIT: 'Khoá',
}
const TOTAL_NUMBER_STUDENTS_REPORT = {
	TITLE: 'Tổng số học viên',
	STUDENT_UNIT: 'Học viên',
	LABEL_STUDENT_SELF_REGISTER: 'Tự đăng ký',
	LABEL_STUDENT_CREATED_BY_ADMIN: 'Được tạo',
	LABEL_INTERACTION: 'Có tương tác'
}
const STUDENT_REPORT_CHART = {
	TITLE: 'Tăng trong tuần',
	LABEL_NEW_STUDENT: 'Học viên mới',
	LABEL_UNVERIFY: 'Chưa kích hoạt'
}
const TOTAL_INCOME_REPORT = {
	TITLE: 'Tổng doanh thu',
	LABEL_TRANSFER: 'Chuyển khoản',
	LABEL_VNPAYBANK: 'Trực tuyến',
	LABEL_DIRECPAY: 'Khác',
	LABEL_COD: 'COD',
	CURRENCY_UNIT: 'VND'
}
const INCOME_REPORT_CHART = {
	TITLE: 'Tăng trong tuần',
	LABEL_BILLS: 'Đơn hàng',
	LABEL_INCOME: 'Doanh thu'
}
const TOTAL_DATA_SIZE_REPORT = {
	TITLE: 'Dung lượng'
}
const DOCCUMENT_UPLOAD_FILE_REPORT = {
	TITLE: 'Tài liệu Upload',
	FILE_UNIT: 'File'
}
const VIDEO_VIEWS_REPORT = {
	TITLE: 'Lượt xem',
	VIEWS_UNIT: 'lần',
	LABEL_VIEWS: 'Số lượt xem video theo tháng gần nhất'
}
const VIDEO_BAND_WIDTH_USED_REPORT = {
	TITLE: 'Băng thông',
	LABEL_BAND_WIDTH: 'Băng thông video sử dụng trong tháng hiện tại',
	LABEL_BAND_WIDTH_NOTE: '(được tính bắt đầu từ ngày 1 đầu tháng)'
}
const NEW_STUDENT_REPORT = {
	TITLE: 'Học viên mới',
	LINK_TO_LIST_STUDENT: 'Xem thêm học viên',
	TH_STUDENT: 'Học viên',
	TH_EMAIL: 'Email',
	TH_PHONE_NUMBER: 'Điện thoại',
	TH_STUDENT_CREATED_BY: 'Người tạo',
	TH_CREATE_TIME: 'Thời gian khởi tạo',
	TEXT_ADMIN: 'Quản trị viên',
	TEXT_STUDENT_SELF_CREATED: 'Tự đăng ký',
}
export default {
	ONLINE_COURSE_REPORT,
	TOP_COURSE_REPORT_CHART,
	TOTAL_NUMBER_STUDENTS_REPORT,
	STUDENT_REPORT_CHART,
	TOTAL_INCOME_REPORT,
	INCOME_REPORT_CHART,
	TOTAL_DATA_SIZE_REPORT,
	DOCCUMENT_UPLOAD_FILE_REPORT,
	VIDEO_VIEWS_REPORT,
	VIDEO_BAND_WIDTH_USED_REPORT,
	NEW_STUDENT_REPORT,
	//MESSAGE
	MSG_GET_DATA_LOADING: 'Đang lấy dữ liệu ...',
	LABEL_UNDEFINED:"Không rõ",
}
