<template>
  <div class="d-flex">
    <BaseInput
      class="mb-5"
      style="max-width: 360px; height: 40px"
      v-model="textSearch"
      :label="label"
      :placeholder="placeholder"
      @keyup.native.enter="$emit('onSearch', textSearch)"
    />
    <BaseButton
      class="ml-3"
      :outlined="outlined"
      :icon="magnifyIcon"
      style="margin-top: 0px; height: 40px"
      @click="$emit('onSearch', textSearch)"
      :text="buttonText || $vuetify.lang.t('$vuetify.BUTTON_SEARCH')"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    label: String,
    placeholder: String,
    buttonText: {
      type: String,
    },
    magnifyIcon: {
      type: String,
      default: 'mdi-magnify',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textSearch: '',
    }
  },
  watch: {
    value() {
      this.textSearch = ''
    },
  },
}
</script>
