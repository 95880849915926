import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Commission = RepositoryFactory.get('commission')

const state = {
  commissions: [],
  revenue: 0,
  count: 0,
  totalCommission: 0
}

const actions = {
  async fetchCommissions({ commit }, params = {}) {
    const res = await Commission.fetch({
      ...params,
    })
    commit("setCommissions", res.data || [])
    return res.data
  },
  async countBill({ commit }, params = {}) {
    const res = await Commission.countBill({
      ...params,
    })
    commit("setCount", res.data || 0)
    return res.data
  },
  async getRevenue({ commit }, params = {}) {
    const res = await Commission.getRevenue({
      ...params,
    })
    commit("setRevenue", res.data || 0)
    return res.data
  },
  async getTotalCommission({ commit }, params = {}) {
    const res = await Commission.getTotalCommission({
      ...params,
    })
    commit("setTotalCommission", res.data || 0)
    return res.data
  }
}

const mutations = {
  setCommissions(state, commissions) {
    state.commissions = commissions
  },
  setCount(state, count) {
    state.count = count
  },
  setRevenue(state, revenue) {
    state.revenue = revenue
  },
  setTotalCommission(state, totalCommission) {
    state.totalCommission = totalCommission
  }
}

const getters = {
  getCommissions: (state) => { return state.commissions },
  getRevenue: (state) => { return state.revenue },
  getTotalBill: (state) => { return state.count },
  getTotalCommission: (state) => { return state.totalCommission }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
