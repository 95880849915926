import axios from 'axios'
import auth from '@/store/modules/auth'
import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_UTILITY + '/interest'

const fetch = (params) => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const addMultipleInterest = (params) => {
  return axios.put(baseURL + `/add?vendorId=${auth.state.auth.vendorId}`,
    params,
    {
      headers: {
        Authorization: auth.state.auth.accessToken
      }
    })
}

const addOneInterest = (id, params) => {
  return axios.put(baseURL + `/add/${id}?vendorId=${auth.state.auth.vendorId}`,
    params,
    {
      headers: {
        Authorization: auth.state.auth.accessToken
      }
    })
}

const removeOneInterest = (id, params) => {
  return axios.put(baseURL + `/remove/${id}?vendorId=${auth.state.auth.vendorId}`,
    params,
    {
      headers: {
        Authorization: auth.state.auth.accessToken
      }
    })
}

const removeMultipleInterest = (params) => {
  return axios.put(baseURL + `/remove?vendorId=${auth.state.auth.vendorId}`,
    params,
    {
      headers: {
        Authorization: auth.state.auth.accessToken
      }
    })
}

export default {
  fetch,
  fetchOne,
  addMultipleInterest,
  addOneInterest,
  removeOneInterest,
  removeMultipleInterest

}
