<template>
  <v-dialog v-model="dialog" width="620px">
    <v-card>
      <v-card-title class="headline lighten-2" primary-titledialog
        >{{
          $vuetify.lang.t(
            type === 'email'
              ? '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TITLE_POPUP_EMAIL_ERROR'
              : '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TITLE_POPUP_NOTIFICATION_ERROR'
          )
        }}
        <v-spacer></v-spacer>
        <v-icon @click="() => (dialog = !dialog)"> mdi-close </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          disable-sort
          :headers="headers"
          :items="students"
          class="my-3 rounded-ts-xl"
        >
          <template v-slot:[`item.student`]="{item}">
            {{ item.student }}
          </template>
          <template v-slot:[`item.email`]="{item}">
            {{ item.email }}
          </template>
          <template v-slot:[`item.problem`]="{item}">
            {{ item.problem }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <BaseButton
          outlined
          @click="dialog = false"
          :text="
            $vuetify.lang.t(
              '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_EXPORT_FILE_EXCEL'
            )
          "
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    type: String,
  },
  data() {
    return {
      students: [
        {
          student: 'tuan',
          email: 'tuan@gmail',
          problem: 'không biết',
        },
        {
          student: 'tuan',
          email: 'tuan@gmail',
          problem: 'không biết',
        },
        {
          student: 'tuan',
          email: 'tuan@gmail',
          problem: 'không biết',
        },
      ],
      dialog: false,
      loading: false,
    }
  },
  computed: {
    headers() {
      let headers = [
        {
          text: this.$vuetify.lang.t(
            '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TH_SEND_TO'
          ),
          value: 'student',
          width: '30%',
        },
        {
          text: this.$vuetify.lang.t(
            '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TH_PROBLEM'
          ),
          value: 'problem',
          width: '30%',
        },
      ]
      if (this.type === 'email') {
        headers.splice(1, 0, {
          text: this.$vuetify.lang.t(
            '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TH_EMAIL'
          ),
          value: 'email',
          width: '30%',
        })
      }
      return headers
    },
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
  },
}
</script>
<style lang="scss">
table {
  border: 1px solid #e6e4eb;
  border-radius: 6px;
}
.custom_table_class thead th {
  border-radius: 6px 6px 0 0;
}
table.v-table tbody td {
  height: 40px;
  border: none;
}
.theme--light.v-table tbody tr:not(:last-child) {
  border-bottom: none;
}
</style>
