var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"outlined"},[_c('v-autocomplete',_vm._b({staticClass:"body-2",attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"label":_vm.label ||
        _vm.$vuetify.lang.t(
          '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_FILTER_COURSE_ASSIGNED'
        ),"placeholder":_vm.placeholder ||
        _vm.$vuetify.lang.t(
          '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.PLACEHOLDER_FILTER_COUSRE'
        ),"item-text":_vm.itemText,"item-value":_vm.itemValue,"value":_vm.value,"multiple":_vm.isMultipleSelect,"chips":_vm.isMultipleSelect,"return-object":"","dense":"","outlined":"","clearable":_vm.isClearable,"filter":_vm.customFilter},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":value => _vm.onSelected(value)},scopedSlots:_vm._u([(_vm.isMultipleSelect)?{key:"selection",fn:function(data){return [(!data.item.disabled)?_c('v-chip',_vm._b({attrs:{"small":"","input-value":data.selected}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")]):_vm._e()]}}:null],null,true)},'v-autocomplete',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }