const state = {
  videoSelected: {},
  videoInUse: {},
  windowLoaded: false,
  existedVideoStatus: false,
  unitCourse: {
    id: '',
    course: {},
  },
  videoSaveStatus: {
    videoId: '',
    isSave: false,
  },
  isUploading: false,
}

const actions = {
  setVideoSelected({commit}, video) {
    commit('setVideoSelected', video)
  },
  setVideoInUse({commit}, video) {
    commit('setVideoInUse', video)
  },
  setWindowLoadedStatus({commit}, status) {
    commit('setWindowLoadedStatus', status)
  },
  resetVideo({commit}) {
    commit('resetVideo')
  },
  setUnitCourse({commit}, unitCourse) {
    commit('setUnitCourse', unitCourse)
  },
  setExistedVideoStatus({commit}, status) {
    commit('setExistedVideoStatus', status)
  },
  setVideoSaveStatus({commit}, data) {
    commit('setVideoSaveStatus', data)
  },
  updateVideoSaveStatus({commit}) {
    commit('updateVideoSaveStatus')
  },
  resetVideoSaveStatus({commit}) {
    commit('resetVideoSaveStatus')
  },
  resetVideoSelected({commit}) {
    commit('resetVideoSelected')
  },
  setIsUploading({commit}, status) {
    commit('setIsUploading', status)
  },
}

const mutations = {
  setVideoSelected(state, video) {
    state.videoSelected = video
  },
  setVideoInUse(state, video) {
    state.videoInUse = video
  },
  setWindowLoadedStatus(state, status) {
    state.windowLoaded = status
  },
  resetVideo(state) {
    state.videoInUse = {}
  },
  setUnitCourse(state, unitCourse) {
    state.unitCourse = {...unitCourse}
  },
  setExistedVideoStatus(state, status) {
    state.existedVideoStatus = status
  },
  setVideoSaveStatus(state, data) {
    state.videoSaveStatus = {...data}
  },
  updateVideoSaveStatus(state) {
    state.videoSaveStatus = {...state.videoSaveStatus, isSave: true}
  },
  resetVideoSaveStatus(state) {
    state.videoSaveStatus = {
      isSave: false,
      videoId: '',
    }
  },
  resetVideoSelected(state) {
    state.videoSelected = {}
  },
  setIsUploading(state, status) {
    state.isUploading = status
  },
}
const getters = {
  getVideoSelected: (state) => state.videoSelected,
  getVideoInUse: (state) => state.videoInUse,
  getWindowLoaded: (state) => state.windowLoaded,
  getUnitCourse: (state) => state.unitCourse,
  getExistedVideoStatus: (state) => state.existedVideoStatus,
  getVideoSaveStatus: (state) => state.videoSaveStatus,
  getIsUploading: (state) => state.isUploading,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
