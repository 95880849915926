import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const UserTemp = RepositoryFactory.get('userTemp')

const state = {
  contacts: [],
  orders: [],
}

const actions = {
  async fetchContacts({commit}, params = {}) {
    const res = await UserTemp.fetchContact(params)
    commit('setContacts', res.data || [])
  },
  async fetchOrders({commit}, params = {}) {
    const res = await UserTemp.fetchOrder(params)
    commit('setOrders', res.data || [])
  },
  async updateOrder({commit}, params = {}) {
    const res = await UserTemp.update(params)
    commit('addOrUpdateOrder', res.data || {})
  },
}

const mutations = {
  setContacts(state, contacts) {
    state.contacts = contacts.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
  },
  setOrders(state, orders) {
    state.orders = orders.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
  },
  addOrUpdateOrder(state, item) {
    if (item && item.id) {
      const foundIndex = state.orders.findIndex(order => order.id === item.id)
      if (foundIndex > -1) {
        state.orders.splice(foundIndex, 1, item)
      } else {
        state.orders.unshift(item)
      }
    }
  },
}

const getters = {
  getContacts(state) {
    return state.contacts
  },
  getOrders(state) {
    return state.orders
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}