import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const QuestionGenerate = RepositoryFactory.get('QuestionGenerate')

const state = {
  QuestionGenerates: [],
  QuestionGenerate: {},
  count: 0,
}

const actions = {
  async fetch({commit}, params = {}) {
    const res = await QuestionGenerate.fetch({
      ...params,
    })
    commit('setQuestionGenerates', res.data)
    return res.data
  },
  async fetchOne({commit}, id) {
    const res = await QuestionGenerate.fetchOne(id)
    commit('setOneQuestionGenerate', res.data)
    return res.data
  },
  async create(_, data) {
    const res = await QuestionGenerate.create(data)
    return res.data
  },
  async delete({commit}, id) {
    await QuestionGenerate.remove(id)
    return commit('removeQuestionGenerate', id)
  },
  async deleteMany(_, params) {
    return await QuestionGenerate.removeMany(params)
  },
  async update(_, {id, ...data}) {
    const res = await QuestionGenerate.update(id, data)
    return res.data
  },
  async count({commit}, params) {
    const res = await QuestionGenerate.count(params)
    commit('setCount', res.data)
    return res.data
  },
}

const mutations = {
  setCount(state, data) {
    return (state.count = data)
  },
  setQuestionGenerates(state, data) {
    return (state.QuestionGenerates = data)
  },
  removeQuestionGenerate(state, id) {
    state.QuestionGenerates = state.QuestionGenerates.filter((val) => val.id !== id)
  },
  setOneQuestionGenerate(state, data) {
    state.QuestionGenerate = data
  },
}

const getters = {
  getAll: (state) => {
    return state.QuestionGenerates
  },
  getOne: (state) => {
    return state.QuestionGenerate
  },
  getCount: (state) => {
    return state.count
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
