import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Session = RepositoryFactory.get('session')
const namespaced = true

const state = {
  timeTables: [],
  sessions: [],
}

const actions = {
  async createGenTimeTables({commit}, params) {
    const res = await Session.genTimetables(params)
    commit('createGenTimeTables', res.data)
    return res.data || []
  },

  async fetchSessions({commit}, params) {
    const res = await Session.fetch(params)
    commit('setSessions', res.data || [])
    return res.data || []
  },
  async fetchSessionsIndependent(_, params) {
    const res = await Session.fetch(params)
    return res.data || []
  },
  setTimeTableImmediately({commit}, dates) {
    commit('setTimeTableImmediately', dates)
  },
}

const mutations = {
  createGenTimeTables(state, data) {
    state.timeTables = [...data]
  },
  setSessions(state, data) {
    state.sessions = [...data]
  },
  setTimeTableImmediately(state, dates) {
    state.timeTables = dates
  },
}

const getters = {
  getTimeTable: (state) => state.timeTables,
  getAllSession: (state) => state.sessions,
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
