import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Rating = RepositoryFactory.get('rating')

const state = {
  ratings: [],
  count: 0
}

const actions = {
  async fetchRatings({ commit }, options) {
    let result = {}
    try {
      let res = await Rating.fetch({ ...options })
      commit('setRatings', res.data || [])
    } catch (error) {
      result.error = error
    }
    return result
  },
  async approveRatings({ commit }, items = []) {
    let result = {}
    const promises = items.map(({ id }) => {
      return Rating.approve(id)
    }).filter(Boolean)
    try {
      const results = await Promise.all(promises)
      if (results && results.length) {
        results.forEach(result => {
          commit("addOrUpdateRate", result.data || {})
        })
      }
    } catch (error) {
      result.error = error
    }
    return result
  },
  async removeRatings({ commit }, items = []) {
    let result = {}
    const promises = items.map(({ id }) => {
      return Rating.remove(id)
    }).filter(Boolean)
    try {
      await Promise.all(promises)
      items.forEach(item => {
        commit("removeRating", { id: item.id })
      })
    } catch (error) {
      result.error = error
    }
    return result
  },
  async fetchCount({ commit }, params) {
    const res = await Rating.count(params)
    commit("setCount", res.data || 0)
  },
}

const mutations = {
  setRatings(state, list) {
    state.ratings = list
  },
  addOrUpdateRate(state, item) {
    if (item && item._id) {
      let foundIndex = state.ratings.findIndex(rating => rating && rating.id === item._id)
      if (foundIndex >= 0) {
        state.ratings.splice(foundIndex, 1, item)
      } else {
        state.ratings.unshift(item)
      }
    }
  },
  setCount(state, count) {
    state.count = count
  },
  removeRating(state, item) {
    if (item && item.id) {
      let deleteIndex = state.ratings.findIndex(rating => rating && rating.id === item.id)
      if (deleteIndex > -1) {
        state.ratings = state.ratings.filter(rating => rating.id !== item.id)
        state.count = state.count > 0 ? state.count - 1 : 0
      }
    }
  }
}

const getters = {
  getRatings: (state) => state.ratings,
  getCount: (state) => state.count,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
