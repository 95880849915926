import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_OFFLINE_STACK + '/class-student'

const fetchClassStudent = (params) => {
  return Repository(baseURL).fetch(params)
}

const addStudent = (params) => {
  return Repository(baseURL).create(params)
}

const deleteStudent = (id) => {
  return Repository(baseURL).delete(id)
}

const createMany = (params) => {
  return Repository(baseURL + 's').create(params)
}

const count = (params) => {
  return Repository(baseURL + '/count').fetch(params)
}

export default {
  addStudent,
  fetchClassStudent,
  deleteStudent,
  createMany,
  count,
}
