import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const OfflineCourse = RepositoryFactory.get('offlineCourse')

const state = {
  courses: {},
  course: {},
  count: 0,
  isGlobalTeacher: false,
  teacherId: '',
  courseInTopic: []
}

const actions = {
  async create({commit}, data) {
    const res = await OfflineCourse.create(data)
    commit('addCourse', res.data)
    return res.data
  },
  async fetchAll({commit}, params = {}) {
    const res = await OfflineCourse.fetch(params)
    commit('setCourses', res.data || [])
    return res.data
  },
  async fetchCourseById({commit}, id) {
    const res = await OfflineCourse.fetchOne(id)
    commit('setCourse', res.data || {})
    return res.data
  },
  async update({commit}, {id, ...courseInfo}) {
    const res = await OfflineCourse.update(id, courseInfo)
    commit('setCourse', res.data)
    return res.data
  },
  async remove({commit}, id) {
    await OfflineCourse.remove(id)
    return commit('removeCourse', id)
  },
  async setCourse({commit}, course) {
    return commit('replaceCourse', course)
  },
  setTeacherConfig({commit}, params) {
    return commit('setTeacherConfig', params)
  },
  async getCourseInTopic({commit}, params = {}) {
    const res = await OfflineCourse.fetch(params)
    commit('setCourseInTopic', res.data)
    return res.data
  }
}

const mutations = {
  addCourse(state, course) {
    state.count = state.count + 1
    return (state.courses = {
      [course.id]: course,
      ...state.courses,
    })
  },
  setCourses(state, courses) {
    return (state.courses = courses.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {}))
  },
  setCourse(state, course) {
    state.course = course
  },
  setCount(state, count) {
    return (state.count = count)
  },
  removeCourse(state, id) {
    delete state.courses[id]
    state.courses = {...state.courses}
  },
  replaceCourse(state, course) {
    return (state.courses = {
      ...state.courses,
      [course.id]: course,
    })
  },
  setTeacherConfig(state, {isGlobalTeacher, teacherId}) {
    state.isGlobalTeacher = isGlobalTeacher
    state.teacherId = teacherId
  },
  setCourseInTopic(state, val) {
    return state.courseInTopic = val
  }
}

const getters = {
  getCourses: (state) => {
    return Object.values(state.courses)
  },
  getCourse: (state) => {
    return state.course
  },
  getCount: (state) => {
    return Object.keys(state.courses).length
  },
  getTeacherId: (state) => state.teacherId,
  getIsGlobalTeacher: (state) => state.isGlobalTeacher,
  getCourseInTopic: (state) => {
    return state.courseInTopic
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
1