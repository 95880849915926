import BaseConfirmSupport from '@/global/BaseConfirmSupport.vue'
const _initConfirmData = {
  state: false,
  title: "",
  text: "",
  okText: 'Đồng ý',
  persistent: false,
  confirmColor: "",
  cancelText: 'Huỷ',
  hideCancel: false,
  hideOk: false,
  done() { },
  cancel() { },
};

const confirmSupport = {
  confirmData: { ..._initConfirmData },
  show(dialogData = {}) {
    Object.assign(this.confirmData, {
      state: true,
      confirmColor: "primary",
      hideCancel: false,
      ...dialogData
    })
    console.log(this.confirmData)
  },
  cancel() {
    this.confirmData.state = false
    this.confirmData.cancel()
    setTimeout(() => {
      Object.assign(this.confirmData, _initConfirmData)
    }, 500)
  }
}

export default {
  install(Vue, pluginName = '$confirmSupport') {
    Vue.prototype[pluginName] = confirmSupport
    Vue.component('plugin-confirm-support', BaseConfirmSupport)
  }
}
export { confirmSupport }
