import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_PHYSICAL_PRODUCT + '/product'
import axios from 'axios'
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'

const fetch = params => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
  return Repository(baseURL).fetchOne(id)
}

const create = params => {
  return Repository(baseURL).create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const remove = id => {
  return Repository(baseURL).delete(id)
}

const count = params => {
  return Repository(baseURL).count(params)
}

const fetchOneWithParams = (id, params) => {
  return axios.get(baseURL + '/' + id, {
    params: {
      ...params,
      vendorId: vendor.state.vendor.vendorId
    },
    headers: {
      Authorization: auth.state.auth.accessToken
    }
  })
}

export default {
  fetch,
  fetchOne,
  create,
  update,
  remove,
  count,
  fetchOneWithParams
}
