import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_EXAM + '/exam'

const fetch = params => {
	return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const create = params => {
	return Repository(baseURL).create(params)
}

const createExamMatrix = params => {
	return Repository(baseURL + '/createExamMatrix').create(params)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const generateExam = (params) => {
	return Repository(baseURL + '/generate').create(params)
}

export default {
	update,
	create,
	fetch,
	fetchOne,
	generateExam,
	createExamMatrix,
}
