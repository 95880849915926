import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const LogRepository = RepositoryFactory.get('log')
const namespaced = true

const state = {
  logs: {},
  log: {},
  count: 0,
}

const actions = {
  async fetchLogs({commit}, params = {}) {
    const res = await LogRepository.fetch({
      ...params,
    })
    commit('setLogs', res.data.items || [])
    commit('setCount', res.data.total || 0)
    return res.data
  },
  async countLogs({commit}, params = {}) {
    const res = await LogRepository.count({
      ...params,
    })
    commit('setCount', res.data || 0)
  },
}

const mutations = {
  setCount(state, count) {
    return (state.count = count)
  },
  setLogs(state, logs) {
    return (state.logs = logs.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {}))
  },
}

const getters = {
  logs: (state) => {
    return Object.values(state.logs)
  },
  count: (state) => {
    return state.count
  },
}
export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
