const state = {
    messageError: '',
};

const actions = {
    setMessageError({commit}, error) {
        commit('setMessageError', error)
    },
    removeMessageError({commit}) {
        commit('removeMessageError')
    },
};

const mutations = {
    setMessageError(state, messageError) {
        state.messageError = messageError
    },
    removeMessageError(state) {
        state.messageError = ''
    },
};

const getters = {
    getMessageError(state) {
        return state.messageError
    },
};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}