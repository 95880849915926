import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Group = RepositoryFactory.get('group')
const UserGroup = RepositoryFactory.get('userGroup')
const namespaced = true

const state = {
  groups: [],
  groupsByUser: [],
  groupsByCourse: [],
  group: {},
  count: 0,
}

const actions = {
  async fetchGroups({commit}, params = {}) {
    const res = await Group.fetch({
      ...params,
    })
    commit('setGroups', res.data || [])
    return res.data
  },
  async countGroup({commit}, params) {
    const res = await Group.count(params)
    commit('setCount', res.data)
    return res.data
  },
  async updateGroup({commit, state}, {id, group}) {
    const res = await Group.update(id, group)
    commit('setOneGroup', {...res.data, courses: state.group.courses, students: state.group.students})
    return res.data
  },
  async fetchGroupsByUserId({commit}, params = {}) {
    // todo: remove change to user group
    const res = await UserGroup.fetch(params)
    const listGroups = res.data.map((i) => i.group)
    commit('setGroupsForUser', listGroups || [])
  },
  async fetchGroupsByCourseId({commit}, params = {}) {
    const res = await Group.fetch({
      ...params,
    })
    commit('setGroupsForCourse', res.data || [])
    return res.data
  },
  async fetchGroupById({commit}, id) {
    const res = await Group.fetchOne(id)
    commit('setOneGroup', res.data)
    return res.data
  },
  async createGroup({commit}, data) {
    const res = await Group.create(data)
    commit('addGroup', res.data)
    return res.data
  },
  async deleteGroup({commit}, id) {
    await Group.remove(id)
    return commit('removeGroup', id)
  },
  async addUsers(_, {groupId, users}) {
    await Group.addUsers(groupId, {users})
  },
  async removeUsers(_, {groupId, users}) {
    await Group.removeUsers(groupId, {users})
  },
  async addProducts({dispatch}, {groupId, products}) {
    await Group.addProducts(groupId, {products})
    dispatch('fetchGroupById', groupId)
  },
  async removeProducts({dispatch}, {groupId, products}) {
    await Group.removeProducts(groupId, {products})
    dispatch('fetchGroupById', groupId)
  },
}

const mutations = {
  setGroups(state, groups) {
    return (state.groups = groups)
  },
  setGroupsForUser(state, groups) {
    return (state.groupsByUser = groups)
  },
  setCount(state, count) {
    return (state.count = count)
  },
  setGroupsForCourse(state, groups) {
    return (state.groupsByCourse = groups)
  },
  addGroup(state, group) {
    state.groups.unshift(group)
    return state.groups
  },
  setGroup(state, newGroup) {
    state.groups = state.groups.map((group) => {
      if (group._id === newGroup._id) {
        return newGroup
      } else return group
    })
    return state.groups
  },
  removeGroup(state, id) {
    state.groups = state.groups.filter((group) => group.id !== id)
  },
  setOneGroup(state, group) {
    state.group = group
  },
  resetOneGroup(state) {
    state.group = {}
  },
}

const getters = {
  getGroups: (state) => {
    return state.groups.sort((a, b) => {
      return b.updatedAt - a.updatedAt
    })
  },
  getGroupsByUser: (state) => {
    return state.groupsByUser.reverse()
  },
  getGroupsByCourse: (state) => {
    return state.groupsByCourse.reverse()
  },
  getGroup: (state) => {
    return state.group
  },
  getCountGroup: (state) => {
    return state.count
  },
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
