import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_RATING + '/rating'

const count = (params) => {
  return Repository(baseURL).count(params)
}
const fetch = (params) => {
  return Repository(baseURL).fetch(params)
}
const approve = (id, params) => {
  return Repository(baseURL + '-approve').update(id, params)
}
const remove = (id) => {
  return Repository(baseURL).delete(id)
}

export default {
  fetch,
  approve,
  remove,
  count,
}