const BLOG_LIST = {
	TITLE: 'ブログ',
	BUTTON_ADD: '記事の作成',
	LABEL_NAME: 'ブログ名',
	PLACEHOLDER_NAME: '記事名で検索してください',

	//EMTY DATA
	TITLE_EMTY_DATA: '記事が存在しません',
	SUBTITLE_EMTY_DATA: '新しい記事を作成してください',

	TH_NAME: '記事名',
	TH_POSTING_TIME: '掲載日時',
	TH_VIEW: '閲覧回数',
	TH_ACTION: '操作',
	TOOLTIP_EDIT_BLOG: '投稿を編集',
	BUTTON_CREATE_COPY: 'コピー作成',

	POPUP_CONFIRM_CREATE_COPY_TITLE: '記事のコピー作成',
	POPUP_CONFIRM_CREATE_COPY_MESSAGE: 'この記事のコピーを作成しますか',
	MSG_CREATING: '記事のコピーを作成しています.'
}
const BLOG_DETAIL = {
	TITLE_NEW_BLOG: '新規ブログ',
	LINK_RETURN_TO_BLOG_LIST: '記事一覧',

	// BLOG_INFO
	TITLE_INFO: '記事情報',
	LABEL_BLOG_TITLE: '記事タイトル',
	PLACEHOLDER_IMPORT_BLOG_TITLE: '記事タイトルを入力してください',
	LABEL_AUTHOR_NAME: '著者',
	PLACEHOLDER_IMPORT_AUTHOR: '著者氏名を入力してください',

	TITLE_DISCUSSION: '議論',
	SUBTITLE_DISCUSSION: 'ユーザーがページにコメントできます .',
	CHECKBOX_TURN_ON_COMMENT_FACEBOOK:
		'ページの最後にFacebookコメント欄を表示します',
	LABEL_CONFIGURATION_APPID: 'AppId構成',
	PLACEHOLDER_CONFIGURATION_APPID: '例:1110049499506',

	// BLOG_DESCRIPTION
	TITLE_DESCRIPTION: '記事内容',
	// BLOG_IMAGE
	TITLE_IMAGE: '記事のアバター画像',
	SUBTITLE_IMAGE: '記事一覧に表示される画像',
	// BLOG_PUBLISH
	TITLE_PUBLISH: '記事の公開',
	SUBTITLE_PUBLISH:
		'この記事がウェブサイトに公開されていて、各種検索エンジンで検索されます。',
	SUBTITLE_HIDDEN_ARTICLE:
		'この記事が非表示されていて、ユーザが記事にアクセスできません。',
	BUTTON_HIDE: '非表示',
	BUTTON_PUBLIC: '公開',
	// BLOG_DELETION
	TITLE_DELETION: '記事の削除',
	SUBTITLE_DELETION: '削除された記事は回復できません',
	BUTTON_DELETE: '削除',
	POPUP_CONFIRM_DELETE_BLOG_TITLE: 'システムから記事削除',
	POPUP_CONFIRM_DELETE_BLOG_MESSAGE: '削除されたデータは回復できません。',
	MSG_DELETING_ARTICLE: '記事を削除しています。',
	MSG_DELETE_SUCCESS: '記事を削除しました。',

	//MESSAGE
	MSG_CHECK_BLOG_TITLE_AGAIN: '記事タイトルを入力してください',
	MSG_CREATING: '新しい記事を作成しています。',
	MSG_CREATE_SUCCESS: '新しい記事を作成しました。',
	MSG_FAIL: '新しい記事を作成できません。',
	MSG_UPDATING: '記事を更新しています。',
	MSG_UPDATE_SUCCESS: '記事を更新しました。',
	MSG_UPDATE_FAIL: '記事を更新できません。',
	MSG_LOADDING: '記事詳細を読み込んでいます。'
}
export default {
	BLOG_LIST,
	BLOG_DETAIL,

	BUTTON_REMOVE: '削除',
	BUTTON_ADD: '追加',
	BUTTON_SAVE: '保存',
	BUTTON_CANCEL: 'キャンセル',
	BUTTON_CONFIRM: '確認',
	BUTTON_ADD_IMAGE: '画像追加',
	LINK_RETURN_CONTENT_BLOCK_LIST: 'コンテンツブロック一覧'
}
