export default {
  badge: 'Badge',
  close: 'Close',
  dataIterator: {
    noResultsText: 'No matching data found',
    loadingText: 'Loading data...',
  },
  dataTable: {
    itemsPerPageText: 'Number of lines per page:',
    ariaLabel: {
      sortDescending: 'Sort descending.',
      sortAscending: 'Sort Ascending.',
      sortNone: 'Do not sort.',
      activateNone: 'Activate to unsort.',
      activateDescending: 'Activate to sort descending.',
      activateAscending: 'Activate to sort ascending.',
    },
    sortBy: 'Sort by',
  },
  dataFooter: {
    itemsPerPageText: 'Number of lines to display:',
    itemsPerPageAll: 'All',
    nextPage: 'Next Page',
    prevPage: 'Previous Page',
    firstPage: 'First Page',
    lastPage: 'Last Page',
    pageText: '{0}-{1} on {2}',
  },
  datePicker: {
    itemsSelected: 'Selected {0}',
    nextMonthAriaLabel: 'Next month',
    nextYearAriaLabel: 'Next year',
    prevMonthAriaLabel: 'Last month',
    prevYearAriaLabel: 'Last year',
  },
  noDataText: 'No data',
  carousel: {
    prev: 'Before',
    next: 'After',
    ariaLabel: {
      delimiter: 'Slide {0} on {1}',
    },
  },
  calendar: {
    moreEvents: 'Still {0}',
  },
  fileInput: {
    counter: '{0} file',
    counterSize: '{0} files ({1} in all)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Page Transition',
      next: 'Next page',
      previous: 'Previous page',
      page: 'Go to page {0}',
      currentPage: 'Current page, page {0}',
    },
  },
}