<template>
  <iframe v-show="false" src="/xlsx-style/index.html" id="base-excel-style-export" />
</template>
<script>
export default {
  methods: {
    triggerExport(data) {
      document.getElementById('base-excel-style-export').contentWindow.postMessage(data)
    },
  },
}
</script>
