import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_UTILITY + '/commission'

const fetch = params => {
	return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const create = params => {
	return Repository(baseURL).create(params)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const remove = id => {
	return Repository(baseURL).delete(id)
}

const countBill = params => {
	return Repository(baseURL + '/count').fetch(params)
}

const getRevenue = params => {
	return Repository(baseURL + '/revenue').fetch(params)
}

const getTotalCommission = params => {
	return Repository(baseURL + '/total').fetch(params)
}

export default {
	fetch,
	fetchOne,
	create,
	update,
	remove,
	countBill,
	getRevenue,
	getTotalCommission
}
