<template>
	<div @click="onSelect" class="relative wrapper">
		<div class="relative box-thumbnail">
			<img v-if="!isloading" class="image-inside" :src="link" />
			<v-progress-circular
				v-else
				class="mt-12"
				indeterminate
				color="white"
			></v-progress-circular>
		</div>
		<v-icon
			v-show="isShowIcon"
			dense
			large
			class="checkbox"
			color="primary"
			right
		>
			mdi-checkbox-marked-circle
		</v-icon>
	</div>
</template>
<script>
export default {
	props: {
		isShowIcon: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: 'Untitled'
		},
		link: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			isloading: false
		}
	},
	methods: {
		onSelect() {
			this.$emit('onSelect')
		}
	},
	watch: {
		link: {
			handler() {
				this.isloading = true
				setTimeout(() => {
					this.isloading = false
				}, 500);
			},
			immediate: true
		}
	}
}
</script>
<style lang="scss" scoped>
.box-thumbnail {
	width: 135px;
	height: 135px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	background: gray;
	border-radius: 4px;
	.image-inside {
		border-radius: 4px;
		width: 100%;
		transition: 0.1s all ease-in-out;
		height: 100%;
		object-fit: cover;
		&:hover {
			transform: scale(1.15);
		}
	}
}
.wrapper {
	width: 135px;
	height: 135px;
	.checkbox {
		z-index: 10;
		position: absolute;
		right: -12px;
		top: -12px;
		background: white;
		border-radius: 50%;
	}
}
</style>
