<template>
	<div class="full-width d-flex align-center">
		<v-icon
			class="cursor-pointer"
			@click="changeResolution('mobile', '400px')"
			medium
			:color="
				resolution === 'mobile' ? 'primary' : 'grey lighten-2'
			"
			>mdi-cellphone-iphone</v-icon
		>
		<v-icon
			class="mr-4 ml-4 cursor-pointer"
			@click="changeResolution('tablet', '800px')"
			:color="
				resolution === 'tablet' ? 'primary' : 'grey lighten-2'
			"
			medium
			>mdi-tablet-ipad</v-icon
		>
		<v-icon
			class="cursor-pointer"
			@click="changeResolution('desktop', '100%')"
			medium
			:color="
				resolution === 'desktop' ? 'primary' : 'grey lighten-2'
			"
			>mdi-laptop-mac</v-icon
		>
	</div>
</template>
<script>
export default {
	data() {
		return {
			resolution: 'desktop',
		}
	},
	methods: {
		changeResolution(type, value) {
			this.resolution = type
			this.$emit('changeResolution', value)
		},
	},
}
</script>