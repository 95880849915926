<template>
  <div>
    <div>
      <FillBlank :questionId="question.id" v-if="isFillBlank" />
      <MultipleChoice :question="question" v-if="isMultipleChoice" />
      <SingleChoice :question="question" v-if="isSingleChoice" />
    </div>
    <div v-if="question.correct">
      <span class="font-weight-medium">Đáp án:&nbsp;</span>
      <span class="green--text" v-html="correct" />
    </div>
    <!-- <div v-if="hasExplain">
      <span class="font-weight-medium">Lời giải chi tiết:</span>
      <div v-html="explain" />
    </div> -->
  </div>
</template>
<script>
const FILL_BLANK_TYPES = ['fill-blank', 'fill-blank-child']
const MULT_CHOICE_TYPES = ['multiple-choice', 'multiple-choice-child']
const SIGL_CHOICE_TYPES = ['single-choice', 'single-choice-child']
import get from 'lodash-es/get'
import FillBlank from './FillBlank.vue'
import MultipleChoice from './MultipleChoice.vue'
import SingleChoice from './SingleChoice.vue'
import {handleCorrect} from '@/helpers/components/pdfRendererUtils'
export default {
  components: {
    FillBlank,
    MultipleChoice,
    SingleChoice,
  },
  props: {
    question: Object,
  },
  computed: {
    isFillBlank() {
      return FILL_BLANK_TYPES.includes(this.question.type)
    },
    isMultipleChoice() {
      return MULT_CHOICE_TYPES.includes(this.question.type)
    },
    isSingleChoice() {
      return SIGL_CHOICE_TYPES.includes(this.question.type)
    }
  },
  data() {
    return {
      correct: '',
      hasExplain: false,
      explain: '',
    }
  },
  watch: {
    question: {
      handler(val) {
        if (val) {
          this.correct = handleCorrect(val)
          const isShowExplain = get(val, 'config.isShowExplain', false)
          this.hasExplain = isShowExplain
          if (isShowExplain) this.explain = get(val, 'config.explain', '')
        }
      },
      immediate: true,
    },
  },
}
</script>
