const DOMAIN_SETTING_FORM = {
	TITLE: 'Page Domain',
	SUBTITLE: 'Please contact customer support to change the domain name'
}
const BASIC_SETTINGS = {
	TITLE: 'Basic Settings',

	TAB_BRANDING: 'Brand',
	TAB_EMAIL: "Cấu hình email",
	TAB_TRANSACTION_PAYMENT: 'Transactions & Payments',

	//TRADE_MARK
	TM_TITLE_DOMAIN_NAME: 'Page Domain',
	TM_SUBTITLE_DOMAIN_NAME:
		'Please contact customer support to change the domain name',

	TM_TITLE_ADDRESS_EMAIL: 'Email Address',
	TM_LABEL_EMAIL_PAGE: 'Page Email',
	TM_PLACEHOLDER_EMAIL_PAGE: 'Example: tuannhhe@gmail.com',
	TM_HINT_EMAIL_PAGE:
		'Students will receive an activation code and notification from this email address.',
	TM_LABEL_PASSWORD: 'Password',
	TM_PLACEHOLDER_PASSWORD: 'Example: ******',
	TM_HINT_PASSWORD:
		'The system needs an email password to access and set up automatic sending. Any questions about security, please contact customer support.',

	TM_TITLE_EMAIL_PAGE: 'Page Email',
	TM_SUBTITLE_EMAIL_PAGE:
		'Students will receive an activation code and notification from this email address.',

	TM_TITLE_CHANGE_ADMIN_PAGE_NAME: 'Rename Admin page',
	TM_LABEL_LABEL_PAGE_NAME: 'Page Name',
	TM_HINT_PAGE_NAME:
		'Recommended: Page name should not be more than 20 characters',

	TM_TITLE_SETTING_LINK_SOCIAL_NETWORK: 'Social network link settings',
	TM_LABEL_LINK_FACEBOOK: 'Facebook link',
	TM_LABEL_LINK_YOUTUBE: 'YouTube Link',
	TM_LABEL_LINK_TIKTOK: 'Tiktok link',
	TM_LABEL_LINK_INSTAGRAM: 'Instagram link',
	TM_PLACEHOLDER_ENTER_LINK: 'Enter path',

	TM_BUTTON_RESET: 'Reset',
	TM_BUTTON_ENTER_EXPORT_HTML: 'Import/Export HTML',
	TM_BUTTON_SAVE: 'Save',

	//TRANSACTON AND PAY
	TP_TITLE_TRANSFER_INFO: 'Transfer Information',
	TP_SUBTITLE_TRANSFER_INFO:
		'Transfer information will be displayed in the Transfer information - Course payment.',

	TP_LABEL_BANK_NAME: 'Bank name',
	TP_PLACEHOLDER_BANK_NAME: 'Example: Example',
	TP_LABEL_ACCOUNT_NAME: 'Account Name',
	TP_PLACEHOLDER_ACCOUNT_NAME: 'Example: Nguyen Van A',

	TP_LABEL_ACOUNT_NUMBER: 'Account Number',
	TP_PLACEHOLDER_ACOUNT_NUMBER: 'Example: 00123212312312',

	TP_LABEL_TRANSFER_CONTENT_FORM: 'Sample Transfer Content',
	TP_PLACEHOLDER_TRANSFER_CONTENT_FORM:
		'Example: Nguyen Van A - Grade 12D - Figure 12',
	HINT_TRANSFER_CONTENT:
		'Sample Transfer Content can be used to suggest students to enter information that is easy to verify.',

	TP_TITLE_VNPAY_CONFIG: 'Configuration',
	TP_SUBTITLE_VNPAY_CONFIG: 'Configuring VNPay payment system',
	TP_LABEL_VNP_TMNCODE: 'vnp_TmnCode',
	TP_PLACEHOLDER_VNP_TMNCOD: 'Example: Example',
	TP_LABEL_VNP_HASHSECRET: 'vnp_HashSecret',
	TP_PLACEHOLDER_VNP_HASHSECRET: 'Example: JKLWEUPOCXMNWHKSDJ',

	//TEMPLATE TITLE
	TT_REGISTER_EMAIL: 'Email for account registration',
	TT_FORGOT_PASSWORD_EMAIL: 'Forgot password email',
	TT_CHANGE_PASSWORD_EMAIL: 'Password change email',
	TT_APPROVED_COURSE_EMAIL: 'Course ownership confirmation email',

	//BUTTON
	BUTTON_RESET: 'Reset',
	BUTTON_SAVE: 'Save',
	BUTTON_ENTER_EXPORT_HTML: 'Import/Export HTML',

	//CHECKBOX
	CHECKBOX_APPEAR: 'Show',
	CHECKBOX_HIDE: 'Hide',

	//RADIUS
	RADIUS_APPEAR: 'Show',
	RADIUS_HIDE: 'HIDE',

	//MESSAGE
	MSG_UPDATE_LOADING: 'Updating...',
	MSG_UPDATE_ACCOUNT_LOADING: 'Updating account ...',
	MSG_UPDATE_PAGE_NAME_LOADING: 'Updating page name ...',
	MSG_CHECK_PAGE_NAME: 'Please enter a page name',
	MSG_CHECK_INFO_AGAIN: 'Please check the information again!',
	MSG_CHECK_FULL_INFO_AGAIN: 'Please enter full information!',
	MSG_UPDATE_ACCOUNT_SUCCESS: 'Account update successful!',
	MSG_UPDATE_SUCCESS: 'Update successful',
	MSG_UPDATE_FAILURE: 'Update failed',
	MSG_UPDATE_DATA_FAILURE: 'Data update failed',
	MSG_UPDATE_DATA_SUCCESS: 'Update successful',
	MSG_UPDATE_DATA_FAIL: 'Update successful',

	PLACEHOLDER_SELECT_FILE_UPLOAD: 'Select file and upload'
}
const ADVANCED_SETTING = {
	TITLE: 'Advanced Settings',

	TAB_VIRTUAL_CLASS_ZOOM: 'Virtual Classroom (ZOOM)',
	TAB_USER_INTERACTION: 'User Interaction',
	TAB_CODE_DEPOSIT_AND_FOLLOW: 'Embed & TRACK CODE',

	LABEL_ZOOM_NOTE:
		'Now you can link your Zoom Virtual Classroom to the system. Sort the list of Zoom classrooms via the button below.',
	LABEL_ZOOM_ACTIVATED: 'Enable Zoom and display Zoom button on home page',

	//VIRTUAL CLASS ZOOM
	VCZ_LABEL_NOTE_OF_ZOOM:
		'Now you can link your Zoom Virtual Classroom to the system. Sort the list of Zoom classrooms via the button below.',
	VCZ_LABEL_ACTIVE_ZOOM_AND_DISBPAY_BUTTON_ZOOM:
		'Enable Zoom and show Zoom button on home page',
	BUTTON_MANAGE_ZOOM_CLASS: 'Manage Zoom Classroom',

	//DEPOSIT & FOLLOW
	DF_PLACEHOLDER_ENTER_CODE_NAME: 'Enter a codename',
	DF_TITLE_NEW_SCRIPT_CODE: 'New Javascript Code',
	DF_SUBTITLE_NEW_SCRIPT_CODE:
		'Embedded code often has the potential for security holes. The system is not responsible for data loss cases.',
	DF_DROPDOWN_LABEL_PLACE_CODE: 'Code Location',

	//USER INTERACTION
	//USER INTERACT

	//BUTTON
	BUTTON_ADD_NEW_SCRIPT: 'Add New Script',
	BUTTON_REMOVE_SCRIPT_FORM: 'Delete',
	BUTTON_SAVE_SCRIPT_FORM: 'Save',

	//RADIUS
	RADIUS_APPEAR: 'Show',
	RADIUS_HIDE: 'Hide',

	//MASSAGE
	MSG_UPDATE_DATA_LOADING: 'Updating data...',
	MSG_UPDATE_DATA_SUCCESS: 'Data update successful'
}
const EMAIL_ADDRESS_SETTING_FORM = {
	TITLE: 'Email address',
	LABEL_EMAIL_ADDRESS: 'Page Email',
	PLACEHOLDER_EMAIL_ADDRESS: 'Ex: tuannhhe@gmail.com',
	HINT_EMAIL_ADDRESS:
		'Students will receive an activation code and notification from this email address.',
	LABEL_PASSWORD: 'Password',
	PLACEHOLDER_PASSWORD: 'Example: ******',
	HINT_PASSWORD:
		'The system needs an email password to access and set up automatic sending. For any security questions, please contact customer support.',
	MSG_UPDATING: 'Updating account...',
	MSG_UPDATED: 'Account update successful!',
	MSG__CHECK_FULL_INFO: 'Please enter full information!'
}

const EMAIL_CONTENT_SETTING_FORM = {
	TITLE: 'Customize email content',
	SUBTITLE:
		'Students will receive an activation code and notification from this email address.',
	//LIST TEMPLATE EMAIL
	TITLE_REGISTTRATION: 'Email for account registration',
	TITLE_FORGOT_PASSWORD: 'Email forgot password',
	TITLE_CHANGE_PASSWORD: 'Password change email',
	TITLE_COURSE_APPROVING: 'Course ownership confirmation email',
	TEXT_HTML: 'HTML TEXT',
	BUTTON_UPDATE_BY_TEMPLATE: 'HTML Import/Export',
	BUTTON_IMPORT_TEMPLATE: 'Import HTML file',
	BUTTON_DOWNLOAD_TEMPLATE: 'Download sample file',
	BUTTON_CANCEL: 'Cancel',
	BUTTON_INSTRUCT: 'Instructions',
	
	POPUP_TITLE_RESET_EMAIL: 'Reset Email',
	POPUP_SUBTITLE_RESET_EMAIL:
		'This will delete the email content you set up below and replace it with the default email. Are you sure you want to do ?',
	MSG_UPDATING: 'Updating email templates...',
	MSG_DOWLOAD: 'Loading...'
}
const ADMIN_PAGE_NAME_SETTING_FORM = {
	TITLE: 'Admin page name',
	SUBTITLE: 'Tên trên đường link truy cập',
	LABEL_PAGE_NAME: 'Page Name',
	PLACEHOLDER_PAGE_NAME: 'Example: HA NOI EDUCATION',
	HINT_PAGE_NAME:
		'Recommended: Page name should not be more than 20 characters',
	MSG_UPDATING: 'Updating site name...',
	MSG_PAGE_NAME_IS_REQUIRED: 'Please enter a page name'
}
const SOCIAL_LINKS_SETTING_FORM = {
	TITLE: 'Social Network Link',
	LABEL_FACEBOOK: 'Facebook path',
	LABEL_YOUTUBE: 'youtube link',
	LABEL_TIKTOK: 'Tiktok path',
	LABEL_INSTAGRAM: 'Instagram link',
	PLACEHOLDER_LINK: 'Enter path'
}
const BANKING_INFOS_FORM = {
	TITLE: 'Transfer Information',
	SUBTITLE:
		'Transfer information will be displayed in the Transfer information - Course payment.',
	LABEL_BANK_NAME: 'Bank name',
	PLACEHOLDER_BANK_NAME: 'Example: IZTEACH',
	LABEL_ACCOUNT_HOLDER_NAME: 'Account Name',
	PLACEHOLDER_ACCOUNT_HOLDER_NAME: 'Ex: NGUYEN VAN A',
	LABEL_ACOUNT_NUMBER: 'Account Number',
	PLACEHOLDER_ACOUNT_NUMBER: 'Example: 00123212312312',
	LABEL_TRANSFER_CONTENT_SAMPLE: 'Sample Transfer Content',
	PLACEHOLDER_TRANSFER_CONTENT_SAMPLE:
		'Example: Nguyen Van A - Grade 12D - Picture 12',
	HINT_TRANSFER_CONTENT_SAMPLE:
		'The Sample Transfer Text can be used to prompt students to enter information that is easy to verify.',
	LABEL_WORKING_HINT: 'Note in payment',
	PLACEHOLDER_WORKING_HINT: 'This note will be displayed when buyer choose bank-transfer option',
}
const ONLINE_PAYMENT_GATEWAY_SETTING_FORM = {
	TITLE: 'Online Payment Gateway',
	SUBTITLE: 'Configuring VNPay payment system',
	LABEL_VNP_TMNCODE: 'vnp_TmnCode',
	PLACEHOLDER_VNP_TMNCODE: 'Example: IZTEACH',
	LABEL_VNP_HASHSECRET: 'vnp_HashSecret',
	PLACEHOLDER_VNP_HASHSECRET: 'Ex: JKLWEUPOCXMNWHKSDJ',
	LABEL_WORKING_HINT: 'Note in payment',
	PLACEHOLDER_WORKING_HINT: 'This note will be displayed when buyer choose VNPay payment option',
	TOGGLE_SHOW_PAYMENT_OPTION: 'Show VNPay payment option',
	TOGGLE_HIDE_PAYMENT_OPTION: 'Hide VNPay payment option',
}
const DIRECT_PAY_SETTING_FORM = {
	TITLE: 'Direct pay setting',
	LABEL_WORKING_HINT: 'Note in payment',
	PLACEHOLDER_WORKING_HINT: 'This note will be displayed when buyer choose direct-pay option',
}
const ADVANCED_SETTINGS = {
	TITLE: 'Advanced Settings',
	TAB_VIRTUAL_CLASS_ZOOM: 'Virtual classroom (Zoom)',
	TAB_EMBEDED_SCRIPT: 'Embed & Tracking Code',
	TAB_USER_INTERACTION: 'User Interaction'
}
const VIRTUAL_CLASS_ZOOM_FORM = {
	SUBTITLE:
		'You can now link your Zoom Virtual Classroom with the IZTeach system. Sort the list of Zoom classrooms via the button below.',
	LABEL_ACTIVE_ZOOM: 'Enable Zoom and show Zoom button on home page',
	TOGGLE_SHOW_ZOOM_ACCESS_BUTTON: 'Show',
	TOGGLE_HIDE_ZOOM_ACCESS_BUTTON: 'Hide',
	BUTTON_MANAGE_ZOOM_CLASS: 'Manage Zoom Classroom'
}
const VIRTUAL_CLASS_LIST = {
	TITLE: 'Manage Zoom Classrooms',
	LINK_RETURN_TO_ADVANCED_SETTINGS: 'Advanced Settings',
	BUTTUN_ADD_CLASS: 'Add a Zoom session',
	LABEL_FILTER_BY_STATUS: 'Filter by state',
	PLACHOLDER_SELECT_STATUS: 'Select state',
	LABEL_FILTER_BY_STUDENT_GROUP: 'Filter by group',
	PLACEHOLDER_FILTER_BY_STUDENT_GROUP: 'Select a group of students',
	BUTTON_UPDATE_BY_TEMPLATE: 'Excel Import/Export',
	BUTTON_IMPORT_TEMPLATE: 'Import Excel file',
	BUTTON_DOWNLOAD_TEMPLATE: 'Download sample file',

	//EMPTY_DATA
	TITLE_EMPTY_DATA: 'No classrooms exist yet',
	SUBTITLE_EMPTY_DATA: 'Add a new lesson to show on this list',

	//TABLE HEADER
	TH_TITLE: 'Lesson Title',
	TH_TEACHER_NAME: 'Teacher in charge',
	TH_START_DATE: 'School day',
	TH_START_TIME: 'Class time',
	TH_STUDENT_GROUP: 'Group',
	TH_STATUS: 'Status',
	TH_ACTION: 'Action',
	//TABLE HEADER DOWNLOAD FILE ZOOM EXCEL
	TH_END_TIME: 'Time to expire',
	TH_END_DATE: 'Expiration Date',
	TH_LINK_ZOOM: 'Link zoom',
	TOOLTIP_EDIT_ZOOM: "Edit lesson",

	TEXT_STATUS_IN_FUTURE: 'Waiting',
	TEXT_STATUS_IN_PROGRESS: 'Ongoing',
	TEXT_STATUS_FINISHED: 'Ended',
	TEXT_CLASS: 'Classroom',
	TEXT_ALL: 'All',
	TEXT_NO_GROUP: 'No group',

	//POPUP REMOVE ZOOM CLASS
	TITLE_REMOVING_DIALOG: 'Are you sure to delete this session?',
	SUBTITLE_REMOVING_DIALOG: 'Data after deletion cannot be recovered',
	MSG_REMOVE_CLASS_SUCCESS: 'Delete the session successfully!',
	MSG_DOWLOAD_DATA_LOADING:
		'The system is loading data, please try again later!'
}
const VIRTUAL_CLASS_ADDING_FORM = {
	TITLE: 'More Zoom Lessons',
	LINK_TO_CLASS_LIST: 'Zoom Classroom List',
	TITLE_GENERAL_INFO: 'General Information',
	LABEL_TITLE: 'Lesson Title',
	PLACEHOLDER_TITLE: 'Enter class title',
	LABEL_TEACHER_NAME: 'Lecturer in charge',
	PLACEHOLDER_TEACHER_NAME: 'Enter instructor name',
	TITLE_DISPLAY_SETTINGS: 'Display settings',
	LABEL_STUDENT_GROUP: 'Select a group of students',
	PLACEHOLDER_STUDENT_GROUP: 'Select tutoring group name',
	//RADIO
	OPTION_RESTRICT_TO_STUDENT_GROUP: 'Show students in groups',
	OPTION_ALLOW_ALL_STUDENT: 'Visible to all students',

	TITLE_SCHEDULE: 'Lecture schedule',
	SUBTITLE_SCHEDULE:
		'The class schedule will be displayed according to the time below and allow the student to participate.',
	LABEL_START_DATE: 'Start Date',
	LABEL_START_TIME: 'Start Time',
	LABEL_END_DATE: 'End date',
	LABEL_END_TIME: 'Time End',

	TITLE_ZOOM_CONFIG: 'Configure Zoom',
	SUBTITLE_ZOOM_CONFIG: 'Copy and paste the invitation link in Invite here.',
	LABEL_LINK: 'New Path',
	PLACEHOLDER_LINK: 'Enter path',
	//MASSAGE
	MSG_CREATE_CLASS_SUCCESS: 'Successful session creation',
	MSG_CLASS_SCHEDULE_INCORRECT: 'The start time must be before the end time!'
}
const EMBEDED_SCRIPT_FORM = {
	BUTTON_ADD_NEW_SCRIPT: 'Add new script',
	TITLE_NEW_SCRIPT: 'New Javascript Code',
	PLACEHOLDER_SCRIPT_NAME: 'Enter a codename',
	SUBTITLE_NEW_JAVASCRIPT:
		'Embedded code often contains the risk of security holes. The system is not responsible for data loss cases.',
	LABEL_SCRIPT_LOCATION: 'Code Location',
	PLACEHOLDER_SCRIPT_LOCATION: 'Choose where to place the code'
}
const USER_PROFILE_SETTING_FORM = {
	TITLE: 'Students Profile Information',
	SUBTITLE:
		'Allow students to self-edit the information in their personal profile. New information will be updated on the system shortly.',
	TOGGLE_ALLOW_EDITING_PROFILE: 'Allow students to edit information in profile'
}
const REGISTRATION_SETTING_FORM = {
	TITLE: 'Registration Operation',
	TOGGLE_RESTRICT_USER_REGISTRATION:
		'Students are not allowed to register from the homepage.'
}
const VIDEO_VIEW_LIMIT_SETTING_FORM = {
	TITLE: 'Limit students video views',
	SUBTITLE:
		'At the learning page in the course, in video format, limit the number of views of students to 1 video (in a day)',
	TOGGLE_RESTRICT_VIDEO_VIEW_LIMIT: 'Limit students video views',
	LABEL_VIDEO_VIEW_LIMIT: 'Limit number of times',
	PLACEHOLDER_VIDEO_VIEW_LIMIT: 'Enter video viewing limit'
}
const DEFAULT_NOTIFICATION_SETTING_FORM = {
	TITLE: 'Set default notifications for anonymous users',
	SUBTITLE:
		'Show message number to anonymous users. Only a maximum of 2 default notifications should be set.',
	TOGGLE_ACTIVE_DEFAULT_NOTIFICATION: 'Show default message',
	BUTTON_ADD_DEFAULT_NOTICE: 'Add Notification',
	TH_TITLE: 'Title',
	TH_CONTENT: 'Content',
	TH_LINK: 'Path',
	TH_ACTION: 'Action',
	TOOLTIP_EDIT_DEFAULT_NOTIFICATION: 'Edit Notification',
}
const DEFAULT_NOTIFICATION_ADDING_FORM = {
	TITLE_NEW_NOTIFICATION: 'Create default notification',
	LABEL_TITLE: 'Title',
	PLACEHOLDER_TITLE: 'Enter message title',
	LABEL_CONTENT: 'Content',
	PLACEHOLDER_CONTENT: 'Enter message text',
	LABEL_LINK: 'Path',
	PLACEHOLDER_LINK: 'Enter message path',
	LABEL_LAST_UPDATE_AT: 'Update time',
	PLACEHOLDER_LAST_UPDATE_AT: 'Enter last update time',
	POPUP_TITLE_REMOVE_NOTIFICATION: 'Clear default notifications',
	POPUP_SUBTITLE_REMOVE_NOTIFICATION: 'Data after deletion cannot be recovered'
}
const LANGUAGE_SETTING_FORM = {
	TITLE: 'Set language for online learning site',
	SUBTITLE: 'Set display language, support Vietnamese and English.',
	LABEL_LANGUAGE: 'Language',
	PLACEHOLDER_LANGUAGE: 'Select language',
	TEXT_VIETNAMESE: 'Vietnamese',
	TEXT_ENGLISH: 'English',
	TEXT_JAPANESE: 'Japanese'
}
export default {
	DOMAIN_SETTING_FORM,
	BASIC_SETTINGS,
	EMAIL_ADDRESS_SETTING_FORM,
	EMAIL_CONTENT_SETTING_FORM,
	ADMIN_PAGE_NAME_SETTING_FORM,
	SOCIAL_LINKS_SETTING_FORM,
	BANKING_INFOS_FORM,
	ONLINE_PAYMENT_GATEWAY_SETTING_FORM,
	EMBEDED_SCRIPT_FORM,
	VIRTUAL_CLASS_ADDING_FORM,
	VIRTUAL_CLASS_LIST,
	VIRTUAL_CLASS_ZOOM_FORM,
	ADVANCED_SETTINGS,
	DEFAULT_NOTIFICATION_SETTING_FORM,
	VIDEO_VIEW_LIMIT_SETTING_FORM,
	REGISTRATION_SETTING_FORM,
	USER_PROFILE_SETTING_FORM,
	DEFAULT_NOTIFICATION_ADDING_FORM,
	LANGUAGE_SETTING_FORM,
	ADVANCED_SETTING,
	DIRECT_PAY_SETTING_FORM,
	BUTTON_SAVE: 'Save',
	BUTTON_CANCEL: 'Cancel',
	BUTTON_CONFIRM: 'Confirm',
	BUTTON_RESET: 'Reset',
	BUTTON_REMOVE: 'Delete',
	BUTTON_ENTER_FILE_HTML: 'Import HTML file',
	BUTTON_DOWNLOAD_FILE_SAMPLE: 'Download sample file',
	BUTTON_ENTER_EXPORT_HTML: 'Import/Export HTML',

	TITLE_EDIT: 'Edit',
	TITLE_CREATE: 'Create',
	TITLE_NOTIFICATION_DEFAULT: 'Default Message',

	MSG_UPDATING: 'Updating...',
	MSG_UPDATED: 'Update successful!',
	MSG_UPDATE_FAILED: 'Update failed!',
	MSG_INPUT_INCORRECT: 'Please check the information again!',
	MSG_RECHECK_INVALID_FIELD: 'Please check the information field again!',
	MSG_UPDATING_DATA: 'Updating data...',
	MSG_DATA_SUCCESS: 'Data update successful!',
	MSG_DATA_FAIL: 'Update failed!',
	MSG_DOWLOAD:'Download successful!'
}
