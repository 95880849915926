<template>
  <div>
    <BaseBuilderWrapper :title="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.SITE_NAME_TITLE')" class="mb-3">
      <BaseInput
        :label="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.SITE_NAME_LABEL')"
        :placeholder="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.SITE_NAME_PLACEHOLDER')"
        v-model="data.siteName"
        hide-details
      />
    </BaseBuilderWrapper>
    <BaseBuilderWrapper
      :title="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.LOGO_TITLE')"
      :subtitle="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.LOGO_DESCRIPTION')"
      class="mb-3"
    >
      <BaseUploadImage @onUploadSuccess="(data) => onUploadSuccess(data, 'logo_url')" :imageUrl="data.logo_url" />
    </BaseBuilderWrapper>
    <BaseBuilderWrapper :title="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.MAIN_COLOR_TITLE')" class="mb-3">
      <BaseColorPicker :init-data="data.color_primary" @save="saveColor" />
    </BaseBuilderWrapper>
    <BaseBuilderWrapper
      :title="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.FAVICON_TITLE')"
      :subtitle="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.FAVICON_DESCRIPTION')"
      class="mb-3"
    >
      <BaseUploadImage
        type="image"
        @onUploadSuccess="(data) => onUploadSuccess(data, 'favicon')"
        :imageUrl="data.favicon"
      />
    </BaseBuilderWrapper>
    <BaseBuilderWrapper
      :title="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.THUMBNAIL_TITLE')"
      :subtitle="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.THUMBNAIL_DESCRIPTION')"
      :isDividerShown="false"
    >
      <BaseUploadImage
        type="image"
        @onUploadSuccess="(data) => onUploadSuccess(data, 'thumbnail')"
        :imageUrl="data.thumbnail"
      />
    </BaseBuilderWrapper>
    <div>
      <v-divider class="mt-4 mb-2" />
      <div style="font-size: larger; font-weight: 600; margin-bottom: 5px">Cài đặt SEO</div>
      <BaseBuilderWrapper title="Tiêu đề trang" :isDividerShown="false">
        <BaseInput label="Tiêu đề trang" v-model="seo.seoTitle"></BaseInput>
      </BaseBuilderWrapper>
      <BaseBuilderWrapper title="Mô tả trang" :isDividerShown="false">
        <BaseTextArea label="Mô tả trang" v-model="seo.seoDescription" />
      </BaseBuilderWrapper>
      <BaseBuilderWrapper
        :title="$vuetify.lang.t('Ảnh')"
        :subtitle="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.THUMBNAIL_DESCRIPTION')"
        :isDividerShown="false"
      >
        <BaseUploadImage
          type="image"
          @onUploadSuccess="(data) => onUploadSuccess(data, 'seoImage', true)"
          :imageUrl="seo.seoImage"
        />
      </BaseBuilderWrapper>
    </div>
  </div>
</template>
<script>
import BaseBuilderWrapper from '@/templates/wrapper/BaseBuilderWrapper'
import {clone, get} from 'lodash-es'
import {mapGetters} from 'vuex'
export default {
  components: {
    BaseBuilderWrapper,
  },
  props: {
    viewData: {
      type: Object,
    },
    seoData: {
      type: Object,
    },
  },
  data() {
    return {
      data: {
        color_primary: '',
        favicon: '',
        logo_url: '',
        siteName: '',
        thumbnail: '',
      },
      seo: {
        seoTitle: '',
        seoDescription: '',
        seoImage: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      themeData: 'vendor/getThemedata',
    }),
  },
  methods: {
    saveColor(color) {
      this.data.color_primary = color
    },
    onUploadSuccess(res, target, isSeo = false) {
      const link = res[0].link
      const fullLink = this.$utils.combineLinkWithBucket(link)
      if (isSeo) {
        this.seo[`${target}`] = fullLink
        this.$forceUpdate()
        return
      }
      this.data[`${target}`] = fullLink
      this.$forceUpdate()
    },
    getData() {
      const data = clone(this.data, true)
      const seoData = clone(this.seo, true)
      const seo = {
        name: seoData.seoTitle,
        description: seoData.seoDescription,
        image: seoData.seoImage,
        colorPrimary: data.color_primary,
        favicon: data.favicon,
        logo: data.logo_url,
      }
      return {
        ...data,
        seo,
      }
    },
  },
  watch: {
    viewData: {
      handler(viewData) {
        if (viewData) {
          this.data = viewData
        } else {
          const currentTheme = JSON.parse(localStorage.getItem('iztech-theme'))
          this.data = currentTheme
        }
      },
      immediate: true,
    },
    seoData: {
      handler(seoData) {
        if (seoData) {
          this.seo.seoTitle = get(seoData, 'name', '')
          this.seo.seoDescription = get(seoData, 'description', '')
          this.seo.seoImage = get(seoData, 'image', '')
        }
      },
      immediate: true,
    },
  },
}
</script>
