import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Ebook = RepositoryFactory.get('ebook')
const OnlineCourseRepository = RepositoryFactory.get('onlineCourse')
const Membership = RepositoryFactory.get('membership')

const state = {
  memberships: [],
  membership: {},
  membershipPrice: '',
  coursesExcludes: [],
  coursesIncludes: [],
  booksExcludes: [],
  booksIncludes: [],
  allCourses: [],
  allBooks: [],
  countCourse: 0,
  countBook: 0,
}

const actions = {
  async fetchMemberships({commit}, params = {}) {
    const res = await Membership.fetch({
      ...params,
    })
    commit('setMemberships', res.data || [])
    return res.data || []
  },
  async fetchMembership({commit}, id) {
    const res = await Membership.fetchOne(id)
    commit('setMembership', res.data || {})
    return res.data || {}
  },
  async createMembership(_, params) {
    const res = await Membership.create(params)
    return res.data || {}
  },
  async updateTitle({commit}, {id, ...params}) {
    const res = await Membership.updateTitle(id, params)
    commit('setMembership', res.data || {})
  },
  async updateBook({commit}, {id, ...params}) {
    const res = await Membership.updateBook(id, params)
    commit('setMembership', res.data || {})
    return res.data || {}
  },
  async updateCourse({commit}, {id, ...params}) {
    const res = await Membership.updateCourse(id, params)
    commit('setMembership', res.data || {})
    return res.data || {}
  },
  async updateShortDescription(_, {id, ...params}) {
    await Membership.updateShortDescription(id, params)
  },
  async updateDescription(_, {id, ...params}) {
    await Membership.updateDescription(id, params)
  },
  async updatePrice({commit}, {id, params}) {
    const res = await Membership.updatePrice(id, params)
    commit('updatePrice', res.data || {})
    return res.data || {}
  },
  async updateExpires(_, {id, ...params}) {
    const res = await Membership.updateExpires(id, params)
    return res.data || {}
  },
  async updateStatus({commit}, {id, ...params}) {
    const res = await Membership.updateStatus(id, params)
    commit('updateStatus', res.data || {})
    return res.data || {}
  },
  async updatePriority({commit}, {id, ...params}) {
    const res = await Membership.updatePriority(id, params)
    commit('updatePriority', res.data || {})
    return res.data || {}
  },
  async updateVisibleHidden({commit}, {id, ...params}) {
    const res = await Membership.updateVisibleHidden(id, params)
    commit('updateVisibleHidden', res.data || {})
    return res.data || {}
  },
  async removeMembership({commit}, id) {
    const res = await Membership.removeMembership(id)
    commit('removeMembership', id)
    return res.data
  },
  setMembershipPrice({commit}, value) {
    commit('setMembershipPrice', value)
  },
  async fetchAllCourses({commit}, params) {
    const res = await OnlineCourseRepository.fetch(params)
    commit('setAllCourses', res.data || [])
  },
  async fetchAllBooks({commit}, params) {
    const res = await Ebook.fetch(params)
    commit('setAllBooks', res.data || [])
  },
  async fetchCourses({commit}, {params, type}) {
    const res = await OnlineCourseRepository.fetch(params)
    commit('setCourses', {data: res.data || [], type})
    return res.data || []
  },
  async fetchBooks({commit}, {params, type}) {
    const res = await Ebook.fetchForMembership(params)
    commit('setBooks', {data: res.data || [], type})
    return res.data || []
  },
  removeCourses({commit}, type) {
    commit('removeCourses', type)
  },
  removeBooks({commit}, type) {
    commit('removeBooks', type)
  },
  async countAllCourse({commit}, params = {}) {
    const res = await OnlineCourseRepository.count({...params})
    commit('setCountCourse', res.data || 0)
    return res.data
  },
  async countAllBook({commit}, params = {}) {
    const res = await Ebook.count({...params})
    commit('setCountBook', res.data || 0)
    return res.data
  },
  clearMembershipData({commit}) {
    commit('clearMembershipData')
  },
  clearCourseAndBookAccess({commit}) {
    commit('clearCourseAndBookAccess')
  },
}

const mutations = {
  setMemberships(state, data) {
    state.memberships = [...data]
  },
  setMembership(state, data) {
    state.membership = {...data}
  },
  removeMembership(state, id) {
    let list = state.memberships
    let index = list.findIndex((val) => val.id === id)
    if (index > -1) {
      list = list.splice(index, 1)
    }
  },
  updateStatus(state, data) {
    state.membership = {...state.membership, status: data.status}
    let index = state.memberships.findIndex((val) => val.id === data.id)
    if (index > -1) {
      state.memberships.splice(index, 1, data)
    }
  },
  setMembershipPrice(state, value) {
    state.membershipPrice = value
  },
  setCourses(state, data) {
    if (data.type === 'include') {
      state.coursesIncludes = [...data.data]
    } else {
      state.coursesExcludes = [...data.data]
    }
  },
  setBooks(state, data) {
    if (data.type === 'include') {
      state.booksIncludes = [...data.data]
    } else {
      state.booksExcludes = [...data.data]
    }
  },
  setAllCourses(state, data) {
    state.allCourses = [...data]
  },
  setAllBooks(state, data) {
    state.allBooks = [...data]
  },
  removeCourses(state, type) {
    if (type === 'include') {
      state.coursesExcludes = []
    } else {
      state.coursesIncludes = []
    }
  },
  removeBooks(state, type) {
    if (type === 'include') {
      state.booksExcludes = []
    } else {
      state.booksIncludes = []
    }
  },
  updateVisibleHidden(state, data) {
    let index = state.memberships.findIndex((val) => val.id === data.id)
    if (index > -1) {
      state.memberships.splice(index, 1, data)
    }
  },
  setCountCourse(state, count) {
    state.countCourse = count
  },
  setCountBook(state, count) {
    state.countBook = count
  },
  clearMembershipData(state) {
    state.membership = {}
  },
  clearCourseAndBookAccess(state) {
    state.booksExcludes = []
    state.booksIncludes = []
    state.coursesExcludes = []
    state.coursesIncludes = []
  },
}

const getters = {
  getMemberships: (state) => state.memberships,
  getMembership: (state) => state.membership,
  getMembershipPrice: (state) => state.membershipPrice,
  getBooks: (state) => state.books,
  courseExcludes: (state) => state.coursesExcludes,
  courseIncludes: (state) => state.coursesIncludes,
  booksExcludes: (state) => state.booksExcludes,
  booksIncludes: (state) => state.booksIncludes,
  allCourses: (state) => state.allCourses,
  allBooks: (state) => state.allBooks,
  getCountCourse: (state) => state.countCourse,
  getCountBook: (state) => state.countBook,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
