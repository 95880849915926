<template>
  <vue-dropzone ref="dropzone" id="dropzone" :include-styling="false" :useCustomSlot="true" :options="dropzoneOptions">
    <slot></slot>
    <v-progress-linear class="mt-4" v-if="loading" :value="percent"></v-progress-linear>
    <BaseUploadDraggerList
      ref="multi-confirm-popup"
      :type="type"
      :singleUploadFileSize="singleUploadFileSize"
      @onUploadSuccess="(val) => $emit('onUploadSuccess', val)"
    />
  </vue-dropzone>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  computed: {
    ...mapGetters({
      getEditedIndex: 'cinema/getEditedIndex',
    }),
  },
  props: {
    maxFilesize: {
      type: Number,
      default: 3750,
    },
    singleUploadFileSize: {
      type: Number,
      default: 2560,
    },
    type: String,
    maxFiles: {
      type: Number,
      default: 1,
    },
    uploadMultiple: Boolean,
    extendedParams: {
      type: Object,
      default: () => ({}),
    },
    relation: {
      type: Object,
      default: () => ({}),
    },
    isConfirmPopupUsed: {
      type: Boolean,
      default: false,
    },
    isShowAlert: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const vm = this
    return {
      loading: false,
      percent: 0,
      dropzoneOptions: {
        previewTemplate: '<div/>',
        url: this.uploadFileControl,
        maxFilesize: this.maxFilesize,
        addRemoveLinks: false,
        maxFiles: this.maxFiles,
        parallelUploads: this.maxFiles,
        acceptedFiles: this.acceptedFiles(),
        uploadMultiple: this.uploadMultiple,
        init: function () {
          this.on('maxfilesexceeded', function () {
            vm.$message.error(
              `Quá số lượng file cho phép! Số lượng file tối đa trong một lần tải là ${vm.maxFiles}.`
            )
            this.removeAllFiles(true)
          })
          this.on('error', function (_, message) {
            vm.showReadableErrorMessage(message || '')
            this.removeAllFiles(true)
          })
        },
      },
      haveVideoOversize: false,
    }
  },
  methods: {
    ...mapActions({
      createDocument: 'document/createDocument',
      createHLSVideo: 'cinema/createHLSVideo',
      uploadQuestionViaFile: 'question/uploadQuestionViaFile',
      setFileEdited: 'cinema/setFileEdited',
    }),
    acceptedFiles() {
      if (this.type === 'pdf') {
        return 'application/pdf'
      } else if (this.type === 'video' || this.type === 'vimeo') {
        return '.mp4, .mov, .m4v, .mkv'
      } else if (this.type === 'favicon') {
        return '.ico'
      } else if (this.type === 'sub') {
        return '.vtt'
      } else if (this.type === 'image') {
        return '.jpg, .jpeg, .png, .gif'
      } else if (this.type === 'audio') {
        return '.mp3'
      } else if (this.type === 'docx') {
        return '.docx'
      } else {
        return 'image/*,application/pdf,.zip, .rar, application/msword, .mp4, .mp3, .wav, .docx, .doc, .xlsx, .xls, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    },
    progressHandler(percent) {
      this.percent = Math.floor(percent)
    },
    disabled() {
      this.$refs['dropzone'].disable()
    },
    enabled() {
      this.$refs['dropzone'].enable()
    },
    async uploadFileControl(files) {
      if (files && files.length) {
        const convertFiles = this.type === 'audio' ? await this.$utils.setDurationAudioFiles(files) : files

        if (files.length > 1 && this.isConfirmPopupUsed && this.getEditedIndex === -1) {
          this.$refs['multi-confirm-popup'].openPopup(convertFiles)
        } else {
          await this.uploadFile(convertFiles)
        }
      }
    },
    async uploadFile(files) {
      try {
        if (this.getEditedIndex === -1) {
          this.loading = true
          const promises = files.map((file) => {
            if (file.size <= this.singleUploadFileSize * 1024 * 1024) {
              console.log(this.singleUploadFileSize);
              this.haveVideoOversize = false
              if (this.type === 'video' || this.type === 'vimeo') {
                //check file size <= 2,5gb
                return this.createHLSVideo({
                  file,
                  relation: this.relation,
                  onProgress: ({percent}) => {
                    this.progressHandler(percent)
                  },
                  onCancel: () => {
                    return
                  },
                })
              } else if (this.type === 'docx') {
                return this.uploadQuestionViaFile({
                  file,
                  ...this.extendedParams,
                  onProgress: ({percent}) => {
                    this.progressHandler(percent)
                  },
                })
              } else {
                return this.createDocument({
                  type: this.type,
                  file,
                  onProgress: ({percent}) => {
                    this.progressHandler(percent)
                  },
                })
              }
            } else {
              this.haveVideoOversize = true
            }
          })
          const result = await Promise.all(promises)
          this.loading = false
          if (this.haveVideoOversize === true) {
            return this.$message.error(`File không được vượt quá ${this.singleUploadFileSize} MB`)
          }
          if (result) {
            if (this.isShowAlert) {
              this.$message.success(
                this.type === 'video' || this.type === 'vimeo'
                  ? this.$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_DRAGGER.MSG_UPLOAD_LOADING')
                  : this.$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_DRAGGER.MSG_UPLOAD_SUCCESS')
              )
            }
            this.$refs['dropzone'].removeAllFiles(true)
            this.$emit('onUploadSuccess', result)
          }
        } else {
          this.setFileEdited(files)
        }
      } catch {
        this.loading = false
        // this.$message.error(e.message)
      }
    },
    showReadableErrorMessage(message = '') {
      if (typeof message === 'string' && message.length) {
        const lowercaseMessage = message.toLowerCase()
        if (lowercaseMessage.indexOf('max filesize') > -1) {
          this.$message.error(this.$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_DRAGGER.MSG_FILE_UPLOAD_OVER_THE_SIZE'))
        } else if (lowercaseMessage.indexOf('this type') > -1) {
          this.$message.error(this.$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_DRAGGER.MSG_FORMAT_FILE_UPLOAD_FALSE'))
        }
      }
    },
  },
  watch: {
    loading: {
      handler(val) {
        this.$emit('onChangeVisibilityProgress', val)
      },
      immediate: true,
    },
    percent: {
      handler(val) {
        this.$emit('onChangeProgress', val)
      },
      immediate: true,
    },
  },
}
</script>
