import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_OFFLINE_EVALUATION + '/evaluation-form'
import queryString from 'query-string'

const fetch = (params) => {
  return Repository(baseURL + '-many').fetch(params)
}

const fetchOneStudentForm = (params) => {
  return Repository(baseURL).fetch(params)
}

const create = (params) => {
  return Repository(baseURL).create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const createMany = (params) => {
  return Repository(baseURL + '-many').create(params)
}

const fetchFormByCollection = (params) => {
  const query = queryString.stringify({
    ...params,
  })
  return Repository(baseURL + `-by-ids?${query}`).fetch()
}

const fetchFormbyCustomCollection = (params) => {
  return Repository(baseURL + '-by-ids-custom').fetch(params)
}

export default {
  fetch,
  fetchOneStudentForm,
  create,
  update,
  createMany,
  fetchFormByCollection,
  fetchFormbyCustomCollection,
}
