import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Notification = RepositoryFactory.get('notification')
import _ from 'lodash-es'

const state = {
  notifications: [],
  totalItems: 0,
  pageText: '',
  itemsPerPage: 10,
  notifyAdmin: [],
  countNotifyAdmin: {},
}
const actions = {
  async sendToUser({dispatch}, params) {
    const res = await Notification.sendToUser(params)
    await dispatch('getNotificationByPagination', {
      relationId: params.relationId,
    })

    return res.data
  },
  async sendToGroup({dispatch}, params) {
    const res = await Notification.sendToGroup(params)
    await dispatch('getNotificationByPagination', {
      relationId: params.relationId,
      relationType: 'send-to-group',
    })

    return res.data
  },
  async sendToInterest(_, params) {
    const res = await Notification.sendToInterest(params)
    return res.data
  },
  async getNotificationByGroup({commit}, params) {
    let countParams = {...params, _limit: null, _skip: null}
    const [notification, totalItems] = await Promise.all([
      Notification.fetchNotificationByGroup(params),
      Notification.count(countParams),
    ])
    commit('setNotifications', notification.data)
    commit('setTotalItem', totalItems.data.count)
  },
  async getNotificationByPagination({state, dispatch}, {page, itemsPerPage, relationId, relationType, tags}) {
    if (!page) page = 1
    if (!itemsPerPage) itemsPerPage = state.itemsPerPage
    let params = {
      _skip: (page - 1) * itemsPerPage,
      _limit: itemsPerPage == -1 ? null : itemsPerPage,
      relationId,
      relationType,
      tags: tags ? _.join(_.split(tags.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), ' '), '-') : '',
    }
    console.log({params})
    await dispatch('getNotificationByGroup', params)
    await dispatch('setPageText', {page, itemsPerPage})
  },
  setPageText({state, commit}, {page, itemsPerPage}) {
    const pageStart = (page - 1) * itemsPerPage + 1
    let pageStop = page * itemsPerPage
    pageStop = pageStop > state.totalItems || pageStop === -1 ? state.totalItems : pageStop
    let pageText = `${pageStart}-${pageStop} trên ${state.totalItems}`
    commit('setPageText', pageText)
  },
  async findNotification({dispatch}, {relationId, relationType, tags}) {
    await dispatch('getNotificationByPagination', {relationId, relationType, tags})
  },
  async getNotifyByAdmin({commit}, params) {
    let countParams = {...params, _limit: null, _skip: null}
    const [notification, totalItems] = await Promise.all([
      Notification.fetchNotificationByAdmin(params),
      Notification.count(countParams),
    ])
    commit('setNotifyAdmin', notification.data)
    commit('setCountNotifyAdmin', totalItems.data)
  },
  async viewMoreNotifications({commit}, params) {
    const res = await Notification.fetchNotificationByAdmin(params)
    commit('setViewMoreNotifes', res.data)
  },
  async markRead({commit}, id) {
    const res = await Notification.markRead(id)
    const count = await Notification.count({
      role: 'admin',
    })
    if (res.data) commit('markReadNotification', id)
    commit('setCountNotifyAdmin', count.data)
  },
  async markReadAll({commit}) {
    const res = await Notification.markReadAll('admin')
    const count = await Notification.count({
      role: 'admin',
    })
    if (res.data) commit('markReadAllNotification')
    commit('setCountNotifyAdmin', count.data)
  },
}
const mutations = {
  setNotifications(state, value) {
    return (state.notifications = value)
  },
  setPageText(state, value) {
    return (state.pageText = value)
  },
  setTotalItem(state, value) {
    return (state.totalItems = value)
  },
  setNotifyAdmin(state, value) {
    return (state.notifyAdmin = value)
  },
  setCountNotifyAdmin(state, value) {
    return (state.countNotifyAdmin = value)
  },
  setViewMoreNotifes(state, value) {
    return (state.notifyAdmin = [...state.notifyAdmin, ...value])
  },
  markReadNotification(state, id) {
    return (state.notifyAdmin = state.notifyAdmin.map((notify) => {
      if (notify.id === id)
        return {
          ...notify,
          read: true,
        }
      return notify
    }))
  },
  markReadAllNotification(state) {
    return (state.notifyAdmin = state.notifyAdmin.map((notify) => {
      return {
        ...notify,
        read: true,
      }
    }))
  },
}
const getters = {
  getNotifications: (state) => {
    return state.notifications
  },
  getPageText: (state) => {
    return state.pageText
  },
  getTotalItem: (state) => {
    return state.totalItems
  },
  getNotifyAdmin: (state) => {
    return state.notifyAdmin
  },
  getCountNotifyAdmin: (state) => {
    return state.countNotifyAdmin
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
