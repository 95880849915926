const SECTION = {
  // BANNER
  BANNER_GROUP_NAME: 'バナー',

  BANNER_SLIDER_TITLE: '全画面のスライドバナー',
  BANNER_SLIDER_DESCRIPTION: '画面幅に合わせるスライドバナー',

  BANNER_TOPIC_TITLE: 'トピックバナー',
  BANNER_TOPIC_DESCRIPTION: 'トピックを表示するスライドバナー',

  BANNER_SINGLE_IMAGE_SLIDER_TITLE: 'シングル画像スライドバナー',
  BANNER_SINGLE_IMAGE_SLIDER_DESCRIPTION: '小さい写真に適したコンパクトなスライドバナー',

  BANNER_TEXT_TITLE: 'テキストバナー',
  BANNER_TEXT_DESCRIPTION: 'バナー上にテキストとナビゲーションキーを表示します。',

  BANNER_LEAD_FORM_TITLE: 'リードフォーム付きバナー',
  BANNER_LEAD_FORM_DESCRIPTION: '背景画像上に情報入力フォームを表示します。フォーム入力情報が「対応待ち顧客」リストに保存されます。',

  BANNER_POPUP_TITLE: '情報ポップアップ付き小さいなバナー',
  BANNER_POPUP_DESCRIPTION: '背景画像と短い情報を表示する。アクションの呼び出しボタンまたは情報ポップアップを追加で設定することができます。',

  // CARD
  CARD_GROUP_NAME: '学習内容カード',

  CARD_OFFLINE_COURSE_TITLE: 'オフラインコースカード',
  CARD_OFFLINE_COURSE_DESCRIPTION: 'オフラインコードのカード一覧',

  CARD_ONLINE_COURSE_TITLE: 'オンラインコースカード',
  CARD_ONLINE_COURSE_DESCRIPTION: 'オンラインコースのカード一覧',

  CARD_MY_ONLINE_COURSE_TITLE: 'マイコースカード',
  CARD_MY_ONLINE_COURSE_DESCRIPTION: '保有しているオンラインコースのカード一覧',

  CARD_EVENT_TITLE: 'イベントカード',
  CARD_EVENT_DESCRIPTION: '一覧に表示されるイベントカードのオプション',

  CARD_DEPARTMENT_TITLE: '学舎カード',
  CARD_DEPARTMENT_DESCRIPTION: '学舎・センターカードオプション。クラス・支店が複数あるセンターに適します。',

  CARD_EBOOK_TITLE: '電子書籍カード',
  CARD_EBOOK_DESCRIPTION: '一覧に表示される電子書籍カードの選択',

  CARD_LEARNING_PATH_TITLE: '学習ルートカード',
  CARD_LEARNING_PATH_DESCRIPTION: '一覧に表示される学習ルートカードの選択',

  // MEDIA
  MEDIA_GROUP_NAME: '画像と動画',

  MEDIA_IMAGE_DESCRIPTION_TITLE: '画像の説明',
  MEDIA_IMAGE_DESCRIPTION_DESCRIPTION: 'ページごとに画像を一枚表示します。サイズはバナーより小さいです',

  MEDIA_GALLERY_PICTURE_TITLE: 'アルバム',
  MEDIA_GALLERY_PICTURE_DESCRIPTION: '元サイズの画像一覧を表示します。',

  // MISC
  MISC_GROUP_NAME: '補足内容',

  MISC_TESTIMONIAL_SLIDER_TITLE: '利用者のコメント',
  MISC_TESTIMONIAL_SLIDER_DESCRIPTION: '利用者のコメントをスライド型で表示します。',

  MISC_TEXT_EDITOR_TITLE: '内容編集',
  MISC_TEXT_EDITOR_DESCRIPTION: '自分で表示内容が編集できます。',

  MISC_TEACHER_TITLE: '講師',
  MISC_TEACHER_DESCRIPTION: '講師情報を表示します。',

  MISC_BLOG_NEWS_TITLE: 'ニュース',
  MISC_BLOG_NEWS_DESCRIPTION: 'ニュース',

  MISC_CATEGORY_TITLE: 'カテゴリ',
  MISC_CATEGORY_DESCRIPTION: 'カテゴリ選択',
}

const ACTION_BAR = {
  GO_BACK_TEXT: 'ホームページ',

  CONFIRM_GO_BACK_TITLE: 'ホームページに戻ってもよろしいですか？',
  CONFIRM_GO_BACK_DESCRIPTION: '全ての変更が保存されません。',
}

const DETAIL = {
  GO_BACK_TEXT: '内容ブロック一覧',

  BTN_ADD_COMPONENT: '情報ブロックの追加',

  CONFIRM_REMOVE_COMPONENT_TITLE: 'この情報ブロックを本当に削除しますか。',
  CONFIRM_REMOVE_COMPONENT_DESCRIPTION: '変更を保存していない場合、データが回復できます。',
}
const NAVIGATION = {
  // NAVIGATION SECTION
  NS_COMMON: '共通アイデンティティ',
  NS_HOMEPAGE: 'ホームページ',
  NS_ABOUT_US: '私たちについて',
  NS_PRIVACY_POLICY: 'プライバシーポリシー',
  NS_RECRUITMENT: '講師募集',
  // CONFIRM CHANGE NAVIGATION
  CONFIRM_CHANGE_NAVIGATION_TITLE: '別のページに移動します。',
  CONFIRM_CHANGE_NAVIGATION_DESCRIPTION: '保存されていないデータは復元できません。',
}

// MAIN COMPONENT, RE-USE MORE, PUT IN INPUT STYLE
const CONFIGURATION = {
  BACKGROUND_TITLE: '背景画像',
  IMAGE_TITLE: '画像',
  RECOMMENDED_IMAGE_SIZE: '推奨サイズ：{0}以下',
  INPUT_HEADING_LABEL: 'タイトル',
  INPUT_HEADING_PLACEHOLDER: 'タイトル情報',
  INPUT_DESCRIPTION_LABEL: '説明',
  PLACEHOLDER_INPUT_DESCRIPTION: "説明を入力してください",
  INPUT_DESCRIPTION_PLACEHOLDER: '説明情報',
  INPUT_BTN_NAME_LABEL: 'ボタン名',
  INPUT_BTN_NAME_PLACEHOLDER: 'ボタンのタイトルを入力してください',
  INPUT_MAIN_HEADING_LABEL: 'メインヘッダー',
  INPUT_MAIN_HEADING_PLACEHOLDER: 'メインタイトルを入力してください',
  INPUT_SUB_HEADING_LABEL: 'サブヘッダー',
  INPUT_SUB_HEADING_PLACEHOLDER: '字幕を入力してください',

  CUSTOM_REDIRECT_ON_BTN_TITLE: 'ビューのカスタムリダイレクトリンク-詳細ボタン',
  CUSTOM_REDIRECT_ON_BTN_DESCRIPTION_ONLINE_COURSE: 'オンラインコースのトピックごとにリダイレクトリンクを構成します。カスタムリダイレクトリンクを構成しない場合、オンラインコースリストへのデフォルトのリンク。',
  CUSTOM_REDIRECT_ON_BTN_DESCRIPTION_OFFLINE_COURSE: 'オフラインコースのトピックごとにリダイレクトリンクを構成します。カスタムリダイレクトリンクを設定しない場合、オフラインコースリストへのデフォルトのリンク。',
  CUSTOM_REDIRECT_ON_BTN_DESCRIPTION_EBOOK: '電子書籍のトピックごとにリダイレクトリンクを構成します。カスタムリダイレクトリンクを構成しない場合、電子書籍リストへのデフォルトのリンク。',

  // BANNER_POPUP
  BP_MAIN_CONTENT_TITLE: '主な内容',
  BP_MAIN_CONTENT_DESCRIPTION: '左側に表示されます。',
  
  BP_OPTION_CTA_BTN_VISIBILITY: 'ダウンロードボタン表示',
  BP_INPUT_CTA_BTN_NAME_LABEL: 'ダウンロードボタン名',
  BP_INPUT_CTA_BTN_NAME_PLACEHOLDER: 'ボタン情報',
  BP_INPUT_CTA_BTN_LINK_LABEL: 'ダウンロードリンク先',
  BP_INPUT_CTA_BTN_LINK_PLACEHOLDER: 'ダウンロードするファイルのパスを入力してください。',
  BP_OPTION_POPUP_VISIBILITY: '案内ポップアップ表示',
  BP_INPUT_OPEN_POPUP_BTN_LABEL: '案内ボタン名',
  BP_INPUT_OPEN_POPUP_BTN_PLACEHOLDER: 'ボタン情報',
  BP_INPUT_POPUP_TITLE_LABEL: 'ポップアップの名称',
  BP_INPUT_POPUP_TITLE_PLACEHOLDER: 'ポップアップ名の情報',
  
  // BANNER_SLIDER
  OPTION_ADD_LINK_TO_IMAGE: '画像押下時リンク先の追加',
  INPUT_IMAGE_LINK_LABEL: '画像押下時のリンク先',
  INPUT_IMAGE_LINK_PLACEHOLDER: 'リンク先先を入力してください。',
  BTN_ADD_IMAGE: '画像追加',

  // BANNER_TEXT
  BTXT_DESCRIPTION_LABEL: '内容',
  BTXT_DESCRIPTION_PLACEHOLDER: '最大 200文字',
  BTXT_LINK_ON_BTN_LABEL: 'ボタンのリンク先',
  BTXT_LINK_ON_BTN_PLACEHOLDER: 'ボタンのリンク先を入力してください。',
  BTXT_TEXT_ALIGN_LABEL: '表示内容位置',
  BTXT_TEXT_ALIGN_PLACEHOLDER: '表示位置を選択してください。',
  BTXT_TEXT_ALIGN_OPTION_LEFT: '左揃え',
  BTXT_TEXT_ALIGN_OPTION_CENTER: '中央揃え',

  // BANNER_TOPIC
  BTPC_TOPIC_SECTION_TITLE: 'トピック選択',
  BTPC_TOPIC_SECTION_DESCRIPTION: '各トピックには、3つのコースとサブトピックを添付する必要があります。',
  BTPC_TOPIC_PICKER_LABEL: 'トピック',
  BTPC_TOPIC_PICKER_PLACEHOLDER: 'トピックを選択してください。',
  BTPC_CHILD_TOPIC_PICKER_LABEL: 'サブトピック',
  BTPC_CHILD_TOPIC_PICKER_PLACEHOLDER: 'サブトピックを選択してください。',
  COURSE_PICKER_LABEL: 'コース',
  COURSE_PICKER_PLACEHOLDER: '表示するコースを選択してください。',
  BTPC_BTN_ADD_CHILD_TOPIC: 'サブトピックの追加',
  BTPC_BTN_ADD_TOPIC: 'トピックの追加',

  // BLOG
  BLOG_DESCRIPTION: '最新投稿を３件しか表示しません。',

  CATEGORY_LIST_TITLE: 'カテゴリ一覧',

  DEPARTMENT_LIST_TITLE: '学舎一覧',
  DEPARTMENT_PICKER_LABEL: '学舎',
  DEPARTMENT_BTN_ADD: '学舎の追加',

  EBOOK_LIST_TITLE: '電子書類一覧',
  EBOOK_PICKER_LABEL: '電子書類',
  EBOOK_PICKER_PLACEHOLDER: '表示する電子書類を選択してください。',

  EVENT_LIST_TITLE: 'イベント一覧',
  EVENT_PICKER_LABEL: 'イベント',
  EVENT_PICKER_PLACEHOLDER: '表示するイベントを選択してください。',

  GALLERY_IMAGE_LIST_TITLE: '画像一覧（最大30枚）',

  IMAGE_DESCRIPTION_INFO_TITLE: '表示情報',
  IMAGE_DESCRIPTION_BUTTON_VISIBILITY_TITLE: 'ボタン表示',
  IMAGE_DESCRIPTION_BUTTON_VISIBILITY_LABEL: 'ボタン表示',

  LEAD_FORM_HEADING_LABEL: 'フォームのタイトル',
  LEAD_FORM_HEADING_PLACEHOLDER: 'フォームのタイトルを入力してください。',

  DISPLAY_STYLE_LABEL: '表示',
  DISPLAY_STYLE_PLACEHOLDER: '表示形式を選択してください。',
  DISPLAY_STYLE_OPTION_GRID: 'グリッド形式',
  DISPLAY_STYLE_OPTION_SLIDE: 'スライド形式',
  CARD_INTRO_BANNER_TITLE: '紹介バナー',
  CONFIRM_REMOVE_BANNER_TITLE: 'このバナーを削除します。よろしいでしょうか?',
  CONFIRM_REMOVE_BANNER_DESCRIPTION: 
  '削除されたデータは回復できません。画像と紐付けられたURLリンクを削除します。バナーを削除してもよろしいでしょうか',

  // LEARNING_PATH
  LP_LIST_TITLE: '学習ルート一覧',
  LP_PICKER_LABEL: '学習ルート',
  LP_PICKER_PLACEHOLDER: '表示する学習ルートを選択してください。',
  LP_CARD_INTRO_BANNER_DESCRIPTION: 'バナーを使用すると、4番目以降の学習ルートが非表示になります。',

  // ONLINE_COURSE
  OC_CARD_INTRO_BANNER_DESCRIPTION: 'バナーを使用すると、4番目以降のコースは非表示になります。',
  
  ONLINE_OFFLINE_COURSE_LIST_TITLE: 'コース一覧',

  CARD_TITLE_NTH: 'カード', // Thẻ thử bao nhiêu
  
  TESTIMONIAL_SLIDER_USER_NAME_LABEL: 'ユーザー名',
  TESTIMONIAL_SLIDER_USER_NAME_PLACEHOLDER: 'ユーザー名を入力してください。',
  TESTIMONIAL_SLIDER_USER_COMMENT_LABEL: 'コメント内容',
  TESTIMONIAL_SLIDER_USER_COMMENT_PLACEHOLDER: '最大200 文字',
  TESTIMONIAL_SLIDER_BTN_ADD_CARD: 'カード追加',
  TESTIMONIAL_SLIDER_CONFIRM_REMOVE_CARD_TITLE: 'このコメントカードを削除します。よろしいでしょうか?',
  TESTIMONIAL_SLIDER_CONFIRM_REMOVE_IMAGE_TITLE: 'この画像を削除します。よろしいでしょうか?',
}
const SITE_INFO = {
  SITE_NAME_TITLE: 'サイト名',
  SITE_NAME_LABEL: 'サイト名',
  SITE_NAME_PLACEHOLDER: 'ウェブサイト名を入力してください。',
  LOGO_TITLE: 'ロゴ',
  LOGO_DESCRIPTION: 'PNG形式・サイズ150x60px',
  MAIN_COLOR_TITLE: 'メインカラー',
  FAVICON_TITLE: 'ファビコン',
  FAVICON_DESCRIPTION: 'ファビコン形式（.png）・サイズ32x32px・解像度72pxel/inch',
  THUMBNAIL_TITLE: 'サムネイル',
  THUMBNAIL_DESCRIPTION: '画像形式',
}

export default {
  SECTION,
  ACTION_BAR,
  DETAIL,
  NAVIGATION,
  CONFIGURATION,
  SITE_INFO,
  BUTTON_SAVE: "保存",
  BUTTON_CANCEL_TEXT: "キャンセル",
  BUTTON_CONFIRM: "確認",
  BUTTON_CANCEL: "キャンセル",
  BUTTON_ADD: "追加",
  BUTTON_DELETE: "削除",
  BUTTON_BACK: "戻る",
  BUTTON_SAVE_AND_CONTINUE: "保存＆続行",
  // MESSAGE
  MSG_UPDATE_SITE_LOADING: '更新中',
  MSG_UPDATE_SUCCESS: "データが正常に更新されました。",
  // IFRAME
  IFRAME_LEARNING_PAGE_TITLE: '学習ページ', // NOT SURE IF NEED TRANSLATE OR NOT

  LABEL_COURSE_CARD_BY_TOPIC: "トピック別コースカード",
   HINT_COURSE_CARD_BY_TOPIC: "4つのオンラインコースがトピック別で表示されています。",
   LABEL_ONLINE_COURSE_CARD_BY_TOPIC: "トピック別オンラインコースのカード",
}