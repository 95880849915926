import Repository from '@/api/core'
import vendor from '@/store/modules/vendor'
import queryString from 'query-string'
const baseURL = process.env.VUE_APP_API_PERMISSION + '/user-course'

const count = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + `/count?${query}`).fetch()
}

const statistic = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + `/statistic?${query}`).fetch()
}

const fetch = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + `?${query}`).fetch()
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const interact = (id, params) => {
	return Repository(baseURL + `/interact`).update(id, params)
}

const enroll = param => {
	return Repository(baseURL + 'enroll').create(param)
}

export default {
	count,
	statistic,
	fetch,
	fetchOne,
	interact,
	enroll
}
