import axios from 'axios'
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'
import Repository from '@/api/core'
const STUDENT_BASE_URL = process.env.VUE_APP_API_STUDENT
const VENDOR_BASE_URL = process.env.VUE_APP_API_VENDOR

const APIHelper = () => ({
	updateVendorCredential: (params, option) =>
		axios.put(
			VENDOR_BASE_URL + `/vendor-credential/${auth.state.auth.vendorId}`,
			params,
			{
				headers: {
					Authorization: auth.state.auth.accessToken
				}
			},
			option
		),
	fetchVendorCredential: () => {
		return Repository(
			VENDOR_BASE_URL + `/vendor-credential/${auth.state.auth.vendorId}`
		).fetch()
	},
	getAdmins: (params) => {
		return Repository(VENDOR_BASE_URL + `/user/clients`).fetch(params)
	},
	createAdmin: (params) => {
		return Repository(VENDOR_BASE_URL + `/user`).create(params)
	},
	deleteAdmin: (id) => {
		return Repository(VENDOR_BASE_URL + `/user`).delete(id)
	},
	getVendorIdFromOrigin: async () => {
		let cache = _loadVendorIdCache()
		if (cache) {
			return cache
		}
		const hostname = location.origin
		const payload = await axios.get(
			VENDOR_BASE_URL + `/vendor/get-vendor-from-origin?hostname=${hostname}`
		)
		if (!payload.data.vendorId) {
			throw new Error(
				`[GetVendorIdFromOrigin] Invalid payload '${JSON.stringify(payload)}'!`
			)
		}
		_saveVendorIdCache(payload.data)
		return payload.data
	},
	updateSettings: (params, option) => {
		let payload = params
		delete payload['vendorId']
		delete payload['id']
		return axios.put(
			VENDOR_BASE_URL + `/vendor-setting/${vendor.state.vendor.vendorId}`,
			{ ...payload },
			{
				headers: {
					Authorization: auth.state.auth.accessToken,
					'ol-client-id': vendor.state.vendor.clientId,
					'ol-vendor-id': vendor.state.vendor.vendorId
				}
			},
			option
		)
	},
	getSettingsVendor: async vendor => {
		let vendorInfo = await axios.get(
			`${VENDOR_BASE_URL}/vendor/${vendor.vendorId}`,
		)
		let vendorSettings = await axios.get(
			`${VENDOR_BASE_URL}/vendor-setting/${vendor.vendorId}`,
		)
		return {
			...vendorInfo.data,
			settings: vendorSettings.data
		}
	},
	statistic: params =>
		Repository(STUDENT_BASE_URL + '/student/statistical').fetch(params)
})

function _loadVendorIdCache() {
	let data = _jsonParse(localStorage.getItem('ol-amplify-vendorId-from-origin'))
	if (
		data &&
		data.origin == window.location.origin &&
		data.payload &&
		typeof data.payload.vendorId === 'string' &&
		Date.now() < data.expiredAt
	) {
		return data.payload
	}
	localStorage.removeItem('ol-amplify-vendorId-from-origin')
	return null
}

function _saveVendorIdCache(payload) {
	let data = {
		origin: window.location.origin,
		payload,
		expiredAt: Date.now() + 24 * 60 * 60 * 1000
	}
	localStorage.setItem('ol-amplify-vendorId-from-origin', JSON.stringify(data))
}

function _jsonParse(str) {
	try {
		return JSON.parse(str)
	} catch (err) {
		return null
	}
}

export const Vendor = APIHelper()
export default {
	Vendor
}
