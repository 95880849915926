import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_MEMBERSHIP + '/user-membership'

const fetchMembershipByStudentId = (id) => {
  return Repository(baseURL).fetchOne(id)
}

export default {
  fetchMembershipByStudentId,
}
