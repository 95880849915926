const SIGN_IN={
  TITLE: "Login",
  LABEL_EMAIL: "Email",
  PLACEHOLDER_INPUT_EMAIL:"Enter email",
  LABEL_PASSWORD:"Password",
  PLACEHOLDER_INPUT_PASSWORD:"Enter password",
  HINT_PASSWORD:"The password contains at least 6 characters",
  CHECKBOX_SAVE_ACCOUNT:"Save Account",
  LINK_GO_TO_FORM_FIND_PASSWORD:"Forgot Password",
  BUTTON_SIGN_IN:"Login",
  MSG_LOADING:"Please wait a moment...",
  MSG_ACCOUNT_IS_NOT_ALLOWED_TO_ACCESS:'Invalid account or password!',
  MSG_ACCOUNT_OR_PASSWORD_FALSE:'Invalid account or password!',
  MSG_CHECK_INFO_AGAIN:"Check information again!",
}
const SIGN_OUT={
  POPUP_CONFIRM_SIGN_OUT_TITLE:"Are you sure you want to sign out",
  POPUP_CONFIRM_SIGN_OUT_MESSAGE: "Save your customizations if you don't want to lose them!",
  BUTTON_CONFIRM:"Confirm",
  BUTTON_CANCEL:"Cancel",
}
export default{
  SIGN_IN,
  SIGN_OUT,
  TITLE_HELP:"Need help?",
  LABEL_HOLINE: "For any questions, please contact",
  LABEL_ADDRESS_EMAIL:"or email to",
  TITLE_NEW_FEATURE:"New feature!",
  WELCOME: 'Welcome',
}