import onlineCourseRepository from '@/api/repository/onlineCourseRepository'
import offlineCourseRepository from '@/api/repository/offlineCourseRepository'
import offlineClassRepository from '@/api/repository/offlineClassRepository'
import utilityRepository from '@/api/repository/utilityRepository'
import resourceRepository from '@/api/repository/resourceRepository'
import departmentRepository from '@/api/repository/departmentRepository'
import sectionRepository from '@/api/repository/sectionRepository'
import unitRepository from '@/api/repository/unitRepository'
import ratingRepository from '@/api/repository/ratingRepository'
import userCourseRepository from '@/api/repository/userCourseRepository'
import userPermissionRepository from '@/api/repository/userPermissionRepository'
import commentRepository from '@/api/repository/commentRepository'
import homeworkRepository from '@/api/repository/homeworkRepository'
import examRepository from '@/api/repository/examRepository'
import questionRepository from '@/api/repository/questionRepository'
import submissionRepository from '@/api/repository/submissionRepository'
import couponCodeRepository from '@/api/repository/couponCodeRepository'
import teacherRepository from '@/api/repository/teacherRepository'
import topicRepository from '@/api/repository/topicRepository'
import eventRepository from '@/api/repository/eventRepository'
import cartRepository from '@/api/repository/cartRepository'
import authorRepository from '@/api/repository/authorRepository'
import ebookRepository from '@/api/repository/ebookRepository'
import blogRepositoty from '@/api/repository/blogRepository'
import mailRepository from '@/api/repository/mailRepository'
import zoomRepository from '@/api/repository/zoomRepository'
import groupRepository from '@/api/repository/groupRepository'
import kolRepository from '@/api/repository/kolRepository'
import activeCodeRepository from '@/api/repository/activeCodeRepository'
import billRepository from '@/api/repository/billRepository'
import comboRepository from '@/api/repository/comboRepository'
import userTempRepository from '@/api/repository/userTempRepository'
import affiliateRepository from '@/api/repository/affiliateRepository'
import studentRepository from '@/api/repository/studentRepository'
import classOfflineRepository from '@/api/repository/classOfflineRepository'
import interestRepository from '@/api/repository/interestRepository'
import commissionRepository from '@/api/repository/commissionRepository'
import certificateRepository from '@/api/repository/certificateRepository'
import userCertificateRepository from '@/api/repository/userCertificateRepository'
import notificationRepository from '@/api/repository/notificationRepository'
import knowledgeRepository from '@/api/repository/knowledgeRepository'
import learningPathRepository from '@/api/repository/learningPathRepository'
import userBookRepository from '@/api/repository/userBookRepository'
import leaderboardRepository from '@/api/repository/leaderboardRepository'
import questionGenerateRepository from '@/api/repository/questionGenerateRepository'
import billStatisticRepository from '@/api/repository/billStatisticRepository'
import productRepository from '@/api/repository/productRepository'
import productCategoryRepository from '@/api/repository/productCategoryRepository'
import productVariantRepository from '@/api/repository/productVariantRepository'
import productCouponRepository from '@/api/repository/productCouponRepository'
import membershipRepository from '@/api/repository/membershipRepository'
import userGroup from '@/api/repository/userGroup'
import classStudentRepository from '@/api/repository/classStudentRepository'
import attendanceRepository from '@/api/repository/attendanceRepository'
import sessionRepository from '@/api/repository/sessionRepository'
import offlineClassMarkRepository from '@/api/repository/offlineClassMarkRepository'
import offlineClassMarkStudentRepository from '@/api/repository/offlineClassMarkStudentRepository'
import systemPrivateRepository from '@/api/repository/systemPrivateRepository'
import userMembershipRepository from '@/api/repository/userMembershipRepository'
import userPathRepository from '@/api/repository/userPathRepository'
import offlineClassEvaluationCollectionRepository from '@/api/repository/offlineClassEvaluationCollectionRepository'
import offlineClassEvaluationFormRepository from '@/api/repository/offlineClassEvaluationFormRepository'
import offlineClassEvaluationTemplateRepository from '@/api/repository/offlineClassEvaluationTemplateRepository'
import logRepository from '@/api/repository/logRepository'
import flashcardRepository from '@/api/repository/flashcardRepository'

const repositories = {
  onlineCourse: onlineCourseRepository,
  offlineCourse: offlineCourseRepository,
  utility: utilityRepository,
  resource: resourceRepository,
  department: departmentRepository,
  offlineClass: offlineClassRepository,
  section: sectionRepository,
  unit: unitRepository,
  rating: ratingRepository,
  userCourse: userCourseRepository,
  userPermission: userPermissionRepository,
  comment: commentRepository,
  homework: homeworkRepository,
  exam: examRepository,
  question: questionRepository,
  submission: submissionRepository,
  couponCode: couponCodeRepository,
  teacher: teacherRepository,
  topic: topicRepository,
  event: eventRepository,
  cart: cartRepository,
  author: authorRepository,
  ebook: ebookRepository,
  blog: blogRepositoty,
  mail: mailRepository,
  zoom: zoomRepository,
  group: groupRepository,
  kol: kolRepository,
  activeCode: activeCodeRepository,
  bill: billRepository,
  combo: comboRepository,
  userTemp: userTempRepository,
  affiliate: affiliateRepository,
  student: studentRepository,
  classOffline: classOfflineRepository,
  interest: interestRepository,
  commission: commissionRepository,
  certificate: certificateRepository,
  userCertificate: userCertificateRepository,
  notification: notificationRepository,
  knowledge: knowledgeRepository,
  learningPath: learningPathRepository,
  userBook: userBookRepository,
  leaderboard: leaderboardRepository,
  QuestionGenerate: questionGenerateRepository,
  BillStatistic: billStatisticRepository,
  ProductRepository: productRepository,
  ProductCategoryRepository: productCategoryRepository,
  ProductVariantRepository: productVariantRepository,
  productCoupon: productCouponRepository,
  membership: membershipRepository,
  userGroup: userGroup,
  classStudent: classStudentRepository,
  attendance: attendanceRepository,
  session: sessionRepository,
  offlineClassMark: offlineClassMarkRepository,
  offlineClassMarkStudent: offlineClassMarkStudentRepository,
  systemPrivate: systemPrivateRepository,
  userMembership: userMembershipRepository,
  userPath: userPathRepository,
  evaluationCollection: offlineClassEvaluationCollectionRepository,
  evaluationTemplate: offlineClassEvaluationTemplateRepository,
  evaluationForm: offlineClassEvaluationFormRepository,
  log: logRepository,
  flashcard: flashcardRepository
}

export const RepositoryFactory = {
  get: (name) => repositories[name],
}
