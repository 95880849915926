import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_MEMBERSHIP + '/membership'

const fetch = (params) => {
  const query = queryString.stringify({
    ...params,
    vendorId: vendor.state.vendor.vendorId,
  })
  return Repository(baseURL + `?${query}`).fetch()
}

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const create = (params) => {
  return Repository(baseURL).create(params)
}

const updateTitle = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const updateBook = (id, params) => {
  return Repository(baseURL + '/book').update(id, params)
}

const updateCourse = (id, params) => {
  return Repository(baseURL + '/online-course').update(id, params)
}

const updateShortDescription = (id, params) => {
  return Repository(baseURL + '/short-description').update(id, params)
}

const updateDescription = (id, params) => {
  return Repository(baseURL + '/description').update(id, params)
}

const updatePrice = (id, params) => {
  return Repository(baseURL + '/price').update(id, params)
}

const updateExpires = (id, params) => {
  return Repository(baseURL + '/expires').update(id, params)
}

const updateStatus = (id, params) => {
  return Repository(baseURL + '/status').update(id, params)
}

const updatePriority = (id, params) => {
  return Repository(baseURL + '/priority').update(id, params)
}

const updateVisibleHidden = (id, params) => {
  return Repository(baseURL + '/visible-hidden').update(id, params)
}

const removeMembership = (id) => {
  return Repository(baseURL).delete(id)
}

export default {
  fetch,
  fetchOne,
  create,
  updateBook,
  updateCourse,
  updateShortDescription,
  updateDescription,
  updatePrice,
  updateExpires,
  updateStatus,
  updatePriority,
  updateVisibleHidden,
  removeMembership,
  updateTitle,
}
