import Repository from '@/api/core'
import queryString from 'query-string'
const baseURL = process.env.VUE_APP_API_EXAM + '/question-generate'

const fetch = (params) => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const remove = (id) => {
  return Repository(baseURL).delete(id)
}
const removeMany = (params) => {
  const query = queryString.stringify(params)
  return Repository(baseURL + '/many' + `?${query}`).deleteMany()
}

const count = (params) => {
  return Repository(baseURL + '/count').fetch(params)
}

export default {
  fetch,
  fetchOne,
  remove,
  count,
  removeMany,
}
