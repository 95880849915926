import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const ClassMark = RepositoryFactory.get('offlineClassMark')
const namespaced = true
import get from 'lodash-es/get'

const state = {
  marks: [],
}

const actions = {
  async fetchMarks({commit}, params) {
    const res = await ClassMark.fetch(params)
    commit('setMarks', get(res, 'data', []))
  },
  async createMark({commit}, params) {
    const res = await ClassMark.create(params)
    const data = get(res, 'data', {})
    commit('addToMarks', data)
    return data
  },
  async updateMark({commit}, {id, ...params}) {
    const res = await ClassMark.update(id, params)
    commit('updateToMarks', get(res, 'data', {}))
  },
  async removeMarks({commit}, id) {
    await ClassMark.remove(id)
    commit('removeFromMarks', id)
  },
  async updateManyMarks({commit}, {children, ...params}) {
    await ClassMark.updateMany(params)
    commit('updateToMarks', children)
  },
  async removeMarksIndependent(_, id) {
    await ClassMark.remove(id)
  },
  async updateMarkIndependent(_, {id,...params}){
    const res = await ClassMark.update(id, params)
    return get(res, 'data', {})
  }
}

const mutations = {
  setMarks(state, marks) {
    state.marks = marks
  },
  addToMarks(state, mark) {
    state.marks.push(mark)
  },
  updateToMarks(state, data) {
    if (Array.isArray(data) && data.length) {
      data.forEach((d) => {
        const found = state.marks.findIndex((x) => x.id === d.id)
        if (found > -1) state.marks.splice(found, 1, d)
      })
    } else {
      const found = state.marks.findIndex((x) => x.id === data.id)
      if (found > -1) state.marks.splice(found, 1, data)
    }
  },
  removeFromMarks(state, id) {
    const found = state.marks.findIndex((x) => x.id === id)
    if (found > -1) state.marks.splice(found, 1)
  },
}

const getters = {
  getMarks: (state) => state.marks,
  getDisplayedMarks: (state) => {
    let groups = state.marks.filter((x) => x.isGroup)
    const nonGroups = state.marks.filter((x) => !x.isGroup && !x.parent)
    if (Array.isArray(groups) && groups.length) {
      groups = groups.map((g) => ({...g, children: state.marks.filter((m) => m.parent === g.id)}))
    }
    return [...groups, ...nonGroups].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  },
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
