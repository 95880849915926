<template>
	<BaseAutocomplete
		:label="label"
		:placeholder="placeholder"
		:items="listTeacher"
		v-model="teacher"
		:item-text="itemText"
		:item-value="itemValue"
		hide-details
		:clearable="isClearable"
		:returnObject="returnObject"
    :multiple="multiple"
	/>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
	props: {
		label: String,
		placeholder: String,
    itemText: String,
    itemValue: String,
    returnObject: Boolean,
    multiple: Boolean,
    value: [Array, Object, String],
    isClearable: Boolean,
	},
	data() {
		return {
			teacher: null
		}
	},
	async created() {
		await this.fetchAuthors()
	},
	computed: {
		...mapGetters({
			listTeacher: 'teacher/getTeachers'
		})
	},
	methods: {
		...mapActions({
			fetchAuthors: 'teacher/fetchTeachers'
		})
	},
	watch: {
		teacher: {
			handler(val) {
        if (this.multiple && val && val.length) {
          this.$emit('input', val)
        } else {
          this.$emit('input', this.returnObject ? val || {} : val || '')
        }
			},
			immediate: true
		},
    value: {
      handler(val) {
        this.teacher = val
      },
      immediate: true
    }
	}
}
</script>
