<template>
  <div class="d-flex flex-column justify-space-between">
    <div v-if="listImagesWithIcons.length" style="min-height: 300px">
      <div class="d-flex flex-wrap" style="min-height: 300px">
        <BaseImageThumbnail
          class="mr-3 mb-3"
          v-for="image in listImagesWithIcons"
          :key="image.id"
          :link="$utils.convertURL($utils.combineLinkWithBucket(image.link))"
          :isShowIcon="image.isShowIcon"
          @onSelect="onSelect(image)"
        />
      </div>
    </div>

    <div v-else class="d-flex" style="min-height: 300px">
      {{
        $vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.LIST_IMAGE_THUMBNAIL.TITLE_NOT_FOUND_IMAGE')
      }}
    </div>
    <div v-if="listImagesWithIcons.length" class="d-flex justify-space-between full-width">
      <div class="mt-3">
        {{ $vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.LIST_IMAGE_THUMBNAIL.LABEL_PAGE') }}
        {{ pageNumber }} - {{ total }}
      </div>
      <div>
        <v-btn :disabled="pageNumber === 1" @click="$emit('previousPage')" icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn :disabled="pageNumber === total" @click="$emit('nextPage')" icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    listImages: Array,
    total: Number,
    pageNumber: Number,
  },
  data() {
    return {
      listImagesWithIcons: [],
      currentSelected: null,
    }
  },
  methods: {
    onSelect(selectedItem) {
      this.listImagesWithIcons = this.listImages.map((image) => {
        return {
          ...image,
          isShowIcon: this.checkStatusShowIcon(selectedItem, image),
        }
      })
      this.currentSelected = selectedItem
      this.$emit('onSelect', selectedItem)
    },
    checkStatusShowIcon(selectedItem, image) {
      if (selectedItem.id === image.id) {
        if (this.currentSelected) {
          if (this.currentSelected.id !== selectedItem.id) {
            return true
          } else return !image.isShowIcon
        } else return true
      } else return false
    },
  },
  watch: {
    listImages: {
      handler(newValue) {
        this.listImagesWithIcons = newValue
      },
      immediate: true,
    },
  },
}
</script>
