import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Affiliate = RepositoryFactory.get('affiliate')

const state = {
  affiliates: {},
  affiliate: {},
  count: 0
}

const actions = {
  async fetchAffiliates({ commit }, params = {}) {
    const res = await Affiliate.fetch({
      ...params,
    })
    commit("setAffiliates", res.data || [])
    return res.data
  },
  async createAffiliate({ commit }, data) {
    const res = await Affiliate.create(data)
    commit("appendAffiliate", res.data)
    return res.data
  },
  async removeAffiliate({ commit }, item) {
    await Affiliate.remove(item.id);
    return commit("removeAffiliate", item.id);
  },
}

const mutations = {
  setAffiliates(state, affiliates) {
    return (state.affiliates = affiliates.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    ));
  },
  appendAffiliate(state, affiliate) {
    state.affiliates[affiliate.id] = affiliate
    state.affiliates = { ...state.affiliates };
  },
  setAffiliate(state, affiliate) {
    return (state.affiliate = affiliate);
  },
  removeAffiliate(state, id) {
    delete state.affiliates[id];
    state.affiliates = { ...state.affiliates };
  }
}

const getters = {
  getAffiliates: (state) => {
    return Object.values(state.affiliates);
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
