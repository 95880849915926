//STUDENT
const STUDENT_LIST = {
  TITLE: 'Học viên',
  BUTTON_IMPORT_EXPORT_EXCEL: 'Nhập/xuất Excel',
  BUTTON_ADD: 'Thêm học viên',
  BUTTON_IMPORT_EXCEL: 'Nhập file Excel',
  BUTTON_DOWNLOAD_EXCEL_TEMPLATE: 'Download file mẫu',
  BUTTON_EXPORT_FILE_EXCEL: 'Xuất file Excel',

  TOOLTIP_EXCEL: 'Lưu ý: Hệ thống chỉ cho phép thêm tối đa 30 học sinh mỗi lần!', ///
  LABEL_FILTER_BY_NAME_AND_EMAIL: 'Bộ lọc',
  PLACEHOLDER_FILTER_BY_NAME_AND_EMAIL: 'Tìm kiếm theo tên và email của học viên',
  // FILTER_DETAIL
  TITLE_DETAILED_FILTER: 'Bộ lọc chi tiết',
  LABEL_VIEW_ALL_STUDENTS_THAT: 'Xem tất cả học sinh có',
  PLACEHOLDER_SELECT_FILTER: 'Chọn bộ lọc',
  OPTION_FILTER_GROUP: 'Nhóm',
  OPTION_FILTER_INTEREST: 'Quan tâm',
  LABEL_FILTER_VALUE_IS: 'là',
  LABEL_SELECT_STUDENT_GROUP: 'Chọn nhóm học viên',
  PLACEHOLDER_SELECT_STUDENT_GROUP: 'Chọn tên nhóm cần thêm',
  LABEL_SELECT_INTEREST: 'Chọn sự quan tâm',
  MSG_CHECK_INFO_AGAIN: 'Vui lòng chọn đủ thông tin',
  // ACTIONS
  BUTTON_EDIT: 'Chỉnh sửa thông tin',
  BUTTON_DELETE: 'Xoá học viên',
  POPUP_CONFIRM_DELETE_TITLE: 'Bạn có chắc chắn xoá học viên này?',
  POPUP_CONFIRM_DELETE_MESSAGE:
    'Tất cả những dữ liệu liên quan đến học sinh sẽ bị xóa hoàn toàn khỏi hệ thống. Bạn vẫn muốn tiếp tục?',
  MSG_DELETE_STUDENT_SUCCESS: 'Xoá học viên thành công!',
  // EMPTY DATA
  TITLE_EMPTY_DATA: 'Chưa tồn tại nhóm học viên nào',
  SUBTITLE_EMPTY_DATA: 'Vui lòng tạo mới nhóm học viên để hiển thị trên danh sách này',
  // TABLE
  TH_FULL_NAME: 'Họ và tên học viên',
  TH_EMAIL: 'Email',
  TH_PHONE: 'Số điện thoại',
  TH_CREATE_DATE: 'Ngày tạo',
  TH_CREATE_FROM: 'Tự đăng ký',
  TD_CREATE_FROM_VERIFIED: 'Đã kích hoạt',
  TD_CREATE_FROM_UNVERIFIED: 'Chưa kích hoạt',
  TH_ACTION: 'Thao tác',
  // BUTTON_DELETE: 'Xoá học viên',
  BUTTON_SEND_NOTIFICATION: 'Soạn thông báo',
  BUTTON_ADD_TO_GROUP: 'Thêm vào nhóm',
  BUTTON_DELETE_ALL_STUDENT: 'Xoá nhiều học viên',

  // ADD_MANY_STUDENT_TO_GROUP
  TITLE_ADD_STUDENT_TO_GROUP: 'Thêm học sinh vào nhóm',
  BUTTON_ADD_TO_EXISTED_GROUP: 'Thêm vào nhóm có sẵn',
  BUTTON_CREATE_NEW_GROUP: 'Tạo nhóm mới',
  LABEL_FILTER_NEW_GROUP: 'Nhóm mới',
  PLACEHOLDER_NEW_GROUP: 'Nhập tên nhóm mới',
  PLACEHOLDER_SELECT_GROUP: 'Chọn nhóm để thêm',

  //BASE_SEND_NOTIFICATION_POPUP
  POPUP_SEND_NOTIFICATION_TITLE: 'Soạn thông báo',
  POPUP_NOTIFICATION_HEADER_LABEL: 'Tiêu đề thông báo',
  POPUP_INPUT_NOTIFICATION_HEADER_PLACEHOLDER: 'Nhập tiêu đề thông báo',
  POPUP_NOTIFICATION_CONTENT_LABEL: 'Nội dung thông báo',
  POPUP_INPUT_NOTIFICATION_CONTENT_PLACEHOLDER: 'Nhập nội dung thông báo',
  POPUP_SEND_NOTIFICATION_BUTTON: 'Gửi',
  POPUP_SEND_NOTIFICATION_SUCCESS_MESSAGE: 'Gửi thông báo cho học viên thành công',
  POPUP_CHECK_STUDENT_QUANTITY_ADD_GROUP_MESSAGE: 'Số lượng học viên mỗi lần thêm vào nhóm đã quá giới hạn',

  //EXPPORT_TABLE_EXCEL
  TH_PASSWORD: 'Mật khẩu',
  TH_BIRTHDAY: 'Ngày sinh (không bắt buộc)',
  TH_PHONE_OPTIONAL: 'Số điện thoại (không bắt buộc)',

  TEXT_LABEL_KEYWORD: 'Từ khoá',
}
const FORM_CREATE_NEW_STUDENT = {
  LINK_RETURN_TO_STUDENT_LIST: 'Danh sách học sinh',
  TITLE: 'Thêm học sinh mới',

  //BASIC_INFO
  TITLE_INFO: 'Thông tin cơ bản',
  LABEL_NAME: 'Tên học viên',
  PLACEHOLDER_INPUT_NAME: 'Nhập tên học viên',
  LABEL_EMAIL: 'Email',
  PLACEHOLDER_INPUT_EMAIL: 'Nhập email',
  LABEL_PHONE_NUMBER: 'Số điện thoại',
  PLACEHOLDER_INPUT_PHONE_NUMBER: 'Nhập số điện thoại',
  LABEL_BIRTH_DATE: 'Ngày sinh',
  PLACEHOLDER_INPUT_BIRTH_DATE: 'Nhập ngày sinh',

  //NOTIFICATION_EMAIL
  TITLE_EMAIL_NOTIFICATION: 'Gửi email thông báo',
  TOGGLE_SEND_NOTIFICATION: 'Gửi thông báo tài khoản mới tới email người dùng',
  //EDIT_TEMPLATE_EMAIL_REGISTER

  // TITLE_EMAIL_RESGISTER: 'Mẫu gửi mail đăng kí',
  // TITLE_EDIT_EMAIL_REGISTER_TEMPLATE: 'Mail đăng kí',
  TITLE_SAMPLE_SEND_EMAIL_TEMPLATE: 'Mẫu gửi mail đăng kí',
  SUBTITLE_SAMPLE_SEND_EMAIL_TEMPLATE: 'Mail đăng kí',

  MSG_CHECK_EMAIL_REGISTER_AGAIN: 'Email đã được đăng ký, vui lòng sử dụng email khác!',
  MSG_CREATE_SUCCESS: 'Tạo học sinh thành công!',
  MSG_CREATING: 'Đang tạo học sinh...',
  MSG_CHECK_VALID_FIELD: 'Vui lòng kiểm tra lại trường thông tin!',
}
const STUDENT_DETAIL = {
  LINK_RETURN_TO_STUDENT_LIST: 'Danh sách học viên',
  //TAB
  TAB_INFOS: 'Thông tin chung',
  TAB_GROUP: 'Nhóm học viên',
  TAB_COURSES: 'Khoá học sở hữu',
  TAB_PROPERTY_EBOOK: 'Tài liệu sở hữu',
  TAB_EDUCATION_REPORT: 'Báo cáo học tập',
  TAB_INTEREST: 'Sự quan tâm',
  TAB_DELETE: 'Xoá học viên',

  //BASIC_INFO
  TITLE_INFO: 'Thông tin chung',
  LABEL_TOTAL_STUDENT: 'Tổng học viên',
  LABEL_FOUNDING: 'Ngày thành lập',
  MSG_UPDATING__INFO: 'Đang cập nhật...',
  MSG_UPDATE_INFO_SUCCESS: 'Cập nhật thành công',
  MSG_RECHECK_INVALID_FIELD: 'Vui lòng kiểm tra lại trường thông tin!',

  //GROUP OF STUDENT
  TITLE_GROUP: 'Nhóm học viên',
  LABEL_SELECT_GROUPT: 'Chọn nhóm học viên',
  PLACEHOLDER_SELECT_GROUP: 'Chọn tên nhóm học viên',
  //TABLE GROUP OF STUDENT
  TH_GROUP: 'Nhóm',
  TH_UPDATED_AT: 'Ngày cập nhật',
  TH_ACTION: 'Thao tác',

  MSG_ADDING_TO_GROUP: 'Đang thêm học sinh vào nhóm...',
  MSG_ADD_STUDENT_TO_GROUP_SUCCESS: 'Thêm học viên vào nhóm thành công!',
  MSG_CHECK_LIMITED_NUMBER_OF_STUDENTS: 'Số lượng học viên mỗi lần thêm vào nhóm đã quá giới hạn',

  POPUP_CONFIRM_DELETE_OUT_GROUP_TITLE: 'Xác nhận xoá học sinh khỏi nhóm học này?',
  POPUP_CONFIRM_DELETE_OUT_GROUP_MESSAGE: 'Dữ liệu sau khi xóa không thể khôi phục',
  MSG_DELETING: 'Đang xoá học sinh khỏi nhóm...',
  MSG_DELETE_STUDENT_OUT_GROUP_SUCCESS: 'Xoá học sinh khỏi nhóm thành công!',

  //COURSE OF STUDENT
  TITLE_PROPERTY_COURSE: 'Khoá học sở hữu',
  LABEL_SELECT_COURSE: 'Chọn khoá học',
  PLACEHOLDER_FILTER_COURSE: 'Tìm kiếm khoá học',
  TH_COURSE_NAME: 'Tên khoá học',
  POPUP_DELETE_ROUTE_STUDY_OUT_SYSTEM_TITLE: 'Xác nhận xoá sở hữu khoá học',
  MSG_DELETING_COURSE: 'Đang xoá sở hữu khoá học...',
  MSG_DELETE_COURSE_SUCCESS: 'Xoá sở hữu khoá học thành công',
  MSG_ADDING_COURSE: 'Đang thêm khoá học cho học viên...',
  MSG_ADD_COURSE_SUCCESS: 'Thêm khoá học cho học viên thành công !',

  //EBOOK
  TITLE_PROPERTY_EBOOK: 'Tài liệu sở hữu',
  LABEL_SELECT_EBOOK: 'Chọn sách',
  PLACEHOLDER_FILTER_EBOOK: 'Tìm kiếm sách',
  TH_EBOOK_NAME: 'Tên sách',
  TH_LAST_VIEW: 'Xem gần nhất lúc',
  POPUP_BUTTON_DELETE_EBOOK_TITLE: 'Xoá tài liệu khỏi nhóm',

  MSG_ADDING_EBOOK: 'Đang thêm tài liệu cho học viên...',
  MSG_ADD_EBOOK_SUCCESS: 'Thêm tài liệu cho học sinh thành công !',
  MSG_DELETING_EBOOK: 'Đang xoá tài liệu khỏi nhóm...',
  MSG_DELETE_EBOOK_SUCCESS: 'Xoá tài liệu khỏi nhóm thành công',

  //REPORT
  TITLE_COURSE_DONE_QUANTITY: 'Đã hoàn thành',
  TITLE_COURSES_DONE_QUANTITY_UNIT: 'khoá học',
  TITLE_OVERVIEW_LAST: 'Truy cập gần nhất',
  TITLE_AVAILABLE_STUDY: 'Bắt đầu học',

  LABEL_TIME_UNDEFINED: 'Chưa xác định',
  BUTTON_VIEW: 'Xem thêm khoá học',
  TITLE_PROGRESS_LEARNING: 'Tiến độ học',
  TITLE_REPORT_DETAIL: 'Báo cáo chi tiết',
  //FILTER STUDY REPORT DETAIL
  LABEL_FILTER_COURSE: 'Tìm kiếm khoá học',
  LABEL_SELECT_LESSON_TYPE: 'Chọn loại bài',
  OPTION_PDF_SLIDE: 'PDF Slide',
  OPTION_EXAM: 'Bài kiểm tra',
  OPTION_HOMEWORK: 'Bài tập về nhà',
  OPTION_VIDEO: 'Video',
  TEXT_EXAM_TYPE_MATRIX: 'Bài kiểm tra dạng ma trận',
  TEXT_EXAM_TYPE_TEST: 'Bài kiểm tra tự tạo',
  TEXT_EXAM_TYPE_EXERCISE: 'Bài kiểm tra có sẵn',
  TEXT_DETAIL_UNIT_STATE_DONE: 'Hoàn thành',
  TEXT_DETAIL_UNIT_STATE_NOT_DONE: 'Chưa xong',
  HINT_SELECT_COURSE_EXERCISE_COURSE: 'Vui lòng chọn khóa học và loại bài tập cần xem kết quả',
  BUTTON_FILTER: 'Lọc',

  //TABLE STUDY REPORT EXAM
  TH_EXERCISE: 'Bài tập',
  TH_EXERCISE_TYPE: 'Loại bài',
  TH_MARK: 'Điểm',
  //TABLE STUDY REPORT HOMEWORK
  TH_HOMEWORK_NAME: 'Tên bài tập',
  TH_TIME: 'Thời gian',
  TH_STATE: 'Trạng thái',
  //TABLE STUDY REPORT VIDEO OR PDF
  TH_LESSON_NAME: 'Tên bài giảng',

  //INTEREST
  TITLE_INTEREST: 'Quan tâm của học sinh',
  TAB_TOPIC: 'Chủ đề',
  TAB_ONLINE_COURSE: 'Khoá học Online',
  TAB_OFFLINE_COURSE: 'Khoá học Offline',
  TAB_EBOOK: 'Sách điện tử',
  TAB_EVENT: 'Sự kiện',
  TITLE_LIST: 'Danh sách sự quan tâm',
  TH_INTERES_NAME: 'Tên sự quan tâm',
  TH_UPDATE: 'Cập nhật',

  BUTTON_UPDATE: 'Cập nhật',
  POPUP_TITLE_DELETE_INTEREST: 'Bạn có chắc muốn xóa sự quan tâm này?',
  POPUP_CREATE_NEW_INTEREST_TITLE: 'Tạo sự quan tâm {0} mới',
  MSG_CREATING_INTEREST: 'Đang tạo sự quan tâm !',
  MSG_CREATE_SUCCESS: 'Tạo sự quan tâm thành công!',

  //DELETION
  TITLE_DETELE: 'Xoá học viên',
  SUBTITLE_DELETE: 'Học viên bị xóa sẽ không thể khôi phục',
  BUTTON_DELETE: 'Xoá học viên',
  POPUP_CONFIRM_DELETE_TITLE: 'Bạn có chắc chắn xoá học viên này?',
  MSG_DELETE_SUCCESS: 'Xoá học viên thành công!',
}
//GROUP OF STUDENT
const GROUP_STUDENT_LIST = {
  TITLE: 'Nhóm học viên',
  BUTTON_ADD: 'Tạo nhóm mới',
  LABEL_NAME: 'Tên nhóm',
  PLACEHOLDER_NAME: 'Tìm theo tên nhóm',

  //EMPTY_DATA
  TITLE_EMPTY_DATA: 'Chưa tồn tại nhóm học viên nào',
  SUBTITLE_EMPTY_DATA: 'Vui lòng tạo mới nhóm học viên để hiển thị trên danh sách này',

  TH_NAME: 'Nhóm',
  TH_STUDENT_NUMBER: 'Số lượng học viên',
  TH_TOTAL_COURSE: 'Số lượng khoá học',
  TH_E_BOOK: 'Số lượng sách điện tử',
  TH_UPDATE: 'Cập nhật',
  TH_ACTION: 'Thao tác',
  TOOLTIP_EDIT_GROUP_STUDENT: 'Chỉnh sửa nhóm học viên',
  MSG_REMOVING_STUDENT: 'Đang xoá học sinh khỏi nhóm...',
  MSG_REMOVE_STUDENT_SUCCESS: 'Xoá học sinh khỏi nhóm thành công!',
  LABEL_FILTER_GROUP_BY_NAME: 'Tên nhóm học viên',
  PLACEHOLDER_FILTER_GROUP_BY_NAME: 'Nhập tên nhóm học viên',
}
const FORM_CREATE_NEW_GROUP_STUDENT = {
  TITLE: 'Tạo nhóm mới',
  LABEL_NAME: 'Tên nhóm học viên',
  PLACEHOLDER_NAME: 'Nhập tên nhóm học viên',
  MSG_CREATING: 'Đang tạo nhóm',
  MSG_CREATE_SUCCESS: 'Tạo nhóm học thành công!',
  MSG_CHECK_NAME_AGAIN: 'Tên nhóm học đã được sử dụng',
  MSG_CREATE_FAIL: 'Tạo nhóm học thất bại',
  //MESSAGE
  MSG_ADDING_STUDENT_TO_GROUP: 'Đang thêm học sinh vào nhóm...',
  MSG_ADD_STUDENT_TO_GROUP_SUCCESS: 'Thêm học viên vào nhóm thành công!',
  MSG_ADD_STUDENT_FAIL: 'Số lượng học viên mỗi lần thêm vào nhóm đã quá giới hạn',
}
const GROUP_STUDENT_DETAIL = {
  LINK_RETURN_TO_GROUP_STUDENT_LIST: 'Danh sách Nhóm học viên',
  //TAB
  TAB_INFO: 'Thông tin',
  TAB_STUDENT: 'DS học viên',
  TAB_COURSE: 'DS khoá học',
  TAB_E_BOOK: 'DS sách điện tử',
  TAB_REPORT: 'Báo cáo',
  TAB_ADVANCED: 'Nâng cao',
  TAB_SEND_NOTICE: 'Thông báo',
  TAB_EMAIL: 'Email',
  TAB_DELETE: 'Xoá',
  //ADVANCED_SETTING
  TITLE_ADVANCED_SETTING: 'Giới hạn thời gian học',
  SUBTITLE_ADVANCED_SETTING:
    'Thiết lập giới hạn thời gian học mà học viên có thể truy cập vào các khoá học và sách điện tử trong nhóm',
  TOGGLE_CLOSE_COURSE: 'Khoá truy cập vào khoá học và sách điện tử khi vượt quá giới hạn thời gian',
  //BASIC_INFO
  TITLE_INFO: 'Thông tin chung',
  SUB_TITLE_INFO: 'Thiết lập thông tin chung nhất cho nhóm học viên',
  LABEL_NAME: 'Tên nhóm học viên (Tối đa 120 ký tự)',
  LABEL_STUDENT_QUANTITY: 'Tổng học viên',
  LABEL_FOUNDING_DATE: 'Ngày thành lập',

  MSG_UPDATE_SUCCESS: 'Cập nhật thành công',
  MSG_UPDATE_FAIL: 'Cập nhật thất bại',

  //STUDENT_ASSIGNED
  TITLE_STUDENT_LIST: 'Danh sách học viên',
  BUTTON_IMPORT_EXPORT_EXCEL: 'Nhập/xuất Excel',
  BUTTON_IMPORT_FILE_EXCEL: 'Nhập file Excel',
  BUTTON_DOWNLOAD_FILE_EXCEL: 'Download file Excel',
  BUTTON_EXPORT_FILE_EXCEL: 'Xuất file Excel',

  LABEL_PICK_STUDENT: 'Chọn học viên',
  LABEL_PICK_ALL_STUDENT: 'Chọn gửi tới tất cả học viên trong nhóm',
  PLACEHOLDER_INPUT_STUDENT: 'Nhập tên học viên cần thêm',
  MSG_ADDING_STUDENT: 'Đang thêm học sinh vào nhóm...',
  MSG_ADD_STUDENT_SUCCESS: 'Thêm học viên vào nhóm thành công!',

  TH_TIME_SEND: 'Gửi lúc',
  TH_STATUS: 'Trạng thái',
  TH_STUDENT: 'Học viên',
  TH_EMAIL: 'Email',
  TH_ACTION: 'Thao tác',
  TH_ACTION_ITEM: 'Xem chi tiết',
  TOOLTIP_IMPORT_EXCEL_MAXIMUM_STUDENT_NUMBER: 'Lưu ý: Hệ thống chỉ cho phép thêm tối đa',
  TEXT_STUDENT_EVERY_TIMES: 'học sinh mỗi lần!',
  //TABLE EXPORT EXCEL SUDENT
  TH_FULL_NAME: 'Họ tên học viên',
  // TH_EMAIL: 'Email',
  TH_PHONE_STUDENT: 'Điện thoại',
  TH_PASSWORD: 'Mật khẩu',
  TH_CREATEDAT: 'Thêm vào nhóm lúc',
  POPUP_DELETE_STUDENT_TITLE: 'Xoá học sinh khỏi nhóm',
  MSG_REMOVING_STUDENT: 'Đang xoá học sinh khỏi nhóm...',
  MSG_REMOVING_STUDENT_SUCCESS: 'Xoá học sinh khỏi nhóm thành công',

  //COURSE_ASSIGNED
  TITLE_COURSE_ASSIGNED: 'Danh sách khoá học cấp nhóm',
  LABEL_FILTER_COURSE_ASSIGNED: 'Chọn khoá học',
  PLACEHOLDER_FILTER_COUSRE: 'Tìm kiếm khoá học',
  ENTITY_COURSE_FOR_STUDENT: 'khoá học cho học sinh',
  TH_COURSE_NAME: 'Tên khoá học',
  POPUP_TITLE_DELETE_COURSE: 'Xoá khóa học khỏi nhóm',

  MSG_UPDATING_COURSE_FOR_STUDENT: 'Đang cập nhật khoá học cho học sinh từ {0} đến {1}',
  MSG_ADDING_COURSE: 'Đang thêm khóa học vào nhóm...',
  MSG_ADD_COURSE_SUCCESS: 'Thêm khóa học vào nhóm thành công',
  MSG_DELETING_COURSE: 'Đang xoá khoá học khỏi nhóm',
  MSG_DELETE_COURSE_SUCCESS: 'Xoá khoá học khỏi nhóm thành công',

  //NOTIFICATION_EMAIL
  PLACEHOLDER_INPUT_STUDENT_NOTIFICATION: 'Tìm theo học viên hoặc tiêu đề',

  //NOTIFICATION
  TITLE_POPUP: 'Soạn notification',
  SELECT_ALL_STUDENTS: 'Chọn gửi tới tất cả học viên trong nhóm',
  PLACEHOLDER_SELECT_STUDENT: 'Học viên',
  LABEL_SELECT_STUDENT: 'Chọn học viên',
  NOTIFICATION_TITLE_INPUT: 'Tiêu đề',
  SHORT_CONTENT_INPUT: 'Nội dung ngắn gọn',
  LINK_WEB_INPUT: 'Liên kết điều hướng Web',
  LINK_MOBILE_APP_INPUT: 'Liên kết điều hướng Mobile App',
  BUTTON_IMPORT_FILE_HTML: 'Nhập file HTML',
  BUTTON_SEND_ALL_GROUP: 'Gửi tới cả nhóm',
  SEND_NOTIFI_TO: 'Gửi notification',
  BUTTON_SEND: 'Gửi tới',
  //NOTIFICATION_ERROR
  TITLE_POPUP_NOTIFICATION_ERROR: 'Các notification gửi không thành công',
  TITLE_POPUP_EMAIL_ERROR: 'Các email gửi không thành công',
  TH_SEND_TO: 'Gửi tới',
  NOTIFICATION_SEND_TO: 'Gửi thông báo',
  TH_PROBLEM: 'Vấn đề phát sinh',
  //NOTIFICATION_SENT
  TITLE_POPUP_NOTIFICATION_SENT: 'Thông báo đã gửi',
  TITLE_POPUP_EMAIL_SENT: 'Email đã gửi',
  NOTIFICATION_TO: 'Tới',
  NOTIFICATION_SUCCESS: 'Gửi thành công',
  TITLE_PR: 'Tận hưởng ưu đãi hấp dẫn',
  SUBTITLE_PR: 'Nhấp vào mua khoá học ngay',
  BUTTON_CLOSE: 'Đóng',
  //EBOOK_ASSIGNED
  TITLE_DOCUMENT_ASSIGNED: 'Danh sách sách điện tử cấp cho nhóm',
  LABEL_FILTER_BOOK: 'Chọn sách',
  PLACEHOLDER_FILTER_EBOOK: 'Tìm kiếm sách',
  TH_EBOOK_NAME: 'Tên sách',
  POPUP_DELETE_EBOOK_TITLE: 'Xoá tài liệu khỏi nhóm',
  MSG_ADDING_EBOOK: 'Đang thêm tài liệu vào nhóm...',
  MSG_ADD_EBOOK_SUCCESS: 'Thêm tài liệu vào nhóm thành công',
  MSG_DELETING_EBOOK: 'Đang xoá tài liệu khỏi nhóm',
  MSG_DELETING_EBOOK_SUCCESS: 'Xoá tài liệu khỏi nhóm thành công',

  //HISTORY_SEND_EMAIL
  TITLE_HISTORY_SEND_EMAIL: 'Lịch sử gửi Email',
  BUTTON_SEND_EMAIL: 'Soạn Email',
  TITLE_HISTORY_SEND_NOTIFICATION: 'Lịch sử gửi Notification',
  MESSAGE_NO_DATA_YET: 'Chưa có dữ liệu',
  BUTTON_SEND_NOTIFICATION: 'Soạn thông báo',
  TH_HEADER: 'Tiêu đề',
  TH_EMAIL_TITLE: 'Tiêu đề Email',
  TH_SEND_EMAIL_TO: 'Gửi tới',
  SEND_EMAIL_TO: 'Gửi mail',
  TH_SEND_EMAIL_ALL: 'Gửi tới cả nhóm',
  TH_EMAIL_SENDING_TIME: 'Thời gian gửi',
  TH_EMAIL_RECIPIENTS_EMAIL_QUANTITY: 'Đã xem/Tổng số người nhận',
  //SEND_EMAIL
  TITLE_SEND_EMAIL_IN_GROUP: 'Email gửi trong nhóm',
  TITLE_SEND_EMAIL: 'Soạn Email',
  LABEL_EMAIL_SUBJECTS: 'Tiêu đề',
  PLACEHOLDER_EMAIL_SUBJECTS: 'Nhập tiêu đề Email',
  LABEL_EMAIL_TEMPLATE: 'Kiểu mẫu Email',
  BUTTON_EMAIL_HTML_TEMPLATE: 'Sử dụng Template HTML',
  BUTTON_IMPORT_FROM_EDITOR: 'Nhập từ editor',

  MESSAGE_SAVE_EMAIL_SUCCESS: 'Lưu Email thành công!',
  MESSAGE_WARNING_INFOS: 'Thông tin không được để trống',
  MESSAGE_SEND_EMAIL_SUCCESS: 'Gửi email thành công !',
  MESSAGE_CHECK_INVALID_FIELD: 'Vui lòng kiểm tra lại trường thông tin!',
  MSG_SEND_NOTIFICATION_FOR_GROUP_SUCCESS: 'Gửi thông báo cho nhóm thành công',
  //DELETE GROUP STUDENT
  TITLE_DELETE: 'Xoá nhóm học viên',
  SUBTITLE_DELETE: 'Nhóm học viên bị xóa sẽ không thể khôi phục',
  BUTTON_DELETE: 'Xoá nhóm học viên',
  POPUP_CONFIRM_DELETE_STUDENT_TITLE: 'Xoá nhóm học viên ra khỏi hệ thống',
  POPUP_CONFIRM_DELETE_STUDENT_MESSAGE: 'Nhóm học viên bị xóa sẽ không thể khôi phục',
  MSG_DELETING: 'Đang xoá nhóm học viên...',
  MSG_DELETE_SUCCESS: 'Xoá nhóm học viên thành công',
  MSG_FAIL_AS_EXCEED_LIMITATION:
    'Số học sinh vượt quá giới hạn (50 học sinh), vui lòng xoá học sinh ra khỏi nhóm trước khi xoá nhóm',
}
//TEACHER
const TEACHER_LIST = {
  TITLE: 'Giảng viên',
  BUTTON_ADD: 'Thêm giảng viên',
  LABEL_FILTER_BY_NAME_EMAIL: 'Tên giảng viên',
  PLACEHOLDER_FILTER_BY_NAME_EMAIL: 'Tìm kiếm theo tên và email của giảng viên',

  TH_TEACHER: 'Giảng viên',
  TH_EMAIL: 'Email',
  TH_ACTION: 'Thao tác',
  TOOLTIP_EDIT_TEACHER: 'Chỉnh sửa giảng viên',

  EMPTY_DATA_TITLE: 'Chưa tồn tại giảng viên nào',
  EMPTY_DATA_SUBTITLE: 'Vui lòng tạo mới giảng viên để hiển thị trên danh sách này',
}
const FORM_CREATE_NEW_TEACHER = {
  TITLE: 'Thêm giảng viên mới',
  LINK_RETURN_TO_TEACHER_LIST: 'Danh sách giảng viên',

  //BASIC_INFO
  TITLE_BASIC_INFO: 'Thông tin cơ bản',
  LABEL_TEACHER_NAME: 'Tên giảng viên',
  PLACEHOLDER_TEACHER_NAME: 'Nhập tên giảng viên',
  LABEL_PHONE: 'Số điện thoại',
  PLACEHOLDER_PHONE: 'Nhập số điện thoại',
  LABEL_EMAIL: 'Email',
  PLACEHOLDER_EMAIL: 'Nhập email',
  LABEL_INPUT_DESCRIPTION: 'Mô tả ngắn gọn',
  PLACEHOLDER_INPUT_DESCRIPTION: 'Nhập mô tả ngắn gọn cho giảng viên',
  HINT_INPUT_DESCRIPTION: 'Độ dài đề nghị: 200 kí tự',

  //DESCRIPTION
  TITLE_DESCRIPTION: 'Mô tả chi tiết',

  MSG_CREATE_SUCCESS: 'Tạo giáo viên thành công!',
  MSG_CREATE_FAIL: 'Tạo giảng viên thất bại!',
  MSG_REGISTER_EMAIL_EXISTED: 'Email giảng viên đã được sử dụng, vui lòng nhập email khác!',
}
const TEACHER_DETAIL = {
  LINK_RETURN_TO_TEACHER_LIST: 'Danh sách Giảng viên',

  //TAB
  TAB_INFO: 'Thông tin chung',
  TAB_COURSE_ASSIGNED: 'Khoá học được phân công',
  TAB_TEST: 'Bài kiểm tra được phân công',
  TAB_STUDENT_ASSIGNED: 'Học sinh được phân công',
  TAB_KNOWLEDGE: 'Khung kiến thức',
  TAB_BOOK: 'Sách của giảng viên',
  TAB_DELETE: 'Xoá giảng viên',

  // COURSE OF TEACHER
  TITLE_ASSIGN_COURSE: 'Khoá học được phân công',
  LABEL_SELECT_COURSE: 'Chọn khoá học',
  PLACEHOLDER_FILTER_COURSE: 'Tìm kiếm khoá học',
  POPUP_DELETE_ROUTE_STUDY_OUT_SYSTEM_TITLE: 'Xác nhận xoá phân công khoá học',
  MSG_DELETING_COURSE: 'Đang xoá phân công khoá học...',
  MSG_DELETE_COURSE_SUCCESS: 'Xoá phân công khoá học thành công',
  MSG_ASSIGN_COURSE: 'Đang phân thêm khoá học cho giáo viên...',
  MSG_ASSIGN_COURSE_SUCCESS: 'Phân thêm khoá học cho giáo viên thành công ',
  MSG_ASSIGN_COURSE_FAIL: 'Khóa học phân công thất bại: {0}',

  //MARK_DIVITION
  TITLE_MARK_DIVITION_LIST: 'Danh sách bài kiểm tra được phân công chấm',
  LABEL_PICK_COURSE: 'Chọn khoá học',
  PLACEHOLDER_PICK_COURSE: 'Tìm kiếm khoá học',
  LABEL_PICK_TEST: 'Chọn bài kiểm tra',
  TEXT_NO_DATA: 'Không có dữ liệu',

  TH_COURSE_NAME: 'Tên khoá học',
  TH_TEST_REQUEST: 'Bài kiểm tra theo yêu cầu',
  TH_PUBLIC_DATE: 'Ngày phân công',
  TH_ACTION: 'Thao tác',

  LABEL_FILTER_BY_UNIT: 'Chọn bài kiểm tra theo yêu cầu',
  BUTTON_ASSIGN: 'Phân công',

  MSG_ASSIGN_SUCCESS: 'Phân công giảng viên chấm bài thành công!',
  MSG_ASSIGN_FAIL: 'Hiện chưa thể phân công giảng viên được. Vui lòng thử lại sau.',
  MSG_DELETE_ASSIGN_SUCCESS: 'Bỏ phân công giáo viên thành công',

  //DELETION
  TITLE_DELETE: 'Xoá giảng viên',
  SUBTITLE_DELETE: 'Giảng viên bị xóa sẽ không thể khôi phục',
  BUTTON_DELETE: 'Xoá giảng viên',

  POPUP_CONFIRM_DELETE_TITLE: 'Bạn có chắc chắn xoá giảng viên này?',
  POPUP_CONFIRM_DELETE_MESSAGE:
    'Tất cả những dữ liệu liên quan đến giảng viên sẽ bị xóa hoàn toàn khỏi hệ thống. Bạn vẫn muốn tiếp tục?',
  MSG_DELETE_SUCCESS: 'Xoá giảng viên thành công',
}
const TEACHER_SALE = {
  TITLE: 'Doanh số của giáo viên',
  LABEL_STATISTICAL: 'Thống kê',
  OPTION_BILL_IN_MONTH: 'Đơn trong tháng',
  OPTION_INCOME: 'Doanh thu',
  //TABLE HEADER
  TH_BUYER: 'Người mua',
  TH_PRICE: 'Giá tiền',
  TH_CREATE_AT: 'Ngày tạo',
  TH_COUPON: 'Coupon',
  TH_COMBO: 'Combo',
  TH_CODE_TYPE: 'Coupon / Combo / Mã kích hoạt',
  TH_AFFILIATE: 'Affiliate',
  TH_BEFORE_DISCOUNT: 'Giá gốc',
  TH_TOTAL_PRICE: 'Thành tiền',
  TH_STATUS: 'Trạng thái',
  TEXT_CURRENT_UNIT: 'đ',
  TEXT_USER: 'Có sử dụng',
  TEXT_NOT_USE: 'Không sử dụng',
  TEXT_SUCCESS: 'Thành công',
  TEXT_WAITING: 'Đang chờ',
  TEXT_FAIL: 'Thất bại',
}
//COMMENT OF STUDENT
const COMMENT_LIST = {
  TITLE: 'Thảo luận',
  LABEL_INPUT_COURSE_NAME: 'Tên khoá học',
  PLACEHOLDER_INPUT_COURSE_NAME: 'Nhập tên khoá học',
  LABEL_TIME: 'Ngày bắt đầu - Ngày kết thúc',
  PLACEHOLDER_FILTER_TIME: 'Chọn thời gian để lọc',
  LABEL_STATUS: 'Tình trạng',
  PLACEHOLDER_SELECT_STATUS: 'Chọn tình trạng',
  OPTION_FILTER_ALL_STATUS: 'Tất cả tình trạng',
  OPTION_FILTER_PUBLISH: 'Đã duyệt',
  OPTION_FILTER_UNPUBLISH: 'Chưa duyệt',
  OPTION_FILTER_WAIT: 'Chờ duyệt',
  BUTTON_APPROVE_MULTI: 'Duyệt tất cả',

  POPUB_TITLE_DELETE: 'Xác nhận xoá bình luận',
  POPUB_SUBTITLE_DELETE: 'Bạn có chắc chắn xoá bình luận này?',
  BUTTON_DELETE: 'Xoá',

  TH_COURSE: 'Khoá học',
  TH_CONTENT: 'Nội dung',
  TH_END_UPDATE: 'Cập nhật lần cuối',
  TH_STATUS: 'Trạng thái',
  TH_ACTION: 'Thao tác',
  TEXT_UNDEFINED: 'Chưa xác định',
  TEXT_APPROVED: 'Đã duyệt',

  //POPUP
  POPUP_CONFIRM_APPROVE_TITLE: 'Xác nhận duyệt bình luận',
  POPUP_CONFIRM_APPROVE_ONE_MESSAGE: 'Bạn có chắc chắn duyệt bình luận này?',

  POPUP_CONFIRM_APPROVE_MULTI_TITLE: 'Xác nhận duyệt tất cả bình luận',
  POPUP_CONFIRM_APPROVE_MULTI_MESSAGE: 'Bạn có chắc chắn duyệt tất cả bình luận này?',

  POPUP_CONFIRM_DELETE_TITLE: 'Xác nhận xoá bình luận',
  POPUP_CONFIRM_DELETE_MESSAGE: 'Bạn có chắc chắn xoá bình luận này?',

  POPUP_CONFIRM_DELETE_MULTI_TITLE: 'Xác nhận xoá tất cả bình luận',
  POPUP_CONFIRM_DELETE_MULTI_MESSAGE: 'Bạn có chắc chắn xoá tất cả bình luận này?',

  //MESSAGE
  MSG_APPROVE_SUCCESS: 'Duyệt thảo luận thành công!',
  MSG_APPROVE_MULTI_SUCCESS: 'Duyệt tất cả thảo luận thành công!',
  MSG_DELETE_SUCCESS: 'Xoá thảo luận thành công',
  MSG_DELETE_MULTI_SUCCESS: 'Xoá tất cả thảo luận thành công',
  MESSAGE_REQUEST_PENDING: 'Đang tiếp nhận...',
  MESSAGE_REQUEST_SUCCESS: 'Thành công!',
}
//RATING OF STUDENT
const RATING_LIST = {
  TITLE: 'Đánh giá',
  //TAB
  TAB_ONLINE_COURSE: 'Khoá học Online',
  TAB_LEARNING_PATH: 'Lộ trình học',
  TAB_EBOK: 'Tài liệu điện tử',

  LABEL_NAME: 'Tên',
  PLACEHOLDE_INPUT_NAME: 'Nhâp tên',
  LABEL_COUSE: 'Khoá học',
  LABEL_LEARNING_PATH: 'Lộ trình học',
  LABEL_EBOOK: 'Tài liệu',
  //ONLINE_COURSE
  LABEL_COURSE_NAME: 'Tên khoá học',
  PLACEHOLDER_COURSE_NAME: 'Nhập tên khoá học',

  //LEARNING_PATH
  LABEL_LEARNING_PATH_NAME: 'Tên lộ trình',
  PLACEHOLDER_LEARNING_PATH_NAME: 'Nhập tên lộ trình',

  //EBOOK
  LABEL_EBOOK_NAME: 'Tên tài liệu',
  PLACEHOLDER_NAME: 'Nhập tên tài liệu',

  LABEL_FILTER_INDICATION_TIME: 'Ngày bắt đầu - Ngày kết thúc',
  PLACEHOLDER_FILTER_INDICATION_TIME: 'Chọn thời gian để lọc',
  LABEL_STATUS: 'Trạng thái',
  PLACEHOLDER_SELECT_STATUS: 'Chọn trạng thái',
  OPTION_FILTER_ALL_STATUS: 'Tất cả tình trạng',
  OPTION_FILTER_PUBLISH: 'Đã duyệt',
  OPTION_FILTER_UNPUBLISH: 'Chưa duyệt',
  OPTION_FILTER_WAIT: 'Chờ duyệt',

  POPUP_CONFIRM_APPROVE_ONE_TITLE: 'Duyệt đánh giá?',
  POPUP_CONFIRM_APPROVE_ONE_DESCRIPTION: 'Bạn có chắc muốn duyệt bài đánh giá này không?',

  POPUP_CONFIRM_APPROVE_ALL_TITLE: 'Duyệt tất cả đánh giá?',
  POPUP_CONFIRM_APPROVE_ALL_DESCRIPTION: 'Bạn có chắc muốn duyệt tất cả những bài đánh giá này không?',

  POPUP_CONFIRM_DELETE_TITLE: 'Xoá đánh giá?',
  POPUP_CONFIRM_DELETE_MESSAGE: 'Bạn có chắc muốn xoá bài đánh giá này không?',

  POPUP_CONFIRM_DELETE_ALL_TITLE: 'Xoá tất cả đánh giá?',
  POPUP_CONFIRM_DELETE_ALL_MESSAGE: 'Bạn có chắc muốn xoá những bài đáng giá này không?',

  TH_CONTENT: 'Nội dung',
  TH_END_UPDATE: 'Cập nhật lần cuối',
  TH_STATUS: 'Cập nhật',
  TH_ACTION: 'Thao tác',
  TEXT_APPROVED: 'Đã duyệt',
  TEXT_PENDDING: 'Chờ duyệt',
  TEXT_UNDEFINED: 'Không rõ',
  MESSAGE_REQUEST_PENDING: 'Đang tiếp nhận...',
  MESSAGE_REQUEST_SUCCESS: 'Thành công!',
  MSG_APPROVE_SUCCESS: 'Duyệt đánh giá thành công!',
  MSG_APPROVE_MULTI_SUCCESS: 'Duyệt tất cả đánh giá thành công!',
  MSG_DELETE_SUCCESS: 'Xoá đánh giá thành công',
  MSG_DELETE_MULTI_SUCCESS: 'Xoá tất cả đánh giá thành công',
}
//CERTIFICATE FOR COURSE
const CERTIFICATE_LIST = {
  TITLE: 'Chứng chỉ',
  BUTTON_ADD: 'Thêm chứng chỉ',
  LABEL_FILTER_BY_NAME: 'Tên chứng chỉ',
  PLACEHOLDER_FILTER_BY_NAME: 'Tìm theo tên chứng chỉ',

  TH_NAME: 'Tên chứng chỉ',
  TH_PATH_COURSE: 'Khóa học/ Lộ trình chỉ định',
  TH_CREATE_DATE: 'Ngày tạo',
  TH_GRANTED: 'Đã cấp',
  TH_ACTION: 'Thao tác',
  TOOLTIP_EDIT_CERTIFICATE: 'Chỉnh sửa chứng chỉ',

  TITLE_EMPTY_DATA: 'Chưa tồn tại chứng chỉ nào',
  SUBTITLE_EMPTY_DATA: 'Vui lòng tạo mới bài viết để hiển thị trên danh sách này',
}
const FORM_CREATE_NEW_CERTIFICATE = {
  LINK_RETURN_TO_LIST: 'Danh sách chứng chỉ',
  TITLE: 'Chứng chỉ mới',
  //TAB
  TAB_CONTENT: 'NỘI DUNG',

  //BASIC_INFO
  TITLE_INFOS: 'Thông tin chứng chỉ',
  LABEL_NAME: 'Tên chứng chỉ',
  PLACEHOLDER_NAME: 'VD: Chứng chỉ hoàn thành khóa học',
  LABEL_TYPE: 'Loại chứng chỉ',
  OPTION_ONLINE: 'Online',
  OPTION_LEARNING_PATH: 'Lộ trình học',
  LABEL_FILTER_COURSE: 'Chọn khoá học',
  PLACEHOLDER_FILTER_COURSE: 'Tìm kiếm khoá học',

  //ADVANCE_INFO
  LABEL_INPUT_HEADER: 'Tiêu đề',
  PLACEHOLDER_INPUT_HEADER: 'VD: Giấy chứng nhận',
  LABEL_INPUT_DESCRIPTION_STUDENT_NAME: 'Mô tả tên học viên',
  PLACEHOLDER_INPUT_DESCRIPTION_STUDENT_NAME: 'VD: Tên học viên',
  LABEL_INPUT_DESCRIPTION_COURNAME: 'Mô tả tên khoá học',
  PLACEHOLDER_INPUT_COURNAME_TITLE: 'Tiêu đề khoá học',

  LABEL_INPUT_AVAILABEL_DATE: 'Ngày cấp',
  PLACEHOLDER_INPUT_AVAILABLE_DATE: 'VD: Ngày cấp',
  LABEL_AVAILABLE_DATE_TITLE: 'Tiêu đề ngày cấp',
  PLACEHOLDER_AVAILABLE_DATE_TITLE: 'Ngày cấp',
  LABEL_EXPIRED_DATE_TITLE: 'Tiêu đề ngày hết hạn',
  PLACEHOLDER_EXPIRED_DATE_TITLE: 'Ngày hết hạn',
  LABEL_EXPIRED_DATE: 'Ngày hết hạn',
  PLACEHOLDER_EXPIRED_DATE: 'VD: 12/12/2020',
  LABEL_CHANGE_PHOTO_DESIGN: 'Thay ảnh thiết kế',
  BUTTON_SELECT_PHOTO_FROM_LIBRARY: 'Chọn ảnh từ thư viện',
  BUTTON_UPLOAD_PHOTO: 'Tải ảnh lên',
  HINT_CHANGE_PHOTO_DESIGN: 'Kích thước khuyên dùng: 1024x726px',

  MSGH_CREATE_CERTIFICATE_SUCCESS: 'Tạo chứng chỉ thành công',
}
const CERTIFICATE_DETAIL = {
  LINK_RETURN_TO_CERTIFICATE_LIST: 'Danh sách chứng chỉ',
  TAB_INFO: 'Thông tin chung',
  TAB_HISTORY_GRANT_CERTIFICATE: 'Lịch sử cấp chứng chỉ',

  //BASIC_INFO
  LABEL_NAME: 'Tên chứng chỉ',
  LABEL_PATH_COURSE: 'Khóa học/ Lộ trình chỉ định',
  LABEL_CREATE_DATE: 'Ngày tạo',
  //ENABLE
  TITLE_STOP_APPLICATION: 'Ngừng áp dụng chứng chỉ này',
  TITLE_APPLICATION: 'Áp dụng chứng chỉ này',
  BUTTON_STOP_APPLICATION: 'Ngừng áp dụng',
  BUTTON_APPLICATION: 'Áp dụng',
  SUBTITLE_APPLICATION_AND_STOP_APPLICATION: 'Tùy chỉnh cho phép sử dụng chứng chỉ này trên khóa học đã cài đặt.',

  //DELETION
  TITLE_DELETE: 'Xóa chứng chỉ',
  SUBTITLE_DELETE: 'Học viện đã nhận được chứng chỉ này trước khi xóa vẫn sẽ được lưu lại kết quả và chứng chỉ.',
  BUTTON_DELETE: 'Xoá chứng chỉ',

  POPUP_CONFIRM_DELETE_TITLE: 'Bạn có chắc muốn xóa chứng chỉ này?',
  POPUP_CONFIRM_DELETE_MESSAGE: 'Dữ liệu sau khi xóa không thể khôi phục',

  //HISTORY_GRANT
  TITLE_STUDENT_LIST: 'Danh sách học viên đã nhận',
  LABEL_FILTER_BY_STUDENT_NAME: 'Tên học viên',
  PLACEHOLDER_FILTER_BY_STUDENT_NAME: 'Tìm kiếm theo tên học viên',

  TH_STUDENT_NAME: 'Tên học viên',
  TH_AVAILABLE_AT: 'Ngày cấp',
  TH_EXPIRED_AT: 'Ngày hết hạn',

  MSG_UPDATE_SUCCESS: 'Cập nhật thành công',
  //VIEW_CERTIFICATE
  LABEL_STUDENT_NAME: 'Tên học viên',
  VIEW_CERTIFICATE: {
    TITLE: 'Giấy chứng nhận',
    SUBTITLE: 'Chuyên viên quản lý',
    DESCRIPTION: 'Đã hoàn thành khóa học',
    SUBDESCRIPTION: 'Ngày cấp',
    TITLE_EXPIRED: 'Hết hạn',
    HEADER: 'Tiêu đề khoá học',
  },
  STUDENT_HAS_CERTIFICATE_LIST: {
    TITLE: 'Danh sách học viên đã nhận',
    LABEL_FILTER_BY_NAME: 'Tên học viên',
    PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên học viên',
    TH_NAME: 'Tên học viên',
    TH_ACHIEVE_DATE: 'Ngày cấp',
    TH_EXPIRED: 'Ngày hết hạn',
  },
}
//FORM CHANGE PASSWORD USE FOR STUDENT AND TEACHER
const FORM_CHANGE_PASSWORD = {
  TITLE: 'Mật khẩu',
  LABEL_INPUT: 'Mật khẩu',
  PLACEHOLDER_INPUT: 'Nhập mật khẩu',
  LABEL_INPUT_AGAIN: 'Nhập lại mật khẩu',
  PLACEHOLDER_INPUT_AGAIN: 'Nhập lại mật khẩu',
  MSG_NOT_SAME: 'Mật khẩu không khớp',
  MSG_CHECK_AGAIN: 'Vui lòng kiểm tra lại mật khẩu !',
}
const FORM_PREVIEW_IMAGE = {
  TITLE: 'Ảnh hiển thị',
  SUBTITLE: 'Kích thước khuyên dùng: 300x300px',
}
export default {
  STUDENT_LIST,
  FORM_CREATE_NEW_STUDENT,
  STUDENT_DETAIL,
  GROUP_STUDENT_LIST,
  FORM_CREATE_NEW_GROUP_STUDENT,
  GROUP_STUDENT_DETAIL,
  TEACHER_LIST,
  FORM_CREATE_NEW_TEACHER,
  TEACHER_DETAIL,
  TEACHER_SALE,
  COMMENT_LIST,
  RATING_LIST,
  CERTIFICATE_LIST,
  FORM_CREATE_NEW_CERTIFICATE,
  CERTIFICATE_DETAIL,
  FORM_CHANGE_PASSWORD,
  FORM_PREVIEW_IMAGE,
  BUTTON_DELETE: 'Xoá',
  BUTTON_SAVE: 'Lưu',
  BUTTON_ADD: 'Thêm',
  BUTTON_CANCEL_TEXT: 'Huỷ',
  BUTTON_CANCEL: 'Huỷ bỏ',
  BUTTON_CONFIRM: 'Xác nhận',
  BUTTON_DELETE_CANCEL: 'Huỷ',
  BUTTON_DELETE_CONFIRM: 'Xoá',
  BUTTON_EDIT: 'Chỉnh sửa',
  BUTTON_UPDATE: 'Cập nhật',
  BUTTON_RESEST: 'Reset',
  BUTTON_SEARCH: 'Tìm kiếm',
  BUTTON_SEND: 'Gửi',
  LABEL_LAST_TIME: 'Lần cuối',
  LABEL_UNDEFINED: 'Chưa xác định',
  //SUBTITLE POPUP
  POPUP_CORFIRM_DELETE_DATA_MESSAGE: 'Tài liệu sau khi xóa không thể khôi phục',
  //MESSAGE
  MSG_UPDATING: 'Đang cập nhật ...',
  MSG_UPDATE_SUCCESS: 'Cập nhật thành công !',
  MSG_GETTING_DATA: 'Đang lấy dữ liệu...',
  MSG_RECHECK_INVALID_FIELD: 'Vui lòng kiểm tra lại trường thông tin!',
  MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin!',
  MSG_CHECK_HAS_EXISTED: 'Khóa học đã tồn tại chứng chỉ',
  MSG_CREATE_CERTIFICATE_FAIL: ' Tạo chứng chỉ thất bại',
  ENTITY_STUDENT: 'Học viên',
}
