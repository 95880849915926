<template>
	<span class="outlined">
		<v-autocomplete
			class="body-2"
			v-bind="$attrs"
			:items="items"
			:loading="isLoading"
			:search-input.sync="search"
			:label="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_FILTER_BOOK')"
			:placeholder="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.PLACEHOLDER_FILTER_EBOOK')"
			:item-text="itemText"
			:item-value="itemValue"
			:value="value"
			:multiple="isMultipleSelect"
			:chips="isMultipleSelect"
			return-object
			dense
			outlined
			@change="value => onSelected(value)"
		>
			<template v-if="isMultipleSelect" v-slot:selection="data">
				<v-chip
					small
					v-if="!data.item.disabled"
					v-bind="data.attrs"
					:input-value="data.selected"
				>
					{{ data.item.title }}
				</v-chip>
			</template>
		</v-autocomplete>
	</span>
</template>

<script>
import debounce from 'lodash-es/debounce'
import {mapActions} from 'vuex'
export default {
	props: {
		itemText: String,
		itemValue: String,
		isMultipleSelect: {
			type: Boolean,
			default: false
		},
		value: [Array, String]
	},
	data() {
		return {
			listSelected: [],
			isLoading: false,
			search: '',
			items: []
		}
	},
	mounted() {
		this.fetchBooks()
	},
	methods: {
		...mapActions({
			fetch: 'ebook/fetchBooks'
		}),
		async refreshItems() {
			await this.$utils.delay(300)
			this.handlerData(this.items)
		},
		onSelected(selected) {
			if (this.isMultipleSelect) {
				this.listSelected = selected
			} else {
				this.listSelected = selected ? [selected] : []
			}
			this.$emit('change', this.isMultipleSelect ? this.listSelected : selected)
			this.$emit('input', this.isMultipleSelect ? this.listSelected : selected)
		},
		async fetchBooks(searchString) {
			try {
				let params = {
					_limit: 10,
					_skip: 0,
					tags_contain: searchString || ''
				}
				this.items = await this.fetch(params)
				if (this.listSelected && this.listSelected.length) {
					this.items = this.items.concat(
						this.listSelected ? this.listSelected : []
					)
				}
				this.handlerData(this.items)
			} catch (error) {
				this.$message.error(error)
			}
			this.isLoading = false
		},
		handlerData(items) {
			if (this.isMultipleSelect) {
				this.items = items.map(book => {
					let bookSelected = this.value.find(v => v.id === book.id)
					return {
						...book,
						disabled: bookSelected ? bookSelected.disabled : false
					}
				})
			}
			this.items.sort((a, b) => {
				let nameA = a.title.toUpperCase()
				let nameB = b.title.toUpperCase()
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0
			})
		},
		debounce: debounce(function() {
			this.fetchBooks(this.tags)
		}, 1000)
	},
	computed: {
		tags() {
			return this.search ? this.$utils.clearUnicode(this.search) : ''
		}
	},
	watch: {
		tags() {
			if (this.isLoading) return
			this.isLoading = true
			this.debounce()
		}
	}
}
</script>
<style lang="scss" scoped>
::v-deep .v-list-item--disabled {
	opacity: 0.5;
}
</style>
