<template>
	<span class="outlined">
		<v-autocomplete
			class="body-2"
			v-bind="$attrs"
			:items="items"
			:loading="isLoading"
			:search-input.sync="search"
			:label="$vuetify.lang.t('$vuetify.COURSE_CONTENT.LEARNING_PATH_LIST.LABEL_SELECT_LEARNING_PATH')"
			:placeholder="$vuetify.lang.t('$vuetify.COURSE_CONTENT.LEARNING_PATH_LIST.PLACEHOLDER_FILTER_LEARNING_PATH')"
			:item-text="itemText"
			:item-value="itemValue"
			:value="value"
			return-object
			dense
			outlined
			@change="(value) => $emit('change', value)"
		/>
	</span>
</template>

<script>
import {debounce} from 'lodash'
import {mapActions, mapGetters} from 'vuex'
export default {
	props: {
		itemText: String,
		itemValue: String,
    value: String,
	},
	data() {
		return {
			isLoading: false,
			search: '',
			items: [],
		}
	},
	mounted() {
		this.fetchLearningPaths()
	},
	methods: {
		...mapActions({
			fetch: 'learningPath/fetchPaths',
		}),
		async refreshItems() {
			await this.$utils.delay(500)
			this.handlerData(this.items)
		},
		async fetchLearningPaths(searchString) {
			try {
        this.items = this.getPaths
				this.items = await this.fetch({
					_limit: 10,
          _skip: 0,
          tags_contain: searchString || ''
				})
				this.items.sort((a, b) => {
          let nameA = a.title.toUpperCase()
          let nameB = b.title.toUpperCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
			} catch (error) {
				this.$message.error(error)
			}
			this.isLoading = false
		},
		debounce: debounce(function() {
      this.fetchLearningPaths(this.tags)
    }, 1000),
	},
	computed: {
    ...mapGetters({
      getPaths: 'learningPath/getPaths'
    }),
		tags() {
			return this.search ? this.$utils.clearUnicode(this.search) : ''
		},
	},
	watch: {
		tags() {
			if (this.isLoading) return
			this.isLoading = true
			this.debounce()
		},
	},
}
</script>