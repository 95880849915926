import Repository from '@/api/core';
import axios from 'axios'
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_PHYSICAL_PRODUCT + '/product-coupon'

const fetch = params => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
  return Repository(baseURL).fetchOne(id)
}

const fetchOneWithParams = (id, params, option) => {
	return axios.get(
		baseURL + '/' + id,
		{
			params: {vendorId: vendor.state.vendor.vendorId, ...params},
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

const create = params => {
  return Repository(baseURL).create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const remove = id => {
  return Repository(baseURL).delete(id)
}

const count = params => {
  return Repository(baseURL).count(params)
}

export default {
  fetch,
  fetchOne,
  fetchOneWithParams,
  create,
  update,
  remove,
  count,
}