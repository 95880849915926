<template>
  <div
    @contextmenu.prevent="(e) => e.preventDefault()"
    class="full-height full-width d-flex justify-center"
  >
    <v-progress-circular
      v-if="loading"
      class="mt-12"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <iframe
      v-else
      id="learning-iframe"
      @mouseup="clickInsideIframe"
      class="full-height no-border"
      :style="`width: ${widthIframe}`"
      :src="currentDomain"
      :title="$vuetify.lang.t('$vuetify.SITE_BUILDER.IFRAME_LEARNING_PAGE_TITLE')"
    ></iframe>
  </div>
</template>
<script>
export default {
  props: {
    domain: String,
    resolution: String,
  },
  data() {
    return {
      currentDomain: "",
      widthIframe: "100%",
      loading: false,
    };
  },
  mounted() {
    setTimeout(() => {
      var myConfObj = {
        iframeMouseOver: false,
      };
      window.addEventListener("blur", function () {
        if (myConfObj.iframeMouseOver) {
          console.log("Wow! Iframe Click!");
        }
      });

      document
        .getElementById("learning-iframe")
        .addEventListener("mouseover", function () {
          myConfObj.iframeMouseOver = true;
        });
      document
        .getElementById("learning-iframe")
        .addEventListener("mouseout", function () {
          myConfObj.iframeMouseOver = false;
        });
    }, 5000);
  },
  methods: {
    clickInsideIframe() {},
    reload() {
      this.loading = true;
      setTimeout(() => {
        this.$forceUpdate();
        this.loading = false;
      }, 1000);
    },
  },
  watch: {
    resolution: {
      handler(value) {
        if (value) {
          this.widthIframe = value;
          this.reload();
        }
      },
      immediate: true,
    },
    domain: {
      handler(value) {
        if (value) {
          const protocol = "https";
          this.currentDomain = `${protocol}://${value}`;
          this.$message.loading(
            this.$vuetify.lang.t("$vuetify.SITE_BUILDER.MSG_UPDATE_SITE_LOADING")
          );
          this.reload();
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.no-border {
  border: none;
}
</style>
