<template>
  <div class="pa-4" v-if="isLoading">
    <BaseLoadingAllScreen customDisplay>
      <div slot="object-inside">
        <div class="wrapper-object">
          <v-progress-circular
            :rotate="180"
            :size="100"
            :width="10"
            :value="value"
            color="white"
            >{{ value }}%</v-progress-circular
          >
          <div class="status text-center" v-if="!isCustomStatus">
            {{
              $vuetify.lang.t(
                "$vuetify.COURSE.DEFAULT_PROGRESS_BAR",
                displayStatus,
                entity,
                numberOfQuestion
              )
            }}
          </div>
          <div class="status status-custom" v-else>
            {{ customStatus }}
          </div>
        </div>
      </div>
    </BaseLoadingAllScreen>
  </div>
</template>
<script>
export default {
  props: {
    entity: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      status: "update",
      numberOfQuestion: 0,
      height: 2,
      value: 0,
      isCustomStatus: false,
      customStatus: "",
    };
  },
  computed: {
    displayStatus() {
      if (this.status === "update") {
        return this.$vuetify.lang.t("$vuetify.COURSE_CONTENT.BUTTON_UPDATE");
      } else if (this.status === "create") {
        return this.$vuetify.lang.t("$vuetify.COURSE.BUTTON_CREATE");
      } else if (this.status === "delete") {
        return this.$vuetify.lang.t("$vuetify.COURSE.BUTTON_DELETE");
      } else if (this.status === "get") {
        return "lấy";
      } else return "";
    },
  },
  methods: {
    clearPercent() {
      this.value = 0
    },
    changePercentage(status, number, total) {
      this.isCustomStatus = false;
      this.numberOfQuestion = number + 1;
      this.status = status;
      const percentage = Math.round((this.numberOfQuestion / total) * 100);
      this.value = percentage > 100 ? 100 : percentage;
    },
    changeCustomStatus(customStatus, value = 0) {
      this.isCustomStatus = true;
      this.value = value;
      this.customStatus = customStatus || "";
    },
    openLoading() {
      this.isLoading = true;
    },
    closeLoading() {
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.status {
  margin-top: 15px;
  position: relative;
  left: -33px;
  color: white;
  text-align: center;
  &.status-custom {
    left: -53px;
  }
}
.wrapper-object {
  width: 200px;
}
</style>
