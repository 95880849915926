import axios from 'axios'
import auth from '@/store/modules/auth'
import {message} from '@/plugins/message.js'
import get from 'lodash-es/get'

export default function setup() {
  axios.interceptors.request.use(
    (config) => {
      if (!config.ignoreAuth) {
        const token = auth.state && auth.state.auth ? auth.state.auth.accessToken : null
        if (token && !config.url.includes('bucket.s3')) config.headers.Authorization = token
      }
      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status !== 401) {
        const messageErr = get(error, 'response.data.errorMessage', null)
        const messageCode = get(error, 'response.data.code', null)
        if (messageErr && messageCode === 500) message.error(messageErr)
        return Promise.reject(error)
      } else {
        message.error('Hết phiên đăng nhập, vui lòng đăng nhập lại')
        return Promise.reject(error)
      }
    }
  )
}
