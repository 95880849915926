import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const DepartmentRepository = RepositoryFactory.get('department')

const state = {
  departments: [],
  department: {},
};

const actions = {
  async fetchDepartments({ commit }, params) {
    const res = await DepartmentRepository.fetch(params)
    commit('setDepartments', res.data || [])
    return res.data || []
  },
  async fetchDepartment({ commit }, id) {
    const res = await DepartmentRepository.fetchOne(id)
    commit('setDepartment', res.data || {})
    return res.data || {}
  },
  async createDepartment({ commit }, params) {
    const res = await DepartmentRepository.create(params)
    commit('addOrUpdate', res.data || {})
    return res.data || {}
  },
  async updateDepartment({ commit }, { id, params }) {
    const res = await DepartmentRepository.update(id, params)
    commit('addOrUpdate', res.data || {})
    return res.data
  },
  async removeDepartment({ commit }, { id }) {
    const res = await DepartmentRepository.remove(id)
    commit('removeDepartment', id)
    return res.data
  },
}

const mutations = {
  setDepartments(state, departments) {
    state.departments = departments.reverse()
  },
  setDepartment(state, department) {
    state.department = department
  },
  removeDepartment(state, id) {
    let deletedIndex = state.departments.findIndex(listItem => listItem.id === id)
    if (deletedIndex > -1) {
      state.departments = state.departments.filter(listItem => listItem.id !== id)
    }
  },
  addOrUpdate(state, department) {
    if (department && department.id) {
      let foundIndex = state.departments.findIndex(listItem => listItem && listItem.id === department.id)
      if (foundIndex >= 0) {
        state.departments.splice(foundIndex, 1, department)
      } else {
        state.departments.unshift(department)
      }
    }
  },
};

const getters = {
  getDepartments: (state) => state.departments,
  getDepartment: (state) => state.department,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
