export default {
    HINT_TIME_NOT_INVALID:"Time is not valid",
    HINT_INFO_OBLIGATORY:"Required Information",
    HINT_NO_WHITESPACES: "No spaces",
    HINT_PERCENTAGE: "Value must be less than 100 and greater than 0",
    HINT_MAX_LENGTH: "The maximum length is {0}",
    HINT_MIN_LENGTH: "Minimum length is {0}",
    HINT_PHONE_NUMBER_FALSE:"Incorrect phone number",
    HINT_EMAIL_FORMAT_FALSE:"Invalid e-mail format.",
    HINT_URL_FORMAT_FALSE: "Invalid path format",
    HINT_NUMBER_FORMAT_FALSE: "Invalid number format",
    HINT_VALID_PRICE: "The e-Payment Gateway requires transaction from at least 10.000VNĐ !",
    HINT_NUMBER_GREATER_EQUAL: "Number greater than or equal to {0}",
    HINT_NUMBER_GREATER_THAN: "Number greater than {0}",
    HINT_NUMBER_LESS_THAN: "Number less than {0}",
    HINT_NUMBER_LESS_EQUAL: "Number greater than or equal to {0}",
    HINT_NUMBER_CLAMP: "Number between {0} and {1}",
    HINT_DATE_GREATER_THAN: "The date selected must be after date {0}",
    HINT_DATE_LESS_THAN: "The selected date must be before {0}",
    HINT_TIME_LESS_THAN:"The start time must be before the end time!",
    HINT_DOMAIN_FORMAT_FALSE:"Wrong domain format.",

    HINT_AT_LEAST_ONE: "At least 1 character",
    MSG_CHECK_FILE_FORMAT_AGAIN:'Please check the file format',
}