import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_LEARN + '/book'

const count = params => {
	// const query = queryString.stringify({
	// 	...params,
	// 	vendorId: vendor.state.vendor.vendorId
	// })
	return Repository(baseURL + '/count').fetch(params)
}

const fetch = params => {
	return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
	return Repository(baseURL + '/admin').fetchOne(id)
}

const create = params => {
	return Repository(baseURL).create(params)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const remove = id => {
	return Repository(baseURL).delete(id)
}

const fetchForMembership = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + `?${query}`).fetch()
}

export default {
	count,
	fetch,
	fetchOne,
	create,
	update,
	remove,
	fetchForMembership,
}
