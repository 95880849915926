const AUTH_ROUTES={
  TITLE: "Login",
}
const HIDDENT_ROUTES={
  TITLE: "Management page",
  LABEL_NOTIFICATIONS: 'Notifications',
  LABEL_EVENT_DETAIL:"Event details",
  LABEL_EBOOK_DETAIL:"E-book details",
  LABEL_TOPIC_DEAIL:"Topic Details",
  LABEL_DEPARTMENT_DETAIL:"Basic details",
  LABEL_MANAGE_ZOOM:"Manage Zoom Classrooms",
  LABEL_CREATE_ZOOM:"Create a Zoom classroom",
  LABEL_AFFILIATE_DETAIL: "Affiliate details page",
  LABEL_CREATE_TEACHER: "Create a new trainer",
  LABEL_CREATE_STUDENT:"Create a new student",
  LABEL_TEACHER_DETAIL: "Trainer details",
  LABEL_STUDENT_DETAIL:"Students Details",
  LABEL_ACTIVE_CODE_DETAIL:"Activation Code Details",
  LABEL_ACTIVE_CODE_CREATE:"Generate new activation code",
  LABEL_COUPON_DETAIL:"Coupon details",
  LABEL_CREATE_COUPON:"Create a new coupon",
  LABEL_COMBO_DETAIL:"Combo Details",
  LABEL_BLOG_DETAIL:"Post details",
  LABEL_GROUP_STUDENT_DETAILL:"Students group details",
  LABEL_CREATE_CETIFICATE: "Generate Certificate",
  LABEL_CETIFICATE_DETAIL: "Certificate Details",
  LABEL_COURSE_PROGRESS_DETAIL: "Details of progress reports",
  LABEL_LEARNING_PATH_DETAIL:"Route details",
}
const NAVIGATION_ROUTES={
  //COURSE_CONTENT
  COURSE_CONTENT:{
    TITLE:"Course Content",
    LINK_TO_ONLINE_COURSE_LIST:"Online Course",
    LINK_TO_OFFLINE_COURSE_LIST:"Offline Course",
    LINK_TO_LEARNING_PATH_LIST:"Learning path",
    LINK_TO_CERTIFICATE_LIST:"Certificate",
    LINK_TO_EVENT_LIST:"Event",
    LINK_TO_EBOOK_LIST:"E-book",
    LINK_TO_TOPIC_LIST:"Topic",
    LINK_TO_FILE_STORAGE_LIST:"Library",
    LINK_TO_QUESTION_BANK_LIST: "Questions Bank",
    LINK_TO_STORE_EXAM_QUESTION_LIST:"Question Generate",
    LINK_TO_DEPARTMENT_LIST:"Department",
    LINK_TO_ASSIGNED_HOMEWORK_LIST:"Assigned test",
  },
  PHYSICAL_PRODUCT: {
    TITLE: "Other products",
    LINK_TO_PRODUCT_LIST: "Product List",
    LINK_TO_PRODUCT_CATEGORY_LIST: "Category"
  },
  //USER_MANAGE
  USER_MANAGE:{
    TITLE: "Student - User",
    LINK_TO_STUDENT_LIST:"Students",
    LINK_TO_STUDENT_GROUP_LIST:"Student Group",
    LINK_TO_TEACHER_LIST:"Teacher",
    LINK_TO_COMMENT_LIST:"Comment",
    LINK_TO_RATING_LIST:"Rating",
  },
  //FINANCE_MANAGE
  FINANCE_MANAGE:{
    TITLE:"Financial Management",
    LINK_TO_ACTIVATION_CODE_CAMPAIGN_LIST:"Activation Code",
    LINK_TO_COUPON_CAMPAIGN_LIST:"Coupon",
    LINK_TO_COMBO_CAMPAIGN_LIST:"Combo",
    LINK_TO_BILL_MANAGE_LIST:"Manage Orders",
    LINK_TO_SALES_LIST:"Sales",
    LINK_TO_SALES_TEACHER_LIST:"Teacher Sales",
    LINK_TO_CONTACT_LIST:"Contact",
    LINK_TO_AFFILIATE_LIST:"Affiliate",
  },
  //WEBSITE_DISPLAY
  WEBSITE_DISPLAY:{
    TITLE: "Website interface",
    LINK_TO_MENU_SETTING:"Menu Settings",
    LINK_TO_SITE_BUILD:"Site Builder",
    LINK_TO_BLOG_LIST:"News - Blog",
  },
  //APP_DISPLAY
  APP_DISPLAY:{
    TITLE: "App interface",
    LINK_TO_SCREEN_BIULD:"Screen Builder",
  },
  //SESTING_SYSTEM
  SESTING_SYSTEM:{
    TITLE: "General Settings",
    LINK_TO_BASIC_SETTING: "Basic Settings",
    LINK_TO_ADVANCED_SETTING:"Advanced Settings",
  },
  //MY_ACCOUNT
  MY_ACCOUNT:{
    TITLE: "Account",
    LINK_TO_CHANGE_PASSWORD_SETTING:"Change Password",
    LINK_TO_CONTACT_INFO_SETTING:"Contact Information"
  },
  SIGN_OUT: "Sign Out",
}
const HIDDEN_WITHOUT_MENU_ROUTES={
  LABEL_ONLINE_COURSE_DETAIL:"Detailed Online Course",
  LABEL_OFFLINE_COURSE_DETAIL:"Detailed Offline Course",
  LABEL_TESTING: "Testing",
  LABEL_ASSIGNED_HOMEWORK_DETAIL:"Test Details",
}
export default{
  AUTH_ROUTES,
  HIDDENT_ROUTES,
  HIDDEN_WITHOUT_MENU_ROUTES,
  NAVIGATION_ROUTES,
  AVATAR:"Picture on the card",
  MAX: "Max",
  KEY_WORD: "Character",
  EVALUETE_OF_STUDENT:"Student Rating",
  HERE: "Here",

  BASIC: "Basic",
  BACKGROUND:"Background image in detail page",

  COURSE:"Course",
  CREATED_LIST:"Create more courses",
  CHOOSE_COURSE:"Select a course",
  COURSE_NOT_SETTING_AUTO:"The course has not been set to automatically timed",

  DISCRIPTION_SHORT:"Short description on tag",
  DISCRIPTION_DETAIL:"Detailed Description",
  DISCRIPTION_DETAIL_HINT:"Display route details page",

  ROUTE_NAME:"Route name",
  PLACEHOLDER_ROUTE_NAME:"Search by route name",
  ROUTE_LIST:"Route List",

  INFORMATION: "Information",
  INFO_BASIC:"Basic Information",
  INFO_BASIC_HINT:"Set the most general information for the route",
  
  LEVEL_DIFFICUL:"Difficulty Level",
  LIST:"Catalog",
  LISH_COURSE:"List of courses",

  TIME: "Duration",
  TIME_COURSE:"Duration of the course",
  TIME_HINT:"Set course duration description",
  
  PICK_AUTO_ENTER:"Enter description yourself",
  PICK_ALLOW_SYSTEM:"Allows the system to calculate itself based on courses",
  PICK_ALLOW_USE_CERTIFICATE:"Allow the use of certificates for the learning path",
  PICK_ALLOW_USE_CERTIFICATE_HINT:"You already have a certificate for this course. View certificate details",
  PICK_ALLOW_STUDENT_EVALUATE:"Allows students to evaluate and display the assessment corresponding to the route",
  PICK_OBLIGATORY_BROW_EVALUATE:"Review review required before being posted publicly on the site",
  PUBLISH:"Publishing",
  PUBLISH_ROUTE_STUDY:"Publish learning route",
  PUBLISH_ROUTE_STUDY_HINT: "This route will be publicly available on the website and can be found in search engines.",
  PUBLISHED:"Published",
  
  NOTE: "Note",
  NOTE_HINT: "Courses that have not been set to automatically measure duration will not be counted towards the total route duration.",

  SETTING_CERTIFICATE:"Certificate",
  SETTING_CERTIFICATE_HINT:"Use the certificate for the learning path. The certificate will be issued when the student completes the course.",
  STOP_PUBLISH:"Stop publishing",

  REMOVE_ROUTE_STUDY:"Delete the learning route",
  REMOVE_ROUTE_STUDY_HINT:"Deleted learning path cannot be restored",
  REMOVE_ROUTE_STUDY_OUT_SYSTEM: "Delete the learning path from the system",
  REMOVE_ROUTE_STUDY_OUT_SYSTEM_HINT:"Data after deletion cannot be recovered",

  ENTER_SEARCH:"Enter to search",

  TITLE_EXAM_ASSIGNED:"Assigned test",
  TITLE_SALES_TEACHER:"Teacher Sales",

  TITLE_ONLINE_COURSE_DETAIL:"Detailed Online Course",
  TITLE_MARK_MULTIPLE_CHOICE:"Multiple choice test scores",
  TITLE_EXERCISE:"Exercise",
  TITLE_STUDY_REPORT:"Study Report",
  TITLE_ONLINE_COURSE:"OnLine Course",
  TITLE_COURSE_CONTENT:"Course Content",
  TITLE_FINANCIAL_MANAGEMENT:"Financial Management",
  TITLE_AFFILIATE_DETAIL:"Affiliate details page",
}