import en from '@/plugins/locale.en.js'
import AUTHENTICATION from './en/authentication'
import DASHBOARD from './en/dashboard'
import COURSE from './en/course'
import COURSE_CONTENT from  './en/courseContent'

import MANAGE_FINANCE from './en/manageFinance'
import MENU_SETTING from './en/menuSetting'
import SITE_BUILDER from './en/siteBuilder'

import ROUTE from './en/route'
import BLOG from  './en/blog'

import SYSTEM_SETTING from  './en/systemSetting'
import MY_ACCOUNT from  './en/myAccount'
import USER_MANAGE from './en/userManage'
import DEFAULT_DATA from './en/defaultData'
import INPUT from './en/input'

export default {
  AUTHENTICATION,
	DASHBOARD,
  COURSE,
  COURSE_CONTENT,
  MANAGE_FINANCE,
  MENU_SETTING,
  SITE_BUILDER,
  ROUTE,
  BLOG,
  SYSTEM_SETTING,
  MY_ACCOUNT,
  USER_MANAGE,
  DEFAULT_DATA,
  INPUT,
  BUTTON_SEARCH:"Search",
  LABEL_PAGE:"Page",
  LABEL_TO:"From",
  LABEL_SUM:"total",
  TEXT_SUM:"Total",
  LABEL_NOT_YET:"not yet",
  TITLE_HOME_PAGE:"Home",
  BUTTON_CANCEL:"Cancel",
  BUTTON_CONFIRM:"Confirm",
  TOOLTIP_FILE_NOT_HEADER:"The file has no header",
  STUDENTS:"Students",
  TITLE_TOPIC:"Theme",
  TEXT_GROUP:"group",
  TEXT_QUESTION:"sentence",
  TEXT_QUESTION_CHILD:"subquestion",
  TEXT_NOT_NAME:"No name",
  TEXT_NOT_EMAIL:"No email",
  TEXT_HOUR:"sound",
  TEXT_MINUTE:"minute",
  TEXT_SECOND:"second",
  LABEL_VIDEO:"Video",
  LABEL_PDF_SLIDE:"PDF Document",
  LABEL_TEST:"Test (Self-created Topic)",
  LABEL_EXERCISE:"Exam (Available Topics)",
  LABEL_HOMEWORK:"Homework",
  LABEL_MATRIX:"Test (Matrix)",
  LABEL_AUDIO:"Audio",

  REJECTED:"Rejected",
  SUCCESS: "Done",
  PENDING:"Wait",
  FAILED: "Failure",
  RETURNED:"Pay",
  LABEL_TRANSFER:"Transfer",
  LABEL_DIRECT_PAY:"Cash",
  LABEL_VN_PAY:"VNPAY",
  LABEL_ADMIN_CREATED:"Admin created",
  LABEL_ACTIVATION_CODE:"Activation code",
  TEXT_WHEN:"at ",
  TEXT_ALMOST:"Almost {0} hours",
  TEXT_MORE_THAN:"More than {0} hours",
  LABEL_TEXT_TEST:"Quit preview",
  //BASELOADINGALLSCREEN
  HINT_MARK_NOTE:"The score cannot be more than 3 digits odd",
  HINT_TIME_FOR_QUESTION:"Time for each sentence must exist and must not be odd",
  HINT_UNDER_QUESTION_IN_QUESTION_BANK:"insufficient number of sentences in the question bank",
  MSG_FILE_FOMAT_FALSE:"Invalid file format, please check or try again with the sample template!",
  //EMPTY_DATA
  TITLE_EMPTY_DATA:"No data exists",
  SUBTITLE_EMPTY_DATA:"Please create new data to display on this list",
  BUTTON_EMPTY_DATA:"Generate Data",
  LABEL_NAME_CATEGORY_CHILD:"Subcategory name",
  PLACEHOLDER_INPUT_NAME_CATEGORY_CHILD:"Enter the name of the subcategory you want to add",
  MSG_FILTERING_CONTACT:"Filtering contacts ...",
  TEXT_YES: "Yes",
  TEXT_NO: "No",
  MSG_NOTICATION_NOT_DOWNLOAD_FILE:"The file cannot be downloaded at the moment. Please clear the cache or use an incognito browser",
  BUTTON_POST:"Post",
  BUTTON_VIEW_ACCEPT:"View and browse",
  LABEL_DATE:"day",
  BUTTON_COPY:"Copy",
  INFO_GENERAL:"General Information",
  LABEL_ON: "on",
  MSG_ERROR_SYSTEM: "System Error",
  ...en
}