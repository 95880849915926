const OPTIONS = {
  ONLINE_COURSE_TOPIC_LABEL: 'Danh mục khoá học online',
  ONLINE_COURSE_TOPIC_DESCRIPTION: 'Hiển thị danh mục trên menu. Nếu có ít hơn 3 danh mục thì không nên bật hiển thị danh mục.',

  PROGRAM_LABEL: 'Chương trình học',
  PROGRAM_DESCRIPTION: 'Menu danh mục lựa chọn Khóa học Online, Khoá học Offline, Sự kiện và Ebook.',

  ACTIVE_COURSE_LABEL: 'Kích hoạt khóa học',
  ACTIVE_COURSE_DESCRIPTION: 'Trang kích hoạt khóa học thông qua mã kích hoạt.',

  SEARCH_BAR_LABEL: 'Thanh tìm kiếm',
  SEARCH_BAR_DESCRIPTION: 'Thanh tìm kiếm cho phép người dùng tìm nội dung trên website. Không nên kích hoạt nếu trang web ít khóa học.',

  DEPARTMENT_LABEL: 'Cơ sở',
  DEPARTMENT_DESCRIPTION: 'Trang liệt kê danh sách cơ sở. Nội dung Cơ sở xem ở Nội dung khoá học',

  BLOG_LABEL: 'Blog',
  BLOG_DESCRIPTION: 'Trang danh sách các bài viết blog trên website',

  ABOUT_US_LABEL: 'Về chúng tôi',
  ABOUT_US_DESCRIPTION: 'Trang giới thiệu về trang web',

  POLICY_LABEL: 'Chính sách bảo mật',
  POLICY_DESCRIPTION: 'Trang quy định - chính sách bảo mật',

  RECRUITMENT_LABEL: 'Trở thành giảng viên',
  RECRUITMENT_DESCRIPTION: 'Trang tuyển dụng giảng viên',

  CART_LABEL: 'Giỏ hàng',
  CART_DESCRIPTION: 'Hiển thị icon giỏ hàng',
  
  ONLINE_COURSE_LABEL: 'Khóa học Online',
  ONLINE_COURSE_DESCRIPTION: 'Chương trình học - Danh sách khóa học Online',

  OFFLINE_COURSE_LABEL: 'Khóa học Offline',
  OFFLINE_COURSE_DESCRIPTION: 'Chương trình học - Danh sách khóa học Offline',

  EVENT_LABEL: 'Sự kiện',
  EVENT_DESCRIPTION: 'Chương trình học - Danh sách sự kiện',

  EBOOK_LABEL: 'Tài liệu điện tử',
  EBOOK_DESCRIPTION: 'Chương trình học - Danh sách tài liệu điện tử',

  ACTIVE_CODE_LABEL: 'Kích hoạt khóa học',
  ACTIVE_CODE_DESCRIPTION: 'Trang kích hoạt khóa học thông qua mã kích hoạt',

  PHONE_LABEL: 'Số điện thoại',
  PHONE_DESCRIPTION: 'Số điện thoại - Thông tin liên hệ',

  EMAIL_LABEL: 'Email',
  EMAIL_DESCRIPTION: 'Email - Thông tin liên hệ',

  ADDRESS_LABEL: 'Địa chỉ',
  ADDRESS_DESCRIPTION: 'Địa chỉ - Thông tin liên hệ',

  SOCIAL_NETWORK_LABEL: 'Liên kết mạng xã hội',
  SOCIAL_NETWORK_DESCRIPTION: 'Cài đặt các đường dẫn tại Cài đặt chung / Cài đặt nâng cao / Thương hiệu',

}
const TOPIC_SETTING_DETAIL = {
  TITLE: "Tuỳ chỉnh danh mục",
  SUBTITLE: "Tuỳ chỉnh danh mục hiển thị trong phần danh mục trên menu đầu trang ",
  LABEL_DISPLAY_TOPIC: "Hiển thị danh mục tuỳ chỉnh thay cho danh mục mặc định",
  TITLE_TOPIC_CONFIG: "Cấu hình danh mục",
  BUTTON_ADD: "Thêm mới",
  BUTTON_SAVE: "Lưu",

  POPUP_EDIT: {
    LABEL_USE: "Sử dụng danh mục thể loại",
    LABEL_TOPIC: "Danh mục",
    PLACEHOLDER_SELECT: "Chọn danh mục",
    LABEL_NAME: "Tên mục*",
    PLACEHOLDER_INPUT_NAME: "Nhập tên mục",
    LABEL_LINK: "Đường dẫn",
    PLACEHOLDER_INPUT_LINK_ATTACHED: "Nhập đường dẫn đính với tên mục",
    TITLE_ADD: "Thêm mục",
  },
  POPUP_CONFIRM_DELETE_TITLE: 'Xác nhận xoá danh mục',
  POPUP_CONFIRM_DELETE_MESSAGE: 'Bạn có chắc muốn xoá danh mục này không?',

  POPUP_CONFIRM_SAVE_TITLE: 'Lưu lại thay đổi',
  POPUP_CONFIRM_SAVE_MESSAGE: 'Bạn có chắc muốn lưu lại thay đổi không?',

}
export default {
  OPTIONS,
  TOPIC_SETTING_DETAIL,
  TITLE: 'Cài đặt menu',

  TAB_HEADER: 'Menu đầu (Header)',
  TITLE_HEADER_CONFIG: 'Cấu hình menu',
  TAB_FOOTER: 'Menu cuối (Footer)',
  TAB_TOPIC: 'Danh mục',
  LEFT_MENU_APP: 'Menu trái',
  MENU_LEFT_DESCRIPTION: 'Chọn trường bạn muốn hiển thị trên menu bên trái.',
  HIDE_BUTTON_ACTIVE_COURSE: 'Hiển thị nút kích hoạt khoá học',


  SECTION_HEADER_TITLE: 'Menu đầu trang',
  SECTION_HEADER_DESCRIPTION: 'Chọn trường bạn muốn hiển thị trên thanh menu nằm đầu trang. Để hiển thị đẹp, không nên kích hoạt nhiều hơn 3 trường.',
  SECTION_HEADER_OPTION_DISPLAY_MARKET_HEADER: 'Hiển thị menu đầu trang theo định dạng thương mại điện tử',

  SECTION_FOOTER_TITLE: 'Menu cuối trang',
  SECTION_FOOTER_DESCRIPTION: 'Chọn trường bạn muốn hiển thị trên thanh menu nằm dưới đáy trang.',

  MSG_UPDATING_DATA: "Đang cập nhật dữ liệu...",
  MSG_UPDATE_SUCESS: "Cập nhật thành công!",
  MSG_UPDATE_FAIL: "Cập nhật thất bại",
  MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin !',

  POPUP_EDIT_MENU_TITLE: 'Chỉnh sửa đường dẫn',
  POPUP_ADD_MENU_TITLE: 'Thêm đường dẫn',
  POPUP_DELETE_MENU_TITLE: 'Xác nhận xoá menu',
  POPUP_DELETE_MENU_CONTENT: 'Bạn có chắc chắn muốn xoá menu này không?',
  POPUP_EDIT_MENU_LABLE_NAME: 'Tên đường dẫn *',
  POPUP_EDIT_MENU_PLACEHOLDER_NAME: 'Nhập tên menu',
  POPUP_EDIT_MENU_LINK: 'Đường dẫn',
  POPUP_EDIT_MENU_PLACEHOLDER_LINK: 'Nhập tên đường đẫn',

  TAB_MENU_DEFAULT: 'Đường dẫn đề xuất',
  TAB_MENU_CUSTOM: "Đường dẫn tuỳ chỉnh",
}