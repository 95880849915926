<template>
	<v-dialog v-model="dialog" max-width="350">
		<v-card>
			<v-card-text>
				<v-form class="d-inline-flex align-center" ref="form">
					<BaseInput
						class="mt-7 mr-4"
						style="width: 210px"
						:label="$vuetify.lang.t('$vuetify.COURSE.COURSE_UNIT_RESOURCE_SELECTION.LABEL_VIDEO_LINK')"
						:placeholder="$vuetify.lang.t('$vuetify.COURSE.COURSE_UNIT_RESOURCE_SELECTION.PLACEHOLDER_INPUT_LINK')"
						:rules="[$rules.required, $rules.url]"
						v-model="link"
					/>
					<BaseButton :text="$vuetify.lang.t('$vuetify.COURSE.BUTTON_INPUT')" @click="onselect" />
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	data() {
		return {
			dialog: false,
			link: ''
		}
	},
	methods: {
		openDialog() {
			this.dialog = true
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
		},
		onselect() {
			if (!this.$refs['form'].validate()) return
			this.dialog = false
			this.$emit('onSelect', this.link)
		}
	}
}
</script>
