import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const UnitRepository = RepositoryFactory.get('unit')

const state = {
  units: {},
  count: 0,
  isExternalLink: false,
  assignedUnits: [],
  totalScore: 0,
  selectedUnit: {},
  selectedUnitReport: {},
  previosUnit: {},
  loading: false,
  tabScreenCourse: 0
}

const actions = {
  async createUnit({ commit }, data) {
    const unit = await UnitRepository.create(data)
    commit('addUnit', unit.data)
    return unit.data
  },
  async fetchUnits({ commit }, params = {}) {
    const res = await UnitRepository.fetch({
      ...params,
    })
    commit('setUnits', res.data || [])
    return res.data
  },
  async fetchUnitsIndependent(_, params = {}) {
    const res = await UnitRepository.fetch({
      ...params,
    })
    return res.data
  },
  async countUnits({ commit }, params = {}) {
    const res = await UnitRepository.count(params)
    commit('setCount', res.data || 0)
    return res.data 
  },
  async updateUnit({ commit }, { id, ...unit }) {
    const res = await UnitRepository.update(id, unit)
    commit('setUnit', res.data)
    return res.data
  },
  async updateVideoAfterUpload(_, { id, video, ...unitVideo }) {
    let data = { ...unitVideo, resources: [video], video: video }
    await UnitRepository.update(id, { ...data })
  },
  async sendNoti(_, id) {
    const res = await UnitRepository.sendNoti(id)
    return res.data
  },
  async updateUnitOrder({ commit }, units) {
    for (let i = 0; i < units.length; i++) {
      units[i] = {
        ...units[i],
        index: i,
      }
      await UnitRepository.update(units[i].id, { index: i })
    }
    commit('changeUnitsOrder', units)
  },

  async removeUnit({ commit }, item) {
    await UnitRepository.remove(item.id, {
      vendorId: item.vendorId,
    })
    commit('setSelectedUnit', {})
    return commit('removeUnit', item.id)
  },
  async addUnit({ commit }, unit) {
    return commit('setUnit', unit)
  },
  async assignTeacherToHomeworkUnit(
    { commit },
    { unitId, courseTitle = '', unitTitle = '', assignee }
  ) {
    const res = await UnitRepository.assignTeacherToHomeworkUnit(unitId, {
      assignee,
    })
    commit('addAssignedUnit', {
      ...(res.data || {}),
      courseTitle,
      unitTitle,
    })
    return res.data || {}
  },
  async removeAssignTeacherFromHomeworkUnit({ commit }, { unitId, assigneeId }) {
    const res = await UnitRepository.removeAssignTeacherFromHomeworkUnit(
      unitId,
      { assigneeId }
    )
    commit('removeAssignedUnit', unitId)
    return res.data || {}
  },
  async fetchAssignedHomework(_, params) {
    const res = await UnitRepository.fetchAssignedHomeworkForTeacher(params)
    return res.data || []
  },
  async fetchUnitIndependent(_, id) {
    const res = await UnitRepository.fetchOne(id)
    return res.data || {}
  },
  async listAssignedHomework({ commit }, params) {
    const res = await UnitRepository.fetchAssignedHomeworkForAdmin(params)
    commit('setAssignedUnits', res.data || [])
    return res.data || []
  },
  async updateTotalScore({ state }, totalScore) {
    state.totalScore = totalScore
  },
  setPreviosUnit({ commit }, unit) {
    commit('setPreviosUnit', unit)
  },
  updateVideoUnit({ commit }, { id, video }) {
    commit('updateVideoUnit', { id, video })
  },
  async setSelectedUnit({ commit }, unit) {
    if(unit) {
      commit('setSelectedUnit', unit)
      commit('section/setCurrentSectionId', unit.section, { root: true })
    }
  },
  async setSelectedUnitReport ({commit}, unit) {
    if(unit) {
      commit('setSelectedUnitReport', unit)
      commit('section/setCurrentSectionId', unit.section, { root: true })
    }
  },
  setTabScreenCourse({commit}, tab) {
    commit('setTabScreenCourse', tab)
  }
}

const mutations = {
  setUnit(state, unit) {
    return (state.units = {
      ...state.units,
      [unit.id]: unit,
    })
  },
  setCount(state, count) {
    return (state.count = count)
  },
  setLoading(state, loading) {
    return (state.loading = loading)
  },
  addUnit(state, unit) {
    state.count = state.count + 1
    state.units = {
      [unit.id]: unit,
      ...state.units,
    }
  },
  updateUnits(state, units) {
    state.units = {
      ...units.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
      ...state.units,
    }
  },
  setSelectedUnit(state, unit) {
    return (state.selectedUnit = unit)
  },
  setSelectedUnitType(state, type) {
    return (state.selectedUnit = {...state.selectedUnit , type})
  },
  setSelectedUnitReport(state, unit) {
    return (state.selectedUnitReport = unit)
  },
  setTabScreenCourse(state, tab) {
    return state.tabScreenCourse = tab
  },
  changeUnitsOrder(state, units) {
    state.units = units
  },
  appendUnits(state, units) {
    return (state.units = {
      ...units.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
      ...state.units,
    })
  },
  setUnits(state, units) {
    return (state.units = units.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    ))
  },
  setIsExternalLink(state, isExternalLink) {
    return (state.isExternalLink = isExternalLink)
  },
  removeUnit(state, id) {
    delete state.units[id]
    state.units = { ...state.units }
  },
  setAssignedUnits(state, units) {
    state.assignedUnits = units
  },
  addAssignedUnit(state, unit) {
    state.count += 1
    state.assignedUnits.push(unit)
  },
  removeAssignedUnit(state, unitId) {
    state.assignedUnits = state.assignedUnits.filter((x) => x.id !== unitId)
  },
  setPreviosUnit(state, unit) {
    state.previosUnit = unit
  },
  updateVideoUnit(state, { id, video }) {
    state.units[id].video = video
  },
}

const getters = {
  units: (state) => {
    return Object.values(state.units).sort((a, b) => {
      const aIndex = a.index ? a.index : 0
      const bIndex = b.index ? b.index : 0
      return aIndex - bIndex
    })
  },
  getUnits: (state) => (sectionId) => {
    return Object.values(state.units)
      .filter((a) => a.section === sectionId)
      .sort((a, b) => {
        const aIndex = a.index ? a.index : 0
        const bIndex = b.index ? b.index : 0
        return aIndex - bIndex
      })
  },
  objectUnits: (state) => {
    return state.units
  },
  getUnitById: (state) => (unitId) => {
    return Object.values(state.units).find((unit) => unit.id === unitId)
  },
  count: (state) => {
    return state.count
  },
  isExternalLink: (state) => {
    return state.isExternalLink
  },
  getAssignedUnits: (state) => {
    return state.assignedUnits
  },
  getTotalScore: (state) => {
    return state.totalScore
  },
  getPreviosUnit: (state) => state.previosUnit,
  getLoadingUnit: (state) => state.loading,
  selectedUnit: (state) => {
    return state.selectedUnit
  },
  selectedUnitReport: (state) => {
    return state.selectedUnitReport
  },
  getCurrentUnitId: (state) => {
    return state.tabScreenCourse === 1 ? state.selectedUnit.id : state.selectedUnitReport.id
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
