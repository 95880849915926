import Repository from '@/api/core'
import vendor from '@/store/modules/vendor'
import queryString from 'query-string'
const baseURL = process.env.VUE_APP_API_PERMISSION + '/user-book'

const fetch = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + `?${query}`).fetch()
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

export default {
	fetch,
	fetchOne
}
