import Repository from '@/api/core'
import vendor from '@/store/modules/vendor'
import queryString from 'query-string'
const baseURL = process.env.VUE_APP_API_EXAM + '/homework'

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const create = params => {
	return Repository(baseURL).create(params)
}

const fetch = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + `?${query}`).fetch()
}

const count = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + `/count?${query}`).fetch()
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const remove = id => {
	return Repository(baseURL).delete(id)
}

const mark = (id, params) => {
	return Repository(baseURL + '/mark').update(id, params)
}

export default {
	remove,
	update,
	count,
	fetch,
	fetchOne,
	create,
	mark,
}
