<template>
	<v-autocomplete
		item-text="title"
		auto-select-first
		dense
		outlined
		flat
		:hide-details="hideDetails"
		:label="label"
		:items="resourcesList.filter(v=>v)"
		:search-input.sync="search"
		v-model="data"
		return-object
	></v-autocomplete>
</template>

<script>
import {RepositoryFactory} from '@/api/factory/repositoryFactory'
import auth from '@/store/modules/auth'
import debounce from 'lodash-es/debounce'
const Resource = RepositoryFactory.get('resource')
export default {
	data: () => ({
		resources: [],
		search: '',
		data: undefined,
		loading: false,
	}),
	props: {
		filter: Object,
		value: Object,
		hideDetails: Boolean,
		label: {
			type: String,
			default: ''
		}
	},
	mounted() {
		this.data = this.value
	},
	computed: {
		tags() {
			return this.search ? this.$utils.clearUnicode(this.search) : ''
		},
		resourcesList() {
			return [...this.resources, this.value]
		},
	},
	methods: {
		async fetchResources() {
			this.loading = true
			const isTeacher = auth.state.auth.roles.includes('teacher')
			const payload = {
				createdBy: auth.state.auth.id,
				tags_contain: this.tags,
				_limit: 10,
				...this.filter,
			}
			if (!isTeacher) delete payload.createdBy
			const result = await Resource.fetch(payload)
			this.resources = result.data
			this.loading = false
		},
		searchResource(searchString) {
			this.search = searchString
			this.debounce()
		},
		debounce: debounce(function () {
			this.fetchResources()
		}, 1000),
	},
	watch: {
		search(value) {
			this.searchResource(value)
		},
		value() {
			this.data = this.value
		},
		data() {
			this.$emit('input', this.data)
		},
	},
}
</script>
