import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const UserMembership = RepositoryFactory.get('userMembership')

const state = {
  userMembership: [],
}

const actions = {
  async fetchUserMembership({commit}, userId) {
    let res = await UserMembership.fetchMembershipByStudentId(userId)
    commit('setUserMembership', res.data || [])
    return res.data || []
  },
}

const mutations = {
  setUserMembership(state, memberships) {
    state.userMembership = [...memberships]
  },
}

const getters = {
  getUserMembership: (state) => {
    return state.userMembership
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
