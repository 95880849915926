const COURSE_LIST = {
	TITLE_ONLINE_COURSE: 'オンラインコース',
	TITLE_OFFLINE_COURSE: 'オフラインコース',
	BUTTON_CREATE: 'コースの作成',
	LABEL_FILTER_BY_NAME: 'コース名',
	PLACEHOLDER_FILTER_BY_NAME: '検索',
	//TABLE HEADER
	TH_NAME: 'コース名',
	TH_UPDATE_DATE: '更新',
	TH_STATUS: '状態',
	TH_ACTION: 'アクション',
	TEXT_PUBLISED: '公開済',
	TEXT_NOT_PUBLISH: '未公開',
	TOOLTIP_EDIT_COURSE: 'コース編集',
	//EMPTY DATA
	TITLE_EMPTY_DATA: 'コースなし',
	SUBTITLE_EMPTY_DATA: '受講中のコースがありません。コースを作成しましょう！',
	MSG_GETTING_DATA: 'コースのデータを取得しています…',
	//CARD COURSE CONTENT
	LABEL_TEACHER_TO_UNDERTAKE: '担当講師',
	LABEL_NOT_TEACHER_TO_UNDERTAKE: '担当講師がいません。',
	LABEL_PUBLISHED: '公開済',
	LABEL_UNPUBLISH: '未公開',
	LABEL_NOT_FOUND_RESULT: '一致する検索結果がありません。',
	LABEL_FREE: '無料',
	LABEL_NOT_SELECT_TOPIC: 'トピック未選択',
	LABEL_TEXT_CONTACT: '連絡先',
	LABEL_NOT_PRICE: '価格なし',
	LABEL_UNIT_PRICE: 'ドン',
	LABEL_UNIT_COURSE: 'コース',
	TOOLTIP_GIVE_COURSE_TO_HIGHLIGHTS_LIST:
		'本コースを人気のコース一覧に追加します。',
	TOOLTIP_CANCEL_COURSE_GET_OUT_HIGHLIGHTS_LIST:
		'本講座をおすすめ講座一覧に追加します。',
	BUTTON_VIEW: 'もっと見る'
}
const FORM_CREATE_NEW_COURSE = {
	TITLE: 'コースの新規作成',
	LABEL_INPUT_NAME: 'コース名',
	PLACEHOLDER_INPUT_NAME: 'コース名を入力してください',
	HINT_INFO: 'コースの情報をいつでも編集できます。',
	MSG_CREATE_SUCCESS: 'コースを作成できました!',
	MSG_CREATE_FAIL: 'コースを作成できませんでした。'
}
const COURSE_OVERVIEW = {
	TAB_INFO: '情報',
	TAB_SCHEDULE_AND_CLASS: 'クラス情報・スケジュール',
	TAB_LEARNING_CONTENT: '学習内容',
	//TAB
	TAB_BASIC_INFO: '基本情報',
	TAB_ADVANCE_INFO: '詳細情報',
	TAB_INTERACTION: 'インタラクション設定',
	TAB_THUMBNAIL: 'サムネイル',
	TAB_PRICING_AND_OWNERSHIP: '価格と所有権',
	TAB_PUBLISHING: '公開',
	TAB_DELETION: 'コースの削除',
	BASIC_INFO: {
		ON_CARD: {
			TITLE: '基本情報',
			SUBTITLE: 'コースの名前、カテゴリ、教師の割り当てを設定します。',
			SUBTITLE_OFFLINE_COURSE: '名前、カテゴリを設定し、教師、コースの合計期間を割り当てます',
			LABEL_NAME: 'コース名（最大120文字）',
			PLACEHOLDER_INPUT_NAME: 'コース名を入力してください',
			LABEL_CATEGORY: 'カテゴリ',
			PLACEHOLDER_SELECT_TOPIC: 'トピックを選択してください',
			BUTTON_CREATE_CATEGORY: 'カテゴリの追加登録',
			LABEL_ADD_TEACHER: '指定講師',
			PLACEHOLDER_SELECT_TEACHER: '講師を選択してください',
			BUTTON_CREATE_TEACHER: '講師の追加登録',
			LABEL_EXPIRY_COURSE: 'コース期間',
			PLACEHOLDER_INPUT_EXPIRY_COURSE: 'コース期間を入力してください',
			OPTION_SHOW_TEACHER_FOR_STUDENT: '割り当てられた教師を学生に公開する',
		},
		DURATION_SETTING: {
			TITLE: 'コース総時間',
			SUBTITLE: 'コース期間の情報を提供する',
			OPTION_SELF_INPUT: '自分で入力',
			OPTION_SYSTEM_AUTO_CACULATE: '講義内容をもとに自動計算自体が可能',
			LABEL_TIME: '合計期間',
			PLACEHOLDER_TIME: '期間を入力してください',
			MSG_GETTING_DATA_TIME: '... 期間のデータを取得しています'
		},
		DESCRIPTION: {
			TITLE: '概要説明',
			SUBTITLE: 'リストページにコースの簡単な紹介の説明を入力します',
			LABEL_INPUT_SHORT_DESCRIPTION: '簡単な説明（提案された長さ：200文字）',
			PLACEHOLDER_INPUT_DESCRIPTION: '説明文を入力してください'
		}
	},
	ADVANCE_INFO: {
		DESCRIPTION_DETAIL: {
			TITLE: '詳細説明',
			SUBTITLE: '受講生が非公開の紹介ページでコースを参照してよりよく理解できるように、説明を提供します。'
		},
		COURSE_FAKE_RATING: {
			TITLE: 'デフォルト評価',
			SUBTITLE: 'コース評価情報の設定。',
			CHECKBOX_TURN_ON_SETTING: '設定をオンにする',
			TH_POINT: '評価点',
			TH_QUANTITY: '評価数',
			TEXT_VERY_BAD: '最低',
			TEXT_NEED_TO_CHANGE: '要改善',
			TEXT_NOT_GOOD: 'やや不十分',
			TEXT_GOOD: '良好',
			TEXT_VERY_GOOD: '非常に優秀',
			TH_TOTAL_REVIEW: '評価総数',
			TH_AVERAGE_OF_REVIEW: '評価平均点'
		},
		COURSE_FAKE_STUDENT_NUMBER: {
			TITLE: 'デフォルトの学生数',
			SUBTITLE: 'コースを受講した学生の数を設定します。',
			LABEL_FIRST_NUMBER_STUDENTS: '当初のユーザー数',
			PLACEHOLDER_STUDENT_TO_BUY: 'コースを購入したユーザー数を入力してください',
			LABEL_STUDENTS_INCREASED_BY_DAY: '日別増加ユーザー数',
			PLACEHOLDER_STUDENT_BUY_COURSE:
				'コースを購入したユーザー数を入力してください',
			LABEL_AVAILABLE_DATE: '開始日',
			PLACEHOLDER_ANWSER: '回答を記入してください'
		}
	},
	//------------COURSE_INTERACTION-----------
	TITLE_INTERACTION: '評価',
	SUBTITLE_INTERACTION: 'コース評価ルールの構成',
	LABEL_SHOW_COURSE_SETTING: 'このコースを購入したユーザーへの表示',
	CHECKBOX_CAN_ENABLE_RATE: 'コースを所有すると、学生はレビューを書くことができます',
	CHECKBOX_MUST_APPROVE_RATE: '承認済みのレビューのみを表示',

	DISPLAY_IMAGE: {
		ON_CARD: {
			TITLE: 'サムネイル',
			SUBTITLE: 'カードのサムネイルを構成します。 推奨サイズ: ',
			TEXT_RATIO: 'アスペクト比'
		},
		BACKGROUND: {
			TITLE: 'コースのカバー画像',
			SUBTITLE:
			'個々の紹介ページの上に画像を設定します。 推奨サイズ: {0} ',
			TEXT_IMAGE_UNIT: '画像'
		}
	},

	//DISPLAY_VIDEO_ON_CARD
	TITLE_VIDEO: '紹介ビデオ',
	SUBTITLE_VIDEO:
	'ユーザーがカードをクリックすると表示される、コースの短い紹介ビデオを構成します。',
	SUBTITLE_VIDEO_HAS_CAPACITY_LIMIT:
		'学習経路の概要紹介動画を設定します。タブを選択すると、学習経路のプロフィール画像の代わりに、動画が再生されます。（動画アップロードの容量制限：1.5GB）',
	CHECKBOX_VIDEO_INTRO: '設定を有効にする',
	LABEL_SELECT_VIDEO_FROM_LIBRARY: '動画ライブラリー',
	LIMIT_CAPACITY: 'アップロードの容量制限：',
	LABEL_STATUS_VIDEO: '動画の状態',
	TITLE_VIDEO_THUMBNAIL: '動画のサムネイル画像',
	SUBTITLE_VIDEO_THUMBNAIL:
		'推奨サイズ：{0} (アスペクト比 {1}). 動画のサムネイル画像の読込速度を最適化するには、画像の容量が小さければ小さいほど良い（制限値：{2}）。',
	MSG_ADD_VIDEO_THUMBNAIL_SUCCESS: '動画のサムネイル画像を追加できました!',

	TEXT_VIDEO_PROCESSED: '動画を処理中です。',
	TEXT_READY: '使用可能',
	TITLE_DRAG_DROP: 'ここにドラッグ＆ドロップ',
	BUTOTN_SELECT_FILE_IN_MACHINE: 'ローカルファイルを選択',
	MSG_WAITING_VIDEO_PROCESSED:
		'ただいま動画を処理中です。しばらくお待ちください。',
	MSG_SUPPORT_VIDEO_PROCESSED:
		'問題が長期間続く場合は、技術チームにサポートをご依頼ください。',
	MSG_FILE_NOT_NAME: 'ファイルに名前がありません',
	//------------COURSE_PRICING-----------
	TITLE_PRICING: 'コースの価格',
	SUBTITLE_PRICING:
		'このコースの表示価格を設定します。 注: 管理者が学生に許可を与えた場合、学生は購入しなくてもコースに参加できます。。',
	LABEL_PRICE_CONTACT: 'お問い合わせオプション',
	RADIO_PRICE_ALL_COURSE: '一括払いの設定',
	TYPE_OF_TIME: {
		TITLE_MONTH: '月',
		TITLE_DATE: '日',
		TITLE_WEEK: '週',
		TITLE_SLOT: 'レッスン'
	},

	RADIO_PRICE_COURSE_HINT: '学生は、参加するためにコースの全額を支払う必要があります。',
	LABEL_PRICE: '価格',
	LABEL_PRICE_BY_TIME: '特定の時間のオプション支払い',
	LABEL_SELECT_ABOUT_TIME: '特定の時間を選択',
	ABOUT_TIME: '期間',
	LABEL_BILL_PRICE: '価格',
	// LABEL_PRICE_CONTACT: '連絡先',
	MSG_REMOVE_CLASS_HINT: '削除された講義は回復できません。',

	CHECKBOX_COURSE_FREE: '無料',
	HINT_COURSE_FREE: '無料コース（購入不要）',
	CHECKBOX_PAY_ONCE_FOR_COURSE: '一回支払い',
	HINT_PAY_ONCE_FOR_COURSE: '一回支払いでコースを永遠に維持',
	LABEL_PRICE_OFFICIAL: '公式価格',
	LABEL_CURRENCY_UNIT: 'ドン',
	PLACEHOLDER_PRICE_OFFICIAL:
		'支払いゲートウェイは 10,000 VND を超える取引が必要です!',
	LABEL_PRICE_AFTER_DISCOUNT: '割引前の価格',
	HINT_PRICE_AFTER_DISCOUNT: '非表示の場合、何も入力しません。',
	CHECKBOX_DISALLOWANCE_PAY: 'コース購入や無料学習の禁止',
	HINT_DISALLOWANCE_PAY:
		'このコースは、コースに割り当てられたユーザーのグループのみが受講できます。',
	MSG_PRICE_AT_LEAST: '料金を最低{0}から設定してください。',

	//------------COURSE_PUBLISHING-----------
	PUBLISHING: {
		TITLE_STOP_PUBLISH: '本コースの公開停止',
		SUBTITLE_STOP_PUBLISH:
			'コースの公開を停止して、一覧ページでコースを非表示にします。',
		TITLE_PUBLISH_COURSE: 'コースを公開',
		SUBTITLE_PUBLISH:
			'このコースを公開し、一覧ページに表示します。 （展示限定のコースは除く）',
		BUTTON_PUBLISH: '公開',
		BUTTON_STOP_PUBLISH: '公開停止',
		POPUP_CONFIRM_STOP_TITLE: '本コースの公開を停止します。宜しいですか？',
		POPUP_CONFIRM_STOP_MESSAGE:
			'コースの公開を停止して、一覧ページでコースを非表示にします。 備考：コースを購入したユーザーはコースを閲覧できています。'
	},
	DELETION: {
		TITLE: 'コースの削除',
		SUBTITLE: 'コースを削除します。 注: 削除されたコースは復元できません',
		BUTTON: 'コースの削除',
		POPUP_CONFIRM_TITLE: 'このコースを削除します。宜しいですか？',
		POPUP_CONFIRM_MESSAGE:
			'削除されたデータは回復できません。システムは自動的にこのコースの第章やレッスン全てを削除します。このコースを削除します。宜しいですか？'
	}
}
const OFFLINE_COURSE_DETAIL = {
	LINK_RETURN_OFFLINE_COURSE_LIST: 'オフラインコース一覧',
	CLASS: {
		TITLE: 'クラス',
		LABEL_NEW: '新規クラス',
		BUTTON_ADD: 'クラスの新規追加',
		MSG_CREATING: '新規クラスを作成中です。',
		MSG_CREATE_SUCCESS: 'コースを作成できました。',
		BASIC_INFO: {
			TITLE: '基本情報',
			LABEL_NAME: 'クラス名',
			PLACEHOLDER_INPUT_NAME: 'クラス名を入力してください',
			LABEL_DEPARTMENT: '学舎',
			PLACEHOLDER_INPUT_DEPARTMENT: '学舎の名称を入力してください'
		},
		SCHEDULE: {
			TITLE: '学習スケジュール',
			LABEL_OPENING_CEREMONY: '開講日',
			RADIO_WEEKLY: '毎週の繰り返しスケジュール',
			RADIO_FREE: 'フリースケジュール',
			MSG_CHECK_AGAIN:
				'フリースケジュールと毎週の繰り返しスケジュールをご確認ください。',
			BUTTON_ADD: '学習プログラムの追加',
			PLACEHOLDER_DAY_IN_WEEK: '曜日を入力してください'
		}
	},
	DELETION: {
		TITLE: 'クラスの削除',
		SUBTITLE: '削除されたクラスは回復できません。',
		BUTTON: 'クラスの削除',
		POPUP_CONFIRM_TITLE: 'このクラスを削除します。宜しいですか？',
		POPUP_CONFIRM_MESSAGE: '削除されたクラスは回復できません。',

		MSG_LOADING: 'クラスを削除中です…',
		MSG_SUCCESS: 'クラスを削除できました。'
	},
	SYLLABUS: {
		LIST: {
			TITLE_LESSON_UNIT: 'レッスン',
			BUTTON_ADD_LESSON: 'レッスンの追加'
		},
		DETAIL: {
			TITLE_LESSON_UNIT: 'レッスン',
			INFO: {
				TITLE: 'レッスンの情報',
				LABEL_LESSON_HEADING: 'レッスンのタイトル',
				PLACEHOLDER_LESSON_HEADING: '例：レッスン1：基本的な導入段落',
				LABEL_DESCRIPTION_LESSON: 'レッスンの説明',
				PLACEHOLDER_DESCRIPTION_LESSON: '例：大概ユーザーへの基本知識紹介…'
			},
			DELETION: {
				TITLE: 'レッスンの削除',
				SUBTITLE: '削除されたレッスンは回復できません。',
				BUTTON: 'レッスンの削除',
				POPDUP_CONFIRM_TITLE: 'このレッスンを削除します。よろしいでしょうか？',
				POPDUP_CONFIRM_MESSAGE: '削除されたレッスンは回復できません。'
			}
		}
	},
	FEE: {
		PLACEHOLDER_FILTER_STUDENT_NAME: 'ユーザーの名前で検索してください',
		TH_STUDENT_NAME: 'ユーザーの氏名',
		TH_DEADLINE: '期日',
		TH_DATE_PAYMENT: '支払日',
		TH_FEE: '学費（ドン）',
		TH_METHOD: '方式',
		TH_STATUS: 'ステータス'
	},
	MSG_UPDATING_LESSON: 'レッスンを更新しています...',
	MSG_UPDATE_LESSON_SUCCESS: 'レッスンが更新できました!',
	DAY_IN_WEEK: {
		TITLE_MONDAY: '月曜日',
		TITLE_TUESDAY: '火曜日',
		TITLE_WEDNESDAY: '水曜日',
		TITLE_THURSDAY: '木曜日',
		TITLE_FRIDAY: '金曜日',
		TITLE_SATURDAY: '土曜日',
		TITLE_SUNDAY: '日曜日'
	}
}
const ONLINE_COURSE_DETAIL = {
	TOOLTIP_RETURN_TO_ONLINE_COURSE_LIST: 'オンラインコース一覧',
	TAB_INFO: '情報',
	TAB_LEARNING_CONTENT: '学習内容',
	TAB_RESULT: '結果',
	TAB_PROGRESS: '進捗',
	TAB_DISCUSS: '議論'
}
//ONLINE COURSE INFOMATION
const ONLINE_COURSE_OVERVIEW = {
	//-----------COURSE_INTERACTION-------------
	INTERACTION: {
		VIDEO_FREE: {
			TITLE: '無料体験',
			SUBTITLE: '構成により、学生は一部のビデオ講義を無料でプレビューできます',
			BUTTON_LIST: '見てみる動画一覧',
			//COURSE_INTERACTION_TRIAL_POPUP
			TRIAL_POPUP: {
				TITLE: '見てみる動画一覧',
				LABEL_PICK_LESSON: '動画レッスンの選択',
				PLACEHOLER_SELECT_LESSON: '動画レッスンを選択してください',
				TEXT_NO_DATA: 'すべての動画レッスンを見てみる一覧に追加しました。',

				TITLE_EMPTY_DATA:
					'レッスンの動画がありません。レッスンの動画を制作してください。',
				BUTTON_CLOSE: '閉じる',
				TEXT_UNCLEAR: '未定'
			}
		}
	},
	//COURSE_VIDEO

	TH_VIDEO_LESSON: '動画レッスン',
	TH_CHAPTER_TITLE: '第章',
	TH_ACTION: 'アクション',
	//EMPTY DATA

	// STUDY_ROUTE
	TITLE_STUDY_ROUTE: '学習の進行状況',
	SUBTITLE_STUDY_ROUTE: '学習進捗ルールの構成',
	CHECKBOX_STUDY_ROUTE_SETTING:
		'次のレッスンに進む前に、生徒は現在のレッスンを完了する必要があります。',

	// SHOW_MY_COURSE_SETTING
	TITLE_SHOW_MY_COURE: '表示制限',
	SUBTITLE_SHOW_MY_COURE: '通常のページには表示されず、特定の条件下でのみ表示されるようにコースを設定します',
	CHECKBOX_SHOW_MY_COURE: 'すでにコースを所有している学生にのみ表示されます',

	//EVALUATE: '評価',
	TITLE_EVALUATE: '評価',
	SUBTITLE_EVALUATE: 'コース評価ルールの構成',
	CHECKBOX_EVALUATE_SETTING_ALLOW: 'コースを所有すると、学生はレビューを書くことができます',
	CHECKBOX_EVALUATE_SETTING_OBLIGATORY: '承認済みのレビューのみを表示。',

	// COMMENT_OFF_STUDENT
	TITLE_COMMENT: 'コメント',
	SUBTITLE_COMMENT: 'コースディスカッションのルールの設定。',

	CHECKBOX_STUDENT_COMMENT: '学生がコースの講義で議論し、知識を共有できるようにする。',
	CHECKBOX_BROWSER_STUDENT_COMMENT: '承認されたディスカッションとナレッジ共有のみを表示。',
	CHECKBOX_TURN_ON_COMMENT_FACEBOOK:
		'Facebook のコメントを独自のページに統合する。',
	LABEL_APPID_CONFIG: 'アプリ ID',
	PLACEHOLDER_APPID_CONFIG: '例：2221114335145',
    
	//NOTIFICATION_TO_STUDENT
	TITLE_NOTIFICATION: '表示通知',
	SUBTITLE_NOTIFICATION: 'システムがコースの更新通知を学生に送信できるようにします',
	CHECKBOX_SEND_NOTIFICATION_TO_STUDENT: 'システムがコースの更新通知を学生に送信できるようにします',

	// COURSE_PRICING
	TITLE_PRICING_AND_OWNERSHIP: '価格と所有権',
	SUBTITLE_PRICING_AND_OWNERSHIP:
		'コースと所有権の表示価格を設定します。 注: 学生は、ライセンスが付与されていれば、購入しなくてもコースを所有できます。',

	RADIO_LABEL_FREE_TITLE: '設定自由',
	RADIO_LABEL_FREE_SUBTITLE: '学生は無課金で所有できる',

	RADIO_LABEL_PAY_TITLE: '一括払いの設定',
	RADIO_LABEL_PAY_SUBTITLE: '学生はコースを所有するために支払う必要があります。',
	LABEL_PRICE_OFFICIAL: '最終価格',
	SUFFIX_CURREN_UNIT: 'VNĐ',
	LABEL_AFTER_DISCOUNT: '割引前の価格',
	HINT_AFTER_DISCOUNT: 'プロモーションがない場合は空白のままにします',

	RADIO_LABEL_NOT_ALLOW_BUY_COURSE_TITLE: '価格オプションをシール',
	RADIO_LABEL_NOT_ALLOW_BUY_COURSE_SUBTITLE:
		'アクティベーションコードを持っているか、許可されている場合、学生はコースでのみ支払うことはできません。',
	MSG_SMALLEST_PRICE: '料金を最低{0}から設定してください。',
	TEXT_CURRENT_UNIT: 'ベトナムドン'
}
//オンラインコースコンテンツ
const ONLINE_COURSE_CONTENT = {
	TOTAL_SCORE_AND_TIME_PART: {
		TITLE_EDIT_TIME: '時間修正表',
		TITLE_EDIT_MARK: '点数修正表',
		TITLE_TIME_AND_MARK: '時間と点数',
		LABEL_QUESTION_GROUP: '問題グループ',
		LABEL_TOTAL_SENTENCE: '問題あたりの総点数',
		LABEL_TOTAL_TIME: '総受験時間',
		TEXT_SENTENCE: '文',
		TEXT_MARK: '点数',
		TEXT_MINUTE_UNIT: '分',
		TEXT_SECOND_UNIT: '秒',
		TEXT_UNLIMITED: '無制限',
		RADIO_UNLIMITED: '無制限',
		LABEL_POINT: '点数',
		RADIO_LIMITED: '制限',
		RADIO_LIMITED_BY_PART: 'セクションごとの限られた時間',
		RADIO_LIMITED_BY_QUESTION: '問題ごとの限られた時間',
		RADIO_CACULATOR_BY_TEN_MARK: '10点のスケールで計算します。',
		RADIO_CACULATOR_BY_QUESTION_MARK: '問題の合計点数で計算します。'
	},
	DIALOG_SETTING_EXAM: {
		TITLE: '試験問題の構成',
		POPUP_CONFIRM_EDIT_EXAM_CONFIG_TITLE: '問題の構成変更',
		POPUP_CONFIRM_EDIT_EXAM_CONFIG_MESSAGE:
			'試験問題の構成を変更してしまうと、問題の配点と時間も変わる可能性があります。よろしいでしょうか？',
		BUTTON_OK: '同意する',
		PART_SETTING: {
			TITLE: '試験問題の構成',
			RADIO_NO_DISTRIBUTION: 'セクションに分割しません。',
			RADIO_DISTRIBUTED_IN_MULTIPLE_PARTS: '複数のセクションに分けられました。'
		}
	},
	BUTTON_ADD_NEW_TOPIC: '章の新規追加',
	TITLE_LESSON_NUMBER: 'レッスンNo.{0}',
	MSG_ADD_NEW_LESSON_SUCCESS: '新しいレッスンが正常に追加されました。',
	MSG_ADD_NEW_LESSON_FAIL: '新しいレッスンが追加できませんでした',
	LABEL_CHAPTER: '章',
	ENTITY_LESSON: 'レッスン',
	LABEL_CHILD_CHAPTER: '子チャプター{0}',

	MSG_ADD_CHAPTER_SUCCESS: '新しい章が正常に追加されました。',
	MSG_ADD_CHAPTER_FAIL: '新しい章が追加できませんでした。',
	MSG_ADD_CHILD_CHAPTER_SUCCESS: '新しい子チャプターを正常に追加してください！',
	MSG_ADD_CHILD_CHAPTER_FAIL: '新しい子チャプターの追加に失敗しました！',
	// COURSE_SECTION
	LABEL_COURSE_UNIT: 'コース',
	BUTTON_ADD_LECTURES: 'レッスン追加',
	BUTTON_ADD_CHILD_CHAPTER: '子の章を追加',
	BUTTON_CREATE_LEARNING_AVAILABLE: '既存のレッスンのコピー',
	TITLE_ADD_NEW: '新規追加',
	TITLE_CREATE_LECTURES: '既存のレッスンのコピー',
	// EDIT_CHAPTER_TITLE
	TITLE_CHAPTER_INFO: '章の情報',
	LABEL_TOPIC: '章のタイトル',
	PLACEHOLDER_TOPIC: '章のタイトルを入力してください',
	TITLE_SORT_SECTION: '章の並べ替え',
	MSG_SORTING_LESSON: 'レッスンを並べ替えています。',
	MSG_CHANGE_ORDER_SORT: '順序が正常に変更されました。',
	MSG_TEXT_DELETE: '削除',
	MSG_TEXT_DELETE_SUCCESS: '正常に削除されました。',
	POPUP_CONFIRM_DELETE_CHAPTER_TITLE: '章の削除',
	POPUP_CONFIRM_DELETE_CHAPTER_MESSAGE:
		'削除されたデータは回復できません。システムは自動的にこのコースの章やレッスン全てを削除します。よろしいでしょうか？',

	// EDIT_UNIT_BASIC_INFO
	TITLE_LESSON: 'レッスン情報',
	LABEL_LESSON_NAME: 'レッスン名',
	PLACEHOLDER_LESSON_NAME: 'レッスン名を入力してください',
	CHECKBOX_DESCRIPTION_LESSON: 'レッスンの説明',
	CHECKBOX_ATTACH_FILE: '添付ファイル',
	PLACEHOLDER_PICK_FILE_UPLOAD: 'アップロード用の画像を選択してください',

	// COURSE_EDIT_UNIT_DISCUSSION
	TITLE_DISCUSSION: '議論',
	CHECKBOX_DISCUSSION_IN_LESSON: 'ユーザーがレッスン中に議論できます。',
	CHECKBOX_ACCEPT_DISCUSSION: 'ユーザーの議論を承認するのは必須です。',

	//COURSE_EDIT_UNIT_TIME_CONDITION
	TITLE_EXPIRED_TIME: '開始・終了時間',
	CHECKBOX_TIME_CONDITION: '受験時間',
	PLACEHOLDER_TIME_CONDITION_BY_PART: 'セクション毎の受験時間',
	LABEL_TIME_CONDITION_BY_MINUTE: '受験時間（分単位）',
	PLACEHOLDER_TIME_CONDITION_BY_MINUTE: '時間を入力してください',
	LABEL_RANGE_TIME: 'アクセス可能な時間設定',
	LABEL_RANGE_TIME_FROM_DATE: '開始日',
	LABEL_RANGE_TIME_FROM_HOUR: '開始時間',
	LABEL_RANGE_TIME_TO_DATE: '終了日',
	LABEL_RANGE_TIME_TO_HOUR: '終了時間',
	LABEL_SETTING_RESULT_PUBLISH_TIME: '結果発表時間の約束',
	LABEL_PUBLISH_DATE: '発行日',
	LABEL_PUBLISH_HOURSE: '発行時',

	MSG_CHECK_TIME_CONDITION_AGAIN: 'アクセス時間が無効です。',
	MSG_CHECK_START_TIME_CONFITION_AGAIN:
		'開始時刻は終了時刻より前である必要があります。',

	// COURSE_EDIT_UNIT_RULES
	TITLE_RULES: 'ルール',
	CHECKBOX_SUBMIT_ONCE: 'ユーザーは一度だけテストを実施することができます。',
	CHECKBOX_RANKING_HIDDEN: 'テスト完了後にテスト結果を非表示にします。',
	CHECKBOX_RESULT_HIDDEN: 'テスト完了後にランキング結果を非表示にします。',
	CHECKBOX_ANSWER_HIDDEN: '結果を閲覧する時に回答を非表示にする',

	CHECKBOX_ENABLE_DETECT_FOCUSOUT: 'テスト中退室回数監視',
	WARNING: "警告",
	WARNING_MESSAGE_MIN_SCORE: "設定した最小スコア構成は、以前にテストを完了した学生には適用されません。 受講者が授業を修了した場合は、細心の注意を払ってください。",
	ERROR_MESSAGE_MIN_SCORE: "最小スコアがテストのスケールとスコアと一致していません。",
	WARNING_MESSAGE_MIN_SCORE_ONLY_ONE: "「これは最低スコアのテストであり、学生は1回しか受験できません。学生が失敗した場合、次のテストに進むためにこのテストを完了することはできません。続行しますか？」、",

	//kiểm tra lại
	// COURSE_EDIT_TEST_EXAM_RULES .. ..
	POPUP_CONFIRM_CONFIGURATION_QUESTIONS_TITLE: '問題の構成変更',
	POPUP_CONFIRM_CONFIGURATION_QUESTION_SUBTITLE: '構成の転送を続行しますか？',
	POPUP_CONFIRM_SEPARATE_QUESTION: '問題をセクションに分割します。',
	POPUP_CONFIRM_MERGE_QUESTION: '全ての問題を一覧にまとめます。',
	POPUP_CONFIRM_CONFIGURATION_QUESTIONS_MESSAGE:
		'アクションにより問題の順位を変更する可能性があります。',
	BUTTON_AGREE: '同意する',
	POPUP_CONFIRM_SETTING_TIME_TITLE: '時間設定変更',
	POPUP_CONFIRM_SETTING_TIME_MESSAGE:
		'時間設定をセクションごとに変更してもよろしいでしょうか？各セクションの時間を再設定する必要があります。',

	LABEL_QUESTION_NOT_SELECT: '質問は選択されていません',
	//COURSE_EDIT_VIDEO_RULES
	TITLE_VIDEO_SETTING: '動画レッスンの設定',
	LABEL_USING_VIDEO: '既存の動画パスの使用',

	INTERACT_COURSE_CARD_SETTING: {
		TITLE: 'コースへの直接アクセス',
		TOGGLE_DIRECT_ACCESS_TO_THE_COURSE:
			'ポップアップなしでコースに直接アクセスします。'
	},

	//BUTTON
	BUTTON_ADD_QUESTION: '問題追加',
	IMPORT_QUESTION: {
		TRIGGER_BUTTON: '質問をアップロード',
		TRIGGER_OPTION_TEMPLATE: 'サンプルファイルをダウンロードする',
		TRIGGER_OPTION_TUTORIAL: '手順を参照してください',
		TRIGGER_OPTION_UPLOAD: 'デバイスからファイルをアップロードする',
		MSG_UPLOAD_LOADING: '処理...',
		MSG_ERROR_NO_CONTENT: 'アップロードしたトピックのデータを読み取ることができません。与えられたテンプレートに従って質問を再確認してください。',
		MSG_ERROR_WRONG_CONTENT: 'システムは{0}の質問を除外できますが、そのうち{1}の質問は要件を満たしていません。次の文章をもう一度確認してください：{2}',
		MSG_ERROR_BUSY: '現在、システムには多くの処理要求があります。数分後にもう一度お試しください。',
	},
}
const COURSE_UNIT_RESOURCE_SELECTION = {
	LABEL_PDF_SLIDE: 'PDFスライド',
	LABEL_TEST: 'テスト（自己作成）',
	LABEL_EXERCISE: 'テスト（既存）',
	LABEL_MATRIX: '試験問題（問題マトリックス）',
	LABEL_HOMEWORK: '宿題',
	LABEL_EMBEDDED: '埋め込み',
	LABEL_VOICE: '声',


	// QUESTION_SETTING
	QUESTION_SETTING: {
		TITLE: '問題とテストのルール',
		CHECKBOX_SHOW_RANDOM: '問題はランダムな順序で表示されます。',
		CHECKBOX_SORT_AUTO:
			'問題がグループ内にある場合、そのグループは問題の代わりに順序を並べ替えられます。',
		CHECKBOX_SORT_GROUP_AUTO:
			'テストが複数のセクションに分割されている場合、問題/問題のグループはセクション内の順序のみが変更されます。',
		CHECKBOX_MIXING_ANWSER: '問題の回答をミックスします。',
		CHECKBOX_RATIO_MARK: '各問題の点数比率が異なります。',
		CHECKBOX_ON_CHANGE_ONE_BY_ONE:
			'ユーザーがそれぞれの問題又はそれぞれの問題グループでテストを受けます。',
		CHECKBOX_IS_DIABLE_PREVIOUS:
			'ユーザーが完成した問題を再びに見て回答してはいけません。',
		CHECKBOX_HAS_MIN_SCORE:
			'スコアが最小未満の場合、生徒は次のレッスンを表示できません',
		MIN_SCORE: "最小スコア"
	},

	POPUP_CONFIRM_DELETE_LESSON_TITLE: 'レッスン削除',
	POPUP_CONFIRM_DELETE_LESSON_MESSAGE:
		'削除されたデータは回復できません。システムは自動的にこのコースの内容を削除します。よろしいでしょうか？',
	MSG_DELETE_LESSON_SUCCESS: 'レッスンが正常に削除されました。',
	//COURSE_EDIT_UNIT_WITH_TYPE_VIDEO....
	SUBTITLE_LIMIT_CAPACITY_FOR_VIDEO: 'アップロードの容量制限が2.5GBです。',
	TITLE_VIDEO: '動画',

	//COURSE_EDIT_UNIT_WITH_TYPE_VIDEO_EXTERNAL
	SUBTITLE_VIDEO_LINK_HINT:
		"動画埋め込みリンクを使用してください。 (普通は 'https://...embed...'です。)",
	LABEL_VIDEO_LINK: '動画のパス',
	PLACEHOLDER_VIDEO_LINK: '動画のパスを入力してください',
	PLACEHOLDER_INPUT_LINK: 'パスを入力してください',

	// COURSE_EDIT_UNIT_WITH_TYPE_PDF
	TEST_PDF: {
		TITLE_ANSWER: '回答',
		QUESTION_FORM: {
			BUTTON_ADD_ONCE_QUESTION: '1問の追加',
			BUTTON_ADD_MANY_QUESTION: '複数問追加'
		}
	},
	// COURSE_EDIT_UNIT_WITH_TYPE_TEST
	LABEL_TOTAL_SCORE: '合計点',
	LABEL_TOTAL_TITME: '時間',
	BUTTON_ADD_PATH: 'セクション追加',
	LABEL_UNTITLED: 'タイトルがありません。',
	LABEL_TIME_FOR_PART: 'セクションの時間',
	BUTTON_EDIT_PART: 'セクション修正',
	BUTTON_DELETE_PART: 'セクション削除',
	//CREATE_QUESTION_TEST
	BUTTON_ADD_ONCE_QUESTION: '1問の追加',
	BUTTON_ADD_QUESTIONS: '複数問追加',
	//CREATE_QUESITON_DIALOG
	TITLE_ADD_MANY_QUESTION: '複数問追加',
	SWITCH_QUESTION_MANY_ANSWER: '複数回答問題',
	LABEL_QUESTION_TYPE: '問題タイプ',
	BUTTON_FILL_IN_BLANK: '空白の記入',
	LABEL_TIME_FOR_QUESTION: '各問の時間（秒単位）',
	PLACEHOLDER_TIME_FOR_QUESTION: '時間を入力してください',
	LABEL_MARK_FOR_QUESTION: '各問の点数（10点のスケール）',
	PLACEHOLDER_IMPORT_MARK: '点数を入力してください',
	// EDIT_PART_DIALOG
	TITLE_EDIT_PART_DIALOG: 'セクション修正',
	TITLE_ADD_NEW_PART_DIALOG: 'セクションの新規追加',
	LABEL_PART_TITLE: 'タイトル',
	PLACEHOLDER_IMPORT_PART: '問題のタイトルを入力してください',
	TITLE_SETTING_TIME_FOR_PART: 'セクションの時間設定',
	RADIO_IMPORT_TIME: '時間を自動的に入力します。',
	RADIO_SYTEM_CACULATE_TIME: 'システムが問題に基づいて自分自身で計算できます',
	LABEL_DURATION_AUTO_CACULATE: '受験時間（秒単位）',
	PLACEHOLDER_IMPORT_TEST_TIME: '受験時間を記入してください',
	POPUP_CONFIRM_DELETE_PART_QUESTION_TITLE: '問題セクションの削除',
	POPUP_CONFIRM_DELETE_PART_QUESTION_MESSAGE:
		'問題セクションを削除すると、セクション内のすべての問題が削除されます。このセクションを削除してもよろしいですか？',
	MSG_CHECK_MART_TEST_AGAIN: 'テストの合計点数は10点でなければなりません',
	MSG_CHECK_NUMBER_QUESTION: '質問の数は 0 より大きくする必要があります',
	// CREATE_QUESTION_TEST
	// LIST_QUESTION_TEST
	TITLE_QUESTION: '問題',
	TITLE_QUESTION_CHILD: '子問題',
	TITLE_QUESTION_CHILD_GROUP: '子問題・グループ',
	LABEL_QUESTION_GROUP: '問題グループ',
	LABEL_IMPORT_QUESTION_CONTENT: '回答記入',
	LABEL_IMPORT_DESCRIPTION_QUESTION_CONTENT: '説明の入力',
	BUTTON_SAVE_QUESTION_CONTENT: 'OK',

	POPUP_DELETE_QUESTION_TITLE: 'この問題を削除します。よろしいでしょうか？',
	POPUP_DELETE_QUESTION_MESSAGE:
		'削除されたデータは回復できません。システムは自動的にこのコースのレッスンや回答全てを削除します。この問題を削除してもよろしいでしょうか？',
	MSG_CHECK_ANSWER_FOR_QUESTION: '問題に回答がありません。',

	//COURSE_EDIT_UNIT_WITH_TYPE_EXERCISE
	SUBTITLE_IMPORT_REQUIREMENT_OF_TEACHER: '講師の要求記入',
	LABEL_ATTACH_FILE: '添付ファイル',
	PLACEHOLDER_PICK_UPLOAD_FILE: 'アップロードファイルを選択してください',
	LALBE_SECOND_UNIT: '秒',

	//COURSE_EDIT_UNIT_WITH_TYPE_HOMEWORK
	TITLE_ASSIGN_TEACHER: '採点する講師の割り当て',
	SUBTITLE_ASSIGN_TEACHER:
		'割り当てられた講師は、ユーザーからの提出物を閲覧し、採点操作を実施することしかできません。氏名、電子メールなどのユーザーの情報は、採点時の客観性を確保するために非表示になります。',
	LABEL_CHANGES_NOT_SAVE_LESSON:
		'変更は保存されていません。変更を完了するには、講義をご保存ください。',
	LABEL_TEACHER_GRADING_LESSONS: '講師の採点',
	PLACEHOLDER_TEACHER_GRADING_LESSONS: '採点する講師を選択してください',
	TOOLTIP_TEACHER_MARK_LAST: '講師の最終採点',
	TOOLTIP_MARK_LAST_TIME: '最終採点時間',
	TOOLTIP_COPY_SUBJECTS: '提出問題のコピー',
	BUTTON_ASSIGN: '割り当てる',

	MSG_DISTRIBUTION_TEACHER: '採点する講師が正常に割り当てられました。',
	MSG_PLEASE_TRY_DISTRIBUTION_TEACHER_AGAIN:
		'講師が割り当てられません。後でお試しください。',
	MSG_CHECK_PICK_TEACHER_MARK: '採点する講師をご選択ください。',
	MSG_REMOVE_ASSIGN_TEACHER_SUCCESS:
		'採点する講師の割り当てが正常に解除されました。',
	MSG_CHECK_REMOVE_ASSIGN_TEACHER_AGAIN:
		'講師の割り当てが解除できていません。後でお試しください。',
	MSG_PICK_TEACHER_WANT_REMOVE: '割り当てを解除したい講師をご選択ください。',

	// COURSE_EDIT_UNIT_WITH_TYPE_MATRIX
	// DOWNLOAD_EXAM_POPUP
	TITLE_CONFIG_DOWNLOAD: 'ダウンロードした問題の構成',
	LABEL_THREADS_TITLE: '問題のタイトル',
	PLACEHOLDER_IMPORT_EXAM_TITLE: '試験問題のタイトルを入力してください',
	TITLE_TEST_TIME: '受験時間（分）',
	DOWNLOAD_SUBJECTS: '問題のダウンロード',
	ENTITY_PAGE: 'ページ',
	TITLE_EXAM_CODE: '問題コード',
	MSG_DATA_LOADING: 'データを読み込んでいます。',
	PLACEHOLDER_IMPORT_ANWSER: '回答を記入してください',
	LABEL_MARK: '点数',
	LABEL_TIME: '時間',
	LABEL_ANWSER_UNIT: '回答',
	MSG_CHECK_CREATE_DATA: 'テストデータはまだ作成されていません。',
	MSG_CHECK_EXAM_CODE_AGAIN: 'もう一度問題コードをご確認ください。',
	MSG_DOWNLOAD_EXAM_SUCCESS: '正常にダウンロードできました。',
	MSG_CREATING_EXAM: '初期化中です',
	// TABLE_MATRIX
	TH_QUESTION_LEVEL: 'レベル（問題/点数)',
	TH_TOTLA_MARK: '合計',
	BUTTON_ADD_TOPIC: 'トピックの追加',
	LABEL_EXAM_CODE_QUANTITY: '問題コード数',
	PLACEHOLDER_EXAM_CODE_QUANTITY: '作成対象の試験問題の数を入力してください',
	TH_TOPIC_QUESTION: '問題のトピック',
	TH_PLUSS_POINT: 'プラス',
	// ADD_TOPIC_MATRIX_DIALOG: ""
	TITLE_MAXTRIX_CONTENT: '問題マトリックス内容追加',
	LABEL_TOPIC: 'トピック',
	PLACEHOLDER_TOPIC: 'トピックを選択してください',
	LABEL_LEVEL_QUESTION: 'レベル:',
	PLACEHOLDER_IMPORT_MARK_NUMBER: '点数を記入してください',
	LABEL_QUESTION_UNIT: '問題',
	LABEL_POINT_UNIT: '点数',
	BUTTON_CREATE_TEST_MATRIX: '作成',

	MSG_IMPORT_INFO_ONCE_LEVEL:
		'少なくとも1レベルの完全な情報を入力してください 。',

	MSG_CHECK_QUESTION_QUANTITY_SETTING_AGAIN:
		'設定されている問題数がシステム上の問題数より多いので、もう一度ご確認ください',
	MSG_CHECK_TOTAL_MARK_AGAIN: '合計テンスは10点でなければなりません。',
	MSG_CHECK_MARK_AGAIN: '{0}の点数、時間、または回答をご確認ください',
	MSG_CHECK_TOPIC_AGAIN: 'トピックが既に存在しています。',
	MATRIX_FUNC: {
		POPUP_CONFIRM_CREATE_NEW_TITLE: '新しい試験問題番号作成',
		POPUP_CONFIRM_CREATE_NEW_MESSAGE:
			'システムは古い試験問題番号を削除し、更新したマトリックスに従って新しい試験問題番号を再生成します。よろしいでしょうか？',
		MESSAGE_CREATE_NEW: '作成できる試験問題総数が1000題です。',

		POPUP_CANCEL_RESULT_OF_STUDENT_TITLE: 'ユーザーの古い結果キャセル',
		POPUP_CANCEL_RESULT_OF_STUDENT_MESSAGE:
			'ユーザーが古い問題を回答し、結果がでました。これらのユーザーの結果を削除してもよろしいでしょうか',
		MSG_REMOVE_RESULT_OF_STUDENT_SUCCESS: '結果が正常に削除されました。',
		ENTITY_TEST: 'テスト',
		ENTITY_EXAM: '試験問題',
		ENTITY_QUESTION: '問題',
		MSG_CREATE_EXAM_SUCCESS: 'テストが正常に作成されました。'
	},
	EMBEDDED_LESSON: {
		TITLE: "埋め込みリンク",
		PLACEHOLDER: "ここに埋め込みリンクを入力してください"
	},
	TEST_MATRIX: {
		TITLE: '詳細内容',
		NO_PARTITION_MATRIX: {
			TOPIC_MATRIX: {
				TITLE_MATRIX_CONTENT: 'マトリックス内容',
				LABEL_TOPIC: 'トピック',
				LABEL_DIFFICULTY_LEVELS: '難易度',
				LABEL_LEVELS: 'レベル',
				LABEL_NUMBER: '問題数',
				TOTAL_TIME: {
					LABEL_TOTAL_TIME: '合計時間',
					LABEL_EVERY_QUESTION: '問題毎'
				},
				TOTAL_SCORE: {
					LABEL_TOTAL: '合計点',
					LABEL_EVERY_QUESTION: '問題毎'
				},
				RADIO_SELECT_QUESTION_RANDOM:
					'問題と問題のグループをランダムに選択します。',
				RADIO_SELECT_QUESTION_SIGNLE_RANDOM:
					'個々の問題のみをランダムに選択します。',
				RADIO_SELECT_QUESTION_GROUP_RANDOM:
					'問題のグループのみをランダムに選択します。',
				//MESSAGE
				MSG_INPUT_FULL_INFO_ONCE_LEVEL:
					'少なくとも1レベルの完全な情報を入力してください 。'
			},
			MSG_ALREADY_EXIST: 'トピックが既に存在しています。'
		},
		PARTITION_MATRIX: {
			PART_HEADER: {
				LABEL: 'タイトル'
			}
		},
		LABEL_EXAM_CODE_QUANTITY: '問題コード数',
		PLACEHOLDER_INPUT_EXAM_CODE_QUANTITY: '作成対象の試験問題の数を入力'
	},
	QUESTION_SELECT_TYPE: {
		LABEL_ADD_QUESTION_QUANTITY: '追加対象の問題数',
		TOGGLE_QUESTION_MANY_ANWSER: '回答が多くある問題',
		LABEL_CONNECTIVE: '連携',

		CHECKBOX_CREATE_QUESTION_IN_ONCE_GROUP:
			'作成される問題が同一のグループに含まれます',
		BUTTON_ANWSER: '回答',
		BUTTON_FILL_BLANK: '空白の記入'
	},
	// edit_unit_function
	ENTITY_TEST: 'テスト',
	ENTITY_QUESTION: '問題',
	MSG_CRATE_TEST_SUCCESS: '試験問題が正常に作成されました。',
	MSG_CONFIRM_DELETE_OLD_TEST_TITLE:
		'システムが既存の試験問題を削除し、新規の試験問題を作成しますが、宜しいですか。',
	MSG_CONFIRM_DELETE_OLD_TEST_MESSAGE: '削除されたデータは回復できません。',
	MSG_MAXIMUM_NUMBER_OF_TOPICS_CREATED: '作成できる試験問題の数が1000個です。',

	// CoursePopupSelectDuplicateUnit
	TITLE_COPY_OLD_LESSON: '既存のレッスンのコピー',
	LABEL_SELECT_LESSON: 'レッスン選択',
	PLACEHOLDER_FILTER_LESSON: 'レッスン検索',
	MSG_CREATING_LESSON: 'レッスンを作成しています。。。',
	MSG_CREATE_LESSON_SUCCES: 'レッスンが正常に作成されました！',
	MSG_NOT_FULL_CONFIGURATION: 'このレッスンが完全に構成されていません！',
	MSG_COPY_LESSON_SUCCESS: 'コースの複製に成功しました。',
	LIST_ONLINE_COURSE: 'オンラインコース一覧',

	// CHECK_LIST_SETTING
	TITLE_STRUCTURE_OF_TEST_DIVISION: 'テストの構成が複数の部分に分けられます',
	TITLE_STRUCTURE_OF_THE_TEST_WITHOUT_DISTRIBUTION:
		'テストの構成が各部分に分けられません',
	CHECKBOX_QUESTIONS_ALREADY_ANSWERING: '問題はランダムな順序で表示されます',
	CHECKBOX_DIFFERENT_POINT_RATE: '各問題の点数比率が異なります',
	CHECKBOX_MIX_ANSWERS: '問題の回答をミックスする',
	CHECKBOX_QUESTION_ONE_BY_ONE:
		'ユーザーがそれぞれの問題又はそれぞれの問題グループでテストを受けます',
	CHECKBOX_DISABLE_PREVIOUS:
		'ユーザーが完成した問題を再びに見て回答するものとしません',

	CHECKBOX_TIME_LIMIT_FOR_EXAM: '試験期間に制限があります',
	CHECKBOX_TIME_LIMIT_DISTRIBUTE_PARTS:
		'試験期間に制限があり、それぞれの問題に時間を分けます',
	CHECKBOX_TIME_LIMIT_DISTRIBUTE_SENTENCE:
		'Thời gian làm bài giới hạn và chia theo từng câu',
	CHECKBOX_UNLIMITED_TIME_FOR_EXAM: '試験期間に制限がありません',

	CHECKBOX_SCOPE_OF_TEN_POINTS: '10点のスケールで採点します',
	CHECKBOX_POINT_FOR_SUMMARY_OF_QUESTIONS: '試験問題の合計点数で採点します'
}
const COURSE_SECTION_REPORT = {
	RESCORING: {
		ENTITY_TEST: 'テスト',
		POPUP_CONFIRM_UPDATE_RESULT_OF_STUDENT_TITLE: 'ユーザーの結果更新',
		POPUP_CONFIRM_UPDATE_RESULT_OF_STUDENT_MESSAGE:
			'テストの内容を変更しました。受験したユーザーが存在し、結果が出ました。システムがそのユーザーの点数を自動的に更新します。',
		MESSAGE_UPDATE_SUCCESS: 'データを正常に更新されました。'
	},
	COURSE_REPORT_WRAPPER: {
		LABEL_GROUP_NAME: 'グループ名',
		PLACEHOLDER_INPUT_GROUP: 'グループ名を入力・選択してください',
		BUTTON_VIEW_FILTER: 'フィルター・表示',
		TEXT_HOMEWORK: '宿題',
		TEXT_EXAM: '試験問題'
	},
	LINK_RETURN_TO_EXAM_LIST: '問題一覧',
	BUTTON_VIEW_REQUIMENT: '問題を見る',
	// ---------- COURSE_REPORT_HOMEWORK ------------
	HOMEWORK_FILTER: {
		LABEL_FILTER_BY_GROUP: 'グループでフィルタリング',
		PLACEHOLDER_INPUT_AND_GROUP_NAME: 'グループ名を入力し選択します',
		LABEL_STUDENT_NAME: 'ユーザー名前',
		PLACEHOLDER_FILTER_BY_STUDENT_NAME: 'ユーザー氏名で検索してください',
		LABEL_STATUS_SUBMIT: '試験提出状態',
		PLACEHOLDER_STATUS_SUBMIT: '状態',
		OPTION_ALL: '全て',
		OPTION_SUBMITTED: '提出済',
		OPTION_APPROVED: '承認済',
		OPTION_HAVED_REFUSED: '拒否済',
		OPTION_NOT_SUBMITTED: '未提出'
	},
	HOMEWORK_ACTION: {
		MSG_ACCEPT_SUCCESS: '問題が正常に承認しました。',
		MSG_REFUSED_SUCCESS: '正常に拒否されました。',
		MSG_SAVE_SUCCESS: '修正問題を保存しました。'
	},
	LABEL_FILTER_COURSE: 'コース選択',
	LABEL_ALL: '全て',
	LABEL_FILTER_TEST_BY_REQUEST: '要求によるテスト選択',
	ASSIGNED_HOMEWORK: {
		TITLE: 'テスト一覧',
		TH_COURSE: 'コース',
		TH_UNIT_TITLE: 'テスト',
		TH_HOMEWORK_COUNT: '宿題数',
		TH_IS_ALL_HOMEWORK_MARKER: '全て採点済み',
		TH_ACTION: 'アクション'
	},
	POPUP_REQUIREMENT: {
		TITLE: '問題',
		TITLE_INTRODUCT: '紹介',
		TITLE_REQUIREMENT_OF_EXERCISE: '問題の要求'
	},

	// HOMEWORK_EXCEL_EXPORT
	BUTTON_EXPORT_EXCEL: 'Excel出力',
	TH_STUDENT: 'ユーザー',
	TH_EMAIL: 'メールアドレス',
	TH_STATUS: '状態',
	TEXT_WATCHED: '閲覧済',
	TEXT_NOT_SEEN_LEARNING: 'レッスン未閲覧',
	TH_TIME: '時間',
	TH_POINT: '点数',
	TEXT_NOT_POINT: '点数なし',
	TH_EXERCISE_NAME: '問題名',

	// HOMEWORK_ARCHIVEALL
	BUTTON_DOWNLOAD_ALL_EXCEL: '全てダウンロード',
	ENTITY_EXERCISE_STUDENT: 'ユーザーの問題',
	MSG_GROUP_STUDENT_DOES_NOT_EXIST: 'ユーザーグループが存在しません',
	MSG_COMPRESSING: '圧縮しています。',
	MSG_COMPRESSING_TO: 'ユーザーの回答を圧縮しています。',
	//TABLE_HOMWORK
	BUTTON_DOWLOAD_FILE_SELECTED: '選択したファイルのダウンロード',
	TOOLTIP_TEACHER_ACCEPT_LAST_LESSON: '講師の最後採点',
	TOOLTIP_TIME_ACCEPT_LAST_LESSON: '最後採点時間',
	TH_HOMEWORK_NAME: '宿題名',
	TH_STUDENT_NAME: 'ユーザーの氏名',
	TH_SUBMIT_TIME: '提出時間',
	//TH_STATUS:"状態",
	TH_MARK: '点数',
	TH_TEACHER: '講師',
	TH_MARK_AT: '採点時間',
	TH_ACTION: 'アクション',
	TEXT_NO_SCORE: '未採点',
	TEXT_SUBMITTED: '提出済',
	TEXT_APPROVED: '承認済み',
	TEXT_REFUSE: '拒否',
	TEXT_UNSUBMIT: '未提出',

	//POPUP_VIEW_HOMEWORK
	TITLE_VIEW_STUDENTS_LESSONS: 'ユーザーのレッスン閲覧',
	LABEL_EXERCISE_TYPE_LINK: ' 問題がリンクで提出されます。',
	LABEL_CLICK_LINK: '下記のリンクを押下し、ユーザーの問題詳細を見ます。',
	BUTTON_MARKING: '採点',
	MSG_DOWNLOADING: '読み込み中',
	MSG_NOT_FOUND_LINK_PDF:
		'PDFファイルのパスが見つかりません。もう一度お試しください。',

	// POPUP_REPORT_APPROVED_HOMEWORK
	TITLE_BROWSER_EXERCISE: '問題の承認',
	SUBTITLE_BROWSER_EXERCISE:
		'この宿題を承認すると、このレッスンを閉じて、ユーザーがこのレッスンの宿題を再提出することができません。.',
	SUBTITLE_DISPLAY_RESULT:
		'結果がユーザーのシステムに表示されます。また、下記の「コメント送付」を選択して、ユーザーにコメントを送付することができます。',
	LABEL_MARKING: '採点',
	LABEL_MARKING_BY_NUMBER: '数字形式での採点',
	LABEL_COMMENT: 'コメント',
	PLACEHOLDER_IMPORT_COMMENT_FOR_HOMEWORK:
		'ユーザーの宿題へのコメントを入力してください。',
	LABEL_ATTACH_COMMENT: 'コメント送付',
	MSG_ACCEPT_EXERCISE_SUCCESS: '問題が正常に承認しました。',
	SUBJECT_COMMENT_HOMEWORK: '宿題のコメント',
	BUTTON_ACCEPT: '承認',
	MSG_CHECK_LINK_FILE_PDF:
		'PDFファイルのパスが見つかりません。もう一度お試しください。',

	// POPUP_DENY_HOMEWORK
	TITLE_DENY_HOMEWORK: '宿題拒否',
	SUBTITLE_DENY_HOMEWORK:
		'この宿題を拒否すると、このレッスンを再度開き、ユーザーがこのレッスンの宿題を再提出することができます.',
	SUBTITLE_DENY_HOMEWORK_NOTE:
		'また、下記の「コメント送付」を選択して、再提出の要求理由を送付することができます。',
	//LABEL_COMMENT:"コメント",
	PLACEHOLDER_COMMENT: 'ユーザーの宿題のコメントを入力',

	MSG_TIME_SUBMIT_NOT_YET: '提出時間が切れません !',
	MSG_ACCEPT_EXERCISE: '問題が正常に承認しました。',
	MSG_REFUSE_SUCCESS: '正常に拒否されました。',
	MSG_SELECT_GROUP: 'グループをご選択ください。',

	// ---------- COURSE_REPORT_DASHBOARD_EXAM ------------
	TITLE_REPORT_DASHBOARD_EXAM: '結果',
	LABEL_STUDENT_DONE: '完了したユーザー数',
	TEXT_STUDENT: 'ユーザー',
	LABEL_STUDENT_TOP: 'ランキング 1位のユーザー',
	TEXT_NOT_NAME: '名称無し',
	TEXT_NOT_EMAIL: 'メール無し',
	TEXT_DO_NOT_EXAM: '未受験',
	LABEL_NO_ONE_SUBMIT: 'このテストを完成したユーザーが存在しません',
	LABEL_MARK_DISTRIBUTION: '点数の分布',
	LABEL_AVERAGE_SCORE: '平均点数',
	LABEL_MOST_INCORRECT_QUESTIONS: '間違いが最も多い問題',
	LABEL_NOT_DATA_YET: 'データ無し !',
	LABEL_INCORRECT_ANSWER: '回答間違い',
	LABEL_TOTAL_QUESTION: '合計の内',
	LABEL_REPORT_DETAIL: '詳細報告',
	// POPUP_QUESTION_REPORT
	TITLE_QUESTION_REPORT: '詳細報告',
	PLACEHOLDER_PICK_EMAIL_TYPE: 'メールテンプレートを選択',
	BUTTON_SHOW_PDF: '問題表示',
	LABEL_PAGE: 'ページ',
	LABEL_NOT_DATA: 'データがありません。後でお戻りください !',

	//kiểm tra lại
	TEXT_QUESTION: '質問',

	TEXT_NOT_MARK: '点数なし',
	// SHOW_ANALYTIC
	TITLE_QUESTION_CONTENT: '問題内容',
	TITLE_STATISTIC_QUESTION: '回答集計',
	TEXT_QUESTION_COUNT_WRONG: '間違いが最も多い問題',
	TEXT_COUNT_WRONG: '間違いが最も少ない問題',

	// ----------- REPORT_DETAIL_EXAM -----------
	OPTION_LAEDER_BOARD: 'ランキング結果により報告を表示',
	OPTION_REPORT_TABLE_EXAM_VIEW: 'テストの結果により報告を表示',
	TEXT_ALL: '全て',
	BUTTON_SUBMITED: '状態：完了済み',
	BUTTON_DOING: '状態：実行中',

	TOOLTIP_FILTER_REPORT_EXAM_BY_RESULTS:
		'テストの結果により報告を表示した場合、講師が受験状態を見てこのテストを受験するユーザーのグループによる表示を選択することができます。',
	TOOLTIP_FILTER_REPORT_EXAM_BY_RATING:
		'ランキングにより報告を見る時、講師がユーザーの試験点数のランキング順が分かります。',
	PLACEHOLDER_IMPORT_PICK_NAME: '名前を入力し選択',
	LABEL_FILTER_BY_STUDENT_NAME: 'ユーザー氏名',
	PLACEHOLDER_FILTER_BY_STUDENT_NAME: '学生氏名で検索してください',
	//XEM LẠI TEXT TABLE HEADER
	// TH_STATUS: "状態",
	TH_SUBMIT_COUNT: '受験回数',
	TH_AVAILABEL_TIME: '開始',
	MSG_CHECK_GROUP_LEARNING: '学習グループをご選択ください。',

	//TABLE REPORT LEADER BOARD
	// LABEL_FILTER_BY_STUDENT_NAME: "ユーザーの名前で検索",
	BUTTON_RELOAD: 'リロード',
	TH_RANK: 'ランキング',
	//TH_STUDENT:"ユーザー",
	TH_LESSON_NAME: 'レッスン名',
	TH_START_TIME_SUBMIT: '最後提出時間',
	TH_LAST_TIME_SUBMIT: '最後提出時間',
	//TH_TIME:"時間",
	TH_OUT_FOCUS: '無集中',
	TH_RESULT: '結果',
	// TH_POINT: "点数",
	TH_DETAIL: '詳細',
	MSG_DELETE_EXAM_SUCCESS: 'テストが正常に削除されました。!',

	LABEL_RESULT_EXERCISE_DETAIL: '問題詳細 - 結果',
	BUTTON_CANCEL_EXAM: 'テストキャンセル',
	TEXT_EMPTY: '空白',
	// ----------- COURSE_REPORT_PDF_AND_VIDEO ----------------
	// TH_STUDENT_NAME: "ユーザー氏名",
	TH_LAST_VIEW: '最終閲覧日時',
	// TH_STATUS: "状態",

	// EXPORT_EXCEL_PDF_AND_VIDEO
	// TH_STUDENT: "ユーザー",
	// TH_EMAIL: "メール",
	// TH_TIME: "時間",
	// TH_STATUS: "状態",
	TEXT_VIEWED: '閲覧済',
	TEXT_VIEWING: '閲覧中',
	TEXT_NOT_VIEW: '未閲覧',
	TEXT_NOT_VIEW_LESSON: 'レッスン未閲覧',
	TEXT_TOTAL: '合計',

	MSG_CHECK_GROUP_STUDENT_AGAIN: 'グループをご選択ください。',

	POPUP_CONFIRM_DELETE_EXAM_TITLE: '{0}さんのテストを削除します。よろしいですか。',
	POPUP_CONFIRM_DELETE_EXAM_MESSAGE: '削除されたデータは回復できません。',

	//kiểm tra 
	MSG_GETTING_GROUP_DATA: 'グループのデータを取得しています。',

	// ----------- COURSE_REPORT_DEFAULT ------------
	LABEL_FILTER_GROUP: 'グループでフィルタリング',
	PLACEHOLDER_FILTER_GROUP: 'グループ名を入力・選択してください',
	CHECKBOX_ALL: '全て',
	BUTTON_VIEW_FILTER: 'フィルター・表示',
	LABEL_CHECK_PICK_LESSON:
		'結果をフィルタリングするには、レッスン＆グループを一つ選択してください。',
	LABEL_REPORT_TABLE_SUBTITLE:
		'.左側のバーで結果を選択して、上記でグループをフィルタリングした後、ユーザーの結果が下記のサンプルのように表示されます。',

	TH_GROUP: 'グループ',
	//TH_STUDENT_NAME:"ユーザー氏名",
	//TH_EMAIL:"メールアドレス",
	TH_LAST_STUDY: '最後の学習',
	//TH_LAST_STUDY:"最後の学習",
	TH_PROGRESS: '学習進捗',
	//TH_TIME:"時間",
	TH_POIN: '点数',
	TEXT_DID: '受験済み',

	TEXT_EXAMPLE_CLASS: '7A1組',
	TEXT_EXAMPLE_NAME: 'Nguyễn Văn A',
	TEXT_EXAMPLE_EMAIL: 'nguyenvana@gmail.com',
	TEXT_EXAMPLE_TIME: '13:30, 12/12/2020',
	TEXT_EXAMPLE_LESSON: 'レッスン1：動き',
	TEXT_IS_DONE: '完了済み',
	//HOMEWORK
	LABEL_SUBJECTS_CODE: '提出番号',
	PLACEHOLDER_INPUT_SUBJECTS_CODE: '提出番号を入力してください',
	WATCHED: '閲覧済',
	WATCHING: '閲覧中',
	NOT_WATCH: '未閲覧',
	TH_SUBMIT_CODE: '提出番号',
	TH_TIME_SUBMIT: '提出時間',
	TH_POINTING_TIME: '採点時間',

	TITLE_VIEW_SUBJECTS_OF_STUDENT: 'ユーザーの提出物を閲覧'
}
const COURSE_REPORT_EXPORT_EXAM = {
	BUTTON_EXPORT: 'Excel出力',
	TH_STUDENT: 'ユーザー',
	TH_EMAIL: 'メールアドレス',
	TH_START_TIME: '開始時間',
	TH_SUBMIT_TIME: '提出時間',
	TH_COURSE_NAME: 'Course name',
	TH_LESSON_NAME: 'Lesson name',
	TH_DURATION: '完了期間',
	TH_VIOLATION: '無集中',
	TH_RESULT: '結果',
	TH_POINT: '未完了',
	TH_FILE_NAME: 'ファイル名',
	TH_STATUS: '状態',
	TH_TIME: '時間',
	TH_MARK: '点数',
	TEXT_INCOGNITO_NAME: '名称無し',
	TEXT_INCOGNITO_EMAIL: 'メール無し',
	TEXT_TOTAL_VIOLATION_UNIT: '回',
	TEXT_UNFINISHED: '未完了'
}
//オンラインコースの進捗状況
const COURSE_PROGRESS = {
	LABEL_GROUP_STUDENT: 'ユーザーグループ',
	PLACEHOLDER_SELECT_GROUP_STUDENT: 'ユーザーグループを選択してください',
	OPTION_ALL: '全て',
	PLACEHOLDER_FILTER_BY_STUDENT_NAME: '学生の名前',
	BUTTON_FILTER_AND_VIEW: 'フィルター・表示',
	BUTTON_EXPORT_EXCEL: 'Excel出力',

	TH_GROUP: 'グループ',
	TH_STUDENT: 'ユーザー',
	TH_EMAIL: 'メールアドレス',
	TH_LAST_STUDY: '最後の学習',
	TH_LAST_VIEW_LESSON: '最終のレッスン',
	TH_PROGRESS: '学習進捗',
	TH_ACTION: 'もっと見る',
	TEXT_PROGRESS: 'ユーザーの学習進捗',

	MSG_FILTERING_STUDENT: 'ユーザーをフィルタリングしています。',
	MSG_CHECK_GROUP_STUDENT_AGAIN: 'グループをご選択ください。',

	DETAIL: {
		HEADING_TEXT: '学習進捗',
		SUB_TEXT: '最終学習時間'
	}
}
const COURSE_PROGRESS_DETAIL = {
	TITLE: 'ユーザー',
	LABEL_STATUS: '状態',
	PLACEHOLDER_STATUS: '提出状態',
	PLACEHOLDER_SELECT_STATUS: '状態を入力してください',
	LABEL_LESSON_TYPE: 'レッスンのタイプ',
	PLACEHOLDER_SELECT_LESSON_TYPE: 'レッスンのタイプを選択してください',
	PLACEHOLDER_FILTER_LESSON_BY_NAME: 'レッスン名で検索',
	OPTION_STATUST_ALL: '全て',
	OPTION_STATUST_DONE: '完了済み',
	OPTION_STATUST_NOT_START: '未開始',
	OPTION_NO_SCORE: '未採点',
	OPTION_APPROVED: '承認済',
	BUTTON_FILTER_AND_VIEW: 'フィルター・表示',

	OPTION_TYPE_ALL: '全て',
	OPTION_TYPE_VIDEO: 'ビデオ',
	OPTION_TYPE_PDF: 'pdf資料',
	OPTION_TYPE_PDF_TEST: '既存のPDFテストで受験',
	OPTION_TYPE_ADVANCE_TEST: 'カスタマイズが多くある高度なテスト',
	OPTION_TYPE_MATRIX: '問題マトリックスによるランダムテスト',
	OPTION_TYPE_HOMEWORK: '宿題',

	TH_LESSON: 'レッスン',
	TH_DURATION: '期間',
	TH_IS_DONE: '完了済み',
	TH_STUDY_COUNT: '学習回数',

	TH_TOTAL_TIME: '合計時間',
	TH_LAST_UPDATE_AT: '最後の学習',
	TH_FIRST_SCORE: '最初の点数',
	TH_LAST_SCORE: '最後の点数',

	TITLE_SUBJECT_COMPLETE_PROGRESS: '完了進捗',
	TITLE_EXAM_DONE: '受信したテスト',
	TITLE_EXERCISE_DONE: 'やった宿題',
	TITLE_VIDEO_VIEWED: '閲覧した動画レッスン',
	TITLE_PDF_VIEWED: '閲覧したPDFレッスン'
}
//ONLINE COURSE DISCUSS
const COURSE_DICUSION = {
	TEXT_STUDENT_NAMELESS: '名称無し',
	TEXT_NOT_THING: '無し',
	TEXT_REPLIES: 'フィードバック',

	LABEL_TIME: '時間',
	PLACEHOLDER_AVAILABLE_EXPIRED_DATE: '開始日・終了日',
	LABEL_STATUS: '状態',
	OPTION_ALL_STATUS: '全状態',
	OPTION_APPROVED: '承認済',
	OPTION_PENDING: '承認待ち',
	OPTION_WAITING: '待機中',
	BUTTON_BROWSER_ALL: '全て承認',
	BUTTON_FILTER_AND_FIND: 'フィルター・表示',
	TH_FEEDBACK: 'フィードバック',
	TH_FULL_NAME: '氏名',
	TH_EMAIL: 'メールアドレス',
	TH_CONTENT: '内容',
	TH_LAST_UPDATE: '最後の更新',
	TH_STATUS: '状態',
	TH_ACTION: 'アクション',

	TITLE_REP_COMMENT: 'コメント返信',
	BUTTON_LOAD_MORE_COMMENT: 'コメントをもっとみる',
	BUTTON_VIEW: 'もっと見る',
	BUTTON_HIDE: '非表示',

	POPUP_CONFIRM_BROWSER_COMMENT_TITLE: 'アクション確認',

	POPUP_CONFIRM_BROWSER_RELIES_MESSAGE:
		'このフィードバックを承認してもよろしいでしょうか?',
	MSG_BROWSER_COMMNET_SUCCESS: 'フィードバックが正常に承認されました。',

	POPUP_CONFIRM_DELETE_RELIES_MESSSAGE:
		'フィードバックを削除します。よろしいでしょうか？',
	MSG_DELETE_REPLIES_SUCCESS: 'フィードバックが正常に削除されました。',

	POPUP_CONFIRM_BROWSER_COMMENT_MESSAGE:
		'このコメントを承認してもよろしいでしょうか？',
	MSG_BROWSER_COMMENT_SUCCESS: 'コメントが正常に承認されました。',

	POPUP_CONFIRM_BROWSER_ALL_COMMENT_MESSAGE:
		'このコメントを承認してもよろしいでしょうか?',
	MSG_BROWSER_ALL_COMMENT_SUCCESS: '全てのコメントが正常に承認されました。',

	POPUP_CONFIRM_DELETE_COMMENT_MESSAGE:
		'このコメントを削除します。よろしいでしょうか?',
	MSG_DELETE_COMMENT_SUCCESS: 'コメントが正常に削除されました。',

	POPUP_CONFIRM_DELETE_ALL_COMMENT_MESSAGE:
		'このコメントを削除します。よろしいでしょうか?',
	MSG_DELETE_ALL_COMMENT_SUCCESS: '全てのコメントが正常に削除されました。',

	MSG_REPLIES_LOADING: 'フィードバックを読み込んでいます。',

	MSG_GETTING: '受信中',
	MSG_SUCCESS: '成功しました。'
}
// BASE_PDF_QUESTION_RENDERER
const BASE_PDF_QUESTION_RENDERRE = {
	LABEL_TIME: '受験時間',

	TEXT_MINUTE: '分',
	LABEL_THREADS: '問題',
	LABEL_QUESTION: '質問',
	LABEL_ANSWER_THE_QUESTION: '回答',

	CHECKBOX_PICK_ONCE_CORRECT_ANSWER: '(回答を1つ選択します)',
	CHECKBOX_PICK_MULTIPLE_ANSWERS: '(複数の回答を選択します)',
	CHECKBOX_FILL_BLANK: '(空白の記入)'
}
const SECTION_REPORT = {
	// EXAM
	// VIDEO
	// PDF
	// HOMEWORK
	HOMEWORK: {
		POPUP_CORRECTION: {
			BTN_RETURN: '戻る',

			PROGRESS_BAR_ENTITY: '修正問題',
			PROGRESS_BAR_MSG_LOADING: '修正問題を保存しています。',
			PROGRESS_BAR_MSG_ERROR:
				'現在、修正問題を保存できません。インターネットの問題が発生しています。ページをリロードしてみてください！',
			PROGRESS_BAR_MSG_SUCCESS: '修正問題が正常に保存されました!',
			SECTION_EDITOR: {
				MSG_BACKGROUND_LOADING: 'データを読み込んでいます。',
				MSG_BACKGROUND_ERROR_TITLE: '画像の読み込みエラー',
				MSG_BACKGROUND_ERROR_DESCRIPTION:
					'現在、システムが画像を読み込むことができません。デバイスのキャッシュが原因になる可能性があります。キャッシュを削除しページをリロードする又はシークレットブラウザを使用してください。この状態が続く場合は、技術サポーターに連絡してください。',
				TEXT_MARKER_CONTENT: 'テキスト内容',
				POPUP_TEXT_EDIT: {
					TITLE_CREATE: 'テキスト作成',
					TITLE_EDIT: 'テキスト修正',
					CONTENT_PLACEHOLDER: 'ここにテキスト内容を記入',
					MSG_CONTENT_VALIDATOR: 'テキスト内容をご記入ください'
				},
				BTN_ACTION_UNDO: '前のアクション',
				BTN_ACTION_REDO: 'キャンセルしたアクション',
				BTN_ACTION_CHOOSE: '選択',
				BTN_ACTION_DRAW: '画像への描画',
				BTN_ACTION_TEXT: 'テキスト',
				BTN_ACTION_ERASER: '消しゴム',
				BTN_ACTION_ROTATE: '回転',
				ACTION_OPTIONS: {
					ROTATE_CLOCKWISE: '時計回りに90度回転します',
					ROTATE_COUNTER_CLOCKWISE: '反時計回りに90度回転します。'
				}
			},
			SECTION_APPROVE: {
				SUBSECTION_ID_TITLE: '提出番号',
				SUBSECTION_MARK_TITLE: '問題の採点',
				SUBSECTION_MARK_ACTION: '採点',
				OPTION_CONTAIN_NOTE: 'コメント付き',
				INPUT_NOTE_LABEL: 'コメント',
				INPUT_NOTE_PLACEHOLDER: 'ユーザーの宿題のコメントを入力',
				SUBSECTION_REMINDER_TITLE: '注意',
				SUBSECTION_REMINDER_DESCRIPTION:
					'講師が現在の作業を完成した後、点数、コメントと修正問題が表示され、ユーザーが見える。\n問題が採点され、承認されたら、ユーザーが再提出できないので、ご注意ください。',
				BTN_APPROVE: '問題の承認',
				BTN_DENY: '拒否',
				WARNING_MARK_IS_NOT_NUMBER: '数字のフォーマットが不正です。',
				WARNING_MARK_IS_GREATER_OR_EQUAL_MIN: '{0}以上の数字です。',
				WARNING_MARK_IS_SMALLER_OR_EQUAL_MAX: '{0}以下の数字です。',
				MSG_MARK_VALIDATOR: '点数をもう一度ご確認ください。'
			},
			SECTION_DENY: {
				INPUT_NOTE_LABEL: '拒否理由',
				INPUT_NOTE_PLACEHOLDER: '学生が次回改善するために見ることができるように理由を記入してください',
			},
			CONFIRM_APPROVE: {
				TITLE: '問題の承認',
				DESCRIPTION: 'このアクションを再実行します。よろしいですか。?'
			},
			CONFIRM_DENY: {
				TITLE: '問題拒否',
				DESCRIPTION: 'この問題を拒否すると、レッスンが再開され、ユーザーが問題を再提出できます。'
			},

			CONFIRM_SAVE_BEFORE_CHANGE_FILE: {
				TITLE: '現在の画像の保存確認',
				DESCRIPTION:
					'「保存せずに次の問題に進む」を選択した場合、今行ったすべての問題修正、コメントは保存されません。.',
				BTN_SAVE: '保存',
				BTN_DONT_SAVE: '保存せずに次の問題に進む'
			},
			CONFIRM_SAVE_BEFORE_LEAVE: {
				TITLE: '変更を保存してもよろしいでしょうか？',
				DESCRIPTION:
					'「保存せずに終了」を選択した場合、今行ったすべての問題修正、点数、またはコメントは保存されません。',
				BTN_SAVE: '保存',
				BTN_DONT_SAVE: '保存せずに終了'
			},
		},
		EXCEL_EXPORT: {
			PREFIX_FILE_NAME: '結果',
			COLUMN_HEADER: {
				SUBMISSION_ID: '提出番号',
				STUDENT_NAME: 'ユーザー',
				STUDENT_EMAIL: 'メールアドレス',
				SUBMISSION_STATUS: '状態',
				SUBMIT_TIME: '時間',
				SUBMISSION_MARK: '点数',
				SUBMISSION_TITLE: '問題タイトル'
			},
			SUBMISSION_STATUS: {
				APPROVED: '承認済み',
				DENIED: '拒否済み',
				SUBMITTED: '提出済',
				NONE: 'レッスン未閲覧'
			},
			SUBMISSION_MARK: {
				DENIED: '拒否済み',
				NONE: '点数なし'
			},
		},
	}
}

const POPUP_EDIT_PART_DIALOG = {
	TITLE_EDIT_OR_DELETE: 'セクション修正又は削除',
	TITLE_ADD_NEW: '新しいセクション追加',
	LABEL_HEADER: 'タイトル',
	PLACEHOLDER_INPUT_HEADER: '問題のタイトルを入力してください',
	LABEL_DESCRIPTION: '説明',
	POPUP_CONFIRM_DELETE_TITLE: 'セクション削除',
	POPUP_CONFIRM_DELETE_MESSAGE:
		'問題セクションを削除すると、セクション内のすべての問題／問題グループが削除され、元に戻せません。このセクションを削除してもよろしいですか？'
}
const COURSE_PROGRESS_PDF_TABLE = {
	TITLE: 'PDFレッスン',
	TH_NAME: 'ファイル名',
	TH_TIME: '完了時間'
}
const COURSE_PROGRESS_VIDEO_TABLE = {
	TITLE: '動画レッスン',
	TH_NAME: 'ファイル名',
	TH_TIME: '完了時間'
}
const ONLINE_COURSE_WITH_TOPIC_MOBIE = {
	TITLE: 'コースのカード情報',
	LABEL_TOPIC: 'トピック',
	PLACEHOLDER_SELECT_TOPIC: 'トピックを選択してください',
	LABEL_HEADER_CARD: 'カードのタイトル',
	PLACEHOLDER_INPUT_HEADER_CARD: 'カードのタイトルを記入してください',
	LABEL_BUTTON_DISPLAY: '表示ボタン',
	PLACHOLDER_BUTTON_INFO_DISPLAY: 'ボタンでの表示情報',
	LABEL_LINK: 'パス',
	PLACEHOLDER_LINK: '例: https://example.com'
}
const BASE_UPLOAD_DRAGGER = {
	MSG_UPLOAD_LOADING: '動画はアップロードできて、準備中です。',
	MSG_UPLOAD_SUCCESS: '正常にアップロードできました。',
	MSG_FILE_UPLOAD_OVER_THE_SIZE:
		'アップロードしたファイルが許容容量を超えています。もう一度確認してください。',
	MSG_FORMAT_FILE_UPLOAD_FALSE:
		'アップロードしたファイルの形式が間違っています。アップロードしたファイルを再確認してください。',
	MSG_MAX_FILES_REACHED: 'アップロードできるのは{0}ファイルのみです',
}
const BASE_UPLOAD_IMAGE = {
	BUTTON_SELECT_FROM_LIBRARY: 'ライブラリから選択',
	BUTTON_UPLOAD_IMAGE: '画像アップロード',
	MODAL_LIBRARY: {
		TITLE: 'ライブラリから選択',
		LABEL_FILE_NAME: 'ファイル名',
		PLACEHOLDER_FILTER_FILE_NAME: 'ファイル名で検索してください',
		LIST_IMAGE_THUMBNAIL: {
			TITLE_NOT_FOUND_IMAGE: '画像ファイルが見つかりません',
			LABEL_PAGE: 'ページ'
		},
		ATTACHMENT_DETAIL: {
			TITLE: 'ファイル詳細',
			LABEL_SIZE: 'サイズ',
			LABEL_CREATE_AT: '作成日',
			BUTTON_EDIT_IMAGE_INFO: '画像情報修正',
			LABEL_FORMAT: '形式',
			PLACEHOLDER_INPUT_LINK: 'パスを入力してください',
			LABEL_HEADER: 'タイトル',
			PLACEHOLDER_INPUT_HEADER: 'タイトルを入力してください'
		},
		BUTTON_SELECT: '選択'
	}
}
const COURSE_DUPLICATE = {
	ACTION_DUPLICATE: '複製',
	DUPLICATE_POPUP: {
		TITLE: 'コースの複製',
		COURSE_TITLE_PREFIX: '{0}の複製',
		MSG_INVALID_COURSE_TITLE:
			'コースのタイトルが既に使用されています。他のタイトルをご使用ください。'
	},
	MSG_PROGRESS: {
		DUPLICATING_COURSE: 'コースを複製しています。',
		DUPLICATING_COURSE_CONTENT: 'コースの内容を複製しています。',
		DUPLICATING_COURSE_CONTENT_CHAPTER: '章"{0}"の内容を複製しています。',
		DUPLICATING_DONE:
			'コースの複製が完了しました。作成したコースに遷移しています。'
	}
}

export default {
	COURSE_LIST,
	FORM_CREATE_NEW_COURSE,
	COURSE_OVERVIEW,
	ONLINE_COURSE_DETAIL,
	OFFLINE_COURSE_DETAIL,
	ONLINE_COURSE_CONTENT,
	COURSE_UNIT_RESOURCE_SELECTION,
	COURSE_PROGRESS,
	COURSE_DICUSION,
	BASE_PDF_QUESTION_RENDERRE,
	POPUP_EDIT_PART_DIALOG,
	COURSE_PROGRESS_PDF_TABLE,
	COURSE_PROGRESS_VIDEO_TABLE,
	ONLINE_COURSE_WITH_TOPIC_MOBIE,
	BASE_UPLOAD_DRAGGER,
	BASE_UPLOAD_IMAGE,
	ONLINE_COURSE_OVERVIEW,
	COURSE_SECTION_REPORT,
	COURSE_REPORT_EXPORT_EXAM,
	COURSE_PROGRESS_DETAIL,
	SECTION_REPORT,
	COURSE_DUPLICATE,
	//TITLE
	TITLE_EXAM: 'テスト',
	TITLE_THREADS: '問題のタイトル',
	TITLE_EXERCISE: '問題',
	TITLE_ACTION: 'アクション',
	TITLE_ANWSER_OF_STUDENT: 'ユーザーの回答',
	TITLE_OPERATION: 'アクション',
	TITLE_POST_UNIT: '講義後',
	
	//LABEL
	LABEL_SELECT_FILE: 'ファイル選択',
	LABEL_QUESTION: '問題',
	LABEL_QUESTION_TYPE: '問題タイプ',
	LABEL_QUESTION_GROUP: '問題グループ',
	LABEL_SENTENCE: '文',
	LABEL_MARK_UNIT: '点数',
	LABEL_ANWSER: '回答',
	LABEL_ANWSER_TRUE: '正解',
	LABEL_CURRENT_UNIT: 'ベトナムドン',
	OPTION_ALL: '全て',
	PLACEHOLDER_INPUT_FILL_BLANK: '回答を入力してください',
	//TEXT
	TEXT_UPDATING: '更新中',
	TEXT_NOT_MARK: '未採点',
	//BUTTON
	BUTTON_CONFIRM: '確認',
	BUTTON_CANCEL_TEXT: 'キャンセル',
	BUTTON_CANCEL: 'キャンセル',
	BUTTON_SAVE: '保存',
	BUTTON_OK: 'OK',
	BUTTON_DELETE: '削除',
	BUTTON_VIEW_FILTER: 'フィルター・表示',
	BUTTON_SEARCH_FILTER: 'フィルター・検索',
	BUTTON_REFUSE: '拒否',
	BUTTON_DOWNLOAD: 'ダウンロード',
	BUTTON_DOWNLOAD_ALL: '全てダウンロード',
	BUTTON_ACCEPT: '承認',
	BUTTON_BACK: '戻る',
	BUTTON_RELOAD: 'リロード',
	BUTTON_CLOSE: '閉じる',
	BUTTON_CREATE: '作成',
	BUTTON_ADD: '追加',
	BUTTON_EDIT: '追加設定',
	BUTTON_SEARCH: '検索',
	BUTTON_DELETE_ANWSER: '回答削除',
	BUTTON_MARK: '採点',
	BUTTON_DONATE_MARK: '採点',
	BUTTON_VIEW_DOWNLOAD: '閲覧・ダウンロード',
	BUTTON_EXPORT_EXCEL: 'Excel出力',
	BUTTON_INPUT: '入力',
	BUTTON_PREVIEW: 'プレビュー',
	//MESSAGE
	MSG_DATA_LOADING: 'データを読み込んでいます。',
	MSG_UPDATING_DATA: 'データを更新しています。',
	MSG_UPDATE_DATA_SUCCESS: 'データが正常に更新されました。',
	MSG_UPDATING: '更新中',
	MSG_UPDATE_SUCCESS: '正常に更新されました。',
	MSG_UPDATE_FAIL: '更新できませんでした。',
	MSG_DELETE_SUCCESS: '正常に削除されました。',
	MSG_DELETE_FAIL: '削除できませんでした。',
	MSG_CHECK_GROUP_LEARNING_AGAIN: 'グループをご選択ください。',
	MSG_GETTING_DATA: 'データを取得しています',
	MSG_REFUSE_SUCCESS: '正常に拒否されました。',
	MSG_COPY_SUCCESS: '正常にコピーできました。',
	MSG_COPY_FAIL: 'コピーできませんでした。’. もう一度お試しください。',
	MSG_EXPORTING_DATA: 'データを出力しています。',
	// TABLE_HEADER
	TH_COURSE_NAME: 'コース名',
	TH_ACTION: 'アクション',
	TEXT_INCOGNITO_NAME: '名称無し',
	TEXT_INCOGNITO_EMAIL: 'メール無し',
	TEXT_STARTED_TIME: '開始済み',
	TEXT_DO_NOT_EXAM: '未受験',

	TEXT_TIMES: '回',
	TEXT_DID: '受験済み',
	//STATUS DO EXAM
	CHECKBOX_STATUS_DOING: '受験中',
	CHECKBOX_DONE: '完了',
	MSG_CHECK_INFO_AGAIN: 'もう一度情報をご確認ください。',
	MSG_CHECK_PICK_GROUP_AGAIN: 'グループをご選択ください。',
	// --------------------
	TOOLTIP_ONLINE_COURSE_LIST: 'オンラインコース一覧',
	DEFAULT_PROGRESS_BAR: '{2}番目の{1}を{0}しています。', // 0: action, 1: entity, 2: entity number
	BTN_VIEW_DETAIL: '詳細を見る',
}
