import { RepositoryFactory } from '@/api/factory/repositoryFactory'
import set from 'lodash-es/set'
import utils from '@/helpers/components/examUtils.js'
import commonUtils from '@/helpers/utils.js'
const Knowledge = RepositoryFactory.get('knowledge')

const state = {
  rootKnowledges: {},
  allKnowledges: {},
  changeTimes: 0,
  fullKnowledges: {},
  sortType: 'index'
}

const actions = {
  async create({ commit, state }, data) {
    const newKnowledge = await Knowledge.create(data)
    commit('setAllKnowledges', {
      ...state.allKnowledges,
      ...(newKnowledge.data || {})
    })
    commit('increaseChangeTimes')
  },
  async fetchAll(_, params) {
    const res = await Knowledge.fetch(params)
    const objKnowledges = res.data.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    )
    return objKnowledges
  },
  async updateKnowledgeOrder(_, { knowledges }) {
    const promises = []
    knowledges.map((knowledge, idxSection) => {
      knowledge = {
        ...knowledge,
        index: idxSection
      }
      const promise = Knowledge.update(knowledge.id, { index: idxSection });
      promises.push(promise)
    });
    try {
      await Promise.all(promises)
    } catch (error) {
      throw new Error(error)
    }
  },
  async update({ commit }, { id, payload }) {
    const res = await Knowledge.update(id, payload)
    commit('updateKnowledge', res.data)
    commit('increaseChangeTimes')
  },
  async updateIndependent(_, { id, payload }) {
    await Knowledge.update(id, payload)
  },
  async delete({ commit }, id) {
    commit('increaseChangeTimes')
    return Knowledge.remove(id)
  },
  setRootKnowledges({ state }, rootKnowledges) {
    state.rootKnowledges = rootKnowledges
  },
  setFullKnowledges({ state }, fullKnowledges) {
    state.fullKnowledges = fullKnowledges
  },
  setSortType({ state }, item) {
    state.sortType = item
  },
  async fetchAllKnowledges({ commit }, params) {
    const res = await Knowledge.fetch(params)
    const objKnowledges = res.data.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    )
    commit('setAllKnowledges', objKnowledges)
  },
  async fetchChildrenForKnowledge({ state, dispatch }, rootItem) {
    let rootKnowledges = state.rootKnowledges
    const allChildItems = await dispatch('fetchAll', {
      type: "children",
      parents: rootItem.id,
    })
    Object.values(allChildItems)
      .sort((a, b) => a.parents.length - b.parents.length)
      .map((item) => {
        const path = utils.getPathOfKnowledge(item);
        set(rootKnowledges, `${path}`, item);
      })
    let listKnowledges = Object.values(rootKnowledges).map((item) => {
      return {
        ...item,
        children: utils.convertChildrenToArray(item.children),
      };
    })
    listKnowledges = commonUtils.sortWithType(listKnowledges, state.sortType)
    state.fullKnowledges = listKnowledges.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    );
  }
}

const mutations = {
  setAllKnowledges(state, obj) {
    state.allKnowledges = { ...obj }
  },
  updateKnowledge(state, item) {
    state.allKnowledges[item.id] = item
  },
  increaseChangeTimes(state) {
    state.changeTimes = state.changeTimes + 1
  }
}

const getters = {
  getKnowledges: state => {
    return state.rootKnowledges
  },
  getFullKnowledges: state => {
    return state.fullKnowledges
  },
  getChangeTimes: state => {
    return state.changeTimes
  },
  getSortType: state => {
    return state.sortType
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
