import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const leaderboardRepository = RepositoryFactory.get('leaderboard')
const state = {
	rankOfStudent: null,
	leaderboard: [],
	statistic: [],
	searchData: [],
	countSearchData: 0,
	averageScore: 0,
	count: 0
}
const actions = {
	async fetchWithRank({commit}, params) {
		const res = await leaderboardRepository.fetchWithRank(params)
		commit('setLeaderboard', res.data || [])
		return res.data
	},
	async searchWithRank({commit}, params) {
		const res = await leaderboardRepository.searchWithRank(params)
		commit('setSearchData', res.data || [])
		return res.data
	},
	async fetchWithScore({commit}, params) {
		const res = await leaderboardRepository.fetchWithScore(params)
		commit('setLeaderboard', res.data || [])
		return res.data
	},
	async fetchAverageScore({commit}, params) {
		const res = await leaderboardRepository.getAverageScore(params)
		commit('setAverageScore', res.data || 0)
		return res.data
	},
	async count({commit}, params) {
		const res = await leaderboardRepository.count(params)
		commit('setCount', res.data || 0)
		return res.data
	},
	async statistic({commit}, params) {
		const res = await leaderboardRepository.statistic(params)
		commit('setStatistic', res.data || [])
		return res.data
	}
}
const mutations = {
	setRankStudent(state, rank) {
		state.rankOfStudent = rank
	},
	setLeaderboard(state, leaderboard) {
		state.leaderboard = leaderboard
	},
	setCount(state, count) {
		state.count = count
	},
	setSearchData(state, data) {
		state.searchData = data.sort((a, b) => a.rank - b.rank)
		state.countSearchData = data.length
	},
	setStatistic(state, statistic) {
		state.statistic = statistic
	},
	setAverageScore(state, averageScore) {
		state.averageScore = averageScore
	}
}
const getters = {
	getRankOfStudent: state => {
		return state.rankOfStudent
	},
	getLeaderboard: state => {
		return state.leaderboard.sort((a, b) => a.rank - b.rank)
	},
	getCount: state => {
		return state.count
	},
	getAverageScore: state => {
		return state.averageScore
	},
	getCountSearchData: state => {
		return state.countSearchData
	},
	getSearchData: state => {
		return state.searchData
	},
	getStatistic: state => {
		return state.statistic
	}
}
export default {
	state,
	actions,
	getters,
	mutations,
	namespaced: true
}
