<template>
  <div v-if="submission" class="canvas-element-exam">
    <div class="exam-duration">Email: <span class="font-weight-medium">{{ submission.email }}</span></div>
    <div class="exam-duration">Học sinh: <span class="font-weight-medium">{{ submission.name }}</span></div>
    <div class="exam-duration" v-if="isMarkShown">Điểm: {{ `${submission.correct}/${submission.total}` }}</div>
    <v-divider class="my-2" />
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  props: {
    isMarkShown: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      submission: 'pdfRender/getSubmission',
    }),
  },
}
</script>
<style lang="scss" scoped>
.exam-duration {
  padding-top: 4px;
  font-size: 14px;
}
.canvas-element-exam {
  padding-left: 16px;
  padding-right: 16px;
}
</style>
