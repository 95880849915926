import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const CouponCode = RepositoryFactory.get('couponCode')

const state = {
  couponCodes: [],
  couponCode: {},
  selectCourse: {},
  count: 0,
}

const actions = {
  async fetchCouponCodes({commit}, params = {}) {
    const res = await CouponCode.fetch({
      ...params,
    })
    commit('setCouponCodes', res.data || [])
    return res.data
  },
  async countCouponCodes({commit}, params) {
    const res = await CouponCode.count(params)
    commit('setCountCoupon', res.data)
    return res.data
  },
  async fetchCouponCodeById({commit}, id) {
    const res = await CouponCode.fetchOne(`${id}?_populate=relation`)
    commit('setOneCouponCode', res.data)
    return res.data
  },
  async createCouponCode({commit, rootState}, data) {
    const res = await CouponCode.create({
      ...data,
      userId: rootState.auth.auth.userId,
    })
    commit('setCouponCode', res.data)
    return res.data
  },
  async deleteCouponCode({commit}, id) {
    await CouponCode.remove(id)
    return commit('removeCouponCode', id)
  },
  async updateCouponCode({commit}, {id, couponCode}) {
    const res = await CouponCode.update(id, couponCode)
    commit('setCouponCode', res.data)
    return res.data
  },
  selectCourse({commit}, course) {
    commit('setSelectCourse', course)
  },
}

const mutations = {
  setCouponCodes(state, couponCodes) {
    return (state.couponCodes = couponCodes)
  },
  setSelectCourse(state, course) {
    state.selectCourse = course
  },
  setCountCoupon(state, count) {
    state.count = count
  },
  removeCouponCode(state, id) {
    state.couponCodes = state.couponCodes.filter((code) => code.id !== id)
  },
  setCouponCode(state, newCouponCode) {
    state.couponCodes = state.couponCodes.map((couponCode) => {
      if (couponCode._id === newCouponCode._id) {
        return newCouponCode
      } else return couponCode
    })
    return state.couponCodes
  },
  setOneCouponCode(state, couponCode) {
    state.couponCode = couponCode
  },
}

const getters = {
  getCouponCodes: (state) => {
    return state.couponCodes.reverse()
  },
  getCouponCode: (state) => {
    return state.couponCode
  },
  getCountCouponCode: (state) => {
    return state.count
  },
  getSelectCourse: (state) => {
    return state.selectCourse
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
