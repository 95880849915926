export default {
  badge: 'バッジ',
  close: '閉じる',
  dataIterator:{
    noResultsText: '一致するデータが見つかりません',
    LoadingText: 'データを読み込んでいます...',
  },
  dataTable: {
    itemsPerPageText: 'ページあたりの行数:',
    ariaLabel:{
      sortDescending: '降順で並べ替えます。',
      sortAscending: '昇順で並べ替えます。',
      sortNone: 'ソートしないでください。',
      activateNone: 'アクティブ化して並べ替えを解除します。',
      activateDescending: 'アクティブ化して降順で並べ替えます。',
      activateAscending: 'アクティブ化して昇順で並べ替えます。',
    },
    sortBy: '並べ替え',
  },
  dataFooter:{
    itemsPerPageText: '表示する行数:',
    itemsPerPageAll: 'すべて',
    nextPage: '次のページ',
    prevPage: '前のページ',
    firstPage: '最初のページ',
    lastPage: '最後のページ',
    pageText: '{0}-{1} on {2}',
  },
  datePicker:{
    itemsSelected: '選択された{0}',
    nextMonthAriaLabel: '来月',
    nextYearAriaLabel: '来年',
    prevMonthAriaLabel: '先月',
    prevYearAriaLabel: '昨年',
  },
  noDataText: 'データなし',
  carousel:{
    prev: '前',
    next: '後',
    ariaLabel:{
      delimiter: '{1}のスライド{0}',
    },
  },
  calendar:{
    moreEvents: 'まだ{0}',
  },
  fileInput:{
    counter: '{0}ファイル',
    counterSize: '{0}ファイル（全部で{1}）',
  },
  timePicker:{
    am: 'AM',
    pm: 'PM',
  },
  pagination:{
    ariaLabel:{
      wrapper: 'ページ遷移',
     next: '次のページ',
      previous: '前のページ',
      page: 'ページ{0}に移動',
      currentPage: '現在のページ,ページ{0}',
    },
  },
}