import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const ClassStudent = RepositoryFactory.get('classStudent')
const namespaced = true
import utils from '@/helpers/utils.js'
import get from 'lodash-es/get'
import vendor from '@/store/modules/vendor'

const state = {
  classStudent: [],
  countStudent: 0,
}

const actions = {
  async fetchClassStudent({commit}, params) {
    const res = await ClassStudent.fetchClassStudent(params)
    commit('setClassStudent', res.data || [])
    return res.data || []
  },

  async addStudent({commit}, params) {
    const res = await ClassStudent.addStudent(params)
    commit('addNewStudents', res.data)
    return res.data || {}
  },
  addStudentImmediate({commit}, data) {
    commit('addStudentImmediate', data)
  },
  async removeClassStudent({commit}, id) {
    const res = await ClassStudent.deleteStudent(id)
    commit('removeClassStudent', id)
    return res.data || {}
  },
  async createMany(_, params) {
    const res = await ClassStudent.createMany({...params, vendorId: vendor.state.vendor.vendorId})
    return res.data || []
  },
  async fetchCount({commit}, params) {
    const res = await ClassStudent.count({...params, vendorId: vendor.state.vendor.vendorId})
    commit('setCountStudent', res.data || 0)
    return res.data || 0
  },
  // refreshClassStudent({commit}) {
  //   co
  // }
}

const mutations = {
  setClassStudent(state, data) {
    state.classStudent = [...data]
  },
  addNewStudents(state, data) {
    state.classStudent = [...state.classStudent, data]
  },
  addStudentImmediate(state, data) {
    data.forEach((item) => {
      let index = state.classStudent.findIndex((val) => val.student === item.id)
      if (index > -1) {
        state.classStudent[index].student = item
      }
    })
  },
  removeClassStudent(state, id) {
    let index = state.classStudent.findIndex((val) => val.id === id)
    if (index > -1) {
      state.classStudent.splice(index, 1)
    }
  },
  setCountStudent(state, num) {
    state.countStudent = num
  },
}

const getters = {
  getClassStudent: (state) =>
    state.classStudent
      .filter((x) => x.student)
      .map((x) => ({
        ...x,
        fullname: get(x, 'student.fullname', '') || get(x, 'student.name', ''),
        email: get(x, 'student.email', 'Không có email'),
        studentId: get(x, 'student.id', ''),
        name: get(x, 'student.fullname', '') || get(x, 'student.name', ''),
        tags: utils.clearUnicode(get(x, 'student.fullname', '') || get(x, 'student.name', '')),
      }))
      .sort(utils.sortByNameInAlphabetical),
  getCountStudent: (state) => state.countStudent,
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
