<template>
	<div>
		<BaseUploadDragger
			ref="uploader"
			:type="type"
			:maxFilesize="maxFilesize"
			:maxFiles="maxFiles"
			:uploadMultiple="uploadMultiple"
			@onUploadSuccess="data => onUploadSuccess(data)"
		>
			<div v-if="hasLibrary" class="relative" style="z-index: 1">
				<v-btn
					@click.native="openLibraryDialog"
					class="button-upload--library"
					small
					depressed
					dark
				>
					<v-icon small left> mdi-image-multiple </v-icon>
						{{$vuetify.lang.t("$vuetify.COURSE.BASE_UPLOAD_IMAGE.BUTTON_SELECT_FROM_LIBRARY")}}
					</v-btn
				>
				<img
					class="image-upload"
					:src="
						imageUrl
							? $utils.convertURL(imageUrl)
							: helpers.DEFAULT_IMAGE.banner
					"
				/>
				<v-btn class="button-upload--image mb-1" depressed dark
					>{{$vuetify.lang.t("$vuetify.COURSE.BASE_UPLOAD_IMAGE.BUTTON_UPLOAD_IMAGE")}}</v-btn
				>
			</div>
			<div v-else class="icon-button-upload has-dashed background-gray">
				<v-btn block plain color="#2e4493"><v-icon>mdi-plus</v-icon></v-btn>
			</div>
		</BaseUploadDragger>
		<ModalLibrary
			ref="library"
			@onSelect="selected => onSelected([selected])"
			@onClose="onClose"
		/>
	</div>
</template>
<script>
import ModalLibrary from '@/components/ModalLibrary'
import helpers from '@/helpers'
export default {
	components: {
		ModalLibrary
	},
	props: {
		hasLibrary: {
			type: Boolean,
			default: true
		},
		maxFilesize: {
			type: Number,
			default: 3750
		},
		type: {
			type: String,
			default: 'image'
		},
		imageUrl: String,
		maxFiles: {
			type: Number,
			default: 1
		},
		uploadMultiple: Boolean
	},
	data() {
		return {
			helpers
		}
	},
	methods: {
		async openLibraryDialog() {
			await this.$refs['library'].show()
			await this.$refs['uploader'].disabled()
		},
		async onSelected(data) {
			await this.$refs['uploader'].enabled()
			await this.$refs['library'].hide()
			await this.onUploadSuccess(data)
		},
		async onClose() {
			await this.$refs['uploader'].enabled()
			await this.$refs['library'].hide()
		},
		onUploadSuccess(data) {
			this.$emit('onUploadSuccess', data)
		}
	}
}
</script>
<style lang="scss" scoped>
.image-upload {
	width: 100%;
	height: 175px;
	object-fit: cover;
	border-radius: 4px;
}
.image-upload:hover {
	opacity: 0.8;
}
.button-upload {
	opacity: 0.8;
	position: absolute;
	display: flex;
	padding: 15px;
	justify-content: space-between;
	align-items: center;
	&--image {
		@extend .button-upload;
		width: 100%;
		bottom: 0px;
		left: 0px;
		border-radius: 0px 0px 4px 4px;
	}
	&--library {
		@extend .button-upload;
		z-index: 2;
		top: 4px;
		right: 4px;
		border-radius: 4px;
	}
}
</style>
