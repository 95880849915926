<template>
  <div v-show="isVisibility">
    <div id="pdf-renderer-exam-review" :key="rendererTime">
      <div class="text-center text-uppercase text-h5">Báo cáo nhận xét kết quả</div>
      <div class="text-center text-uppercase text-h6 mt-2 mb-4">{{ unitTitle }}</div>
      <StudentInfo :isMarkShown="false" />
      <div class="d-flex justify-space-between align-center ml-4 mb-4 canvas-element">
        <div class="d-flex flex-column justify-center aligh-center mx-2">
          <div class="font-weight-medium text-center">Hoàn thành với<br />số điểm là</div>
          <div class="red--text text-h6 text-center">{{ mark }}</div>
        </div>
        <div class="mx-2">
          <ReviewGraph
            :partitionReviewStats="reviewStats"
            style="position: relative; height: 250px; width: 450px"
            @onRendered="onReviewGraphRendered"
            id="review-graph"
          />
        </div>
      </div>
      <div class="d-flex justify-space-between ml-4 full-width mb-4 canvas-element">
        <div>
          <div class="font-weight-medium mb-2" style="color: #797979">Câu trả lời</div>
          <div class="d-flex progress-line" style="background-color: #e5e7eb; width: 300px">
            <div class="green progress-line" :style="`width: ${correctPercentage}%`"></div>
            <div class="red progress-line" :style="`width: ${wrongPercentage}%`"></div>
          </div>
          <div class="d-flex mt-2">
            <div v-if="correctQuantity" class="mr-2 d-flex align-center">
              <v-icon color="green" x-small class="mr-1">mdi-circle</v-icon>
              <div class="text-caption">{{ correctQuantity }} câu đúng</div>
            </div>
            <div v-if="wrongQuantity" class="mr-2 d-flex align-center">
              <v-icon color="red" x-small class="mr-1">mdi-circle</v-icon>
              <div class="text-caption">{{ wrongQuantity }} câu sai</div>
            </div>
            <div v-if="remainQuantity" class="mr-2 d-flex align-center">
              <v-icon color="grey" x-small class="mr-1">mdi-circle</v-icon>
              <div class="text-caption">{{ remainQuantity }} câu chưa trả lời</div>
            </div>
          </div>
        </div>
        <div>
          <div class="font-weight-medium mb-2" style="color: #797979">Thời gian làm bài</div>
          <div class="d-flex progress-line" style="background-color: #e5e7eb; width: 300px">
            <div class="progress-line" :style="`background-color: #0084ff; width: ${timePercentage}%`"></div>
          </div>
          <div class="mt-2 text-caption">Hoàn thành trong {{ timeProgressText }}</div>
        </div>
      </div>
      <div class="d-flex flex-column ml-4 full-width mb-4 canvas-element">
        <div class="font-weight-medium mb-2" style="color: #797979">Chi tiết điểm</div>
        <ul class="full-width">
          <li v-for="(stat, index) in reviewStats" :key="index" class="full-width">
            <div class="d-flex justify-space-between text-body-2" style="width: 80%">
              <div class="black--text font-weight-medium">Phần {{ stat.title }}</div>
              <div>
                <span class="green--text font-weight-medium">{{ padNumber(stat.correctQuantity) }}</span>
                <span class="black--text font-weight-medium">/{{ padNumber(stat.totalQuantity) }}</span>
                <span className="grey--text">&nbsp;câu đúng</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="font-weight-medium mb-2 ml-4 canvas-element" style="color: #797979">Nhận xét của giáo viên</div>
      <div v-for="(detail, index) in reviewDetails" :key="index" class="mb-2 ml-4 canvas-element">
        <div class="d-flex align-center">
          <v-icon color="black" x-small class="mr-1">mdi-circle</v-icon>
          <span class="font-weight-medium text-body-2">{{ !detail.noPrefix ? 'Phần ' : '' }}{{ detail.title }}</span>
        </div>
        <div style="white-space: break-spaces" class="text-body-2 mt-1">{{ detail.text }}</div>
      </div>
    </div>
    <div id="pdf-renderer-temp-exam-review"></div>
    <div id="description-exam-review"></div>
  </div>
</template>
<script>
import StudentInfo from '@/components/pdf-renderer/exam/StudentInfo'
import ReviewGraph from '@/components/pdf-renderer/exam-review/ReviewGraph'
import {mapActions, mapGetters} from 'vuex'
import {getStatsFromPartition, getReviewDetail} from '@/helpers/components/examReviewUtils.js'
import dayjs from 'dayjs'
import get from 'lodash-es/get'
export default {
  components: {
    StudentInfo,
    ReviewGraph,
  },
  data() {
    return {
      reviewStats: [],
      reviewDetails: [],
      mark: '',
      correctQuantity: 0,
      wrongQuantity: 0,
      remainQuantity: 0,
      correctPercentage: 0,
      wrongPercentage: 0,
      timePercentage: 0,
      timeProgressText: '',
      unitTitle: '',
    }
  },
  computed: {
    ...mapGetters({
      isVisibility: 'pdfRender/getVisibilityExamReview',
      rendererTime: 'pdfRender/getRenderedTime',
      latestSubmission: 'pdfRender/getSubmission',
      selectedUnit: 'unit/selectedUnitReport',
      selectedExam: 'exam/exam',
    }),
  },
  methods: {
    ...mapActions({
      pdfSetIsExamReviewRendered: 'pdfRender/pdfSetIsExamReviewRendered',
    }),
    init(latestSubmission = {}) {
      this.onReset()
      const {questions, answers, correct, total, startTime, submitTime} = latestSubmission
      const reviewStats = getStatsFromPartition(this.selectedExam.partition, answers, questions)
      this.reviewStats = reviewStats
      this.reviewDetails = getReviewDetail(this.selectedUnit.reviewConfig, reviewStats)
      this.mark = `${correct}/${total}`
      const correctQuantity = this.getCountAnswer(answers, true),
        wrongQuantity = this.getCountAnswer(answers, false),
        totalQuantity = reviewStats.map((x) => x.totalQuantity).reduce((a, b) => a + b, 0)

      this.correctQuantity = correctQuantity
      this.wrongQuantity = wrongQuantity
      this.remainQuantity = totalQuantity - (correctQuantity + wrongQuantity)
      this.correctPercentage = ((correctQuantity / totalQuantity) * 100).toFixed(2)
      this.wrongPercentage = ((wrongQuantity / totalQuantity) * 100).toFixed(2)
      const {timeDiffMilliseconds, timeProgressText} = this.compareTimePeriods(startTime, submitTime)
      const timeLimit = Number(get(this.selectedExam, 'settings.expiredTime', 0)) * 60000
      if (timeDiffMilliseconds > timeLimit || !timeLimit) this.timePercentage = 100
      else this.timePercentage = ((timeDiffMilliseconds / timeLimit) * 100).toFixed(2)
      this.timeProgressText = timeProgressText
      this.unitTitle = this.selectedUnit.title
    },
    padNumber(num) {
      return num < 10 ? `0${num}` : num
    },
    getCountAnswer(answers, type) {
      return answers ? Object.values(answers).filter((answer) => answer.isCorrect === type).length : 0
    },
    compareTimePeriods(firstTime, lastTime) {
      const day1 = new Date(firstTime)
      const day2 = new Date(lastTime)
      const timeDiffMilliseconds = day2.getTime() - day1.getTime()
      const minutes = dayjs(timeDiffMilliseconds).format('mm')
      const seconds = dayjs(timeDiffMilliseconds).format('ss')
      return {
        timeProgressText: `${minutes} phút ${seconds} giây`,
        timeDiffMilliseconds,
      }
    },
    onReviewGraphRendered() {
      const graphCanvas = document.querySelector('#review-graph canvas')
      const graphToImage = graphCanvas.toDataURL('image/png')
      const image = document.createElement('img')
      image.src = graphToImage
      image.id = 'review-graph-image'
      image.onload = () => {
        document.querySelector('#review-graph').appendChild(image)
        graphCanvas.style.display = 'none'
        this.pdfSetIsExamReviewRendered(true)
      }
      // this.pdfSetIsExamReviewRendered(true)
    },
    onReset() {
      const graphCanvas = document.querySelector('#review-graph canvas')
      graphCanvas.style.display = 'block'
      const renderImage = document.querySelector('#review-graph-image')
      if (renderImage) document.querySelector('#review-graph').removeChild(renderImage)
    },
  },
  watch: {
    latestSubmission: {
      handler(val) {
        if (val) this.init(val)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
@import './src/assets/scss/pdf-renderer/exam-review-renderer.scss';
</style>
