<template>
  <div class="py-4 d-flex justify-center flex-column">
    <div class="text-h5 font-weight-medium text--secondary text-center">
      {{ $vuetify.lang.t(title) }}
    </div>
    <div class="text-subtitle text--secondary text-center" v-if="isSubtitleShown">
      {{ $vuetify.lang.t(subtitle) }}
    </div>
    <div class="mx-auto pt-4">
      <v-btn
        v-if="!isNewButtonCustomed"
        class="elevation-0 text-none full-width mb-4"
        @click="$emit('click')"
        color="primary"
      >
        <v-icon small class="mr-2">{{actionButtonIcon}}</v-icon>{{ $vuetify.lang.t(buttonText) }}
      </v-btn>
      <slot name="custom-new-button" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '$vuetify.TITLE_EMPTY_DATA',
    },
    subtitle: {
      type: String,
      default: '$vuetify.SUBTITLE_EMPTY_DATA',
    },
    buttonText: {
      type: String,
      default: '$vuetify.BUTTON_EMPTY_DATA',
    },
    isNewButtonCustomed: Boolean,
    isSubtitleShown: {
      type: Boolean,
      default: true
    },
    actionButtonIcon: {
      type: String,
      default: 'mdi-plus'
    }
  },
}
</script>