export default{
  BUTTON_DOWNLOAD:"Download",
  BUTTON_TUTORIAL:"Installation Guide",
  HEADING_TEXT:"Heading text",
  BUTTON_TEXT:"Button Name",
  MAIN_TITLE:"Main Title",
  SUBTITLE:"Subtitle",
  EBOOK:"Book",
  LEAD_FORM:"Form",
  BUTTON_SEND:"Send",
  TITLE_AUTHOR:"Author",
  SUBTITLE_QUOTE:"Quote",
  DESCRIPTION:"Description",
  HEADING_ONLINE_COURSE:"Online Course",
  HEADING_OFFLINE_COURSE:"Offline Course",
  HEADING_LEARNING_PATH:"Learning Paths",
  HEADING_MY_ONLINE_COURSE:"My Online Course",
  SLIDE: "Slide",
  SUBHEADING_TEXT:"Subheading text"

}