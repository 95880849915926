<template>
  <div>
    {{ $vuetify.lang.t('$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_ANSWER_THE_QUESTION') }}:
    <span
      >{{ studentAnswer }}
      <v-icon v-if="!isCorrect" size="small" color="red">mdi-close</v-icon>
      <v-icon v-else size="small" color="green">mdi-check</v-icon></span
    >
  </div>
</template>
<script>
import get from 'lodash-es/get'
import {mapGetters} from 'vuex'
export default {
  props: {
    questionId: String,
  },
  computed: {
    ...mapGetters({
      submission: 'pdfRender/getSubmission',
    }),
    studentAnswer() {
      const answer = this.submission.answers[this.questionId]
      return get(answer, 'answer', '')
    },
    isCorrect() {
      const answer = this.submission.answers[this.questionId]
      return get(answer, 'isCorrect', false)
    },
  },
}
</script>
