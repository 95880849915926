//LỘ TRÌNH HỌC
const LEARNING_PATH_LIST = {
	TITLE: 'Lộ trình học',
	BUTTON_CREATE: 'Tạo lộ trình',
	LABEL_FILTER_BY_NAME: 'Tên lộ trình',
	PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên lộ trình',
	//TABLE HEADER
	TH_NAME: 'Tên lộ trình',
	TH_CREATE_DATE: 'Ngày tạo',
	TH_STATUS: 'Trạng thái',
	TH_ACTION: 'Thao tác',
	TEXT_PUBLISHED: 'Đã xuất bản',
	TEXT_UNPUBLISH: 'Chưa xuất bản',
	TOOLTIP_EDIT_LEARNING_PATH: 'Chỉnh sửa lộ trình',
	//EMPTY_DATA
	TITLE_EMPTY_DATA: 'Chưa tồn tại lộ trình học nào',
	SUBTITLE_EMPTY_DATA:
		'Vui lòng tạo mới lộ trình để hiển thị trên danh sách này',

	TOOLTIP_PUSH_TO_HIGHLIGHTS_LIST:
		'Đưa lộ trình này vào danh sách lộ trình nổi bật',
	TOOLTIP_REMOVE_OUT_HIGHLIGHTS_LIST:
		'Bỏ lộ trình này khỏi danh sách lộ trình nổi bật',

	//BASE_LEARNING_PATH_PICKER
	LABEL_SELECT_LEARNING_PATH: 'Chọn lộ trình học',
	PLACEHOLDER_FILTER_LEARNING_PATH: 'Tìm kiếm lộ trình học'
}
const FORM_CREATE_NEW_LEARNING_PATH = {
	TITLE: 'Lộ trình học mới',
	LABEL_NAME: 'Tên lộ trình',
	PLACEHOLDER_INPUT_NAME: 'Nhập tên lộ trình học',

	//MESSAGE
	MSG_CREATING: 'Đang tạo lộ trình học ...',
	MSG_CREATE_SUCCESS: 'Tạo lộ trình học mới thành công',
	MSG_CHECK_NAME_AGAIN: 'Tên lộ trình đã được sử dụng!',
	MSG_CREATE_FAIL: 'Tạo lộ trình học mới thất bại'
}
const LEARNING_PATH_DETAIL = {
	LINK_RETURN_TO_LEARNING_PATH_LIST: 'Danh sách Lộ trình học',
	//TAB
	TAB_INFO: 'Thông tin',
	TAB_PUBLISH: 'Xuất bản',
	TAB_COURSE: 'Khoá học',
	TAB_DELETE: 'Xoá lộ trình học',
	PATH_INFO: {
		BASIC_INFO: {
			TITLE: 'Thông tin cơ bản',
			SUBTITLE: 'Thiết lập tên, danh mục và mức độ khó cho lộ trình',
			LABEL_NAME: 'Tên lộ trình (Tối đa 120 kí tự)',
			PLACEHOLDER_INPUT_NAME: 'Nhập tên lộ trình',
			LAVEL_DIFICUL_LEVEL: 'Mức độ khó',
			PLACEHOLDER_SELECT_TYPE: 'Chọn loại',
			TEXT_BEGINNER: 'Cơ bản',
			OPTION_IMMEDIATE: 'Nâng cao',
			OPTION_EXPERT: 'Thông thạo',
			LABEL_CATEGORY: 'Danh mục',
			PLACEHOLDER_SELECT_TOPIC: 'Chọn chủ đề',
			BUTTON_ADD_CATEGORY: 'Tạo thêm danh mục',
		},
		//DESCRIPTION_SHORT
		DESCRIPTION_SHORT: {
			TITLE: 'Mô tả ngắn gọn',
			SUBTITLE: 'Thiết lập mô tả ngắn gọn',
			LABEL_DESCRIPTION_ON_CARD: 'Mô tả ngắn trên thẻ',
			PLACEHOLDER_INPUT_DESCRIPTION_CARD: 'Nhập mô tả (tối da 200 ký tự)'

		},
		//DESCRIPTION_INFO
		DESCRIPTION_INFO: {
			TITLE: 'Mô tả chi tiết',
			SUBTITLE: 'Cung cấp mô tả để học viên tham khảo và hiểu rõ hơn về lộ trình học'
		},
		COVER_IMAGE: {
			TITLE: 'Ảnh nền trong trang chi tiết',
			SUBTITLE: 'Cấu hình ảnh nền lộ trình học trong trang chi tiết. Kích thước khuyên dùng: 1920x400px'
		},
		PREVIEW_IMAGE: {
			TITLE: 'Ảnh đại diện trên thẻ',
			SUBTITLE: 'Cấu hình ảnh hiển thị trên thẻ tại màn hình danh sách. Kích thước khuyên dùng: 240x360px'
		},
		DURATION_SETTING: {
			TITLE: 'Thời lượng',
			SUBTITLE: 'Thiết lập mô tả thời lượng lộ trình học.',
			RADIO_INPUT_DESCRIPTION: 'Tự nhập',
			RADIO_ALLOW_SYSTEM_CACULATE:
				'Cho phép tự động tính toán dựa trên các khóa học',
			LABEL_COURSE_TIME: 'Thời lượng khoá học',
			PLACEHOLDER_INPUT_COURSE_TIME: 'Nhập thời lượng',
			HINT_COURSE_TIME:
				'Lưu ý: Các khóa học chưa cài đặt tự động tính thời lượng sẽ không được tính vào tổng thời lượng của lộ trình.'
		},
		CERTIFICATE_SETTING: {
			TITLE: 'Chứng chỉ được áp dụng',
			SUBTITLE:
				' Cấu hình chứng chỉ để cấp cho học viên sau khi hoàn thành lộ trình học.',
			LABEL_ALLOW_USE: 'Chọn thiết lập',
			LABEL_VIEW_DETAIL:
				'Bạn đã có chứng chỉ cho lộ trình học này. Xem chi tiết chứng chỉ',
			LINK_GO_TO_CERTIFICATE: 'Tại đây',
			LABEL_CREATE_NEW:
				'Chưa có chứng chỉ nào được được thiết lập. Vui lòng tạo mới'
		},
		INTERACTION_SETTING: {
			TITLE: 'Đánh giá của học viên',
			LABEL_ALLOW_AND_DISPLAY:
				'Cho phép học sinh đánh giá và hiển thị đánh giá tương ứng với lộ trình',
			LABEL_REQUIRED_BROWSER:
				'Bắt buộc duyệt đánh giá trước khi được đăng công khai trên trang'
		}
	},
	PUBLISH_SETTING: {
		TITLE: 'Xuất bản lộ trình học',
		SUBTITLE:
			'Công khai lộ trình học này và hiển thị trên các trang danh sách. (Trừ trường hợp lộ trình học bị giới hạn hiển thị)',
		BUTTON_STOP: 'Ngừng xuất bản',
		BUTTON_PUBLISH: 'Xuất bản',
		POPUP_CONFIRM_STOP_TITLE:
			'Bạn có chắc muốn ngừng xuất bản lộ trình học này?',
		POPUP_CONFIRM_STOP_MESSAGE:
			'Lộ trình học sẽ không còn được hiển thị trên website nữa.'
	},
	COURSE_IN_PATH: {
		TITLE: 'Danh sách khoá học',
		POPUP_CONFIRM_SAVE_TITLE: 'Cảnh báo lưu khóa học',
		POPUP_CONFIRM_SAVE_MESSAGE:
			'Tồn tại khóa học không cài đặt tự động tính thời lượng. Khóa học đó sẽ không được tính vào thời lượng của lộ trình.',

		TITLE_TEMPTY_DATA: 'Chưa có khóa học nào, vui lòng thêm khóa học!',
		LABEL_PUBLISHED: 'Đã xuất bản',
		LABEL_NOT_PUBLISHED: 'Chưa xuất bản',

		TOOLTIP_AUTO_TIME_SETTINGS_NOT_SET_UP:
			'Khóa học chưa cài đặt tự động tính thời lượng',
		TOOLTIP_REMOVE: 'Gỡ bỏ khóa học',

		POPUP_CONFIRM_EXIT_TITLE: 'Thay đổi chưa được lưu',
		POPUP_CONFIRM_EXIT_MESSAGE:
			'Bạn đang có cài đặt chưa được lưu. Vui lòng hoàn thành trước khi rời đi.',

		POPUP_CONFIRM_REMOVE_TITLE: 'Xác nhận gỡ khoá học',
		POPUP_CONFIRM_REMOVE_MESSAGE:
			'Bạn có chắc muốn gỡ khóa học khỏi lộ trình này không?'
	},
	DELETION: {
		TITLE: 'Xóa lộ trình học',
		SUBTITLE: 'Tiến hành xoá lộ trình học. Chú ý: Lộ trình học bị xoá sẽ không thể khôi phục',
		BUTTON: 'Xoá lộ trình',
		MSG_DELETING: 'Đang xoá lộ trình',

		POPUP_CONFIRM_DELETE_TITLE: 'Xoá lộ trình học khỏi hệ thống',
		POPUP_CONFIRM_DELETE_MESSAGE: 'Dữ liệu sau khi xóa không thể khôi phục'
	},
	MSG_UPDATE_SUCCESS: 'Cập nhật tài liệu thành công',
	MSG_USED_NAME: 'Tên lộ trình đã được sử dụng!',
	MSG_UPDATE_FAIL: 'Cập nhật thất bại!'
}
//SỰ KIỆN
const EVENT_LIST = {
	TITLE: 'Sự kiện',
	BUTTON_CREATE: 'Tạo sự kiện',
	LABEL_FILTER_BY_NAME: 'Tên sự kiện',
	PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên sự kiện',
	TITLE_EMPTY_DATA: 'Chưa tồn tại sự kiện nào',
	SUBTITLE_EMPTY_DATA:
		'Vui lòng tạo mới sự kiện để hiển thị trên danh sách này',
	//TABLE HEADER
	TH_NAME: 'Tên sự kiện',
	TH_AVAILABEL_TIME: 'Thời gian bắt đầu',
	TH_CREATE_DATE: 'Ngày tạo',
	TH_STATUS: 'Trạng thái',
	TH_ACTION: 'Thao tác',
	TEXT_PUBLISHED: 'Đã xuất bản',
	TEXT_UNPUBLISH: 'Chưa xuất bản',
}
const FORM_CREATE_NEW_EVENT = {
	TITLE: 'Sự kiện mới',
	LABEL_NAME: 'Tên sự kiện',
	PLACEHOLDER_INPUT_NAME: 'Nhập tên sự kiện',
	LABEL_DESCRIPTION: 'Mô tả sự kiện',
	PLACEHOLDER_INPUT_DESCRIPTION: 'Nhập mô tả sự kiện',

	//MESSAGE
	MSG_CREATING: 'Đang tạo sự kiện ...',
	MSG_CREATE_SUCCESS: 'Tạo sự kiện mới thành công',
	MSG_CHECK_NAME_AGAIN: 'Tên sự kiện đã được sử dụng!',
	MSG_CREATE_FAIL: 'Tạo sự kiện mới thất bại',

	//TIME
	LABEL_AVAILABLE_DATE: 'Ngày bắt đầu',
	PLACEHOLDER_SELECT_AVAILABLE_DATE: 'Chọn ngày bắt đầu',
	LABEL_AVAILABLE_HOUR: 'Giờ bắt đầu',
	PLACEHOLDER_SELECT_LABEL_AVAILABLE_HOUR: 'Chọn giờ bắt đầu',
	LABEL_EXPIRED_DATE: 'Ngày kết thúc',
	PLACEHOLDER_SELECT_EXPIRED_DATE: 'Chọn ngày kết thúc',
	LABEL_EXPIRED_HOUR: 'Giờ kết thúc',
	PLACEHOLDER_SELECT_EXPIRED_HOUR: 'Chọn giờ kết thúc',
}
const EVENT_DETAIL = {
	TITLE: 'Sự kiện mới',
	LINK_RETURN_TO_EVENT_LIST: 'Danh sách sự kiện',
	//TAB
	TAB_BASIC_INFO: 'Thông tin',
	TAB_ADVANCE_INFO: 'Thông tin nâng cao',
	TAB_PRICE: 'Giá tiền',
	TAB_PUBLISH: 'Xuất bản',
	TAB_DELETE: 'Xoá sự kiện',
	BASIC_INFO: {
		ON_CARD: {
			TITLE: 'Thông tin cơ bản',
			LABEL_HEADER: 'Tên sự kiện',
			PLACEHOLDER_INPUT_HEADER: 'Nhập tiêu đề sự kiện',
			TITLE_SHORT_DESCRIPTION: 'Mô tả ngắn gọn',
			SUBTITLE_SHORT_DESCRIPTION: 'Cung cấp mô tả để giới thiệu nhanh về sự kiện trên trang danh sách.',
			LABEL_SHORT_DESCRIPTION: 'Mô tả ngắn gọn (Độ dài đề nghị: 200 từ)',
			PLACEHOLDER_INPUT_SHORT_DESCRIPTION: 'Nhập mô tả sự kiện',
			MSG_REQUEST_INPUT_HEADER: 'Vui lòng nhập tiêu đề sự kiện!'
		},
		ADDRESS: {
			TITLE: 'Địa điểm tổ chức',
			RADIO_OUTSIDE: 'Địa điểm khác',
			LABEL_INPUT_ADDRESS_OF_EVENT: 'Nhập địa chỉ',
			RADIO_TRAINING_DEPARTMENT: 'Cơ sở theo danh sách',
			LABEL_PICK_DEPARTMENT: 'Chọn cơ sở'
		},
		TIME: {
			TITLE: 'Thời gian diễn ra',
			LABEL_AVAILABLE_DATE: 'Ngày bắt đầu',
			PLACEHOLDER_SELECT_AVAILABLE_DATE: 'Chọn ngày bắt đầu',
			LABEL_AVAILABLE_HOUR: 'Giờ bắt đầu',
			PLACEHOLDER_SELECT_LABEL_AVAILABLE_HOUR: 'Chọn giờ bắt đầu',
			LABEL_EXPIRED_DATE: 'Ngày kết thúc',
			PLACEHOLDER_SELECT_EXPIRED_DATE: 'Chọn ngày kết thúc',
			LABEL_EXPIRED_HOUR: 'Giờ kết thúc',
			PLACEHOLDER_SELECT_EXPIRED_HOUR: 'Chọn giờ kết thúc',
			MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin thời gian!'
		},
		PREVIEW_IMAGE: {
			TITLE: 'Ảnh đại diện',
			SUBTITLE: 'Cấu hình ảnh hiển thị trên thẻ tại màn hình danh sách. Kích thước khuyên dùng: {0}'
		},
		COVER_IMAGE: {
			TITLE: 'Ảnh bìa',
			SUBTITLE: 'Cấu hình ảnh hiển thị trên dầu trang giới thiệu riêng.Kích thước khuyên dùng: {0}'
		}
	},
	INFO_ADVANCE_IMAGE: {
		TITLE: 'Ảnh mô tả sự kiện (tối đa 6 ảnh)',
		SUBTITLE: 'Kích thước khuyên dùng: 400x400px'
	},
	//INF_ADVANCE_DESCRIPTION
	TITLE_DESCRIPTION_DETAIL: 'Mô tả chi tiết',
	SUBTITLE_DESCRIPTION_DETAIL: 'Cung cấp mô tả để học viên tham khảo và hiểu rõ hơn về sự kiện tại trang giới thiệu riêng',
	//INFO_PRICE
	PRICE_INFO: {
		TITLE: 'Giá tiền',
		SUBTITLE: 'Cấu hình mức giá hiển thị cho sự kiện',
		RADIO_FREE: 'Thiết lập miễn phí',
		RADIO_PAY_ONCE: 'Thiết lập thanh toán một lần',
		LABEL_OFFICIAL: 'Mức giá cuối',
		SUFFIX_CURRENT_UNIT: 'VND',
		LABEL_AFTER_DISCOUNT: 'Mức giá trước khi khuyến mại',
		HINT_AFTER_DISCOUNT: 'Bỏ trống nếu không có khuyến mại',

		MSG_RECHECK_INFO: 'Vui lòng kiểm tra lại thông tin!'
	},
	PUBLISHING: {
		TITLE_STOP_PUBLISH: 'Ngừng xuất bản sự kiện này',
		SUBTITLE_STOP_PUBLISH:'Ngừng xuất bản sự kiện và ẩn khỏi các trang danh sách.',
		TITLE_PUBLISH_COURSE: 'Xuất bản sự kiện',
		SUBTITLE_PUBLISH:'Công khai sự kiện này và hiển thị trên các trang danh sách. (Trừ trường hợp sự kiện bị giới hạn hiển thị)',
		BUTTON_PUBLISH: 'Xuất bản',
		BUTTON_STOP_PUBLISH: 'Ngừng xuất bản',
		POPUP_CONFIRM_STOP_TITLE: 'Bạn có chắc muốn ngừng xuất bản sự kiện này?',
		POPUP_CONFIRM_STOP_MESSAGE:'Ngừng xuất bản sự kiện và ẩn khỏi các trang danh sách.'
	},
	DELETION: {
		TITLE: 'Xóa sự kiện',
		SUBTITLE: 'Tiến hành xóa sự kiện. Chú ý: Sự kiện bị xóa sẽ không thể khôi phục',
		BUTTON: 'Xoá sự kiện',
		POPUP_CONFIRM_TITLE: 'Xoá sự kiện khỏi hệ thống',
		POPUP_CONFIRM_MESSAGE: 'Dữ liệu sau khi xóa không thể khôi phục',
		MSG_DELETING: 'Đang xoá sự kiện...',
		MSG_DELETE_SUCCESS: 'Xoá sự kiện thành công'
	},
	MSG_DOWNLOAD_EVENT_DETAIL_LOADING: 'Đang tải chi tiết sự kiện...',
	MSG_RECHECK_EVENT_TITLE: 'Vui lòng nhập tiêu đề sự kiện!',
	MSG_RECHECK_TIME_EVENT: 'Vui lòng kiểm tra lại thời gian diễn ra sự kiện!',
	MSG_CREATING_EVENT: 'Đang tạo sự kiện...',
	MSG_CREATE_EVENT_FAIL: 'Lỗi tạo sự kiện!',
	MSG_UPDATING_EVENT: 'Đang cập nhật sự kiện...',
	MSG_UPDATE_EVENT_SUCCESS: 'Cập nhật sự kiện thành công',
	MSG_UPDATE_EVENT_FAIL: 'Lỗi cập nhật sự kiện!'
}
//SÁCH ĐIỆN TỬ
const EBOOK_LIST = {
	TITLE: 'Sách điện tử',
	BUTTON_CREATE: 'Tạo sách',
	LABEL_FILTER_BY_NAME: 'Tên tài liệu',
	PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên tài liệu',
	//TABLE HEADER
	TH_NAME: 'Sách điện tử',
	TH_CREATEDAT: 'Thêm vào nhóm lúc',
	TH_AUTHOR: 'Tác giả',
	TH_UPDATE: 'Cập nhật',
	TH_ACTION: 'Thao tác',
	TOOLTIP_EDIT_EBOOK: 'Chỉnh sửa sách điện tử',
	//EMPTY_DATA
	TITLE_EMPTY_DATA: 'Chưa tồn tại sách điện tử nào',
	SUBTITLE_EMPTY_DATA:
		'Vui lòng tạo mới sách điện tử để hiển thị trên danh sách này'
}
const EBOOK_DETAIL = {
	TITLE: 'Danh sách Sách điện tử',
	TITLE_NEW_DOCUMENT: 'Tài liệu mới',
	//TAB
	TAB_BASIC_INFO: 'Thông tin',
	TAB_CONTENT: 'Nội dung',
	TAB_PRICE_AND_OWNERSHIP: 'Giá tiền và sở hữu',
	TAB_SETTING_INTERACT: 'Cài đặt tương tác',
	TAB_DELETE: 'Xoá sách điện tử',
	//BASIC_INFO
	BASIC_INFO: {
		ON_CARD: {
			TITLE: 'Thông tin cơ bản',
			LABEL_NAME: 'Tên ebook',
			PLACEHOLDER_INPUT_NAME: 'Điền tên sách điện tử',
			LABEL_TEACHER_UNDERTAKE: 'Tác giả/Giảng viên',
			PLACEHOLDER_SELECT_TEACHER_UNDERTAKE: 'Chọn giảng viên',
			BUTTON_CREATE_TEACHER: 'Tạo thêm giảng viên',
			LABEL_CATEGORY: 'Danh mục',
			PLACEHOLDER_SELECT_TOPIC: 'Chọn chủ đề',
			BUTTON_CREATE_CATEGORY: 'Tạo thêm danh mục',
		},
		SHORT_DESCRIPTION: {
			TITLE: 'Mô tả ngắn gọn',
			SUBTITLE: 'Cung cấp mô tả để giới thiệu nhanh về Sách điện tử tại trang danh sách',
			LABEL_DESCRIPTION: 'Mô tả ngắn gọn (Độ dài đề nghị: 200 ký tự)',
			PLACEHOLDER_INPUT_DESCRIPTION: 'Nhập mô tả',
		},
		DESCRIPTION_DETAIL: {
			TITLE: 'Mô tả chi tiết',
			SUBTITLE: 'Cung cấp mô tả để học viên tham khảo và hiểu rõ hơn về sách điện tử tại trang giới thiệu riêng'
		},
		PREVIEW_IMAGE: {
			TITLE: 'Ảnh đại diện',
			SUBTITLE: 'Cấu hình ảnh hiển thị trên thẻ tại màn hình danh sách. Kích thước khuyên dùng: {0}'
		},
		COVER_IMAGE: {
			TITLE: 'Ảnh bìa',
			SUBTITLE: 'Cấu hình ảnh hiển thị trên đầu trang giới thiệu riêng. Kích thước khuyên dùng: {0}'
		}
	},
	//ADVANCE_INFO
	TITLE_UPLOAD_IMAGE_TYPE_PDF: 'Nội dung sách (PDF)',
	TITLE_DESCRIPTION_DETAIL: 'Mô tả chi tiết',
	PRICE_INFO: {
		TITLE: 'Giá tiền và sở hữu',
		RADIO_FREE: 'Thiết lập miễn phí',
		RADIO_PAY_ONCE: 'Thiết lập thanh toán một lần',
		LABEL_PRICE_OFFICIAL: 'Mức giá cuối',
		LABEL_CURRENT_UNIT: 'VND',
		LABEL_VALUE_AFTER_DISCOUNT: 'Mức giá trước khi khuyến mại',
		HINT_PRICE_AFTER_DISCOUNT: 'Bỏ trống nếu không có khuyến mại',
		RADIO_LOCK_PURCHASE:
			'Học viên không thể thanh toán và chỉ sở hữu sách điện tử nếu có mã kích hoạt hoặc được cấp quyền',
	},
	//INTERACTION_SETTING
	TITLE_INTERACTION_OF_STUDENT: 'Đánh giá',
	SUBTITLE_INTERACTION_OF_STUDENT: 'Cấu hình quy tắc về đánh giá sách điện tử',
	CHECKBOX_STUDENT_CAN_ENABLE_RATE:
		'Cấu hình quy tắc về đánh giá sách điện tử',
	CHECKBOX_MUST_APPROVE_RATE:
		'Chỉ hiển thị các đánh giá đã được duyệt',

	//DISPLAY_SETTING
	TITLE_SETTING_DISPLAY: 'Hiển thị',
	SUBTITLE_SETTING_DISPLAY: 'Cấu hình hiển thị sách điện tử trên trang Hồ sơ học tập',
	CHECKBOX_VISIBLE_HIDDEN:
		'Học sinh sở hữu sách điện tử mới có thể nhìn thấy trong trang Hồ sơ học tập',

	DELETION: {
		TITLE: 'Xóa sách điện tử',
		SUBTITLE: 'Tiến hành xóa sách điện tử. Chú ý: Sách điện tử bị xóa sẽ không thể khôi phục',
		BUTTON: 'Xoá sách điện tử',
		MSG_LOADING: 'Đang xoá sự kiện...',
		MSG_SUCCESS: 'Xoá thành công',
		POPUP_CONFIRM_TITLE: 'Xoá tài liệu khỏi hệ thống',
		POPUP_CONFIRM_MESSAGE: 'Dữ liệu sau khi xóa không thể khôi phục'
	},

	MSG_INPUT_TITLE_EVENT: 'Vui lòng nhập tiêu đề sự tài liệu!',
	MSG_CHECK_INFO_PRICE:
		'Cổng Thanh toán điện tử không thể thực hiện giao dịch dưới 10.000VNĐ. Vui lòng đặt giá trị cao hơn !',
	MSG_CREATE_EBOOK_SUCCESS: 'Tạo tài liệu thành công',
	MSG_UPDATE_EBOOK_SUCCESS: 'Cập nhật tài liệu thành công',
	MSG_CREATE_EBOOK_LOADING: 'Đang tạo tài liệu...',

	TOPIC_DETAIL_EBOOK: {
		LINK_RETURN_TO_CATEGORY_LIST: 'Danh sách danh mục',
		TITLE: 'Chỉnh sửa Danh mục',
		TITLE_MANAGE_EBOOK: 'Quản lý sách điện tử trong Danh mục',
		LABEL_EBOOK_NAME: 'Tên sách điện tử',
		PLACEHOLDER_SELECT_EBOOK_NAME: 'Chọn sách điện tử gắn vào danh mục',
		MSG_ADDING: 'Thêm sách vào danh mục...',
		MSG_ADD_SUCCESS: 'Thêm sách vào danh mục thành công!',
		MSG_REMOVING: 'Gỡ bỏ sách khỏi danh mục...',
		MSG_REMOVE_SUCCESS: 'Gỡ bỏ sách thành công!',
		POPUP_CONFIRM_REMOVE_TITLE: 'Bạn có chắc muốn gỡ bỏ sách khỏi danh mục này?'
	},
	TOPIC_TABLE_EBOOK: {
		TH_EBOOK: 'Tên sách điện tử',
		TH_UPDATE: 'Cập nhật',
		TH_ACTION: 'Thao tác',
	},
}
//DANH MỤC - THỂ LOẠI
const CATEGORY_LIST = {
	TITLE: 'Danh mục - Thể loại',
	BUTTON_CREATE: 'Tạo danh mục',
	LABEL_FILTER_BY_NAME: 'Tên danh mục',
	PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên danh mục',
	//TAB
	TAB_ONLINE_COURSE: 'Khoá học Online',
	TAB_OFFLINE_COURSE: 'Khoá học Offline',
	TAB_EBOOK: 'Ebook',
	//TABEL HEADER
	TH_CATEGORY: 'Danh mục - Thể loại',
	TH_UPDATE: 'Cập nhật',
	TH_ACTION: 'Thao tác',
	TOOLTIP_EDIT_CATEGORY: 'Chỉnh sửa danh mục',
	SUBTITLE_EMPTY_DATA:
		'Vui lòng tạo mới danh mục để hiển thị trên danh sách này',

	//TOGGLE_FEATURE_TOPIC
	TOOLTIP_ADD_CATEGORIES_TO_DISPLAY_LIST:
		'Đưa danh mục này vào danh sách danh mục hiển thị trên menu',
	TOOLTIP_REMOVE_CATEGORY_FROM_DISPLAY_LIST:
		'Bỏ danh mục này khỏi danh sách danh mục hiển thị trên menu',
	POPUP_CONFIRM_DELETE_TITLE: 'Bạn có chắc chắn xoá danh mục này không?',
	//MESSAGE
	MSG_DELETE_SUCCESS: 'Xoá danh mục thành công',
	MSG_DELETE_FAIL: 'Đã xảy ra lỗi trong quá trình xóa',
	MSG_UPDATING_DATA: 'Đang cập nhật dữ liệu ...',
	MSG_UPDATE_SUCCESS: 'Cập nhật thành công !'
}
const CATEGORY_CREATE_NEW_FORM = {
	TITLE: 'Tạo danh mục mới',
	LABEL_NAME: 'Danh mục',
	PLACEHOLDER_INPUT_NAME: 'Nhập tên cho danh mục',
	LABEL_TYTPE: 'Loại',
	LABEL_SELECT_TYPE: 'Chọn loại cho danh mục',
	OPTION_ONLINE_COURSE: 'Khoá học Online',
	OPTION_OFFLINE_COURSE: 'Khoá học Offline',
	OPTION_EBOOK: 'Ebook',
	MSG_CREATING: 'Đang thực hiện',
	MSG_CREATE_SUCCESS: 'Tạo danh mục thành công!'
}
const CATEGORY_DETAIL = {
	LINK_RETURN_TO_CATEGORY_LIST: 'Danh sách Danh mục',
	LINK_RETURN_TO_CATEGORY_PARENT_DETAIL: 'Chi tiết Danh mục cha',
	TITLE_EDIT_CATEGORY: 'Chỉnh sửa danh mục',
	TITLE_EDIT_CATEGORY_CHILD: 'Chỉnh sửa danh mục con',
	EDIT_BASIC_INFO: {
		TITLE: 'Thông tin cơ bản',
		LABEL_CATEGORY_NAME: 'Tên danh mục',
		MSG_CHECK_AND_INPUT_CATEGORY_NAME: 'Vui lòng nhập tên danh mục!'
	},
	TABLE_CHILD: {
		TITLE: 'Quản lý danh mục con',
		TH_CATEGORY: 'Danh mục',
		TH_UPDATE: 'Cập nhật',
		TH_ACTION: 'Thao tác',
		TOOLTIP_EDIT_CHILD_CATEGORY: 'Chỉnh sửa danh mục con',
		MSG_CREATE_SUCCESS: 'Tạo danh mục thành công !',
		MSG_DELETING_CATEGORY: 'Xoá danh mục ...',
		MSG_DELETE_SUCCESS: 'Xóa danh mục thành công',
		POPUP_CONFIRM_DELETE_CATEGORY_TITLE:
			'Bạn có chắc chắn muốn xoá danh mục con này?'
	},
	ADD_COURSE_TOPIC: {
		TITLE: 'Quản lý khóa học trong Danh mục',
		LABEL_NAME: 'Tên khoá học',
		PLACEHOLDER_SELECT_COURSE: 'Chọn khoá học muốn thêm vào danh mục',
		MSG_ADDING: 'Thêm khóa học...',
		MSG_REMOVING: 'Gỡ bỏ khóa học...',
		POPUP_CONFIRM_DELETE_TITLE:
			'Bạn có chắc muốn gỡ khóa học khỏi danh mục này?'
	},
	TABLE_COURSE: {
		TH_COURSE: 'Tên khóa học',
		TH_UPDATE: 'Cập nhật',
		TH_ACTION: 'Thao tác',
	},
	MSG_PROCESSING: 'Đang thực hiện...',
	MSG_UPDATE_COURSE_SUCCESS: 'Cập nhật khoá học thành công!'
}
//TẬP LƯU TRỮ
const LIBRARY_LIST = {
	TITLE: 'Thư viện',
	TAB_VIDEO: 'Video',
	TAB_PDF: 'PDF',
	TAB_AUDIO: 'AUDIO',
	VIDEO_PANEL: {
		LABEL_NAME: 'Tên video',
		PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên',
		CONFIRM_UPLOAD_MULTIPLE_FILES: 'Xác nhận tải tệp tin',
	},
	PDF_PANEL: {
		LABEL_NAME: 'Tên PDF',
		PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên'
	},
	AUDIO_PANEL: {
		LABEL_NAME: 'Tên audio',
		PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên'
	},
	//UPLOAD_DIALOG
	BUTTON_UPLOAD: 'Tải lên',
	TITLE_UPLOAD_FILE: 'Tải file lên',
	TITLE_EDIT_DIALOG: {
		TITLE: 'Đổi tên file',
		LABEL_FILE_NAME: 'Tên file',
		PLACEHOLDER_INPUT_FILE_NAME: 'Nhập tên file'
	},
	TH_NAME: 'Tên',
	TH_FORMAT: 'Định dạng',
	TH_UPLOAD_AT: 'Ngày tải lên',
	TH_CAPACITY: 'Dung lượng',
	TH_ACTION: 'Thao tác',
	TEXT_NOT_NAME: 'Không có tên',
	TITLE_FILE_NOT_NAME: 'File chưa có tên',

	POPUP_CONFIRM_DELETE_DATA_TITLE: 'Chắc chắc xoá dữ liệu ?',
	POPUP_CONFIRM_DELETE_DATA_MESSAGE:
		'Các nội dung liên kết với dữ liệu này sẽ bị xoá theo và không thể khôi phục'
}
//NGÂN HÀNG CÂU HỎI
const QUESTION_BANK_LIST = {
	TITLE: 'Ngân hàng câu hỏi',
	//TAB
	TAB_QUESTION_IN_SYSTEM: 'Câu hỏi trên hệ thống',
	TAB_TOPIC_AND_KNOWLEDGE: 'Chủ đề/Khung kiến thức',
	//STRUCTURE_KNOWLEDGE
	BUTTON_ADD_TOPIC: 'Thêm chủ đề',
	POPUP_CONFIRM_DELETE_TOPTIC_TITLE: 'Xoá chủ đề khỏi hệ thống',
	POPUP_CONFIRM_DELETE_TOPTIC_MESSAGE:
		'Dữ liệu sau khi xóa không thể khôi phục',
	MSG_CREATING_TOPIC: 'Đang tạo chủ đề ...',
	MSG_CHẸCK_TOPIC_AGAIN: 'Tên chủ đề không được để trống!',
	//EDIT_KNOWLEDGE
	TITLE_EDIT_TOPIC_INFO: 'Chỉnh sửa thông tin chủ đề',
	LABEL_TOPIC_NAME: 'Tên chủ đề',
	PLACEHOLDER_INPUT_TOPIC_NAME: 'Nhập tên chủ đề',
	//ADD_KNOWLEDGE
	LABEL_PARENT_TOPIC: 'Chủ đề cha',
	PLACHOLDER_PARENT_TOPIC: 'Chọn chủ đề cha'
}
const LIST_QUESTION = {
	FILTER: {
		LABEL_FILTER_BY_TOPIC: 'Lọc theo chủ đề',
		PLACEHOLDER_SELECT_TOPIC: 'Chọn chủ đề',
		LABEL_FILTER_BY_CONTENT: 'Lọc theo nội dung',
		PLACEHOLDER_FILTER_BY_CONTENT: 'Tìm kiếm câu hỏi theo nội dung'
	},
	LIST_ACTION_WITH_EXCEL: {
		LABEL_QUESTION: 'Câu hỏi',
		LABEL_QUESTION_TOPIC: 'Chủ đề câu hỏi',
		BUTTON_SELECT_FILE: 'Chọn file',
		OPTION_IMPORT_EXCEL: 'Nhập file Excel',
		OPTION_DOWNLOAD_FILE_EXCEL: 'Download file mẫu',
		LABEL_CONTENT: 'Nội dung',
		LABEL_ANWSER: 'Câu trả lời',
		TH_TYPE:
		'Kiểu ("single-choice": Trắc nghiệm một đáp án, "multiple-choice": Trắc nghiệm nhiều đáp án, "fill-blank": Điền vào chỗ trống)',
		TH_QUESTION_TYPE: 'Kiểu',
		TH_MULTIPLE_CHOICE: 'Trắc nghiệm nhiều đáp án',
		TH_FILL_BLANK: 'Điền vào ô trống',
		TH_ANWSER_TRUE: 'Đáp án',
		TH_LEVELS: 'Cấp độ',
		MSG_CHECK_QUESTION_TYPE_AGAIN: 'Sai kiểu câu hỏi, vui lòng kiểm tra lại',
		MSG_ANWSER_ERRO:
			'Câu hỏi lựa chọn bắt buộc phải có đáp án, vui lòng kiểm tra lại file vừa tải lên!'
	},
	TH_CONTENT: 'Nội dung',
	TH_TOPIC: 'Chủ đề',
	TH_LEVEL: 'Cấp độ',
	TH_TYPE: 'Dạng',
	TH_ACTION: 'Thao tác',
	TOOLTIP_EDIT_QUESTION: 'Chỉnh sửa câu hỏi',
	TEXT_QUESTIONS_ON_AVAILABLE_SUBJECTS: 'Câu hỏi thuộc đề có sẵn',

	POPUP_CONFIRM_DELETE_TITLE: 'Xác nhận xoá',
	POPUP_CONFIRM_DELETE_MESSAGE: 'Bạn có chắn muốn xóa câu hỏi này ?',

	BUTTON_SINGLE_CHOICE_PDF: 'Trắc nghiệm một đáp án',
	BUTTON_MULTIPLE_CHOICE_PDF: 'Trắc nghiệm nhiều đáp án',
	BUTTON_FILL_BLANK: 'Điền từ vào chỗ trống',

	EDIT_QUESTION_INFO_DIALOG: {
		TITLE: 'Chỉnh sửa thông tin câu hỏi',
		LABEL_INPUT_DESCRIPTION: 'Nhập mô tả ...',
		LABEL_INPUT_CONTENT_OF_ANWSER: 'Nhập câu trả lời ...',
		LABEL_INPUT_ANWSER: 'Nhập đáp án ...',
		LABEL_LEVEL_QUESTION: 'Cấp độ câu hỏi',
		PLACEHOLDER_INPUT_LEVEL_QUESTION: 'Nhập cấp độ câu hỏi',

		TAB_CONTENT: 'Nội dung',
		TAB_ANSWER: 'Trả lời & Đáp án',
		TAB_ADVANCED: 'Kiến thức liên quan',
		TAB_EXPLAIN: 'Giải thích',

		POPUP_CONFIRM_TITLE: 'Xác nhận chỉnh sửa',
		POPUP_CONFIRM_MESSAGE: 'Câu hỏi sẽ được thay đổi sau khi xác nhận. Nếu bạn đã chắc chắn hãy bấm vào nút lưu bên dưới để tiếp tục.',
		BUTTON_CONFIRM: 'Lưu',
		BUTTON_CANCEL: 'Hủy',
		MSG_UPDATE_SUCCESS: 'Cập nhật thành công !'
	},
	EDIT_ALL_QUESTION_INFO_DIALOG: {
		TITLE_EDIT_ALL_QUESTION: 'Chỉnh sửa hàng loạt thông tin câu hỏi',
		LABEL_LEVEL_QUESTION: 'Cấp độ câu hỏi',
		LABEL_INPUT_LEVEL_QUESTION: 'Nhập cấp độ câu hỏi'
	},
	POPUP_CONFIRM_DELETE_MANY_QUESTION_TITLE: 'Xoá nhiều câu hỏi',
	POPUP_CONFIRM_DELETE_MANY_QUESTION_MESSAGE:
		'Dữ liệu sau khi xóa không thể khôi phục. Bạn có chắc chắn muốn xóa bài học này?'
}
//KHO ĐỀ THI
const QUESTION_GENERATE = {
	TITLE: 'Kho đề thi',
	LABEL_SELECT_COURSE: 'Chọn khoá học',
	PLACEHOLDER_FILTER_COURSE: 'Tìm kiếm khoá học',

	LABEL_SELECT_MATRIX: 'Chọn bài giảng ma trận',
	PLACEHOLDER_FILTER_MATRIX: 'Tìm kiếm bài giảng ma trận',
	MSG_CHECK_LESSON_AGAIN: 'Bài học không tồn tại bài kiểm tra',

	LABEL_EXAM_UNIT: 'Đề thi số',
	LABEL_EXAM_CODE: 'Mã đề',
	TH_EXAM_NAME: 'Tên đề thi',
	TH_ACTION: 'Thao tác',
	POPUP_CONFIRM_DELETE_TITLE: 'Xoá đề thi',
	POPUP_CONFIRM_DELETE_MESSAGE:
		'Thao tác này sẽ xóa bỏ đề thi ra khỏi hệ thống và học sinh sẽ không nhận được đề thi này',
	MSG_DELETE_SUCCESS: 'Xoá đề thi thành công',
	POPUP_DETAIL: {
		TITLE: 'Chi tiết đề thi',
		LABEL_QUESTION_UNIT: 'Câu'
	}
}
//CƠ SỞ
const DEPARTMENT_LIST = {
	TITLE: 'Cơ sở',
	BUTTON_CREATE: 'Tạo cơ sở',
	LABEL_FILTER_BY_NAME: 'Tên cơ sở',
	PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên',
	//TABLE
	TH_NAME: 'Tên cơ sở',
	TH_ACTION: 'Thao tác',
	TOOLTIP_EDIT_DEPARTMENT: 'Chỉnh sửa cơ sở',
	//EMPTY_DATA
	TITLE_EMPTY_DATA: 'Chưa tồn tại cơ sở nào',
	SUBTITLE_EMPTY_DATA: 'Vui lòng tạo mới cơ sở để hiển thị trên danh sách này',

	POPUP_CONFIRM_DELETE_TITLE: 'Bạn có chắc chắn xoá cơ sở này không?',
	MSG_DELETE_SUCCESS: 'Xoá cơ sở thành công'
}
const FORM_CREATED_NEW_DEPARTMENT = {
	TITLE: 'Tạo cơ sở',
	LABEL_NAME: 'Tên cơ sở',
	PLACEHOLDER_INPUT_NAME: 'Nhập tên cơ sở',
	MSG_CREATING: 'Đang tạo cở sở ...',
	MSG_CREATE_SUCCESS: 'Tạo cơ sở thành công!'
}
const DEPARTMENT_DETAIL = {
	LINK_RETURN_TO_DEPARTMENT_LIST: 'Danh sách cơ sở',
	TITLE: 'Sửa cơ sở',
	TITLE_INFOS: 'Thông tin cơ sở',
	LABEL_NAME: 'Tên cơ sở',
	PLACEHOLDER_INPUT_NAME: 'Nhập tên cơ sở',
	TITLE_IMAGE: 'Ảnh hiển thị',
	LABEL_SIZE_IMAGE: 'Kích thước khuyên dùng: 300x225px',

	MSG_GETTING_DATA: 'Đang lấy dữ liệu',
	MSG_UPDATING_DATA: 'Đang cập nhật dữ liệu',
	MSG_UPDATE_SUCCESS: 'Cập nhật dữ liệu thành công'
}
//BÀI KIỂM TRA ĐƯỢC PHÂN CÔNG
const ASSIGNED_HOMEWORK_LIST = {
	TITLE: 'Danh sách bài kiểm tra',
	LABEL_SELECT_COURSE: 'Chọn khoá học',
	OPTION_ALL: 'Tất cả',
	LABEL_FILTER_TEST_BY_REQUEST: 'Chọn bài kiểm tra theo yêu cầu',
	//TABLE_HEADER
	TH_COURSE: 'Khoá học',
	TH_UNIT_TITLE: 'Bài kiểm tra',
	TH_HOMEWORK_COUNT: 'Số bài nộp',
	TH_IS_ALL_HOMEWORK_MARKER: 'Đã chấm hết',
	TH_ACTION: 'Thao tác'
}
const ASSIGNED_HOMEWORK_DETAIL = {
	LINK_RETURN_TO_EXAM_LIST: 'Danh sách bài kiểm tra',
	BUTTON_VIEW_REQUIMENT: 'Xem đề bài',
	FILTER: {
		LABEL_STATUS: 'Trạng thái',
		PLACEHOLDER_STATUS: 'Trạng thái bài nộp',
		OPTION_STATUST_ALL: 'Tất cả',
		OPTION_NO_SCORE: 'Chưa chấm',
		OPTION_APPROVED: 'Đã duyệt',
		LABEL_SUBJECTS_CODE: 'Mã bài nộp',
		PLACEHOLDER_INPUT_SUBJECTS_CODE: 'Nhập mã bài nộp',
		BUTTON_FILTER_AND_VIEW: 'Lọc và xem'
	},
	BUTTON_DOWNLOAD_ALL_EXCEL: 'Tải tất cả',
	ENTITY_EXERCISE_STUDENT: 'Bài tập của học viên',
	MSG_GROUP_STUDENT_DOES_NOT_EXIST: 'Nhóm không tồn tại',
	MSG_COMPRESSING: 'Đang nén...',
	MSG_COMPRESSING_TO: 'Đang nén bài tập của học sinh thứ',

	BUTTON_DOWLOAD_FILE_SELECTED: 'Tải file đã chọn',
	TOOLTIP_TEACHER_ACCEPT_LAST_LESSON: 'Giảng viên chấm bài lần cuối',
	TOOLTIP_TIME_ACCEPT_LAST_LESSON: 'Thời gian chấm bài lần cuối',
	//TABLE_HEADER
	TH_SUBMIT_CODE: 'Mã nộp bài',
	TH_TIME_SUBMIT: 'Thời gian nộp bài',
	TH_STATUS: 'Trạng thái',
	TH_MARK: 'Điểm',
	TH_TEACHER: 'Giảng viên',
	TH_POINTING_TIME: 'Thời gian chấm điểm',
	TH_ACTION: 'Thao tác',
	TEXT_NO_SCORE: 'Chưa chấm',
	TEXT_SUBMITTED: 'Đã nộp bài',
	TEXT_APPROVED: 'Đã duyệt',
	TEXT_REFUSE: 'Từ chối',
	TEXT_UNSUBMIT: 'Chưa nộp',
	TOOLTIP_COPY_SUBJECTS: 'Sao chép bài nộp',
	MSG_COPY_SUCCESS: 'Sao chép thành công',
	MSG_COPY_FAIL: 'Sao chép không thành công. Vui lòng thử lại',
	POPUP_VIEW_UNIT_REQUIREMENT: {
		TITLE: 'Đề bài',
		TITLE_INTRODUCT: 'Giới thiệu',
		TITLE_REQUIREMENT_OF_EXERCISE: 'Yêu cầu của bài tập'
	},
	ARCHIVE_BY_CHOICE: {
		ENTITY_EXERCISE_STUDENT: 'Bài tập của học viên'
	},
	MSG_ACCEPT_EXERCISE_SUCCESS: 'Duyệt bài tập thành công',
	MSG_SAVE_EDIT_SUCCESS: 'Lưu bài chữa thành công!'
}
//
const MODAL_CREATE_AUTHOR = {
	TITLE_ADD_NEW_TEACHER: 'Thêm nhanh giảng viên mới',
	LABEL_TEACHER_NAME: 'Tên giáo viên',
	PLACEHOLDER_INPUT_TEACHER_NAME: 'Nhập tên giáo viên',
	LABEL_EMAIL: 'Email',
	PLACEHOLDER_INPUT_TEACHER_EMAIL: 'Nhập email giáo viên',
	TITLE_EDIT_TEACHER_IN: 'Xem và chỉnh sửa chi tiết Giảng viên tại',
	LINK_TEACHER_LIST: 'Danh sách Giảng viên',
	MSG_CREATEING_TEACHER: 'Đang tạo giảng viên...',
	MSG_CREATED_TEACHER: 'Đã tạo giảng viên mới',
	MSG_RECHECK_EMAIL_TEACHER:
		'Email giảng viên đã được sử dụng, vui lòng nhập email khác!',
	MSG_CREATE_TEACHER_FAIL: 'Tạo giảng viên thất bại!'
}
//
const MODAL_CREATE_TOPIC = {
	TITLE_ADD_NEW: 'Thêm nhanh chủ đề mới',
	LABEL_CATEGORY_HEADER: 'Tiêu đề danh mục',
	LABEL_INPUT_CATEGORY_HEADER: 'Nhập tiêu đề danh mục',
	TITLE_EDIT_CATEGORY_DETAIL_IN: 'Xem và chỉnh sửa chi tiết danh mục tại',
	LINK_GO_TO_CATEGORY_LIST: 'Danh sách danh mục',
	MSG_CREATING_CATEGORY: 'Đang tao danh mục...',
	MSG_CREATED_NEW_CATEGPRY: 'Đã tạo danh mục mới!',
	MSG_CREATE_CATEGORY_FAIL: 'Tạo danh mục thất bại!'
}

export default {
	LEARNING_PATH_LIST,
	FORM_CREATE_NEW_LEARNING_PATH,
	LEARNING_PATH_DETAIL,
	EVENT_LIST,
	FORM_CREATE_NEW_EVENT,
	EVENT_DETAIL,
	EBOOK_LIST,
	EBOOK_DETAIL,
	CATEGORY_LIST,
	CATEGORY_CREATE_NEW_FORM,
	CATEGORY_DETAIL,
	LIBRARY_LIST,
	QUESTION_BANK_LIST,
	QUESTION_GENERATE,
	DEPARTMENT_LIST,
	FORM_CREATED_NEW_DEPARTMENT,
	DEPARTMENT_DETAIL,
	MODAL_CREATE_AUTHOR,
	MODAL_CREATE_TOPIC,
	ASSIGNED_HOMEWORK_LIST,
	ASSIGNED_HOMEWORK_DETAIL,

	LIST_QUESTION,
	PLACEHOLDER_SELECT_TOPIC: 'Chọn chủ đề',
	LABEL_TOPIC: 'Chủ đề',
	LABEL_QUESTION: 'Câu hỏi',
	LABEL_SELECT_ALL: 'Chọn tất cả',
	BUTTON_SEARCH: 'Tìm kiếm',
	BUTTON_CONFIRM: 'Xác nhận',
	BUTTON_CANCEL: 'Huỷ bỏ',
	BUTTON_SAVE: 'Lưu',
	BUTTON_CANCEL_TEXT: 'Huỷ',
	BUTTON_CREATE: 'Tạo',
	BUTTON_UPDATE: 'Cập nhật',
	BUTTON_DELETE: 'Xoá',
	BUTTON_OK: 'OK',
	TITLE_ACTION: 'Thao tác',
	BUTTON_ADD: 'Thêm',

	//MESSAGE
	MSG_DELETING: 'Đang xoá...',
	MSG_UPDATING_DATA: 'Đang cập nhật dữ liệu ...',
	MSG_UPDATE_DATA_SUCCESS: 'Cập nhật dữ liệu thành công !',
	MSG_UPDATE_DATA_FAIL: 'Cập nhật dữ liệu thất bại',
	MSG_CREATE_SUCCESS: 'Tạo thành công',
	MSG_DELETE_DATA_SUCCESS: 'Xoá dữ liệu thành công!',
	MSG_DELETE_DATA_FAIL: 'Xoá dữ liệu thất bại!',
	MSG_UPDATING: 'Đang cập nhật ...',
	MSG_UPDATE_SUCCESS: 'Cập nhật thành công!',
	MSG_UPDATE_FAIL: 'Cập nhật thất bại !',
	MSG_DELETE_SUCCESS: 'Xoá thành công !',
	MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin!',
	MSG_GETTING_DATA:"Đang lấy dữ liệu...",

	TITLE_EMPTY_DATA: 'Chưa tồn tại danh mục cho {0} nào',
}
