<template>
	<div class="navbar-spacing pa-5">
		<EditSiteInfo
			v-if="selected === 'common'"
			:viewData="viewData"
			ref="common"
		/>
		<EditPageInfo
			v-else
			:viewData="viewData"
			:ref="`${selected}`"
			:isSlickDisabled="true"
			:isMobile="true"
		/>
	</div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import differenceWith from 'lodash-es/differenceWith'
import isEqual from 'lodash-es/isEqual'
import clone from 'lodash-es/clone'
import EditPageInfo from '@/modules/SiteBuilder/components/EditPageInfo'
import EditSiteInfo from '@/modules/SiteBuilder/components/EditSiteInfo'
export default {
	props: {
		selected: String,
		reloadIframe: Function
	},
	computed: {
		...mapGetters({
			viewHomePage: 'vendor/getMobileHomepage'
		})
	},
	components: {
		EditSiteInfo,
		EditPageInfo
	},
	data() {
		return {
			viewData: null
		}
	},
	methods: {
		...mapActions({
			updateMobileTheme: 'vendor/updateMobileTheme'
		}),
		setViewData() {
			if (this.selected === 'homepage') {
				this.viewData = clone(this.viewHomePage, true)
			}
		},
		getCurrentViewData() {
			if (this.selected === 'homepage') return this.viewHomePage
		},
		async checkChangeData() {
			const newData = await this.$refs[`${this.selected}`].getData()
			const oldData = await this.getCurrentViewData()
			const diff = await differenceWith(newData, oldData, isEqual)
			if (!this.viewData || !diff.length) {
				return false
			} else {
				return true
			}
		},
		async onSave() {
			const data = await this.$refs[`${this.selected}`].getData()
			let payload = {}
			if (this.selected === 'homepage') payload = {viewData: data}
			try {
				await this.updateMobileTheme(payload)
				this.$message.success(this.$vuetify.lang.t('$vuetify.SITE_BUILDER.MSG_UPDATE_SUCCESS'))
			} catch (error) {
				console.error(error)
				this.$message.error(error)
			}
		}
	},
	watch: {
		viewHomePage: {
			handler(val) {
				if (val) this.setViewData()
			},
			immediate: true
		}
	}
}
</script>
<style lang="scss" scoped>
.navbar-spacing {
	margin-top: 72px;
}
</style>
