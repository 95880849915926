<template>
  <v-dialog v-model="dialog" width="620px">
    <v-card>
      <v-card-title class="headline lighten-2" primary-titledialog>
        <!-- {{
          $vuetify.lang.t(
            type === 'email' ? '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TITLE_POPUP_EMAIL_SENT': '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TITLE_POPUP_NOTIFICATION_SENT'
          )
        }} -->
        {{ title }}
        <v-spacer></v-spacer>
        <v-icon @click="() => (dialog = !dialog)"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text>
        <p class="font-weight-medium my-1" style="color: #3e3e3c">
          {{
            $vuetify.lang.t(
              '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.NOTIFICATION_TO'
            )
          }}
          <span style="color: #255cc2">{{ name }}</span>
        </p>
        <p class="my-1">{{ email }}</p>
        <p class="my-3">
          <span style="color: #4bca81">
            {{
              $vuetify.lang.t(
                '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.NOTIFICATION_SUCCESS'
              )
            }}
          </span>
          vào
          <span>{{ date }}</span>
        </p>
        <p v-html="content"></p>
        <p class="my-1" v-if="linkWeb">
          Web
          <a style="color: #255cc2">{{ linkWeb }}</a>
        </p>
        <p class="my-1" v-if="linkMobile">
          Mobile App
          <a style="color: #255cc2">{{ linkMobile }}</a>
        </p>
      </v-card-text>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <BaseButton
          outlined
          @click="dialog = false"
          :text="
            $vuetify.lang.t(
              '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_CLOSE'
            )
          "
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    type: String,
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    linkWeb: {
      type: String,
      default: '',
    },
    linkMobile: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
  },
}
</script>
<style lang="scss" scoped>
p {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
