import Repository from '@/api/core'
import utils from '@/helpers/utils.js'
const baseURL = process.env.VUE_APP_API_PAYMENT + '/bill-statistic'

const fetch = params => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
  return Repository(baseURL).fetchOne(id)
}

const count = params => {
  return Repository(baseURL + '/count').fetch({ ...utils.cleanObject(params) })
}

const report = params => {
  return Repository(baseURL + '/report').fetch({ ...utils.cleanObject(params) })
}

const graph = params => {
  return Repository(baseURL + '/graph').fetch({ ...utils.cleanObject(params) })
}


export default {
  fetch,
  fetchOne,
  count,
  report,
  graph
}
