<template>
  <div class="cursor-pointer relative">
    <div class="absolute label-text-area">{{ label }}</div>
    <div class="text-area" @click="isOpenEditorDialog = true" v-html="html"></div>
    <EditorDialog
      :content="html"
      :isOpen="isOpenEditorDialog"
      @close="isOpenEditorDialog = false"
      @accept="handleAccept"
    />
  </div>
</template>
<script>
import EditorDialog from '@/modules/QuestionBank/components/EditQuestionInfoDialog/EditorDialog.vue'
export default {
  components: {
    EditorDialog,
  },
  props: {
    html: String,
    label: String,
  },
  data() {
    return {
      isOpenEditorDialog: false,
    }
  },
  methods: {
    handleAccept(value) {
      this.$emit('change', value)
      this.isOpenEditorDialog = false
    },
  },
}
</script>
<style lang="scss" scoped>
.text-area {
  width: 100%;
  padding: 12px;
  border: 1px solid #acabab;
  border-radius: 4px;
  min-height: 30px;
}
.label-text-area {
  top: -12px;
  left: 11px;
  background: white;
  padding: 0px 4px 0px 4px;
}
</style>
