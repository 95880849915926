const state = {
  menuSectionIndex: -1,
}

const actions = {
  setMenuSectionIndex({commit}, currentIndex = 0) {
    commit('setMenuSectionIndex', currentIndex)
  },
}

const mutations = {
  setMenuSectionIndex(state, currentIndex) {
    state.menuSectionIndex = currentIndex
  },
}

const getters = {
  getMenuSectionIndex: (state) => {
    return state.menuSectionIndex
  },
}

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}