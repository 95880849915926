const DOMAIN_SETTING_FORM = {
	TITLE: "Tên miền trang",
	SUBTITLE: "Vui lòng liên hệ với hỗ trợ khách hàng để đổi tên miền",
}
const BASIC_SETTINGS = {
	TITLE: "Cài đặt cơ bản",

	TAB_BRANDING: "Thương hiệu",
	TAB_EMAIL: "Cấu hình email",
	TAB_TRANSACTION_PAYMENT: "Giao dịch & Thanh toán",

	//TRADE_MARK
	TM_TITLE_DOMAIN_NAME: "Tên miền trang",
	TM_SUBTITLE_DOMAIN_NAME: "Vui lòng liên hệ với hỗ trợ khách hàng để đổi tên miền",

	TM_TITLE_ADDRESS_EMAIL: "Địa chỉ Email",
	TM_LABEL_EMAIL_PAGE: "Email trang",
	TM_PLACEHOLDER_EMAIL_PAGE: "VD: tuannhhe@gmail.com",
	TM_HINT_EMAIL_PAGE: "Học viên sẽ nhận được mã kích hoạt và thông báo từ địa chỉ email này.",
	TM_LABEL_PASSWORD: "Mật khẩu",
	TM_PLACEHOLDER_PASSWORD: "VD: ******",
	TM_HINT_PASSWORD: "Hệ thống cần mật khẩu email để truy cập và thiết lập chế độ tự động gửi. Mọi thắc mắc về vấn đề bảo mật xin liên hệ với bộ phận hỗ trợ khách hàng.",

	TM_TITLE_EMAIL_PAGE: "Email trang",
	TM_SUBTITLE_EMAIL_PAGE: "Học viên sẽ nhận được mã kích hoạt và thông báo từ địa chỉ email này.",

	TM_TITLE_CHANGE_ADMIN_PAGE_NAME: "Đổi tên trang Admin",
	TM_LABEL_LABEL_PAGE_NAME: "Tên trang",
	TM_HINT_PAGE_NAME: "Khuyến nghị: Tên trang không nên viết nhiều hơn 20 ký tự",

	TM_TITLE_SETTING_LINK_SOCIAL_NETWORK: "Cài đặt đường dẫn mạng xã hội",
	TM_LABEL_LINK_FACEBOOK: "Đường dẫn facebook",
	TM_LABEL_LINK_YOUTUBE: "Đường dẫn youtube",
	TM_LABEL_LINK_TIKTOK: "Đường dẫn tiktok",
	TM_LABEL_LINK_INSTAGRAM: "Đường dẫn instagram",
	TM_PLACEHOLDER_ENTER_LINK: "Nhập đường dẫn",

	TM_BUTTON_RESET: "Mặc định",
	TM_BUTTON_ENTER_EXPORT_HTML: "Nhập/xuất HTML",
	TM_BUTTON_SAVE: "Lưu",

	//TRANSACTON AND PAY
	TP_TITLE_TRANSFER_INFO: "Thông tin chuyển khoản",
	TP_SUBTITLE_TRANSFER_INFO: "Thông tin chuyển khoản sẽ được hiển thị trong phần thông tin Chuyển khoản - Thanh toán khóa học.",

	TP_LABEL_BANK_NAME: "Tên ngân hàng",
	TP_PLACEHOLDER_BANK_NAME: "VD: Example",
	TP_LABEL_ACCOUNT_NAME: "Tên tài khoản",
	TP_PLACEHOLDER_ACCOUNT_NAME: "VD: Nguyễn Văn A",

	TP_LABEL_ACOUNT_NUMBER: "Số tài khoản",
	TP_PLACEHOLDER_ACOUNT_NUMBER: "VD: 00123212312312",

	TP_LABEL_TRANSFER_CONTENT_FORM: "Nội dung chuyển khoản mẫu",
	TP_PLACEHOLDER_TRANSFER_CONTENT_FORM: "VD: Nguyen Van A - Lớp 12D - Toan hình 12",
	HINT_TRANSFER_CONTENT: "Có thể dùng Nội dung chuyển khoản mẫu để gợi ý cho học viên nhập thông tin dễ xác minh.",

	TP_TITLE_VNPAY_CONFIG: "Cấu hình",
	TP_SUBTITLE_VNPAY_CONFIG: "Cấu hình hệ thống thanh toán VNPay",
	TP_LABEL_VNP_TMNCODE: "vnp_TmnCode",
	TP_PLACEHOLDER_VNP_TMNCOD: "VD: Example",
	TP_LABEL_VNP_HASHSECRET: "vnp_HashSecret",
	TP_PLACEHOLDER_VNP_HASHSECRET: "VD: JKLWEUPOCXMNWHKSDJ",

	//TEMPLATE TITLE
	TT_REGISTER_EMAIL: "Email đăng kí tài khoản",
	TT_FORGOT_PASSWORD_EMAIL: "Email quên mật khẩu",
	TT_CHANGE_PASSWORD_EMAIL: "Email đổi mật khẩu",
	TT_APPROVED_COURSE_EMAIL: "Email xác nhận đã sở hữu khóa học",

	//BUTTON
	BUTTON_RESET: "Mặc định",
	BUTTON_SAVE: "Lưu",
	BUTTON_ENTER_EXPORT_HTML: "Nhập/xuất HTML",

	//CHECKBOX
	CHECKBOX_APPEAR: "Hiện",
	CHECKBOX_HIDE: "Ẩn",

	//RADIUS
	RADIUS_APPEAR: "Hiện",
	RADIUS_HIDE: "ẨN",

	//MESSAGE
	MSG_UPDATE_LOADING: "Đang cập nhật ...",
	MSG_UPDATE_ACCOUNT_LOADING: "Đang cập nhật tài khoản ...",
	MSG_UPDATE_PAGE_NAME_LOADING: "Đang cập nhật tên trang ...",
	MSG_CHECK_PAGE_NAME: "Vui lòng nhập tên trang",
	MSG_CHECK_INFO_AGAIN: "Vui lòng kiểm tra lại thông tin !",
	MSG_CHECK_FULL_INFO_AGAIN: "Vui lòng nhập đầy đủ thông tin thông tin !",
	MSG_UPDATE_ACCOUNT_SUCCESS: "Cập nhật tài khoản thành công!",
	MSG_UPDATE_SUCCESS: "Cập nhật thành công",
	MSG_UPDATE_FAILURE: "Cập nhật thất bại",
	MSG_UPDATE_DATA_FAILURE: "Cập nhật dữ liệu thất bại",
	MSG_UPDATE_DATA_SUCCESS: "Cập nhật thành công",
	MSG_UPDATE_DATA_FAIL: "Cập nhật thành công",

	PLACEHOLDER_SELECT_FILE_UPLOAD: "Chọn file và tải lên",
}
const ADVANCED_SETTING = {
	TITLE: "Cài đặt nâng cao",

	TAB_VIRTUAL_CLASS_ZOOM: "PHÒNG HỌC ẢO (ZOOM)",
	TAB_USER_INTERACTION: "Tương tác người dùng",
	TAB_CODE_DEPOSIT_AND_FOLLOW: "MÃ NHÚNG & THEO DÕI",

	LABEL_ZOOM_NOTE: "Nay bạn đã có thể liên kết Phòng học ảo Zoom của bản thân với hệ thống. Sắp xếp danh sách phòng học Zoom thông qua nút dưới đây.",
	LABEL_ZOOM_ACTIVATED: "Kích hoạt Zoom và hiển thị nút Zoom trên trang chủ",

	//VIRTUAL CLASS ZOOM
	VCZ_LABEL_NOTE_OF_ZOOM: "Nay bạn đã có thể liên kết Phòng học ảo Zoom của bản thân với hệ thống. Sắp xếp danh sách phòng học Zoom thông qua nút dưới đây.",
	VCZ_LABEL_ACTIVE_ZOOM_AND_DISBPAY_BUTTON_ZOOM: "Kích hoạt Zoom và hiển thị nút Zoom trên trang chủ ",
	BUTTON_MANAGE_ZOOM_CLASS: "Quản lý phòng học Zoom",

	//DEPOSIT & FOLLOW
	DF_PLACEHOLDER_ENTER_CODE_NAME: "Nhập tên mã",
	DF_TITLE_NEW_SCRIPT_CODE: "Mã Javascript mới",
	DF_SUBTITLE_NEW_SCRIPT_CODE: "Mã nhúng thường tiềm ẩn nguy cơ về lỗ hổng bảo mật. Hệ thống không chịu trách nhiệm với những trường hợp bị mất dữ liệu.",
	DF_DROPDOWN_LABEL_PLACE_CODE: "Vị trí mã",

	//USER INTERACTION
	//TƯƠNG TÁC NGƯỜI DÙNG



	//BUTTON
	BUTTON_ADD_NEW_SCRIPT: "Thêm Script mới",
	BUTTON_REMOVE_SCRIPT_FORM: "Xoá",
	BUTTON_SAVE_SCRIPT_FORM: "Lưu",

	//RADIUS
	RADIUS_APPEAR: "Hiện",
	RADIUS_HIDE: "Ẩn",

	//MASSAGE
	MSG_UPDATE_DATA_LOADING: "Đang cập nhật dữ liệu...",
	MSG_UPDATE_DATA_SUCCESS: "Cập nhật dữ liệu thành công",

}
const EMAIL_ADDRESS_SETTING_FORM = {
	TITLE: 'Địa chỉ Email',
	LABEL_EMAIL_ADDRESS: 'Email trang',
	PLACEHOLDER_EMAIL_ADDRESS: 'VD: tuannhhe@gmail.com',
	HINT_EMAIL_ADDRESS:
		'Học viên sẽ nhận được mã kích hoạt và thông báo từ địa chỉ email này.',
	LABEL_PASSWORD: 'Mật khẩu',
	PLACEHOLDER_PASSWORD: 'VD: ******',
	HINT_PASSWORD:
		'Hệ thống cần mật khẩu email để truy cập và thiết lập chế độ tự động gửi. Mọi thắc mắc về vấn đề bảo mật xin liên hệ với bộ phận hỗ trợ khách hàng.',
	MSG_UPDATING: 'Đang cập nhật tài khoản ...',
	MSG_UPDATED: 'Cập nhật tài khoản thành công!',
	MSG__CHECK_FULL_INFO: "Vui lòng nhập đầy đủ thông tin thông tin !",

}
const EMAIL_CONTENT_SETTING_FORM = {
	TITLE: 'Tùy chỉnh nội dung email',
	SUBTITLE:
		'Hệ thống đã có sẵn các mẫu email mặc định được gửi đến người dùng. Nếu muốn thay đổi nội dung email, vui lòng chọn “Chỉnh sửa”. Để trở lại email ban đầu, vui lòng chọn “Mặc định”.',
	//LIST TEMPLATE EMAIL
	TITLE_REGISTTRATION: 'Email xác nhận đăng kí tài khoản',
	TITLE_FORGOT_PASSWORD: 'Email xác nhận quên mật khẩu',
	TITLE_CHANGE_PASSWORD: 'Email xác nhận đổi mật khẩu',
	TITLE_COURSE_APPROVING: 'Email thông báo đã sở hữu khóa học',
	TEXT_HTML: "HTML TEXT",
	BUTTON_UPDATE_BY_TEMPLATE: 'Nhập/xuất HTML',
	BUTTON_IMPORT_TEMPLATE: 'Nhập file HTML',
	BUTTON_DOWNLOAD_TEMPLATE: 'Tải xuống file mẫu',
	BUTTON_CANCEL: "Huỷ bỏ",
	BUTTON_INSTRUCT: 'Hướng dẫn',

	POPUP_TITLE_RESET_EMAIL: 'Reset Email',
	POPUP_SUBTITLE_RESET_EMAIL:
		'Thao tác này sẽ xóa bỏ nội dung email bạn cài đặt bên dưới và thay vào bằng email mặc định.  Bạn có chắc muốn thực hiện ?',
	MSG_UPDATING: 'Đang cập nhật mẫu email ...',
	MSG_DOWLOAD: 'Đang tải ...'

}
const ADMIN_PAGE_NAME_SETTING_FORM = {
	TITLE: 'Đổi tên trang admin',
	SUBTITLE: 'Tên trên đường link truy cập',
	LABEL_PAGE_NAME: 'Tên trang',
	PLACEHOLDER_PAGE_NAME: 'VD: HA NOI EDUCATION',
	HINT_PAGE_NAME:
		'Khuyến nghị: Tên trang không nên viết nhiều hơn 20 ký tự',
	MSG_UPDATING: 'Đang cập nhật tên trang ...',
	MSG_PAGE_NAME_IS_REQUIRED: 'Vui lòng nhập tên trang',
}
const SOCIAL_LINKS_SETTING_FORM = {
	TITLE: 'Cài đặt đường dẫn mạng xã hội',
	LABEL_FACEBOOK: 'Đường dẫn facebook',
	LABEL_YOUTUBE: 'Đường dẫn youtube',
	LABEL_TIKTOK: 'Đường dẫn tiktok',
	LABEL_INSTAGRAM: 'Đường dẫn instagram',
	PLACEHOLDER_LINK: 'Nhập đường dẫn',
}
const BANKING_INFOS_FORM = {
	TITLE: 'Thông tin chuyển khoản',
	SUBTITLE:
		'Thông tin chuyển khoản sẽ được hiển thị trong phần thông tin Chuyển khoản - Thanh toán khóa học.',
	LABEL_BANK_NAME: 'Tên ngân hàng',
	PLACEHOLDER_BANK_NAME: 'VD: IZTEACH',
	LABEL_ACCOUNT_HOLDER_NAME: 'Tên tài khoản',
	PLACEHOLDER_ACCOUNT_HOLDER_NAME: 'VD: NGUYEN VAN A',
	LABEL_ACOUNT_NUMBER: 'Số tài khoản',
	PLACEHOLDER_ACOUNT_NUMBER: 'VD: 00123212312312',
	LABEL_TRANSFER_CONTENT_SAMPLE: 'Nội dung chuyển khoản mẫu',
	PLACEHOLDER_TRANSFER_CONTENT_SAMPLE:
		'VD: Nguyen Van A - Lớp 12D - Toan hình 12',
	HINT_TRANSFER_CONTENT_SAMPLE:
		'Có thể dùng Nội dung chuyển khoản mẫu để gợi ý cho học viên nhập thông tin dễ xác minh.',
	LABEL_WORKING_HINT: 'Ghi chú khi thanh toán',
	PLACEHOLDER_WORKING_HINT: 'Ghi chú hiển thị cho người mua khi chọn thanh toán qua chuyển khoản',
}
const ONLINE_PAYMENT_GATEWAY_SETTING_FORM = {
	TITLE: 'Cổng thanh toán trực tuyến',
	SUBTITLE: 'Cấu hình hệ thống thanh toán VNPay',
	LABEL_VNP_TMNCODE: 'vnp_TmnCode',
	PLACEHOLDER_VNP_TMNCODE: 'VD: IZTEACH',
	LABEL_VNP_HASHSECRET: 'vnp_HashSecret',
	PLACEHOLDER_VNP_HASHSECRET: 'VD: JKLWEUPOCXMNWHKSDJ',
	LABEL_WORKING_HINT: 'Ghi chú khi thanh toán',
	PLACEHOLDER_WORKING_HINT: 'Ghi chú hiển thị cho người mua khi chọn thanh toán qua VNPay',
	TOGGLE_SHOW_PAYMENT_OPTION: 'Hiện tuỳ chọn thanh toán qua VNPay',
	TOGGLE_HIDE_PAYMENT_OPTION: 'Ẩn tuỳ chọn thanh toán qua VNPay',
}
const DIRECT_PAY_SETTING_FORM = {
	TITLE: 'Thanh toán trực tiếp tại quầy',
	LABEL_WORKING_HINT: 'Ghi chú khi thanh toán',
	PLACEHOLDER_WORKING_HINT: 'Ghi chú hiển thị cho người mua khi chọn thanh toán trực tiếp tại quầy',
}
const ADVANCED_SETTINGS = {
	TITLE: 'Cài đặt nâng cao',
	TAB_VIRTUAL_CLASS_ZOOM: 'Phòng học ảo (Zoom)',
	TAB_EMBEDED_SCRIPT: 'Mã nhúng & theo dõi',
	TAB_USER_INTERACTION: 'Tương tác người dùng',
}
const VIRTUAL_CLASS_ZOOM_FORM = {
	SUBTITLE:
		'Tích hợp với hệ thống họp trực tuyến như Zoom để tạo các phòng học ảo và lên lịch cho học viên.',
	LABEL_ACTIVE_ZOOM:
		'Kích hoạt Zoom và hiển thị nút Zoom trên trang chủ',
	TOGGLE_SHOW_ZOOM_ACCESS_BUTTON: 'Hiện',
	TOGGLE_HIDE_ZOOM_ACCESS_BUTTON: 'Ẩn',
	BUTTON_MANAGE_ZOOM_CLASS: 'Quản lý phòng học Zoom',
}
const VIRTUAL_CLASS_LIST = {
	TITLE: 'Quản lý phòng học Zoom',
	LINK_RETURN_TO_ADVANCED_SETTINGS: 'Cài đặt nâng cao',
	BUTTUN_ADD_CLASS: 'Thêm buổi học Zoom',
	LABEL_FILTER_BY_STATUS: "Lọc theo trạng thái",
	PLACHOLDER_SELECT_STATUS: "Chọn trạng thái",
	LABEL_FILTER_BY_STUDENT_GROUP: 'Lọc theo nhóm',
	PLACEHOLDER_FILTER_BY_STUDENT_GROUP: 'Chọn nhóm học viên',
	BUTTON_UPDATE_BY_TEMPLATE: 'Nhập/xuất Excel',
	BUTTON_IMPORT_TEMPLATE: 'Nhập file Excel',
	BUTTON_DOWNLOAD_TEMPLATE: 'Dowload file mẫu',

	//EMPTY_DATA
	TITLE_EMPTY_DATA: "Chưa tồn tại phòng học nào",
	SUBTITLE_EMPTY_DATA: "Thêm buổi học mới để hiển thị trên danh sách này",

	//TABLE HEADER
	TH_TITLE: 'Tiêu đề buổi học',
	TH_TEACHER_NAME: 'Giảng viên phụ trách',
	TH_START_DATE: 'Ngày học',
	TH_START_TIME: 'Giờ học',
	TH_STUDENT_GROUP: 'Nhóm',
	TH_STATUS: 'Trạng thái',
	TH_ACTION: 'Thao tác',
	//TABLE HEADER DOWNLOAD FILE ZOOM EXCEL
	TH_END_TIME: "Giờ hết hạn",
	TH_END_DATE: "Ngày hết hạn",
	TH_LINK_ZOOM: "Link zoom",
	TOOLTIP_EDIT_ZOOM: "Chỉnh sửa buổi học",

	TEXT_STATUS_IN_FUTURE: 'Đang chờ',
	TEXT_STATUS_IN_PROGRESS: 'Đang diễn ra',
	TEXT_STATUS_FINISHED: 'Đã kết thúc',
	TEXT_CLASS: "Phòng học",
	TEXT_ALL: "Tất cả",
	TEXT_NO_GROUP: "Không có nhóm",

	//POPUP REMOVE ZOOM CLASS
	TITLE_REMOVING_DIALOG: 'Bạn có chắc chắn xoá buổi học này?',
	SUBTITLE_REMOVING_DIALOG: 'Dữ liệu sau khi xóa không thể khôi phục',
	MSG_REMOVE_CLASS_SUCCESS: 'Xóa buổi học thành công !',
	MSG_DOWLOAD_DATA_LOADING: 'Hệ thống đang tải dữ liệu, vui lòng thử lại sau!'
}
const VIRTUAL_CLASS_ADDING_FORM = {
	TITLE: 'Thêm buổi học Zoom',
	LINK_TO_CLASS_LIST: 'Danh sách phòng học Zoom',
	TITLE_GENERAL_INFO: 'Thông tin chung',
	LABEL_TITLE: 'Tiêu đề buổi học',
	PLACEHOLDER_TITLE: 'Nhập tiêu đề buổi học',
	LABEL_TEACHER_NAME: 'Giảng viên phụ trách',
	PLACEHOLDER_TEACHER_NAME: 'Nhập tên giảng viên',
	TITLE_DISPLAY_SETTINGS: 'Cài đặt hiển thị',
	LABEL_STUDENT_GROUP: 'Chọn nhóm học viên',
	PLACEHOLDER_STUDENT_GROUP: 'Chọn tên nhóm học thêm',
	//RADIO
	OPTION_RESTRICT_TO_STUDENT_GROUP: 'Hiển thị cho học sinh theo nhóm',
	OPTION_ALLOW_ALL_STUDENT: 'Hiển thị cho tất cả học sinh',

	TITLE_SCHEDULE: 'Lịch giảng',
	SUBTITLE_SCHEDULE:
		'Lịch của buổi học sẽ được hiển thị theo thời gian dưới và cho phép học sinh tham gia.',
	LABEL_START_DATE: 'Ngày bắt đầu',
	LABEL_START_TIME: 'Giờ bắt đầu',
	LABEL_END_DATE: 'Ngày kết thúc',
	LABEL_END_TIME: 'Giờ kết thúc',

	TITLE_ZOOM_CONFIG: 'Cấu hình Zoom',
	SUBTITLE_ZOOM_CONFIG:
		'Copy và dán đường dẫn lời mời trong phần Invite tại đây.',
	LABEL_LINK: 'Đường dẫn mới',
	PLACEHOLDER_LINK: 'Nhập đường dẫn',
	//MASSAGE
	MSG_CREATE_CLASS_SUCCESS: 'Tạo buổi học thành công',
	MSG_CLASS_SCHEDULE_INCORRECT: 'Thời gian bắt đầu phải trước thời gian kết thúc!'
}
const EMBEDED_SCRIPT_FORM = {
	BUTTON_ADD_NEW_SCRIPT: 'Thêm mã nhúng mới',
	TITLE_NEW_SCRIPT: 'Mã Javascript mới',
	PLACEHOLDER_SCRIPT_NAME: 'Nhập tên mã',
	SUBTITLE_NEW_JAVASCRIPT:
		'Mã nhúng thường tiềm ẩn nguy cơ về lỗ hổng bảo mật. Hệ thống không chịu trách nhiệm với những trường hợp bị mất dữ liệu.',
	LABEL_SCRIPT_LOCATION: 'Vị trí đặt mã',
	PLACEHOLDER_SCRIPT_LOCATION: 'Chọn vị trí đặt mã',
}
const USER_PROFILE_SETTING_FORM = {
	TITLE: 'Thông tin hồ sơ học viên',
	SUBTITLE:
		'Cho phép học viên tự chỉnh sửa các thông tin trong hồ sơ cá nhân. Thông tin mới sẽ được cập nhật lên hệ thống ngay sau đó.',
	TOGGLE_ALLOW_EDITING_PROFILE:
		'Cho phép học viên chỉnh sửa thông tin trong hồ sơ',
}
const REGISTRATION_SETTING_FORM = {
	TITLE: 'Thao tác đăng kí',
	TOGGLE_RESTRICT_USER_REGISTRATION:
		'Không cho phép học viên đăng kí từ trang chủ.',
}
const VIDEO_VIEW_LIMIT_SETTING_FORM = {
	TITLE: 'Điều chỉnh thói quen xem video của học viên',
	SUBTITLE:
		'Giới hạn số lần xem tối đa của học viên đối với mỗi video trên hệ thống để yêu cầu học tập tập trung hơn.',
	TOGGLE_RESTRICT_VIDEO_VIEW_LIMIT: 'Khóa quyền xem đối với video khi học viên xem quá số lần cho phép đối với video đó',
	LABEL_VIDEO_VIEW_LIMIT: 'Số lần xem cho phép',
	PLACEHOLDER_VIDEO_VIEW_LIMIT: 'VD: 2',
}
const DEFAULT_NOTIFICATION_SETTING_FORM = {
	TITLE:
		'Thiết lập thông báo mặc định cho người dùng vô danh',
	SUBTITLE:
		'Hiển thị số thông báo cho người dùng vô danh. Chỉ nên đặt tối đa 2 thông báo mặc định.',
	TOGGLE_ACTIVE_DEFAULT_NOTIFICATION: 'Hiển thị thông báo mặc định',
	BUTTON_ADD_DEFAULT_NOTICE: 'Thêm thông báo',
	TH_TITLE: 'Tiêu đề',
	TH_CONTENT: 'Nội dung',
	TH_LINK: 'Đường dẫn',
	TH_ACTION: 'Thao tác',
	TOOLTIP_EDIT_DEFAULT_NOTIFICATION: 'Chỉnh sửa thông báo',
}
const DEFAULT_NOTIFICATION_ADDING_FORM = {
	TITLE_NEW_NOTIFICATION: 'Tạo thông báo mặc định',
	LABEL_TITLE: 'Tiêu đề',
	PLACEHOLDER_TITLE: 'Nhập tiêu đề thông báo',
	LABEL_CONTENT: 'Nội dung',
	PLACEHOLDER_CONTENT: 'Nhập nội dung thông báo',
	LABEL_LINK: 'Đường dẫn',
	PLACEHOLDER_LINK: 'Nhập đường dẫn thông báo',
	LABEL_LAST_UPDATE_AT: 'Thời gian cập nhật',
	PLACEHOLDER_LAST_UPDATE_AT: 'Nhập thời gian cập nhật lần cuối',
	POPUP_TITLE_REMOVE_NOTIFICATION: "Xoá thông báo mặc định",
	POPUP_SUBTITLE_REMOVE_NOTIFICATION: "Dữ liệu sau khi xóa không thể khôi phục",

}
const LANGUAGE_SETTING_FORM = {
	TITLE: 'Thiết lập ngôn ngữ cho trang học trực tuyến',
	SUBTITLE: 'Thiết lập hiển thị ngôn ngữ, hỗ trợ tiếng Việt và tiếng Anh.',
	LABEL_LANGUAGE: 'Ngôn ngữ',
	PLACEHOLDER_LANGUAGE: 'Chọn ngôn ngữ',
	TEXT_VIETNAMESE: "Tiếng việt",
	TEXT_ENGLISH: "English",
	TEXT_JAPANESE: "Japanese"
}
export default {
	DOMAIN_SETTING_FORM,
	BASIC_SETTINGS,
	EMAIL_ADDRESS_SETTING_FORM,
	EMAIL_CONTENT_SETTING_FORM,
	ADMIN_PAGE_NAME_SETTING_FORM,
	SOCIAL_LINKS_SETTING_FORM,
	BANKING_INFOS_FORM,
	ONLINE_PAYMENT_GATEWAY_SETTING_FORM,
	EMBEDED_SCRIPT_FORM,
	VIRTUAL_CLASS_ADDING_FORM,
	VIRTUAL_CLASS_LIST,
	VIRTUAL_CLASS_ZOOM_FORM,
	ADVANCED_SETTINGS,
	DEFAULT_NOTIFICATION_SETTING_FORM,
	VIDEO_VIEW_LIMIT_SETTING_FORM,
	REGISTRATION_SETTING_FORM,
	USER_PROFILE_SETTING_FORM,
	DEFAULT_NOTIFICATION_ADDING_FORM,
	LANGUAGE_SETTING_FORM,
	ADVANCED_SETTING,
	DIRECT_PAY_SETTING_FORM,
	BUTTON_SAVE: 'Lưu',
	BUTTON_CANCEL: 'Hủy',
	BUTTON_CONFIRM: 'Xác nhận',
	BUTTON_RESET: 'Mặc định',
	BUTTON_REMOVE: 'Xóa',
	BUTTON_ENTER_FILE_HTML: 'Nhập file HTML',
	BUTTON_DOWNLOAD_FILE_SAMPLE: 'Tải xuống file mẫu',
	BUTTON_ENTER_EXPORT_HTML: 'Nhập/xuất HTML',

	TITLE_EDIT: "Sửa",
	TITLE_CREATE: "Tạo",
	TITLE_NOTIFICATION_DEFAULT: "Thông báo mặc định",

	MSG_UPDATING: 'Đang cập nhật ...',
	MSG_UPDATED: 'Cập nhật thành công!',
	MSG_UPDATE_FAILED: 'Cập nhật thất bại!',
	MSG_INPUT_INCORRECT: 'Vui lòng kiểm tra lại thông tin!',
	MSG_RECHECK_INVALID_FIELD: 'Vui lòng kiểm tra lại trường thông tin!',
	MSG_UPDATING_DATA: "Đang cập nhật dữ liệu...",
	MSG_DATA_SUCCESS: "Cập nhật dữ liệu thành công!",
	MSG_DATA_FAIL: 'Cập nhật dữ liệu thất bại!',
	MSG_DOWLOAD: 'Tải xuống thành công!'

}
