import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const couponPhysicalProduct = RepositoryFactory.get('productCoupon')

const state = {
  productCoupons: [],
  productCoupon: {},
  originalCost: 0
}

const actions = {
  async fetchProductCoupons({commit}, params) {
    const res = await couponPhysicalProduct.fetch({...params, _sort:'tags:asc'})
    commit('setProductCoupons', res.data || [])
    return res.data || []
  },
  async createProductCoupons({commit}, params) {
    const res = await couponPhysicalProduct.create(params)
    commit('setProductCoupon', res.data)
    return res.data
  },
  async fetchProductCouponById({commit}, id) {
    const res = await couponPhysicalProduct.fetchOneWithParams(id, {
      _populate: 'children',
    })
    commit('setOneProductCoupon', res.data || {})
    return res.data || {}
  },
  async updateProductCouponById({commit}, {id, coupon}) {
    const res = await couponPhysicalProduct.update(id, coupon)
    commit('updateProductCoupon', res.data)
    return res.data
  },
  async deleteProductCoupon({commit}, id) {
    await couponPhysicalProduct.remove(id)
    return commit('deleteProductCoupon', id)
  },
}

const mutations = {
  setProductCoupons(state, list) {
    state.productCoupons = list
  },
  updateProductCoupon(state, coupon) {
    state.productCoupon = coupon
  },
  setProductCoupon(state, newCouponCode) {
    state.productCoupons = state.productCoupons.map((couponCode) => {
      if (couponCode._id === newCouponCode._id) {
        return false
      } else {
        state.productCoupons.push(newCouponCode)
      }
    })
    return state.couponCodes
  },
  setOneProductCoupon(state, coupon) {
    state.productCoupon = coupon
  },
  deleteProductCoupon(state, id) {
    state.productCoupons = state.productCoupons.filter((item) => item.id !== id)
  },
  setOriginalCost(state, cost) {
    state.originalCost = cost
  },
  clearOriginalCost(state) {
    state.originalCost = 0
  }
}

const getters = {
  getProductCoupons: (state) => state.productCoupons,
  getProductCoupon: (state) => state.productCoupon,
  getOriginalCost: (state) => state.originalCost
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
