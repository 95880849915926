import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_RESOURCE + '/config'

const checkFeatureAccess = (params) => {
  return Repository(baseURL + '/check-feature-access').fetch(params)
}

export default {
  checkFeatureAccess,
}
