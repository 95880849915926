import Router from 'vue-router'
import Workspace from '@/modules/SiteBuilder/index.vue'
const message = {
  router: {
    isNotDefined: 'Router is not defined',
    isNotConfig: 'Router has not the information'
  }
}

const localData = {}
export default {
  install(Vue, options) {
    let defaultOptions = {}
    defaultOptions = Object.assign(defaultOptions, options)

    if (!defaultOptions.router || !(defaultOptions.router instanceof Router)) {
      throw new Error(message.router.isNotDefined)
    }
    if (!defaultOptions.routerConfig) {
      throw new Error(message.router.isNotConfig)
    }
    defaultOptions.routerConfig.route.component = Workspace
    options.router.addRoutes([defaultOptions.routerConfig.route])
    Vue.prototype.$siteBuilder = localData
  }
}