<template>
	<v-layout
		align-center
		justify-center
		class="background-authen full-width full-height"
	>
		<v-flex xs11 md9 lg8>
			<v-layout wrap>
				<v-flex
					xs12
					md6
					class="pureground-authen pa-8 d-flex flex-column justify-space-between primary--text"
				>
					<div class="d-flex justify-center">
						<img
							class="mb-4 full-height"
							:src="adminLogo"
							:width="adminLogoWidth"
						/>
					</div>
					<div>
						<div class="headline font-weight-bold mb-2">{{ $vuetify.lang.t("$vuetify.AUTHENTICATION.TITLE_HELP") }}?</div>
						<div>
							{{ $vuetify.lang.t("$vuetify.AUTHENTICATION.LABEL_HOLINE") }} hotline: <br />
							0366311292 ( Mr Huy ) <br />
							{{ $vuetify.lang.t("$vuetify.AUTHENTICATION.LABEL_ADDRESS_EMAIL") }}: <br />
							support@izteach.vn
						</div>
					</div>
				</v-flex>
				<v-flex>
					<router-view></router-view>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>
<script>
import { mapGetters } from 'vuex'
import get from 'lodash-es/get'
export default {
	computed: {
		...mapGetters({
			settings: 'vendor/getSettings'
		}),
		adminLogo() {
			return get(this.settings, 'adminLogo', '/admin-static/logo-izteach.svg')
		},
		adminLogoWidth() {
			if (this.adminLogo.startsWith('/admin-static')) return 150
			return 'auto'
		}
	}	
}
</script>
<style lang="scss" scoped>
.background-authen {
	background: url('/admin-static/auth-bg-image-1.svg') center center no-repeat;
	height: 80%;
	background-position: center;
	background-repeat: repeat;
	background-size: contain;
}
.pureground-authen {
	background: url('/admin-static/auth-bg-image-2.svg') center center no-repeat;
	background-size: cover;
}
</style>
