const BLOG_LIST = {
	TITLE: 'Blog',
	BUTTON_ADD: 'Create Post',
	LABEL_NAME: 'Blog Name',
	PLACEHOLDER_NAME: 'Search by article name',

	//EMTY DATA
	TITLE_EMTY_DATA: 'No posts yet',
	SUBTITLE_EMTY_DATA: 'Please create a new post to display on this list',
	TH_NAME: 'Post Name',
	TH_POSTING_TIME: 'Posting time',
	TH_VIEW: 'Views',
	TH_ACTION: 'Action',
	TOOLTIP_EDIT_BLOG: 'Edit Post',
	BUTTON_CREATE_COPY: 'Make a copy',

	POPUP_CONFIRM_CREATE_COPY_TITLE: 'Make a copy of the article',
	POPUP_CONFIRM_CREATE_COPY_MESSAGE:
		'Are you sure you want to make a copy of this article',
	MSG_CREATING: 'Creating posts...'
}
const BLOG_DETAIL = {
	TITLE_NEW_BLOG: 'New Blog',
	LINK_RETURN_TO_BLOG_LIST: 'List of articles',

	//BLOG_INFO
	TITLE_INFO: 'Post Info',
	LABEL_BLOG_TITLE: 'Title',
	PLACEHOLDER_IMPORT_BLOG_TITLE: 'Enter article title',
	LABEL_AUTHOR_NAME: 'Author',
	PLACEHOLDER_IMPORT_AUTHOR: 'Enter author name',

	TITLE_DISCUSSION: 'Discussion',
	SUBTITLE_DISCUSSION: 'Allow students to comment on the learning page.',
	CHECKBOX_TURN_ON_COMMENT_FACEBOOK: 'Enable Facebook comments in the footer',
	LABEL_CONFIGURATION_APPID: 'Configure AppId',
	PLACEHOLDER_CONFIGURATION_APPID: 'Example: 1110049499506',

	//BLOG_DESCRIPTION
	TITLE_DESCRIPTION: 'Post content',
	//BLOG_IMAGE
	TITLE_IMAGE: 'Post Profile Image',
	SUBTITLE_IMAGE: 'The image will be on the list of posts.',
	//BLOG_PUBLISH
	TITLE_PUBLISH: 'Publish article',
	SUBTITLE_PUBLISH:
		'The post is being made public. The article will be displayed on the website and can be found on the search engines',
	SUBTITLE_HIDDEN_ARTICLE:
		'Posts are hidden. The user will not be able to access the post.',
	BUTTON_HIDE: 'Hide from page',
	BUTTON_PUBLIC: 'Public',
	//BLOG_DELETION
	TITLE_DELETION: 'Delete Post',
	SUBTITLE_DELETION: 'Deleted posts cannot be recovered',
	BUTTON_DELETE: 'Delete Post',
	POPUP_CONFIRM_DELETE_BLOG_TITLE: 'Delete post from system',
	POPUP_CONFIRM_DELETE_BLOG_MESSAGE: 'Data after deletion cannot be recovered',
	MSG_DELETING_ARTICLE: 'Deleting posts',
	MSG_DELETE_SUCCESS: 'Delete successful post',

	//MESSAGE
	MSG_CHECK_BLOG_TITLE_AGAIN: 'Please enter post title!',
	MSG_CREATING: 'Creating new posts...',
	MSG_CREATE_SUCCESS: 'Successful creation',
	MSG_FAIL: 'Error creating article!',
	MSG_UPDATING: 'Updating posts...',
	MSG_UPDATE_SUCCESS: 'Post update successful',
	MSG_UPDATE_FAIL: 'Error updating post!',
	MSG_LOADDING: 'Loading article details...'
}
export default {
	BLOG_LIST,
	BLOG_DETAIL,

	BUTTON_REMOVE: 'Delete',
	BUTTON_ADD: 'Add',
	BUTTON_SAVE: 'Save',
	BUTTON_CANCEL: 'Cancel',
	BUTTON_CONFIRM: 'Confirm',
	BUTTON_ADD_IMAGE: 'Add a photo',
	LINK_RETURN_CONTENT_BLOCK_LIST: 'List of content blocks'
}
