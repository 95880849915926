<template>
  <div>
    <div class="d-flex align-item-center" v-for="(answer, answerIndex) in question.answers" :key="answerIndex">
      <div style="display: inline-flex">{{ answer.label }}.</div>
      <div v-html="answer.value || ''" style="display: inline-flex; margin-left: 4px"></div>
      <div v-if="isAnswerShown" style="display: inline-flex" class="ml-2">
        <div v-if="isStudentAnswerShown(answer.key)">
          <v-icon v-if="!isCorrect" size="small" color="red">mdi-close</v-icon>
          <v-icon v-else size="small" color="green">mdi-check</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import get from 'lodash-es/get'
import {mapGetters} from 'vuex'
export default {
  props: {
    question: Object,
  },
  computed: {
    ...mapGetters({
      submission: 'pdfRender/getSubmission',
    }),
    isAnswerShown() {
      return this.submission.answers && this.submission.answers[this.question.id]
    },
    isCorrect() {
      const studentAnswer = this.submission.answers[this.question.id]
      return get(studentAnswer, 'isCorrect', false)
    },
  },
  methods: {
    isStudentAnswerShown(ansKey = '') {
      const studentAnswer = this.submission.answers[this.question.id]
      return get(studentAnswer, 'answerId', '') === ansKey
    },
  },
}
</script>
