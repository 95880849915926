const BASE_URL = process.env.VUE_APP_API_NOTIFY + '/notify'
import Repository from '@/api/core'
const sendToUser = (params) => {
  return Repository(BASE_URL + '/user').create(params)
}

const sendToGroup = (params) => {
  return Repository(BASE_URL + '/group').create(params)
}

const sendToInterest = (params) => {
  return Repository(BASE_URL + '/interest').create(params)
}

const fetchNotificationByGroup = (params) => {
  return Repository(BASE_URL).fetch(params)
}
const count = (params) => {
  return Repository(BASE_URL + '/count').fetch(params)
}
const fetchNotificationByAdmin = (params) => {
  return Repository(BASE_URL).fetch(params)
}
const markRead = (id) => {
  return Repository(BASE_URL + `/markRead`).update(id)
}
const markReadAll = (id) => {
  return Repository(BASE_URL + `/markReadAll`).update(id)
}
export default {
  sendToUser,
  sendToGroup,
  sendToInterest,
  fetchNotificationByGroup,
  count,
  fetchNotificationByAdmin,
  markRead,
  markReadAll,
}
