<template>
  <div>
    <v-divider class="mt-4 mb-2" />
    <div style="font-size: larger; font-weight: 600; margin-bottom: 5px">Cài đặt SEO</div>
    <BaseBuilderWrapper title="Tiêu đề trang" :isDividerShown="false">
      <BaseInput label="Tiêu đề trang" v-model="seo.seoTitle"></BaseInput>
    </BaseBuilderWrapper>
    <BaseBuilderWrapper title="Mô tả trang" :isDividerShown="false">
      <BaseTextArea label="Mô tả trang" v-model="seo.seoDescription" />
    </BaseBuilderWrapper>
    <BaseBuilderWrapper
      :title="$vuetify.lang.t('Ảnh')"
      :subtitle="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.THUMBNAIL_DESCRIPTION')"
      :isDividerShown="false"
    >
      <BaseUploadImage
        type="image"
        @onUploadSuccess="(data) => onUploadSuccess(data, 'seoImage')"
        :imageUrl="seo.seoImage"
      />
    </BaseBuilderWrapper>
  </div>
</template>
<script>
import BaseBuilderWrapper from '@/templates/wrapper/BaseBuilderWrapper'
import {clone, get} from 'lodash-es'
import {mapGetters} from 'vuex'
export default {
  components: {
    BaseBuilderWrapper,
  },
  props: {
    seoData: {
      type: Object,
    },
  },
  data() {
    return {
      seo: {
        seoTitle: '',
        seoDescription: '',
        seoImage: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      themeData: 'vendor/getThemedata',
    }),
  },
  methods: {
    saveColor(color) {
      this.data.color_primary = color
    },
    onUploadSuccess(res, target) {
      const link = res[0].link
      const fullLink = this.$utils.combineLinkWithBucket(link)
      this.seo[`${target}`] = fullLink
      this.$forceUpdate()
    },
    getData() {
      const seoData = clone(this.seo, true)
      const seo = {
        settings: {
          name: seoData.seoTitle,
          description: seoData.seoDescription,
          image: seoData.seoImage,
        },
      }
      return seo
    },
  },
  watch: {
    seoData: {
      handler(seoData) {
        if (seoData) {
          this.seo.seoTitle = get(seoData, 'settings.name', '')
          this.seo.seoDescription = get(seoData, 'settings.description', '')
          this.seo.seoImage = get(seoData, 'settings.image', '')
        }
      },
      immediate: true,
    },
  },
}
</script>
