function lazyLoad(view) {
  return () => import(`@/pages/${view}.vue`)
}
export default [
  {
    path: "signIn",
    name: "Sign In",
    component: lazyLoad('SignIn'),
    meta: {
      title: '$vuetify.ROUTE.AUTH_ROUTES.TITLE'
    }
  },
]