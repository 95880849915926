<template>
  <v-app id="inspire">
    <v-app-bar class="d-flex justify-center" app absolute clipped-right flat height="72">
      <v-responsive max-width="300">
        <ChangeResolution @changeResolution="(value) => changeResolution(value)" />
      </v-responsive>
    </v-app-bar>
    <v-navigation-drawer permanent app width="500">
      <v-app-bar absolute clipped-left flat height="72">
        <ActionsBar @onSave="onSave" />
      </v-app-bar>
      <div class="d-flex flex-row full-height">
        <v-navigation-drawer permanent color="grey darken-3" width="100">
          <ListNavigations
            :onSave="onSave"
            :selected="selected"
            :checkChangedData="checkChangedData"
            @onSelectNavigation="(key) => onSelectNavigation(key)"
          />
        </v-navigation-drawer>
        <v-navigation-drawer permanent width="400">
          <EditDetailPart ref="edit-part" :selected="selected" :reloadIframe="reloadIframe" />
        </v-navigation-drawer>
      </div>
    </v-navigation-drawer>
    <v-main>
      <IFrameLearningPage ref="iframe" :domain="`${learningDomain}` + `${path}`" :resolution="resolution" />
    </v-main>
  </v-app>
</template>

<script>
import IFrameLearningPage from '@/modules/SiteBuilder/components/IFrameLearningPage'
import EditDetailPart from '@/modules/SiteBuilder/components/EditDetailPart'
import ListNavigations from '@/modules/SiteBuilder/components/ListNavigations'
import ChangeResolution from '@/modules/SiteBuilder/components/ChangeResolution'
import ActionsBar from '@/modules/SiteBuilder/components/ActionsBar'
import {mapGetters, mapActions} from 'vuex'
export default {
  components: {
    ChangeResolution,
    ActionsBar,
    ListNavigations,
    EditDetailPart,
    IFrameLearningPage,
  },
  data() {
    return {
      resolution: '100%',
      path: '',
      selected: 'common',
    }
  },
  computed: {
    ...mapGetters({
      learningDomain: 'vendor/getLearningDomain',
    }),
  },
  methods: {
    ...mapActions({
      removeMessageError: 'sitebuilder/removeMessageError',
    }),
    changeResolution(value) {
      this.resolution = value
    },
    onSelectNavigation(key) {
      if (key === 'about-us' || key === 'privacy-policy' || key === 'recruitment') {
        this.path = `/${key}`
      } else this.path = ''
      this.selected = key
    },
    reloadIframe() {
      this.$refs['iframe'].reload()
    },
    async onSave() {
      await this.$refs['edit-part'].onSave()
    },
    async checkChangedData() {
      const isChanged = await this.$refs['edit-part'].checkChangeData()
      return isChanged
    },
  },
  async created() {
    await this.removeMessageError()
  },
}
</script>
