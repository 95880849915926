<template>
  <div>
    <div v-if="title" class="d-flex justify-space-between">
      <div>
        <div class="subtitle-2">{{ title }}</div>
      </div>
      <slot name="action-button"></slot>
    </div>
    <div v-if="subtitle" class="body-2 mt-1">{{ subtitle }}</div>
    <div :class="defaultSlotClass">
      <slot></slot>
    </div>
    <v-divider v-if="isDividerShown" class="mt-4" />
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    subtitle: String,
    defaultSlotClass: {
      type: String,
      default: 'mt-4',
    },
    isDividerShown: {
      type: Boolean,
      default: true,
    },
  },
}
</script>