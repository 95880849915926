export default{
  BUTTON_DOWNLOAD:"Tải về",
  BUTTON_TUTORIAL:"Hướng dẫn cài đặt",
  HEADING_TEXT:"Tiêu đề",
  BUTTON_TEXT:"Tên nút",
  MAIN_TITLE:"Tiêu đề chính",
  SUBTITLE:"Tiêu đề phụ",
  EBOOK:"Sách",
  LEAD_FORM:"Mẫu đơn",
  BUTTON_SEND:"Gửi",
  TITLE_AUTHOR:"Tác giả",
  SUBTITLE_QUOTE:"Trích dẫn",
  DESCRIPTION:"Mô tả",
  HEADING_ONLINE_COURSE:"Khoá học Online",
  HEADING_OFFLINE_COURSE:"Khoá học Offline",
  HEADING_LEARNING_PATH:"Lộ trình học",
  HEADING_MY_ONLINE_COURSE:"Khoá học Online của tôi",
  SLIDE: "Slide",
  SUBHEADING_TEXT:"Subheading text"
}