//LEARNING PATH
const LEARNING_PATH_LIST = {
	TITLE: '学習ルート',
	BUTTON_CREATE: '新規作成',
	LABEL_FILTER_BY_NAME: 'ルート名',
	PLACEHOLDER_FILTER_BY_NAME: 'ルート名で検索してください',
	//テーブルヘッダー
	TH_NAME: 'ルート名',
	TH_CREATE_DATE: '作成日',
	TH_STATUS: '状態',
	TH_ACTION: 'アクション',
	TEXT_PUBLISHED: '公開済み',
	TEXT_UNPUBLISH: '未公開',
	TOOLTIP_EDIT_LEARNING_PATH: 'ルートを編集',
	// EMPTY_DATA
	TITLE_EMPTY_DATA: '学習ルートが1件も存在していません。',
	SUBTITLE_EMPTY_DATA:
		'新規学習ルートを作成してください。',

	TOOLTIP_PUSH_TO_HIGHLIGHTS_LIST:'このルートを推薦ルート一覧に追加します。',
	TOOLTIP_REMOVE_OUT_HIGHLIGHTS_LIST:'このルートを推薦ルート一覧から削除します。',

	// BASE_LEARNING_PATH_PICKER
	LABEL_SELECT_LEARNING_PATH: '学習ルートの選択',
	PLACEHOLDER_FILTER_LEARNING_PATH: '学習ルート名を検索してください'
}
const FORM_CREATE_NEW_LEARNING_PATH = {
	TITLE: '新規学習ルート',
	LABEL_NAME: '学習ルート名',
	PLACEHOLDER_INPUT_NAME: '学習ルート名を入力してください',

	//MESSAGE
	MSG_CREATING: '新規学習ルートを作成しています。',
	MSG_CREATE_SUCCESS: '新規学習ルートを作成しました。',
	MSG_CHECK_NAME_AGAIN: '学習ルート名が使用されました！',
	MSG_CREATE_FAIL: '新規学習ルートの作成に失敗しました。'
}
const LEARNING_PATH_DETAIL = {
	LINK_RETURN_TO_LEARNING_PATH_LIST: '学習ルート一覧',
	//TAB
	TAB_INFO: '情報',
	TAB_PUBLISH: '公開',
	TAB_COURSE: 'コース',
	TAB_DELETE: '学習ルートの削除',
	PATH_INFO: {
		BASIC_INFO: {
			TITLE: '基本情報',
			SUBTITLE: 'ラーニング パス名、カテゴリ、および難易度の設定',
			LABEL_NAME: '学習ルート名（最大120文字）',
			PLACEHOLDER_INPUT_NAME: '学習ルート名を入力してください',
			LAVEL_DIFICUL_LEVEL: '難易度',
			PLACEHOLDER_SELECT_TYPE: '種別を選択してください',
			TEXT_BEGINNER: '初級',
			OPTION_IMMEDIATE: '中級',
			OPTION_EXPERT: '上級',
			LABEL_CATEGORY: 'カテゴリ',
			PLACEHOLDER_SELECT_TOPIC: 'トピックを選択してください',
			BUTTON_ADD_CATEGORY: 'カテゴリの追加',
		},
		//DESCRIPTION_SHORT
		DESCRIPTION_SHORT: {
			TITLE: '簡単な説明',
			SUBTITLE: '簡単な説明の設定',
			LABEL_DESCRIPTION_ON_CARD: 'カード上の簡単な説明',
			PLACEHOLDER_INPUT_DESCRIPTION_CARD:'説明を入力してください（最大200文字）'

		},
		// DESCRIPTION_INFO
		DESCRIPTION_INFO: {
			TITLE: '詳細説明',
			SUBTITLE: '学生が学習パスを参照してよりよく理解できるように、説明を提供します'
		},
		COVER_IMAGE: {
			TITLE: '詳細情報ページのバックグラウンド',
			SUBTITLE: '詳細ページでラーニング パスの背景画像を構成します。 推奨サイズ: 1920x400px'
		},
		PREVIEW_IMAGE: {
			TITLE: 'カードのアバター画像',
			SUBTITLE: 'カードのサムネイルを構成します。 推奨サイズ: 240x360px'
		},
		DURATION_SETTING: {
			TITLE: '総時間',
			SUBTITLE: '学習ルート総時間設定',
			RADIO_INPUT_DESCRIPTION: '自分で入力',
			RADIO_ALLOW_SYSTEM_CACULATE:
				'コースに基づいて自動計算自体が可能',
			LABEL_COURSE_TIME: 'コース時間',
			PLACEHOLDER_INPUT_COURSE_TIME: '時間を入力してください',
			HINT_COURSE_TIME:
				'注意：時間自動測定が設定されていないコースが学習ルートの総時間に加算されません。'
		},
		CERTIFICATE_SETTING: {
			TITLE: '証明書が適用されます',
			SUBTITLE:
				'学習プログラムの完了後に学生に発行される構成証明書。',
			LABEL_ALLOW_USE: '設定をオンにする',
			LABEL_VIEW_DETAIL:
				'あなたがこの学習ルートの証明書を持っています。こちらで証明書をご覧ください。',
			LINK_GO_TO_CERTIFICATE: 'こちらで',
			LABEL_CREATE_NEW:
				'証明書はまだ設定されていません。 新しいものを作成してください'
		},
		INTERACTION_SETTING: {
			TITLE: '学生の評価',
			LABEL_ALLOW_AND_DISPLAY:
				'学生がルートに対応する評価を評価して表示できるようにする',
			LABEL_REQUIRED_BROWSER: 'サイトに公開する前にレビューレビューが必要です'
		}
	},
	PUBLISH_SETTING: {
		TITLE: '学習ルートの公開',
		SUBTITLE:
			'このロードマップを公開し、リスト ページに表示します (この変更は、表示が制限されたロードマップには適用されません)。',
		BUTTON_STOP: '公開停止',
		BUTTON_PUBLISH: '公開',
		POPUP_CONFIRM_STOP_TITLE:
			'この学習ルートの公開を停止します。よろしいでしょうか？',
		POPUP_CONFIRM_STOP_MESSAGE:
			'この学習ルートはウェブサイトから表示されなくなります。',
	},
	COURSE_IN_PATH: {
		TITLE: 'コース一覧',
		POPUP_CONFIRM_SAVE_TITLE: 'コース保存確認',
		POPUP_CONFIRM_SAVE_MESSAGE:
			'時間自動測定が設定されていないコースが存在します。’’コース時間はルートの総時間に加算されません',

		TITLE_TEMPTY_DATA: 'コースがありません。コースを追加してください。',
		LABEL_PUBLISHED: '公開済',
		LABEL_NOT_PUBLISHED: '未公開',

		TOOLTIP_AUTO_TIME_SETTINGS_NOT_SET_UP:
			'コースに時間自動計算が設定されていません。',
		TOOLTIP_REMOVE: 'コースの削除',

		POPUP_CONFIRM_EXIT_TITLE: '変更内容は保存されていません。',
		POPUP_CONFIRM_EXIT_MESSAGE:
			'保存されていない設定内容があります。保存してください。',

		POPUP_CONFIRM_REMOVE_TITLE: 'コースの削除確認',
		POPUP_CONFIRM_REMOVE_MESSAGE:
			'この学習ルートからコースを削除します。よろしいでしょうか？',
	},
	DELETION: {
		TITLE: '学習ルートの削除',
		SUBTITLE: 'ラーニング パスを削除しました。 注: 削除されたラーニング パスは復元できません。',
		BUTTON: '削除',
		MSG_DELETING: '学習ルートを削除しています。',

		POPUP_CONFIRM_DELETE_TITLE: '学習ルート削除',
		POPUP_CONFIRM_DELETE_MESSAGE: '削除されたデータは回復できません。'
	},
	MSG_UPDATE_SUCCESS: 'ドキュメントを更新しました。',
	MSG_USED_NAME: '学習ルート名が使用されました。',
	MSG_UPDATE_FAIL: '更新に失敗しました！'
}
//EVENTS
const EVENT_LIST = {
	TITLE: 'イベント',
	BUTTON_CREATE: '新規作成',
	LABEL_FILTER_BY_NAME: 'イベント名',
	PLACEHOLDER_FILTER_BY_NAME: 'イベント名で検索してください',
	TITLE_EMPTY_DATA: 'イベントが1つも存在しません',
	SUBTITLE_EMPTY_DATA: 'イベントを新規作成してください',
	//テーブルヘッダー
	TH_NAME: 'イベント名',
	TH_AVAILABEL_TIME: '開始時間',
	TH_CREATE_DATE: '作成日',
	TH_ACTION: 'アクション',
	TOOLTIP_EDIT_EVENT: 'イベントを編集',
}
const FORM_CREATE_NEW_EVENT = {
	TITLE: '新しいイベント',
	LABEL_NAME: 'イベント名',
	PLACEHOLDER_INPUT_NAME: 'イベント名を入力',
	LABEL_DESCRIPTION: 'イベントの説明',
	PLACEHOLDER_INPUT_DESCRIPTION: 'イベントの説明を入力',

	//MESSAGE
	MSG_CREATING: 'イベントの作成...',
	MSG_CREATE_SUCCESS: '成功するイベントを作成する',
	MSG_CHECK_NAME_AGAIN: 'イベント名は既に使用されています!',
	MSG_CREATE_FAIL: '新しいイベントの作成に失敗しました',

	//TIME
	LABEL_AVAILABLE_DATE: '開始日',
	PLACEHOLDER_SELECT_AVAILABLE_DATE: '開始日を選択してください',
	LABEL_AVAILABLE_HOUR: '始まる時間',
	PLACEHOLDER_SELECT_LABEL_AVAILABLE_HOUR: '開始時間を選択してください',
	LABEL_EXPIRED_DATE: '終了日',
	PLACEHOLDER_SELECT_EXPIRED_DATE: '終了日を選択してください',
	LABEL_EXPIRED_HOUR: '時間終了',
	PLACEHOLDER_SELECT_EXPIRED_HOUR: '終了時間を選択',
}
const EVENT_DETAIL = {
	TITLE: '新規イベント',
	LINK_RETURN_TO_EVENT_LIST: 'イベント一覧',
	//TAB
	TAB_BASIC_INFO: '基本情報',
	TAB_ADVANCE_INFO: '詳細情報',
	TAB_PRICE: '価格',
	TAB_PUBLISH: '公開',
	TAB_DELETE: 'イベント削除',
	BASIC_INFO: {
		ON_CARD: {
			TITLE: '基本情報',
			LABEL_HEADER: 'イベント名',
			PLACEHOLDER_INPUT_HEADER: 'イベント名を入力してください',
			TITLE_SHORT_DESCRIPTION: '簡単な説明',
			SUBTITLE_SHORT_DESCRIPTION: '一覧ページでイベントを簡単に紹介する説明を入力します。',
			LABEL_SHORT_DESCRIPTION: '簡単な説明 (推奨される長さ: 200 文字)',
			PLACEHOLDER_INPUT_SHORT_DESCRIPTION: 'イベントの説明を入力してください',
			HINT_DESCRIPTION: '推奨長さ: 200文字',
			MSG_REQUEST_INPUT_HEADER: 'イベント名を入力してください。!'
		},
		ADDRESS: {
			TITLE: '場所の組織',
			RADIO_OUTSIDE: '他の場所',
			LABEL_INPUT_ADDRESS_OF_EVENT: '住所を入力',
			RADIO_TRAINING_DEPARTMENT: '施設一覧',
			LABEL_PICK_DEPARTMENT: 'トレーニングセンターの選択'
		},
		TIME: {
			TITLE: 'イベント時間',
			LABEL_AVAILABLE_DATE: '開始日',
			PLACEHOLDER_SELECT_AVAILABLE_DATE: '開始日を選択してください',
			LABEL_AVAILABLE_HOUR: '開始時間',
			PLACEHOLDER_SELECT_LABEL_AVAILABLE_HOUR: '開始時間を選択してください',
			LABEL_EXPIRED_DATE: '終了日',
			PLACEHOLDER_SELECT_EXPIRED_DATE: '終了日を選択してください',
			LABEL_EXPIRED_HOUR: '終了時間',
			PLACEHOLDER_SELECT_EXPIRED_HOUR: '終了時間を選択してください',
			MSG_CHECK_INFO_AGAIN: '期間情報を再度確認してください！'
		},
		PREVIEW_IMAGE: {
			TITLE: 'サムネイル',
			SUBTITLE: 'カードのサムネイルを構成します。おすすめサイズ: {0}'
		},
		COVER_IMAGE: {
			TITLE: 'イベントのカバー写真',
			SUBTITLE: 'コース詳細ページの上部に表示される画像を設定します。おすすめサイズ: {0}'
		}
	},
	INFO_ADVANCE_IMAGE: {
		TITLE: 'イベントの説明画像（最大6枚）',
		SUBTITLE: 'おすすめサイズ: 400x400px'
	},
	//INF_ADVANCE_DESCRIPTION
	TITLE_DESCRIPTION_DETAIL: '詳細説明',
	SUBTITLE_DESCRIPTION_DETAIL: '非公開の紹介ページで学生がイベントを参照してよりよく理解できるように、説明を提供します。',
	// INFO_PRICE
	PRICE_INFO: {
		TITLE: '価格',
		SUBTITLE: 'このイベントの価格表示を設定します。',
		RADIO_FREE: '設定自由',
		RADIO_PAY_ONCE: '一括払いの設定',
		LABEL_OFFICIAL: '最終価格',
		SUFFIX_CURRENT_UNIT: 'VND',
		LABEL_AFTER_DISCOUNT: '割引前の価格',
		HINT_AFTER_DISCOUNT: 'プロモーションがない場合は空白のままにします',

		MSG_RECHECK_INFO: '情報を再度確認してください！'
	},
	PUBLISHING: {
		TITLE_STOP_PUBLISH: '本コースの公開停止',
		SUBTITLE_STOP_PUBLISH:
			'コースの公開を停止して、一覧ページでコースを非表示にします。',
		TITLE_PUBLISH_COURSE: 'コースを公開',
		SUBTITLE_PUBLISH:
			'このコースを公開し、一覧ページに表示します。 （展示限定のコースは除く）',
		BUTTON_PUBLISH: '公開',
		BUTTON_STOP_PUBLISH: '公開停止',
		POPUP_CONFIRM_STOP_TITLE: '本コースの公開を停止します。宜しいですか？',
		POPUP_CONFIRM_STOP_MESSAGE:
			'コースの公開を停止して、一覧ページでコースを非表示にします。'
	},
	DELETION: {
		TITLE: 'イベントの削除',
		SUBTITLE: 'イベントを削除します。 注: 削除されたイベントは復元できません。',
		BUTTON: '削除',
		POPUP_CONFIRM_TITLE: 'システムからイベント削除',
		POPUP_CONFIRM_MESSAGE: '削除されたデータは回復できません。',
		MSG_DELETING: 'イベントを削除しています。',
		MSG_DELETE_SUCCESS: 'イベントを削除しました。'
	},
	MSG_DOWNLOAD_EVENT_DETAIL_LOADING: 'イベントの詳細情報を読み込んでいます。',
	MSG_RECHECK_EVENT_TITLE: 'イベントのタイトルを入力してください。',
	MSG_RECHECK_TIME_EVENT: 'イベントの開催時間を再度確認してください。',
	MSG_CREATING_EVENT: 'イベントを作成しています。',
	MSG_CREATE_EVENT_FAIL: 'イベント作成のエラーが発生しました。',
	MSG_UPDATING_EVENT: 'イベントを更新しています。',
	MSG_UPDATE_EVENT_SUCCESS: 'イベントを更新しました。',
	MSG_UPDATE_EVENT_FAIL: 'イベント更新のエラーが発生しました。',
}
//E-BOOK
const EBOOK_LIST = {
	TITLE: '電子書籍',
	BUTTON_CREATE: '電子書籍の作成',
	LABEL_FILTER_BY_NAME: 'ドキュメント名',
	PLACEHOLDER_FILTER_BY_NAME: 'ドキュメント名で検索してください',
	//テーブルヘッダー
	TH_NAME: 'ドキュメント名',
	TH_AUTHOR: '作成者',
	TH_UPDATE: '更新日時',
	TH_ACTION: 'アクション',
	TOOLTIP_EDIT_EBOOK: '電子書籍の編集',
	// EMPTY_DATA
	TITLE_EMPTY_DATA: '電子書籍が1つも存在しません',
	SUBTITLE_EMPTY_DATA: '電子書籍を新規作成してください'
}
const EBOOK_DETAIL = {
	TITLE: '電子書籍一覧',
	TITLE_NEW_DOCUMENT: '新規ドキュメント',
	//TAB
	TAB_BASIC_INFO: '情報',
	TAB_CONTENT: 'コンテンツ',
	TAB_PRICE_AND_OWNERSHIP: '価格と所有権',
	TAB_SETTING_INTERACT: 'インタラクション設定',
	TAB_DELETE: '電子書籍の削除',
	// BASIC_INFO
	BASIC_INFO: {
		ON_CARD: {
			TITLE: '基本情報',
			LABEL_NAME: '電子書籍名',
			PLACEHOLDER_INPUT_NAME: '電子書籍名を入力してください',
			LABEL_TEACHER_UNDERTAKE: '著者/講師',
			PLACEHOLDER_SELECT_TEACHER_UNDERTAKE: '講師を選択してください',
			BUTTON_CREATE_TEACHER: '講師の追加登録',
			LABEL_CATEGORY: 'カテゴリ',
			PLACEHOLDER_SELECT_TOPIC: 'トピックを選択してください',
			BUTTON_CREATE_CATEGORY: 'カテゴリの追加登録',
		},
		SHORT_DESCRIPTION: {
			TITLE: '簡単な説明',
			SUBTITLE: '一覧ページに電子書籍を簡単に紹介するための説明を入力します',
			LABEL_DESCRIPTION: '簡単な説明 (推奨される長さ: 200 文字)',
			PLACEHOLDER_INPUT_DESCRIPTION: 'ドキュメントの説明を入力してください',
			HINT_DISCRIPTION: '推奨長さ: 200文字'
		},
		DESCRIPTION_DETAIL: {
			TITLE: '詳細な説明',
			SUBTITLE: '生徒が非公開の紹介ページで電子ブックを参照してよりよく理解できるように、説明を提供します。'
		},
		PREVIEW_IMAGE: {
			TITLE: 'サムネイル',
			SUBTITLE: 'カードのサムネイルを構成します。 推奨サイズ: {0}'
		},
		COVER_IMAGE: {
			TITLE: '電子書籍のカバー写真',
			SUBTITLE: 'コース詳細ページの上部に表示される画像を設定します。 推奨サイズ: {0}'
		}
	},
	//ADVANCE_INFO
	TITLE_UPLOAD_IMAGE_TYPE_PDF: 'コンテンツブック（PDF）',
	TITLE_DESCRIPTION_DETAIL: '詳細説明',
	PRICE_INFO: {
		TITLE: '価格と所有権',
		RADIO_FREE: '無料オプション',
		RADIO_PAY_ONCE: '一括払いオプション',
		LABEL_PRICE_OFFICIAL: '最終価格',
		LABEL_CURRENT_UNIT: 'VND',
		LABEL_VALUE_AFTER_DISCOUNT: '割引前の価格',
		HINT_PRICE_AFTER_DISCOUNT: '割引がない場合は空欄',
		RADIO_LOCK_PURCHASE:
		'学生はこの電子ブックを購入できません。 有効なコードを使用するか、権限を付与された場合にのみ、この電子書籍を登録できます。',
	},
	// INTERACTION_SETTING
	TITLE_INTERACTION_OF_STUDENT: '評価',
	SUBTITLE_INTERACTION_OF_STUDENT: '電子書籍の評価ルールを構成する',
	CHECKBOX_STUDENT_CAN_ENABLE_RATE:
		'電子書籍の評価ルールの構成',
	CHECKBOX_MUST_APPROVE_RATE:
		'承認された評価のみを表示する。',

	//DISPLAY_SETTING
	TITLE_SETTING_DISPLAY: '画面',
	SUBTITLE_SETTING_DISPLAY: 'ラーニング プロファイル ページで電子書籍の表示を構成する',
	CHECKBOX_VISIBLE_HIDDEN:
		'電子ブックを所有している学生は、学習プロファイル ページでそれらを見ることができます',

	DELETION: {
		TITLE: '電子書籍の削除',
		SUBTITLE: '電子書籍を削除します。 注: 削除された電子書籍は復元できません',
		BUTTON: '削除',
		MSG_LOADING: '電子書籍を削除しています…',
		MSG_SUCCESS: '電子書籍を削除しました',
		POPUP_CONFIRM_TITLE: 'システムからドキュメント削除',
		POPUP_CONFIRM_MESSAGE: '削除されたデータは回復できません。'
	},

	MSG_INPUT_TITLE_EVENT: 'ドキュメント名を入力してください。',
	MSG_CHECK_INFO_PRICE:
		'ペイメントゲートウェイで1万ドン未満の取引を行えません。より高い金額の取引を実施してください。',
	MSG_CREATE_EBOOK_SUCCESS: 'ドキュメントを作成しました！',
	MSG_UPDATE_EBOOK_SUCCESS: 'ドキュメントを更新しました。',
	MSG_CREATE_EBOOK_LOADING: 'ドキュメントの作成...',

	TOPIC_DETAIL_EBOOK: {
		LINK_RETURN_TO_CATEGORY_LIST: 'カテゴリ一覧',
		TITLE: 'カテゴリ編集',
		TITLE_MANAGE_EBOOK: 'カテゴリの電子書籍管理',
		LABEL_EBOOK_NAME: '電子書籍名',
		PLACEHOLDER_SELECT_EBOOK_NAME: 'カテゴリに追加する電子書籍を選択してください',
		MSG_ADDING: 'カテゴリに電子書籍を追加しています。',
		MSG_ADD_SUCCESS: 'カテゴリに電子書籍を追加しました!',
		MSG_REMOVING: 'カテゴリから電子書籍を削除しています。。。',
		MSG_REMOVE_SUCCESS: 'カテゴリから電子書籍を削除しました!',
		POPUP_CONFIRM_REMOVE_TITLE: 'このカテゴリから電子書籍を削除しますか?'
	},
	TOPIC_TABLE_EBOOK: {
		TH_EBOOK: '電子書籍名',
		TH_UPDATE: '更新日時',
		TH_ACTION: 'アクション',
	},
}
//TOPIC
const CATEGORY_LIST = {
	TITLE: 'カテゴリ-ジャンル',
	BUTTON_CREATE: 'カテゴリの作成',
	LABEL_FILTER_BY_NAME: 'カテゴリ名',
	PLACEHOLDER_FILTER_BY_NAME: 'カテゴリ名で検索',
	//TAB
	TAB_ONLINE_COURSE: 'オンラインコース',
	TAB_OFFLINE_COURSE: 'オフラインコース',
	TAB_EBOOK: '電子書籍',
	// TABEL HEADER
	TH_CATEGORY: 'カテゴリ-ジャンル',
	TH_UPDATE: '更新日時',
	TH_ACTION: 'アクション',
	TOOLTIP_EDIT_CATEGORY: 'カテゴリを編集',
	SUBTITLE_EMPTY_DATA: 'カテゴリを新規作成してください',

	// TOGGLE_FEATURE_TOPIC
	TOOLTIP_ADD_CATEGORIES_TO_DISPLAY_LIST:
		'メニューのカテゴリ一覧にこのカテゴリを追加します',
	TOOLTIP_REMOVE_CATEGORY_FROM_DISPLAY_LIST:
		'メニューのカテゴリ一覧からこのカテゴリを削除します',
	POPUP_CONFIRM_DELETE_TITLE: 'メニューのカテゴリ一覧からこのカテゴリを削除します?',
	//MESSAGE
	MSG_DELETE_SUCCESS: 'カテゴリを削除しました。',
	MSG_DELETE_FAIL: 'カテゴリ削除のエラーが発生しました',
	MSG_UPDATING_DATA: 'データを更新しています。。。',
	MSG_UPDATE_SUCCESS: 'データを更新しました!'
}
const CATEGORY_CREATE_NEW_FORM = {
	TITLE: 'カテゴリの新規作成',
	LABEL_NAME: 'カテゴリ',
	PLACEHOLDER_INPUT_NAME: 'カテゴリ名を入力してください',
	LABEL_TYTPE: 'タイプ',
	LABEL_SELECT_TYPE: 'カテゴリタイプの選択',
	OPTION_ONLINE_COURSE: 'オンラインコース',
	OPTION_OFFLINE_COURSE: 'オフラインコース',
	OPTION_EBOOK: '電子書籍',
	MSG_CREATING: 'カテゴリを作成しています',
	MSG_CREATE_SUCCESS: 'カテゴリを作成しました!'
}
const CATEGORY_DETAIL = {
	LINK_RETURN_TO_CATEGORY_LIST: 'カテゴリ一覧',
	LINK_RETURN_TO_CATEGORY_PARENT_DETAIL: '親カテゴリの詳細',
	TITLE_EDIT_CATEGORY: 'カテゴリの編集',
	TITLE_EDIT_CATEGORY_CHILD: '子カテゴリの編集',
	EDIT_BASIC_INFO: {
		TITLE: '基本情報',
		LABEL_CATEGORY_NAME: 'カテゴリ名',
		MSG_CHECK_AND_INPUT_CATEGORY_NAME: 'カテゴリ名を入力してください!'
	},
	TABLE_CHILD: {
		TITLE: '子カテゴリ管理',
		TH_CATEGORY: 'カテゴリ',
		TH_UPDATE: '更新日時',
		TH_ACTION: 'アクション',
		TOOLTIP_EDIT_CHILD_CATEGORY: '子カテゴリの編集',
		MSG_CREATE_SUCCESS: 'カテゴリを作成しました!',
		MSG_DELETING_CATEGORY: 'カテゴリを削除しています。。。',
		MSG_DELETE_SUCCESS: 'カテゴリを削除しました。',
		POPUP_CONFIRM_DELETE_CATEGORY_TITLE:
			'この子カテゴリを削除しますか？'
	},
	ADD_COURSE_TOPIC: {
		TITLE: 'カテゴリのコース管理',
		LABEL_NAME: 'コース名',
		PLACEHOLDER_SELECT_COURSE: 'カテゴリに追加するコースを選択してください',
		MSG_ADDING: 'コースを追加しています。。。',
		MSG_REMOVING: 'コースを削除しています。。。',
		POPUP_CONFIRM_DELETE_TITLE:
			'このカテゴリからコースを削除しますか?'
	},
	TABLE_COURSE: {
		TH_COURSE: 'コース名',
		TH_UPDATE: '更新日時',
		TH_ACTION: 'アクション',
	},
	MSG_PROCESSING: '処理中...',
	MSG_UPDATE_COURSE_SUCCESS: 'コースを更新しました!'
}
//LIBRARY
const LIBRARY_LIST = {
	TITLE: 'レッスンライブラリ',
	TAB_VIDEO: '動画',
	TAB_PDF: 'PDF',
	TAB_AUDIO: 'AUDIO',
	VIDEO_PANEL: {
		LABEL_NAME: '動画名',
		PLACEHOLDER_FILTER_BY_NAME: '動画名で検索してください',
		CONFIRM_UPLOAD_MULTIPLE_FILES: 'これらのファイルのアップロードを確認する',
	},
	PDF_PANEL: {
		LABEL_NAME: 'PDFファイル名',
		PLACEHOLDER_FILTER_BY_NAME: 'PDFファイル名で検索してください'
	},
	AUDIO_PANEL: {
		LABEL_NAME: 'オーディオ名',
		PLACEHOLDER_FILTER_BY_NAME: '名前で検索'
	},
	// UPLOAD_DIALOG
	BUTTON_UPLOAD: 'アップロード',
	TITLE_UPLOAD_FILE: 'ファイルアップロード',
	TITLE_EDIT_DIALOG: {
		TITLE: 'ファイル名の変更',
		LABEL_FILE_NAME: 'ファイル名',
		PLACEHOLDER_INPUT_FILE_NAME: 'ファイル名を入力してください'
	},
	TH_NAME: 'ファイル名',
	TH_FORMAT: '形式',
	TH_UPLOAD_AT: 'アップロード日',
	TH_CAPACITY: '容量',
	TH_ACTION: 'アクション',
	TEXT_NOT_NAME: '名称無し',
	TITLE_FILE_NOT_NAME: '名称無しのファイル',

	POPUP_CONFIRM_DELETE_DATA_TITLE: 'データを削除しますか?',
	POPUP_CONFIRM_DELETE_DATA_MESSAGE:
		'このデータに連携する内容は削除され、回復できません'
}
//QUESTION BANK
const QUESTION_BANK_LIST = {
	TITLE: 'テスト項目',
	//TAB
	TAB_QUESTION_IN_SYSTEM: 'システムの質問',
	TAB_TOPIC_AND_KNOWLEDGE: 'トピック・知識の枠組み',
	// STRUCTURE_KNOWLEDGE
	BUTTON_ADD_TOPIC: 'トピックの追加',
	POPUP_CONFIRM_DELETE_TOPTIC_TITLE: 'システムからトピック削除',
	POPUP_CONFIRM_DELETE_TOPTIC_MESSAGE: '削除されたデータは回復できません。',
	MSG_CREATING_TOPIC: 'トピックを作成しています。。。',
    
	MSG_SUREK_TOPIC_AGAIN: 'トピックは必須入力です！',
    
	// EDIT_KNOWLEDGE
	TITLE_EDIT_TOPIC_INFO: 'トピック情報の編集',
	LABEL_TOPIC_NAME: 'トピック名',
	PLACEHOLDER_INPUT_TOPIC_NAME: 'トピック名を入力してください',
	// ADD_KNOWLEDGE
	LABEL_PARENT_TOPIC: '親トピック',
	PLACHOLDER_PARENT_TOPIC: '親トピックを選択してください'
}
const LIST_QUESTION = {
	FILTER: {
		LABEL_FILTER_BY_TOPIC: 'トピックによるフィルター',
		PLACEHOLDER_SELECT_TOPIC: 'トピックを選択してください',
		LABEL_FILTER_BY_CONTENT: '内容によるフィルター',
		PLACEHOLDER_FILTER_BY_CONTENT: '内容で質問を検索してください'
	},
	LIST_ACTION_WITH_EXCEL: {
		LABEL_QUESTION: '質問',
		LABEL_QUESTION_TOPIC: '質問のトピック',
		BUTTON_SELECT_FILE: 'ファイル選択',
		OPTION_IMPORT_EXCEL: 'Excelファイルのインポート',
		OPTION_DOWNLOAD_FILE_EXCEL: 'テンプレートファイルのダウンロード',
		LABEL_CONTENT: '内容',
		LABEL_ANWSER: '回答',
		TH_TYPE:
			'質問タイプ（ "single-choice":多肢選択（単一回答）, "multiple-choice":多肢選択（複数回答）, "fill-blank":空欄記入）',
		TH_QUESTION_TYPE: '質問タイプ',
		TH_MULTIPLE_CHOICE: '多肢選択',
		TH_FILL_BLANK: '空白の記入',
		TH_ANWSER_TRUE: '正解',
		TH_LEVELS: 'レベル',
		MSG_CHECK_QUESTION_TYPE_AGAIN:'質問タイプが不正です。再度確認してください。',
		MSG_ANWSER_ERRO:
			'多肢選択の質問は回答の選択が必須です。アップロードしたファイルを再度確認してください。',
	},
	TH_CONTENT: '内容',
	TH_TOPIC: 'トピック',
	TH_LEVEL: 'レベル',
	TH_TYPE: 'タイプ',
	TH_ACTION: 'アクション',
	TOOLTIP_EDIT_QUESTION: '質問を編集',
	TEXT_QUESTIONS_ON_AVAILABLE_SUBJECTS: '問題は固有問題集にあります',

	POPUP_CONFIRM_DELETE_TITLE: '削除の確認',
	POPUP_CONFIRM_DELETE_MESSAGE: 'この質問を削除しますか?',

	BUTTON_SINGLE_CHOICE_PDF: '多肢選択（単一回答）',
	BUTTON_MULTIPLE_CHOICE_PDF: '多肢選択（複数回答）',
	BUTTON_FILL_BLANK: '空欄記入',

	EDIT_QUESTION_INFO_DIALOG: {
		TITLE: '質問情報の編集',
		LABEL_INPUT_DESCRIPTION: '説明の入力',
		LABEL_INPUT_CONTENT_OF_ANWSER: '回答の入力...',
		LABEL_INPUT_ANWSER: '回答の入力',
		LABEL_LEVEL_QUESTION: '質問のレベル',
		PLACEHOLDER_INPUT_LEVEL_QUESTION: '質問のレベルを入力してください',

		TAB_CONTENT: '内容',
		TAB_ANSWER: '正解',
		TAB_ADVANCED: '詳細',
		TAB_EXPLAIN: '説明',

		POPUP_CONFIRM_TITLE: '編集確認',
		POPUP_CONFIRM_MESSAGE: '確認後、質問を変更いたします。 よろしければ、保存ボタンをクリックしてください。',
		BUTTON_CONFIRM: '保存',
		BUTTON_CANCEL: 'キャンセル',
		MSG_UPDATE_SUCCESS: '更新に成功'
	},
	EDIT_ALL_QUESTION_INFO_DIALOG: {
		TITLE_EDIT_ALL_QUESTION: '質問情報の一括編集',
		LABEL_LEVEL_QUESTION: '質問のレベル',
		LABEL_INPUT_LEVEL_QUESTION: '質問レベルの入力'
	},
	POPUP_CONFIRM_DELETE_MANY_QUESTION_TITLE: '複数質問の削除',
	POPUP_CONFIRM_DELETE_MANY_QUESTION_MESSAGE:
		'削除されたデータは回復できません。このレッスンを削除しますか？'
}
//KHO ĐỀ THI
const QUESTION_GENERATE = {
	TITLE: '試験問題のライブラリ',
	LABEL_SELECT_COURSE: 'コース選択',
	PLACEHOLDER_FILTER_COURSE: 'コースを検索してください',

	LABEL_SELECT_MATRIX: 'マトリックスレッスンの選択',
	PLACEHOLDER_FILTER_MATRIX: 'Tマトリックスレッスンを選択してください',
	MSG_CHECK_LESSON_AGAIN: 'レッスンにテストが存在しません。',

	LABEL_EXAM_UNIT: '試験問題番号',
	LABEL_EXAM_CODE: '試験問題番号',
	TH_EXAM_NAME: '試験問題名',
	TH_ACTION: 'アクション',
	POPUP_CONFIRM_DELETE_TITLE: '試験問題の削除',
	POPUP_CONFIRM_DELETE_MESSAGE:
		'この操作によって試験問題がシステムから削除されます。学生者がこの試験問題を受けられません。',
	MSG_DELETE_SUCCESS: '試験問題を削除しました！',
	POPUP_DETAIL: {
		TITLE: '試験問題詳細',
		LABEL_QUESTION_UNIT: '問題'
	}
}
//基礎
const DEPARTMENT_LIST = {
	TITLE: '学舎',
	BUTTON_CREATE: '学舎の作成',
	LABEL_FILTER_BY_NAME: '学舎名',
	PLACEHOLDER_FILTER_BY_NAME: '学舎名で検索してください',
	//TABLE
	TH_NAME: '学舎名',
	TH_ACTION: 'アクション',
	TOOLTIP_EDIT_DEPARTMENT: 'ベースを編集',
	// EMPTY_DATA
	TITLE_EMPTY_DATA: '学舎が存在しません',
	SUBTITLE_EMPTY_DATA: '学舎を新規作成してください',

	POPUP_CONFIRM_DELETE_TITLE: 'この学舎を削除しますか?',
	MSG_DELETE_SUCCESS: '学舎を削除しました。'
}
const FORM_CREATED_NEW_DEPARTMENT = {
	TITLE: '学舎の作成',
	LABEL_NAME: '学舎名',
	PLACEHOLDER_INPUT_NAME: '学舎名を入力してください',
	MSG_CREATING: '学舎を作成しています',
	MSG_CREATE_SUCCESS: '学舎を作成しました！'
}
const DEPARTMENT_DETAIL = {
	LINK_RETURN_TO_DEPARTMENT_LIST: '学舎一覧',
	TITLE: '学舎の編集',
	TITLE_INFOS: '学舎情報',
	LABEL_NAME: '学舎名',
	PLACEHOLDER_INPUT_NAME: '学舎名を入力してください',
	TITLE_IMAGE: '表示画像',
	LABEL_SIZE_IMAGE: '推奨サイズ：300x225px',

	MSG_GETTING_DATA: 'データを取得しています。',
	MSG_UPDATING_DATA: 'データを更新しています。',
	MSG_UPDATE_SUCCESS: 'データを更新しました。'
}
//割り当てられたテスト
const ASSIGNED_HOMEWORK_LIST = {
	TITLE: 'テストリスト',
	LABEL_SELECT_COURSE: 'コース選択',
	OPTION_ALL: '全て',
	LABEL_FILTER_TEST_BY_REQUEST: '要求によるテスト選択',
	// TABLE_HEADER
	TH_COURSE: 'コース',
	TH_UNIT_TITLE: 'テスト',
	TH_HOMEWORK_COUNT: '宿題数',
	TH_IS_ALL_HOMEWORK_MARKER: '全てスコアリング済',
	TH_ACTION: 'アクション'
}
const ASSIGNED_HOMEWORK_DETAIL = {
	LINK_RETURN_TO_EXAM_LIST: 'テストリスト',
	BUTTON_VIEW_REQUIMENT: '問題を見る',
	FILTER: {
		LABEL_STATUS: '状態',
		PLACEHOLDER_STATUS: '提出状態',
		OPTION_STATUST_ALL: '全て',
		OPTION_NO_SCORE: '未スコアリング',
		OPTION_APPROVED: '承認済',
		LABEL_SUBJECTS_CODE: '回答コード',
		PLACEHOLDER_INPUT_SUBJECTS_CODE: '回答コード入力',
		BUTTON_FILTER_AND_VIEW: 'フィルター及び表示'
	},
	BUTTON_DOWNLOAD_ALL_EXCEL: '全てダウンロード',
	ENTITY_EXERCISE_STUDENT: 'ユーザーの問題',
	MSG_GROUP_STUDENT_DOES_NOT_EXIST: 'ユーザーグループが存在しません',
	MSG_COMPRESSING: '圧縮しています。',
	MSG_COMPRESSING_TO: 'ユーザーの回答を圧縮しています。',

	BUTTON_DOWLOAD_FILE_SELECTED: '選択したファイルのダウンロード',
	TOOLTIP_TEACHER_ACCEPT_LAST_LESSON: '講師の最後採点',
	TOOLTIP_TIME_ACCEPT_LAST_LESSON: '最後採点時間',
	// TABLE_HEADER
	TH_SUBMIT_CODE: '提出番号',
	TH_TIME_SUBMIT: '提出時間',
	TH_STATUS: '状態',
	TH_MARK: '点数',
	TH_TEACHER: '講師',
	TH_POINTING_TIME: '採点時間',
	TH_ACTION: 'アクション',
	TEXT_NO_SCORE: '未採点',
	TEXT_SUBMITTED: '提出済',
	TEXT_APPROVED: '承認済み',
	TEXT_REFUSE: '拒否',
	TEXT_UNSUBMIT: '未提出',
	TOOLTIP_COPY_SUBJECTS: '提出問題のコピー',
	MSG_COPY_SUCCESS: 'コピーしました。',
	MSG_COPY_FAIL: 'コピーできませんでした。’. 後で試してください。',
	POPUP_VIEW_UNIT_REQUIREMENT: {
		TITLE: '問題',
		TITLE_INTRODUCT: '紹介',
		TITLE_REQUIREMENT_OF_EXERCISE: '問題の要求'
	},
	ARCHIVE_BY_CHOICE: {
		ENTITY_EXERCISE_STUDENT: 'ユーザーの問題'
	},
	MSG_ACCEPT_EXERCISE_SUCCESS: '問題を承認しました',
	MSG_SAVE_EDIT_SUCCESS: '修正問題を保存しました!'
}
//
const MODAL_CREATE_AUTHOR = {
	TITLE_ADD_NEW_TEACHER: '新しい講師の追加',
	LABEL_TEACHER_NAME: '講師氏名',
	PLACEHOLDER_INPUT_TEACHER_NAME: '講師氏名を入力してください',
	LABEL_EMAIL: 'メールアドレス',
	PLACEHOLDER_INPUT_TEACHER_EMAIL: '講師のメールアドレスを入力してください',
	TITLE_EDIT_TEACHER_IN: '講師詳細情報閲覧・編集',
	LINK_TEACHER_LIST: '講師一覧',
	MSG_CREATEING_TEACHER: '講師を作成しています。',
	MSG_CREATED_TEACHER: '新しい講師を作成しました',
	MSG_RECHECK_EMAIL_TEACHER:
		'既に登録されたメールアドレスです。他のメールアドレスで試してください。!',
	MSG_CREATE_TEACHER_FAIL: '講師を作成できません!'
}
//
const MODAL_CREATE_TOPIC = {
	TITLE_ADD_NEW: '新しいトピックの追加',
	LABEL_CATEGORY_HEADER: 'カテゴリタイトル',
	LABEL_INPUT_CATEGORY_HEADER: 'カテゴリタイトルを入力してください',
	TITLE_EDIT_CATEGORY_DETAIL_IN: 'カテゴリ詳細情報閲覧・編集',
	LINK_GO_TO_CATEGORY_LIST: 'カテゴリ一覧',
	MSG_CREATING_CATEGORY: 'カテゴリを作成しています',
	MSG_CREATED_NEW_CATEGPRY: '新しいカテゴリを作成しました!',
	MSG_CREATE_CATEGORY_FAIL: 'カテゴリを作成できません!'
}

export default {
	LEARNING_PATH_LIST,
	FORM_CREATE_NEW_LEARNING_PATH,
	LEARNING_PATH_DETAIL,
	EVENT_LIST,
	FORM_CREATE_NEW_EVENT,
	EVENT_DETAIL,
	EBOOK_LIST,
	EBOOK_DETAIL,
	CATEGORY_LIST,
	CATEGORY_CREATE_NEW_FORM,
	CATEGORY_DETAIL,
	LIBRARY_LIST,
	QUESTION_BANK_LIST,
	QUESTION_GENERATE,
	DEPARTMENT_LIST,
	FORM_CREATED_NEW_DEPARTMENT,
	DEPARTMENT_DETAIL,
	MODAL_CREATE_AUTHOR,
	MODAL_CREATE_TOPIC,
	ASSIGNED_HOMEWORK_LIST,
	ASSIGNED_HOMEWORK_DETAIL,

	LIST_QUESTION,
	PLACEHOLDER_SELECT_TOPIC: 'トピックを選択してください',
	LABEL_TOPIC: 'トピック',
	LABEL_QUESTION: '質問',
	LABEL_SELECT_ALL: '全選択',
	BUTTON_SEARCH: '検索',
	BUTTON_CONFIRM: '確認',
	BUTTON_CANCEL: 'キャンセル',
	BUTTON_SAVE: '保存',
	BUTTON_CANCEL_TEXT: 'キャンセル',
	BUTTON_CREATE: '作成',
	BUTTON_UPDATE: '更新',
	BUTTON_DELETE: '削除',
	BUTTON_OK: 'OK',
	TITLE_ACTION: 'アクション',
	BUTTON_ADD: '追加',

	//MESSAGE
	MSG_DELETING: '作成しています。',
	MSG_UPDATING_DATA: 'データを更新しています。。。',
	MSG_UPDATE_DATA_SUCCESS: 'データを更新しました。',
	MSG_UPDATE_DATA_FAIL: 'データを更新できません。',
	MSG_CREATE_SUCCESS: '作成しました。',
	MSG_DELETE_DATA_SUCCESS: 'データを削除しました。',
	MSG_DELETE_DATA_FAIL: 'データを削除できません。',
	MSG_UPDATING: '更新中',
	MSG_UPDATE_SUCCESS: '更新しました。',
	MSG_UPDATE_FAIL: '更新に失敗しました。',
	MSG_DELETE_SUCCESS: '削除しました',
	MSG_CHECK_INFO_AGAIN: 'もう一度情報を確認してください!',
	MSG_GETTING_DATA: "データを取得しています",
	TITLE_EMPTY_DATA: '{0} のカテゴリが存在しません。',
	
}
