import Repository from '@/api/core'
const contactURL = process.env.VUE_APP_API_UTILITY + '/contact'
const orderURL = process.env.VUE_APP_API_UTILITY + '/order'

const fetchContact = (params) => {
  return Repository(contactURL).fetch(params)
}

const fetchOrder = (params) => {
  return Repository(orderURL).fetch(params)
}

export default {
  fetchContact,
  fetchOrder,
}