import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const SubmissionRepository = RepositoryFactory.get('submission')
const state = {
	submissions: [],
	count: 0,
	statistic: [],
	analytic: [],
	statisticDetail: [],
	currentAnswers: null,
	isUpdateAnswer: false,
}

const actions = {
	async fetchLastSubmission({ commit }, params = {}) {
		const res = await SubmissionRepository.fetchLast({
			...params
		})
		commit('setSubmissions', res.data)
		return res.data
	},
	async removeSubmission(_, id) {
		const res = await SubmissionRepository.remove(id)
		return res.data
	},
	async fetchSubmissions({ commit }, params = {}) {
		const res = await SubmissionRepository.fetch({
			...params
		})
		commit('setSubmissions', res.data)
		return res.data
	},
	async fetchById(_, id) {
		const res = await SubmissionRepository.fetchOne(id)
		return res.data
	},
	async fetchStatisticSubmission({ commit }, params = {}) {
		const res = await SubmissionRepository.statistic({
			...params
		})
		commit('setStatistic', res.data)
		return res.data
	},
	async analyticQuestion({ commit }, params = {}) {
		const res = await SubmissionRepository.analyticQuestion({
			...params
		})
		commit('setAnalytic', res.data)
		return res.data
	},
	async fetchStatisticSubmissionDetail({ commit }, params = {}) {
		const res = await SubmissionRepository.statistic({
			...params
		})
		commit('setStatisticDetail', res.data)
		return res.data
	},
	async countSubmissions({ commit }, params = {}) {
		const res = await SubmissionRepository.count({
			...params
		})
		commit('setCount', res.data)
		return res.data
	},
	async reScoring(_, { id, ...submission }) {
		const res = await SubmissionRepository.submit(id, {
			...submission,
		})
		return res.data
	},
	resetSubmissions({ state }) {
		state.submissions = []
		state.count = 0
	}
}
const mutations = {
	reset(state) {
		state.submissions = []
	},
	setSubmissions(state, submissions) {
		return (state.submissions = submissions)
	},
	setStatistic(state, statistic) {
		return (state.statistic = statistic)
	},
	setStatisticDetail(state, statistic) {
		return (state.statisticDetail = statistic)
	},
	setCount(state, count) {
		return (state.count = count)
	},
	setAnalytic(state, analytic) {
		return (state.analytic = analytic)
	},
	setCurrentAnswers(state, answers) {
		return (state.currentAnswers = answers)
	},
	setIsUpdateAnswer(state, value) {
		return (state.isUpdateAnswer = value)
	}
}

const getters = {
	getSubmissions: state => {
		return state.submissions
	},
	getStatistic: state => {
		return state.statistic
	},
	getStatisticDetail: state => {
		return state.statisticDetail
	},
	getAnalytic: state => {
		return state.analytic
	},
	getCurrentAnswers: state => {
		return state.currentAnswers
	},
	getIsUpdateAnswer: state => {
		return state.isUpdateAnswer
	}
}

export default {
	state,
	actions,
	mutations,
	getters,
	namespaced: true
}
