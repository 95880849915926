//MANAGE ACTIVATION CODE
const ACTIVATION_CODE_CAMPAIGN_LIST = {
	TITLE: 'アクティベーションコード',
	BUTTON_ADD: 'キャンペーンの追加',
	LABEL_FILTER_BY_NAME: 'キャンペーン名',
	PLACEHOLDER_FILTER_BY_NAME: 'キャンペーンで検索してください',
	BUTTON_IMPORT_EXPORT_DATA_BY_EXCEL: 'Excelの取込・出力',
	BUTTON_IMPORT_DATA_BY_EXCEL_FILE: 'Excelの取込',
	BUTTON_DOWNLOAD_EXCEL_TEMPLATE: 'Excelのダウンロード',
	BUTTON_EXPORT_DATA_TO_EXCEL_FILE: 'Excelの出力',
	// TABLE
	TH_NAME: 'キャンペーン名',
	TH_COURSE_NAME: 'コース名',
	TH_EXPIRED_AT: '有効期限',
	TH_CODE_QUANTITY: '使用済のコード数',
	TH_CODE_QUANTITY_DIVIDE_TOTAL: '使用されたコード/利用可能な合計コード',
	TH_AVAILABLE_DATE: '開始日',
	TH_ACTION: 'アクション',
	TOOLTIP_EDIT_ACTIVE_CODE: 'アクティベーション コードの編集',
	//EXPORTING DATA
	EXPORTING_DATA_TH_CODE: 'アクティベーションコード',
	//IMPORTING DATA
	MSG_IMPORT_FAIL_READING_FILE_FAIL:
		'ファイル取込のエラーがあります。再度試してみてください!',
	MSG_IMPORT_FAIL_EMPTY_FILE: '内容を含むファイルを取込んでください',
	MSG_IMPORT_FAIL_INCORRECT_ROWS: 'ファイル内の不正な行数：{0}',
	//EMPTY DATA
	TITLE_EMPTY_DATA: 'アクティベーションコードのキャンペーンが存在しません',
	SUBTITLE_EMPTY_DATA: 'キャンペーンを新規作成してください'
}
const FORM_CREATE_NEW_ACTIVATION_CODE_CAMPAIGN = {
	TITLE: '新規作成',
	LINK_RETURN_TO_CAMPAGIN_LIST: 'キャンペーン一覧',
	BUTTON_ADD: 'キャンペーンの追加',
	MSG_CREATING_CODE: 'キャンペーンを作成しています。',
	MSG_CREATE_CODE_SUCCESS: 'キャンペーンを作成しました',
	// BASIC_INFO
	TITLE_CAMPAIGN_INFOS: 'キャンペーン情報',
	LABE_CAMPAIGN_NAME: 'キャンペーン名',
	PLACEHOLDER_CAMPAGIN_NAME: 'キャンペーン名を入力してください',
	LABEL_COURSE_TYPE: 'コースタイプ',
	TEXT_COURSE_TYPE_ONLINE: 'オンライン',
	LABEL_COURSE: '適用されたコース',
	PLACEHOLDER_COURSE: 'コースを選択してください',
	MSG_CHECK_INFO_AGAIN: 'もう一度情報を確認してください',
	// ADVANCED_INFO
	TITLE_CODE_INFOS: 'アクティベーションコードの情報',
	CHECKBOX_REUSABLE_CODE: '複数使用コード',
	LABEL_CODE_QUANTITY: '生成するコードの数',
	PLACEHOLDER_CODE_QUANTITY: '生成するコードの数を入力してください',
	LABEL_AVAILABLE_DATE: '開始時間(0h 00)',
	PLACEHOLDER_AVAILABLE_DATE: '開始時間を選択してください',
	LABEL_EXPIRATION_DATE: '終了時間(0h 00)',
	PLACEHOLDER_EXPIRATION_DATE: '終了時間を選択してください',
	MSG_CHECK_TIME_INFO_AGAIN: '時間情報を再度確認してください。'
}
const CAMPAIGN_ACTIVATION_CODE_DETAIL = {
	LINK_RETURN_TO_CAMPAGIN_LIST: 'キャンペーン一覧',
	// TAB
	TAB_CAMPAIGN_INFO: 'キャンペーン情報',
	TAB_CODE_LIST: 'コード一覧',
	// BASIC_INFO
	TITLE_CAMPAIGN_INFOS: 'キャンペーン情報',
	LABEL_COURSE: 'アクティベーションコース',
	LABEL_COURSE_TYPE: 'コースタイプ',
	TEXT_COURSE_TYPE_ONLINE: 'オンライン',
	LABEL_REUSABLE_CODE: '複数使用コード',
	TEXT_STATUS_ACTIVE: 'アクティベート',
	TEXT_STATUS_INACTIVE: 'デアクティベート',
	LABEL_AVAILABLE_AT: '有効化日時',
	LABEL_EXPIRED_AT: '有効期限',
	LABEL_CODE_QUANTITY: 'コード総数',
	LABEL_USED_CODE_QUANTITY: '使用済のコード数',
	//TRANSACTIONS
	TITLE_TRANSACTIONS: '取引履歴',
	TH_CODE: 'コード',
	TH_USED_AT: 'アクティベーション日',
	TH_STUDENT_NAME: 'ユーザーの氏名',
	TH_STUDENT_EMAIL: 'メールアドレス',
	TH_STUDENT_PHONE: '電話番号',
	//AVAILABLE CODES
	TITLE_AVAILABLE_CODES: '未アクティベートコード',
	TH_AVAILABLE_CODE: 'コード',
	TH_ACTION: 'アクション',
	// MESSAGE
	MSG_COPY_SUCCESS: '正常にコピーできました。',
	MSG_COPY_FAIL: 'コピーできませんでした。’. もう一度お試しください。'
}
//MANAGE COUPON
const COUPON_CAMPAIGN_LIST = {
	TITLE: 'クーポン',
	BUTTON_ADD: 'キャンペーン追加',
	LABEL_NAME: 'キャンペーン名',
	PLACEHOLDER_NAME: 'キャンペーン名称で検索してください',
	// TABLE
	TH_NAME: 'キャンペーン名',
	TH_COURSE_TYPE: 'コースタイプ',
	TH_DISCOUNT_VALUE: '割引',
	TH_AVAILABLE_AT: '開始日時',
	TH_EXPIRED_AT: '終了日時',
	TH_USED_CODE: '使用コード数',
	TH_ACTION: 'アクション',
	TOOLTIP_EDIT_COUPON: 'クーポンを編集',
	//EMPTY DATA
	TITLE_EMPTY_DATA: '割引コードのキャンペーンが存在しません。',
	SUBTITLE_EMPTY_DATA: 'リスト表示するには新規作成してください。'
}
const FORM_CREATE_NEW_COUPON_CAMPAIGN = {
	TITLE: '新規作成',
	LINK_RETURN_TO_COUPON_LIST: 'キャンペーン一覧',
	BUTTON_SAVE: 'キャンペーン保存',
	//MASSAGE
	MSG_CREATING_CODE: 'キャンペーンを作成しています。',
	MSG_CREATE_SUCCESS: 'キャンペーンを作成しました',
	//BASIC INFO
	TITLE_BASIC_INFOS: '基本情報',
	LABEL_CAMPAIGN_NAME: 'キャンペーン名',
	PLACEHOLDER_CAMPAIGN_NAME: 'キャンペーン名を入力してください',
	LABEL_COURSE_TYPE: 'コースタイプ',
	PLACEHOLDER_COURSE_TYPE: 'コースタイプを選択してください',
	TEXT_COURSE_TYPE_ONLINE: 'オンライン',
	TEXT_COURSE_TYPE_OFFLINE: 'オフライン',
	LABEL_COURSE: '適用対象コース',
	PLACEHOLDER_COURSE: 'コースを選択',
	MSG_CHECK_BASIC_INFO_AGAIN: 'もう一度基本情報をご確認ください。',
	//DISCOUNT INFO
	TITLE_DISCOUNT_INFOS: '割引情報',
	LABEL_COURSE_PROCE: '原価',
	LABEL_DISCOUNT_VALUE: '割引値',
	PLACEHOLDER_DISCCOUNT_VALUE: '割引値を入力してください',
	LABEL_CODE_QUANTITY: 'コード総数',
	PLACEHOLDER_CODE_QUANTITY: '生成したいコードの数を入力してください',
	LABEL_AVAILABLE_DATE: '開始日',
	PLACEHOLDER_AVAILABLE_DATE: '開始日を選択してください',
	LABEL_EXPIRATION_DATE: '終了日',
	PLACEHOLDER_EXPIRATION_DATE: '終了日を選択してください',
	MSG_CHECK_DISCOUNT_INFO_AGAIN: 'もう一度割引情報をご確認ください'
}
const COUPON_CAMPAIGN_DETAIL = {
	LINK_RETURN_TO_CAMPAGIN_LIST: 'キャンペーン一覧',
	// TAB
	TAB_CAMPPAIGN_INFO: 'キャンペーン情報',
	TAB_CODE_LIST: 'コード一覧',
	// BASIC INFO
	TITLE_CAMPAIGN_INFOS: 'キャンペーン情報',
	LABEL_CAMPAIGN_NAME: 'キャンペーン名',
	LABEL_COURSE_TYPE: 'コースタイプ',
	PLACEHOLDER_COURSE_TYPE: 'コースタイプを選択してください',
	TEXT_COURSE_TYPE_ONLINE: 'オンライン',
	TEXT_COURSE_TYPE_OFFLINE: 'オフライン',
	LABEL_COURSE: '適用されたコース',
	PLACEHOLDER_COURSE: 'コースを選択してください',
	TEXT_COURSE_NOT_SET_YET: 'コースを選択していません。',
	MSG_DATA_LOADING: 'データを取得しています。',
	MSG_CHECK_BASIC_INFO_AGAIN: 'もう一度基本情報をご確認ください。',
	MSG_PROMOTION_INFO_AGAIN: 'もう一度割引情報をご確認ください',
	// DISCOUNT INFO
	TITLE_DISCOUNT_INFOS: '割引内容',
	LABEL_COURSE_PRICE: '原価',
	LABEL_DISCOUNT_VALUE: '割引価値',
	TEXT_CURRENCY_UNIT: 'ベトナムドン',
	LABEL_CODE_QUANTITY: 'コード総数',
	LABEL_USED_CODE_QUANTITY: '使用済のコード数',
	LABEL_AVAILABLE_AT: '開始時間',
	LABEL_EXPIRATION_AT: '終了時間',
	LABEL_TIME: '時間',
	MSG_CHECK_INFO_AGAIN: 'もう一度割引情報をご確認ください',
	// USED CODES
	LABEL_USED_CODE: '使用済みコード',
	LABEL_CODE: '割引コード',
	PLACEHOLDER_CODE: 'コードで検索してください',
	BUTTON_VIEW_MORE: 'もっと見る',
	TH_CODE: 'コード',
	TH_ACTION: 'アクション',
	TH_USED_AT: '使用日',
	TH_STUDENT_ACCOUNT: '使用アカウント',
	TH_BILL_TOTAL_VALUE: '注文額（ベトナムドン）',
	TH_DISCOUNT_VALUE: '割引',
	TH_CODE_STATUS: '状態',
	TH_VIEW_BILL: '注文',
	TEXT_CODE_STATUS_USED: '使用済み',
	TEXT_CODE_STATUS_NOT_USED_YET: '未使用',
	MSG_COPY_CODE_SUCCESS: '正常にコピーできました。',
	MSG_COPY_CODE_FAIL: 'コピーできませんでした。もう一度お試しください。',
	// BUTTON
	BUTTON_CANCEL: 'キャンセル',
	// MASSAGE
	MSG_UPDATING: '情報を更新しています。',
	MSG_UPDATE_SUCCESS: '正常に更新されました。',
	// DELETE
	TITLE_DELETE_ACTION: 'キャンペーン削除',
	SUBTITLE_DELETE_ACTION:
		'キャンペーンを削除してしまうと、使用されていないコードも無効になります。',
	BUTTON_DELETE: 'キャンペーン削除',
	POPUP_DELETION_CONFIRM_TITLE: 'キャンペーン削除',
	POPUP_DELETION_COMFIRM_MESSAGE:
		'削除されたデータは回復できません。このキャンペーンを削除します。よろしいでしょうか？',
	MSG_DELETE_SUCCESS: '正常に削除されました。'
}
//MANAGE COMBO
const COMBO_CAMPAIGN_LIST = {
	TITLE: 'コンボ',
	BUTTON_ADD: 'キャンペーン追加',
	LABEL_FILTER_BY_NAME: 'キャンペーン名',
	PLACEHOLDER_FILTER_BY_NAME: 'キャンペーン名で検索してください',
	// TABLE
	TH_NAME: 'キャンペーン名',
	TH_DISCOUNT_VALUE: '割引値',
	TH_AVAILABLE_AT: '開始',
	TH_EXPIRED_AT: '終了時間',
	TH_ACTION: 'アクション',
	// DELETE ACTION
	POPUP_CONFIRM_DELETION_TITLE: 'コンボ削除',
	POPUP_CONFIRM_DELETION_MESSAGE:
		'削除されたデータは回復できません。このコンボを削除してもよろしいでしょうか',
	// MESSAGE
	MSG_DELETING: '削除中',
	MSG_DELETE_SUCCESS: 'コンボが正常に削除されました。'
}
const COMBO_CAMPAIGN_DETAIL = {
	LINK_RETURN_TO_COMBO_LIST: 'コンボ一覧',
	// BASIC INFO
	TITLE_CAMPAIGN_INFOS: '基本情報',
	LABEL_NAME: 'コンボ名',
	LABEL_AVAILABLE_TIME: '開始日',
	LABEL_EXPIRED_TIME: '終了日',
	// BASIC INFO EDIT FORM
	PLACEHOLDER_IMPORT_NAME: 'コンボ名を入力してください',
	PLACEHOLDER_AVAILABLE_TIME: '開始時間を選択してください',
	PLACEHOLDER_EXPIRED_TIME: '終了時間を選択してください',
	MSG_UPDATE_SUCCESS: 'コンボが正常に更新されました。',
	// CONDITION APPLY COMBO
	TITLE_CONDITION: '適用条件',
	SUBTITLE_CONDITION:
		'ユーザーのカートは条件を満たすと本コンボ割引が適用できます。適用条件はコンボ作成時に設定され、一度保存されたら編集できませんのでご注意ください。',
	LABEL_MIN_NUMBER_OF_COURSES: '最小コース数',
	LABEL_PRICE_APPLY: '適用金額',
	LABEL_COURSE_APPLY: '適用対象コース',
	BUTTON_ADD_CONDITION: '条件追加',
	//VALUE COMBO
	TITLE_VALUE: 'コンボ値',
	SUBTITLE_VALUE: 'キャンペーン作成完了後、割引値を変更できません。',
	LABEL_CURRENCY_UNIT: 'ベトナムドン',
	//DELETE COMBO
	TITLE_DELETION: 'コンボ削除',
	SUBTITLE_DELETION:
		'コンボを削除しても、ユーザーがコンボで購入したコースにアクセスできます。コンボ情報が注文・売上管理で保存され、確認できます。',
	BUTTON_DELETE: 'コンボ削除',
	POPUP_CONFIRM_DELETE_TITLE: 'コンボ削除',
	POPUP_CONFIRM_DELETE_MESSAGE:
		'削除されたデータは回復できません。このコンボを削除してもよろしいでしょうか',
	MSG_DELETING: '削除中',
	MSG_DELETE_SUCCESS: 'コンボが正常に削除されました。',
	MSG_UPDATING: '更新中',
	COMBO_PUBLISHER: {
		TITLE: '公開',
		SUBTITLE_ACTIVATION: 'アクティベートしない限り、コンボが適用されません。.',
		SUBTITLE_HIDE: 'このコンボを画面から非表示になります。',
		BUTTON_ACTIVE: 'アクティベート',
		BUTTON_BUTTON_ACTIVE: '停止'
	}
}
const FORM_CREATE_NEW_COMBO_CAMPAIGN = {
	TITLE: '新規作成',
	LINK_RETURN_TO_COMBO_LIST: 'コンボ割引キャンペーン一覧',
	//BASIC INFO
	TITLE_BASIC_INFO: '基本情報',
	LABEL_NAME: 'コンボ名',
	PLACEHOLDER_INPUT_NAME: 'コンボ名を入力してください',
	LABEL_AVAILABLE_DATE: '開始日',
	PLACEHOLDER_SELECT_AVAILABLE_DATE: '開始日を選択してください',
	LABEL_EXPIRATION_DATE: '終了日',
	PLACEHOLDER_SELECT_EXPIRATION_DATE: '終了日を選択してください',
	MSG_UPDATE_SUCCESS: 'コンボを更新しました。',
	// CONDITION
	TITLE_CONDITION: '適用条件',
	SUBTITLE_CONDITION:
		'ユーザーのカートは条件を満たすと本コンボ割引が適用できます。適用条件はコンボ作成時に設定され、一度保存されたら編集できませんのでご注意ください。',
	BUTTON_ADD_CONDITION: '条件追加',

	//VALUE
	TITLE_DISCOUNT_INFOS: '割引情報',
	SUBTITLE_COMBO_VALUE: 'キャンペーン作成完了後、割引値を変更できません。',
	OPTION_DISCOUNT_BY_FIXED_AMOUNT: '定額割引',
	HINT_DISCOUNT_BY_FIXED_AMOUNT:
		'電子決済ゲートウェイは10.000VNĐ以下のトランザクションを実行できません。それ以上の金額を設定してください。',
	PLACEHOLDER_DISCOUNT_VALUE: '割引金額',
	PLACEHOLDER_INPUT_DISCOUNT_AMOUNT_VALUE: '割引金額を入力してください',
	OPTION_DISCOUNT_BY_PERCENT: '割引率',
	PLACEHOLDER_INPUT_DISCOUNT_PERCENT_VALUE: '%割引',
	// MESSAGE
	MSG_CREATING: 'コンボを作成しています...',
	MSG_CREATE_SUCCESS: 'コンボを作成しました。'
}
const POPUP_ADD_CONDITION = {
	TITLE: '条件追加',
	TEXT_NOTE: 'ユーザーのカートは条件を満たすと本コンボ割引が適用できます。',
	//
	OPTION_COURSE_QUANTITY: '条件達成最小コース数',
	PLACEHOLDER_INPUT_COURSE_QUANTITY: 'コース数を入力してください',
	//
	OPTION_BILL_VALUE: '条件達成最小金額',
	LABEL_CURRENCY_UNIT: 'ベトナムドン',
	HINT_INPUT_BILL_VALUE: '10.000ドン以上の金額を入力してください。',
	//
	OPTION_SELECTED_COURSES: '1回で以下コースをまとめて購入する',
	LABEL_COURSE: 'コース',
	PLACEHOLDER_SELECT_COURSE: 'コースを選択してください。',
	POPUP_CONFIRM_REMOVE_COURSE: 'このコースを条件から削除してもよろしいですか？',
	MSG_REMOVING_COURSE: 'コースを削除しています。',
	MSG_REMOVING_SUCCESS: 'コースが正常に削除されました',
	//
	BUTTON_SAVE_CONDITION: '条件保存',
	// MESSAGE
	MSG_CHECK_INFO_AGAIN: 'もう一度情報を確認してください',
	MSG_COURSES_IS_REQUIRED: 'コースを選択してください。',
	MSG_ADDING_COURSE: 'コースを追加しています。',
	MSG_ADD_COURSE_SUCCESS: 'コースが正常に追加されました。',
	MSG_ADD_COURSE_FAIL:
		'コース追加に失敗しました。既存のコースを追加できません。',
	MSG_CONDITION_IS_REQUIRED: '適用条件をご選択ください。'
}
//MANAGE BILL
const BILL_LIST = {
	TITLE: '注文管理',
	LABEL_FILTER_BY_EMAIL_STUDENT: 'ユーザーのメールアドレス',
	PLACEHOLDER_INPUT_EMAIL_STUDENT:
		'ユーザーのメールアドレスにより検索してください。',
	LABEL_AVAILABLE_AND_EXPIRED_DATE: '開始日・終了日',
	PLACEHOLDER_INPUT_SEARCH_TIME: '期間により検索してください。',
	LABEL_STATUS_BILL: '注文状態',
	TEXT_STATUS_ALL: '全て',
	TEXT_STATUS_WAITTING: '待機中',
	TEXT_STATUS_FAIL: '失敗',
	TEXT_STATUS_COMPLATED: '完了',
	TEXT_STATUS_REFUSED: '拒否済み',
	TEXT_STATUS_PAID: '支払い済み',
	// TABLE
	TH_STUDENT: 'ユーザー',
	TH_CONTENT: '内容',
	TH_TIME: '時間',
	TH_PAYMENT_METHOD: '支払い',
	TH_VALUE: '単価',
	TH_STATUS: '状態',
	TH_ACTION: 'アクション',
	TEXT_STUDENT_CONTACT_INFO: '連絡情報',
	TEXT_COURSE_UNIT: 'コース',
	TEXT_EBOOK: '電子書類',
	TEXT_OTHER_PRODUCT: '商品',
	//BILL TABLE DETAIL
	TH_BILL_VALUE_BEFORE_DISCOUNT: '値引前の価格(ベトナムドン)',
	TH_BILL_VALUE_AFTER_DISCOUNT: '値引後の価格(ベトナムドン)',
	TEXT_RECEIVER_ADDRESS: '住所',
	TEXT_RECEIVER_NAME: '受信者名',
	TEXT_RECEIVER_PHONE: '電話番号',
	TEXT_EMAIL: 'メールアドレス',
	TEXT_PRICE_BEFORE_DISCOUNT: '仮計算',
	TEXT_COMBO_IS_APPLIED: 'コンボ適用',
	TEXT_COUPON_IS_APPLIED: 'クーポン適用',
	TEXT_FINAL_PRICE: '合計金額',
	//
	BUTTON_REFUND_ALL: '全て払い戻し',
	BUTTON_REJECT_ALL: '全て拒否',
	BUTTON_APPROVE_ALL: '全て承認',
	BUTTON_REFUND: '払い戻し',
	BUTTON_REJECT: '拒否',
	//
	POPUP_CONFIRM_REFUND_BILL_TITLE: '注文を払い戻ししてもよろしいですか?',
	POPUP_CONFIRM_REFUND_BILL_MESSAGE:
		'払い戻しされた注文は状態が編集できません。ユーザーは該当の注文における学習コンテンツへアクセスできなくなります。該当の注文は今期の売上として計上されません。',
	//
	POPUP_CONFIRM_APPROVE_BILL_TITLE:
		'該当の注文が支払われたことを確認しますか？',
	POPUP_CONFIRM_APPROVE_BILL_MESSAGE:
		'確認したらユーザーにコースの学習権限に付与します。',

	POPUP_CONFIRM_REJECT_BILL_TITLE: '注文を拒否してもよろしいでしょうか？',
	POPUP_CONFIRM_REJECT_BILL_MESSAGE:
		'拒否された注文は、再度承認したり、売上として計上したりすることはできません。',
	//
	POPUP_CONFIRM_REFUND_MANY_BILLS_TITLE:
		'全ての注文を払い戻ししてもよろしいですか?',
	POPUP_CONFIRM_REFUND_MANY_BILLS_MESSAGE:
		'払い戻しされた注文は状態が編集できません。ユーザーは該当の注文における学習コンテンツへアクセスできなくなります。該当の注文は今期の売上として計上されません。',
	//
	POPUP_CONFIRM_APPROVE_MANY_BILLS_TITLE:
		'全ての注文が支払われたことを確認しますか?',
	POPUP_CONFIRM_APPROVE_MANY_BILLS_MESSAGE:
		'確認したらユーザーにコースの学習権限に付与します。',
	//
	POPUP_CONFIRM_REJECT_MANY_BILLS_TITLE:
		'全ての注文を拒否してもよろしいでしょうか？',
	POPUP_CONFIRM_REJECT_MANY_BILLS_MESSAGE:
		'拒否された注文は、再度承認したり、売上として計上したりすることはできません。',
	//MESSAGE
	MSG_UPDATING: 'データを更新しています。',
	MSG_UPDATE_SUCCESS: 'データが正常に更新されました。'
}
// MANAGE TRANSACTION
const TRANSACTION_LIST = {
	TITLE: '売上',
	//STATISTIC
	TITLE_STATISTIC: '集計',
	LABEL_TIME_BY_MONTH: '月期',
	LABEL_NUMBER_OF_BILLS: '月内で発生注文数',
	LABEL_REVENUE: '売上実績',
	LABEL_NUMBER_OF_SUCCESS_BILLS: '支払った注文数',
	TITLE_TRANSACTION_LIST: '詳細',
	TH_STUDENT: '購入者',
	TH_VALUE: '価格',
	TH_CREATED_DATE: '作成日',
	TH_STATUS: '状態',
	TEXT_STATUS_SUCCES: '成功',
	TEXT_STATUS_WAITING: '待機中',
	TEXT_STATUS_FAIL: '失敗',
	TEXT_CURRENT_UNIT: 'ベトナムドン'
}
//MANAGE TEACHER TRANSACTION
const TEACHER_TRANSACTION_LIST = {
	TITLE: '講師の売上',
	TITLE_STATISTIC: '集計',
	LABEL_BILL_QUANTITY: '月度の請求書',
	LABEL_REVENUE: '売上',
	LABEL_COURSE_QUANTITY_SOLD_IN_SIX_MONTH: '6か月で販売されたコースの数',
	LABEL_TOTAL_BILL: '注文合計',
	LABEL_REVENUE_IN_SIX_MONTH: '6か月での売上',
	LABEL_TOTAL_REVENUE: '総売上',
	LABEL_CURRENCY_UNIT: '（ベトナムドン）',
	LABEL_STATISTICS_WITH_SUCCESSFUL_ORDERS:
		'統計データは、成功のステータスの注文に適用されます',
	GRAPH_STATISTIC: {
		LABEL_REVENUE: '売上 (千)',
		LABEL_REGISTER_QUANTITY: '登録数'
	}
}
//MANAGE CONTACT
const CONTACT_LIST = {
	TITLE: '連絡先',
	TAB_CONTACT_WITHIN_BILL: '注文',
	TAB_CONTACT_WITHIN_CONTACT_FORM: '連絡先',
	//
	LABEL_FILTER_BY_TIME: '時間フィルター',
	PLACEHOLDER_INPUT_TIME: '時間フィルターをしてください。',
	OPTION_TIME_SCOPE_WITHIN_THE_MONTH: '1ヶ月以内',
	OPTION_TIME_SCOPE_UNLIMITED: '全て',
	// ORDER
	LABEL_FILTER_BY_PRODUCT_NAME: '商品名',
	PLACEHOLDER_INPUT_PRODUCT_NAME: '商品名を入力してください。',
	TH_EMAIL: 'メールアドレス',
	TH_PHONE: '電話番号',
	MSG_FILTERING_BILL: '請求書をフィルタリングしてください。',
	// CONTACT
	LABEL_SEARCH_BY_INFOS: '連絡先検索',
	PLACEHOLDER_INPUT_INFOS: '下記のテーブルにより情報を入力してください',
	TH_DATE: '日付',
	TH_STUDENT_NAME: 'ユーザーの氏名',
	TH_PRODUCT_NAME: '関心商品の名称',
	MSG_FILTERING: '連絡先をフィルタリングしています。'
	//TH_EMAIL: 'メールアドレス,
	//TH_PHONE: '電話番号',
	//MSG_FILTERING: '連絡先をフィルタリングしています。'
}
//MANAGE AFFILIATE
const AFFILIATE_LIST = {
	TITLE: 'アフィリエイト管理',
	BUTTON_ADD: 'KOL追加',
	LABEL_FILTER_BY_NAME_EMAIL: 'KOLの氏名',
	PLACEHOLDER_INPUT_NAME_EMAIL:
		'KOLの氏名とメールアドレスにより検索してください',
	TH_NAME: 'KOL氏名',
	TH_EMAIL: 'メールアドレス',
	TH_CREATED_DATE: '作成日',
	TH_STATUS: '状態',
	TH_ACTION: 'アクション',
	TOOLTIP_EDIT_AFFILIATE: 'アフィリエイトを編集',
	TEXT_STATUS_CONFIRMED: '確認済み',
	TEXT_STATUS_UNCONFIRMED: '未定',
	POPUP_CONFIRM_DELETE_TITLE: 'KOL削除',
	POPUP_CONFIRM_DELETE_MESSAGE: '削除されたデータは回復できません。',
	//MESSAGE
	MSG_DELETE_SUCCESS: 'KOLが正常に削除されました。',
	// EMPTY DATA
	TITLE_EMPTY_DATA: 'KOLが存在していません',
	SUBTITLE_EMPTY_DATA: 'KOL一覧を表示するには新規作成してください。'
}
const FORM_CREATE_NEW_AFFILIATE = {
	TITLE: 'KOLの新規追加',
	LABEL_FULL_NAME: '氏名',
	PLACEHOLDER_INPUT_FULL_NAME: 'KOLの氏名を入力してください',
	LABEL_PHONE: '電話番号',
	PLACEHOLDER_INPUT_PHONE: 'KOLの電話番号を入力してください',
	LABEL_EMAIL: 'メールアドレス',
	PLACEHOLDER_INPUT_EMAIL: 'KOLのメールアドレスを入力してください',
	LABEL_PASSWORD: 'パスワード',
	PLACEHOLDER_INPUT_PASSWORD: 'パスワードを入力してください',
	LABEL_CONFIRM_PASSWORD: 'パスワードを再度入力してください。',
	PLACEHOLDER_INPUT_CONFIRM_PASSWORD: 'パスワードを再度入力してください。',
	LABEL_DESCRIPTION: 'KOLの説明',
	PLACEHOLDER_DESCRIPTION: '説明を入力してください',
	TITLE_AVATA: 'アバター',
	MSG_CREATE_SUCCESS: 'KOLが正常にされました。',
	MSG_CHECK_INFO_AGAIN: 'もう一度情報を確認してください。'
}
const AFFILIATE_DETAIL = {
	TITLE: 'KOL詳細',
	LINK_RETURN_TO_AFILIATE_LIST: 'アフィリエイト一覧',
	TAB_CONFIGURATION: '構成',
	TAB_REPORT: 'レポート',
	// CONFIG_KOL
	TITLE_CONFIGURATION: '構成',
	SUBTITLE_CONFIGURATION: 'KOLの構成設定',
	LABEL_FULL_NAME: '氏名',
	PLACEHOLDER_INPUT_FULL_NAME: 'KOLの氏名を入力してください',
	LABEL_PHONE: '電話番号',
	PLACEHOLDER_INPUT_PHONE: 'KOLの電話番号を入力してください。',
	LABEL_EMAIL: 'メールアドレス',
	PLACEHOLDER_INPUT_EMAIL: '例： hanoiltv@gmail.com',
	TEXT_LIST: '一覧',
	TEXT_INTRODUCED: '紹介済み',
	LABEL_PASSWORD: 'パスワード',
	PLACEHOLDER_INPUT_PASSWORD: 'パスワードを入力してください',
	LABEL_INPUT_PASSWORD_AGAIN: 'パスワード再度入力',
	PLACEHOLDER_INPUT_PASSWORD_AGAIN: 'パスワードを再入力してください',
	LABEL_DESCRIPTION_KOL: 'KOLの説明',
	PLACEHOLDER_INPUT_DESCRIPTION: '説明を入力してください',
	TITLE_AVATA: 'アバター',

	//TAB
	TAB_ONLINE_COURSE: 'オンラインコース',
	TAB_OFFLINE_COURSE: 'オフラインコース',
	TAB_EBOOK: '電子書籍',
	TAB_EVENT: 'イベント',
	//
	TH_NAME: 'キャンペーン名',
	TH_LINK: 'リンク',
	TH_AVAILABLE_AT: '使用開始時間',
	TH_EXPIRED_AT: '有効期限',
	TH_COMMISSION: '手数料',
	TH_ACTION: 'アクション',

	//LIST_PRODUCT_AFFILIATE
	BUTTON_ADD: 'キャンペーン追加',
	TEXT_CURRENT_UNIT: 'ベトナムドン',

	MSG_COPY_SUCCESS: '正常にコピーできました。',
	MSG_COPY_FAIL: 'コピーできませんでした。’. もう一度お試しください。',
	MSG_UPDATE_SUCCESS: 'データが正常に更新されました。',
	MSG_CHECK_PASSWORD_AGAIN: 'パスワードが一致しません。',
	//MSG_CHECK_INFO_AGAIN: 'もう一度情報をご確認ください',
	POPUP_CONFIRM_DELETION_TITLE: 'キャンペーン削除',
	POPUP_CONFIRM_DELETION_MESSAGE: '削除されたデータは回復できません。',
	MSG_DELETE_AFILIATE_SUCCES: 'アフィリエイトが正常に削除されました。',
	//REPORT
	TITLE_REPORT: 'レポート',
	SUBTITLE_REPORT: 'KOLによる紹介キャンペーンの効果のレポート',
	//月期
	LABEL_NUMBER_OF_BILLS: '月内の注文数',
	LABEL_REVENUE: '売上実績',
	LABEL_COMMISSION: '手数料',
	//
	TH_BILL_STUDENT: 'ユーザー',
	TH_BILL_CONTENT: '本文',
	TH_BILL_VALUE: '単価（ベトナムドン）',
	TH_BILL_STATUS: 'ステータス',
	TH_BILL_CREATED_AT: '時間',
	TH_BILL_PAYMENT_METHOD: '支払い方法',
	TEXT_NO_MAIL: 'メール無し'
}
//POPUP ADD PROGRAM FOR KOL
const POPUP_ADD_PROGRAM = {
	TITLE_CREATE: 'キャンペーンの作成',
	TEXT_NEW: '新規',
	LABEL_COURSE: 'コース選択',
	PLACEHOLDER_SELECT_COURSE: 'コースを検索してください',
	LABEL_EBOOK: '電子書類選択',
	PLACEHOLDER_SEARCH_EBOOK: '電子書類を検索してください',
	LABEL_EVENT: 'イベント選択',
	LABEL_DISCOUNT_TYPE: '割引タイプ',
	LABEL_DISCOUNT_VALUE: '割引価値',
	LABEL_COMMISSION_TYPE: '手数料タイプ',
	OPTION_COMMISSION_PERCENT: 'パーセント (%)',
	OPTION_COMMISSION_FIX_VALUE: '固定(ベトナムドン)',
	LABEL_COMMISSION_VALUE: '手数料価値',

	LABEL_AVAILABLE_DATE: '開始日',
	LABEL_AVAILABLE_HOUR: '開始時間',
	LABEL_EXPIRED_DATE: '終了日',
	LABEL_EXPIRED_HOUR: '終了時間',
	MSG_CREATING_AFFILIATE: 'アフィリエイトを作成しています。',
	MSG_CREATE_AFFILIATE_SUCCESS: 'アフィリエイトが正常に作成されました。',
	MSG_CHECK_INFO_AGAIN: 'もう一度情報をご確認ください。',

	//BASE_TREE_SELECT_TOPIC
	TITLE_TREE_SELECT_TOPIC: 'トピックが選択されていません',
	//BaseCourseOfflinePicker
	LABEL_FILTER_COURSE: 'コース選択',
	PLACEHOLDER_FILTER_COURSE: '入力して検索してください',
	//BaseEventPicker
	LABEL_SELECT_EVENT: 'イベント選択',
	MSG_GETTING_EVENT: 'イベントを取得しています。'
	//BaseBookPicker
}
export default {
	ACTIVATION_CODE_CAMPAIGN_LIST,
	FORM_CREATE_NEW_ACTIVATION_CODE_CAMPAIGN,
	CAMPAIGN_ACTIVATION_CODE_DETAIL,
	COUPON_CAMPAIGN_LIST,
	FORM_CREATE_NEW_COUPON_CAMPAIGN,
	COUPON_CAMPAIGN_DETAIL,
	COMBO_CAMPAIGN_LIST,
	COMBO_CAMPAIGN_DETAIL,
	FORM_CREATE_NEW_COMBO_CAMPAIGN,
	BILL_LIST,
	TRANSACTION_LIST,
	TEACHER_TRANSACTION_LIST,
	POPUP_ADD_CONDITION,
	CONTACT_LIST,
	AFFILIATE_LIST,
	FORM_CREATE_NEW_AFFILIATE,
	AFFILIATE_DETAIL,
	POPUP_ADD_PROGRAM,
	BUTTON_CANCEL_TEXT: 'キャンセル',
	BUTTON_CANCEL: 'キャンセル',
	BUTTON_CONFIRM: '確認',
	BUTTON_EDIT: '修正',
	BUTTON_SAVE: '保存',
	BUTTON_ADD: '追加',
	BUTTON_SEARCH: '検索',
	MSG_UPDATING_INFO: '情報を更新しています。',
	MSG_UPDATE_SUCCESS: '正常に更新されました。',
	MSG_SYSTEM_LOADING:
		'システムがデータを読み込んでいます。後でもう一度お試しください。',
	MSG_DATA_SYSTEM_LOADING: 'データを読み込んでいます。',
	MSG_UPDATING_DATA: 'データを更新しています。',
	MSG_UPDATE_DATA_SUCCESS: 'データが正常に更新されました。',
	MSG_GETTING_DATA: 'データを取得しています',
	MSG_CHECK_INFO_AGAIN: 'もう一度情報をご確認ください。',
	MSG_CHECH_KEYWORD_QUANTITY_AGAIN: '2つ以上のキーワードをご選択ください。',
	MSG_CHECK_PICK_COURSE_AGAIN: 'コースをご選択ください。'
}
