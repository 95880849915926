const OPTIONS = {
  ONLINE_COURSE_TOPIC_LABEL: 'Online course directory',
  ONLINE_COURSE_TOPIC_DESCRIPTION: 'Display menu items. If there are less than 3 categories, the display of categories should not be enabled.',
  
  PROGRAM_LABEL: 'Programme',
  PROGRAM_DESCRIPTION: 'Online Courses, Offline Courses, Events, and Ebooks.',

  ACTIVE_COURSE_LABEL: 'Activate course',
  ACTIVE_COURSE_DESCRIPTION: 'Course activation page via activation code.',

  SEARCH_BAR_LABEL: 'Search Bar',
  SEARCH_BAR_DESCRIPTION: 'The search bar allows users to find content on the website. Should not be activated if the site has few courses.',

  DEPARTMENT_LABEL: 'Basis',
  DEPARTMENT_DESCRIPTION: 'Factory listing page. Basic content can be found in Course content',

  BLOG_LABEL: 'Blog',
  BLOG_DESCRIPTION: 'Website blog post listing page',

  ABOUT_US_LABEL: 'About us',
  ABOUT_US_DESCRIPTION: 'About the site',

  POLICY_LABEL: 'Privacy Policy',
  POLICY_DESCRIPTION: 'Policy page - privacy policy',

  RECRUITMENT_LABEL: 'Become a trainer',
  RECRUITMENT_DESCRIPTION: 'Trainer recruitment page',

  ONLINE_COURSE_LABEL: 'Online Course',
  ONLINE_COURSE_DESCRIPTION: 'Course - Online Course List',

  OFFLINE_COURSE_LABEL: 'Offline course',
  OFFLINE_COURSE_DESCRIPTION: 'Course - Offline Course List',

  EVENT_LABEL: 'Event',
  EVENT_DESCRIPTION: 'Agenda - Event List',

  EBOOK_LABEL: 'E-BOOK',
  EBOOK_DESCRIPTION: 'Course - Electronic Document List',

  ACTIVE_CODE_LABEL: 'Activate course',
  ACTIVE_CODE_DESCRIPTION: 'Course activation page via activation code',

  PHONE_LABEL: 'Phone number',
  PHONE_DESCRIPTION: 'Phone number - Contact information',

  EMAIL_LABEL: 'Email',
  EMAIL_DESCRIPTION: 'Email - Contact Information',

  ADDRESS_LABEL: 'Address',
  ADDRESS_DESCRIPTION: 'Address - Contact Information',

  SOCIAL_NETWORK_LABEL: 'Social Network Link',
  SOCIAL_NETWORK_DESCRIPTION: 'Install the paths at General Settings / Advanced Settings / Brands',
}
const TOPIC_SETTING_DETAIL={
  TITLE:"Customize Categories",
  SUBTITLE:"Customize the categories displayed in the categories section on the top menu",
  LABEL_DISPLAY_TOPIC:"Display custom categories instead of default categories",
  TITLE_TOPIC_CONFIG:"Configure Categories",
  BUTTON_ADD:"Add New",
  BUTTON_SAVE:"Save",

  POPUP_EDIT:{
    LABEL_USE:"Use category category",
    LABEL_TOPIC:"Category",
    PLACEHOLDER_SELECT:"Select category",
    LABEL_NAME:"Topic name*",
    PLACEHOLDER_INPUT_NAME:"Enter an topic name",
    LABEL_LINK: "Path",
    PLACEHOLDER_INPUT_LINK_ATTACHED:"Enter link with item name",
    TITLE_ADD:"Add topic",
  },
  POPUP_CONFIRM_DELETE_TITLE:'Confirm directory deletion',
  POPUP_CONFIRM_DELETE_MESSAGE:'Are you sure you want to delete this topic?',

  POPUP_CONFIRM_SAVE_TITLE:'Save Changes',
  POPUP_CONFIRM_SAVE_MESSAGE:'Are you sure you want to save your changes?',
  
}
export default {
  OPTIONS,
  TOPIC_SETTING_DETAIL,
  TITLE: 'Menu settings',
  TAB_TOPIC: 'Topic',
  
  TAB_HEADER: 'Header Menu',
  TAB_FOOTER: 'Last Menu (Footer)',

  SECTION_HEADER_TITLE: 'Top menu',
  SECTION_HEADER_DESCRIPTION: 'Select the field you want to display in the menu bar at the top of the page. For nice display, no more than 3 fields should be enabled.',
  SECTION_HEADER_OPTION_DISPLAY_MARKET_HEADER: 'Show top menu in e-commerce format',

  SECTION_FOOTER_TITLE: 'Final menu',
  SECTION_FOOTER_DESCRIPTION: 'Select the field you want to display in the menu bar at the bottom of the page.',

  MSG_UPDATING_DATA: "Updating data...",
  MSG_UPDATE_SUCESS:"Update successful!",
  MSG_UPDATE_FAIL:"Update failed",
  MSG_CHECK_INFO_AGAIN: 'Please check infomation again !',

}