import axios from 'axios'
import auth from '@/store/modules/auth'
const BASE_URL = process.env.VUE_APP_API_VENDOR
const NOTIFY_URL = process.env.VUE_APP_API_NOTIFY
import Repository from '@/api/core'
import vendor from '@/store/modules/vendor'

const APIHelper = () => ({
  setupAccountMail: (params, option) =>
    axios.put(
      BASE_URL + `/vendor-credential/${auth.state.auth.vendorId}`,
      {...params},
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      option
    ),
  getAccountMail: () =>
    Repository(
      BASE_URL + `/vendor-credential/${auth.state.auth.vendorId}`
    ).fetch(),
  createOrUpdateTemplate: (params) =>
    Repository(NOTIFY_URL + `/template`).create({...params}),
  getTemplates: (params) =>
    Repository(NOTIFY_URL + `/template`).fetch({...params}),
  sendMail: (params) => Repository(NOTIFY_URL + `/email/send`).create(params),
  sendMailToGroup: (params) =>
    Repository(NOTIFY_URL + `/email/group`).create(params),
  getMail: (params) => Repository(NOTIFY_URL + `/email`).fetch(params),
  countMail: (params) => Repository(NOTIFY_URL + `/email/count`).fetch(params),
  sendMailVerify: (params) =>
    Repository(NOTIFY_URL + `/email/send-verify`).create(params),
  checkMailVerifiled: (params) =>
    Repository(NOTIFY_URL + `/email/check-verified`).create(params),
  confirmMailVerifiled: (params) =>
    Repository(NOTIFY_URL + `/email/confirm-verified`).create(params),
  getTemplateEmail: (params) =>
    Repository(NOTIFY_URL + '/template-email').fetch(params),
  updateDefaultTemplateEmail: (params) =>
    Repository(NOTIFY_URL + '/template-email/update-default').update(null, {
      ...params,
      vendorId: vendor.state.vendor.vendorId,
    }),
  rollbackTemplateDefaultEmail: (params) =>
    Repository(NOTIFY_URL + '/template-email/rollback-default').update(null, {
      ...params,
      vendorId: vendor.state.vendor.vendorId,
    }),
  getTemplateDefault: (params) =>
    Repository(NOTIFY_URL + '/template-email/default').fetch(params),
})
export const Mail = APIHelper()
export default {
  ...Mail,
}
