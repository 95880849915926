import Repository from '@/api/core'
import axios from 'axios'
const baseURL = process.env.VUE_APP_API_GROUP + '/user-permission'
const adminUrl = process.env.VUE_APP_API_TEACHER

const fetch = (params) => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const count = (params) => {
  return Repository(baseURL + '/count').fetch(params)
}

const addProducts = (params) => {
  return axios.put(baseURL + `/add-products/${params.userId}`, params)
}

const removeProducts = (params) => {
  return axios.put(baseURL + `/remove-products/${params.userId}`, params)
}

const setLimitedTime = (id, params) => {
  return axios.put(baseURL + `/set-limited-time/${id}`, params)
}
const check = (params) => {
  return Repository(baseURL + `/${params.userId}`).fetch(params.payload)
}

const upgradeAccountForPreview = (params) => {
  return axios.post(adminUrl + `/preview`, params)
}

export default {
  fetch,
  fetchOne,
  addProducts,
  removeProducts,
  check,
  count,
  setLimitedTime,
  upgradeAccountForPreview,
}
