import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Collection = RepositoryFactory.get('evaluationCollection')
const namespaced = true
import vendor from '@/store/modules/vendor'
import dayjs from 'dayjs'
import utils from '../../helpers/utils'

const state = {
  collections: [],
  collection: {},
  timeCreatedClassCollections: '',
  currentDate: '',
}

const actions = {
  async fetchCollections({commit}, params) {
    await utils.delay(200)
    const res = await Collection.fetch({
      ...params,
      vendorId: vendor.state.vendor.vendorId,
      _populate: 'template',
      _sort: 'date:ASC',
    })
    commit('setCollections', res.data || [])
    return res.data || []
  },
  async fetchCollection({commit}, id) {
    const res = await Collection.fetchOne(id)
    commit('setCollection', res.data || {})
    return res.data || {}
  },
  async updateCollection({commit}, {id, collection}) {
    const res = await Collection.update(id, collection)
    commit('updateCollection', res.data || {})
    return res.data || {}
  },
  async removeCollection({commit}, id) {
    await Collection.remove(id)
    commit('removeCollection', id)
  },
  async sendMailAndNotify(_, params) {
    const res = await Collection.sendMailAndNotify({...params, vendorId: vendor.state.vendor.vendorId})
    return res.data || {}
  },
  setTimeCreatedClassCollections({commit}, date) {
    commit('setTimeCreatedClassCollections', date)
  },
  setCurrentDate({commit}, date) {
    commit('setCurrentDate', date)
  },
  refreshCollection({commit}) {
    commit('refreshCollection')
  },
}
const mutations = {
  setCollections(state, collections) {
    state.collections = collections
  },
  setCollection(state, collection) {
    state.collection = collection
  },
  updateCollection(state, collection) {
    const index = state.collections.findIndex((v) => v.id === collection.id)
    if (index > -1) {
      state.collections.splice(index, 1, collection)
    }
  },
  removeCollection(state, id) {
    const index = state.collections.findIndex((v) => v.id === id)
    if (index > -1) {
      state.collections.splice(index, 1)
    }
  },
  setTimeCreatedClassCollections(state, date) {
    if (typeof date === 'string' && date.length)
      state.timeCreatedClassCollections = `${dayjs(date).format('DD/MM/YYYY')}, lúc ${dayjs(date).format('HH:mm')}`
  },
  setCurrentDate(state, date) {
    state.currentDate = dayjs(date).format('DD/MM/YYYY')
  },
  refreshCollection() {
    state.collections = []
  },
}
const getters = {
  getAllCollections: (state) => state.collections,
  getCollection: (state) => state.collection,
  getTime: (state) => state.timeCreatedClassCollections,
  getDate: (state) => state.currentDate,
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
