const PASSWORD_CHANGE_FORM = {
	TITLE: 'Change new password',
	LABEL_OLD_PASSWORD: 'Old Password',
	PLACEHOLDER_OLD_PASSWORD: 'Enter old password',
	LABEL_NEW_PASSWORD: 'New Password',
	PLACEHOLDER_NEW_PASSWORD: 'Enter new password',
	LABEL_NEW_PASSWORD_RETYPE: 'Confirm password',
	PLACEHOLDER_NEW_PASSWORD_RETYPE: 'Enter new password',
	RULE_NEW_PASSWORD_RETYPE: 'Repeat password is not ',
	HINT_PASSWORD:"Password contains at least 6 characters",
	BUTTON_UPDATE: 'Change Password',
	//MESSAGE
	MSG_UPDATE_LOADING: 'Requesting...',
	MSG_UPDATE_SUCCESS: 'Password Change Successful!',
	MSG_INFO_INCORRECT: 'Check information',
	MSG_WRONG_OLD_PASSWORD: 'Your old password is wrong!',
	MSG_UPDATE_FAIL: 'Password Change Fail! Please refresh and try again later!',
	}
	const CONTACT_INFO_UPDATE_FORM = {
	TITLE: 'Contact',
	TITLE_FORM: 'Set up contact information',
	LABEL_DISPLAY_NAME: "First and last name",
	PLACEHOLDER_DISPLAY_NAME: 'Ex: Nguyen Van A',
	LABEL_PHONE_NUMBER: 'Phone number (Hotline)',
	PLACEHOLDER_PHONE_NUMBER: 'Example: 0123321000',
	LABEL_EMAIL: 'Email',
	PLACEHOLDER_EMAIL: 'Ex: hanoiltv@gmail.com',
	LABEL_ADDRESS: 'Address',
	PLACEHOLDER_ADDRESS: 'Example: 123 Hanoi',
	HINT_ADDRESS:
	'Note: When using multiple addresses, the addresses must be separated by a "{0}"',
	BUTTON_UPDATE: 'Update',
	//MESSAGE
	MSG_INFO_INCORRECT: 'Check information',
	MSG_UPDATE_SUCCESS: 'Update successful!'
	}
	export default {
	PASSWORD_CHANGE_FORM,
	CONTACT_INFO_UPDATE_FORM,
	BUTTON_UPDATE:"Update"
	}