<template>
	<span>
		<v-autocomplete
			class="body-2"
			v-bind="this.$attrs"
			:loading="isLoading"
			:items="items"
			:search-input.sync="textInput"
			:item-text="itemText"
			:item-value="itemValue"
			:placeholder="placeholder || $vuetify.lang.t('$vuetify.USER_MANAGE.STUDENT_LIST.LABEL_SELECT_STUDENT_GROUP')"
			:label="label || $vuetify.lang.t('$vuetify.USER_MANAGE.STUDENT_LIST.PLACEHOLDER_SELECT_STUDENT_GROUP')"
			:value="value"
			:multiple="isMultipleSelect"
			:chips="isMultipleSelect"
			outlined
			return-object
			dense
			@change="value => onChangeValue(value)"
		>
			<template v-if="isMultipleSelect" v-slot:selection="data">
				<v-chip
					small
					v-if="!data.item.disabled"
					v-bind="data.attrs"
					:input-value="data.selected"
				>
					{{ data.item.title }}
				</v-chip>
			</template>
		</v-autocomplete>
	</span>
</template>

<script>
import debounce from 'lodash-es/debounce'
import {mapActions, mapGetters} from 'vuex'
export default {
	props: {
		value: [String, Array, Object],
		itemText: String,
		itemValue: String,
		type: String,
		isMultipleSelect: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
		},
		label: {
			type: String,
		}
	},
	data() {
		return {
			listSelected: [],
			isLoading: false,
			items: [],
			textInput: ''
		}
	},
	methods: {
		...mapActions({
			fetchGroups: 'group/fetchGroups'
		}),
		onChangeValue(selected) {
			if (this.isMultipleSelect) {
				this.listSelected = selected
			} else {
				this.listSelected = selected ? [selected] : []
			}
			this.$emit('change', this.isMultipleSelect ? this.listSelected : selected)
			this.$emit('input', this.isMultipleSelect ? this.listSelected : selected)
		},
		debounce: debounce(function() {
			this.onUpdateSearchString(this.textInput)
		}, 1000),
		onUpdateSearchString(textInput) {
			const searchString = textInput ? this.$utils.clearUnicode(textInput) : ''
			if (this.isLoading) return
			this.fetchGroupWithSearchString(searchString)
		},
		async refreshItems() {
			await this.$utils.delay(500)
			this.handlerData(this.items)
		},
		handlerData(items) {
			if (this.isMultipleSelect) {
				this.items = items.map(group => {
					let groupSelected = this.value.find(v => v.id === group.id)
					return {
						...group,
						disabled: groupSelected ? groupSelected.disabled : false
					}
				})
			} else this.items = [...items]
			this.items.sort((a, b) => {
				let nameA = a.title.toUpperCase()
				let nameB = b.title.toUpperCase()
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0
			})
		},
		async fetchGroupWithSearchString(searchString) {
			try {
				this.isLoading = true
				this.items = await this.fetchGroups({
					_limit: 10,
					_skip: 0,
					tags_contain: searchString
				})
				if (this.listSelected && this.listSelected.length) {
					this.items = this.items.concat(
						this.listSelected ? this.listSelected : []
					)
				}
				this.handlerData(this.items)
				this.isLoading = false
			} catch (error) {
				this.$message.error(error)
			}
		}
	},
	computed: {
		...mapGetters({
			groups: 'group/getGroups'
		})
	},
	watch: {
		textInput: {
			handler() {
				this.debounce()
			},
			immediate: true
		},
	}
}
</script>
<style lang="scss" scoped>
::v-deep .v-list-item--disabled {
	opacity: 0.5;
}
</style>
