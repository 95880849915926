import get from 'lodash-es/get'

const getStatsFromPart = (part = {}, answers = {}, questions = []) => {
  let correctQuantity = 0
  let totalQuantity = 0
  let percentage = 0

  part.questions.forEach((partQuestionId) => {
    const question = questions.find((x) => x.id === partQuestionId)
    if (question && question.type.includes('group')) {
      question.questions.forEach((childQuestion) => {
        if (get(answers, `${childQuestion.id}.isCorrect`, false)) correctQuantity += 1
      })
      totalQuantity += question.questions.length
    } else {
      if (get(answers, `${partQuestionId}.isCorrect`, false)) correctQuantity += 1
      totalQuantity += 1
    }
  })
  if (!totalQuantity) percentage = 0
  else percentage = (correctQuantity / totalQuantity) * 100

  return {
    title: part.title,
    correctQuantity,
    totalQuantity,
    percentage,
  }
}

const getStatsFromPartition = (partitions, answers = {}, questions = []) => {
  const res = []
  if (Array.isArray(partitions) && partitions.length) {
    partitions.forEach((part) => {
      res.push(getStatsFromPart(part, answers, questions))
    })
  } else if (typeof partitions === 'object') {
    Object.values(partitions).forEach((part) => {
      res.push(getStatsFromPart(part, answers, questions))
    })
  }
  return res
}

const getConfigText = (configs = [], correctQuantity = 0) => {
  let res = ''
  const sortedConfigs = configs
    .map((x) => ({
      text: x.text,
      value: Number(x.value),
    }))
    .sort((a, b) => a.value - b.value)
  for (let i = 0; i < sortedConfigs.length; i += 1) {
    const config = sortedConfigs[i]
    if (get(config, 'value', 0) >= correctQuantity) {
      res = config.text
      break
    }
  }
  return res
}

const getReviewDetail = (reviewConfig = {}, reviewStats = []) => {
  const res = []
  if (!reviewConfig) return res
  if (reviewStats.length) {
    reviewStats.forEach((stat, statIndex) => {
      const {title} = stat
      let text = ''
      const configs = reviewConfig[statIndex]
      if (Array.isArray(configs) && configs.length) {
        text = getConfigText(configs, stat.correctQuantity)
      }
      res.push({title, text, noPrefix: false})
    })
    const totalReviewConfigs = reviewConfig.total
    if (Array.isArray(totalReviewConfigs) && totalReviewConfigs.length) {
      res.push({
        title: 'Tổng kết',
        text: getConfigText(
          totalReviewConfigs,
          reviewStats.map((x) => x.correctQuantity).reduce((a, b) => a + b, 0)
        ),
        noPrefix: true,
      })
    }
  }
  return res
}

export {getStatsFromPartition, getReviewDetail}
