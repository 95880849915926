import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Resource = RepositoryFactory.get('resource')
const namespaced = true

const state = {
  audios: {},
  audio: {},
  count: 0,
  page: 1,
  itemsPerPage: 20,
  audioSelected: {} 
}

const actions = {
  async fetchAudio({commit}, params = {}) {
    const res = await Resource.fetch({
      ...params,
    })
    commit('setAudios', res.data || [])
    return res.data
  },
  async countAudios({commit}, params = {}) {
    const res = await Resource.count({
      ...params,
    })
    commit('setCount', res.data || 0)
  },
  async removeAudio({commit}, id) {
    await Resource.remove(id)
    return commit('removeAudio', id)
  },
  async getLinkDownload(_, id) {
    const res = await Resource.getDownloadUrl(id)
    return res.data
  },
  setPage({commit}, data) {
    commit('setPage', data)
  },
  setAudioSelected({commit}, data) {
    commit('setAudioSelected', data)
  }
}

const mutations = {
  setCount(state, count) {
    return (state.count = count)
  },
  setAudios(state, audios) {
    return (state.audios = audios.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {}))
  },
  addAudio(state, audio) {
    state.count = state.count + 1
    return (state.audios = {
      [audio.id]: audio,
      ...state.audios,
    })
  },
  removeAudio(state, id) {
    delete state.audios[id]
    state.audios = {...state.audios}
    state.count = state.count - 1
  },
  setPage(state, data) {
    state.page = data.page ?? state.page
    state.itemsPerPage = data.itemsPerPage ?? state.itemsPerPage
  },
  setAudioSelected(state, data) {
    state.audioSelected = data
  },
}

const getters = {
  audios: (state) => {
    return Object.values(state.audios)
  },
  count: (state) => {
    return state.count
  },
  audio: (state) => {
    return state.audio
  },
  page: (state) => {
    return state.page
  },
  itemsPerPage: (state) => {
    return state.itemsPerPage
  },
  audioSelected: (state) => {
    return state.audioSelected
  },
}
export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
