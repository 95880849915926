import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const ActiveCode = RepositoryFactory.get('activeCode')

const state = {
  activeCodes: [],
  activeCode: {},
  codeByStatusActivated: [],
  codeByStatusValid: [],
  totalActiveCode: 0,
  totalValidCode: 0,
  count: 0,
}

const actions = {
  async fetchActiveCodes({commit}, params = {}) {
    const res = await ActiveCode.fetch({
      ...params,
    })
    commit('setActiveCodes', res.data || [])
    return res.data
  },
  async getActiveCodeByPagination({state, dispatch}, {codeId, page, itemsPerPage, status}) {
    if (!page) page = 1
    if (!itemsPerPage) itemsPerPage = state.itemsPerPage
    let params = {
      _skip: (page - 1) * itemsPerPage,
      _limit: itemsPerPage == -1 ? null : itemsPerPage,
      _sort: 'createdAt:desc',
      status,
      populate: 'students',
    }
    await dispatch('fetchActiveCodeByStatus', {codeId, params})
    await dispatch('setPageText', {page, itemsPerPage})
  },
  setPageText({state, commit}, {page, itemsPerPage}) {
    const pageStart = (page - 1) * itemsPerPage + 1
    let pageStop = page * itemsPerPage
    pageStop = pageStop > state.totalItems || pageStop === -1 ? state.totalItems : pageStop
    let pageText = `${pageStart}-${pageStop} trên ${state.totalItems}`
    commit('setPageText', pageText)
  },
  async countActiveCode({commit}, params = {}) {
    const res = await ActiveCode.count({
      ...params,
    })
    commit('setCount', res.data || 0)
    return res.data
  },
  async fetchActiveCodeExportExcel (_, {codeId, params}){
    const res = await ActiveCode.fetchCodeByStatus(codeId, params)
    return res.data
  },
  async fetchActiveCodeByStatus({commit}, {codeId, params}) {
    const res = await ActiveCode.fetchCodeByStatus(codeId, params)
    if (params.status === 'activated') {
      commit('setCodeByStatusActivated', res.data.data)
      commit('setTotalItemCodeActivated', res.data.total)
    }
    else {
      commit('setCodeByStatusValid', res.data.data)
      commit('setTotalItemCodeValid', res.data.total)
    }
  },
  async fetchActiveCodeById({commit}, id) {
    const res = await ActiveCode.fetchOne(id)
    commit('setOneActiveCode', res.data)
    return res.data
  },
  async createActiveCode({commit, rootState}, data) {
    const res = await ActiveCode.createMany({
      ...data,
      userId: rootState.auth.auth.userId,
    })
    commit('setActiveCode', res.data)
    return res.data
  },
  async deleteActiveCode({commit}, id) {
    await ActiveCode.remove(id)
    return commit('removeActiveCode', id)
  },
  async updateActiveCode({commit}, {id, activeCode}) {
    const res = await ActiveCode.update(id, activeCode)
    commit('setActiveCode', res.data)
    return res.data
  },
}

const mutations = {
  setActiveCodes(state, activeCodes) {
    return (state.activeCodes = activeCodes)
  },
  setCount(state, count) {
    return (state.count = count)
  },
  removeActiveCode(state, id) {
    state.activeCodes = state.activeCodes.filter((code) => code.id !== id)
  },
  setActiveCode(state, newActiveCode) {
    state.activeCodes = state.activeCodes.map((activeCode) => {
      if (activeCode._id === newActiveCode._id) {
        return newActiveCode
      } else return activeCode
    })
    return state.activeCodes
  },
  setOneActiveCode(state, activeCode) {
    state.activeCode = activeCode
  },
  setPageText(state, value) {
    state.pageText = value
  },
  setTotalItemCodeActivated(state, value) {
    state.totalActiveCode = value
  },
  setTotalItemCodeValid(state, value) {
    state.totalValidCode = value
  },
  setCodeByStatusActivated(state, code) {
    state.codeByStatusActivated = code
  },
  setCodeByStatusValid(state, code) {
    state.codeByStatusValid = code
  },
  resetDataCode(state) {
    state.totalActiveCode = 0
    state.totalValidCode = 0
    state.codeByStatusActivated = []
    state.codeByStatusValid = []
    state.activeCode = {}
  },
}

const getters = {
  getActiveCodes: (state) => {
    return state.activeCodes
  },
  getActiveCode: (state) => {
    return state.activeCode
  },
  getCount: (state) => {
    return state.count
  },
  getPageText: (state) => {
    return state.pageText
  },
  getTotalItemActivated: (state) => {
    return state.totalActiveCode
  },
  getTotalItemValid: (state) => {
    return state.totalValidCode
  },
  getCodeByStatusActivated: (state) => {
    return state.codeByStatusActivated
  },
  getCodeByStatusValid: (state) => {
    return state.codeByStatusValid
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
