import axios from 'axios'
import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_GROUP + '/group'

const fetch = (params) => {
  return Repository(baseURL).fetch(params)
}
const count = (params) => {
  const query = queryString.stringify({
    ...params,
    vendorId: vendor.state.vendor.vendorId,
  })
  return Repository(baseURL + '/count' + `?${query}`).fetch()
}

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const create = (params) => {
  return Repository(baseURL).create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const remove = (id) => {
  return Repository(baseURL).delete(id)
}

const addUsers = (id, params) => {
  return axios.put(baseURL + `/add-users/${id}`, {...params, vendorId: vendor.state.vendor.vendorId})
}

const removeUsers = (id, params) => {
  return axios.put(baseURL + `/remove-users/${id}`, params)
}

const addProducts = (id, params) => {
  return axios.put(baseURL + `/add-products/${id}`, params)
}

const removeProducts = (id, params) => {
  return axios.put(baseURL + `/remove-products/${id}`, params)
}

export default {
  fetch,
  fetchOne,
  count,
  create,
  update,
  remove,
  addUsers,
  removeUsers,
  addProducts,
  removeProducts,
}
