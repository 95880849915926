import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const ClassMarkStudent = RepositoryFactory.get('offlineClassMarkStudent')
const namespaced = true

const state = {
  markStudents: [],
  count: 0,
}

const actions = {
  async fetchMarkStudents({commit}, params) {
    const res = await ClassMarkStudent.fetch(params)
    commit('setMarkStudents', res.data || [])
    return res.data || []
  },
  async countMarkStudents({commit}, params) {
    const res = await ClassMarkStudent.count(params)
    commit('setCount', res.data || 0)
    return res.data || 0
  },
  async createMarkStudent({commit}, params) {
    const res = await ClassMarkStudent.create(params)
    commit('addToMarkStudents', res.data || {})
  },
  async createManyMarkStudents({commit}, params) {
    const res = await ClassMarkStudent.createMany(params)
    commit('addToMarkStudents', res.data || [])
  },
  async updateMarkStudent(_, {id, ...params}) {
    await ClassMarkStudent.update(id, params)
  },
  async updateManyMarkStudents(_, params) {
    await ClassMarkStudent.updateMany(params)
  },
  async removeMarkStudent(_, id) {
    await ClassMarkStudent.remove(id)
  },
  reset({commit}) {
    commit('setMarkStudents', [])
    commit('setCount', 0)
  },
  async fetchMarkStudentsIndependent(_, params) {
    const res = await ClassMarkStudent.fetch(params)
    return res.data || []
  },
  async fetchReportMarkStudentIndependent(_, params) {
    const res = await ClassMarkStudent.reportMarkStudent(params)
    return res.data || []
  }
}

const mutations = {
  setMarkStudents(state, markStudents) {
    state.markStudents = markStudents
  },
  setCount(state, count) {
    state.count = count
  },
  addToMarkStudents(state, data) {
    if (Array.isArray(data) && data.length) {
      state.markStudents = [...state.markStudents, ...data]
    } else {
      state.markStudents.push(data)
    }
  },
  updateMarkStudents(state, data) {
    const found = state.markStudents.findIndex((x) => x.id === data.id)
    if (found > -1) state.markStudents.splice(found, 1, data)
  },
}

const getters = {
  getMarkStudents: (state) => state.markStudents,
  getCount: (state) => state.count,
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
