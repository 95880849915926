import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthenLayout from '@/templates/layout/AuthenLayout'
import AccountLayout from '@/templates/layout/AccountLayout'
import FullScreenLayout from '@/templates/layout/FullScreenLayout'
import authRoutes from '@/router/authRoutes'
import authFilter from '@/router/authFilter'
import hiddenWithoutMenuRoutes from '@/router/hiddenWithoutMenuRoutes'
import hiddenRoutes from '@/router/hiddenRoutes'
import navigationRoutes from '@/router/navigationRoutes'

Vue.use(VueRouter)

const getRouter = (routers) => {
  let router = [];
  routers.forEach((item) => {
    if (item.type === "group") {
      router = router.concat(item.children);
    } else {
      router.push(item);
    }
  });
  router = router.filter((item) => !(item.type === "plugins"));
  return router;
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/account",
    component: AccountLayout,
    beforeEnter: authFilter,
    children: [...getRouter(navigationRoutes), ...hiddenRoutes],
  },
  {
    path: "/account",
    component: FullScreenLayout,
    beforeEnter: authFilter,
    children: hiddenWithoutMenuRoutes,
  },
  {
    path: "/auth",
    redirect: "/auth/signIn",
    name: "AuthLayout",
    component: AuthenLayout,
    children: authRoutes,
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
