import {confirm} from '@/plugins/confirm'
import {message} from '@/plugins/message'
import utils from '@/helpers/utils.js'

const state = {
  listSubmission: [],
  loadingProgress: false,
  progress: {},

}
const actions = {
  async openPopupConfirm({dispatch, rootState, commit}) {
    const exam = rootState.exam.exam
    if (exam.type === 'random-question-test') return
    const listSubmission = await dispatch('submission/fetchSubmissions', {exam: exam.id}, {root: true})
    if (listSubmission.length && rootState.draftUnit.isUpdateDataExam) {
      commit('setListSubmission', listSubmission)
      confirm.show({
        title: 'Cập nhật kết quả của học viên',
        text: 'Bạn vừa thay đổi nội dung trong bài kiểm tra. Đã có học viên làm bài và có kết quả. Hệ thống sẽ tự động cập nhật lại điểm cho các học viên đó.',
        okText: 'Xác nhận',
        cancelText: 'Hủy bỏ',
        confirmColor: 'primary',
        done: async () => {
          await dispatch('reScoringExam')
        },
      })
    }
  },
  async reScoringExam({commit, rootState, dispatch}) {
    commit('setLoading', true)
    const {listSubmission} = rootState.draftUnitRescore
    let list = listSubmission.reverse()
    for await (const [index, submission] of list.entries()) {
      if (submission.id && submission.answers) {
        try {
          await dispatch(
            'submission/reScoring',
            {
              id: submission.id,
              answers: submission.answers,
            },
            {root: true}
          )
          commit('setProgress', {
            type: 'update',
            number: index,
            total: list.length,
            entity: '',
          })
          await utils.delay(300)
        } catch (error) {
          message.error(error)
        }
      }
    }
    commit('setLoading', false)
    message.success('Cập nhật thành công!')
  },
}
const mutations = {
  setExam: (state, val) => {
    return (state.exam = val)
  },
  setLoading: (state, val) => {
    return (state.loadingProgress = val)
  },
  setProgress: (state, val) => {
    return (state.progress = val)
  },
  setListSubmission: (state, val) => {
    return (state.listSubmission = val)
  },
}
const getters = {
  getLoadingProgress: (state) => {
    return state.loadingProgress
  },
  getProgress: (state) => {
    return state.progress
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
