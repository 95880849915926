import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const SystemPrivate = RepositoryFactory.get('systemPrivate')

const state = {}
const actions = {
  async checkFeatureAccess(_, key) {
    const res = await SystemPrivate.checkFeatureAccess({key})
    return res.data || false
  }
}
const mutations = {}
const getters = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}