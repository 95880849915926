import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Combo = RepositoryFactory.get('combo')

const state = {
  combos: [],
  combo: {},
  count: 0,
}

const actions = {
  async fetchCombos({ commit }, params = {}) {
    const res = await Combo.fetch({
      ...params,
    })
    commit("setCombos", res.data || [])
    return res.data
  },
  async fetchComboById({ commit }, id) {
    const res = await Combo.fetchOne(id)
    commit("setOneCombo", res.data || {})
    return res.data || {}
  },
  async createCombo({ commit }, data) {
    const res = await Combo.create(data)
    commit("setCombo", res.data || {})
    return res.data
  },
  async deleteCombo({ commit }, id) {
    await Combo.remove(id)
    return commit("removeCombo", id)
  },
  async updateCombo({ commit }, { id, ...combo }) {
    const res = await Combo.update(id, { ...combo })
    commit("setCombo", res.data)
    return res.data
  },
}

const mutations = {
  setCombos(state, combos) {
    return state.combos = combos
  },
  removeCombo(state, id) {
    state.combos = state.combos.filter(code => code.id !== id)
  },
  setCombo(state, newCombo) {
    state.combos = state.combos.map(combo => {
      if (combo._id === newCombo._id) {
        return newCombo
      } else return combo
    })
    return state.combos
  },
  setOneCombo(state, combo) {
    state.combo = combo
  },
}

const getters = {
  getCombos: (state) => {
    return state.combos.reverse()
  },
  getCombo: (state) => {
    return state.combo
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
