<template>
  <div class="preview__audio_content">
    <div v-if="link" class="main_audio container_plyr" id="main-audio">
      <vue-plyr>
      <audio v-if="link" class="audio_preview" controls ref="player" :id="`audio_player_${link}`">
        <source :src="link" type="audio/mpeg" />
      </audio>
    </vue-plyr>
    </div>
    <div v-else>Audio hiện không khả dụng</div>
  </div>
</template>
<script>
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

export default {
  props: {
    link: String,
  },
  components: {
    VuePlyr,
  },
  mounted() {
    this.initAudio()
  },
  methods: {
    initAudio() {
      const collection = document.getElementsByClassName("plyr__controls");
      collection[0]?.setAttribute("style", "border-radius: 4px;")
    },
  },
  watch: {
    link: {
      async handler(val) {
        if(val) {
          await this.$utils.delay(100)
          this.initAudio()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.preview__audio_content {
  height: 100%;
  width: 100%;
}
.main_audio {
  height: 100%;
  width: 100%;
  max-width: 800px;
  padding-top: 14%;
}
.audio_preview {
  width: 100%;
  max-width: 650px;
}
* .container_plyr {
    --plyr-audio-controls-background: #f0f0f0;
  }
</style>
