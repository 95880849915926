import Repository from '@/api/core';
import queryString from 'query-string'
const baseURL = process.env.VUE_APP_API_PHYSICAL_PRODUCT + '/product-category'
import axios from 'axios'
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'

const fetch = params => {
	return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const fetchOneWithParams = (id, params, option) => {
	return axios.get(
		baseURL + '/' + id,
		{
			params: {vendorId: vendor.state.vendor.vendorId, ...params},
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

const count = params => {
	return Repository(baseURL).count(params)
}

const fetchCategoryByLevel0 = (params) => {
	return Repository(baseURL + `?level=0`).fetch(params)
}

const fetchChildrentCategoryBylevel = (params, level, parentId) => {
	const query = queryString.stringify({
		...params,
		level: level,
		parentId: parentId,
	})
	return Repository(baseURL + `?${query}`).fetch()
}
const create = params => {
	return Repository(baseURL).create(params)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const remove = id => {
	return Repository(baseURL).delete(id)
}

export default {
	fetch,
	fetchOne,
	count,
	fetchCategoryByLevel0,
	fetchChildrentCategoryBylevel,
	fetchOneWithParams,
	create,
	update,
	remove
}
