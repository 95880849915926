import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Form = RepositoryFactory.get('evaluationForm')
const namespaced = true
import vendor from '@/store/modules/vendor'
import get from 'lodash-es/get'
import utils from '@/helpers/utils.js'

const state = {
  forms: [],
  form: {},
  studentName: '',
}

const actions = {
  async fetchCollectionStudentForms({commit}, params) {
    const res = await Form.fetch({...params, vendorId: vendor.state.vendor.vendorId})
    commit('setCollectionForms', res.data || [])
    return res.data || []
  },
  async fetchStudentForm({commit}, params) {
    const res = await Form.fetchOneStudentForm({...params, vendorId: vendor.state.vendor.vendorId})
    commit('setStudentForm', res.data || {})
  },
  async fetchStudentFormsIndependent(_, params) {
    const res = await Form.fetchFormByCollection(params)
    return res.data || []
  },
  async fetchStudentsCustomFormsIndependent(_, params) {
    const res = await Form.fetchFormbyCustomCollection(params)
    return res.data || []
  },
  async updateStudentForm({commit}, {id, ...params}) {
    const res = await Form.update(id, params)
    commit('updateStudentForm', res.data || {})
    return res.data || {}
  },
  setStudentName({commit}, name) {
    commit('setStudentName', name)
  },
  resetForm({commit}) {
    commit('resetForm')
  },
  refreshForms({commit}) {
    commit('refreshForms')
  },
  refreshOneForm({commit}) {
    commit('refreshOneForm')
  },
  async createManyStudentForm(_, params) {
    const res = await Form.createMany({...params, vendorId: vendor.state.vendor.vendorId})
    return res.data || []
  },
}

const mutations = {
  setCollectionForms(state, forms) {
    state.forms = []
    state.forms = forms
  },
  setStudentForm(state, form) {
    state.form = form
  },
  setStudentName(state, name) {
    state.studentName = name
  },
  // eslint-disable-next-line
  updateStudentForm(state, data) {
    const index = state.forms.findIndex((v) => v.id === data.id)
    if (index > -1) {
      const studentName = get(state.forms[index], 'student.name', 'Chưa có tên')
      const formatData = {...data, studentName, student: state.forms[index].student}
      state.forms.splice(index, 1, formatData)
    }
  },
  resetForm(state) {
    state.form = {}
  },
  refreshForms(state) {
    state.forms = []
  },
  refreshOneForm(state) {
    state.form = {}
  },
}

const getters = {
  getForms: (state) => {
    return state.forms
      .map((v) => {
        const studentName = get(v, 'student.name', '')
        const studentIndex = utils.generateNameToNumber(studentName)
        return {...v, studentName, studentIndex}
      })
      .sort((a, b) => a.studentIndex.localeCompare(b.studentIndex))
  },
  getForm: (state) => state.form,
  getStudentName: (state) => state.studentName,
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
