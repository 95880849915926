import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_OFFLINE_STACK + '/session'

const genTimetables = (params) => {
  return Repository(baseURL + '-gen').create(params)
}

const fetch = (params) => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

export default {
  genTimetables,
  fetch,
  fetchOne,
}
