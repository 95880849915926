const SIGN_IN = {
  TITLE:'ログイン',
  LABEL_EMAIL:'メール',
  PLACEHOLDER_INPUT_EMAIL: "メールアドレスを入力してください",
  LABEL_PASSWORD: "パスワード",
  PLACEHOLDER_INPUT_PASSWORD: "パスワードを入力してください",
  HINT_PASSWORD: "パスワードには少なくとも6文字が含まれています",
  CHECKBOX_SAVE_ACCOUNT: "アカウントを保存",
  LINK_GO_TO_FORM_FIND_PASSWORD: "パスワードを忘れた",
  BUTTON_SIGN_IN: "ログイン",
  MSG_LOADING:'しばらくお待ちください...',
  MSG_ACCOUNT_IS_NOT_ALLOWED_TO_ACCESS: 'アカウントまたはパスワードが無効です！',
  MSG_ACCOUNT_OR_PASSWORD_FALSE: 'アカウントまたはパスワードが無効です！',
  MSG_CHECK_INFO_AGAIN: "情報をもう一度確認してください！",
}
const SIGN_OUT = {
  POPUP_CONFIRM_SIGN_OUT_TITLE: "サインアウトしてもよろしいですか？",
  POPUP_CONFIRM_SIGN_OUT_MESSAGE: "カスタマイズを失いたくない場合は,カスタマイズを保存してください！",
  BUTTON_CONFIRM: "確認",
  BUTTON_CANCEL: "キャンセル",
}
export default{
  SIGN_IN,
  SIGN_OUT,
  TITLE_HELP: "ヘルプが必要ですか？",
  LABEL_HOLINE: "ご不明な点がございましたら,ホットラインまでお問い合わせください:",
  LABEL_ADDRESS_EMAIL: "またはEメール:",
  TITLE_NEW_FEATURE: "新機能！",
  WELCOME:'ようこそ',
}