// USE IN LIST SECTION (FOR TITLE) AND LIST SECTION SELECTION (DESCRIPTION, WHILE LABEL REUSES TITLE)
const SECTION = {
  // BANNER
  BANNER_GROUP_NAME: 'Slide / Banner',

  BANNER_SLIDER_TITLE: 'Slide toàn màn hình',
  BANNER_SLIDER_DESCRIPTION: 'Slide trình chiếu Banner chiếm toàn bộ chiều ngang màn hình.',

  BANNER_TOPIC_TITLE: 'Slide kết hợp với chủ đề',
  BANNER_TOPIC_DESCRIPTION: 'Slide trình chiếu Banner kết hợp với chủ đề.',

  BANNER_SINGLE_IMAGE_SLIDER_TITLE: 'Slide nhỏ',
  BANNER_SINGLE_IMAGE_SLIDER_DESCRIPTION: 'Slide trình chiếu nhỏ gọn phù hợp với ảnh bé.',

  BANNER_TEXT_TITLE: 'Banner & Chữ mô tả',
  BANNER_TEXT_DESCRIPTION: 'Hiển thị chữ và nút bấm điều hướng trên 1 banner.',

  BANNER_LEAD_FORM_TITLE: 'Banner kèm form thông tin',
  BANNER_LEAD_FORM_DESCRIPTION: 'Một form điền thông tin hiển thị trên ảnh nền. Form sẽ cung cấp thông tin vào trong phần "Contact", thuộc "Quản lí tài chính".',

  // TODO: this is not only for download, FIX this ASAP
  BANNER_POPUP_TITLE: 'Banner nhỏ kèm popup thông tin',
  BANNER_POPUP_DESCRIPTION: 'Hiển thị ảnh nền và thông tin ngắn. Có thể cấu hình thêm nút kêu gọi hành động hoặc popup thông tin.',

  // CARD
  CARD_GROUP_NAME: 'Thẻ nội dung học',

  CARD_OFFLINE_COURSE_TITLE: 'Thẻ Khóa học offline',
  CARD_OFFLINE_COURSE_DESCRIPTION: 'Danh sách gồm nhiều thẻ thông tin Khóa học Offline.',

  CARD_ONLINE_COURSE_TITLE: 'Thẻ khóa học online',
  CARD_ONLINE_COURSE_DESCRIPTION: 'Danh sách gồm nhiều thẻ thông tin Khóa học Online.',

  CARD_MY_ONLINE_COURSE_TITLE: 'Thẻ khóa học của tôi',
  CARD_MY_ONLINE_COURSE_DESCRIPTION: 'Danh sách gồm nhiều thẻ thông tin Khóa học Online đã sở hữu.',

  CARD_EVENT_TITLE: 'Thẻ sự kiện',
  CARD_EVENT_DESCRIPTION: 'Tùy chọn thẻ sự kiện hiển thị trong danh sách',

  CARD_DEPARTMENT_TITLE: 'Thẻ cơ sở',
  CARD_DEPARTMENT_DESCRIPTION: 'Tuỳ chọn thẻ cơ sở - trung tâm. Phù hợp với trung tâm có nhiều lớp - chi nhánh.',

  CARD_EBOOK_TITLE: 'Thẻ tài liệu số',
  CARD_EBOOK_DESCRIPTION: 'Tùy chọn thẻ tài liệu (ebook) hiển thị trong danh sách',

  CARD_LEARNING_PATH_TITLE: 'Thẻ lộ trình học',
  CARD_LEARNING_PATH_DESCRIPTION: 'Tùy chọn thẻ lộ trình học hiển thị trong danh sách',

  // MEDIA
  MEDIA_GROUP_NAME: 'Ảnh và video',

  MEDIA_IMAGE_DESCRIPTION_TITLE: 'Mô tả ảnh',
  MEDIA_IMAGE_DESCRIPTION_DESCRIPTION: 'Hiển thị một ảnh cho mỗi trang. Kích thước nhỏ hơn Banner.',

  MEDIA_GALLERY_PICTURE_TITLE: 'Bộ sưu tập ảnh',
  MEDIA_GALLERY_PICTURE_DESCRIPTION: 'Hiển thị một danh sách các bức ảnh với tỷ lệ ảnh gốc.',

  // MISC
  MISC_GROUP_NAME: 'Nội dung bổ sung',

  MISC_TESTIMONIAL_SLIDER_TITLE: 'Nhận xét người dùng',
  MISC_TESTIMONIAL_SLIDER_DESCRIPTION: 'Hiển thị một hay nhiều nhận xét của người dùng theo dạng slide.',

  MISC_TEXT_EDITOR_TITLE: 'Soạn thảo nội dung',
  MISC_TEXT_EDITOR_DESCRIPTION: 'Cho phép tự soạn thảo nội dung hiển thị',

  MISC_TEACHER_TITLE: 'Giảng viên',
  MISC_TEACHER_DESCRIPTION: 'Hiển thị thông tin giáo viên',

  MISC_BLOG_NEWS_TITLE: 'Tin tức',
  MISC_BLOG_NEWS_DESCRIPTION: 'Tin tức',

  MISC_CATEGORY_TITLE: 'Chủ đề',
  MISC_CATEGORY_DESCRIPTION: 'Chọn chủ đề hiển thị',

  HTML_SECTION_TITLE: 'Nội dung html',
  HTML_SECTION_DESCRIPTION: 'Cho phép hiển thị nội dung theo mã html',
}

const ACTION_BAR = {
  GO_BACK_TEXT: 'Trang quản lý',

  CONFIRM_GO_BACK_TITLE: 'Bạn có chắc muốn trở về trang quản lý?',
  CONFIRM_GO_BACK_DESCRIPTION: 'Mọi thay đổi của bạn sẽ không được lưu lại.',
}

const DETAIL = {
  GO_BACK_TEXT: 'Danh sách khối nội dung',

  BTN_ADD_COMPONENT: 'Thêm khối thông tin',

  CONFIRM_REMOVE_COMPONENT_TITLE: 'Bạn có chắc muốn xóa khối nội dung này?',
  CONFIRM_REMOVE_COMPONENT_DESCRIPTION: 'Dữ liệu vẫn có thể khôi phục được nếu bạn không lưu thay đổi.',
}

const NAVIGATION = {
  // NAVIGATION SECTION
  NS_COMMON: 'Nhận diện chung',
  NS_HOMEPAGE: 'Trang chủ',
  NS_ABOUT_US: 'Trang về chúng tôi',
  NS_PRIVACY_POLICY: 'Trang chính sách',
  NS_RECRUITMENT: 'Trang trở thành giảng viên',
  // CONFIRM CHANGE NAVIGATION
  CONFIRM_CHANGE_NAVIGATION_TITLE: 'Chuyển sang trang khác',
  CONFIRM_CHANGE_NAVIGATION_DESCRIPTION: 'Dữ liệu chưa lưu không thể khôi phục',
}

// MAIN COMPONENT, RE-USE MORE, PUT IN INPUT STYLE
const CONFIGURATION = {
  BACKGROUND_TITLE: 'Ảnh nền',
  IMAGE_TITLE: 'Ảnh',
  RECOMMENDED_IMAGE_SIZE: 'Kích thước khuyên dùng {0}',
  INPUT_HEADING_LABEL: 'Tiêu đề',
  INPUT_HEADING_PLACEHOLDER: 'Thông tin tiêu đề',
  INPUT_DESCRIPTION_LABEL: 'Mô tả',
  PLACEHOLDER_INPUT_DESCRIPTION:"Nhập mô tả",
  INPUT_DESCRIPTION_PLACEHOLDER: 'Thông tin mô tả',
  INPUT_BTN_NAME_LABEL: 'Tiêu đề nút',
  INPUT_BTN_NAME_PLACEHOLDER: 'Nhập tiêu đề nút',
  INPUT_MAIN_HEADING_LABEL: 'Tiêu đề chính',
  INPUT_MAIN_HEADING_PLACEHOLDER: 'Nhập tiêu đề chính',
  INPUT_SUB_HEADING_LABEL: 'Tiêu đề phụ',
  INPUT_SUB_HEADING_PLACEHOLDER: 'Nhập tiêu đề phụ',
  INPUT_SLIDE_TRANSITION_TIME: 'Thời gian chuyển slide',
  INPUT_SLIDE_TRANSITION_TIME_PLACEHOLDER: 'Nhập thời gian',

  CUSTOM_REDIRECT_ON_BTN_TITLE: 'Đường dẫn cho nút xem thêm',
  CUSTOM_REDIRECT_ON_BTN_DESCRIPTION_ONLINE_COURSE: 'Cấu hình đường dẫn cho nút xem thêm theo chủ đề của khoá học trực tuyến. Nếu bạn không cấu hình, đường dẫn của nút xem thêm sẽ dẫn tới danh sách khoá học trực tuyến.',
  CUSTOM_REDIRECT_ON_BTN_DESCRIPTION_OFFLINE_COURSE: 'Cấu hình đường dẫn cho nút xem thêm theo chủ đề của khoá học trực tiếp. Nếu bạn không cấu hình, đường dẫn của nút xem thêm sẽ dẫn tới danh sách khoá học trực tiếp.',
  CUSTOM_REDIRECT_ON_BTN_DESCRIPTION_EBOOK: 'Cấu hình đường dẫn cho nút xem thêm theo chủ đề của tài liệu điện tử. Nếu bạn không cấu hình, đường dẫn của nút xem thêm sẽ dẫn tới danh sách tài liệu điện tử.',

  // BANNER_POPUP
  BP_MAIN_CONTENT_TITLE: 'Nội dung chính',
  BP_MAIN_CONTENT_DESCRIPTION: 'Hiển thị ở phía bên trái',
  BP_OPTION_CTA_BTN_VISIBILITY: 'Hiển thị nút kêu gọi hành động',
  BP_INPUT_CTA_BTN_NAME_LABEL: 'Tiêu đề cho nút kêu gọi hành động',
  BP_INPUT_CTA_BTN_NAME_PLACEHOLDER: 'Nhập tiêu đề cho nút',
  BP_INPUT_CTA_BTN_LINK_LABEL: 'Gắn đường dẫn khi nhấn vào nút',
  BP_INPUT_CTA_BTN_LINK_PLACEHOLDER: 'Nhập đường dẫn cho nút',
  BP_OPTION_POPUP_VISIBILITY: 'Hiển thị popup thông tin',
  BP_INPUT_OPEN_POPUP_BTN_LABEL: 'Tiêu đề cho nút mở popup',
  BP_INPUT_OPEN_POPUP_BTN_PLACEHOLDER: 'Nhập tiêu đề cho nút',
  BP_INPUT_POPUP_TITLE_LABEL: 'Tiêu đề popup',
  BP_INPUT_POPUP_TITLE_PLACEHOLDER: 'Thông tin tiêu đề popup',

  // BANNER_SLIDER
  OPTION_ADD_LINK_TO_IMAGE: 'Gắn đường dẫn khi nhấn vào ảnh',
  INPUT_IMAGE_LINK_LABEL: 'Đường dẫn khi nhấn vào vào ảnh',
  INPUT_IMAGE_LINK_PLACEHOLDER: 'Nhập đường dẫn',
  BTN_ADD_IMAGE: 'Thêm ảnh',

  // BANNER_TEXT
  BTXT_DESCRIPTION_LABEL: 'Nội dung',
  BTXT_DESCRIPTION_PLACEHOLDER: 'Nội dung tối đa 200 kí tự',
  BTXT_LINK_ON_BTN_LABEL: 'Đường dẫn cho nút',
  BTXT_LINK_ON_BTN_PLACEHOLDER: 'Nhập đường dẫn cho nút',
  BTXT_TEXT_ALIGN_LABEL: 'Vị trí nội dung hiển thị',
  BTXT_TEXT_ALIGN_PLACEHOLDER: 'Chọn vị trí hiển thị',
  BTXT_TEXT_ALIGN_OPTION_LEFT: 'Bên trái',
  BTXT_TEXT_ALIGN_OPTION_CENTER: 'Căn giữa',

  // BANNER_TOPIC
  BTPC_TOPIC_SECTION_TITLE: 'Lựa chọn chủ đề',
  BTPC_TOPIC_SECTION_DESCRIPTION: 'Mỗi chủ đề nên đi kèm với 3 khoá học và chủ đề con',
  BTPC_TOPIC_PICKER_LABEL: 'Chủ đề',
  BTPC_TOPIC_PICKER_PLACEHOLDER: 'Chọn chủ đề',
  BTPC_CHILD_TOPIC_PICKER_LABEL: 'Chủ đề con',
  BTPC_CHILD_TOPIC_PICKER_PLACEHOLDER: 'Chọn chủ đề con',
  COURSE_PICKER_LABEL: 'Khoá học',
  COURSE_PICKER_PLACEHOLDER: 'Chọn khoá học hiển thị',
  BTPC_BTN_ADD_CHILD_TOPIC: 'Thêm chủ đề con',
  BTPC_BTN_ADD_TOPIC: 'Thêm chủ đề',

  // BLOG
  BLOG_DESCRIPTION: 'Chỉ có tác dụng hiển thị 3 bài viết mới nhất',

  CATEGORY_LIST_TITLE: 'Danh sách chủ đề',

  DEPARTMENT_LIST_TITLE: 'Danh sách cơ sở',
  DEPARTMENT_PICKER_LABEL: 'Cơ sở',
  DEPARTMENT_BTN_ADD: 'Thêm cơ sở',

  EBOOK_LIST_TITLE: 'Danh sách tài liệu',
  EBOOK_PICKER_LABEL: 'Tài liệu',
  EBOOK_PICKER_PLACEHOLDER: 'Chọn tài liệu để hiển thị',

  EVENT_LIST_TITLE: 'Danh sách sự kiện',
  EVENT_PICKER_LABEL: 'Sự kiện',
  EVENT_PICKER_PLACEHOLDER: 'Chọn sự kiện để hiển thị',

  GALLERY_IMAGE_LIST_TITLE: 'Danh sách các bức ảnh (Tối đa 30 tấm)',

  IMAGE_DESCRIPTION_INFO_TITLE: 'Thông tin hiển thị',
  IMAGE_DESCRIPTION_BUTTON_VISIBILITY_TITLE: 'Nút hiển thị',
  IMAGE_DESCRIPTION_BUTTON_VISIBILITY_LABEL: 'Hiển thị nút',

  LEAD_FORM_HEADING_LABEL: 'Tiêu đề form',
  LEAD_FORM_HEADING_PLACEHOLDER: 'Nhập tiêu đề form',

  DISPLAY_STYLE_LABEL: 'Hiển thị',
  DISPLAY_STYLE_PLACEHOLDER: 'Chọn dạng hiển thị',
  DISPLAY_STYLE_OPTION_GRID: 'Dạng lưới',
  DISPLAY_STYLE_OPTION_SLIDE: 'Dạng slide',
  CARD_INTRO_BANNER_TITLE: 'Banner giới thiệu',
  CONFIRM_REMOVE_BANNER_TITLE: 'Bạn có chắc muốn xóa banner này?',
  CONFIRM_REMOVE_BANNER_DESCRIPTION: 'Dữ liệu sau khi xóa không thể khôi phục. Hệ thống sẽ xoá cả ảnh và đường dẫn URL kèm theo ảnh. Bạn có chắc chắn muốn xoá banner không?',

  // LEARNING_PATH
  LP_LIST_TITLE: 'Danh sách lộ trình học',
  LP_PICKER_LABEL: 'Lộ trình học',
  LP_PICKER_PLACEHOLDER: 'Chọn lộ trình học hiển thị',
  LP_CARD_INTRO_BANNER_DESCRIPTION: 'Lộ trình học thứ tư sẽ bị ẩn đi khi sử dụng banner.',

  // ONLINE_COURSE
  OC_CARD_INTRO_BANNER_DESCRIPTION: 'Khóa học thứ tư sẽ bị ẩn đi khi sử dụng banner.',
  
  ONLINE_OFFLINE_COURSE_LIST_TITLE: 'Danh sách khoá học',

  CARD_TITLE_NTH: 'Thẻ', // Thẻ thử bao nhiêu
  
  TESTIMONIAL_SLIDER_USER_NAME_LABEL: 'Tên người dùng',
  TESTIMONIAL_SLIDER_USER_NAME_PLACEHOLDER: 'Nhập tên người dùng',
  TESTIMONIAL_SLIDER_USER_COMMENT_LABEL: 'Nội dung nhận xét',
  TESTIMONIAL_SLIDER_USER_COMMENT_PLACEHOLDER: 'Tối đa 200 kí tự',
  TESTIMONIAL_SLIDER_BTN_ADD_CARD: 'Thêm thẻ',
  TESTIMONIAL_SLIDER_CONFIRM_REMOVE_CARD_TITLE: 'Bạn có chắc muốn xóa thẻ nhận xét này?',
  TESTIMONIAL_SLIDER_CONFIRM_REMOVE_IMAGE_TITLE: 'Bạn có chắc muốn xóa ảnh này?',
}

const SITE_INFO = {
  SITE_NAME_TITLE: 'Site Name',
  SITE_NAME_LABEL: 'Tên trang',
  SITE_NAME_PLACEHOLDER: 'Nhập tên trang web',
  LOGO_TITLE: 'Logo',
  LOGO_DESCRIPTION: 'Định dạng PNG - Kích thước 150x60 px',
  MAIN_COLOR_TITLE: 'Màu sắc chính',
  FAVICON_TITLE: 'Favicon',
  FAVICON_DESCRIPTION: 'Định dạng Favicon (.png) - Kích thước 32x32 px - Độ phân giải 72 pixels/inch',
  THUMBNAIL_TITLE: 'Thumbnail',
  THUMBNAIL_DESCRIPTION: 'Định dạng ảnh',
}

export default {
  SECTION,
  ACTION_BAR,
  DETAIL,
  NAVIGATION,
  CONFIGURATION,
  SITE_INFO,
  BUTTON_SAVE:"Lưu",
  BUTTON_CANCEL_TEXT:"Huỷ",
  BUTTON_CONFIRM:"Xác nhận",
  BUTTON_CANCEL:"Huỷ bỏ",
  BUTTON_ADD:"Thêm",
  BUTTON_DELETE:"Xoá",
  BUTTON_BACK:"Quay lại",
  BUTTON_SAVE_AND_CONTINUE:"Lưu & tiếp tục",
  // MESSAGE
  MSG_UPDATE_SITE_LOADING: 'Đang cập nhật trang...',
  MSG_UPDATE_SUCCESS:"Cập nhật thành công !",
  // IFRAME
  IFRAME_LEARNING_PAGE_TITLE: 'Learning Page', // NOT SURE IF NEED TRANSLATE OR NOT

  LABEL_COURSE_CARD_BY_TOPIC:"Thẻ khóa học theo chủ đề",
  HINT_COURSE_CARD_BY_TOPIC:"4 khoá học online hiển thị theo chủ đề",
  LABEL_ONLINE_COURSE_CARD_BY_TOPIC:"Thẻ khóa học online theo chủ đề",
}