const SIGN_IN={
  TITLE:"Đăng nhập",
  LABEL_EMAIL:"Email",
  PLACEHOLDER_INPUT_EMAIL:"Nhập email",
  LABEL_PASSWORD:"Password",
  PLACEHOLDER_INPUT_PASSWORD:"Nhập mật khẩu",
  HINT_PASSWORD:"Mậu khẩu chứa ít nhất 6 ký tự",
  CHECKBOX_SAVE_ACCOUNT:"Lưu tài khoản",
  LINK_GO_TO_FORM_FIND_PASSWORD:"Quên mật khẩu",
  BUTTON_SIGN_IN:"Đăng nhập",
  MSG_LOADING:"Vui lòng chờ giây lát...",
  MSG_ACCOUNT_IS_NOT_ALLOWED_TO_ACCESS:'Tài khoản hoặc mật khẩu không đúng !',
  MSG_ACCOUNT_OR_PASSWORD_FALSE:'Tài khoản hoặc mật khẩu không đúng !',
  MSG_CHECK_INFO_AGAIN:"Kiểm tra lại thông tin !",
}
const SIGN_OUT={
  POPUP_CONFIRM_SIGN_OUT_TITLE:"Bạn có chắc muốn đăng xuất",
  POPUP_CONFIRM_SIGN_OUT_MESSAGE:"Hãy lưu lại các tuỳ chỉnh nếu không muốn mất nhé!",
  BUTTON_CONFIRM:"Xác nhận",
  BUTTON_CANCEL:"Huỷ bỏ",
}
export default{
  SIGN_IN,
  SIGN_OUT,
  TITLE_HELP:"Bạn cần trợ giúp?",
  LABEL_HOLINE:"Mọi thắc mắc xin liên hệ ",
  LABEL_ADDRESS_EMAIL:"hoặc gửi email tới địa chỉ",
  TITLE_NEW_FEATURE:"Tính năng mới!",
  WELCOME: 'Chào mừng',
}