<template>
  <div>
    <v-dialog v-model="dialog" width="800" scrollable>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon class="heading grey--text text--darken-4" @click="closeDialog()"> mdi-close </v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text @click="canWatchData = true" class="d-flex align-center justify-center">
          <BaseEditor
            class="mt-5 mb-1 popup-dialog__content"
            :key="keyEditor"
            height="200"
            :init-data="data.html"
            @text-change="onTextChange"
            minHeight="0"
            customHeight="88"
            ref="edit-html"
          ></BaseEditor>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-6 py-4 d-flex justify-end">
          <v-btn depressed color="primary" @click="onSaveTemplate" :disabled="!isDataChange">
            <span class="text-none">
              {{ 'Lưu' }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input @change="handleFileSelect" ref="input-html-file" type="file" accept=".html" style="display: none" />
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      data: {},
      keyEditor: Date.now(),
      isDataChange: false,
      canWatchData: false,
    }
  },
  methods: {
    renderBaseEditor(value) {
      this.$refs['edit-html'].insertContent(value)
    },
    openDialog() {
      this.dialog = true
    },
    onTextChange(html) {
      if (this.canWatchData) this.isDataChange = true
      this.data.html = html
    },
    closeDialog() {
      this.dialog = false
    },
    onSaveTemplate() {
      this.$emit('onSave', this.data.html)
      this.closeDialog()
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        let data = e.target.result
        if (data) this.renderBaseEditor(data)
        this.isDataChange = true
      }
      reader.readAsText(file)
    },
  },
  watch: {
    text: {
      handler(val) {
        if (val && val.length) {
          this.data.html = val
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .v-dialog--scrollable > .v-card > .v-card__text,
  .v-dialog--scrollable > form > .v-card > .v-card__text {
    overflow-y: hidden;
  }
  .v-dialog {
    height: 600px !important;
  }
}

.popup-dialog__content {
  height: 100%;
}
</style>
