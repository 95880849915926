import helpers from '../../helpers/index.js'
import uuid from 'uuid'
import isInteger from 'lodash-es/isInteger'
import max from 'lodash-es/max'
import min from 'lodash-es/min'
import sum from 'lodash-es/sum'
import dayjs from 'dayjs'
import stringUtils from './stringUtils'
import vuetify from '@/plugins/vuetify'
import matrixMethod from './matrixUtils.js'
import clone from 'lodash-es/clone'
import {get} from 'lodash-es'
const caculateTotalTimeAndScore = (questions) => {
  let totalTime = 0
  let totalScore = 0
  let count = 0
  questions.map((question) => {
    if (!question.type.includes('child')) {
      totalScore += Number(question.factor ? question.factor : 0)
      totalTime += Number(question.config ? question.config.time : 0)
    }
    if (!question.type.includes('group')) count++
  })
  return {totalTime, totalScore: totalScore.toFixed(2), count}
}

const convertChildrenToArray = (children) => {
  if (!children) return []
  return Object.values(children)
    .map((item) => {
      if (item.children) {
        return {
          ...item,
          children: convertChildrenToArray(item.children),
        }
      } else return item
    })
    .sort((a, b) => a.index - b.index)
}

const getPathOfKnowledge = (knowledge) => {
  const parents = knowledge.parents
  let path = ''
  parents.map((parentId, index) => {
    path += `${parentId}${index === parents.length - 1 ? `.children.${knowledge.id}` : '.children.'}`
  })
  return path
}

const flattenQuestion = (questions) => {
  let childs = []
  let flatQuestions = questions
  flatQuestions.forEach((q) => {
    if (q.type.includes('group')) childs = childs.concat(q.children)
  })
  flatQuestions = flatQuestions.concat(childs)
  return flatQuestions
}
const flatQuestionInPart = (parts) => {
  let questions = []
  Object.keys(parts).forEach((key) => {
    if (parts[key].questions) questions = questions.concat(parts[key].questions)
  })
  return questions
}

const validateOneQuestion = (question, type) => {
  if (!type?.includes('group')) if (!question.correct) return false
  if (question.type.includes('true-false') && Object.keys(question.correct).length !== 4) return false
  if (type !== 'exercise' && !isInteger(Number(question.config.time))) return false
  return true
}

const validateMark = (questions, settings) => {
  if (settings.selectedTypeScore === '10-mark') {
    let totalScore = 0
    const allFactor = questions.map((q) => {
      if (!q.type.includes('group')) return parseFloat(String(q.factor || 0))
      return 0
    })
    totalScore = Math.round(sum(allFactor) * 100) / 100
    return totalScore === 10
  } else return true
}

const validateQuestion = (questions, type) => {
  let qInvalid = []
  questions.map((q) => {
    if (q.type.includes('group')) {
      if (!validateOneQuestion(q, 'group')) qInvalid.push(q)
    } else if (q.type.includes('child')) {
      if (!validateOneQuestion(q)) qInvalid.push(q)
    } else if (!validateOneQuestion(q, type)) qInvalid.push(q)
  })
  return qInvalid.map((q) => {
    if (q.type.includes('group')) return `${vuetify.framework.lang.translator('$vuetify.TEXT_GROUP')} ${q.index + 1}`
    else if (q.type.includes('child'))
      return `${vuetify.framework.lang.translator('$vuetify.TEXT_QUESTION_CHILD')} ${q.index + 1}`
    else return `${vuetify.framework.lang.translator('$vuetify.TEXT_QUESTION')} ${q.index + 1}`
  })
}

const getDataQuestion = (exam, question, currentUnit) => {
  return {
    questions: question.questions || undefined,
    course: currentUnit.course,
    unit: currentUnit.id,
    vendorId: currentUnit.vendorId,
    exam: exam._id,
    type: question.type,
    index: question.index,
    correct: question.correct,
    factor: question.factor || 1,
    answers: question.answers,
    html: question.html || null,
    config: question.config || null,
    knowledge: question.knowledge || null,
  }
}

const convertTestAnswers = (answers) => {
  let newAnswers = {}
  if (!answers) return undefined
  answers.forEach((answer) => {
    newAnswers[answer.key] = {
      type: 'html',
      value: answer.value,
      label: answer.label,
      key: answer.key,
    }
  })
  return newAnswers
}

const convertTestQuestions = (questions) => {
  return questions.map((v) => {
    let newAnswers = convertTestAnswers(v.answers)
    return {
      ...v,
      html: v.description,
      answers: newAnswers,
      correct: v.answer,
    }
  })
}

const getIdsQuestionWithChild = (questions) => {
  let listIds = []
  questions.forEach((i) => {
    if (i.type.includes('group')) {
      listIds.push(i.id)
      listIds = listIds.concat(i.questions)
    } else listIds.push(i.id)
  })
  return listIds
}

const getQuestionWillDelete = (beforeQuestions, afterQuestions) => {
  const listIdsBefore = getIdsQuestionWithChild(beforeQuestions)
  const listIdsAfter = getIdsQuestionWithChild(afterQuestions)
  if (afterQuestions.length === 0) return listIdsBefore
  let deleteQuestions = []
  listIdsBefore.forEach((id) => {
    if (listIdsAfter.indexOf(id) < 0) deleteQuestions.push(id)
  })
  return deleteQuestions
}

const getSelections = (numberSelections) => {
  let selections = {}
  for (let i = 0; i < numberSelections; i++) {
    const newKey = uuid.v4()
    selections[newKey] = {
      type: 'html',
      value: '',
      label: helpers.DEFAULT_LABELS[i],
      key: newKey,
    }
  }
  return selections
}

const getCorrect = (type) => {
  return type === helpers.TYPES_OF_QUESTIONS.MULTIPLE_CHOICES ? [] : ''
}

const getNewQuestion = (index, settings, isGroup) => {
  const type = getTypeQuestions(settings)
  const {selectedType, key, numberQuestions, factor, html} = settings
  let score = isGroup === 'group' ? Number(numberQuestions) * factor : factor
  if (selectedType === 'direct-fill-bank-group') score = 0
  return {
    index: index,
    type: isGroup === 'group' ? 'group' : type,
    answers: getSelections(selectedType),
    correct: getCorrect(type),
    html: html ?? '',
    id: uuid.v4(),
    config: {
      time: settings.time,
      key,
    },
    factor: score,
  }
}

const getTypeQuestions = (settings) => {
  const typeOfQuestion = helpers.TYPES_OF_QUESTIONS
  if (settings.isEnableMultipleCorrect) {
    return typeOfQuestion[settings.isGroup ? 'MULTIPLE_CHOICES_CHILD' : 'MULTIPLE_CHOICES']
  } else {
    if (settings.selectedType === typeOfQuestion.DIRECT_FILL_BLANK_GROUP) return typeOfQuestion.DIRECT_FILL_BLANK_GROUP
    if (settings.selectedType === typeOfQuestion.DIRECT_FILL_BLANK_CHILD) return typeOfQuestion.DIRECT_FILL_BLANK_CHILD
    if (settings.selectedType === typeOfQuestion.LINK_QUESTION_GROUP) return typeOfQuestion.LINK_QUESTION_GROUP
    if (settings.selectedType === typeOfQuestion.LINK_QUESTION_CHILD) return typeOfQuestion.LINK_QUESTION_CHILD
    if (settings.selectedType === typeOfQuestion.TRUE_FALSE_CHOICE)
      return typeOfQuestion[settings.isGroup ? 'TRUE_FALSE_CHOICE_CHILD' : 'TRUE_FALSE_CHOICE']
    if (settings.selectedType === typeOfQuestion.FILL_BLANK) {
      return typeOfQuestion[settings.isGroup ? 'FILL_BLANK_CHILD' : 'FILL_BLANK']
    } else return typeOfQuestion[settings.isGroup ? 'SINGLE_CHOICE_CHILD' : 'SINGLE_CHOICE']
  }
}

const mapQuestionsGroup = (questions) => {
  const listTypeGroup = [
    'group',
    'single-choice-child',
    'multiple-choice-child',
    'fill-blank-child',
    'true-false-choice-child',
    'link-question-group',
    'link-question-child',
  ]
  const listTypeDirectFill = ['direct-fill-blank-group', 'direct-fill-blank-child']
  let rawQuestions = questions.filter(
    (qe) => listTypeGroup.indexOf(qe.type) < 0 && listTypeDirectFill.indexOf(qe.type) < 0
  )
  let listGroups = questions.filter((q) => q.type.includes('group'))
  listGroups = listGroups.map((group) => {
    const children = group.questions
      ?.map((item) => {
        let questionId = typeof item === 'string' ? item : item.id
        const question = questions.find((qe) => qe.id === questionId)
        return {
          ...question,
          parentId: group.id,
        }
      })
      .filter((item) => item.id)
    return {
      ...group,
      children,
    }
  })
  rawQuestions = rawQuestions.concat(listGroups)
  return rawQuestions.sort((a, b) => a.index - b.index)
}

const transformQuestionPart = (questionsInPart) => {
  let currentIndex = 0
  let newQuestions = questionsInPart.map((q) => {
    if (q.type.includes('group')) {
      let newQuestion = {...q, index: currentIndex}
      let child = q.children.map((child) => {
        let newChild = {...child, index: currentIndex}
        currentIndex++
        return newChild
      })
      newQuestion = {...newQuestion, children: child}
      return newQuestion
    } else {
      let newQuestion = {...q, index: currentIndex}
      currentIndex++
      return newQuestion
    }
  })
  return newQuestions
}

const setIndexRandomQuestions = (allQuestions) => {
  let questions = allQuestions.filter((q) => q && !q.type.includes('child'))
  let randomQuestions = []
  let currentIndex = 0
  questions.forEach((q) => {
    if (q.type.includes('group')) {
      randomQuestions.push({...q, index: currentIndex})
      q.questions.forEach((child) => {
        const childQuestion = allQuestions.find((i) => i.id === child.id)
        randomQuestions.push({...childQuestion, index: currentIndex})
        currentIndex++
      })
    } else {
      randomQuestions.push({...q, index: currentIndex})
      currentIndex++
    }
  })
  return randomQuestions
}

const getFromToQuestionGroup = (question) => {
  if (question.children && question.children.length > 1) {
    const listIndex = question.children.map((child) => Number(child.index))
    return `${min(listIndex) + 1}-${max(listIndex) + 1}`
  }
  return 'đơn'
}

const mapQuestionsForPart = (part, initQues) => {
  Object.keys(part).forEach((key) => {
    const questionsOfPart = []
    part[key].questions.map((que) => {
      const existedItem = initQues.find((q) => q.id === que || q.id === que.id)
      if (existedItem) {
        if (existedItem.type.includes('group')) {
          existedItem.questions.forEach((childId) => {
            const existedChild = initQues.find((q) => q.id === childId)
            if (existedChild) questionsOfPart.push(existedChild)
          })
          questionsOfPart.push(existedItem)
        } else questionsOfPart.push(existedItem)
      }
    })
    part[key].questions = questionsOfPart
  })
  return part
}

const getMaxIdx = (questions) => {
  return questions && questions.length
    ? Math.max.apply(
        Math,
        questions.map((question) => {
          return Number(question.index)
        })
      ) + 1
    : 0
}

const getTypeOfChild = (type) => {
  if (type.indexOf('child') !== -1) return type
  return `group-${type}-child`
}

const setIndexQuestions = (tree) => {
  let index = 0
  return tree.map((q) => {
    let idx = index
    if (q.type.includes('group')) {
      q.children = q.children?.map((childQ) => {
        let childIdx = index
        index++
        return {...childQ, index: childIdx}
      })
    } else index++
    return {...q, index: idx}
  })
}

const getLengthQuestions = (questions) => {
  let count = 0
  questions.forEach((question) => {
    count++
    if (question.type === 'group') {
      question.children.forEach((child) => {
        if (child) count++
      })
    }
  })
  return count
}

const transformExportSubmission = (submissions, unit, currentCourse) => {
  return submissions.map((submission) => {
    const startTime = dayjs(submission.startTime).valueOf()
    const endTime = dayjs(submission.submitTime).valueOf()
    const timeDoTest = submission.submitTime ? endTime - startTime : 0
    if (submission.name) {
      return {
        ...submission,
        submitCount: submission.submitCount,
        duration: timeDoTest ? stringUtils.secondsToHms(timeDoTest / 1000) : '',
        unitInfo: unit,
        currentCourse: currentCourse,
      }
    } else {
      return {
        ...submission,
        submitCount: submission.submitCount,
        duration: timeDoTest ? stringUtils.secondsToHms(timeDoTest / 1000) : '',
        name: submission.user ? submission.user.name : vuetify.framework.lang.translator('$vuetify.TEXT_NOT_NAME'),
        email: submission.user ? submission.user.email : vuetify.framework.lang.translator('$vuetify.TEXT_NOT_EMAIL'),
        unitInfo: unit,
        currentCourse: currentCourse,
      }
    }
  })
}

const getBase64Image = async (imgLink) => {
  if (imgLink && imgLink) {
    const data = await fetch(imgLink)
    const blob = await data.blob()
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = function () {
        const base64data = reader.result
        resolve(base64data)
      }
    })
  }
  return ''
}
const sortQuestionGroupInLater = (questions) => {
  const questionChilds = []
  const questionGroups = []
  questions.forEach((question) => {
    if (question.type === 'group') questionGroups.push(question)
    else questionChilds.push(question)
  })
  return [...questionChilds, ...questionGroups]
}
const preprocessQuestions = async (questions = []) => {
  // duyệt qua nội dung của các câu hỏi, nếu câu hỏi có chứa ảnh chuyển sang base64 để in ra pdf
  const imgRegex = /<img[^>]+src="([^">]+)"/gm
  const promises = questions.map(async (question) => {
    let description = question.description || question.html || ''
    if (description) {
      const testImageRegex = imgRegex.exec(description)
      if (testImageRegex && testImageRegex.length) {
        const imgSrc = testImageRegex[1]
        const base64Src = await getBase64Image(imgSrc)
        description = description.replace(/src="([^">]+)"/gm, `src="${base64Src}"`)
      }
    }
    return {
      ...question,
      description,
    }
  })
  return Promise.all(promises)
}
const countAnswer = (question) => {
  return Object.keys(question.answers ?? {}).length || 4
}

const breakDataForDraft = (unit, exam, questions) => {
  const {type, resources, link, isUsePdf, homeworkQuestion, pdf, externalLink} = unit
  switch (type) {
    case 'audio':
    case 'pdf':
      return {
        [type]: unit[type],
        resources: resources,
        type,
      }
    case 'video':
    case 'vimeo':
      return {
        video: unit[type],
        resources: resources,
        type,
        externalLink,
      }
    case 'embedded':
      return {
        link,
        type,
      }
    case 'homework':
      return {
        isUsePdf,
        homeworkQuestion,
        pdf,
        type,
      }
    case 'exercise': {
      const {totalScore, totalTime, count} = caculateTotalTimeAndScore(questions)
      return {
        questions,
        totalScore,
        totalTime,
        count,
        resources: exam.resources,
        type,
      }
    }
    case 'test':
    case 'listening-test':
    case 'reading-test': {
      const {totalScore, totalTime, count} = caculateTotalTimeAndScore(questions)
      return {
        partition: exam.partition ?? {},
        questions,
        type,
        count,
        totalScore,
        totalTime,
        isPartition: get(exam, 'settings.isPartition', false),
      }
    }
    case 'matrix': {
      const isPartition = get(exam, 'settings.isPartition', false)
      const {transformPartsForMatrix, transformInitData, caculateScoreAndTimeMatrix, caculateScoreAndTimeMatrixPart} =
        matrixMethod
      let convert = isPartition ? transformPartsForMatrix(exam) : transformInitData(exam)

      const data = {
        isPartition,
        type,
      }
      if (!isPartition)
        return {
          ...data,
          ...caculateScoreAndTimeMatrix(convert),
          matrixConvert: convert,
        }
      let matrixConvertPart = {}
      if (isPartition) {
        Object.keys(convert).forEach((key) => {
          matrixConvertPart[key] = {
            ...convert[key],
            matrixConvert: transformInitData(convert[key]),
          }
        })
      }
      return {
        ...data,
        ...caculateScoreAndTimeMatrixPart(matrixConvertPart),
        matrixConvertPart,
      }
    }
    default:
      return {}
  }
}
const convertSettingsExamDraft = (settings) => {
  return {
    isPartition: settings.isPartition,

    isRandomQuestion: settings.isRandomQuestion,
    isUseFactor: settings.isUseFactor,
    isQuestionOneByOne: settings.isQuestionOneByOne,
    isDisablePrevious: settings.isDisablePrevious,
    shuffleAnswers: settings.shuffleAnswers,

    selectedTime: settings.selectedTime,
    selectedTypeScore: settings.selectedTypeScore,
    expiredTime: settings.expiredTime,

    numberOfExam: settings.numberOfExam,
  }
}

const setDirectFillChildrenData = (data, maxIdx) => {
  const questionsCreate = []
  const questionsRemove = []
  const questionsUpdate = []
  const {question} = data
  const oldKeys = data.oldKeys.split('_')
  const newKeys = data.newKeys.split('_')
  const maxlength = Math.max(oldKeys.length, newKeys.length)

  let children = clone(question.children ?? [], true)
  for (let index = 0; index < maxlength; index++) {
    if (oldKeys[index] !== newKeys[index]) {
      if (!oldKeys[index]) {
        const newQuestion = getNewQuestion(maxIdx, {
          time: 1,
          factor: 1,
          selectedType: 'direct-fill-blank-child',
          key: newKeys[index],
        })
        maxIdx++
        questionsCreate.push(newQuestion)
      } else {
        const childUpdate = children.find((que) => que.config.key === oldKeys[index])
        if (childUpdate)
          !newKeys[index]
            ? questionsRemove.push(childUpdate)
            : questionsUpdate.push({...childUpdate, config: {...childUpdate.config, key: newKeys[index]}})
      }
    }
  }
  return {
    questionsCreate,
    questionsRemove,
    questionsUpdate,
  }
}
export default {
  setDirectFillChildrenData,
  caculateTotalTimeAndScore,
  getDataQuestion,
  getQuestionWillDelete,
  convertTestQuestions,
  getNewQuestion,
  getTypeQuestions,
  getMaxIdx,
  getTypeOfChild,
  mapQuestionsGroup,
  setIndexQuestions,
  getLengthQuestions,
  flattenQuestion,
  mapQuestionsForPart,
  validateQuestion,
  validateMark,
  setIndexRandomQuestions,
  getFromToQuestionGroup,
  transformExportSubmission,
  transformQuestionPart,
  getPathOfKnowledge,
  convertChildrenToArray,
  getBase64Image,
  preprocessQuestions,
  countAnswer,
  flatQuestionInPart,
  breakDataForDraft,
  convertSettingsExamDraft,
  sortQuestionGroupInLater,
}
