import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const UserPathRepository = RepositoryFactory.get('userPath')
const namespaced = true

const state = {
  userPath: {},
  userPaths: [],
}
const actions = {
  async fetchUserPaths({commit}, params = {}) {
    const res = await UserPathRepository.fetch(params)
    commit('setUserPaths', res.data || [])
  },
  async fetchUserPath({commit}, params = {}) {
    const res = await UserPathRepository.fetchOneUserPath(params)
    commit('setUserPath', res.data || {})
    return res.data || []
  },
}

const mutations = {
  setUserPaths(state, userPaths) {
    state.userPaths = userPaths
  },
  setUserPath(state, userPath) {
    state.userPath = userPath
  },
}
const getters = {
  getUserPaths(state) {
    return state.userPaths
  },
  getUserPath(state) {
    return state.userPath
  },
}

export default {
  namespaced,
  getters,
  actions,
  mutations,
  state,
}
