import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Flashcard = RepositoryFactory.get('flashcard')
import utils from '@/helpers/utils.js'

const state = {
  flashcards: [],
  combo: {},
  count: 0,
}

const actions = {
  async getFlashcardOfUnit({commit}, params) {
    const result = await Flashcard.fetch(params)
    commit('setFlashcards', result.data || [])
    return result.data
  },
  async setWordsForUnit({commit, rootState}, params = {}) {
    if(!params.length) return
    params = params.map((i) => {
      // eslint-disable-next-line no-unused-vars
      const {id, ...item} = i
      return {
        ...utils.cleanObject(item),
        unit: rootState.unit.selectedUnit.id,
      }
    })
    const res = await Flashcard.createMany({data: params})
    commit('setFlashcards', res.data || [])
    return res.data
  },
}

const mutations = {
  setFlashcards(state, flashcards) {
    return (state.flashcards = flashcards)
  },
}

const getters = {
  getFlashcards: (state) => {
    return state.flashcards
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
