<template>
  <v-dialog width="500" v-model="dialog">
    <v-card>
      <v-card-title class="headline">
        {{
          $vuetify.lang.t(
            "$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_SEND_NOTIFICATION_TITLE"
          )
        }}
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5 pb-0">
        <v-form v-model="form" ref="form-send-notification">
          <BaseInput
            :label="
              $vuetify.lang.t(
                '$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_NOTIFICATION_HEADER_LABEL'
              )
            "
            :placeholder="
              $vuetify.lang.t(
                '$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_INPUT_NOTIFICATION_HEADER_PLACEHOLDER'
              )
            "
            :rules="[$rules.required]"
            v-model="title"
          />
          <BaseInput
            :label="
              $vuetify.lang.t(
                '$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_NOTIFICATION_CONTENT_LABEL'
              )
            "
            :placeholder="
              $vuetify.lang.t(
                '$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_INPUT_NOTIFICATION_CONTENT_PLACEHOLDER'
              )
            "
            :rules="[$rules.required]"
            v-model="content"
          />
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <BaseButton
          @click="closeDialog"
          isButtonText
          :text="$vuetify.lang.t('$vuetify.USER_MANAGE.BUTTON_CANCEL_TEXT')"
        />
        <BaseButton
          @click="onSendNotification"
          :text="
            $vuetify.lang.t(
              '$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_SEND_NOTIFICATION_BUTTON'
            )
          "
          :dark="false"
          :disabled="!form"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      title: "",
      dialog: false,
      content: "",
      form: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.title = "";
      this.content = "";
    },
    onSendNotification() {
      const validate = this.$refs["form-send-notification"].validate();
      if (validate) {
        this.$emit("sendNotification", { title: this.title, body: this.content });
      } else
        this.$message.warning(
          this.$vuetify.lang.t("$vuetify.USER_MANAGE.MSG_RECHECK_INVALID_FIELD")
        );
    },
  },
};
</script>
