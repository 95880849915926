const OPTIONS = {
	ONLINE_COURSE_TOPIC_LABEL: 'オンラインコースカテゴリ',
	ONLINE_COURSE_TOPIC_DESCRIPTION:
		'メニューにカテゴリリストを表示します。カテゴリが3つ以下ある場合、カテゴリ表示を有効にする必要がありません。',

	PROGRAM_LABEL: '学習プログラム',
	PROGRAM_DESCRIPTION:
		'オンラインコース、オフラインコース、イベント及びEbookを選ぶメニューです。',

	ACTIVE_COURSE_LABEL: 'コースアクティベーション',
	ACTIVE_COURSE_DESCRIPTION:
		'アクティベーションコードによるコースアクティベーションページです。',

	SEARCH_BAR_LABEL: '検索バー',
	SEARCH_BAR_DESCRIPTION:
		'検索バーを利用するとでウェブサイト内のコンテンツ検索が可能になります。コースが少ない場合、有効にする必要がありません。',

	DEPARTMENT_LABEL: '学舎',
	DEPARTMENT_DESCRIPTION:
		'学舎一覧ページ. 学舎内容はコース内容で表示されています。',
	BLOG_LABEL: 'ブログ',
	BLOG_DESCRIPTION: 'ウェブサイト上のブログ投稿一覧',

	ABOUT_US_LABEL: '私たちについて',
	ABOUT_US_DESCRIPTION: 'ウェブサイトについての紹介ページ',

	POLICY_LABEL: 'プライバシーポリシー',
	POLICY_DESCRIPTION: '規制ページ・プライバシーポリシー',

	RECRUITMENT_LABEL: '講師募集',
	RECRUITMENT_DESCRIPTION: '講師募集ページの表示・非表示設定',

	ONLINE_COURSE_LABEL: 'オンラインコース',
	ONLINE_COURSE_DESCRIPTION: '学習プログラム・オンラインコース一覧',

	OFFLINE_COURSE_LABEL: 'オフラインコース',
	OFFLINE_COURSE_DESCRIPTION: '学習プログラム・オフラインコース一覧',

	EVENT_LABEL: 'イベント',
	EVENT_DESCRIPTION: '学習プログラム・イベント一覧',

	EBOOK_LABEL: '電子書籍',
	EBOOK_DESCRIPTION: '学習プログラム・電子書籍一覧',

	ACTIVE_CODE_LABEL: 'コースアクティベーション',
	ACTIVE_CODE_DESCRIPTION:
		'アクティベーションコードによるコースアクティベーションページです。',

	PHONE_LABEL: '電話番号',
	PHONE_DESCRIPTION: '電話番号・連絡先',

	EMAIL_LABEL: 'メールアドレス',
	EMAIL_DESCRIPTION: 'メールアドレス・連絡先',

	ADDRESS_LABEL: '住所',
	ADDRESS_DESCRIPTION: '住所・連絡先',

	SOCIAL_NETWORK_LABEL: 'ソーシャルネットワークリンク',
	SOCIAL_NETWORK_DESCRIPTION: '一般設定/詳細設定/ブランドでのパス設定'
}

//Chưa dịch
const TOPIC_SETTING_DETAIL = {
	TITLE: 'カテゴリのカスタマイズ',
	SUBTITLE:
		'トップメニューのカテゴリセクションに表示されるカテゴリをカスタマイズする',
	LABEL_DISPLAY_TOPIC:
		'デフォルトのカテゴリの代わりにカスタムカテゴリを表示します。',
	TITLE_TOPIC_CONFIG: 'カテゴリの構成',
	BUTTON_ADD: '新規追加',
	BUTTON_SAVE: '保存',

	POPUP_EDIT: {
		LABEL_USE: 'カテゴリの使用',
		LABEL_TOPIC: 'カテゴリ',
		PLACEHOLDER_SELECT: 'カテゴリ選択',
		LABEL_NAME: 'アイテム名*',
		PLACEHOLDER_INPUT_NAME: '項目名を入力してください',

		LABEL_LINK: 'パス',
		//CHƯA DỊCH
		PLACEHOLDER_INPUT_LINK_ATTACHED: '項目名に添付されているリンクを入力してください',
		TITLE_ADD: '項目追加'
	},
	POPUP_CONFIRM_DELETE_TITLE: 'カテゴリ削除',
	POPUP_CONFIRM_DELETE_MESSAGE: '変更を保存してもよろしいですか？',

	POPUP_CONFIRM_SAVE_TITLE: '変更保存',
	POPUP_CONFIRM_SAVE_MESSAGE: 'この変更を保存します。よろしいでしょうか?'

}
//
export default {
	OPTIONS,
	TOPIC_SETTING_DETAIL,
	TITLE: 'メニュー設定',

	TAB_HEADER: 'ヘッダーメニュー（ヘッダー）',
	TAB_FOOTER: 'フッターメニュー（フッター）',
	TAB_TOPIC: 'カテゴリ',
  //
	SECTION_HEADER_TITLE: 'トップメニュー',
	SECTION_HEADER_DESCRIPTION:
		'ページ上部のメニューバーに表示したいフィールドを選択します。表示の見栄えを良くするには、3つ以下のフィールドをアクティブにする必要があります。',
	SECTION_HEADER_OPTION_DISPLAY_MARKET_HEADER:
		'トップメニューをeコマース形式で表示する',

	SECTION_FOOTER_TITLE: 'ボトムメニュー',
	SECTION_FOOTER_DESCRIPTION:
		'ページ下部のメニューバーに表示したいフィールドを選択します。',

	MSG_UPDATING_DATA: 'データが更新されています。',
	MSG_UPDATE_SUCESS: 'データが正常に更新されました',
	MSG_UPDATE_FAIL: 'データの更新に失敗しました',
	MSG_CHECK_INFO_AGAIN: 'もう一度情報を確認してください'
}
