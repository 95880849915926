const namespaced = true

const state = {
  pageInfo: {},
  isSavePage: true,
  pageOnEdit: null,
  idPageOnDelete: null,
  isDeletedPage: false
}

const actions = {
  setPage({commit}, page) {
    commit('setPage', page)
  },
  setCreatePageStatus({commit}, status) {
    commit('setCreatePageStatus', status)
  },
  setPageOnEdit({commit}, page) {
    commit('setPageOnEdit', page)
  },
  setIdPageOnDelete({commit}, id) {
    commit('setIdPageOnDelete', id)
  },
  onRefresh({commit}) {
    commit('onRefresh')
  },
  setIsDeletedPage({commit}, status) {
    commit('setIsDeletedPage', status)
  }
}

const mutations = {
  setPage(state, page) {
    state.pageInfo = page
  },
  setCreatePageStatus(state, status) {
    state.isSavePage = status
  },
  setPageOnEdit(state, page) {
    state.pageOnEdit = page
  },
  setIdPageOnDelete(state, id) {
    state.idPageOnDelete = id
  },
  onRefresh(state) {
    state.pageOnEdit = null
    state.idPageOnDelete = null
  },
  setIsDeletedPage(state, status) {
    state.isDeletedPage = status
  }
}

const getters = {
  getPage: (state) => state.pageInfo,
  getPageSettingStatus: (state) => state.isSavePage,
  getPageOnEdit: (state) => state.pageOnEdit,
  getIdPageonDelete: (state) => state.idPageOnDelete,
  getDeletedStatus: state => state.isDeletedPage
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
