import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const BillStatistic = RepositoryFactory.get('BillStatistic')

const state = {
  billStatistic: [],
  count: 0,
  report: {},
  graph: []
}

const actions = {
  async fetch({ commit }, params = {}) {
    const res = await BillStatistic.fetch({
      ...params,
      _sort: 'createdAt:desc'
    })
    commit('setBillStatistic', res.data || [])
    return res.data || []
  },
  async count({ commit }, params = {}) {
    const res = await BillStatistic.count({
      ...params,
    })
    commit('setCount', res.data || 0)
    return res.data || 0
  },
  async report({ commit }, params = {}) {
    const res = await BillStatistic.report({
      ...params,
    })
    commit('setReport', res.data[0] || {})
    return res.data[0] || {}
  },
  async getGraphData({ commit }, params = {}) {
    const res = await BillStatistic.graph({
      ...params,
    })
    commit('setGraph', res.data || [])
    return res.data || []
  },
}

const mutations = {
  setBillStatistic(state, bills) {
    state.billStatistic = bills
    return state.billStatistic
  },
  setCount(state, count) {
    state.count = count
    return state.count
  },
  setReport(state, report) {
    state.report = report
    return state.report
  },
  setGraph(state, graph) {
    state.graph = graph
    return state.graph
  }
}

const getters = {
  getBillStatistic: state => {
    return state.billStatistic
  },
  getCount: state => {
    return state.count
  },
  getReport: state => {
    return state.report
  },
  getGraph: state => {
    return state.graph
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
