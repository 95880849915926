import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'

const newTeacherUrl = process.env.VUE_APP_API_TEACHER + '/teacher'

const fetch = params => {
	return Repository(newTeacherUrl).fetch(params)
}

const fetchOne = id => {
	return Repository(newTeacherUrl).fetchOne(id)
}

const create = params => {
	return Repository(newTeacherUrl).create(params)
}

const changePassword = params => {
	return Repository(newTeacherUrl + '/admin-change-password').create(params)
}

const update = (id, params) => {
	return Repository(newTeacherUrl).update(id, params)
}

const remove = id => {
	return Repository(newTeacherUrl).delete(id)
}

const count = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(newTeacherUrl + '/count' + `?${query}`).fetch()
}

export default {
	fetchOne,
	create,
	update,
	remove,
	changePassword,
	fetch,
	count
}
