import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Comment = RepositoryFactory.get('comment')

const state = {
  comments: [],
  count: 0,
  replies: [],
  replyCount: 0,
}

const actions = {
  async fetchComments({ commit }, params) {
    const res = await Comment.fetch(params)
    commit('setComments', res.data || [])
  },
  async fetchCount({ commit }, params) {
    const res = await Comment.count(params)
    commit('setCount', res.data || 0)
  },
  async approveComments({ commit }, items = []) {
    let result = {}
    const promises = items.map(({ id }) => {
      return Comment.approve(id)
    }).filter(Boolean)
    try {
      const results = await Promise.all(promises);
      if (results && results.length) {
        results.forEach(result => {
          commit('addOrUpdateComment', { ...result.data, id: result.data._id } || {});
        })
      }
    } catch (error) {
      result.error = error
    }
    return result
  },
  async removeComments({ commit }, items = []) {
    let result = {}
    const promises = items.map(({ id }) => {
      return Comment.remove(id)
    }).filter(Boolean)
    try {
      await Promise.all(promises)
      items.forEach(item => {
        commit('removeComment', { id: item.id })
      })
    } catch (error) {
      result.error = error
    }
    return result
  },
  async fetchReplies({ commit }, params) {
    const res = await Comment.fetch(params)
    commit('setReplies', res.data || [])
  },
  async fetchReplyCount({ commit }, params) {
    const res = await Comment.count(params)
    commit('setReplyCount', res.data || 0)
  },
  async approveReplies({ commit }, items = []) {
    let result = {}
    const promises = items.map(({ id }) => {
      return Comment.approve(id)
    }).filter(Boolean)
    try {
      const results = await Promise.all(promises);
      if (results && results.length) {
        results.forEach(result => {
          commit('addOrUpdateReply', result.data || {});
        })
      }
    } catch (error) {
      result.error = error
    }
    return result
  },
  async removeReplies({ commit }, items = []) {
    let result = {}
    const promises = items.map(({ id }) => {
      return Comment.remove(id)
    }).filter(Boolean)
    try {
      await Promise.all(promises)
      items.forEach(item => {
        commit('removeReply', { id: item.id })
      })
    } catch (error) {
      result.error = error
    }
    return result
  },
  async createReply({ commit }, params) {
    const res = await Comment.create(params)
    commit('addReply', res.data || {})
  },
}

const mutations = {
  setComments(state, comments) {
    state.comments = comments
  },
  setCount(state, count) {
    state.count = count
  },
  addOrUpdateComment(state, item) {
    if (item && item.id) {
      let foundIndex = state.comments.findIndex(listItem => listItem && listItem.id === item.id)
      if (foundIndex > -1) {
        state.comments.splice(foundIndex, 1, item)
      } else {
        state.comments.unshift(item)
      }
    }
  },
  removeComment(state, item) {
    if (item && item.id) {
      let deleteIndex = state.comments.findIndex(listItem => listItem && listItem.id === item.id)
      if (deleteIndex > -1) {
        state.comments = state.comments.filter(listItem => listItem.id !== item.id)
        state.count = state.count ? state.count - 1 : 0
      }
    }
  },
  setReplies(state, replies) {
    state.replies = replies.reverse().concat(state.replies)
  },
  addOrUpdateReply(state, item) {
    if (item && item._id) {
      let foundIndex = state.replies.findIndex(listItem => listItem && listItem.id === item._id)
      if (foundIndex > -1) {
        state.replies.splice(foundIndex, 1, item)
      } else {
        state.replies.unshift(item)
      }
    }
  },
  removeReply(state, item) {
    if (item && item.id) {
      let deleteIndex = state.replies.findIndex(listItem => listItem && listItem.id === item.id)
      if (deleteIndex > -1) {
        state.replies = state.replies.filter(listItem => listItem.id !== item.id)
        state.replyCount = state.replyCount ? state.replyCount - 1 : 0
      }
    }
  },
  addReply(state, reply) {
    state.replies = [...state.replies, reply]
    state.replyCount += 1
  },
  resetReplies(state) {
    state.replies = []
    state.replyCount = 0
  },
  setReplyCount(state, count) {
    state.replyCount = count
  },
}

const getters = {
  getComments(state) {
    return state.comments
  },
  getCount(state) {
    return state.count
  },
  getReplies(state) {
    return state.replies
  },
  getReplyCount(state) {
    return state.replyCount
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
