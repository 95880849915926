import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_STUDENT + '/student'
import axios from 'axios'
import auth from '@/store/modules/auth'

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const fetch = (params) => {
  const query = queryString.stringify(params)
  return Repository(baseURL + `?${query}`).fetch()
}

const create = (params) => {
  return Repository(baseURL).create(params)
}

const changePassword = (params) => {
  return Repository(baseURL + '/admin-change-password').create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const remove = (id) => {
  return Repository(baseURL).delete(id)
}

const removeManyStudent = (ids) => {
  const query = queryString.stringify({
    vendorId: vendor.state.vendor.vendorId,
  })
  return Repository(baseURL + '/remove-many' + `?${query}`).create(ids)
}

const removeIP = (id, params) => {
  const query = queryString.stringify({
    ...params,
    vendorId: vendor.state.vendor.vendorId,
  })
  return axios.delete(baseURL + '/delete-ip' + `/${id}?${query}`, {
    headers: {
      Authorization: auth.state.auth.accessToken,
    },
  })
}

const count = (params) => {
  const query = queryString.stringify({
    ...params,
  })
  return Repository(baseURL + '/count' + `?${query}`).fetch()
}

const countDetail = (params) => {
  return Repository(baseURL + `/count-detail`).fetch(params)
}

export default {
  fetchOne,
  create,
  update,
  remove,
  fetch,
  count,
  changePassword,
  countDetail,
  removeIP,
  removeManyStudent,
}
