import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const UserCourse = RepositoryFactory.get('userCourse')

const state = {
  userCourse: {},
  userCourses: [],
  count: 0,
  statistic: []
}

const actions = {
  async fetchUserCourses({ commit }, params) {
    let data = await UserCourse.fetch(params)
    commit('setUserCourses', data.data && data.data.length ? data.data : [])
    return data.data
  },
  async fetchUserCourse ({ commit },  params ={}) {
      let data = await UserCourse.fetch({
        ...params
      })
      commit('setUserCourse', data.data && data.data.length ? data.data[0] : {})
      return data.data
  },
  async countUserCourses({ commit }, params = {}) {
    const res = await UserCourse.count({
      ...params,
    });
    commit('setCount', res.data)
    return res.data;
  },
  async statisticUserCourses({ commit }, params = {}) {
    const res = await UserCourse.statistic({
      ...params,
    });
    commit('setStatistic', res.data)
    return res.data;
  },
}

const mutations = {
  setUserCourses(state, userCourses) {
    state.userCourses = userCourses
  },
  setUserCourse (state, userCourse) {
    state.userCourse = userCourse
  },
  setCount(state, count) {
    state.count = count
  },
  setStatistic(state, statistic) {
    state.statistic = statistic
  }
}

const getters = {
  getUserCourses: state => {
    return state.userCourses
  },
  getNumbersUserCourses: state => {
    return state.count
  },
  getStatistic: state => {
    return state.statistic
  },
  getUserCourse: state => {
    return state.userCourse
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
