const ONLINE_COURSE_REPORT = {
	TITLE: 'Online Course',
	COURSE_UNIT: 'Course',
	LABEL_PUBLISHED_COURSE: 'Published',
	LABEL_FREE_COURSE: 'Free',
	LABEL_PAID_COURSE: 'There is a fee'
	}
	const TOP_COURSE_REPORT_CHART = {
	TITLE: 'Top Course Highlights',
	LABEL_COURSE_OTHER: 'Other',
	COURSE_UNIT: 'Course'
	}
	const TOTAL_NUMBER_STUDENTS_REPORT = {
	TITLE: 'Total number of students',
	STUDENT_UNIT: 'Students',
	LABEL_STUDENT_SELF_REGISTER: 'Self-Register',
	LABEL_STUDENT_CREATED_BY_ADMIN: 'Generated',
	LABEL_INTERACTION: 'Interactive'
	}
	const STUDENT_REPORT_CHART = {
	TITLE: 'Up for the week',
	LABEL_NEW_STUDENT: 'New Student',
	LABEL_UNVERIFY: 'Not activated'
	}
	const TOTAL_INCOME_REPORT = {
	TITLE: 'Total Sales',
	LABEL_TRANSFER: 'Transfer',
	LABEL_VNPAYBANK: 'Online',
	LABEL_DIRECPAY: 'Direct',
	LABEL_COD: 'COD',
	CURRENCY_UNIT: 'VND'
	}
	const INCOME_REPORT_CHART = {
	TITLE: 'Up for the week',
	LABEL_BILLS: 'Order',
	LABEL_INCOME: 'Revenue'
	}
	const TOTAL_DATA_SIZE_REPORT = {
	TITLE: 'Capacity'
	}
	const DOCCUMENT_UPLOAD_FILE_REPORT = {
	TITLE: 'Document Upload',
	FILE_UNIT: 'File'
	}
	const VIDEO_VIEWS_REPORT = {
	TITLE: 'Views',
	VIEWS_UNIT: 'times',
	LABEL_VIEWS: "Last month's video views"
	}
	const VIDEO_BAND_WIDTH_USED_REPORT = {
	TITLE: 'Bandwidth',
	LABEL_BAND_WIDTH: 'Current month video bandwidth usage',
	LABEL_BAND_WIDTH_NOTE: '(calculated starting from 1st of the month)'
	}
	const NEW_STUDENT_REPORT = {
	TITLE: 'New Student',
	LINK_TO_LIST_STUDENT: 'See more students',
	TH_STUDENT: 'Students',
	TH_EMAIL: 'Email',
	TH_PHONE_NUMBER: 'Phone',
	TH_STUDENT_CREATED_BY: 'Creator',
	TH_CREATE_TIME: 'Initialization time',
	TEXT_ADMIN: 'ADMINISTRATORS',
	TEXT_STUDENT_SELF_CREATED: 'Self-generated'
	}
	export default {
	ONLINE_COURSE_REPORT,
	TOP_COURSE_REPORT_CHART,
	TOTAL_NUMBER_STUDENTS_REPORT,
	STUDENT_REPORT_CHART,
	TOTAL_INCOME_REPORT,
	INCOME_REPORT_CHART,
	TOTAL_DATA_SIZE_REPORT,
	DOCCUMENT_UPLOAD_FILE_REPORT,
	VIDEO_VIEWS_REPORT,
	VIDEO_BAND_WIDTH_USED_REPORT,
	NEW_STUDENT_REPORT,
	//MESSAGE
	MSG_GET_DATA_LOADING: 'Getting data...',
	LABEL_UNDEFINED: 'Unknown'
	}