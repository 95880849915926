<template>
  <a
    target="_blank"
    class="black--text cursor-pointer body-2 font-weight-medium action-hover"
    @click="$emit('click')"
  >
    <slot />
    <div class="d-flex align-center" v-if="isBack" :class="textClass">
      <v-icon size="18" class="mr-1">mdi-arrow-left</v-icon> {{ backText }}
    </div>
  </a>
</template>
<script>
export default {
  props: {
    isBack: Boolean,
    backText: String,
    textClass: String,
  },
};
</script>
