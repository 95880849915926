import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Attendance = RepositoryFactory.get('attendance')
const namespaced = true

const state = {
  count: 0,
  attendances: [],
  classAttendance: [],
}

const actions = {
  async fetchCount({commit}, params) {
    const res = await Attendance.count({...params})
    commit('setCount', res.data)
  },

  async fetchAllAttendance({commit}, params) {
    const res = await Attendance.fetch(params)
    commit('setAttendances', res.data || [])
    return res.data || []
  },

  async createAttendance(_, params) {
    const res = await Attendance.create(params)
    return res.data || {}
  },

  async createManyAttendance(_, params) {
    await Attendance.createMany(params)
  },

  async updateManyAttendance(_, params) {
    await Attendance.updateMany(params)
  },

  async removeAllAttendance(_, params) {
    await Attendance.removeMany({...params})
  },
  resetAttendance({commit}) {
    commit('setAttendances', [])
  },
  async reportstudentAttendance({commit}, params) {
    const res = await Attendance.reportStudentAttendance(params)
    commit('setStudentAttendance', res.data || [])
    return res.data || []
  },
}

const mutations = {
  setCount(state, count) {
    state.count = count
  },
  setAttendances(state, data) {
    state.attendances = [...data]
  },
  setStudentAttendance(state, data) {
    state.attendance = [...data]
  },
}

const getters = {
  getCount: (state) => state.count,
  getAllAttendance: (state) => state.attendances,
  getAttendance: (state) => state.attendance,
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
