export default {
	HINT_TIME_NOT_INVALID: '時間が無効です。',
	HINT_INFO_OBLIGATORY: '必須な情報です。',
	HINT_NO_WHITESPACES: 'スペースがありません。',
	HINT_PERCENTAGE: '値は100未満で0より大きい必要があります。',
	HINT_MAX_LENGTH: '最大長は{0}です。',
	HINT_MIN_LENGTH: '最小長は{0}です。',
	HINT_PHONE_NUMBER_FALSE: '電話番号が不正です。',
	HINT_EMAIL_FORMAT_FALSE: 'メールアドレスのフォーマットが不正です。',
	HINT_URL_FORMAT_FALSE: 'パスのフォーマットが不正です。',
	HINT_NUMBER_FORMAT_FALSE: '数字のフォーマットが不正です。',
	HINT_VALID_PRICE:
		'支払いゲートウェイは 10,000 VND を超える取引が必要です!',
	HINT_NUMBER_GREATER_EQUAL: '{0}以上の数字です。',
	HINT_NUMBER_GREATER_THAN: '{0}より大きい数字です。',
	HINT_NUMBER_LESS_THAN: '{0}未満の数字です。',
	HINT_NUMBER_LESS_EQUAL: '{0}以上の数字です。',
	HINT_NUMBER_CLAMP: '{0}から{1}までの数字です。',
	HINT_DATE_GREATER_THAN: '選択した日付は{0}日より後である必要があります。',
	HINT_DATE_LESS_THAN: '選択した日付は{0}日より前である必要があります。',
	HINT_TIME_LESS_THAN: '開始時刻は終了時刻より前である必要があります。',
	HINT_DOMAIN_FORMAT_FALSE: 'ドメインのフォーマットが不正です。',

	HINT_AT_LEAST_ONE: '少なくとも1文字あります。',
	MSG_CHECK_FILE_FORMAT_AGAIN: 'ファイルの形式をもう一度確認してください'
}
