import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Homework = RepositoryFactory.get('homework')
const namespaced = true;

const state = {
  homeworks: {},
  count: 0
};

const actions = {
  async fetchHomeworks({ commit }, params = {}) {
    const res = await Homework.fetch({
      ...params,
    });
    commit("setHomeworks", res.data || []);
    return res.data;
  },
  async countHomeworks({ commit }, params = {}) {
    const res = await Homework.count({
      ...params,
    });
    commit('setCount', res.data)
    return res.data;
  },
  async updateHomework({ commit }, { id, homework }) {
    const res = await Homework.update(id, homework);
    commit("updateHomework", {homework: res.data || {}, isPureUpdate: true});
    return res.data
  },
  async fetchHomeworksIndependent(_, params = {}) {
    const res = await Homework.fetch(params)
    return res.data
  },
  async markHomework({commit, rootState}, {id, homework}) {
    const res = await Homework.mark(id, {
      ...homework,
      userId: rootState.auth.auth.id,
    })
    commit("updateHomework", {homework: res.data || {}})
    return res.data
  },
  async countHomeworksIndependent(_, params = {}) {
    const res = await Homework.count(params)
    return res.data || 0
  }
};

const mutations = {
  setHomeworks(state, homeworks) {
    return state.homeworks = homeworks.reduce(
      (acc, cur) => ({...acc, [cur._id]: cur}),
      {}
    )
  },
  setCount(state, count) {
    return state.count = count
  },
  updateHomework(state, {homework, isPureUpdate = false}) {
    const tmp = {...homework}
    const existed = state.homeworks[tmp._id]
    if (isPureUpdate) tmp.markedBy = existed.markedBy
    state.homeworks[tmp._id] = tmp
    return (state.homeworks = {
      ...state.homeworks,
      [tmp._id]: tmp,
    });
  }
};

const getters = {
  getHomeworks: (state) => {
    return Object.values(state.homeworks);
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
