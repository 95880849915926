<template>
  <v-tooltip color="grey darken-3" top close-delay="50">
    <template v-slot:activator="{on, attrs}">
      <v-icon
        :color="iconColor"
        class="cursor-pointer"
        :small="isIconSmall"
        v-bind="attrs"
        v-on="on"
        @click="$emit('click')"
      >
        {{ iconName }}
      </v-icon>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    tooltipText: String,
    iconName: String,
    iconColor: {
      type: String,
      default: 'primary'
    },
    isIconSmall: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
