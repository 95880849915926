<template>
  <BaseUploadDragger
    :type="type"
    :maxFilesize="maxFilesize"
    @onUploadSuccess="(data) => onUploadSuccess(data)"
    :relation="relation"
    :max-files="maxFiles"
    :upload-multiple="uploadMultiple"
    :singleUploadFileSize="singleUploadFileSize"
    :is-confirm-popup-used="isConfirmPopupUsed"
  >
    <div id="wrapper" class="has-dashed full-width d-flex justify-center">
      <div class="d-flex flex-column justify-center">
        <div class="subtitle-1 font-weight-bold text-align-center">
          {{
            $vuetify.lang.t('$vuetify.COURSE.COURSE_OVERVIEW.TITLE_DRAG_DROP')
          }}...
        </div>
        <v-btn class="subtitle-2 mt-2 text-capitalize" rounded color="primary">
          {{
            $vuetify.lang.t(
              '$vuetify.COURSE.COURSE_OVERVIEW.BUTOTN_SELECT_FILE_IN_MACHINE'
            )
          }}
        </v-btn>
      </div>
    </div>
  </BaseUploadDragger>
</template>
<script>
export default {
  props: {
    maxFilesize: {
      type: Number,
      default: 3750,
    },
    singleUploadFileSize: {
      type: Number,
      default: 2560,
    },
    type: String,
    relation: {
      type: Object,
      default: () => ({}),
    },
    maxFiles: {
      type: Number,
      default: 1,
    },
    uploadMultiple: {
      type: Boolean,
      default: false,
    },
    isConfirmPopupUsed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onUploadSuccess(data) {
      this.$emit('onUploadSuccess', data)
    },
  },
}
</script>
<style lang="scss" scoped>
#wrapper {
  background: #f8f8f8;
  height: 125px;
}
#wrapper .dz-message {
  display: none;
}
</style>
