<template>
  <div class="question-overview">
    <div class="question-overview__parent" v-if="description">
      <div
        class="question-overview__description canvas-element-exam"
        v-for="(element, i) in description"
        :key="'parent' + i"
        :class="{'is-last': i === description.length - 1}"
      >
        <div class="question-overview__parent-title">Câu hỏi nhóm ('Điền trực tiếp vào câu hỏi')</div>
        <div v-html="element"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {paragraphDivision, handleCorrect} from '@/helpers/components/pdfRendererUtils'
import get from 'lodash-es/get'
export default {
  props: {
    question: Object,
  },
  computed: {
    ...mapGetters({
      submission: 'pdfRender/getSubmission',
    }),
  },
  data() {
    return {description: ''}
  },
  methods: {
    handleGroupQuestion(groupQuestion) {
      let {html, keys, id: groupQuestionId} = groupQuestion
      keys.forEach((key, index) => {
        const question = groupQuestion.questions.find((x) => get(x, 'config.key', '') === key)
        const patternToReplace = `<span id="child_${groupQuestionId}_${key}_${index}"></span>`
        if (!question) html = html.replace(patternToReplace, '')
        else {
          const answer = this.submission.answers[question.id]
          const studentAnswer = get(answer, 'answer', '')
          const isCorrect = get(answer, 'isCorrect', false)
          let res = [studentAnswer]
          res.push(
            `<span>${
              isCorrect
                ? '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green"><path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>'
                : '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>'
            }</span>`
          )
          const correct = handleCorrect(question)
          res.push(`(<span class="font-weight-medium">Đáp án:&nbsp;</span><span class="green--text">${correct}</span>)`)
          html = html.replace(patternToReplace, res.join(' '))
        }
      })
      this.description = paragraphDivision(html)
    },
  },
  watch: {
    question: {
      handler(groupQuestion) {
        if (groupQuestion) this.handleGroupQuestion(groupQuestion)
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.question-overview__parent-title {
  padding-top: 4px;
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
}
.question-overview__title {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 8px;
}
.question-overview__description {
  font-size: 14px;
  &.is-last {
    padding-bottom: 8px;
  }
}
.canvas-element-exam {
  padding-left: 16px;
  padding-right: 16px;
}
</style>
