const PASSWORD_CHANGE_FORM = {
	TITLE: 'パスワード変更',
	LABEL_OLD_PASSWORD: '古いパスワード',
	PLACEHOLDER_OLD_PASSWORD: '古いパスワードを入力してください。',
	LABEL_NEW_PASSWORD: '新しいパスワード',
	PLACEHOLDER_NEW_PASSWORD: '新しいパスワードを入力してください',
	LABEL_NEW_PASSWORD_RETYPE: 'パスワードを認証する',
	PLACEHOLDER_NEW_PASSWORD_RETYPE: '新しいパスワードを入力',
	RULE_NEW_PASSWORD_RETYPE: '新しいパスワードとパスワードの確認が一致しません',
	HINT_PASSWORD: 'パスワードには6文字以上含んでいる必要があります',
	BUTTON_UPDATE: 'パスワード変更',
	//MESSAGE
	MSG_UPDATE_LOADING: '受け取っています',
	MSG_UPDATE_SUCCESS: 'パスワードが正常に変更されました',
	MSG_INFO_INCORRECT: '情報を再度確認します。',
	MSG_WRONG_OLD_PASSWORD: '古いパスワードが正しくありません',
	MSG_UPDATE_FAIL: 'パスワードの変更に失敗しました！ページをリロードして、数分後にもう一度お試しください。',
}
const CONTACT_INFO_UPDATE_FORM = {
	TITLE: '連絡先',
	TITLE_FORM: '連絡先情報の設定',
	LABEL_DISPLAY_NAME: '氏名',
	PLACEHOLDER_DISPLAY_NAME: '例：名無しの権兵衛',
	LABEL_PHONE_NUMBER: '電話番号（ホットライン）',
	PLACEHOLDER_PHONE_NUMBER: '例：0123321000',
	LABEL_EMAIL: 'メールアドレス',
	PLACEHOLDER_EMAIL: '例：yourname@hosting.com',
	LABEL_ADDRESS: '住所',
	PLACEHOLDER_ADDRESS: '例：通り/通り、区/コミューン、地区/地区、市/県 を指定してください',
	HINT_ADDRESS:
		'注意：複数の住所を使用する場合、アドレスは、「{0}」で区切る必要があります',
	BUTTON_UPDATE: '更新',
	//MESSAGE
	MSG_INFO_INCORRECT: '情報を再度確認します。',
	MSG_UPDATE_SUCCESS: 'データが正常に更新されました。'
}
export default {
	PASSWORD_CHANGE_FORM,
	CONTACT_INFO_UPDATE_FORM,
	BUTTON_UPDATE: '更新'
}
