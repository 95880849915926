import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Ebook = RepositoryFactory.get('ebook')

const state = {
  books: [],
  book: {},
};

const actions = {
  async fetchBooks({ commit }, params) {
    const res = await Ebook.fetch(params)
    commit('setBooks', res.data || [])
    return res.data || []
  },
  async fetchBook({ commit }, id) {
    const res = await Ebook.fetchOne(id)
    commit('setBook', res.data || {})
    return res.data || {}
  },
  async createBook(_, params) {
    const res = await Ebook.create(params)
    return res.data || {}
  },
  async updateBook(_, { id, ...params }) {
    const res = await Ebook.update(id, params)
    return res.data
  },
  async removeBook({ commit }, { id }) {
    const res = await Ebook.remove(id)
    commit('removeBook', id)
    return res.data
  },
}

const mutations = {
  setBooks(state, list) {
    state.books = list
  },
  setBook(state, book) {
    state.book = book
  },
  removeBook(state, id) {
    let deletedIndex = state.books.findIndex(listItem => listItem.id === id)
    if (deletedIndex > -1) {
      state.books = state.books.filter(listItem => listItem.id !== id)
    }
  },
};

const getters = {
  getBooks: (state) => state.books,
  getBook: (state) => state.book,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
