export default{
  BUTTON_DOWNLOAD: "ダウンロード",
  BUTTON_TUTORIAL: "インストールガイド",
  HEADING_TEXT: "タイトル",
  BUTTON_TEXT: "ボタン名",
  MAIN_TITLE: "タイトル",
  SUBTITLE: "サブタイトル",
  EBOOK:'電子書類',
  LEAD_FORM: "フォーム",
  BUTTON_SEND: "送付",
  TITLE_AUTHOR: "著者",
  SUBTITLE_QUOTE: "引用",
  DESCRIPTION:'説明',
  HEADING_ONLINE_COURSE: "オンラインコース",
  HEADING_OFFLINE_COURSE: "オフラインコース",
  HEADING_LEARNING_PATH: "学習ルート",
  HEADING_MY_ONLINE_COURSE: "マイオンラインコース",
  SLIDE:"スライド",
  SUBHEADING_TEXT: "小見出しテキスト",
}