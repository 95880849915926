// USE IN LIST SECTION (FOR TITLE) AND LIST SECTION SELECTION (DESCRIPTION, WHILE LABEL REUSES TITLE)
const SECTION = {
	// BANNER
	BANNER_GROUP_NAME: 'Banner',

	BANNER_SLIDER_TITLE: 'Full screen slide banner',
	BANNER_SLIDER_DESCRIPTION:
		'The Banner Slide Show takes up the entire screen width.',

	BANNER_TOPIC_TITLE: 'Banner associated with the theme',
	BANNER_TOPIC_DESCRIPTION: 'Banner slide show associated with the theme.',

	BANNER_SINGLE_IMAGE_SLIDER_TITLE: 'Small slide banner',
	BANNER_SINGLE_IMAGE_SLIDER_DESCRIPTION:
		'Compact slide show suitable for baby photos.',

	BANNER_TEXT_TITLE: 'Banner & Description Text',
	BANNER_TEXT_DESCRIPTION: 'Show text and navigation buttons on 1 banner.',

	BANNER_LEAD_FORM_TITLE: 'Picture with information form',
	BANNER_LEAD_FORM_DESCRIPTION:
		'A form displayed on top of a background image. Information from this form will be transfered to "Contact", in "Financial Management".',

  BANNER_POPUP_TITLE: 'Small banner with popup or CTA',
  BANNER_POPUP_DESCRIPTION: 'Display condensed text. Can config CTA button or information popup.',
	// CARD
	CARD_GROUP_NAME: 'Card for learning content',

	CARD_OFFLINE_COURSE_TITLE: 'Offline Course Card',
	CARD_OFFLINE_COURSE_DESCRIPTION:
		'List of many Offline Course information cards.',

	CARD_ONLINE_COURSE_TITLE: 'Online course card',
	CARD_ONLINE_COURSE_DESCRIPTION:
		'A list of many Online Course information cards.',

	CARD_MY_ONLINE_COURSE_TITLE: 'My Course Card',
	CARD_MY_ONLINE_COURSE_DESCRIPTION:
		'List of many online Course information cards already owned.',

	CARD_EVENT_TITLE: 'Event card',
	CARD_EVENT_DESCRIPTION: 'Show event tag options in the list',

	CARD_DEPARTMENT_TITLE: 'Base Card',
	CARD_DEPARTMENT_DESCRIPTION:
		'Base card option - hub. Suitable for hubs with multiple layers - branches.',

	CARD_EBOOK_TITLE: 'Digital Document Card',
	CARD_EBOOK_DESCRIPTION: 'Document tag (ebook) options show up in the list',

	CARD_LEARNING_PATH_TITLE: 'Learning path card',
	CARD_LEARNING_PATH_DESCRIPTION:
		'The route card option is displayed in the list',

	// MEDIA
	MEDIA_GROUP_NAME: 'Photos and videos',

	MEDIA_IMAGE_DESCRIPTION_TITLE: 'Image description',
	MEDIA_IMAGE_DESCRIPTION_DESCRIPTION:
		'Display one photo per page. Size is smaller than Banner.',

	MEDIA_GALLERY_PICTURE_TITLE: 'Photo Gallery',
	MEDIA_GALLERY_PICTURE_DESCRIPTION:
		'Display a list of photos with original aspect ratio.',

	// MISC
	MISC_GROUP_NAME: 'Additional Content',

	MISC_TESTIMONIAL_SLIDER_TITLE: 'User Comments',
	MISC_TESTIMONIAL_SLIDER_DESCRIPTION:
		'Show one or more user comments as a slide.',

	MISC_TEXT_EDITOR_TITLE: 'Content Editor',
	MISC_TEXT_EDITOR_DESCRIPTION: 'Allow self-editing of display content',

	MISC_TEACHER_TITLE: 'Teacher',
	MISC_TEACHER_DESCRIPTION: 'Show teacher information',

	MISC_BLOG_NEWS_TITLE: 'News',
	MISC_BLOG_NEWS_DESCRIPTION: 'News',

	MISC_CATEGORY_TITLE: 'Subject',
	MISC_CATEGORY_DESCRIPTION: 'Select display theme'
}
const ACTION_BAR = {
	GO_BACK_TEXT: 'Home',
	CONFIRM_GO_BACK_TITLE: 'Are you sure you want to return to the homepage?',
	CONFIRM_GO_BACK_DESCRIPTION: 'Your changes will not be saved.'
}
const DETAIL = {
	GO_BACK_TEXT: 'List of content blocks',
	BTN_ADD_COMPONENT: 'Add Information Block',
	CONFIRM_REMOVE_COMPONENT_TITLE:
		'Are you sure you want to delete this content block?',
	CONFIRM_REMOVE_COMPONENT_DESCRIPTION:
		"The data is still recoverable if you don't save the changes."
}
const NAVIGATION = {
	// NAVIGATION SECTION
	NS_COMMON: 'Common Identity',
	NS_HOMEPAGE: 'Home',
	NS_ABOUT_US: 'About Us',
	NS_PRIVACY_POLICY: 'Policy page',
	NS_RECRUITMENT: 'Page become a trainer',
	// CONFIRM CHANGE NAVIGATION
	CONFIRM_CHANGE_NAVIGATION_TITLE: 'Move to another page',
	CONFIRM_CHANGE_NAVIGATION_DESCRIPTION: 'Unsaved data cannot be recovered'
}
// MAIN COMPONENT, RE-USE MORE, PUT IN INPUT STYLE
const CONFIGURATION = {
  BACKGROUND_TITLE: 'Background Image',
  IMAGE_TITLE: 'Picture',
  RECOMMENDED_IMAGE_SIZE: 'Recommended size {0}',
  INPUT_HEADING_LABEL: 'Title',
  INPUT_HEADING_PLACEHOLDER: 'Header Information',
  INPUT_DESCRIPTION_LABEL: 'Description',
  PLACEHOLDER_INPUT_DESCRIPTION: 'Enter description',
  INPUT_DESCRIPTION_PLACEHOLDER: 'Description info',
  INPUT_BTN_NAME_LABEL: 'Button Title',
  INPUT_BTN_NAME_PLACEHOLDER: 'Enter button title',
  INPUT_MAIN_HEADING_LABEL: 'Main Header',
  INPUT_MAIN_HEADING_PLACEHOLDER: 'Enter main title',
  INPUT_SUB_HEADING_LABEL: 'Subheader',
  INPUT_SUB_HEADING_PLACEHOLDER: 'Enter subtitle',
  
  CUSTOM_REDIRECT_ON_BTN_TITLE: 'Custom redirect link for view-more button',
  CUSTOM_REDIRECT_ON_BTN_DESCRIPTION_ONLINE_COURSE: 'Config redirect link by topic of online course. If you do not config custom redirect link, the default links to online course list.',
  CUSTOM_REDIRECT_ON_BTN_DESCRIPTION_OFFLINE_COURSE: 'Config redirect link by topic of offline course. If you do not config custom redirect link, the default links to offline course list.',
  CUSTOM_REDIRECT_ON_BTN_DESCRIPTION_EBOOK: 'Config redirect link by topic of ebook. If you do not config custom redirect link, the default links to ebook list.',
  
  // BANNER_DOWNLOAD
  BP_MAIN_CONTENT_TITLE: 'Main content',
  BP_MAIN_CONTENT_DESCRIPTION: 'Display on the left side of banner',
  BP_OPTION_CTA_BTN_VISIBILITY: 'Display call-to-action button',
  BP_INPUT_CTA_BTN_NAME_LABEL: 'Title for call-to-action button',
  BP_INPUT_CTA_BTN_NAME_PLACEHOLDER: 'Enter title for button',
  BP_INPUT_CTA_BTN_LINK_LABEL: 'Attach URL to redirect when clicking on button',
  BP_INPUT_CTA_BTN_LINK_PLACEHOLDER: 'Enter url for button',
  BP_OPTION_POPUP_VISIBILITY: 'Display information popup',
  BP_INPUT_OPEN_POPUP_BTN_LABEL: 'Title for open-popup button',
  BP_INPUT_OPEN_POPUP_BTN_PLACEHOLDER: 'Enter title for button',
  BP_INPUT_POPUP_TITLE_LABEL: 'Information popup title',
  BP_INPUT_POPUP_TITLE_PLACEHOLDER: 'Enter popup title',
  
  // BANNER_SLIDER
  OPTION_ADD_LINK_TO_IMAGE: 'Assemble link when clicking on image',
  INPUT_IMAGE_LINK_LABEL: 'Link when clicking on image',
  INPUT_IMAGE_LINK_PLACEHOLDER: 'Enter path',
  BTN_ADD_IMAGE: 'Add photo',

	// BANNER_TEXT
  BTXT_DESCRIPTION_LABEL: 'Content',
  BTXT_DESCRIPTION_PLACEHOLDER: 'Content up to 200 characters',
  BTXT_LINK_ON_BTN_LABEL: 'Path for node',
  BTXT_LINK_ON_BTN_PLACEHOLDER: 'Enter path for node',
  BTXT_TEXT_ALIGN_LABEL: 'Displayed content position',
  BTXT_TEXT_ALIGN_PLACEHOLDER: 'Select display position',
  BTXT_TEXT_ALIGN_OPTION_LEFT: 'Left',
  BTXT_TEXT_ALIGN_OPTION_CENTER: 'Center',
  
  // BANNER_TOPIC
  BTPC_TOPIC_SECTION_TITLE: 'Subject Selection',
  BTPC_TOPIC_SECTION_DESCRIPTION:
  'Each topic should be accompanied by 3 courses and subtopics',
  BTPC_TOPIC_PICKER_LABEL: 'Theme',
  BTPC_TOPIC_PICKER_PLACEHOLDER: 'Select theme',
  BTPC_CHILD_TOPIC_PICKER_LABEL: 'Child theme',
  BTPC_CHILD_TOPIC_PICKER_PLACEHOLDER: 'Select child theme',
  COURSE_PICKER_LABEL: 'Course',
  COURSE_PICKER_PLACEHOLDER: 'Select courses to display',
  BTPC_BTN_ADD_CHILD_TOPIC: 'Add child theme',
  BTPC_BTN_ADD_TOPIC: 'Add topic',

	// BLOG
  BLOG_DESCRIPTION: 'Only works to display the last 3 posts',

  CATEGORY_LIST_TITLE: 'List of topics',
  
  DEPARTMENT_LIST_TITLE: 'Base List',
  DEPARTMENT_PICKER_LABEL: 'Department',
  DEPARTMENT_BTN_ADD: 'Add Facility',
  
  EBOOK_LIST_TITLE: 'List of documents',
  EBOOK_PICKER_LABEL: 'Document',
  EBOOK_PICKER_PLACEHOLDER: 'Select documents to display',
  
  EVENT_LIST_TITLE: 'Event List',
  EVENT_PICKER_LABEL: 'Event',
  EVENT_PICKER_PLACEHOLDER: 'Select events to display',
  
  GALLERY_IMAGE_LIST_TITLE: 'List of photos (Up to 30 pictures)',
  
  IMAGE_DESCRIPTION_INFO_TITLE: 'Display info',
  IMAGE_DESCRIPTION_BUTTON_VISIBILITY_TITLE: 'Show button',
  IMAGE_DESCRIPTION_BUTTON_VISIBILITY_LABEL: 'Show button',
  
  LEAD_FORM_HEADING_LABEL: 'Form Title',
  LEAD_FORM_HEADING_PLACEHOLDER: 'Enter form title',
  
  DISPLAY_STYLE_LABEL: 'Display',
  DISPLAY_STYLE_PLACEHOLDER: 'Select display format',
  DISPLAY_STYLE_OPTION_GRID: 'Grid',
  DISPLAY_STYLE_OPTION_SLIDE: 'Slide Format',
  CARD_INTRO_BANNER_TITLE: 'Introduction Banner',
  CONFIRM_REMOVE_BANNER_TITLE: 'Are you sure you want to delete this banner?',
  CONFIRM_REMOVE_BANNER_DESCRIPTION:
  'Data after deletion cannot be recovered. The system will delete both the image and the URL associated with the image. Are you sure you want to remove the banner?',
  
  // LEARNING_PATH
  LP_LIST_TITLE: 'List of learning routes',
  LP_PICKER_LABEL: 'Learning path',
  LP_PICKER_PLACEHOLDER: 'Select display learning route',
  LP_CARD_INTRO_BANNER_DESCRIPTION:
  'The fourth learning path will be hidden when using the banner.',
  
  // ONLINE_COURSE
  OC_CARD_INTRO_BANNER_DESCRIPTION:
  'The fourth course will be hidden when using the banner.',
  
  ONLINE_OFFLINE_COURSE_LIST_TITLE: 'Course List',
  
  CARD_TITLE_NTH: 'Card', // How many test cards

  TESTIMONIAL_SLIDER_USER_NAME_LABEL: 'Username',
  TESTIMONIAL_SLIDER_USER_NAME_PLACEHOLDER: 'Enter username',
  TESTIMONIAL_SLIDER_USER_COMMENT_LABEL: 'Comment content',
  TESTIMONIAL_SLIDER_USER_COMMENT_PLACEHOLDER: 'Up to 200 characters',
  TESTIMONIAL_SLIDER_BTN_ADD_CARD: 'Add Card',
  TESTIMONIAL_SLIDER_CONFIRM_REMOVE_CARD_TITLE:
  'Are you sure you want to delete this comment tag?',
  TESTIMONIAL_SLIDER_CONFIRM_REMOVE_IMAGE_TITLE: 'Are you sure you want to delete this photo?'
}
const SITE_INFO = {
  SITE_NAME_TITLE: 'Site Name',
  SITE_NAME_LABEL: 'Site name',
  SITE_NAME_PLACEHOLDER: 'Enter site name',
  LOGO_TITLE: 'Logo',
  LOGO_DESCRIPTION: 'PNG format - Size 150x60 px',
  MAIN_COLOR_TITLE: 'Main color',
  FAVICON_TITLE: 'Favicon',
  FAVICON_DESCRIPTION:
  'Favicon format (.png) - Size 32x32 px - Resolution 72 pixels/inch',
  THUMBNAIL_TITLE: 'Thumbnail',
  THUMBNAIL_DESCRIPTION: 'Picture Format'
}
export default {
	SECTION,
	ACTION_BAR,
	DETAIL,
	NAVIGATION,
	CONFIGURATION,
	SITE_INFO,
  BUTTON_SAVE: 'Save',
  BUTTON_CANCEL_TEXT: 'Cancel',
  BUTTON_CONFIRM: 'Confirm',
  BUTTON_CANCEL: 'Cancel',
  BUTTON_ADD: 'Add',
  BUTTON_DELETE: 'Delete',
  BUTTON_BACK: 'Back',
  BUTTON_SAVE_AND_CONTINUE: 'Save & Continue',
  // MESSAGE
  MSG_UPDATE_SITE_LOADING: 'Updating page...',
  MSG_UPDATE_SUCCESS: 'Update successful!',
  // IFRAME
  IFRAME_LEARNING_PAGE_TITLE: 'Learning Page', // NOT SURE IF NEED TRANSLATE OR NOT
  
  LABEL_COURSE_CARD_BY_TOPIC: 'Course card by subject',
  HINT_COURSE_CARD_BY_TOPIC: '4 online courses displayed by topic',
  LABEL_ONLINE_COURSE_CARD_BY_TOPIC: 'Online course card by subject'
}
