<template>
  <div>
    <slot />
  </div>
</template>
<script>
import JSZip from 'jszip'
import saveAs from 'jszip/vendor/FileSaver'
import mimeTypes from 'mime-types'
import get from 'lodash-es/get'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    unit: Object,
    isStudentNameShown: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async generateZipFile({
      homeworks = [],
      i = 0,
      totalHomeworks = 1,
      zipCount = 1,
      progressBar
    }) {
      const zip = new JSZip()
      let logs = []
      for (let j = 0; j < homeworks.length; j++) {
        const homework = homeworks[j]
        const studentFolderName = this.generateStudentFolderName(homework)
        const studentFolder = zip.folder(studentFolderName)
        logs.push(studentFolderName)
        if (homework.type === 'multiple-files') {
          const resources = this.getResources(homework)
          if (this.checkIfArrContainsElem(resources)) {
            const resLogs = await this.handleMultipleFiles({
              studentFolder,
              resources
            })
            logs = logs.concat(resLogs)
          } else {
            logs.push('Student has committed NO files')
          }
        } else {
          studentFolder.file(`link_${Date.now()}.txt`, homework.link)
          logs.push(`- ${homework.link}`)
        }
        const percentage = Math.round(((i + j + 1) / totalHomeworks) * 100)
        progressBar.changeCustomStatus(
          `${this.$vuetify.lang.t('$vuetify.COURSE.COURSE_SECTION_REPORT.MSG_COMPRESSING_TO')} ${i + j + 1}`,
          percentage
        )
      }
      zip.file(`logs_${zipCount}.txt`, logs.join('\n'))
      const zipBlob = await zip.generateAsync({type: 'blob'})
      saveAs(zipBlob, this.generateZipFileName(zipCount))
    },
    generateStudentFolderName(homework = {}) {
      if (this.isStudentNameShown) {
        let prefix = ''
        let postfix = ''
        if (typeof homework.email === 'string' && homework.email.length) {
          prefix = homework.email.split('@').shift()
        }
        if (typeof homework.name === 'string' && homework.name.length) {
          postfix = this.$utils.clearUnicode(homework.name)
        }
        const submitTime = new Date(homework.submitTime).getTime()
        if (prefix.length || postfix.length) {
          return prefix + '-' + postfix + '-' + submitTime
        } else return 'no-name-' + submitTime
      } else {
        return homework.id || homework._id
      }
    },
    generateZipFileName(index = 1) {
      const prefix = this.$utils.clearUnicode(this.unit.title)
      return `${prefix}_${index}`
    },
    async getContentFileAsBase64(url) {
      try {
        const res = await fetch(`${url}?t=${Date.now()}`)
        if (!res.ok) return ''
        return Buffer.from(await res.arrayBuffer()).toString('base64')
      } catch {
        return ''
      }
    },
    checkIfArrContainsElem(arr = []) {
      return Array.isArray(arr) && arr.length
    },
    getResources(homework = {}) {
      let result = []
      if (this.checkIfArrContainsElem(homework.correctionResources)) {
        result = homework.correctionResources
      } else if (this.checkIfArrContainsElem(homework.resources)) {
        result = homework.resources
      }
      return result
    },
    async handleMultipleFiles({studentFolder = {}, resources = []}) {
      const logs = []
      for (let k = 0; k < resources.length; k++) {
        const res = resources[k]
        const fileLink = this.$utils.convertURL(this.$utils.combineLinkWithBucket(res.link))
        const b64str = await this.getContentFileAsBase64(fileLink)
        if (b64str.length) {
          const ext = mimeTypes.extension(get(res, 'origin.mimeType', ''))
          studentFolder.file(`${Date.now()}_${res.id}.${ext}`, b64str, {
            base64: true
          })
        } else {
          studentFolder.file(`err_${res._id}.txt`, `Error getting ${fileLink}`)
        }
        logs.push(`- ${fileLink}`)
      }
      return logs
    }
  }
}
</script>
