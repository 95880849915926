<script>
import {Radar} from 'vue-chartjs'
export default {
  extends: Radar,
  props: {
    partitionReviewStats: Array,
  },
  data() {
    return {
      labels: [],
      data: [],
    }
  },
  methods: {
    async setDataForChart(data = []) {
      this.labels = []
      this.data = []
      data.forEach((item) => {
        let title = item.title
        if (title.length > 26) title = title.slice(0, 26) + '...'
        this.labels.push(title)
        this.data.push(item.percentage)
      })
      await this.refresh()
    },
    async refresh() {
      if (this.isRender === true) return
      if (this.$refs.canvas) {
        this.renderChart(
          {
            labels: this.labels,
            datasets: [
              {
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                data: this.data,
                pointStyle: 'line',
              },
            ],
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            scale: {
              ticks: {
                max: 100,
                min: 0,
                stepSize: 25,
              },
            },
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          }
        )

        await this.$utils.delay(1000)

        this.$emit('onRendered')
      }
    },
  },
  watch: {
    partitionReviewStats: {
      async handler(val) {
        if (Array.isArray(val) && val.length) await this.setDataForChart(val)
      },
      immediate: true,
    },
  },
}
</script>
