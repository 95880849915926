import utils from '@/helpers/utils.js'
import {message} from '@/plugins/message'

const state = {
  loadingProgress: false,
  progress: {},
  exam: null,
  countQuestion: 0,
}
const actions = {
  async updateQuestions({commit, dispatch}, exam) {
    commit('setExam', exam)
    const result = await dispatch('executeQuestions')
    await dispatch('question/fetchQuestions', {exam: exam._id}, {root: true})
    await commit('reset')
    return result
  },
  async executeQuestions({dispatch, rootState, commit}) {
    const {data, isPartition} = rootState.draftUnit
    const questions = data.questions
    try {
      commit('setLoading', true)
      const result = await (isPartition ? dispatch('saveQuesAndPart') : dispatch('saveQuestion', {questions}))
      await dispatch('checkDeleteQuestions', {questionsAfterSave: result})
      commit('setProgress', {})
      return result
    } catch (error) {
      //   this.$emit('onChangeLoadingScreen', false)
      message.error(error)
    } finally {
      commit('setLoading', false)
    }
  },
  async saveQuestion({dispatch}, {questions}) {
    try {
      // const newChildrens = questions.filter((q) => !q.type.includes('child'))
      const childQuestions = questions.filter((q) => q.type.includes('child'))
      let newChildrens = []
      questions.forEach((q) => {
        if (!q.type.includes('child')) {
          if (q.type.includes('group') && !q.children) {
            const children = q.questions.map((id) => childQuestions.find((item) => item.id === id))
            newChildrens.push({...q, children})
          } else newChildrens.push(q)
        }
      })
      return await dispatch('runProgressQuestions', {questions: newChildrens})
    } catch (error) {
      message.error(error)
    }
  },
  async saveChildQuestions({dispatch, commit, rootState}, question) {
    const countQuestion = rootState.draftUnit.data.count
    let listChild = []
    for await (const childQues of question.children.entries()) {
      const child = await dispatch('saveOneQuestion', childQues[1])
      const progress = rootState.draftUnitTest.progress.number ?? 0
      commit('setProgress', {
        entity: '',
        number: progress + 1,
        total: countQuestion,
        type: 'update',
      })
      listChild.push(child)
      await utils.delay(200)
    }
    return listChild
  },
  async runProgressQuestions({dispatch, commit, rootState}, {questions}) {
    const countQuestion = rootState.draftUnit.data.count
    let result = []
    for await (const question of questions.entries()) {
      try {
        let data = question[1]
        if (data.type.includes('group')) {
          const listChild = await dispatch('saveChildQuestions', data)
          data = {
            ...data,
            questions: listChild,
          }
        } else {
          const progress = rootState.draftUnitTest.progress.number ?? 0
          commit('setProgress', {
            entity: '',
            number: progress + 1,
            total: countQuestion,
            type: 'update',
          })
        }
        const res = await dispatch('saveOneQuestion', data)

        result.push(res)
        await utils.delay(200)
      } catch (e) {
        throw new Error(e)
      }
    }
    return result
  },
  async saveOneQuestion({dispatch, rootState}, question) {
    const {exam} = rootState.draftUnitTest
    const {selectedUnit} = rootState.unit
    const body = await utils.getDataQuestion(exam, question, selectedUnit)
    return await dispatch(
      'question/createOrUpdateQuestion',
      {
        questionId: question.id && question.id.indexOf('-') > -1 ? '' : question.id,
        data: body,
      },
      {root: true}
    )
  },
  async saveQuesAndPart({dispatch, rootState}) {
    const {data} = rootState.draftUnit
    const partition = data.partition
    const {exam} = rootState.draftUnitTest
    let questionsInExam = []
    let parts = Object.keys(partition).map(async (key) => {
      const res = await dispatch('saveQuestion', {questions: partition[key].questions})
      questionsInExam = questionsInExam.concat(res)
      return {
        ...partition[key],
        questions: res.map((v) => v.id),
      }
    })
    // promise.all is correct ?
    let partitionArray = await Promise.all(parts)
    let partitionForUpdate = partitionArray.reduce((acc, cur) => ({...acc, [cur.index]: cur}), {})
    const params = {
      id: exam.id,
      exam: {partition: partitionForUpdate},
    }
    await dispatch('exam/updateExam', params, {root: true})
    return questionsInExam
  },
  async checkDeleteQuestions({rootState, commit, dispatch}, {questionsAfterSave}) {
    if (!questionsAfterSave) return
    const questionsDefault = rootState.question.questions
    const questions = await utils.getQuestionWillDelete(questionsDefault, questionsAfterSave)
    if (!questions.length) return
    for await (const [index, id] of questions.entries()) {
      try {
        commit('setProgress', {
          entity: '',
          number: index,
          total: rootState.draftUnitTest.countQuestion,
          type: 'delete',
        })
        await dispatch('question/removeQuestion', id, {root: true})
      } catch (error) {
        message.error(error)
      }
      await utils.delay(200)
    }
  },
}
const mutations = {
  setLoading(state, val) {
    return (state.loadingProgress = val)
  },
  setLoadingProgress(state, val) {
    return (state.progress = val)
  },
  setExam(state, val) {
    return (state.exam = val)
  },
  setCountQuestion(state, val) {
    return (state.countQuestion = val)
  },
  setProgress(state, val) {
    return (state.progress = val)
  },
  reset() {
    state.exam = null
    state.progress = {}
    state.countQuestion = 0
  },
}
const getters = {
  getLoadingProgress: (state) => {
    return state.loadingProgress
  },
  getProgress: (state) => {
    return state.progress
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
