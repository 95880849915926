import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const TeacherRepository = RepositoryFactory.get('teacher')
import Base64 from '@/helpers/encodeBase64.js'
import dayjs from 'dayjs'

const state = {
  teachers: {},
  teacher: {},
  count: 0,
}

const actions = {
  async createTeacher({ dispatch }, data) {
     if(data.password) {
      data = {
        ...data,
        password: Base64.encode(data.password)
      }
    }
    const teacher = await TeacherRepository.create(data)
    dispatch('fetchTeachers')
    return teacher.data
  },
  async changePassword(_, data) {
    if(data.password) {
      data = {
        ...data,
        password: Base64.encode(data.password)
      }
    }
    if(data.newPassword) {
      data = {
        ...data,
        newPassword: Base64.encode(data.newPassword)
      }
    }
    const res = await TeacherRepository.changePassword(data)
    return res.data
  },
  async fetchTeachers({ commit }, params = {}) {
    const res = await TeacherRepository.fetch({
      ...params
    })
    res.data.sort((a, b) => {
      return (
        dayjs(b.updatedAt).format('YYYYMMDDHHmmss') -
        dayjs(a.updatedAt).format('YYYYMMDDHHmmss')
      )
    })
    commit('setTeachers', res.data || [])
    return res.data
  },
  async fetchTeacher({ commit }, id) {
    const res = await TeacherRepository.fetchOne(id)
    commit('setTeacherData', res.data || [])
    return res.data
  },
  async countTeachers({ commit }, params = {}) {
    const res = await TeacherRepository.count({
      ...params,
    })
    commit('setCount', res.data || 0)
  },
  async updateTeacher({ commit }, { id, ...teacher }) {
    const res = await TeacherRepository.update(id, teacher)
    return commit('setTeacherData', res.data)
  },
  async removeTeacher({ commit }, item) {
    await TeacherRepository.remove(item.id)
    return commit('removeTeacher', item.id)
  },
  async setTeacher({ commit }, teacher) {
    return commit('setTeacherData', teacher)
  },
  async addTeacher({ commit }, teacher) {
    return commit('setTeacher', teacher)
  },
  async fetchTeachersIndependent(_, params = {}) {
    const res = await TeacherRepository.fetch(params)
    return res.data || []
  }
}

const mutations = {
  setTeacher(state, teacher) {
    return (state.teachers = {
      ...state.teachers,
      [teacher.id]: teacher
    })
  },
  setCount(state, count) {
    return (state.count = count)
  },
  addTeacher(state, teacher) {
    state.count = state.count + 1
    return (state.teachers = {
      [teacher.id]: teacher,
      ...state.teachers
    })
  },
  setTeacherData(state, teacher) {
    return (state.teacher = teacher)
  },
  appendTeachers(state, teachers) {
    return (state.teachers = {
      ...state.teachers,
      ...teachers.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
    })
  },
  setTeachers(state, teachers) {
    return (state.teachers = teachers.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    ))
  },

  removeTeacher(state, id) {
    delete state.teachers[id]
    state.teachers = { ...state.teachers }
  }
}

const getters = {
  getTeachers: state => {
    return Object.values(state.teachers)
  },
  count: state => {
    return state.count
  },
  getTeacher: state => {
    return state.teacher
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
