import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const TopicRepository = RepositoryFactory.get('topic')

const state = {
  topic: {},
  count: 0,
  topics: {},
};

const actions = {
  async createTopic({ commit }, data) {
    const topic = await TopicRepository.create(data);
    commit("addTopic", topic.data);
    return topic.data;
  },
  async fetchTopics({ commit }, params = {}) {
    const res = await TopicRepository.fetch({
      ...params,
    });
    commit("setTopics", res.data || []);
    return res.data;
  },
  async countTopics({ commit }, params = {}) {
    const res = await TopicRepository.count({
      ...params,
      type: "topic",
    });
    commit("setCount", res.data.Count || 0);
  },
  async updateTopic({ commit }, { id, ...topic }) {
    const res = await TopicRepository.update(id, topic);
    commit("setTopicData", res.data || {});
    commit("setTopic", res.data || {})
    return res.data || {}
  },
  async removeTopic({ commit }, item) {
    await TopicRepository.remove(item.id);
    return commit("removeTopic", item.id);
  },
  async setTopic({ commit }, topic) {
    return commit("setTopicData", topic);
  },
  async addTopic({ commit }, topic) {
    return commit("setTopic", topic);
  },
  async fetchTopic({commit}, id) {
    const res = await TopicRepository.fetchOne(id)
    commit("setTopicData", res.data || {})
  },
  async fetchTopicsIndependent(_, params) {
    const res = await TopicRepository.fetch(params)
    return res.data || []
  }
};

const mutations = {
  setTopic(state, topic) {
    return (state.topics = {
      ...state.topics,
      [topic.id]: topic,
    });
  },
  setCount(state, count) {
    return (state.count = count);
  },
  addTopic(state, topic) {
    state.count = state.count + 1;
    return (state.topics = {
      [topic.id]: topic,
      ...state.topics,
    });
  },
  setTopicData(state, topic) {
    return (state.topic = topic);
  },
  appendTopics(state, topics) {
    return (state.topics = {
      ...state.topics,
      ...topics.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
    });
  },
  setTopics(state, topics) {
    return (state.topics = topics.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    ));
  },
  removeTopic(state, id) {
    delete state.topics[id];
    state.topics = { ...state.topics };
  },
};

const getters = {
  topics: (state) => {
    return Object.values(state.topics);
  },
  topic: (state) => {
    return state.topic;
  },
  count: (state) => {
    return state.count;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
