import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_PAYMENT
import Repository from '@/api/core'
const checkCombo = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + '/combo/my-combo' + `?${query}`).fetch()
}

export default {
	checkCombo
}
