const PASSWORD_CHANGE_FORM = {
	TITLE: 'Đổi mật khẩu mới',
	LABEL_OLD_PASSWORD: 'Mật khẩu cũ',
	PLACEHOLDER_OLD_PASSWORD: 'Nhập mật khẩu cũ',
	LABEL_NEW_PASSWORD: 'Mật khẩu mới',
	PLACEHOLDER_NEW_PASSWORD: 'Nhập mật khẩu mới',
	LABEL_NEW_PASSWORD_RETYPE: 'Xác nhận mật khẩu',
	PLACEHOLDER_NEW_PASSWORD_RETYPE: 'Nhập lại mật khẩu mới',
	RULE_NEW_PASSWORD_RETYPE: 'Mật khẩu mới và Xác nhận mật khẩu chưa trùng khớp',
	HINT_PASSWORD:"Mật khẩu chứa ít nhất 6 kí tự",
	BUTTON_UPDATE: 'Đổi mật khẩu',
	//MESSAGE
	MSG_UPDATE_LOADING: 'Đang tiếp nhận...',
	MSG_UPDATE_SUCCESS: 'Đổi mật khẩu thành công!',
	MSG_INFO_INCORRECT: 'Kiểm tra lại thông tin',
	MSG_WRONG_OLD_PASSWORD: 'Mật khẩu cũ không đúng',
	MSG_UPDATE_FAIL: 'Đổi mật khẩu không thành công! Vui lòng tải lại trang và thử lại sau ít phút!',
}
const CONTACT_INFO_UPDATE_FORM = {
	TITLE: 'Liên hệ',
	TITLE_FORM: 'Thiết lập thông tin liên hệ',
	LABEL_DISPLAY_NAME: "Họ và tên",
	PLACEHOLDER_DISPLAY_NAME: 'VD: Nguyễn Văn A',
	LABEL_PHONE_NUMBER: 'Số điện thoại (Hotline)',
	PLACEHOLDER_PHONE_NUMBER: 'VD: 0123321000',
	LABEL_EMAIL: 'Email',
	PLACEHOLDER_EMAIL: 'VD: hanoiltv@gmail.com',
	LABEL_ADDRESS: 'Địa chỉ',
	PLACEHOLDER_ADDRESS: 'VD: 123 Hà Nội',
	HINT_ADDRESS:
		'Lưu ý: Khi sử dụng nhiều địa chỉ, các địa chỉ phải phân cách nhau bởi dấu "{0}"',
	BUTTON_UPDATE: 'Cập nhật',
	//MESSAGE
	MSG_INFO_INCORRECT: 'Kiểm tra lại thông tin',
	MSG_UPDATE_SUCCESS: 'Cập nhật thành công!'
}
export default {
	PASSWORD_CHANGE_FORM,
	CONTACT_INFO_UPDATE_FORM,
	BUTTON_UPDATE:"Cập nhật"
}
