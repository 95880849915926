import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const UserPermission = RepositoryFactory.get('userPermission')

const state = {
  onlineCourses: [],
  books: [],
  count: 0,
}

const actions = {
  async fetchUserPermissions({commit}, userId) {
    if (userId) {
      const res = await UserPermission.fetch({userId})
      commit('setProducts', {products: res.data.onlineCourses, type: 'course'})
      commit('setProducts', {products: res.data.books, type: 'book'})
      return res.data
    }
  },
  async count({commit}, params) {
    const res = await UserPermission.count(params)
    commit('updateCount', res.data[0].count)
  },
  async checkValidateUserPermission(_, {userId, courseId}) {
    const res = await UserPermission.check({
      userId,
      payload: {
        relationId: courseId,
      },
    })
    return res.data
  },
  async addProducts({dispatch, rootState}, params = {}) {
    const res = await UserPermission.addProducts(params)
    const userId = rootState.student.profile.userId
    dispatch('fetchUserPermissions', userId)
    return res.data
  },
  async removeProducts({dispatch, rootState}, params = {}) {
    const res = await UserPermission.removeProducts(params)
    const userId = rootState.student.profile.userId
    dispatch('fetchUserPermissions', userId)
    return res.data
  },
  async setLimitedTime({dispatch}, {id, payload}) {
    const res = await UserPermission.setLimitedTime(id, payload)
    dispatch('fetchUserPermissions', res.data.userId)
    return res.data
  },
  async upgradeAccountForPreview({rootState}, courseId) {
    const userId = rootState.auth.auth.id
    const vendorId = rootState.auth.auth.vendorId
    const result = await UserPermission.upgradeAccountForPreview({vendorId, userId, courseId})
    console.log(result)
    return result.data
  },
}

const mutations = {
  setProducts(state, {products, type}) {
    let listProducts = []
    products.map((product) => {
      product.permissions.map((permission) => {
        listProducts.push({
          ...permission,
          userPermissionId: permission.userPermissionId,
          id: product.id,
          title: product.title,
          authors: product.authors,
        })
      })
    })
    if (type === 'course') state.onlineCourses = listProducts
    if (type === 'book') state.books = listProducts
  },
  updateCount(state, count) {
    state.count = count
  },
}

const getters = {
  getOnlineCourses: (state) => {
    return state.onlineCourses
  },
  getBooks: (state) => {
    return state.books
  },
  getCount: (state) => {
    return state.count
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
