<template>
  <div class="navbar-spacing">
    <div
      @click="onSelectEditPart(navigation)"
      :class="
        selectedNavigation === navigation.id
          ? 'color--white box-icon box-icon__active cursor-pointer'
          : 'color--white box-icon cursor-pointer'
      "
      v-for="navigation in listNavigations"
      :key="navigation.title"
    >
      <v-icon color="white">{{ navigation.icon }}</v-icon>
      <div class="mt-3">{{ navigation.title }}</div>
    </div>
    <div
      @click="() => onAddPage()"
      class="d-flex justify-center flex-column align-center cursor-pointer addition-button"
    >
      <v-icon color="white">mdi-plus</v-icon>
      <div class="mt-1 white--text">{{ 'Thêm trang' }}</div>
    </div>
    <PopupAddNewPage ref="addition-page" @onAddNewPage="(data) => onAddNewPage(data)" />
  </div>
</template>
<script>
import PopupAddNewPage from './PopupAddNewPage'
import uuid from 'uuid'
import get from 'lodash-es/get'
import {mapActions, mapGetters} from 'vuex'
export default {
  components: {
    PopupAddNewPage,
  },
  props: {
    selected: String,
    onSave: Function,
    checkChangedData: Function,
  },
  computed: {
    ...mapGetters({
      pageSettingStatus: 'customPage/getPageSettingStatus',
      themeConfig: 'vendor/getThemeConfig',
      idPageonDelete: 'customPage/getIdPageonDelete',
      deletedStatus: 'customPage/getDeletedStatus'
    }),
  },
  data() {
    return {
      selectedNavigation: 'common',
      listNavigations: [
        {
          id: 'common',
          icon: 'mdi-palette-outline',
          title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.NS_COMMON'),
        },
        {
          id: 'homepage',
          icon: 'mdi-monitor-cellphone-star',
          title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.NS_HOMEPAGE'),
        },
        {
          id: 'about-us',
          icon: 'mdi-monitor-cellphone-star',
          title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.NS_ABOUT_US'),
        },
        {
          id: 'privacy-policy',
          icon: 'mdi-monitor-cellphone-star',
          title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.NS_PRIVACY_POLICY'),
        },
        {
          id: 'recruitment',
          icon: 'mdi-monitor-cellphone-star',
          title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.NS_RECRUITMENT'),
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      setPage: 'customPage/setPage',
      setCreatePageSavingStatus: 'customPage/setCreatePageStatus',
      setPageOnEdit: 'customPage/setPageOnEdit',
      onRefreshPageData: 'customPage/onRefresh',
      setIsDeletedPage: 'customPage/setIsDeletedPage'
    }),

    async onSelectEditPart(navigation) {
      this.setPageOnEdit(navigation)
      const checkChangedData = await this.checkChangedData()
      if (checkChangedData && !this.deletedStatus) {
        this.$confirm.show({
          title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.CONFIRM_CHANGE_NAVIGATION_TITLE'),
          text: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.CONFIRM_CHANGE_NAVIGATION_DESCRIPTION'),
          confirmColor: 'primary',
          okText: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_SAVE_AND_CONTINUE'),
          cancelText: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_CANCEL_TEXT'),
          done: async () => {
            await this.onSave()
            this.$emit('onSelectNavigation', navigation.id)
          },
        })
      } else this.$emit('onSelectNavigation', navigation.id)
      this.setIsDeletedPage(false)
    },
    onAddPage() {
      if (this.pageSettingStatus) {
        this.$refs['addition-page'].openDialog()
      } else return this.$message.error('Hãy lưu trang trước khi tạo trang mới')
    },
    onAddNewPage(data) {
      if (data) {
        const id = this.$utils.clearUnicode(get(data, 'title', 'no-title'))
        const additionData = {
          id: `${id}-${uuid.v4()}`,
          title: get(data, 'title', 'Chưa có tiêu đề'),
          url: get(data, 'url', ''),
          isAddition: true,
        }
        this.setPage(additionData)
        this.setCreatePageSavingStatus(false)
        this.listNavigations.push({...additionData, icon: 'mdi-monitor-cellphone-star'})
      }
    },
    handlerThemeConfig(theme) {
      const customPage = Object.values(theme)
        .filter((v) => v.isAddition)
        .map((v) => ({...v, icon: 'mdi-monitor-cellphone-star'}))
      const result = [...this.listNavigations, ...customPage].filter((item, index, array) => {
        return index === array.findIndex((t) => t.id === item.id)
      })
      this.listNavigations = result
    },
  },
  watch: {
    selected: {
      handler(value) {
        this.selectedNavigation = value
      },
      immediate: true,
    },
    themeConfig: {
      handler(val) {
        if (val) {
          this.handlerThemeConfig(val)
        }
      },
      immediate: true,
      deep: true,
    },
    idPageonDelete: {
      async handler(val) {
        if (typeof val === 'string' && val.length) {
          const index = this.listNavigations.findIndex((v) => v.id === val)
          if (index > -1) this.listNavigations.splice(index, 1)
          this.onRefreshPageData()
          await this.onSelectEditPart(this.listNavigations[0])
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.navbar-spacing {
  margin-top: 72px;
}
.box-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100px;
  &__active {
    background: grey;
  }
}
.box-icon:hover {
  background: grey;
}

.addition-button {
  min-height: 80px;
  &:hover {
    background: rgba($color: #ffffff, $alpha: 0.4);
  }
}
</style>
