import auth from '@/store/modules/auth'
import Repository from '@/api/core'
const BASE_URL = process.env.VUE_APP_API_LEARNING_PATH + '/user-path'

const fetch = (params) => {
  return Repository(BASE_URL).fetch(params)
}

const fetchOneUserPath = (params) => {
  return Repository(BASE_URL + `/${auth.state.auth.id}`).fetch(params)
}

export default {
  fetch,
  fetchOneUserPath,
}
