import helpers from '@/helpers'

const state = {
  action: helpers.HOMEWORK_IMAGE_EDITOR_ACTIONS.MOVE,
  scaleFactor: 1,
  hitboxOpacity: 0,
  minScaleFactor: 1, // pivot for scaling
  baseFontSize: 40,
}
const actions = {
  chooseAction({ commit }, action) {
    commit('setAction', action)
  },
  refresh({ commit }) {
    commit('refreshState')
  },
  changeScaleFactor({ commit }, scaleFactor) {
    commit('setScaleFactor', scaleFactor)
  },
  setHitboxOpacity({ commit }, hitboxOpacity) {
    commit('setHitboxOpacity', hitboxOpacity)
  },
  setMinScaleFactor({commit}, minScaleFactor) {
    commit('setMinScaleFactor', minScaleFactor)
  },
  setBaseFontSize({commit}, baseFontSize) {
    commit('setBaseFontSize', baseFontSize)
  }
}
const mutations = {
  setAction(state, action) {
    state.action = action
  },
  refreshState(state) {
    state.action = helpers.HOMEWORK_IMAGE_EDITOR_ACTIONS.MOVE
    state.scaleFactor = 1
    state.hitboxOpacity = 0
    state.minScaleFactor = 1
  },
  setScaleFactor(state, scaleFactor) {
    state.scaleFactor = scaleFactor
  },
  setHitboxOpacity(state, hitboxOpacity) {
    state.hitboxOpacity = hitboxOpacity
  },
  setMinScaleFactor(state, minScaleFactor) {
    state.minScaleFactor = minScaleFactor
  },
  setBaseFontSize(state, baseFontSize) {
    state.baseFontSize = baseFontSize
  }
}
const getters = {
  isMovingActivated: state => state.action === helpers.HOMEWORK_IMAGE_EDITOR_ACTIONS.MOVE,
  isDrawingActivated: state => state.action === helpers.HOMEWORK_IMAGE_EDITOR_ACTIONS.DRAW,
  isErasingActivated: state => state.action === helpers.HOMEWORK_IMAGE_EDITOR_ACTIONS.ERASE,
  isTextingActivated: state => state.action === helpers.HOMEWORK_IMAGE_EDITOR_ACTIONS.TEXT,
  isRotatingActivated: state => state.action === helpers.HOMEWORK_IMAGE_EDITOR_ACTIONS.ROTATE,
  action: state => state.action,
  scaleFactor: state => state.scaleFactor,
  hitboxOpacity: state => state.hitboxOpacity,
  minScaleFactor: state => state.minScaleFactor,
  baseFontSize: state => state.baseFontSize
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}