import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const TeacherRepository = RepositoryFactory.get('teacher')
import dayjs from 'dayjs'

const state = {
	authors: {},
	author: {},
	count: 0
}

const actions = {
	async createAuthor({dispatch}, data) {
		const author = await TeacherRepository.create(data)
		dispatch('fetchAuthors')
		return author.data
	},
	async changePassword(_, data) {
		const res = await TeacherRepository.changePassword(data)
		return res.data
	},
	async fetchAuthors({commit}, params = {}) {
		const res = await TeacherRepository.fetch({
			...params
		})
		res.data.sort((a, b) => {
			return (
				dayjs(b.updatedAt).format('YYYYMMDDHHmmss') -
				dayjs(a.updatedAt).format('YYYYMMDDHHmmss')
			)
		})
		commit('setAuthors', res.data || [])
		return res.data
	},
	async fetchAuthor({commit}, id) {
		const res = await TeacherRepository.fetchOne(id)
		commit('setAuthorData', res.data || [])
		return res.data
	},
	async countAuthors({commit}, params = {}) {
		const res = await TeacherRepository.count({
			...params,
			type: 'author'
		})
		commit('setCount', res.data.Count || 0)
	},
	async updateAuthor({commit}, {id, userId, ...author}) {
		const res = await TeacherRepository.update(id, author)
		delete author.fullname
		if (userId) {
			await TeacherRepository.update(userId, author)
		}
		return commit('setAuthorData', res.data)
	},
	async removeAuthor({commit}, item) {
		await TeacherRepository.remove(item.id)
		return commit('removeAuthor', item.id)
	},
	async setAuthor({commit}, author) {
		return commit('setAuthorData', author)
	},
	async addAuthor({commit}, author) {
		return commit('setAuthor', author)
	},
	async fetchAuthorsIndependent(_, params) {
		const res = await TeacherRepository.fetch({
			...params
		})
		return res.data
	}
}

const mutations = {
	setAuthor(state, author) {
		return (state.authors = {
			...state.authors,
			[author.id]: author
		})
	},
	setCount(state, count) {
		return (state.count = count)
	},
	addAuthor(state, author) {
		state.count = state.count + 1
		return (state.authors = {
			[author.id]: author,
			...state.authors
		})
	},
	setAuthorData(state, author) {
		return (state.author = author)
	},
	appendAuthors(state, authors) {
		return (state.authors = {
			...state.authors,
			...authors.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {})
		})
	},
	setAuthors(state, authors) {
		return (state.authors = authors.reduce(
			(acc, cur) => ({...acc, [cur.id]: cur}),
			{}
		))
	},

	removeAuthor(state, id) {
		delete state.authors[id]
		state.authors = {...state.authors}
	}
}

const getters = {
	authors: state => {
		return Object.values(state.authors)
	},
	count: state => {
		return state.count
	},
	author: state => {
		return state.author
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
