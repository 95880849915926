import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_LEARN + '/section'

const count = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId,
		_select: 'COUNT'
	})
	return Repository(baseURL + `?${query}`).fetch()
}

const fetch = params => {
	return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const create = params => {
	return Repository(baseURL).create(params)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const remove = id => {
	return Repository(baseURL).delete(id)
}

export default {
	count,
	fetch,
	fetchOne,
	create,
	update,
	remove
}
