import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const UserBook = RepositoryFactory.get('userBook')

const state = {
	userBooks: []
}

const actions = {
	async fetchUserBooks({commit}, userId) {
		let result = await UserBook.fetch({
			userId: userId
		})
		commit('setUserBooks', result.data)
		return result.data
	}
}

const mutations = {
	setUserBooks(state, books) {
		return (state.userBooks = books)
	}
}

const getters = {
	getUserBooks: state => {
		return state.userBooks
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
