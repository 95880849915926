function lazyLoad(view) {
  return () => import(`@/pages/${view}.vue`)
}
export default [
  {
    path: "/online-course/:id",
    name: '$vuetify.ROUTE.HIDDEN_WITHOUT_MENU_ROUTES.LABEL_ONLINE_COURSE_DETAIL',
    component: lazyLoad('OnlineCourseDetail'),
  },
  {
    path: "/offline-course/:id",
    name: '$vuetify.ROUTE.HIDDEN_WITHOUT_MENU_ROUTES.LABEL_OFFLINE_COURSE_DETAIL',
    component: lazyLoad('OfflineCourseDetail'),
  },
  {
    path: "/test-component",
    name: '$vuetify.ROUTE.HIDDEN_WITHOUT_MENU_ROUTES.LABEL_TESTING',
    component: lazyLoad('Testing'),
  },
  {
    path:"/assigned-homework/:id",
    name: '$vuetify.ROUTE.HIDDEN_WITHOUT_MENU_ROUTES.LABEL_ASSIGNED_HOMEWORK_DETAIL',
    component: lazyLoad('AssignedHomeworkDetail')
  },
]