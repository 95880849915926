import { Vendor } from '@/api/repository/vendorRepository.js'

const state = {
  admins: [],
}

const actions = {
  async fetchAdmins({ commit }, params) {
    const res = await Vendor.getAdmins(params)
    commit('setAdmins', res.data.Items)
  },
  async createNewAdmin(_, params) {
    const res = await Vendor.createAdmin(params)
    return res.data || {}
  },
  async deleteAdmin(_, id) {
    const res = await Vendor.deleteAdmin(id)
    return res.data || {}
  }
}

const mutations = {
  setAdmins(state, admins) {
    return (state.admins = admins)
  },
}

const getters = {
  getAdmins: state => {
    return state.admins.sort((a, b) => b.createdAt - a.createdAt)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
