const generateHtmlForDirectFillQuestion = (parentId = '', html = '') => {
  const regex = /\{\{\s*(\d+)\s*\}\}/g
  let index = -1
  const keys = Array.from(html.matchAll(regex)).map((match) => match[1])
  const replacedHTML = html.replace(regex, (_, key) => {
    index += 1
    return `<span id="child_${parentId}_${key}_${index}"></span>`
  })

  return {
    html: replacedHTML,
    keys,
  }
}

const handleDirectFillQuesion = (questionGroup = {}) => {
  const questions = [],
    parentId = questionGroup.id
  let {html, keys} = generateHtmlForDirectFillQuestion(parentId, questionGroup.html)
  questions.push({...questionGroup, keys, html})
  return questions
}

const handleGroupQuestion = (questionGroup = {}) => {
  let questions = []
  questions.push({
    ...questionGroup,
    parentHtml: paragraphDivision(questionGroup.html || ''),
  })
  if (!(questionGroup.questions || []).length) return
  const child = questionGroup.questions.filter(Boolean).map((q, index) => {
    const childData = {
      ...q,
      answers: q.answers,
      description: paragraphDivision(q.html || ''),
      type: q.type,
    }
    if (index === questionGroup.questions.length - 1) childData.isLastQuestion = true
    return childData
  })
  questions = questions.concat(child)
  return questions
}

const handlerQuestions = (sourceList) => {
  let questions = []
  sourceList.forEach((question) => {
    if (question.type === 'group') {
      questions = questions.concat(handleGroupQuestion(question))
    } else if (question.type === 'direct-fill-blank-group') {
      questions = questions.concat(handleDirectFillQuesion(question))
    } else
      questions.push({
        ...question,
        description: paragraphDivision(question.html || ''),
      })
  })
  return questions
}

const qlWrapper = `<div data-v-b07b6bbe="" ql-container ql-snow" style="min-height: inherit; max-height: inherit; overflow-y: auto; position: relative; border: 0px;"><div class="ql-editor" data-gramm="false" style="padding: 0px;">{{element}}</div><div class="ql-clipboard" contenteditable="true" tabindex="-1"></div><div class="ql-tooltip ql-hidden"><a class="ql-preview" rel="noopener noreferrer" target="_blank" href="about:blank"></a><input type="text" data-formula="e=mc^2" data-link="https://quilljs.com" data-video="Embed URL"><a class="ql-action"></a><a class="ql-remove"></a></div><div class="ql-custom"><textarea style="width: 100%; margin: 0px; background: rgb(29, 29, 29); box-sizing: border-box; color: rgb(204, 204, 204); font-size: 15px; outline: none; padding: 20px; line-height: 24px; position: absolute; top: 0px; bottom: 0px; border: none; display: none;"></textarea></div></div>`
const paragraphDivision = (description = '', elementTempId = 'description') => {
  if (description.indexOf('ql-editor') !== -1) {
    let descriptionTemp = document.createElement('div')
    let child = []
    descriptionTemp.innerHTML = description
    const qlEditor = descriptionTemp.firstChild ? descriptionTemp.firstChild.firstChild : null
    if (!qlEditor) return []
    const elementTemp = document.getElementById(elementTempId)
    let newElement = document.createElement('div')
    elementTemp.appendChild(newElement)
    if (qlEditor) {
      qlEditor.childNodes.forEach((element, index) => {
        newElement.appendChild(element.cloneNode(true))
        const newElementHeight = newElement.getBoundingClientRect().height
        if (newElementHeight > 300 || index === (qlEditor.children || []).length - 1) {
          let elementWrapped = qlWrapper.replace('{{element}}', newElement.outerHTML)
          child.push(elementWrapped)
          newElement.innerHTML = ''
        }
      })
    }
    return child
  } else return [description]
}

const handleCorrect = (question = {}) => {
  if (['fill-blank', 'fill-blank-child', 'direct-fill-blank-child'].includes(question.type)) {
    if (question.correct.includes('//')) {
      return question.correct.split('//').join('<br>')
    }
    return question.correct
  }
  if (Array.isArray(question.correct) && question.correct.length) {
    const correctLabels = []
    question.correct.forEach((key) => correctLabels.push(question.answers[key].label))
    return correctLabels.join('\n')
  }
  return question.answers[question.correct].label
}

export {handlerQuestions, paragraphDivision, handleCorrect}
