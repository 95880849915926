<template>
  <div>
    <div v-if="pageOnEdit && pageOnEdit.isAddition">
      <div class="d-flex">
        <div class="list-sections-title">
          {{ 'Tên trang:' }}
        </div>
        <div class="pl-2 list-sections-title--content">
          {{ `${pageOnEdit.title}` }}
        </div>
      </div>
      <div class="d-flex">
        <div class="list-sections-title">{{ 'Url:' }}</div>
        <div class="pl-2 list-sections-title--content">
          {{ $utils.formatTitle(`/custom-page/${pageOnEdit.url}`, 35) }}
        </div>
        <v-btn icon color="primary" @click="copyText(`/custom-page/${pageOnEdit.url}`)">
          <v-icon class="mb-2" size="16">mdi-content-copy</v-icon>
        </v-btn>
      </div>
      <v-divider class="mb-4" />
    </div>
    <div class="slick-list">
      <SlickList
        lockAxis="y"
        @sort-end="(event) => $emit('sort-end', event)"
        v-model="sectionNames"
        appendTo=".slick-list"
        lockToContainerEdges
      >
        <SlickItem
          class="section-item"
          v-for="(item, i) in sectionNames"
          :key="i"
          :index="i"
          :disabled="isSlickDisabled"
        >
          <v-icon class="section-item__icon">mdi-drag-vertical</v-icon>
          <v-icon class="mr-2">mdi-image-area</v-icon>
          <div class="full-width subheading">
            {{ generateNameSection(item, isMobile ? isMobile : false) }}
          </div>
          <v-icon @click="editSection(item)" class="section-item__icon mr-2">mdi-cog-outline</v-icon>
          <v-icon @click="removeItem(item)" class="section-item__icon mr-2 action-delete-hover">mdi-delete</v-icon>
        </SlickItem>
      </SlickList>
    </div>
    <div class="mt-4 d-flex justify-center" :class="{'justify-space-between': pageOnEdit?.isAddition}">
      <BaseButton
        icon="mdi-plus"
        :text="$vuetify.lang.t('$vuetify.SITE_BUILDER.DETAIL.BTN_ADD_COMPONENT')"
        @click="addSection"
        outlined
      />
      <BaseButton
        v-if="pageOnEdit?.isAddition"
        icon="mdi-delete"
        text="Xoá trang"
        @click="() => deletePage()"
        outlined
        color="error"
      />
    </div>
  </div>
</template>
<script>
import helpers from '@/helpers'
import {SlickList, SlickItem} from 'vue-slicksort'
import {mapActions, mapGetters} from 'vuex'
import get from 'lodash-es/get'
import unset from 'lodash-es/unset'
import clone from 'lodash-es/clone'
export default {
  props: {
    viewData: Array,
    isSlickDisabled: Boolean,
    isMobile: Boolean,
  },
  components: {
    SlickItem,
    SlickList,
  },
  computed: {
    ...mapGetters({
      pageOnEdit: 'customPage/getPageOnEdit',
    }),
  },
  data() {
    return {
      sectionNames: [],
      // title: '',
    }
  },
  filters: {
    generateNameSection(val, isMobile) {
      if (!val) return ''
      // eslint-disable-next-line
      let [name, id] = val.split(':')
      let listSections = isMobile ? helpers.LIST_SECTION_SCREENBUILDER : helpers.LIST_SECTION_SITEBUILDER
      const element = listSections.find((item) => item.value === name)
      return element && element.title ? element.title : ''
    },
  },
  methods: {
    ...mapActions({
      removeError: 'sitebuilder/removeMessageError',
      deleteCustomPage: 'vendor/onDeleteCustomPage',
      fechVendorIndependent: 'vendor/fechVendorIndependent',
      updateSetting: 'vendor/onUpdatePage',
      getSettingsVendor: 'vendor/getSettingsVendor',
      setIdPageOnDelete: 'customPage/setIdPageOnDelete',
      setCreatePageSavingStatus: 'customPage/setCreatePageStatus',
      setIsDeletedPage: 'customPage/setIsDeletedPage',
    }),
    removeItem(item) {
      this.removeError()
      this.$confirm.show({
        title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.DETAIL.CONFIRM_REMOVE_COMPONENT_TITLE'),
        text: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.DETAIL.CONFIRM_REMOVE_COMPONENT_DESCRIPTION'),
        okText: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_CONFIRM'),
        cancelText: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_CANCEL'),
        done: () => {
          this.$emit('onRemoveItem', item)
        },
      })
    },
    editSection(item) {
      this.removeError()
      this.$emit('onEditSection', item)
    },
    addSection() {
      this.removeError()
      this.$emit('onAddSection')
    },
    generateNameSection(val, isMobile) {
      if (!val) return ''
      // eslint-disable-next-line
      let [name, id] = val.split(':')
      let listSections = isMobile ? helpers.LIST_SECTION_SCREENBUILDER : helpers.LIST_SECTION_SITEBUILDER
      const element = listSections.find((item) => item.value === name)
      return element && element.title ? this.$vuetify.lang.t(element.title) : ''
    },
    async deletePage() {
      const commonTypes = ['common', 'homepage', 'about-us', 'privacy-policy', 'recruitment']
      const currentType = get(this.pageOnEdit, 'id')
      if (currentType) {
        if (!commonTypes.includes(currentType)) {
          this.$message.loading('Đang xoá trang, vui lòng đợi', 30000)
          const res = await this.fechVendorIndependent()
          const pages = get(res, 'settings.learn.themeConfig')
          if (pages) {
            try {
              const copyPages = clone(pages, true)
              unset(copyPages, this.pageOnEdit.url)
              let otherPageSeo = get(pages, 'seo.otherPage', {})
              unset(otherPageSeo, this.pageOnEdit.url) // xoá seo của trang bị xoá
              copyPages.seo.otherPage = otherPageSeo
              await this.updateSetting(copyPages)
              this.setIsDeletedPage(true)
              this.setIdPageOnDelete(this.pageOnEdit.id)
              this.setCreatePageSavingStatus(true)
              this.$message.success('Xoá trang thành công')
            } catch (error) {
              this.$message.error('Có lỗi xảy ra, vui lòng thử lại')
            }
          }
        }
      }
    },
    copyText(text) {
      var textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        var successful = document.execCommand('copy')
        if (successful) {
          this.$message.success('Sao chép thành công')
        } else {
          this.$message.error('Sao chép không thành công. Vui lòng thử lại')
        }
      } catch (err) {
        this.$message.error('Sao chép không thành công. Vui lòng thử lại')
      }
      document.body.removeChild(textArea)
    },
  },
  watch: {
    viewData: {
      handler(val) {
        if (val && val instanceof Array) {
          this.sectionNames = val.map((item) => item.id)
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.slick-list {
  box-shadow: 0px 1px 2px rgba(9, 30, 66, 0.25);
  > .section-item {
    left: 20px !important;
    background-color: #f8f8f8;
  }
}
.section-item {
  user-select: none;
  cursor: default;
  padding: 14px 5px 14px 2px;
  background: #ffffff;
  border-top: none;
  border-bottom: 1px solid #dddbda;
  display: flex !important;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    box-shadow: -3px 0 0 #4bca81;
  }
  &:active {
    color: black;
  }
  .section-item__icon {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
  }
  &:hover {
    .section-item__icon {
      visibility: visible;
      opacity: 1;
    }
  }
}
.subheading {
  color: black !important;
}
.topic-card {
  box-shadow: 0px 1px 2px rgba(9, 30, 66, 0.25);
  cursor: pointer;
}
.list-sections-title {
  font-size: 16px;
  font-weight: 500;
}
.list-sections-title--content {
  color: #79797c;
}
</style>
