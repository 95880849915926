import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const UserCertificate = RepositoryFactory.get('userCertificate')
const namespaced = true

const state = {
  userCertificate: [],
}

const actions = {
  async fetchUserCertificate({ commit }, params) {
    const res = await UserCertificate.fetch({
			...params
		})
    commit('setUserCertificate', res.data || [])
		return res.data
  },
}

const mutations = {
  setUserCertificate(state, userCertificate) {
    state.userCertificate = userCertificate
  },
}

const getters = {
  getUserCertificate: state => {
    return state.userCertificate
  },
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}
