import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const productCategoryRepository = RepositoryFactory.get(
  'ProductCategoryRepository'
)

const state = {
  categories: [],
  categoriesLv0: [],
  categoriesLv1: [],
  categoriesLv2: [],
  categoriesLv3: [],
  category: null,
  selectedItems: [],
  choosenCategory: [],
}

const actions = {
  async fetchCategories({commit}, params) {
    const res = await productCategoryRepository.fetch(params)
    commit('setProductCategories', res.data || [])
    return res.data || []
  },

  async fetchCategories0({commit}, params) {
    const res = await productCategoryRepository.fetchCategoryByLevel0(params)
    commit('setProductCategoriesLv0', res.data || [])
    return res.data || []
  },

  async fetchChildrentCategories({commit}, {params, level, parentId}) {
    const res = await productCategoryRepository.fetchChildrentCategoryBylevel(
      params,
      level,
      parentId
    )
    switch (level) {
      case 1:
        commit('setProductCategoriesLv1', res.data || [])
        break
      case 2:
        commit('setProductCategoriesLv2', res.data || [])
        break
      case 3:
        commit('setProductCategoriesLv3', res.data || [])
        break
    }
    return res.data || []
  },
  async fetchCategory({commit}, id) {
    const res = await productCategoryRepository.fetchOneWithParams(id, {
      _populate: 'parents',
    })
    commit('setProductCategory', res.data || {})
    return res.data
  },
  async createNewCategory({commit}, params) {
    const res = await productCategoryRepository.create(params)
    switch (res.data.level) {
      case 0:
        commit('setNewCategoryLv0', res.data || [])
        break
      case 1:
        commit('setNewCategoryLv1', res.data || [])
        break
      case 2:
        commit('setNewCategoryLv2', res.data || [])
        break
      case 3:
        commit('setNewCategoryLv3', res.data || [])
        break
    }
  },
  async editCategory({commit}, {id, ...params}) {
    const res = await productCategoryRepository.update(id, params)
    switch (res.data.level) {
      case 0:
        commit('setNewCategoryLv0', res.data || [])
        break
      case 1:
        commit('setNewCategoryLv1', res.data || [])
        break
      case 2:
        commit('setNewCategoryLv2', res.data || [])
        break
      case 3:
        commit('setNewCategoryLv3', res.data || [])
        break
    }
  },
  async removeCategory({commit}, category) {
    let id = category.id
    let level = category.level
    const res = await productCategoryRepository.remove(id)
    switch (level) {
      case 0:
        commit('removeCategoryLv0', id)
        break
      case 1:
        commit('removeCategoryLv1', id)
        break
      case 2:
        commit('removeCategoryLv2', id)
        break
      case 3:
        commit('removeCategoryLv3', id)
        break
    }
    return res.data
  },
}
const mutations = {
  setProductCategories(state, list) {
    state.categories = list
  },
  setProductCategoriesLv0(state, list) {
    state.categoriesLv0 = list
  },
  setProductCategoriesLv1(state, list) {
    state.categoriesLv1 = list
  },
  setProductCategoriesLv2(state, list) {
    state.categoriesLv2 = list
  },
  setProductCategoriesLv3(state, list) {
    state.categoriesLv3 = list
  },
  setProductCategory(state, category) {
    state.category = category
  },
  setSelectedItems(state, selectedItem) {
    const foundIndex = state.selectedItems.findIndex(
      (item) => item.level === selectedItem.level
    )
    if (foundIndex < 0) {
      state.selectedItems.push(selectedItem)
    } else {
      state.selectedItems.splice(foundIndex, 1, selectedItem)
    }
  },
  setChoosenCategory(state, category) {
    const foundIndex = state.choosenCategory.findIndex(
      (item) => item.level === category.level
    )
    if (foundIndex < 0) {
      state.choosenCategory.push(category)
    } else {
      state.choosenCategory.splice(foundIndex, 1, category)
    }
  },
  deleteChosenCategory(state, category) {
    const result = state.choosenCategory.filter(
      (item) => item.level <= category.level
    )
    state.choosenCategory = result
  },
  clearSelectedItems(state) {
    state.selectedItems = []
  },
  clearChoosenCategory(state) {
    state.choosenCategory = []
  },
  clearAllProductCategory(state) {
    state.category = {}
    state.categoriesLv1 = []
    state.categoriesLv2 = []
    state.categoriesLv3 = []
  },
  clearProductCategoryByLevels(state, levels = []) {
    levels.forEach((item) => {
      switch (item) {
        case 1:
          state.categoriesLv1 = []
          break
        case 2:
          state.categoriesLv2 = []
          break
        case 3:
          state.categoriesLv3 = []
          break
      }
    })
  },
  setNewCategoryLv0(state, category) {
    const foundIndex = state.categoriesLv0.findIndex(
      (item) => item.id === category.id
    )
    if (foundIndex < 0) {
      state.categoriesLv0.push(category)
    } else {
      state.categoriesLv0.splice(foundIndex, 1, category)
    }
  },
  setNewCategoryLv1(state, category) {
    const foundIndex = state.categoriesLv1.findIndex(
      (item) => item.id === category.id
    )
    if (foundIndex < 0) {
      state.categoriesLv1.push(category)
    } else {
      state.categoriesLv1.splice(foundIndex, 1, category)
    }
  },
  setNewCategoryLv2(state, category) {
    const foundIndex = state.categoriesLv2.findIndex(
      (item) => item.id === category.id
    )
    if (foundIndex < 0) {
      state.categoriesLv2.push(category)
    } else {
      state.categoriesLv2.splice(foundIndex, 1, category)
    }
  },
  setNewCategoryLv3(state, category) {
    const foundIndex = state.categoriesLv3.findIndex(
      (item) => item.id === category.id
    )
    if (foundIndex < 0) {
      state.categoriesLv3.push(category)
    } else {
      state.categoriesLv3.splice(foundIndex, 1, category)
    }
  },
  removeCategoryLv0(state, id) {
    let deleteIndex = state.categoriesLv0.findIndex(
      (listItem) => listItem.id === id
    )
    if (deleteIndex > -1) {
      state.categoriesLv0 = state.categoriesLv0.filter(
        (listItem) => listItem.id !== id
      )
    }
  },
  removeCategoryLv1(state, id) {
    let deleteIndex = state.categoriesLv1.findIndex(
      (listItem) => listItem.id === id
    )
    if (deleteIndex > -1) {
      state.categoriesLv1 = state.categoriesLv1.filter(
        (listItem) => listItem.id !== id
      )
    }
  },
  removeCategoryLv2(state, id) {
    let deleteIndex = state.categoriesLv2.findIndex(
      (listItem) => listItem.id === id
    )
    if (deleteIndex > -1) {
      state.categoriesLv2 = state.categoriesLv2.filter(
        (listItem) => listItem.id !== id
      )
    }
  },
  removeCategoryLv3(state, id) {
    let deleteIndex = state.categoriesLv3.findIndex(
      (listItem) => listItem.id === id
    )
    if (deleteIndex > -1) {
      state.categoriesLv3 = state.categoriesLv3.filter(
        (listItem) => listItem.id !== id
      )
    }
  },
}
const getters = {
  getProductCategories: (state) => state.categories,
  getProductCategory: (state) => state.category,
  getProductCategoriesLv0: (state) => state.categoriesLv0,
  getProductCategoriesLv1: (state) => state.categoriesLv1,
  getProductCategoriesLv2: (state) => state.categoriesLv2,
  getProductCategoriesLv3: (state) => state.categoriesLv3,
  getSelectedItems: (state) => state.selectedItems,
  getChoosenCategory(state) {
    state.choosenCategory.sort(function (resultA, resultB) {
      return resultA.level - resultB.level
    })
    return state.choosenCategory
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
