<template>
  <v-dialog persistent v-model="modal" width="900">
    <v-card>
      <v-card-title class="headline">
        <div>{{ $vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.TITLE') }}</div>
        <v-spacer />
        <v-btn icon @click="$emit('onClose')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0 modal-library__main-content">
        <v-row no-gutters>
          <v-col class="pa-10" cols="7" style="border-right: 1px solid #dddbda">
            <BaseInputSearch
              :label="$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.LABEL_FILE_NAME')"
              :placeholder="
                $vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.PLACEHOLDER_FILTER_FILE_NAME')
              "
              @onSearch="(text) => onSearch(text)"
            />
            <v-progress-circular indeterminate v-if="loading" model-value="20"></v-progress-circular>
            <ListImageThumbnail
              v-if="!loading"
              :listImages="files"
              :total="Math.ceil(countDocument / 10)"
              :pageNumber="pageNumber + 1"
              @nextPage="nextPage"
              @previousPage="previousPage"
              @onSelect="(item) => onSelect(item)"
            />
          </v-col>
          <v-col class="pa-5" cols="5">
            <AttachmentDetail :selectedImage="selectedItem" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="py-4">
        <v-spacer />
        <BaseButton
          isButtonText
          @click="$emit('onClose')"
          :text="$vuetify.lang.t('$vuetify.COURSE.BUTTON_CANCEL_TEXT')"
        />
        <BaseButton
          :disabled="!selectedItem"
          @click="submit"
          :text="$vuetify.lang.t('$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.BUTTON_SELECT')"
          :dark="false"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AttachmentDetail from '@/components/library/AttachmentDetail'
import ListImageThumbnail from '@/components/library/ListImageThumbnail'
import {mapActions, mapGetters} from 'vuex'
export default {
  components: {
    ListImageThumbnail,
    AttachmentDetail,
  },
  props: {
    type: String,
  },
  data() {
    return {
      modal: false,
      itemsPerPage: 10,
      pageNumber: 0,
      search: '',
      selectedItem: undefined,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      files: 'document/documents',
      countDocument: 'document/count',
    }),
    tags() {
      return this.search ? this.$utils.clearUnicode(this.search) : undefined
    },
  },
  methods: {
    ...mapActions({
      fetchDocuments: 'document/fetchDocuments',
      countDocuments: 'document/countDocuments',
    }),
    onSelect(selected) {
      this.selectedItem = selected
    },
    refresh() {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    async show() {
      await this.refresh()
      this.modal = true
      this.countDocuments({
        subType: this.type === 'file' ? null : 'image',
        type: 'document',
      })
      this.handleResourceData(this.pageNumber, this.itemsPerPage)
    },
    nextPage() {
      this.pageNumber++
      this.handleResourceData(this.pageNumber, this.itemsPerPage)
    },
    previousPage() {
      this.pageNumber--
      this.handleResourceData(this.pageNumber, this.itemsPerPage)
    },
    async handleResourceData(pageNumber, itemsPerPage) {
      this.loading = true
      await this.fetchDocuments({
        tags_contain: this.tags,
        subType: this.type === 'file' ? null : 'image',
        type: 'document',
        _skip: pageNumber * itemsPerPage,
        _limit: itemsPerPage,
      })
      this.loading = false
    },
    hide() {
      this.modal = false
    },
    async onSearch(searchString) {
      this.itemsPerPage = 10
      this.pageNumber = 0
      this.search = searchString
      this.countDocuments({
        tags_contain: this.tags,
        subType: this.type === 'file' ? null : 'image',
        type: 'document',
      })
      this.handleResourceData(this.pageNumber, this.itemsPerPage)
    },
    async submit() {
      this.$emit('onSelect', this.selectedItem)
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-library__main-content {
  min-height: 300px;
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
