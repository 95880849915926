import dayjs from 'dayjs'
import vuetify from '@/plugins/vuetify'
import unidecode from 'unidecode'

const clearUnicode = (alias = '', hyphen = true) => {
  var str = alias.normalize()
  str = str.toLowerCase()
  str = unidecode(str)

  str = str.replace(
    /*eslint-disable-next-line*/
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  )
  str = str.replace(/ + /g, ' ')
  if (hyphen) str = str.trim().split(' ').join('-')
  return str.trim()
}

const removeSpacing = (text) => {
  let newText = text
    .trim()
    .split(' ')
    .filter((str) => str)
    .join(' ')
  return newText
}

const parseScore = (score, number) => {
  return score ? Number(score.toFixed(number)) : 0
}

const formatMoney = (number, currency = '', isSuffix = true) => {
  const cookedNumber = Math.floor(Number(number))
  let result = cookedNumber + ''
  if (number > 0) {
    result = cookedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  if (currency) {
    return isSuffix ? result + currency : currency + result
  }
  return result
}

const cleanObject = (obj = {}, filter = Boolean) => {
  const output = {}
  if (obj['not-clear-request']) return obj
  for (let key in obj) {
    if (filter(obj[key], key) || obj[key] === 0 || obj[key] === false) {
      output[key] = obj[key]
    }
  }
  return output
}

const toNumberOrZero = (numberString = '') => {
  return numberString ? +numberString : 0
}

const secondsToHms = (d) => {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)

  var hDisplay =
    h > 0
      ? h +
        ' ' +
        (d % 3600 > 0
          ? vuetify.framework.lang.translator('$vuetify.TEXT_HOUR')
          : vuetify.framework.lang.translator('$vuetify.TEXT_HOUR'))
      : ''
  var mDisplay =
    m > 0
      ? m +
        ' ' +
        ((d % 3600) % 60 > 0
          ? vuetify.framework.lang.translator('$vuetify.TEXT_MINUTE')
          : vuetify.framework.lang.translator('$vuetify.TEXT_MINUTE'))
      : ''
  var sDisplay = s > 0 ? s + ' ' + vuetify.framework.lang.translator('$vuetify.TEXT_SECOND') : ''
  return hDisplay + ' ' + mDisplay + ' ' + sDisplay
}

const convertDateToVietnamese = (date, isReturnToISO = false, format = 'DD/MM/YYYY') => {
  const customParseFormat = require('dayjs/plugin/customParseFormat')
  dayjs.extend(customParseFormat)
  if (date) {
    if (isReturnToISO) return dayjs(date, format).toISOString()
    return dayjs(date, format)
  }
  return ''
}

const memorySizeOf = (number) => {
  function formatByteSize(bytes) {
    if (bytes < 1024) return bytes + ' bytes'
    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KiB'
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MiB'
    else return (bytes / 1073741824).toFixed(3) + ' GiB'
  }

  return formatByteSize(number)
}

const formatSourceTypePermission = ({sourceType, sourceName}) => {
  let translate = {
    default: `Trực tiếp`,
    group: `Nhóm ${sourceName}`,
    payment: `Đã mua`,
    membership: `Gói ${sourceName}`,
    free: 'Miễn phí',
    'active-code': 'Mã kích hoạt',
  }
  return translate[sourceType]
}

export default {
  removeSpacing,
  parseScore,
  clearUnicode,
  formatMoney,
  cleanObject,
  secondsToHms,
  toNumberOrZero,
  convertDateToVietnamese,
  memorySizeOf,
  formatSourceTypePermission,
}
