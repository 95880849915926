import { render, staticRenderFns } from "./BaseTooltipWithIcon.vue?vue&type=template&id=2a5b6801&"
import script from "./BaseTooltipWithIcon.vue?vue&type=script&lang=js&"
export * from "./BaseTooltipWithIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports