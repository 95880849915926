import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const InterestRepository = RepositoryFactory.get('interest')

const state = {
  interests: {},
};

const actions = {
  async fetchInterests({ commit }, params = {}) {
    const res = await InterestRepository.fetch({
      ...params,
    });
    commit("setInterests", res.data || []);
    return res.data;
  },
  async addMultipleInterest({ dispatch }, params = {}) {
    const res = await InterestRepository.addMultipleInterest({
      ...params,
    });
    dispatch("fetchInterests", { userId: params.userId });
    return res.data;
  },
  async addOneInterest({ dispatch }, { id, params }) {
    const res = await InterestRepository.addOneInterest(id, params);
    dispatch("fetchInterests", { userId: params.userId });
    return res.data;
  },
  async removeOneInterest({ dispatch }, { id, params }) {
    const res = await InterestRepository.removeOneInterest(id, params);
    dispatch("fetchInterests", { userId: params.userId });
    return res.data;
  },
  async removeMultipleInterest({ commit }, params = {}) {
    const res = await InterestRepository.removeMultipleInterest({
      ...params,
    });
    commit("");
    return res.data;
  },
};

const mutations = {
  setInterests(state, interests) {
    return (state.interests = interests.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    ));
  },
  appendOneInterest(state, interest) {
    state.interests = {
      ...state.interests,
      ...interest
    }
  },
  appendInterests(state, interests) {
    const newIntersts = interests.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    );
    state.interests = {
      ...state.interests,
      ...newIntersts
    }
  }
};

const getters = {
  getInterests: (state) => {
    return Object.values(state.interests);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
